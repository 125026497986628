import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Select } from 'antd';
import CachedAppsList from '../../utils/CachedAppsList';

const { Option } = Select;

const AppSelector = ({ apps, curApp, onAppSelected, allowedPlatforms, isMarketing }) => {
  const [searchQuery, searchQueryChange] = useState('');

  const sub_key = isMarketing ? '_Marketing' : '';

  const handleAppSelected = (value, option) => {
    if (!curApp || curApp.package !== option.props.package || curApp.platform !== option.props.platform) {
      onAppSelected(option.props.app);
      const appToCache = apps.find(
        (app) =>
          app.name === option.props.name &&
          app.package === option.props.package &&
          app.platform === option.props.platform
      );
      CachedAppsList.addCachedApp(appToCache, sub_key);
      searchQueryChange('');
    }
  };

  if (apps.length === 0) {
    return null;
  }
  const filteredAppList = (apps || [])
    .filter((app) => allowedPlatforms.includes(app.platform))
    .filter((app) => {
      if (searchQuery && searchQuery.length >= 3) {
        const match =
          app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          app.package.toLowerCase().includes(searchQuery.toLowerCase());
        return match;
      }
      return false;
    });

  const appsToShow = filteredAppList.length === 0 ? CachedAppsList.getCachedApps(sub_key) : filteredAppList;

  return (
    <div className="insight-selector">
      <div className="fields">
        {curApp && <img src={curApp.icon} className="app-selector-icon" />}

        <div className="insight-selector__app-dropdown two wide field">
          <Select
            showSearch
            filterOption={false}
            placeholder="Select app..."
            onSearch={(value) => searchQueryChange(value)}
            onChange={handleAppSelected}
            defaultActiveFirstOption={false}
            value={curApp ? [curApp.package, curApp.platform].toString() : ''}
          >
            {appsToShow.map((app) => (
              <Option
                key={app.id}
                value={[app.package, app.platform].toString()}
                package={app.package}
                app={app}
                name={app.name}
                platform={app.platform}
              >
                {`${app.name} [${app.platform}] (${app.package})`}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  apps: state.apps.all,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSelector);
