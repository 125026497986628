import * as AT from 'redux/actionTypes';
import { loginManager, getCurUserDetailsAPI } from '../actions/login';
import { getAllDeviceModelsAPI } from '../actions/deviceModels';

const loginMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.LOGIN: {
        const { user } = action.payload;
        const basicAuth = btoa(`watchful:${user.password}`);
        localStorage.setItem('basicAuth', JSON.stringify(basicAuth));
        dispatch(loginManager());
        return next(action);
      }
      case AT.GET_CUR_USER_DETAILS: {
        dispatch(getCurUserDetailsAPI());
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default loginMiddleware;
