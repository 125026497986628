"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIFF_MOCK = void 0;
exports.DIFF_MOCK = {
    event: 'diff',
    timestamp: '2023-05-29T10:15:08.245562',
    package: 'com.zhiliaoapp.musically',
    prev_version: '2022902040__29.2.4',
    curr_version: '2022902040__29.2.4',
    diff: {
        package: 'com.zhiliaoapp.musically',
        prev_version: '2022902040__29.2.4',
        curr_version: '2022902040__29.2.4',
        diff: {
            strings: {
                added: [
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                    {
                        a01: 'Submit query',
                    },
                ],
                removed: [],
                changed: [],
            },
            layouts: {
                added: [],
                removed: [],
                changed: [],
            },
        },
    },
};
