import { Button, Modal } from 'antd';
import React, { useLayoutEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { getPreviewForAsset } from '../../../../../api/insightsAPI';
import { CanvasedImage } from './CanvasedImage';
import './EditAssetModal.scss';
import { LinkedInsight } from './LinkedInsight';

export const EditAssetModal = ({ assets, actAssetIndex, isOpen, tableLayout, updAsset, closeModal, appId }) => {
  const curImg = assets[actAssetIndex] || assets[0];
  const [rects, rectsChange] = useState(curImg ? curImg.rects : []);
  const [action, setAction] = useState(
    curImg ? { action_type: curImg.action_type, action_coords: curImg.action_coords } : null
  );
  const [previewLink, previewLinkChange] = useState(null);
  const [isZoomInModalVisible, setIsZoomInModalVisible] = useState(false);

  useLayoutEffect(() => {
    rectsChange(assets[actAssetIndex].rects || []);
    setAction({
      action_type: assets[actAssetIndex].action_type,
      action_coords: assets[actAssetIndex].action_coords,
    });
    previewLinkChange(null);
  }, [actAssetIndex]);

  const updateImagePreviewMut = useMutation(getPreviewForAsset, {
    onSuccess: (res) => {
      previewLinkChange(res.data.url_annotated);
    },
  });

  const handleSaveAsset = (closeAfter) => {
    if (curImg.type === 'image') {
      updAsset({ asset_id: curImg.user_flow_set_asset_id, rects });
    }
    previewLinkChange(null);
    if (closeAfter) {
      closeModal();
    }
  };

  const updatePreview = () => {
    if (curImg.type === 'image') {
      updateImagePreviewMut.mutateAsync({
        asset_id: curImg.id,
        data: {
          ...curImg,
          rects,
        },
      });
    }
  };

  const handleRectChange = (rect) => {
    rect = {
      ...rect,
      removed: rect.removed === undefined ? undefined : String(rect.removed),
      key: rect.key === undefined ? undefined : String(rect.key),
    };
    let newRects;
    if (rect.removed) {
      newRects = rects.filter((x) => x.key !== rect.removed);
    } else {
      const index = rects.map((x) => x.key).indexOf(rect.key);
      if (index === -1) {
        newRects = [...rects, { ...rect }];
      } else {
        newRects = rects.map((x) => (x.key === rect.key ? { ...rect } : x));
      }
    }
    rectsChange(newRects);
  };

  if (!curImg) {
    return;
  }

  return (
    <div>
      <Modal
        title="Mark image"
        centered
        visible={isZoomInModalVisible}
        onOk={() => {
          setIsZoomInModalVisible(false);
          updatePreview();
        }}
        onCancel={() => setIsZoomInModalVisible(false)}
        width="fit-content"
        className="zoom-in-canvas"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CanvasedImage
          imgSrc={curImg.url_raw}
          key={`${curImg.id}-${curImg.orientation}-${
            rects.length > 0 ? rects.reduce((acc, curr) => `${acc}-${curr.type}`) : 'none'
          }`}
          rects={Object.fromEntries((rects || []).map((x) => [x.key, x]))}
          onRectChange={handleRectChange}
          isLandscape={curImg.orientation === 'landscape'}
          asset={curImg}
          isZoomIn
          action={action}
        />
      </Modal>
      <Modal
        visible={isOpen}
        title="Edit Asset"
        centered
        width="fit-content"
        height="85%"
        footer={null}
        wrapClassName="modal-assetslist-edit"
        onCancel={closeModal}
      >
        <div className="mae-flex-wrap">
          <div className="mae-flex-left-side-table edit-table">{tableLayout()}</div>
          <div className="mae-flex-right-side-images">
            {assets && actAssetIndex !== null && (
              <div className={`preview-wrap ${curImg.orientation}`}>
                {!isZoomInModalVisible && curImg.type === 'image' && (
                  <CanvasedImage
                    imgSrc={curImg.url_raw}
                    asset={curImg}
                    key={`${curImg.id}-${curImg.orientation}-${
                      rects.length > 0 ? rects.reduce((acc, curr) => `${acc}-${curr.type}`) : 'none'
                    }`}
                    rects={Object.fromEntries((rects || []).map((x) => [x.key, x]))}
                    onRectChange={handleRectChange}
                    isLandscape={curImg.orientation === 'landscape'}
                    isZoomIn={isZoomInModalVisible}
                    onZoomIn={() => setIsZoomInModalVisible(true)}
                    url_annotated={curImg.url_annotated}
                    previewLink={previewLink}
                    updatePreview={updatePreview}
                    updatePreviewIsloading={updateImagePreviewMut.isLoading}
                    action={action}
                  />
                )}
              </div>
            )}
            <div className="asset-manage">
              <LinkedInsight assetId={curImg.user_flow_set_asset_id} linkedInsights={curImg.insights} appId={appId} />
              <Button onClick={closeModal} type="ghost" className="asset-manage-btn">
                Cancel
              </Button>
              <Button onClick={() => handleSaveAsset(false)} type="default" className="asset-manage-btn">
                Save
              </Button>
              <Button onClick={() => handleSaveAsset(true)} type="primary" className="asset-manage-btn">
                Save and Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
