/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  addVerificationFilter,
  delVerificationFilter,
  getAllVerificationFilters,
  updVerificationFilter,
} from '../../redux/actions/annotationVerificationPage';
import { Alert, Button, Checkbox } from 'antd';
import VerificationFiltersTable from './verificationFiltersTable/VerificationFiltersTable';
import VerificationFilterModal from './forms/VerificationFilterModal';
import { getAppsData } from '../../redux/actions/appsReleasesPage';
import { getOrchestratorsList } from '../../redux/actions/performancePage';
import { getAllDeviceModels } from '../../redux/actions/deviceModels';
import { useMutation, useQuery } from 'react-query';
import { getSettings, setSetting } from '../../api/dbSettingsAPI';

function AnnotationVerification({
  getAllVerificationFilters,
  addVerificationFilter,
  updVerificationFilter,
  delVerificationFilter,
  verificationFilters,
  isLoading,
  error,
  apps,
  getAppsData,
  deviceModels,
  getAllDeviceModels,
  orchestrators,
  getOrchestratorsList,
}) {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const settings = useQuery('settings', getSettings);
  const setSettingMutation = useMutation(setSetting, {
    onSuccess: (data) => settings.refetch(),
  });

  const isAnnotationVerificationEnabled =
    settings.data && settings.data.data.find((x) => x.name === 'is_annotation_verification_enabled').value;
  const isAnnotationVerificationDeviationEnabled =
    settings.data && settings.data.data.find((x) => x.name === 'is_annotation_verification_deviation_enabled').value;

  useEffect(() => {
    getAppsData();
    getAllDeviceModels();
    getOrchestratorsList();
    getAllVerificationFilters();
  }, []);

  const handleAddVerificationFilter = (data) => {
    addVerificationFilter(data);
  };

  return (
    <div>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      {isAddModalVisible && (
        <VerificationFilterModal
          handleHide={() => setIsAddModalVisible(false)}
          handleSumit={handleAddVerificationFilter}
          apps={apps}
          deviceModels={deviceModels}
          orchestrators={orchestrators}
          verificationFilters={verificationFilters}
        />
      )}
      <Button onClick={() => setIsAddModalVisible(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        Add Verification Filter
      </Button>
      <Checkbox
        checked={isAnnotationVerificationEnabled}
        disabled={settings.isLoading || setSettingMutation.isLoading}
        onChange={() =>
          setSettingMutation.mutateAsync({
            name: 'is_annotation_verification_enabled',
            value: !isAnnotationVerificationEnabled,
          })
        }
      >
        Annotation Verification
      </Checkbox>
      <Checkbox
        checked={isAnnotationVerificationDeviationEnabled}
        disabled={settings.isLoading || setSettingMutation.isLoading || !isAnnotationVerificationEnabled}
        onChange={() =>
          setSettingMutation.mutateAsync({
            name: 'is_annotation_verification_deviation_enabled',
            value: !isAnnotationVerificationDeviationEnabled,
          })
        }
      >
        by Deviation
      </Checkbox>
      <VerificationFiltersTable
        verificationFilters={verificationFilters}
        isLoading={isLoading}
        handleChangeVerificationFilter={updVerificationFilter}
        handleRemoveVerificationFilter={delVerificationFilter}
      />
    </div>
  );
}

const mapDispatchToProps = {
  getAllVerificationFilters,
  updVerificationFilter,
  delVerificationFilter,
  addVerificationFilter,
  getAppsData,
  getAllDeviceModels,
  getOrchestratorsList,
};
const mapStateToProps = ({ annotationVerificationPage }) => ({
  verificationFilters: annotationVerificationPage.verificationFilters,
  isLoading: annotationVerificationPage.isLoading,
  error: annotationVerificationPage.error,
  apps: annotationVerificationPage.apps,
  deviceModels: annotationVerificationPage.deviceModels,
  orchestrators: annotationVerificationPage.orchestrators,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnotationVerification);
