import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import CtasTable from './CtasTable';
import CtaModal from './CtaModal';

const InsightCtas = ({ ctas, isLoading, error, addCta, updCta, delCta }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditCtaId, setCurEditCtaId] = useState(null);

  return (
    <div>
      {error && (
        <Alert
          message={error.message}
          description={JSON.stringify(
            (error.response && error.response.body && error.response.body.error) || error.response.body.validation_error
          )}
          type="error"
          showIcon
        />
      )}
      {isModalVisible && (
        <CtaModal
          cta={ctas.find((x) => x.id === curEditCtaId)}
          onHide={() => {
            setIsModalVisible(false);
            setCurEditCtaId(null);
          }}
          onAddCta={addCta}
          onUpdCta={updCta}
        />
      )}
      <CtasTable
        isLoading={isLoading}
        ctas={ctas}
        onUpdCta={(ctaId) => {
          setIsModalVisible(true);
          setCurEditCtaId(ctaId);
        }}
        onDelCta={delCta}
      />
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        type="primary"
        style={{ margin: 16 }}
        loading={isLoading}
      >
        Add CTA
      </Button>
    </div>
  );
};

export default InsightCtas;
