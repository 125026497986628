import { backendApiRequest } from './request';

// INSIGHT PAGE 2.0 api

// GET Data for APP
export const getInsightsByApp = async (app_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/apps/${app_id}/insights`,
  });
export const getPersonasByApp = async (app_id) =>
  backendApiRequest({
    method: 'GET',
    params: { app_id },
    path: `/personas/all`,
  });
export const getReleasesByApp = async (app_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/apps/${app_id}/releases`,
  });

// API for ABTest data
export const getFilteredABTests = async (app_id, search_query) =>
  backendApiRequest({
    method: 'GET',
    path: `/ab-tests`,
    params: {
      app_id,
      search_query,
      num_items: 50,
      page_num: 1,
    },
  });
export const updABtestByID = async ({ abtest_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/ab-tests/${abtest_id}`,
    body: data,
  });

// GET Data for Ins
export const getInsightByID = async (insight_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insight_id}`,
  });

// CTAS
export const getCTAsForInsight = async (insight_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/insight/${insight_id}/ctas`,
  });
export const createCTAForInsight = async ({ insight_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/insight/${insight_id}/ctas`,
    body: data,
  });
export const setCTAForInsight = async ({ cta_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/insight/ctas/${cta_id}`,
    body: data,
  });
export const remCTAForInsight = async (cta_id) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/insight/ctas/${cta_id}`,
  });

// TIMELINE
export const getTimelineForInsight = async (insight_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insight_id}/timeline`,
  });
export const createTimelineRecForInsight = async ({ insight_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/insight/${insight_id}/timeline`,
    body: data,
  });
export const setTimelineRecForInsight = async ({ timeRecID, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/insight/timeline/${timeRecID}`,
    body: data,
  });
export const remTimelineRecForInsight = async (timeRecID) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/insight/timeline/${timeRecID}`,
  });

// MANAGE INSIGHT
export const removeInsight = async ({ insight_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/insights/${insight_id}`,
  });
export const updInsightByID = async ({ insight_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/insights/${insight_id}`,
    body: data,
  });
export const createInsight = async (data) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights`,
    body: data,
  });
export const copyInsightAPI = async ({ insight_id }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/${insight_id}/copy`,
    params: {
      new_response_format: 1,
    },
  });

// GET Images
export const getVariantsByABtestID = async (abtest_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/ab-tests/${abtest_id}/variants`,
  });
export const getAssetsByInsightID = async (insight_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/insight/${insight_id}/assets`,
  });

// ADD ASSETS
export const addAssetToVariant = async ({ variant_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/ab-tests/variants/${variant_id}/assets`,
    body: data,
  });
export const addAssetToInsight = async ({ insight_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/insight/${insight_id}/assets`,
    body: data,
  });

// UPDATE ASSETS
export const updAssetByID = async ({ asset_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/ab-tests/variants/assets/${asset_id}`,
    body: data,
  });
export const updInsThumb = async ({ insight_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/insight/${insight_id}/thumbnail`,
    body: data,
  });

// REMOVE ASSETS
export const removeAssetFromVariant = async (asset_id) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/ab-tests/variants/assets/${asset_id}`,
  });
export const remVarintToABTest = async ({ abtest_id, variant_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/ab-tests/${abtest_id}/variants/${variant_id}`,
  });
export const removeAssetFromInsight = async ({ insight_id, asset_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/insight/${insight_id}/assets/${asset_id}`,
  });

export const reOrderInsightsAssets = async (data) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/insights/assets/order-indexes`,
    body: data,
  });

// ADD VARIANTS
export const addVarintToABTest = async ({ abtest_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/ab-tests/${abtest_id}/variants`,
    body: data,
  });

// VARIANTS RELEASES
export const addReleaseLinkToVariant = async ({ abtest_id, variant_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/ab-tests/${abtest_id}/variants/${variant_id}/releases`,
    body: data,
  });
export const remReleaseLinkToVariant = async ({ abtest_id, variant_id, release_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/ab-tests/${abtest_id}/variants/${variant_id}/releases/${release_id}`,
  });
export const updVariantInABTest = async ({ variant_id, data }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/ab-tests/variants/${variant_id}`,
    body: data,
  });

// NEW - UPDATE PREVIEW
export const getPreviewForAsset = async ({ asset_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/assets/${asset_id}/image-preview`,
    body: data,
  });
export const createPreviewForVideoAsset = async ({ asset_id, data }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/assets/${asset_id}/video-preview`,
    body: data,
  });
export const getPreviewStatusForVideoAsset = async (preview_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/assets/video-preview/${preview_id}`,
  });

export const createInsightHighlight = async (params) =>
  backendApiRequest({
    method: 'POST',
    path: '/admin/insights/highlights',
    body: params,
  });

export const sendHighlightEmail = async ({ highlightId }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/highlights/${highlightId}/emails`,
    body: {},
  });

export const getHighlightSlackMessage = async ({ highlightId }) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/highlights/${highlightId}/slack-message`,
  });

export const getInsightLabels = async (insightId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insightId}/labels`,
  });

export const addInsightLabel = async ({ insightId, labelName }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/${insightId}/labels`,
    body: { label_name: labelName },
  });

export const delInsightLabel = async ({ insightId, labelId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/insights/${insightId}/labels/${labelId}`,
  });

export const getInsightPersonas = async (insightId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insightId}/personas`,
  });

export const addInsightPersona = async ({ insightId, personaId }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/${insightId}/personas`,
    body: { persona_id: personaId },
  });

export const delInsightPersona = async ({ insightId, personaId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/insights/${insightId}/personas/${personaId}`,
  });

export const getInsightRelationsSuggestions = async (insightId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insightId}/relations/suggestions`,
  });

export const getInsightRelations = async (insightId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/insights/${insightId}/relations`,
  });

export const addInsightRelation = async ({ insightId, relatedInsightId }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/${insightId}/relations`,
    body: { insight_id: relatedInsightId },
  });

export const delInsightRelation = async ({ insightId, relatedInsightId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/insights/${insightId}/relations/${relatedInsightId}`,
  });

export const getInsightsOfAbtest = async (ab_test_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/ab-tests/${ab_test_id}/insights`,
  });

export const copyVariantsOfInsight = async ({ source_insight_id, target_insight_id }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/insights/${source_insight_id}/variants/copy`,
    body: { target_insight_id },
  });

export const getPresignedPostUrl = async () =>
  backendApiRequest({
    method: 'GET',
    path: '/insight/presigned-post-url',
  });

export const uploadFileToS3 = async ({ file }) => {
  const { data } = await getPresignedPostUrl();

  file.amzData = data;
  file.key = data.uploaded_file_name;
  file.percent = 0;
  file.status = 'uploading';

  const formData = new FormData();
  Object.keys(file.amzData.s3_post_data.fields).forEach((key) => {
    formData.append(key, file.amzData.s3_post_data.fields[key]);
  });
  formData.append('file', file);

  await fetch(file.amzData.s3_post_data.url, {
    method: 'POST',
    body: formData,
  });

  return {
    uploaded_file_name: data.uploaded_file_name,
  };
};

export const uploadFileToS3FromUrl = async ({ fileUrl }) => {
  const response = await fetch(fileUrl);
  const file = await response.blob();

  return uploadFileToS3({ file });
};

// verticals

export const getVerticals = async () =>
  backendApiRequest({
    method: 'GET',
    path: '/admin/verticals',
  });

export const createVertical = async ({ name }) =>
  backendApiRequest({
    method: 'POST',
    path: '/admin/verticals',
    body: { name },
  });

export const updateVertical = async ({ id, name }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/verticals/${id}`,
    body: { name },
  });

export const deleteVertical = async ({ id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/verticals/${id}`,
  });

// focus areas

export const getAllFocusAreas = async () =>
  backendApiRequest({
    method: 'GET',
    path: '/admin/verticals/focus-areas',
  });

export const getFocusAreasForVertical = async ({ vertical_id }) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/verticals/${vertical_id}/focus-areas`,
  });

export const createFocusArea = async ({ vertical_id, name }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/verticals/${vertical_id}/focus-areas`,
    body: { name },
  });

export const updateFocusArea = async ({ id, name }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/verticals/focus-areas/${id}`,
    body: { name },
  });

export const deleteFocusArea = async ({ id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/verticals/focus-areas/${id}`,
  });
