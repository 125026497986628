import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  verificationFilters: [],
  apps: [],
  deviceModels: [],
  orchestrators: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_VERIFICATION_FILTERS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_ALL_VERIFICATION_FILTERS_SUCCESS: {
      return {
        ...state,
        verificationFilters: action.payload.data,
        isLoading: false,
      };
    }
    case actionTypes.GET_ALL_VERIFICATION_FILTERS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case actionTypes.UPD_VERIFICATION_FILTER: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_VERIFICATION_FILTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        verificationFilters: state.verificationFilters.map((x) =>
          x.id === action.payload.data.id ? action.payload.data : x
        ),
      };
    }
    case actionTypes.UPD_VERIFICATION_FILTER_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case actionTypes.ADD_VERIFICATION_FILTER: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_VERIFICATION_FILTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        verificationFilters: [...action.payload.data, ...state.verificationFilters],
      };
    }
    case actionTypes.ADD_VERIFICATION_FILTER_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case actionTypes.DEL_VERIFICATION_FILTER: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_VERIFICATION_FILTER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        verificationFilters: state.verificationFilters.filter((x) => x.id !== action.payload.filter_id),
      };
    }
    case actionTypes.DEL_VERIFICATION_FILTER_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case actionTypes.GET_APPS_DATA_SUCCESS: {
      return {
        ...state,
        apps: action.payload.appsData.map((x) => ({
          id: x.id,
          package_name: x.package_name,
          app_name: x.app_name,
          platform: x.platform,
        })),
      };
    }
    case actionTypes.GET_DEVICE_MODELS_SUCCESS: {
      return {
        ...state,
        deviceModels: action.payload.deviceModels,
      };
    }
    case actionTypes.GET_ORCHESTRATORS_LIST_SUCCESS: {
      return {
        ...state,
        orchestrators: action.payload.data,
      };
    }

    default: {
      return state;
    }
  }
}
