import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import './InsightsTabs.scss';
import { DiffPanel } from './diffPanel';
import { ImagesPreview } from './imagesPreview';
import { InsightsPanel } from './insightsPanel';
import { RPPanel } from './rpPanel';
import { UJPanel } from './ujPanel';

export const TABS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  DIFF: 'diff',
  RP: 'rp',
  UJ: 'uj',
};

export const InsightsTabs = ({
  insights,
  diffObject,
  leftDevice,
  rightDevice,
  socket,
  currApp,
  rp,
  setRp,
  userJourneys,
  leftDeviceAppVer,
  rightDeviceAppVer,
  ...rest
}) => {
  const draftInsights = useMemo(() => insights.filter((i) => !i.should_show), [insights]);
  const publishedInsights = useMemo(() => insights.filter((i) => i.should_show), [insights]);
  const onTabChange = (e) => {
    const classesToChange = ['insights-main', 'diff-list-container'];

    classesToChange.forEach((classToChange) => {
      const elements = document.getElementsByClassName(classToChange);
      Array.from(elements).forEach((ele) => {
        ele.style.width = '30rem';
      });
    });
  };
  return (
    <div className="tabs-container">
      <Tabs defaultActiveKey={TABS.DRAFT} type="card" onTabClick={onTabChange}>
        <Tabs.TabPane tab={`Drafts(${draftInsights.length})`} key={TABS.DRAFT}>
          <InsightsPanel insights={draftInsights} {...rest} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Published(${publishedInsights.length})`} key={TABS.PUBLISHED}>
          <InsightsPanel insights={publishedInsights} {...rest} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'UJ'} key={TABS.UJ}>
          <UJPanel {...rest} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Diff" key={TABS.DIFF}>
          {diffObject && <DiffPanel diffObject={diffObject} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="RP" key={TABS.RP}>
          {leftDevice && rightDevice && currApp && (
            <RPPanel
              leftDeviceAppVer={leftDeviceAppVer}
              rightDeviceAppVer={rightDeviceAppVer}
              leftDevice={leftDevice}
              rightDevice={rightDevice}
              socket={socket}
              currApp={currApp}
              rp={rp}
              setRp={setRp}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
      <ImagesPreview {...rest} />
    </div>
  );
};
