import React, { useState, useEffect } from 'react';
import { Input } from 'antd';

export default function InsightFormTextArea({
  insight,
  handleInsChange,
  field,
  title,
  insideContent = false,
  textarea = true,
  required = false,
  maxLength,
}) {
  const [locTextAreaVal, locTextAreaValChange] = useState();

  const isInsightChanged =
    field === 'internalComment' && typeof insight[field] === 'object'
      ? JSON.stringify(insight[field], null, 4) !== locTextAreaVal
      : insight[field] !== locTextAreaVal;

  useEffect(() => {
    let value = insight[field];

    if (field === 'internalComment' && typeof value === 'object') {
      value = JSON.stringify(value, null, 4);
    }

    locTextAreaValChange(value);
  }, [insight[field]]);

  const updField = () => {
    if (insideContent) {
      handleInsChange({ content: { ...insight, [field]: locTextAreaVal } });
    } else {
      handleInsChange({ [field]: locTextAreaVal });
    }
  };

  const handleEnter = (e) => {
    if (e.shiftKey) {
      updField();
      e.preventDefault();
    }
  };

  const handleBlur = (e) => {
    updField();
  };

  return (
    <div className="ins-descr-wrap">
      <label>
        {title} <span className="red-star">*</span>
        {isInsightChanged && <span className="textarea-tip-not-save">not saved</span>}
      </label>
      {textarea ? (
        <Input.TextArea
          maxLength={maxLength}
          required={required}
          status={required && (!!locTextAreaVal || 'error')}
          value={locTextAreaVal}
          onPressEnter={handleEnter}
          onBlur={handleBlur}
          onChange={(e) => locTextAreaValChange(e.target.value)}
          rows={4}
        />
      ) : (
        <Input
          maxLength={maxLength}
          required={required}
          status={required && (!!locTextAreaVal || 'error')}
          value={locTextAreaVal}
          onPressEnter={handleEnter}
          onBlur={handleBlur}
          onChange={(e) => locTextAreaValChange(e.target.value)}
        />
      )}

      {textarea ? (
        <div className="textarea-tip">ENTER to line break / SHIFT + ENTER to save</div>
      ) : (
        <div className="textarea-tip">SHIFT + ENTER to save</div>
      )}
    </div>
  );
}
