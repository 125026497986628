import { Auth } from 'aws-amplify';
import { stringify } from 'query-string';
import { WbServicesMock } from '../components/workbench_v2/mocks/wb_services';
import store from '../redux/store';

class ApiError extends Error {
  constructor(message, meta) {
    super(message);
    this.meta = meta;
  }
}

export const backendApiRequest = async ({ method, path, params, body }) => {
  const requestParams = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
    },
  };

  if (['POST', 'PUT'].includes(method)) {
    requestParams.body = JSON.stringify(body);
  }

  // eslint-disable-next-line no-undef
  const response = await fetch(`${API_HOST}${path}?${stringify(params)}`, requestParams);

  const responseText = await response.text();
  const json = responseText ? JSON.parse(responseText) : '';
  if (!response.ok) {
    throw new ApiError(JSON.stringify(json), { status: response.status, path });
  }

  return json;
};

export const workbenchApiRequest = async ({ method, path, params, body }) => {
  const { deviceServicesHostUrl } = store.getState().workbench.config;

  if (WbServicesMock.mockEnabled) {
    const mockEndpoints = WbServicesMock.getWbServicesMockEndpoints();

    return await mockEndpoints[method][path]();
  }

  const requestParams = {
    method: method || 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (['POST', 'PUT'].includes(method)) {
    requestParams.body = JSON.stringify(body);
  }

  // eslint-disable-next-line no-undef
  const response = await fetch(`${deviceServicesHostUrl}${path}?${stringify(params)}`, requestParams);

  const responseText = await response.text();
  const json = responseText ? JSON.parse(responseText) : '';
  if (!response.ok) {
    throw new ApiError(JSON.stringify(json), { status: response.status, path });
  }

  return json;
};
