"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hints = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
require("./Hints.scss");
var WorkbenchContext_1 = require("components/workbench_v2/contexts/workbench-context/WorkbenchContext");
var consts_1 = require("components/workbench_v2/utils/consts");
var wbTracking_1 = require("monitoring/wbTracking");
var HintsConfigFields = {
    OnlyResourceStrings: 'only_resource_strings',
    ElementLocationChangeHints: 'element_location_change_hint',
    DiffDataHints: 'diff_hints',
    AddedRemovedTextHints: 'added_removed_text',
};
var initialHintsConfig = [HintsConfigFields.AddedRemovedTextHints, HintsConfigFields.ElementLocationChangeHints];
var customDeviceHintsConfig = [];
var Hints = function (_a) {
    var hintsStarted = _a.hintsStarted, setHintsStarted = _a.setHintsStarted;
    var _b = (0, WorkbenchContext_1.useWorkbenchContext)(), isCustomDeviceLeft = _b.isCustomDeviceLeft, isCustomDeviceRight = _b.isCustomDeviceRight, leftDeviceAppVer = _b.leftDeviceAppVer, rightDeviceAppVer = _b.rightDeviceAppVer, socket = _b.socket, leftDevice = _b.leftDevice, rightDevice = _b.rightDevice, setHintProgress = _b.setHintProgress, diffObject = _b.diffObject, currApp = _b.currApp;
    var isCustomDevice = isCustomDeviceLeft || isCustomDeviceRight;
    var isDiffCollected = !!diffObject;
    var _c = (0, react_1.useState)(initialHintsConfig), hintsConfig = _c[0], setHintsConfig = _c[1];
    var isAddedRemovedTextSelected = hintsConfig.includes(HintsConfigFields.AddedRemovedTextHints);
    (0, react_1.useEffect)(function () {
        setHintsConfig(isCustomDevice ? customDeviceHintsConfig : initialHintsConfig);
    }, [isCustomDevice]);
    var handleToggleHints = function () {
        var prevDeviceAppVer = leftDeviceAppVer || rightDeviceAppVer;
        var currDeviceAppVer = rightDeviceAppVer || leftDeviceAppVer;
        wbTracking_1.workbenchTracking.startEventTrack('hints_loading_time', {
            hints_started_at: new Date().toLocaleString(),
        });
        socket.send(__assign({ event: hintsStarted ? consts_1.SOCKET_SEND_EVENTS.HintsAnalysisStop : consts_1.SOCKET_SEND_EVENTS.HintsAnalysisStart }, (hintsStarted
            ? null
            : {
                platform: currApp ? currApp.platform : 'android',
                hint_types: hintsConfig,
                prev_custom_version: leftDevice && prevDeviceAppVer && leftDevice.serial === consts_1.CUSTOM_DEVICE.serial
                    ? "".concat(prevDeviceAppVer.version_code, "__").concat(prevDeviceAppVer.version_name)
                    : '',
                curr_custom_version: rightDevice && currDeviceAppVer && rightDevice.serial === consts_1.CUSTOM_DEVICE.serial
                    ? "".concat(currDeviceAppVer.version_code, "__").concat(currDeviceAppVer.version_name)
                    : '',
            })));
        setHintProgress(null);
        setHintsStarted(!hintsStarted);
    };
    var handleHintsConfigChange = function (_a) {
        var checked = _a.checked;
        var hintsOptions = checked;
        if (!hintsOptions.includes(HintsConfigFields.AddedRemovedTextHints)) {
            hintsOptions = hintsOptions.filter(function (option) { return !option.includes(HintsConfigFields.OnlyResourceStrings); });
        }
        if (hintsStarted) {
            socket.send({
                event: consts_1.SOCKET_SEND_EVENTS.HintsAnalysisStop,
            });
            socket.send({
                event: consts_1.SOCKET_SEND_EVENTS.HintsAnalysisStart,
                hint_types: hintsOptions,
            });
        }
        setHintsConfig(hintsOptions);
    };
    var handleConfigOptionSelect = function (_, _a) {
        var node = _a.node;
        var eventKey = node.props.eventKey;
        if (hintsConfig.includes(eventKey)) {
            var checkedOptions = hintsConfig.filter(function (option) { return !option.includes(eventKey); });
            return handleHintsConfigChange({ checked: checkedOptions });
        }
        if (!hintsConfig.includes(eventKey)) {
            return handleHintsConfigChange({ checked: __spreadArray(__spreadArray([], hintsConfig, true), [eventKey], false) });
        }
    };
    return (react_1.default.createElement("div", { className: "wb-hints-container" },
        react_1.default.createElement("div", { className: "wb-hints-btn-container" },
            react_1.default.createElement(antd_1.Button, { type: hintsStarted ? 'primary' : 'default', size: "large", style: { width: '100%' }, onClick: handleToggleHints, disabled: !currApp },
                hintsStarted ? 'Stop' : 'Start',
                " Hint Generation")),
        react_1.default.createElement("div", { className: "wb-hints-config-container" },
            react_1.default.createElement(antd_1.Tree, { checkStrictly: true, checkable: true, defaultCheckedKeys: hintsConfig, checkedKeys: hintsConfig, onCheck: handleHintsConfigChange, onSelect: handleConfigOptionSelect, disabled: isCustomDevice || !isDiffCollected },
                react_1.default.createElement(antd_1.Tree.TreeNode, { title: "Added/Removed Text", className: "screen-data-hints", key: HintsConfigFields.AddedRemovedTextHints, disabled: isCustomDevice || !isDiffCollected },
                    react_1.default.createElement(antd_1.Tree.TreeNode, { title: "Only Resource Strings", className: "screen-data-hints", key: HintsConfigFields.OnlyResourceStrings, disabled: !isDiffCollected || !isAddedRemovedTextSelected || isCustomDevice })),
                react_1.default.createElement(antd_1.Tree.TreeNode, { title: "Element Location Change Hints", className: "element-location-hints", key: HintsConfigFields.ElementLocationChangeHints, disabled: isCustomDevice || !isDiffCollected }),
                react_1.default.createElement(antd_1.Tree.TreeNode, { title: "Diff Data Hints", key: HintsConfigFields.DiffDataHints, className: "diff-data-hints", disabled: !isDiffCollected })))));
};
exports.Hints = Hints;
