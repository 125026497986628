import { Button, Input } from 'antd';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getUserFlowSets } from '../../../../../api/userJourneysAPI';
import './UJPanel.scss';

const MAX_NUM_OF_JOURNEYS_TO_SHOW = 30;

export const UJPanel = ({ appId, isAutoMode, setIsAutoMode }) => {
  const [search, setSearch] = useState('');

  const { data: ujData } = useQuery(['GetUserFlowSets', appId], () => getUserFlowSets(appId), {
    enabled: !!appId,
  });
  const userJourneys = ujData ? ujData.data : [];

  const userJourneysToShow = useMemo(() => {
    const filteredJourneys = userJourneys.filter(
      (uj) =>
        uj.user_flow &&
        `${uj.app.name} - ${uj.release.release_name} - ${uj.user_flow.name}`
          .toLowerCase()
          .includes(search.toLowerCase())
    );

    return filteredJourneys.length > MAX_NUM_OF_JOURNEYS_TO_SHOW
      ? filteredJourneys.slice(0, MAX_NUM_OF_JOURNEYS_TO_SHOW)
      : filteredJourneys;
  }, [search, userJourneys]);

  const onUJListItemClick = (ujId) => () => {
    window.open(`/#/user-journeys/${appId}/${ujId}`, '_blank');
  };

  const onAutoModeClick = () => {
    setIsAutoMode((prev) => !prev);
  };

  return (
    <div className="insights-main">
      <Input.Search onSearch={(value) => setSearch(value)} placeholder="Search User Journey" enterButton />
      <div className="insights-list">
        {userJourneysToShow.map((uj) => (
          <div key={uj.id} className="insight-wrapper" onClick={onUJListItemClick(uj.id)}>
            <div className="title">{`${uj.app.name} - ${uj.release.release_name} - ${uj.user_flow.name}`}</div>
            <div className="description">Persona: {uj.persona.title}</div>
          </div>
        ))}
      </div>
      <div className="auto-btn-container">
        <Button
          type={isAutoMode ? 'danger' : 'primary'}
          shape="round"
          icon="poweroff"
          size="large"
          onClick={onAutoModeClick}
          disabled={!appId}
        >
          {isAutoMode ? 'Stop' : 'Start'} Auto Mode
        </Button>
      </div>
    </div>
  );
};
