import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ALL_PLATFORMS } from '../../constants/platforms';
import { loadApps } from '../../redux/actions/apps';
import { getReleases } from '../../redux/actions/appsReleasesPage';
import './UserJourneys.scss';
import { AppSelect } from './components/AppSelect';
import { UJAssetList } from './components/UJAssetList/UJAssetList';
import { UJEdit } from './components/UJEdit';
import { UJList } from './components/UJList';
import { useUserFlows } from './hooks/useUserflows';

export const UserJourneysBase = ({ apps, releases, loadApps, getReleases, history }) => {
  const [curApp, setCurApp] = useState(null);
  const [curUserFlowSet, setCurUserFlowSet] = useState(null);
  const {
    userFlowTreesDraft,
    userFlowTreesPublished,
    userFlowSets,
    personas,
    userFlows,
    handleCreateUserFlow,
    handleCreateUserFlowSet,
    isCreateUserFlowSetLoading,
    handleDeleteUserFlowSet,
    handleUpdateUserFlowSet,
    handleUpdateUserFlowSetAssetIndex,
    handleDeletUserFlowSetAsset,
    handleCreateUserSetFlowAssets,
    handleUpdateUserFlowSetAsset,
    handleUserFlowSearch,
  } = useUserFlows(curApp ? curApp.id : null);
  const pathname = history.location.pathname;
  const appId = Number(pathname.split('/')[2]);
  const userFlowSetId = Number(pathname.split('/')[3]);

  useEffect(() => {
    if (!pathname.includes('/user-journeys')) {
      history.replace('/user-journeys');
    }

    if (appId && apps.length > 0) {
      setCurApp(apps.find(({ id }) => id === appId));
    }

    if (userFlowSetId && userFlowSets.length > 0) {
      setCurUserFlowSet(userFlowSets.find(({ id }) => id === userFlowSetId));
    } else {
      setCurUserFlowSet(null);
    }

    if (apps.length < 1) loadApps(ALL_PLATFORMS);
  }, [pathname, apps, userFlowSets]);

  useEffect(() => {
    if (curApp) {
      getReleases(curApp.id);
    }
  }, [curApp]);

  const handleAppSelect = (appId) => {
    history.replace(`/user-journeys/${appId}`);
  };

  const handleUserJourneyCreateClick = async () => {
    const firstUserFlowChild = userFlows.find(({ parent_id }) => !!parent_id);

    if (personas.length < 1) {
      message.error('Personas are missing, cannot create a user flow set');
      return;
    }

    if (releases.length < 1) {
      message.error('Releases are missing, cannot create a user flow set');
      return;
    }

    if (!firstUserFlowChild) {
      message.error('User Flow child is missing');
      return;
    }

    const { data } = await handleCreateUserFlowSet({
      persona_id: personas[0].id,
      release_id: releases[0].id,
      user_flow_id: firstUserFlowChild.id,
    });
    const { id } = data;
    const pathname = history.location.pathname.split('/');
    if (pathname.length < 3) pathname.push(id.toString());
    else pathname[3] = id.toString();
    history.push(pathname.join('/'));
  };

  return (
    <div className="ujp-container">
      <div className="ujp-sidebar">
        <AppSelect curApp={curApp} apps={apps} onSelect={handleAppSelect} />
        {curApp && userFlows && personas && releases && (
          <Button
            loading={isCreateUserFlowSetLoading}
            onClick={handleUserJourneyCreateClick}
            className="ujp-sidebar-create-btn"
          >
            Create User Journey +
          </Button>
        )}
        {(userFlowTreesDraft.length > 0 || userFlowTreesPublished.length > 0) && (
          <Input
            className="ujsearch"
            placeholder="Type to search"
            onChange={(e) => handleUserFlowSearch(e.target.value)}
          />
        )}
        {userFlowTreesDraft.length > 0 && <UJList title="Draft" userFlowTrees={userFlowTreesDraft} />}
        {userFlowTreesPublished.length > 0 && <UJList title="Published" userFlowTrees={userFlowTreesPublished} />}
      </div>
      <div className="ujp-main">
        {curUserFlowSet && releases && personas && userFlows && (
          <React.Fragment>
            <UJEdit
              curUserFlowSet={curUserFlowSet}
              releases={releases}
              personas={personas}
              userFlows={userFlows}
              handleCreateUserFlow={handleCreateUserFlow}
              handleDelete={handleDeleteUserFlowSet}
              handleUpdate={handleUpdateUserFlowSet}
              packageName={curApp.package}
            />
            <UJAssetList
              curUserFlowSet={curUserFlowSet}
              updateAssetIndex={handleUpdateUserFlowSetAssetIndex}
              deleteAsset={handleDeletUserFlowSetAsset}
              createAssets={handleCreateUserSetFlowAssets}
              updateAsset={handleUpdateUserFlowSetAsset}
              curApp={curApp}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  apps: state.apps.all,
  releases: state.appsReleasesPage.releases,
});

const mapDispatchToProps = {
  loadApps,
  getReleases,
};

export const UserJourneys = connect(mapStateToProps, mapDispatchToProps)(UserJourneysBase);
