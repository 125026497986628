import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ProductModal from './forms/ProductModal';
import { getAllProducts, addNewProduct, deleteProduct, updateProduct } from '../../redux/actions/products';
import ProductsTable from './Table';

const Products = ({
  // eslint-disable-next-line no-shadow
  productsData,
  getAllProducts,
  updateProduct,
  addNewProduct,
  deleteProduct,
  user,
}) => {
  const [showProductsAddModal, setShowProductsAddModal] = useState(false);
  const [showProductsEditModal, setShowProductsEditModal] = useState(false);
  const [currentProductModalEdit, setCurrentProductModalEdit] = useState(null);

  useEffect(() => {
    getAllProducts();
  }, []);

  const handleAddProductSubmit = (product) => {
    setShowProductsAddModal(false);
    addNewProduct(product);
  };

  const handleDeleteProduct = (product) => {
    deleteProduct(product);
  };
  const currentProduct = productsData.filter((x) => x.id === currentProductModalEdit);
  return (
    <div>
      <ProductModal
        visible={showProductsAddModal}
        handleHide={() => setShowProductsAddModal(false)}
        handleSubmit={handleAddProductSubmit}
        products={productsData}
      />

      {showProductsEditModal && (
        <ProductModal
          visible={showProductsEditModal}
          handleHide={() => setShowProductsEditModal(false)}
          handleSubmit={(product) => updateProduct(product)}
          product={currentProduct[0]}
          products={productsData}
        />
      )}

      <div style={{ display: 'table' }}>
        <Button
          // disabled={props.productData.length === 0}
          type="primary"
          style={{ margin: '16px 0 16px 5px' }}
          // loading={isApiRequestLoading}
          onClick={() => {
            setShowProductsAddModal(true);
          }}
          disabled={!user.isAdmin}
        >
          Add New Product
        </Button>
        <ProductsTable
          productsData={productsData}
          setProductID={setCurrentProductModalEdit}
          setShowProductsEditModal={setShowProductsEditModal}
          deleteProduct={handleDeleteProduct}
          isAdmin={user.isAdmin}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ productsData, usersPage }) => ({
  productsData: productsData.productsData,
  user: usersPage.user,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllProducts,
      addNewProduct,
      deleteProduct,
      updateProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Products);
