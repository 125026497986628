import React, { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import { Auth } from 'aws-amplify';
import { message } from 'antd';

const ALLOWED_CONTROL_METHODS = [
  'running',
  'swipe',
  'method-swipe',
  'back',
  'method-back',
  'go_home',
  'method-go_home',
  'press_return_key',
  'method-press_return_key',
];
function FarmerWebSocketConnection({
  webSocketUrl,
  connectStatus,
  connectStatusChange,
  farDevListOfAppsChange,
  lastEdgeDataFarmerChange,
  lastScreenDataChange,
  handleGetScreenData,
  fetchingScreenShotDataChange,
  farmerDeviceState,
  farmerDeviceStateChange,
  farmerErrorChange,
  deviceID,
  farmerDeviceChange,
  userMistakeWarningChange,
}) {
  const farmerSocket = useRef(null);
  const [controlState, controlStateChange] = useState(false);
  const [displayState, displayStateChange] = useState(false);

  useEffect(() => {
    farmerDeviceStateChange(controlState && displayState);
  }, [controlState, displayState]);
  const openSocket = async () => {
    const currentAWSSession = await Auth.currentSession();
    const token = currentAWSSession.idToken.jwtToken;

    farmerSocket.current = io(webSocketUrl, {
      auth: {
        token,
      },
    });

    const sock = farmerSocket.current;

    sock.on('connect', () => {
      const engine = sock.io.engine;
      console.log(engine.transport.name); // in most cases, prints "polling"

      engine.once('upgrade', () => {
        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
        console.log(engine.transport.name); // in most cases, prints "websocket"
        connectStatusChange('connected');
      });

      engine.on('packet', ({ type, data }) => {
        // called for each packet received
      });

      engine.on('packetCreate', ({ type, data }) => {
        // called for each packet sent
      });

      engine.on('drain', () => {
        // called when the write buffer is drained
      });

      engine.on('close', (reason) => {
        // called when the underlying connection is closed
      });
    });
    sock.on('update', async (payload) => {
      const parsedPayload = JSON.parse(payload.message.payload);
      const payloadChannel = payload.message.channel;
      // debugger
      if (payloadChannel === 'wadb') {
        // message.info(`WS - ${parsedPayload.method}  ${parsedPayload.response ? `- ${parsedPayload.response}` : ''} `, 3)
        message.info(`WS - ${parsedPayload.method} `);
        if (['back', 'press_return_key', 'swipe', 'text'].includes(parsedPayload.method)) {
          lastEdgeDataFarmerChange(parsedPayload);
        }
      }
      if (parsedPayload.method === 'get_installed_packages') {
        farDevListOfAppsChange(parsedPayload.response);
      }
      if (payloadChannel === `device_commands_${deviceID}`) {
        if (parsedPayload.response && parsedPayload.response.events) {
          parsedPayload.response.events.forEach((evt) => {
            if (evt.evtype && typeof evt.evtype === 'string' && evt.evtype !== '') {
              userMistakeWarningChange(evt.evtype.split('Exception: ')[1]);
            }
          });
        }

        if (parsedPayload.error) {
          if (parsedPayload.cmd === 'asl') {
            farmerErrorChange('Error happens on farmer');
          } else {
            farmerErrorChange(parsedPayload.error.message);
          }
        }
        if (parsedPayload.method === 'get_screen_data') {
          lastScreenDataChange(parsedPayload.context);

          fetchingScreenShotDataChange(!!parsedPayload.context_upload_ts);
        }
      }
      if (parsedPayload.method === 'start_package') {
        // handleGetScreenData(true)
      }
      if (payloadChannel === 'devices' && parsedPayload.id === deviceID) {
        farmerDeviceChange(parsedPayload);
        // console.log('update-device-data')
        // console.log(payloadChannel)
        // console.log(parsedPayload)
      }

      if (payloadChannel === 'workers') {
        if (parsedPayload.role === 'display') {
          controlStateChange(parsedPayload.state === 'running');
        }
        if (parsedPayload.role === 'control') {
          displayStateChange(ALLOWED_CONTROL_METHODS.includes(parsedPayload.state));
        }
      }
    });
  };

  useEffect(() => {
    openSocket();
    return () => {
      farmerSocket.current.close();
    };
  }, []);

  return <div className={connectStatus === 'connected' ? 'connected' : 'not-connected'}>{connectStatus}</div>;
}

export default FarmerWebSocketConnection;
