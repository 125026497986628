import * as AT from './../actionTypes';
import {
  getAppsWithScreenChangesAPI,
  getScreenChangesForAppAPI,
  getGraphDataForAppAPI,
  getVersionGraphDataForAppAPI,
  getAllAppsWithGraphsAPI,
  createComplaintForGraphAPI,
  deleteComplaintForGraphAPI,
} from '../actions/UIViewerPage';
import { createScreenChangeInsightAPI, getRootNodeByInsightNodeIDAPI } from '../actions/insightPage';

const UIViewerMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_APPS_WITH_SCREEN_CHANGES: {
        dispatch(getAppsWithScreenChangesAPI());
        return next(action);
      }
      case AT.GET_SCREEN_CHANGES_FOR_APP: {
        dispatch(getScreenChangesForAppAPI({ app_id: action.payload.app_id }));
        return next(action);
      }
      case AT.GET_VERSION_GRAPH_DATA_FOR_APP: {
        const { platform, package_name } = action.payload;
        dispatch(getVersionGraphDataForAppAPI({ platform, package_name }));
        return next(action);
      }
      case AT.GET_GRAPH_DATA_FOR_APP: {
        const { graph_id } = action.payload;
        dispatch(getGraphDataForAppAPI({ graph_id }));
        return next(action);
      }
      case AT.GET_ALL_APPS_WITH_GRAPHS: {
        dispatch(getAllAppsWithGraphsAPI());
        return next(action);
      }
      case AT.CREATE_SCREEN_CHANGE_INSIGHT: {
        const { insight, prevImageAsset, currentImageAsset } = action.payload;
        dispatch(
          createScreenChangeInsightAPI(
            {
              packageName: insight.packageName,
              platform: insight.app.platform,
              localId: Math.floor(Date.now() / 1000),
              thisVersion: insight.version_code,
              node_id: insight.node_id,
            },
            prevImageAsset,
            currentImageAsset,
            insight
          )
        );
        return next(action);
      }

      case AT.CREATE_COMPLAINT_FOR_GRAPH: {
        dispatch(createComplaintForGraphAPI(action.payload));
        return next(action);
      }
      case AT.DELETE_COMPLAINT_FOR_GRAPH: {
        dispatch(deleteComplaintForGraphAPI(action.payload.graph_id));
        return next(action);
      }
      case AT.GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID: {
        dispatch(getRootNodeByInsightNodeIDAPI(action.payload));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default UIViewerMiddleware;
