/* eslint-disable react/prop-types */
import React from 'react';
import { Button, Table, Typography } from 'antd';

const { Paragraph } = Typography;

export default ({
  abTests,
  isLoading,
  currentPage,
  pageSize,
  abTestsTotal,
  getAbTests,
  updAbTest,
  setShowAbTestEditModal,
  setCurrentAbTestModalEdit,
  handleDeleteABTest,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: (value, record) => (
        <div
          style={{
            display: 'flex',
            width: 'fit-content',
          }}
        >
          <span>{value}</span>
          <Button
            style={{ margin: '0 3px' }}
            type="small"
            icon="edit"
            onClick={(e) => {
              e.stopPropagation();
              setShowAbTestEditModal(true);
              setCurrentAbTestModalEdit(record.id);
            }}
          />
          <Button type="danger small" icon="delete" onClick={() => handleDeleteABTest(record)} />
        </div>
      ),
    },
    {
      title: 'App Name',
      dataIndex: 'app_name',
    },
    {
      title: 'Package',
      dataIndex: 'package',
    },
    {
      title: 'Internal Name',
      dataIndex: 'internal_name',
    },
    {
      title: 'Start Release Date',
      dataIndex: 'start_release_date',
    },
    {
      title: 'Start Release Code',
      dataIndex: 'start_release_code',
    },
    {
      title: 'Start Release Name',
      dataIndex: 'start_release_name',
    },
    {
      title: 'End Release Date',
      dataIndex: 'end_release_date',
    },
    {
      title: 'End Release Code',
      dataIndex: 'end_release_code',
    },
    {
      title: 'End Release Name',
      dataIndex: 'end_release_name',
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Where',
      dataIndex: 'text_where',
      render: (value, record) => (
        <Paragraph editable={{ onChange: (x) => updAbTest(record.id, { ...record, text_where: x }) }}>
          {value}
        </Paragraph>
      ),
    },
    {
      title: 'What',
      dataIndex: 'text_what',
      render: (value, record) => (
        <Paragraph editable={{ onChange: (x) => updAbTest(record.id, { ...record, text_what: x }) }}>{value}</Paragraph>
      ),
    },
    {
      title: 'Comment',
      dataIndex: 'text_comment',
      render: (value, record) => (
        <Paragraph editable={{ onChange: (x) => updAbTest(record.id, { ...record, text_comment: x }) }}>
          {value}
        </Paragraph>
      ),
    },
    {
      title: 'Insights',
      dataIndex: 'insights',
      render: (value, record) => (
        <ul>
          {value.map((x) => (
            <li>
              <a href={`#/intelligence/${x.app_id}/${x.id}`}>{x.title}</a>
            </li>
          ))}
        </ul>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={abTests}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 700,
        }}
        pagination={{
          pageSize,
          total: abTestsTotal,
          current: currentPage,
          onChange: (page, pageSize) => getAbTests(page, pageSize),
        }}
      />
    </div>
  );
};
