import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  abTests: [],
  abTestsTotal: 0,
  currentPage: 1,
  pageSize: 120,
  apps: [],
  filterAppId: undefined,
  filterHasInsights: null,
  searchQuery: '',
  currentABTestInsightPage: null,
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_AB_TESTS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_AB_TESTS_SUCCESS: {
      const { data, meta, page, pageSize, appId, searchQuery, hasInsights } = action.payload;
      return {
        ...state,
        abTests: data,
        abTestsTotal: meta.num_total,
        isLoading: false,
        currentPage: page,
        pageSize,
        filterAppId: appId,
        searchQuery,
        filterHasInsights: hasInsights,
      };
    }

    case actionTypes.GET_AB_TEST_DATA: {
      return {
        ...state,
        currentABTestInsightPage: null,
      };
    }
    case actionTypes.GET_AB_TEST_DATA_SUCCESS: {
      return {
        ...state,
        currentABTestInsightPage: action.payload.data,
      };
    }
    case actionTypes.GET_AB_TESTS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case actionTypes.UPD_AB_TEST: {
      return {
        ...state,
        error: null,
      };
    }
    case actionTypes.UPD_AB_TEST_SUCCESS: {
      const { abTestId, abTest } = action.payload;
      return {
        ...state,
        abTests: state.abTests.map((x) => (x.id === abTestId ? abTest : x)),
        currentABTestInsightPage: [abTest],
      };
    }
    case actionTypes.UPD_AB_TEST_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case actionTypes.GET_APPS_DATA_SUCCESS: {
      return {
        ...state,
        apps: action.payload.appsData.map((x) => ({
          id: x.id,
          package_name: x.package_name,
          app_name: x.app_name,
          platform: x.platform,
        })),
      };
    }
    case actionTypes.ADD_AB_TEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.ADD_AB_TEST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        abTests: [{ ...action.payload.data }, ...state.abTests],
      };
    }
    case actionTypes.ADD_AB_TEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case actionTypes.DELETE_AB_TEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.DELETE_AB_TEST_SUCCESS: {
      const abTests = [...state.abTests.filter((abTest) => abTest.id !== action.payload)];
      return {
        ...state,
        isLoading: false,
        abTests,
      };
    }
    case actionTypes.DELETE_AB_TEST_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
