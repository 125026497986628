import {
  getAllPersonasAPI,
  addPersonaAPI,
  updPersonaAPI,
  addPersonaOrganizationAPI,
  delPersonaOrganizationAPI,
  delProductSettingAPI,
  addPersonaProductAPI,
  updPersonaProductAPI,
  delPersonaProductAPI,
  addProductSettingAPI,
  updPaymentDetailAPI,
  delPaymentMethodAPI,
} from '../actions/personas';
import * as AT from '../actionTypes';

const personasMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_PERSONAS: {
        dispatch(getAllPersonasAPI(action.payload.appId));
        return next(action);
      }
      case AT.ADD_PERSONA: {
        dispatch(addPersonaAPI(action.payload.data));
        return next(action);
      }
      case AT.UPD_PERSONA: {
        dispatch(updPersonaAPI(action.payload.personaId, action.payload.data));
        return next(action);
      }
      case AT.ADD_PERSONA_ORGANIZATION: {
        dispatch(addPersonaOrganizationAPI(action.payload.personaId, action.payload.data));
        return next(action);
      }
      case AT.DEL_PERSONA_ORGANIZATION: {
        dispatch(delPersonaOrganizationAPI(action.payload.personaId, action.payload.organizationId));
        return next(action);
      }
      case AT.ADD_PRODUCT_SETTING: {
        dispatch(addProductSettingAPI(action.payload.personaId, action.payload.productId, action.payload.data));
        return next(action);
      }
      case AT.DEL_PRODUCT_SETTING: {
        dispatch(delProductSettingAPI(action.payload.personaId, action.payload.productId, action.payload.settingId));
        return next(action);
      }
      case AT.ADD_PERSONA_PRODUCT: {
        dispatch(addPersonaProductAPI(action.payload.personaId, action.payload.data));
        return next(action);
      }
      case AT.UPD_PERSONA_PRODUCT: {
        dispatch(updPersonaProductAPI(action.payload.personaId, action.payload.productId, action.payload.data));
        return next(action);
      }
      case AT.DEL_PERSONA_PRODUCT: {
        dispatch(delPersonaProductAPI(action.payload.personaId, action.payload.productId));
        return next(action);
      }
      case AT.UPDATE_PAYMENT_DETAIL: {
        dispatch(updPaymentDetailAPI(action.payload.personaId, action.payload.personaProductId, action.payload.data));
        return next(action);
      }
      case AT.DEL_PAYMENT_DETAIL: {
        dispatch(delPaymentMethodAPI(action.payload.personaId, action.payload.personaProductId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default personasMiddleware;
