import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';

const { Option } = AutoComplete;

const AbTest = ({ getAbTests, insight, abTests, setAbTestID, currentABTestInsightPage }) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getAbTests(searchQuery);
  }, []);

  const internals = Array.from(new Set(abTests.map((test) => test.internal_name)));
  const onSearch = (value) => {
    setSearchQuery(value);
    getAbTests(value);
  };

  const onSelect = (value) => {
    const a = abTests.find((test) => test.internal_name === value);
    setAbTestID(a.id);
  };

  return (
    <div className="ten wide field" style={{ marginLeft: '1rem' }}>
      <label>Internal Name</label>
      <AutoComplete
        style={{
          border: '1px rgba(34,36,38,.15) solid',
          borderRadius: '.28571429rem',
          width: '20rem',
        }}
        showSearch
        placeholder="Search by Internal Name..."
        onSelect={onSelect}
        onSearch={(value) => onSearch(value, insight.app_id)}
        defaultValue={currentABTestInsightPage ? currentABTestInsightPage.internal_name : ''}
      >
        {internals.map((internal) => (
          <Option key={internal} value={internal}>
            {internal}
          </Option>
        ))}
      </AutoComplete>
    </div>
  );
};

export default AbTest;
