import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';
import { DEFAULT_PLATFORM } from '../../constants/platforms';

// GET ALL USERS
export const getAllUsers = () => ({
  type: AT.GET_ALL_USERS,
});

export const getAllUsersAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'user/get_all_users',
    label: NETWORK_LABELS.GET_ALL_USERS,
    onSuccess: (body, dispatch) => dispatch(getAllUsersSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllUsersFailed(error.message)),
  },
});

export const getAllUsersSuccess = (data) => ({
  type: AT.GET_ALL_USERS_SUCCESS,
  payload: { data },
});

export const getAllUsersFailed = (errorStatus) => ({
  type: AT.GET_ALL_USERS_FAILED,
  payload: { errorStatus },
});

// ADD USER
export const addUser = (user) => ({
  type: AT.ADD_USER,
  payload: { user },
});

export const addUserAPI = (user) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'user/add',
    method: 'POST',
    data: { user },
    label: NETWORK_LABELS.ADD_USER,
    onSuccess: (body, dispatch) => dispatch(addUserSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addUserFailed(error)),
  },
});

export const addUserSuccess = (data) => ({
  type: AT.ADD_USER_SUCCESS,
  payload: { data },
});

export const addUserFailed = (error) => ({
  type: AT.ADD_USER_FAILED,
  payload: { error },
});

// INIT USER MSG(api status)
export const initUserMsg = () => ({
  type: AT.INIT_USER_MSG,
});

// GET USER
export const getUser = (user) => ({
  type: AT.GET_USER,
  payload: { user },
});

// DELETE USER
export const deleteUser = (user) => ({
  type: AT.DELETE_USER,
  payload: { user },
});

export const deleteUserAPI = (user) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${user.id}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DELETE_USER,
    onSuccess: (body, dispatch) => dispatch(deleteUserSuccess(body)),
    onFailure: (error, dispatch) => dispatch(deleteUserFailed(error.response.text)),
  },
});

export const deleteUserSuccess = (data) => ({
  type: AT.DELETE_USER_SUCCESS,
  payload: { data },
});

export const deleteUserFailed = (errorStatus) => ({
  type: AT.DELETE_USER_FAILED,
  payload: { errorStatus },
});

// DELETE USER APP
export const deleteUserApp = (userID, appID) => ({
  type: AT.DELETE_USER_APP,
  payload: { userID, appID },
});

export const deleteUserAppAPI = (userID, appID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/apps/${userID}`,
    method: 'DELETE',
    data: { appID },
    label: NETWORK_LABELS.DELETE_USER_APP,
    onSuccess: (body, dispatch) => dispatch(deleteUserAppSuccess(body)),
    onFailure: (error, dispatch) => dispatch(deleteUserAppFailed(error.response.text)),
  },
});

export const deleteUserAppSuccess = (data) => ({
  type: AT.DELETE_USER_APP_SUCCESS,
  payload: { data },
});

export const deleteUserAppFailed = (errorStatus) => ({
  type: AT.DELETE_USER_APP_FAILED,
  payload: { errorStatus },
});

// Remove user app
export const removeUserApp = ({ app, user }) => ({
  type: AT.REMOVE_USER_APP,
  payload: { app, user },
});

// Add user app
export const addUserApp = ({ app, user }) => ({
  type: AT.ADD_USER_APP,
  payload: { app, user },
});

// Edit user apps
export const editUserApps = (userID, userApps) => ({
  type: AT.EDIT_USER_APPS,
  payload: { userID, userApps },
});

export const editUserAppsAPI = (userID, userApps) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/apps/${userID}`,
    method: 'POST',
    data: { userApps },
    label: NETWORK_LABELS.EDIT_USER_APPS,
    onSuccess: (body, dispatch) => dispatch(editUserAppsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(editUserAppsFailed(error)),
  },
});

export const editUserAppsSuccess = (data) => ({
  type: AT.EDIT_USER_APPS_SUCCESS,
  payload: { data },
});

export const editUserAppsFailed = (errorStatus) => ({
  type: AT.EDIT_USER_APPS_FAILED,
  payload: { errorStatus },
});

// Edit user details
export const editUserDetails = (user) => ({
  type: AT.EDIT_USER_DEATILS,
  payload: { user },
});

export const editUserDetailsAPI = (user) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'user/edit_user_details',
    method: 'POST',
    data: { user },
    label: NETWORK_LABELS.EDIT_USER_APPS,
    onSuccess: (body, dispatch) => dispatch(editUserDetailsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(editUserDetailsFailed(error)),
  },
});

export const editUserDetailsSuccess = (data) => ({
  type: AT.EDIT_USER_DEATILS_SUCCESS,
  payload: { data },
});

export const editUserDetailsFailed = (errorStatus) => ({
  type: AT.EDIT_USER_DEATILS_FAILED,
  payload: { errorStatus },
});

// Get User apps
export const getUserApps = (user) => ({
  type: AT.GET_USER_APPS,
  payload: { user },
});

export const getUserAppsAPI = (user) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/apps/${user.id}`,
    label: NETWORK_LABELS.GET_USER_APPS,
    onSuccess: (body, dispatch) => dispatch(getUserAppsSuccess(body, user.id)),
    onFailure: (error, dispatch) => dispatch(getUserAppsFailed(error)),
  },
});

export const getUserAppsSuccess = (data, userID) => ({
  type: AT.GET_USER_APPS_SUCCESS,
  payload: { apps: data, userID },
});

export const getUserAppsFailed = (errorStatus) => ({
  type: AT.GET_USER_APPS_FAILED,
  payload: { errorStatus },
});

// Get All apps
export const getAllApps = (platform = DEFAULT_PLATFORM) => ({
  type: AT.GET_ALL_APPS,
  payload: {
    platform,
  },
});

export const getAllAppsAPI = (platform = DEFAULT_PLATFORM) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/list?platform=${platform}`,
    label: NETWORK_LABELS.GET_USER_APPS,
    onSuccess: (body, dispatch) => dispatch(getAllAppsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllAppsFailed(error)),
  },
});

export const getAllAppsSuccess = (apps) => ({
  type: AT.GET_ALL_APPS_SUCCESS,
  payload: { apps },
});

export const getAllAppsFailed = (errorStatus) => ({
  type: AT.GET_ALL_APPS_FAILED,
  payload: { errorStatus },
});

// Change user password

export const changeUserPassword = (userID, password) => ({
  type: AT.SET_USER_PASSWORD,
  payload: { userID, password },
});

export const changeUserPasswordAPI = (userID, password) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/password`,
    method: 'PUT',
    data: { password },
    label: NETWORK_LABELS.SET_USER_PASSWORD,
    onSuccess: (body, dispatch) => dispatch(changeUserPasswordSuccess(body)),
    onFailure: (error, dispatch) => dispatch(changeUserPasswordFailed(error)),
  },
});

export const changeUserPasswordSuccess = (data) => ({
  type: AT.SET_USER_PASSWORD_SUCCESS,
  payload: { data },
});

export const changeUserPasswordFailed = (errorStatus) => ({
  type: AT.SET_USER_PASSWORD_FAILED,
  payload: { errorStatus },
});

export const setUser = (user) => ({
  type: AT.SET_USER,
  payload: user,
});

export const getProductsForUser = (userID) => ({
  type: AT.GET_PRODUCTS_FOR_USER,
  payload: userID,
});

export const getProductsForUserAPI = (userID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/products`,
    method: 'GET',
    label: NETWORK_LABELS.GET_PRODUCTS_FOR_USER,
    onSuccess: (body, dispatch) => dispatch(getProductsForUserSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getProductsForUserFailed(error)),
  },
});

export const getProductsForUserSuccess = (data) => ({
  type: AT.GET_PRODUCTS_FOR_USER_SUCCESS,
  payload: data,
});

export const getProductsForUserFailed = (error) => ({
  type: AT.GET_PRODUCTS_FOR_USER_FAILED,
  payload: { error },
});

export const getUserDetails = (userID) => ({
  type: AT.GET_USER_DETAILS,
  payload: userID,
});

export const getUserDetailsAPI = (userID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/preferences/details`,
    method: 'GET',
    label: NETWORK_LABELS.GET_USER_DETAILS,
    onSuccess: (body, dispatch) => dispatch(getUserDetailsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getUserDetailsFailed(error)),
  },
});

export const getUserDetailsSuccess = (data) => ({
  type: AT.GET_USER_DETAILS_SUCCESS,
  payload: data,
});

export const getUserDetailsFailed = (error) => ({
  type: AT.GET_USER_DETAILS_FAILED,
  payload: { error },
});
