import React, { useState } from 'react';
import { Rect, Text } from 'react-konva';
import { HintTypeToMarkerType } from 'components/workbench_v2/hooks/useWorkbenchSocket';
import { MarkerTypes } from 'components/workbench_v2/utils/consts';

export const Rectangle = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = (e) => {
    if (props.type === MarkerTypes.translation) {
      const container = e.target.getStage().container();
      container.style.cursor = 'pointer';
    }

    setIsHovering(true);
  };

  const handleMouseLeave = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = 'default';
    setIsHovering(false);
  };

  const handleChange = (e) => {
    const shape = e.target;
    const width = shape.width() > 1 ? shape.width() : 100;
    const height = shape.height() > 1 ? shape.height() : 100;
    props.onTransform({
      x: shape.x(),
      y: shape.y(),
      width: width * shape.scaleX(),
      height: height * shape.scaleY(),
      rotation: shape.rotation(),
      type: props.type,
    });
  };

  let fill = null;
  let stroke = '#2196f3';
  let opacity = null;

  if (props.type === MarkerTypes.blur) {
    stroke = null;
    fill = 'black';
    opacity = 0.5;
  }

  if (props.type === MarkerTypes.translation) {
    stroke = '#ff9900';
  }

  if (props.hintType === HintTypeToMarkerType.diff_hint) {
    fill = null;
    stroke = '#21f33d';
    opacity = null;
  }

  if (props.hintType === HintTypeToMarkerType.element_bounds_change) {
    fill = null;
    stroke = 'red';
    opacity = null;
  }

  return (
    <React.Fragment>
      <Rect
        x={props.x}
        y={props.y}
        width={props.width}
        height={props.height}
        // force no scaling
        // otherwise Transformer will change it
        scaleX={1}
        scaleY={1}
        stroke={stroke}
        fill={fill}
        opacity={opacity}
        strokeWidth={3}
        name={props.name}
        onMouseDown={props.onMouseDown}
        {...(!props.isHint && props.type !== MarkerTypes.translation
          ? {
              onDragEnd: handleChange,
              onTransformEnd: handleChange,
              draggable: true,
            }
          : null)}
        cornerRadius={5}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      {isHovering && props.type === MarkerTypes.translation && (
        <Text
          x={props.x}
          y={props.y}
          fontSize={16}
          text={props.text}
          stroke="black"
          strokeWidth={1}
          align="center"
          sceneFunc={(ctx, shape) => {
            ctx.fillStyle = 'white';
            ctx.fillRect(0, 0, shape.width(), shape.height());
            shape._sceneFunc(ctx);
          }}
        />
      )}
    </React.Fragment>
  );
};
