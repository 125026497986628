import React from 'react';

const VIDEO_WIDTH = 140;
const VIDEO_HEGIHT = 310;

export const VideoPlayer = ({ videoUrl, width = VIDEO_WIDTH, height = VIDEO_HEGIHT }) => {
  return (
    <div>
      <video controls style={{ width, height }} src={videoUrl}>
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
