import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const apiRequestSettingsFailed = (error) => ({
  type: AT.API_REQUEST_SETTINGS_FAILED,
  payload: { error },
});

// Get all settings

export const getAllSettings = () => ({
  type: AT.GET_SETTINGS,
});

export const getAllSettingsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'personas/settings/all',
    label: NETWORK_LABELS.GET_SETTINGS,
    onSuccess: (body, dispatch) => dispatch(getAllSettingsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestSettingsFailed(error)),
  },
});

export const getAllSettingsSuccess = (data) => ({
  type: AT.GET_SETTINGS_SUCCESS,
  payload: { settings: data.data },
});

// Update settings

export const updSetting = (settingId, data) => ({
  type: AT.UPD_SETTING,
  payload: {
    settingId,
    data,
  },
});

export const updSettingAPI = (settingId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/settings/${settingId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_SETTING,
    onSuccess: (body, dispatch) => dispatch(updSettingSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestSettingsFailed(error)),
  },
});

export const updSettingSuccess = (body) => ({
  type: AT.UPD_SETTING_SUCCESS,
  payload: { data: body.data },
});

// Create setting

export const addSetting = (data) => ({
  type: AT.ADD_SETTING,
  payload: { data },
});

export const addSettingAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'personas/settings',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_SETTING,
    onSuccess: (body, dispatch) => dispatch(addSettingSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestSettingsFailed(error)),
  },
});

export const addSettingSuccess = (body) => ({
  type: AT.ADD_SETTING_SUCCESS,
  payload: { data: body.data },
});

// Delete setting

export const delSetting = (settingId) => ({
  type: AT.DEL_SETTING,
  payload: { settingId },
});

export const delSettingAPI = (settingId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/settings/${settingId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_SETTING,
    onSuccess: (body, dispatch) => dispatch(delSettingSuccess(settingId)),
    onFailure: (error, dispatch) => dispatch(apiRequestSettingsFailed(error)),
  },
});

export const delSettingSuccess = (settingId) => ({
  type: AT.DEL_SETTING_SUCCESS,
  payload: { settingId },
});
