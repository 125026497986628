/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Modal, Table, Checkbox, Select, Button, Popconfirm, Icon, InputNumber } from 'antd';
import { isEqual } from 'lodash';
import { APP_PERSONA_PERM_INSIGHT_TYPES } from "../../../constants/insightType";

const { Option } = Select;

export default function EditUserAppsModal({
  modalVisible,
  setIsVisible,
  selectedUser,
  allApps,
  allUsers,
  getAppsForUser,
  saveAppsForUser,
  deleteAppForUser,
  status,
  personas,
}) {
  const isFetchingData = status !== '';
  const [appList, setAppList] = useState([]);
  const [selectedAppIDS, setSelectedAppIDS] = useState([]);
  const [dataSource, setDataSource] = useState(null);
  const [selectedSourceUser, setSelectedSourceUser] = useState();
  const [sourceUserApps, setSourceUserApps] = useState([]);
  const [mergedSourceUserApps, setMergedSourceUserApps] = useState(false);

  useEffect(() => {
    // Set data source only once after fetching the user apps
    if (selectedUser && selectedUser.apps && !dataSource) {
      setDataSource(selectedUser.apps);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (selectedSourceUser) {
      const selectedUserApps = new Set((dataSource || []).map((app) => app.id));
      const selectedSourceUserApps = allUsers.find((user) => user.id === selectedSourceUser).apps;
      if (selectedSourceUserApps) {
        setSourceUserApps(
          selectedSourceUserApps
            .filter((app) => !selectedUserApps.has(app.id))
            .map((app) => ({
              ...app,
              mergedFromSrcUser: true,
            }))
        );
      } else {
        setSourceUserApps([]);
      }
    } else {
      setSourceUserApps([]);
    }
  }, [selectedSourceUser, allUsers]);

  useEffect(() => {
    if (mergedSourceUserApps && sourceUserApps.length === 0) {
      setDataSource(selectedUser.apps);
      setMergedSourceUserApps(false);
    }
  }, [sourceUserApps]);

  const columns = [
    {
      title: 'App Name',
      dataIndex: 'name',
      key: 'name',
      render: (app, record) => (
        <div style={{ display: 'flex' }}>
          <img style={{ width: '25px', marginRight: '2px' }} src={record.icon} alt="app icon" />
          <div>{app}</div>
        </div>
      ),
    },
    {
      title: 'App Package Name',
      dataIndex: 'package',
      key: 'package',
    },
    {
      title: 'App Platform',
      dataIndex: 'platform',
      key: 'platform',
    },
    {
      title: 'App Licenses',
      dataIndex: 'licences',
      key: 'licencesData',
      render: (text, record) => (
        <Select
          value={
            record.performance && record.experiments
              ? 'both'
              : record.performance
                ? 'performance'
                : record.experiments
                  ? 'experiments'
                  : ''
          }
          style={{ minWidth: '16rem' }}
          onChange={(event) =>
            setDataSource(
              dataSource.map((app) => {
                const a = { ...app };
                if (app.id === record.id) {
                  a.performance = true;
                  a.experiments = true;
                  if (event === 'experiments') {
                    a.performance = false;
                  } else if (event === 'performance') {
                    a.experiments = false;
                  }
                }
                return a;
              })
            )
          }
        >
          <Select.Option value="experiments">Experiments (Intelligence)</Select.Option>
          <Select.Option value="performance">Performance</Select.Option>
          <Select.Option value="both">Both</Select.Option>
        </Select>
      ),
    },
    {
      title: 'Personas',
      dataIndex: 'personas',
      key: 'personas',
      render: (text, record) => record.experiments && (
        <div>
          <Select
            placeholder="Personas"
            onChange={(value) => setDataSource(dataSource.map(
              app => (app.id === record.id)
                ? { ...app, personas: [{ id: value, insight_types: [...APP_PERSONA_PERM_INSIGHT_TYPES] }, ...app.personas] }
                : app
            ))}
            showSearch
            value={null}
            filterOption={(input, option) => option.props.children.toString().toLowerCase().includes(input.toLowerCase())}
          >
            {personas.filter(x => !record.personas.map(p => p.id).includes(x.id)).map((persona) => (
              <Select.Option value={persona.id} key={persona.id}>
                #{persona.id}
                <span style={{ margin: '0px 2px' }} className={`flag-icon flag-icon-${persona.location.toLowerCase()}`} />
                {persona.title}
              </Select.Option>
            ))}
          </Select>
          {record.personas.map(persona => (
            <div>
              <Button
                type="primary"
                shape="round"
                icon="close"
                size="small"
                onClick={() => setDataSource(dataSource.map(
                  app => (app.id === record.id)
                    ? { ...app, personas: app.personas.filter(x => x.id !== persona.id) }
                    : app
                ))}
              />
              <span>
                #{persona.id}
                <span style={{ margin: '0px 2px' }} className={`flag-icon flag-icon-${personas.find(x => x.id === persona.id).location.toLowerCase()}`} />
                {personas.find(x => x.id === persona.id).title}
              </span>
              <Select
                mode="multiple"
                placeholder="Insight types"
                onChange={value => setDataSource(dataSource.map(
                  app => (app.id === record.id)
                    ? { ...app, personas: app.personas.map(x => x.id === persona.id ? { ...x, insight_types: [...value] } : x) }
                    : app
                ))}
                style={{ width: '25rem' }}
                value={persona.insight_types}
              >
                {APP_PERSONA_PERM_INSIGHT_TYPES.map((insight_type) => (
                  <Select.Option value={insight_type} key={insight_type}>
                    {insight_type}
                  </Select.Option>
                ))}
              </Select>
            </div>
          ))}
        </div>),
    },
    {
      title: 'Benchmarking Order',
      dataIndex: 'benchmarking_preference_index',
      key: 'benchmarking_preference_index',
      render: (text, record) => (
        <InputNumber
          value={record.benchmarking_preference_index}
          style={{ width: 'flex' }}
          min={0}
          max={99}
          maxLength={2}
          allowClear
          size="small"
          onChange={(value) =>
            (Number.isInteger(value) || !value) &&
            setDataSource(
              dataSource.map((app) => {
                const a = { ...app };
                if (app.id === record.id) {
                  a.benchmarking_preference_index = value;
                }
                return a;
              })
            )
          }
        />
      ),
    },
    {
      title: 'Merge?',
      dataIndex: 'mergedFromSrcUser',
      key: 'mergedFromSrcUser',
      render: (text, record) => (
        <Checkbox
          disabled={record.mergedFromSrcUser === undefined}
          checked={record.mergedFromSrcUser}
          onChange={(event) =>
            setDataSource(
              dataSource.map((app) => {
                const a = { ...app };
                if (app.id === record.id) {
                  a.mergedFromSrcUser = event.target.checked;
                }
                return a;
              })
            )
          }
        />
      ),
    },
    {
      title: 'Delete App',
      key: 'deleteApp',
      render: (text, record) => (
        <Popconfirm
          title="Are you sure?"
          icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
          onConfirm={() => {
            handleDeleteAppForUser(record.id);
          }}
        >
          <Button type="danger" icon="delete">
            Delete App
          </Button>
        </Popconfirm>
      ),
    },
  ];

  const handleSourceUserChange = (value) => {
    if (value !== undefined) {
      getAppsForUser(value);
      setSelectedSourceUser(value);
    } else {
      setSelectedSourceUser();
    }
  };

  const handleMergeSourceUserApps = () => {
    if (!mergedSourceUserApps && sourceUserApps.length > 0) {
      setDataSource([...dataSource].concat(sourceUserApps));
      setMergedSourceUserApps(true);
    } else {
      setDataSource(selectedUser.apps);
      setMergedSourceUserApps(false);
    }
  };

  const handleSearch = (value) => {
    if (value && value.length >= 3) {
      setAppList(
        allApps.filter(
          (app) =>
            app.package &&
            app.name &&
            app.id &&
            !dataSource.map((a) => a.app_id).includes(app.id) &&
            (app.package.toLowerCase().includes(value.toLowerCase()) ||
              app.name.toLowerCase().includes(value.toLowerCase()) ||
              String(app.id).toLowerCase().includes(value.toLowerCase()))
        )
      );
    } else {
      setAppList([]);
    }
  };

  const handleChange = (value) => {
    setSelectedAppIDS(value);
  };

  const handleAddAppsForUser = () => {
    setDataSource([
      ...dataSource,
      ...allApps
        .filter((app) => selectedAppIDS.includes(String(app.id)))
        .map((app) => ({
          ...app,
          experiments: true,
        })),
    ]);
    setSelectedAppIDS([]);
  };

  const handleDeleteAppForUser = (appID) => {
    setDataSource(dataSource.filter((app) => app.id !== appID));
    deleteAppForUser(selectedUser.id, appID);
  };

  const handleSaveAppsForUser = () => {
    saveAppsForUser(
      selectedUser.id,
      dataSource.filter((app) => app.mergedFromSrcUser === undefined || app.mergedFromSrcUser === true)
    );
    setIsVisible(false);
    setAppList([]);
    setSelectedAppIDS([]);
    setDataSource(null);
    setSelectedSourceUser();
    setSourceUserApps([]);
    setMergedSourceUserApps(false);
  };

  return (
    <Modal
      width="fit-content"
      title="Edit user apps"
      centered
      visible={modalVisible}
      okButtonProps={{ disabled: isEqual(dataSource, selectedUser && selectedUser.apps) }}
      onOk={handleSaveAppsForUser}
      onCancel={() => {
        setIsVisible(false);
        setAppList([]);
        setSelectedAppIDS([]);
        setDataSource(null);
        setSelectedSourceUser();
        setSourceUserApps([]);
        setMergedSourceUserApps(false);
      }}
    >
      <div className="new-app-search">
        <Select
          mode="multiple"
          value={selectedAppIDS}
          onSearch={handleSearch}
          onChange={handleChange}
          placeholder="Select apps"
          notFoundContent={null}
          filterOption={false}
          disabled={isFetchingData}
        >
          {appList.map((app) => (
            <Option key={app.id}>{`${app.name} - ${app.platform} (${app.package})`}</Option>
          ))}
        </Select>
        <Button disabled={selectedAppIDS.length === 0} onClick={handleAddAppsForUser}>
          Add
        </Button>
      </div>
      <div className="source-user-search">
        <Select
          allowClear
          showSearch
          value={selectedSourceUser}
          onChange={handleSourceUserChange}
          placeholder="Select User"
          disabled={isFetchingData}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {allUsers.map((user) => (
            <Option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name} - ${user.email}`}</Option>
          ))}
        </Select>
        <Button disabled={sourceUserApps.length === 0} onClick={() => handleMergeSourceUserApps()}>
          {`${mergedSourceUserApps ? 'Remove' : 'Add'} ${sourceUserApps.length} apps`}
        </Button>
      </div>
      <Table columns={columns} dataSource={dataSource} pagination={false} loading={isFetchingData} />
    </Modal>
  );
}
