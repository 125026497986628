import { Input } from 'antd';
import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { MARKETING_INSIGHT_TYPES } from '../../../../../constants/insightType';
import { EDITING_MODES } from '../../../utils/consts';
import './InsightsPanel.scss';
import { NewInsight } from './newInsight';

const MAX_NUM_OF_INSIGHTS_TO_SHOW = 30;

const InsightsPanelBase = ({
  appId,
  insights,
  editingModeStep,
  setEditingModeStep,
  onCreateInsight,
  setSelectedAddToInsight,
  selectedAddToInsight,
}) => {
  const [search, setSearch] = useState('');

  const insightsToShow = useMemo(() => {
    const filteredInsights = insights.filter(
      (insight) => insight.title && insight.title.toLowerCase().includes(search.toLowerCase())
    );

    return filteredInsights.length > MAX_NUM_OF_INSIGHTS_TO_SHOW
      ? filteredInsights.slice(0, MAX_NUM_OF_INSIGHTS_TO_SHOW)
      : filteredInsights;
  }, [search, insights]);

  const insightClick = (insightId, type) => {
    if (editingModeStep === EDITING_MODES.AddToInsight) {
      setSelectedAddToInsight(insightId);
    } else {
      window.open(
        `/#/${MARKETING_INSIGHT_TYPES.includes(type) ? 'marketing' : 'intelligence'}/${appId}/${insightId}`,
        '_blank'
      );
    }
  };

  const handleClose = () => {
    setEditingModeStep(EDITING_MODES.None);
  };

  return (
    <div className="insights-main">
      {editingModeStep === EDITING_MODES.InsightCreation && (
        <NewInsight createInsight={onCreateInsight} newInsightCreationClose={handleClose} appId={appId} />
      )}
      <Input.Search onSearch={(value) => setSearch(value)} placeholder="Search Insight" enterButton />
      <div className="insights-list">
        {insightsToShow.map((insight) => (
          <div
            key={insight.id}
            className={`insight-wrapper ${
              editingModeStep === EDITING_MODES.AddToInsight && insight.id === selectedAddToInsight
                ? 'selected-insight'
                : ''
            }`}
            onClick={() => insightClick(insight.id, insight.type)}
          >
            <div className="title">{insight.title || 'No title'}</div>
            <div className="description">{insight.description || 'No description'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const InsightsPanel = withRouter(InsightsPanelBase);
