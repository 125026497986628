export const KeyEvent = {
  ACTION_DOWN: 0,
  ACTION_UP: 1,
  KEYCODE_0: 7,
  KEYCODE_1: 8,
  KEYCODE_11: 227,
  KEYCODE_12: 228,
  KEYCODE_2: 9,
  KEYCODE_3: 10,
  KEYCODE_3D_MODE: 206,
  KEYCODE_4: 11,
  KEYCODE_5: 12,
  KEYCODE_6: 13,
  KEYCODE_7: 14,
  KEYCODE_8: 15,
  KEYCODE_9: 16,
  KEYCODE_A: 29,
  KEYCODE_ALL_APPS: 284,
  KEYCODE_ALT_LEFT: 57,
  KEYCODE_ALT_RIGHT: 58,
  KEYCODE_APOSTROPHE: 75,
  KEYCODE_APP_SWITCH: 187,
  KEYCODE_ASSIST: 219,
  KEYCODE_AT: 77,
  KEYCODE_AVR_INPUT: 182,
  KEYCODE_AVR_POWER: 181,
  KEYCODE_B: 30,
  KEYCODE_BACK: 4,
  KEYCODE_BACKSLASH: 73,
  KEYCODE_BOOKMARK: 174,
  KEYCODE_BREAK: 121,
  KEYCODE_BRIGHTNESS_DOWN: 220,
  KEYCODE_BRIGHTNESS_UP: 221,
  KEYCODE_BUTTON_1: 188,
  KEYCODE_BUTTON_10: 197,
  KEYCODE_BUTTON_11: 198,
  KEYCODE_BUTTON_12: 199,
  KEYCODE_BUTTON_13: 200,
  KEYCODE_BUTTON_14: 201,
  KEYCODE_BUTTON_15: 202,
  KEYCODE_BUTTON_16: 203,
  KEYCODE_BUTTON_2: 189,
  KEYCODE_BUTTON_3: 190,
  KEYCODE_BUTTON_4: 191,
  KEYCODE_BUTTON_5: 192,
  KEYCODE_BUTTON_6: 193,
  KEYCODE_BUTTON_7: 194,
  KEYCODE_BUTTON_8: 195,
  KEYCODE_BUTTON_9: 196,
  KEYCODE_BUTTON_A: 96,
  KEYCODE_BUTTON_B: 97,
  KEYCODE_BUTTON_C: 98,
  KEYCODE_BUTTON_L1: 102,
  KEYCODE_BUTTON_L2: 104,
  KEYCODE_BUTTON_MODE: 110,
  KEYCODE_BUTTON_R1: 103,
  KEYCODE_BUTTON_R2: 105,
  KEYCODE_BUTTON_SELECT: 109,
  KEYCODE_BUTTON_START: 108,
  KEYCODE_BUTTON_THUMBL: 106,
  KEYCODE_BUTTON_THUMBR: 107,
  KEYCODE_BUTTON_X: 99,
  KEYCODE_BUTTON_Y: 100,
  KEYCODE_BUTTON_Z: 101,
  KEYCODE_C: 31,
  KEYCODE_CALCULATOR: 210,
  KEYCODE_CALENDAR: 208,
  KEYCODE_CALL: 5,
  KEYCODE_CAMERA: 27,
  KEYCODE_CAPS_LOCK: 115,
  KEYCODE_CAPTIONS: 175,
  KEYCODE_CHANNEL_DOWN: 167,
  KEYCODE_CHANNEL_UP: 166,
  KEYCODE_CLEAR: 28,
  KEYCODE_COMMA: 55,
  KEYCODE_CONTACTS: 207,
  KEYCODE_COPY: 278,
  KEYCODE_CTRL_LEFT: 113,
  KEYCODE_CTRL_RIGHT: 114,
  KEYCODE_CUT: 277,
  KEYCODE_D: 32,
  KEYCODE_DEL: 67,
  KEYCODE_DPAD_CENTER: 23,
  KEYCODE_DPAD_DOWN: 20,
  KEYCODE_DPAD_DOWN_LEFT: 269,
  KEYCODE_DPAD_DOWN_RIGHT: 271,
  KEYCODE_DPAD_LEFT: 21,
  KEYCODE_DPAD_RIGHT: 22,
  KEYCODE_DPAD_UP: 19,
  KEYCODE_DPAD_UP_LEFT: 268,
  KEYCODE_DPAD_UP_RIGHT: 270,
  KEYCODE_DVR: 173,
  KEYCODE_E: 33,
  KEYCODE_EISU: 212,
  KEYCODE_ENDCALL: 6,
  KEYCODE_ENTER: 66,
  KEYCODE_ENVELOPE: 65,
  KEYCODE_EQUALS: 70,
  KEYCODE_ESCAPE: 111,
  KEYCODE_EXPLORER: 64,
  KEYCODE_F: 34,
  KEYCODE_F1: 131,
  KEYCODE_F10: 140,
  KEYCODE_F11: 141,
  KEYCODE_F12: 142,
  KEYCODE_F2: 132,
  KEYCODE_F3: 133,
  KEYCODE_F4: 134,
  KEYCODE_F5: 135,
  KEYCODE_F6: 136,
  KEYCODE_F7: 137,
  KEYCODE_F8: 138,
  KEYCODE_F9: 139,
  KEYCODE_FOCUS: 80,
  KEYCODE_FORWARD: 125,
  KEYCODE_FORWARD_DEL: 112,
  KEYCODE_FUNCTION: 119,
  KEYCODE_G: 35,
  KEYCODE_GRAVE: 68,
  KEYCODE_GUIDE: 172,
  KEYCODE_H: 36,
  KEYCODE_HEADSETHOOK: 79,
  KEYCODE_HELP: 259,
  KEYCODE_HENKAN: 214,
  KEYCODE_HOME: 3,
  KEYCODE_I: 37,
  KEYCODE_INFO: 165,
  KEYCODE_INSERT: 124,
  KEYCODE_J: 38,
  KEYCODE_K: 39,
  KEYCODE_KANA: 218,
  KEYCODE_KATAKANA_HIRAGANA: 215,
  KEYCODE_L: 40,
  KEYCODE_LANGUAGE_SWITCH: 204,
  KEYCODE_LAST_CHANNEL: 229,
  KEYCODE_LEFT_BRACKET: 71,
  KEYCODE_M: 41,
  KEYCODE_MANNER_MODE: 205,
  KEYCODE_MEDIA_AUDIO_TRACK: 222,
  KEYCODE_MEDIA_CLOSE: 128,
  KEYCODE_MEDIA_EJECT: 129,
  KEYCODE_MEDIA_FAST_FORWARD: 90,
  KEYCODE_MEDIA_NEXT: 87,
  KEYCODE_MEDIA_PAUSE: 127,
  KEYCODE_MEDIA_PLAY: 126,
  KEYCODE_MEDIA_PLAY_PAUSE: 85,
  KEYCODE_MEDIA_PREVIOUS: 88,
  KEYCODE_MEDIA_RECORD: 130,
  KEYCODE_MEDIA_REWIND: 89,
  KEYCODE_MEDIA_SKIP_BACKWARD: 273,
  KEYCODE_MEDIA_SKIP_FORWARD: 272,
  KEYCODE_MEDIA_STEP_BACKWARD: 275,
  KEYCODE_MEDIA_STEP_FORWARD: 274,
  KEYCODE_MEDIA_STOP: 86,
  KEYCODE_MEDIA_TOP_MENU: 226,
  KEYCODE_MENU: 82,
  // KEYCODE_META_LEFT: 117, opens assistant disable for now
  KEYCODE_META_RIGHT: 118,
  KEYCODE_MINUS: 69,
  KEYCODE_MOVE_END: 123,
  KEYCODE_MOVE_HOME: 122,
  KEYCODE_MUHENKAN: 213,
  KEYCODE_MUSIC: 209,
  KEYCODE_MUTE: 91,
  KEYCODE_N: 42,
  KEYCODE_NAVIGATE_IN: 262,
  KEYCODE_NAVIGATE_NEXT: 261,
  KEYCODE_NAVIGATE_OUT: 263,
  KEYCODE_NAVIGATE_PREVIOUS: 260,
  KEYCODE_NOTIFICATION: 83,
  KEYCODE_NUM: 78,
  KEYCODE_NUMPAD_0: 144,
  KEYCODE_NUMPAD_1: 145,
  KEYCODE_NUMPAD_2: 146,
  KEYCODE_NUMPAD_3: 147,
  KEYCODE_NUMPAD_4: 148,
  KEYCODE_NUMPAD_5: 149,
  KEYCODE_NUMPAD_6: 150,
  KEYCODE_NUMPAD_7: 151,
  KEYCODE_NUMPAD_8: 152,
  KEYCODE_NUMPAD_9: 153,
  KEYCODE_NUMPAD_ADD: 157,
  KEYCODE_NUMPAD_COMMA: 159,
  KEYCODE_NUMPAD_DIVIDE: 154,
  KEYCODE_NUMPAD_DOT: 158,
  KEYCODE_NUMPAD_ENTER: 160,
  KEYCODE_NUMPAD_EQUALS: 161,
  KEYCODE_NUMPAD_LEFT_PAREN: 162,
  KEYCODE_NUMPAD_MULTIPLY: 155,
  KEYCODE_NUMPAD_RIGHT_PAREN: 163,
  KEYCODE_NUMPAD_SUBTRACT: 156,
  KEYCODE_NUM_LOCK: 143,
  KEYCODE_O: 43,
  KEYCODE_P: 44,
  KEYCODE_PAGE_DOWN: 93,
  KEYCODE_PAGE_UP: 92,
  KEYCODE_PAIRING: 225,
  KEYCODE_PASTE: 279,
  KEYCODE_PERIOD: 56,
  KEYCODE_PICTSYMBOLS: 94,
  KEYCODE_PLUS: 81,
  KEYCODE_POUND: 18,
  KEYCODE_POWER: 26,
  KEYCODE_PROFILE_SWITCH: 288,
  KEYCODE_PROG_BLUE: 186,
  KEYCODE_PROG_GREEN: 184,
  KEYCODE_PROG_RED: 183,
  KEYCODE_PROG_YELLOW: 185,
  KEYCODE_Q: 45,
  KEYCODE_R: 46,
  KEYCODE_REFRESH: 285,
  KEYCODE_RIGHT_BRACKET: 72,
  KEYCODE_RO: 217,
  KEYCODE_S: 47,
  KEYCODE_SCROLL_LOCK: 116,
  KEYCODE_SEARCH: 84,
  KEYCODE_SEMICOLON: 74,
  KEYCODE_SETTINGS: 176,
  KEYCODE_SHIFT_LEFT: 59,
  KEYCODE_SHIFT_RIGHT: 60,
  KEYCODE_SLASH: 76,
  KEYCODE_SLEEP: 223,
  KEYCODE_SOFT_LEFT: 1,
  KEYCODE_SOFT_RIGHT: 2,
  KEYCODE_SOFT_SLEEP: 276,
  KEYCODE_SPACE: 62,
  KEYCODE_STAR: 17,
  KEYCODE_STB_INPUT: 180,
  KEYCODE_STB_POWER: 179,
  KEYCODE_STEM_1: 265,
  KEYCODE_STEM_2: 266,
  KEYCODE_STEM_3: 267,
  KEYCODE_STEM_PRIMARY: 264,
  KEYCODE_SWITCH_CHARSET: 95,
  KEYCODE_SYM: 63,
  KEYCODE_SYSRQ: 120,
  KEYCODE_SYSTEM_NAVIGATION_DOWN: 281,
  KEYCODE_SYSTEM_NAVIGATION_LEFT: 282,
  KEYCODE_SYSTEM_NAVIGATION_RIGHT: 283,
  KEYCODE_SYSTEM_NAVIGATION_UP: 280,
  KEYCODE_T: 48,
  KEYCODE_TAB: 61,
  KEYCODE_THUMBS_DOWN: 287,
  KEYCODE_THUMBS_UP: 286,
  KEYCODE_TV: 170,
  KEYCODE_TV_ANTENNA_CABLE: 242,
  KEYCODE_TV_AUDIO_DESCRIPTION: 252,
  KEYCODE_TV_AUDIO_DESCRIPTION_MIX_DOWN: 254,
  KEYCODE_TV_AUDIO_DESCRIPTION_MIX_UP: 253,
  KEYCODE_TV_CONTENTS_MENU: 256,
  KEYCODE_TV_DATA_SERVICE: 230,
  KEYCODE_TV_INPUT: 178,
  KEYCODE_TV_INPUT_COMPONENT_1: 249,
  KEYCODE_TV_INPUT_COMPONENT_2: 250,
  KEYCODE_TV_INPUT_COMPOSITE_1: 247,
  KEYCODE_TV_INPUT_COMPOSITE_2: 248,
  KEYCODE_TV_INPUT_HDMI_1: 243,
  KEYCODE_TV_INPUT_HDMI_2: 244,
  KEYCODE_TV_INPUT_HDMI_3: 245,
  KEYCODE_TV_INPUT_HDMI_4: 246,
  KEYCODE_TV_INPUT_VGA_1: 251,
  KEYCODE_TV_MEDIA_CONTEXT_MENU: 257,
  KEYCODE_TV_NETWORK: 241,
  KEYCODE_TV_NUMBER_ENTRY: 234,
  KEYCODE_TV_POWER: 177,
  KEYCODE_TV_RADIO_SERVICE: 232,
  KEYCODE_TV_SATELLITE: 237,
  KEYCODE_TV_SATELLITE_BS: 238,
  KEYCODE_TV_SATELLITE_CS: 239,
  KEYCODE_TV_SATELLITE_SERVICE: 240,
  KEYCODE_TV_TELETEXT: 233,
  KEYCODE_TV_TERRESTRIAL_ANALOG: 235,
  KEYCODE_TV_TERRESTRIAL_DIGITAL: 236,
  KEYCODE_TV_TIMER_PROGRAMMING: 258,
  KEYCODE_TV_ZOOM_MODE: 255,
  KEYCODE_U: 49,
  KEYCODE_UNKNOWN: 0,
  KEYCODE_V: 50,
  KEYCODE_VOICE_ASSIST: 231,
  KEYCODE_VOLUME_DOWN: 25,
  KEYCODE_VOLUME_MUTE: 164,
  KEYCODE_VOLUME_UP: 24,
  KEYCODE_W: 51,
  KEYCODE_WAKEUP: 224,
  KEYCODE_WINDOW: 171,
  KEYCODE_X: 52,
  KEYCODE_Y: 53,
  KEYCODE_YEN: 216,
  KEYCODE_Z: 54,
  KEYCODE_ZENKAKU_HANKAKU: 211,
  KEYCODE_ZOOM_IN: 168,
  KEYCODE_ZOOM_OUT: 169,

  META_ALT_LEFT_ON: 16,
  META_ALT_MASK: 50,
  META_ALT_ON: 2,
  META_ALT_RIGHT_ON: 32,
  META_CAPS_LOCK_ON: 1048576,
  META_CTRL_LEFT_ON: 8192,
  META_CTRL_MASK: 28672,
  META_CTRL_ON: 4096,
  META_CTRL_RIGHT_ON: 16384,
  META_FUNCTION_ON: 8,
  META_META_LEFT_ON: 131072,
  META_META_MASK: 458752,
  META_META_ON: 65536,
  META_META_RIGHT_ON: 262144,
  META_NUM_LOCK_ON: 2097152,
  META_SCROLL_LOCK_ON: 4194304,
  META_SHIFT_LEFT_ON: 64,
  META_SHIFT_MASK: 193,
  META_SHIFT_ON: 1,
  META_SHIFT_RIGHT_ON: 128,
  META_SYM_ON: 4,
};

const UIEventsCode = {
  Backquote: 'Backquote',
  Backslash: 'Backslash',
  BracketLeft: 'BracketLeft',
  BracketRight: 'BracketRight',
  Comma: 'Comma',
  Digit0: 'Digit0',
  Digit1: 'Digit1',
  Digit2: 'Digit2',
  Digit3: 'Digit3',
  Digit4: 'Digit4',
  Digit5: 'Digit5',
  Digit6: 'Digit6',
  Digit7: 'Digit7',
  Digit8: 'Digit8',
  Digit9: 'Digit9',
  Equal: 'Equal',
  IntlBackslash: 'IntlBackslash',
  IntlRo: 'IntlRo',
  IntlYen: 'IntlYen',
  KeyA: 'KeyA',
  KeyB: 'KeyB',
  KeyC: 'KeyC',
  KeyD: 'KeyD',
  KeyE: 'KeyE',
  KeyF: 'KeyF',
  KeyG: 'KeyG',
  KeyH: 'KeyH',
  KeyI: 'KeyI',
  KeyJ: 'KeyJ',
  KeyK: 'KeyK',
  KeyL: 'KeyL',
  KeyM: 'KeyM',
  KeyN: 'KeyN',
  KeyO: 'KeyO',
  KeyP: 'KeyP',
  KeyQ: 'KeyQ',
  KeyR: 'KeyR',
  KeyS: 'KeyS',
  KeyT: 'KeyT',
  KeyU: 'KeyU',
  KeyV: 'KeyV',
  KeyW: 'KeyW',
  KeyX: 'KeyX',
  KeyY: 'KeyY',
  KeyZ: 'KeyZ',
  Minus: 'Minus',
  Period: 'Period',
  Quote: 'Quote',
  Semicolon: 'Semicolon',
  Slash: 'Slash',
  AltLeft: 'AltLeft',
  AltRight: 'AltRight',
  Backspace: 'Backspace',
  CapsLock: 'CapsLock',
  ContextMenu: 'ContextMenu',
  ControlLeft: 'ControlLeft',
  ControlRight: 'ControlRight',
  Enter: 'Enter',
  MetaLeft: 'MetaLeft',
  MetaRight: 'MetaRight',
  ShiftLeft: 'ShiftLeft',
  ShiftRight: 'ShiftRight',
  Space: 'Space',
  Tab: 'Tab',
  Convert: 'Convert',
  KanaMode: 'KanaMode',
  Lang1: 'Lang1',
  Lang2: 'Lang2',
  Lang3: 'Lang3',
  Lang4: 'Lang4',
  Lang5: 'Lang5',
  NonConvert: 'NonConvert',
  Delete: 'Delete',
  End: 'End',
  Help: 'Help',
  Home: 'Home',
  Insert: 'Insert',
  PageDown: 'PageDown',
  PageUp: 'PageUp',
  ArrowDown: 'ArrowDown',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  ArrowUp: 'ArrowUp',
  NumLock: 'NumLock',
  Numpad0: 'Numpad0',
  Numpad1: 'Numpad1',
  Numpad2: 'Numpad2',
  Numpad3: 'Numpad3',
  Numpad4: 'Numpad4',
  Numpad5: 'Numpad5',
  Numpad6: 'Numpad6',
  Numpad7: 'Numpad7',
  Numpad8: 'Numpad8',
  Numpad9: 'Numpad9',
  NumpadAdd: 'NumpadAdd',
  NumpadBackspace: 'NumpadBackspace',
  NumpadClear: 'NumpadClear',
  NumpadClearEntry: 'NumpadClearEntry',
  NumpadComma: 'NumpadComma',
  NumpadDecimal: 'NumpadDecimal',
  NumpadDivide: 'NumpadDivide',
  NumpadEnter: 'NumpadEnter',
  NumpadEqual: 'NumpadEqual',
  NumpadHash: 'NumpadHash',
  NumpadMemoryAdd: 'NumpadMemoryAdd',
  NumpadMemoryClear: 'NumpadMemoryClear',
  NumpadMemoryRecall: 'NumpadMemoryRecall',
  NumpadMemoryStore: 'NumpadMemoryStore',
  NumpadMemorySubtract: 'NumpadMemorySubtract',
  NumpadMultiply: 'NumpadMultiply',
  NumpadParenLeft: 'NumpadParenLeft',
  NumpadParenRight: 'NumpadParenRight',
  NumpadStar: 'NumpadStar',
  NumpadSubtract: 'NumpadSubtract',
  Escape: 'Escape',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  Fn: 'Fn',
  FnLock: 'FnLock',
  PrintScreen: 'PrintScreen',
  ScrollLock: 'ScrollLock',
  Pause: 'Pause',

  // 3.1.6. Media Keys
  BrowserBack: 'BrowserBack',
  BrowserFavorites: 'BrowserFavorites',
  BrowserForward: 'BrowserForward',
  BrowserHome: 'BrowserHome',
  BrowserRefresh: 'BrowserRefresh',
  BrowserSearch: 'BrowserSearch',
  BrowserStop: 'BrowserStop',
  Eject: 'Eject',
  LaunchApp1: 'LaunchApp1',
  LaunchApp2: 'LaunchApp2',
  LaunchMail: 'LaunchMail',
  MediaPlayPause: 'MediaPlayPause',
  MediaSelect: 'MediaSelect',
  MediaStop: 'MediaStop',
  MediaTrackNext: 'MediaTrackNext',
  MediaTrackPrevious: 'MediaTrackPrevious',
  Power: 'Power',
  Sleep: 'Sleep',
  AudioVolumeDown: 'AudioVolumeDown',
  AudioVolumeMute: 'AudioVolumeMute',
  AudioVolumeUp: 'AudioVolumeUp',
  WakeUp: 'WakeUp',
  Hyper: 'Hyper',
  Super: 'Super',
  Turbo: 'Turbo',
  Abort: 'Abort',
  Resume: 'Resume',
  Suspend: 'Suspend',
  Again: 'Again',
  Copy: 'Copy',
  Cut: 'Cut',
  Find: 'Find',
  Open: 'Open',
  Paste: 'Paste',
  Props: 'Props',
  Select: 'Select',
  Undo: 'Undo',
  Hiragana: 'Hiragana',
  Katakana: 'Katakana',
  Unidentified: 'Unidentified',
};

export const KeyToCodeMap = new Map([
  [UIEventsCode.Backquote, KeyEvent.KEYCODE_GRAVE],
  [UIEventsCode.Backslash, KeyEvent.KEYCODE_BACKSLASH],
  [UIEventsCode.BracketLeft, KeyEvent.KEYCODE_LEFT_BRACKET],
  [UIEventsCode.BracketRight, KeyEvent.KEYCODE_RIGHT_BRACKET],
  [UIEventsCode.Comma, KeyEvent.KEYCODE_COMMA],
  [UIEventsCode.Digit0, KeyEvent.KEYCODE_0],
  [UIEventsCode.Digit1, KeyEvent.KEYCODE_1],
  [UIEventsCode.Digit2, KeyEvent.KEYCODE_2],
  [UIEventsCode.Digit3, KeyEvent.KEYCODE_3],
  [UIEventsCode.Digit4, KeyEvent.KEYCODE_4],
  [UIEventsCode.Digit5, KeyEvent.KEYCODE_5],
  [UIEventsCode.Digit6, KeyEvent.KEYCODE_6],
  [UIEventsCode.Digit7, KeyEvent.KEYCODE_7],
  [UIEventsCode.Digit8, KeyEvent.KEYCODE_8],
  [UIEventsCode.Digit9, KeyEvent.KEYCODE_9],
  [UIEventsCode.Equal, KeyEvent.KEYCODE_EQUALS],
  [UIEventsCode.IntlRo, KeyEvent.KEYCODE_RO],
  [UIEventsCode.IntlYen, KeyEvent.KEYCODE_YEN],
  [UIEventsCode.KeyA, KeyEvent.KEYCODE_A],
  [UIEventsCode.KeyB, KeyEvent.KEYCODE_B],
  [UIEventsCode.KeyC, KeyEvent.KEYCODE_C],
  [UIEventsCode.KeyD, KeyEvent.KEYCODE_D],
  [UIEventsCode.KeyE, KeyEvent.KEYCODE_E],
  [UIEventsCode.KeyF, KeyEvent.KEYCODE_F],
  [UIEventsCode.KeyG, KeyEvent.KEYCODE_G],
  [UIEventsCode.KeyH, KeyEvent.KEYCODE_H],
  [UIEventsCode.KeyI, KeyEvent.KEYCODE_I],
  [UIEventsCode.KeyJ, KeyEvent.KEYCODE_J],
  [UIEventsCode.KeyK, KeyEvent.KEYCODE_K],
  [UIEventsCode.KeyL, KeyEvent.KEYCODE_L],
  [UIEventsCode.KeyM, KeyEvent.KEYCODE_M],
  [UIEventsCode.KeyN, KeyEvent.KEYCODE_N],
  [UIEventsCode.KeyO, KeyEvent.KEYCODE_O],
  [UIEventsCode.KeyP, KeyEvent.KEYCODE_P],
  [UIEventsCode.KeyQ, KeyEvent.KEYCODE_Q],
  [UIEventsCode.KeyR, KeyEvent.KEYCODE_R],
  [UIEventsCode.KeyS, KeyEvent.KEYCODE_S],
  [UIEventsCode.KeyT, KeyEvent.KEYCODE_T],
  [UIEventsCode.KeyU, KeyEvent.KEYCODE_U],
  [UIEventsCode.KeyV, KeyEvent.KEYCODE_V],
  [UIEventsCode.KeyW, KeyEvent.KEYCODE_W],
  [UIEventsCode.KeyX, KeyEvent.KEYCODE_X],
  [UIEventsCode.KeyY, KeyEvent.KEYCODE_Y],
  [UIEventsCode.KeyZ, KeyEvent.KEYCODE_Z],
  [UIEventsCode.Minus, KeyEvent.KEYCODE_MINUS],
  [UIEventsCode.Period, KeyEvent.KEYCODE_PERIOD],
  [UIEventsCode.Quote, KeyEvent.KEYCODE_APOSTROPHE],
  [UIEventsCode.Semicolon, KeyEvent.KEYCODE_SEMICOLON],
  [UIEventsCode.Slash, KeyEvent.KEYCODE_SLASH],
  [UIEventsCode.KanaMode, KeyEvent.KEYCODE_KANA],
  [UIEventsCode.Delete, KeyEvent.KEYCODE_FORWARD_DEL],
  [UIEventsCode.End, KeyEvent.KEYCODE_MOVE_END],
  [UIEventsCode.Help, KeyEvent.KEYCODE_HELP],
  [UIEventsCode.Home, KeyEvent.KEYCODE_MOVE_HOME],
  [UIEventsCode.Insert, KeyEvent.KEYCODE_INSERT],
  [UIEventsCode.PageDown, KeyEvent.KEYCODE_PAGE_DOWN],
  [UIEventsCode.PageUp, KeyEvent.KEYCODE_PAGE_UP],
  [UIEventsCode.AltLeft, KeyEvent.KEYCODE_ALT_LEFT],
  [UIEventsCode.AltRight, KeyEvent.KEYCODE_ALT_RIGHT],
  [UIEventsCode.Backspace, KeyEvent.KEYCODE_DEL],
  [UIEventsCode.CapsLock, KeyEvent.KEYCODE_CAPS_LOCK],
  [UIEventsCode.ControlLeft, KeyEvent.KEYCODE_CTRL_LEFT],
  [UIEventsCode.ControlRight, KeyEvent.KEYCODE_CTRL_RIGHT],
  [UIEventsCode.Enter, KeyEvent.KEYCODE_ENTER],
  [UIEventsCode.MetaLeft, KeyEvent.KEYCODE_META_LEFT],
  [UIEventsCode.MetaRight, KeyEvent.KEYCODE_META_RIGHT],
  [UIEventsCode.ShiftLeft, KeyEvent.KEYCODE_SHIFT_LEFT],
  [UIEventsCode.ShiftRight, KeyEvent.KEYCODE_SHIFT_RIGHT],
  [UIEventsCode.Space, KeyEvent.KEYCODE_SPACE],
  [UIEventsCode.Tab, KeyEvent.KEYCODE_TAB],
  [UIEventsCode.ArrowLeft, KeyEvent.KEYCODE_DPAD_LEFT],
  [UIEventsCode.ArrowUp, KeyEvent.KEYCODE_DPAD_UP],
  [UIEventsCode.ArrowRight, KeyEvent.KEYCODE_DPAD_RIGHT],
  [UIEventsCode.ArrowDown, KeyEvent.KEYCODE_DPAD_DOWN],

  [UIEventsCode.NumLock, KeyEvent.KEYCODE_NUM_LOCK],
  [UIEventsCode.Numpad0, KeyEvent.KEYCODE_NUMPAD_0],
  [UIEventsCode.Numpad1, KeyEvent.KEYCODE_NUMPAD_1],
  [UIEventsCode.Numpad2, KeyEvent.KEYCODE_NUMPAD_2],
  [UIEventsCode.Numpad3, KeyEvent.KEYCODE_NUMPAD_3],
  [UIEventsCode.Numpad4, KeyEvent.KEYCODE_NUMPAD_4],
  [UIEventsCode.Numpad5, KeyEvent.KEYCODE_NUMPAD_5],
  [UIEventsCode.Numpad6, KeyEvent.KEYCODE_NUMPAD_6],
  [UIEventsCode.Numpad7, KeyEvent.KEYCODE_NUMPAD_7],
  [UIEventsCode.Numpad8, KeyEvent.KEYCODE_NUMPAD_8],
  [UIEventsCode.Numpad9, KeyEvent.KEYCODE_NUMPAD_9],
  [UIEventsCode.NumpadAdd, KeyEvent.KEYCODE_NUMPAD_ADD],
  [UIEventsCode.NumpadComma, KeyEvent.KEYCODE_NUMPAD_COMMA],
  [UIEventsCode.NumpadDecimal, KeyEvent.KEYCODE_NUMPAD_DOT],
  [UIEventsCode.NumpadDivide, KeyEvent.KEYCODE_NUMPAD_DIVIDE],
  [UIEventsCode.NumpadEnter, KeyEvent.KEYCODE_NUMPAD_ENTER],
  [UIEventsCode.NumpadEqual, KeyEvent.KEYCODE_NUMPAD_EQUALS],
  [UIEventsCode.NumpadMultiply, KeyEvent.KEYCODE_NUMPAD_MULTIPLY],
  [UIEventsCode.NumpadParenLeft, KeyEvent.KEYCODE_NUMPAD_LEFT_PAREN],
  [UIEventsCode.NumpadParenRight, KeyEvent.KEYCODE_NUMPAD_RIGHT_PAREN],
  [UIEventsCode.NumpadSubtract, KeyEvent.KEYCODE_NUMPAD_SUBTRACT],

  [UIEventsCode.Escape, KeyEvent.KEYCODE_ESCAPE],
  [UIEventsCode.F1, KeyEvent.KEYCODE_F1],
  [UIEventsCode.F2, KeyEvent.KEYCODE_F2],
  [UIEventsCode.F3, KeyEvent.KEYCODE_F3],
  [UIEventsCode.F4, KeyEvent.KEYCODE_F4],
  [UIEventsCode.F5, KeyEvent.KEYCODE_F5],
  [UIEventsCode.F6, KeyEvent.KEYCODE_F6],
  [UIEventsCode.F7, KeyEvent.KEYCODE_F7],
  [UIEventsCode.F8, KeyEvent.KEYCODE_F8],
  [UIEventsCode.F9, KeyEvent.KEYCODE_F9],
  [UIEventsCode.F10, KeyEvent.KEYCODE_F10],
  [UIEventsCode.F11, KeyEvent.KEYCODE_F11],
  [UIEventsCode.F12, KeyEvent.KEYCODE_F12],
  [UIEventsCode.Fn, KeyEvent.KEYCODE_FUNCTION],
  [UIEventsCode.PrintScreen, KeyEvent.KEYCODE_SYSRQ],
  [UIEventsCode.Pause, KeyEvent.KEYCODE_BREAK],
]);
