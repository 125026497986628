import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { creatingInsight } from '../../redux/actions/insightPage';

class CreateInsight extends Component {
  state = {};

  onClick = () => {
    const { selectedAppPackage, selectedAppPlatform } = this.props;
    this.props.creatingInsight({
      packageName: selectedAppPackage,
      platform: selectedAppPlatform,
      localId: Math.floor(Date.now() / 1000),
    });
  };

  render() {
    const { createInsightStatus } = this.props;
    return (
      <div className="insight-page__create">
        <Button primary onClick={this.onClick}>
          Create Insight
        </Button>
        <p>{createInsightStatus}</p>
      </div>
    );
  }
}

const mapStateToProps = ({ insightPage }) => ({
  selectedAppName: insightPage.selectedAppName,
  selectedAppPackage: insightPage.selectedAppPackage,
  selectedAppPlatform: insightPage.selectedAppPlatform,
  createInsightStatus: insightPage.createInsightStatus,
});

export default connect(mapStateToProps, { creatingInsight })(CreateInsight);
