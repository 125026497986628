export const INSIGHT_TYPE = {
  ab_test: 'A/B Test',
  indication: 'Indication',
  hashflag: 'Hashflag',
  pricing: 'Pricing',
  new_feature: 'Feature Added',
  removed_feature: 'Feature Removed',
  screen_change: 'Screen Change',
  inapp_campaign: 'In-App Campaign',
  email_campaign: 'Email Campaign',
  push_notification_campaign: 'Push Notification Campaign',
};

export const INSIGHT_CAMPAIGN_SUBTYPE = {
  mass_market: 'Mass Market',
  transactional: 'Transactional',
  prospects_nursery: 'Prospects/Nursery',
  onboarding: 'Onboarding',
  retention: 'Cross-Sell, Up-Sell & Retention',
  lapsed: 'Lapsed',
};

export const insightTypeDictionary = {
  ab_test: 'Experiment',
  screen_change: 'UI Change',
  new_feature: 'New Feature',
  indication: 'Indication',
  inapp_campaign: 'In-App / On-Site',
  email_campaign: 'Email',
  push_notification_campaign: 'Push Notification',
  social_media: 'Social Media Campaign',
};

export const MARKETING_INSIGHT_TYPES = ['inapp_campaign', 'email_campaign', 'push_notification_campaign'];

export const ABBR_MAP_TYPE = {
  ab_test: 'AB',
  indication: 'IND',
  screen_change: 'UC',
  new_feature: 'NEW',
  removed_feature: 'REM',
  email_campaign: 'EMAIL',
  push_notification_campaign: 'PUSH',
  inapp_campaign: 'INAPP',
};

export const APP_PERSONA_PERM_INSIGHT_TYPES = [
  "email_campaign",
  "push_notification_campaign",
  "inapp_campaign",
  "ab_test",
  "screen_change",
  "indication",
  "new_feature",
  "removed_feature",
  "user_journey",
];
