import {
  addVerificationFilterAPI,
  delVerificationFilterAPI,
  getAllVerificationFiltersAPI,
  updVerificationFilterAPI,
} from '../actions/annotationVerificationPage';
import { getAllDeviceModelsAPI } from '../actions/deviceModels';
import * as AT from '../actionTypes';

const annotationVerificationMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_DEVICE_MODELS: {
        dispatch(getAllDeviceModelsAPI());
        return next(action);
      }
      case AT.GET_ALL_VERIFICATION_FILTERS: {
        dispatch(getAllVerificationFiltersAPI());
        return next(action);
      }
      case AT.UPD_VERIFICATION_FILTER: {
        dispatch(updVerificationFilterAPI(action.payload.filter_id, action.payload.data));
        return next(action);
      }
      case AT.ADD_VERIFICATION_FILTER: {
        dispatch(addVerificationFilterAPI(action.payload.data));
        return next(action);
      }
      case AT.DEL_VERIFICATION_FILTER: {
        dispatch(delVerificationFilterAPI(action.payload.filter_id));
        return next(action);
      }

      default:
        return next(action);
    }
  };

export default annotationVerificationMiddleware;
