import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Input, Switch, Popconfirm, Spin, Dropdown, Icon } from 'antd';
import Graph from 'react-graph-vis';

import VideoStream from './VideoStream';

import edit_node_ico from '../../assets/icons/graph_builder/edit_node.svg';
import delete_node_ico from '../../assets/icons/graph_builder/delete_node.svg';
import exist_node_ico from '../../assets/icons/graph_builder/exist_node.svg';
import sub_group_ico from '../../assets/icons/graph_builder/sub_group.svg';
import {
  CELLSIZE,
  GRAPHCANVASOPTIONS,
  canvasWidth,
  canvasHeight,
  automationStateEnum,
  // methods
  getXYNewNode,
  drawGrid,
  getLastOrderIndex,
} from './helpers';

const NetworkGraph = ({
  sendDataToDeviceOnFramerHandle,
  farmerDevice,
  farDevListOfApps,
  productData,
  deviceID,
  connectIsEnabled,
  streamURL,

  // Graph Data
  graphData,
  rootNodeID,
  handleNewNodeWithEdge,
  handleNewEdgeForExistNodesAPI,
  handleUpdateNodeAPI,
  handleDeleteNode,
  lastScreenData,
  lastScreenDataChange,

  editedNode,
  editedNodeChange,
  fetchingScreenShotData,
  chosenEdgeChange,
  chosenEdge,
  isEdgeChosenChange,
  EdgeDirActiveTab,
  selectedNodeAfterUpdateGraph,
  selectedNodeAfterUpdateGraphChange,
  newScreenshotWaiting,
  newScreenshotWaitingChange,
  screenDataWaitingChange,
  lastNodeInChain,
  handleUpdateEdge,
  interactionWithVideo,
  interactionWithVideoChange,
  automationState,
  automationStateChange,
  graphDataChange,
  saveFullGraphRequest,
  isMassSaving,
  curEdgeIdInGraphRun,
}) => {
  const graphNetwork = useRef(null);
  const graphNetworkBackGrid = useRef(null);

  window.graphData = graphData;

  const [textForSending, textForSendingChange] = useState('');
  const [keyVis, keyVisChange] = useState(new Date().getTime());

  const [createEdgeToExistingNodeMode, createEdgeToExistingNodeModeChange] = useState(false);
  const [editedNodePopupShow, editedNodePopupShowChange] = useState(false);
  const [editedNodeLabel, editedNodeLabelChange] = useState('');
  const [editedNodeDescr, editedNodeDescrChange] = useState('');
  const [editedNodeGroup, editedNodeGroupChange] = useState('');

  useEffect(() => {
    const canvas = document.getElementById('graph-back-grid');
    graphNetworkBackGrid.current = canvas.getContext('2d');
    window.gn = graphNetwork.current;
    window.bg = graphNetworkBackGrid.current;
    updateGrid();
  }, []);

  useEffect(() => {
    if (editedNode) {
      editedNodeChange(null);
    }
    if (graphData && lastNodeInChain) {
      openNewEdgeForm(lastNodeInChain);
      handleGetScreenData(true);
      screenDataWaitingChange(true);
      lastScreenDataChange(null);
    }
  }, [lastNodeInChain]);

  useEffect(() => {
    if (!lastScreenData || !lastScreenData.screenshot_link) {
      return;
    }
    const node = graphData.nodes.find((n) => n.id === selectedNodeAfterUpdateGraph);
    if (newScreenshotWaiting && editedNode) {
      console.log(`--------UPDATE SCREENSHOT FOR NODE ${editedNode.id}--------`);
      handleUpdateNodeAPI(editedNode.id, {
        screenshot_link: lastScreenData.screenshot_link,
        ui_xml_data_link: lastScreenData.ui_xml_data_link,
        screenshot_hash: lastScreenData.screenshot_details.phash,
      });
      newScreenshotWaitingChange(false);
    } else if (!newScreenshotWaiting && selectedNodeAfterUpdateGraph && node && !node.screenshot_link) {
      console.log(`--------SAVE SCREENSHOT FOR NODE ${selectedNodeAfterUpdateGraph}--------`);
      handleUpdateNodeAPI(selectedNodeAfterUpdateGraph, {
        screenshot_link: lastScreenData.screenshot_link,
        ui_xml_data_link: lastScreenData.ui_xml_data_link,
        screenshot_hash: lastScreenData.screenshot_details.phash,
      });
    }
    selectedNodeAfterUpdateGraphChange(null);
  }, [lastScreenData]);

  useEffect(() => {
    Object.values(graphNetwork.current.body.edges).forEach((edge) => {
      edge.setOptions({
        color: {
          color: '#848484',
        },
        width: 1,
      });
    });
    if (curEdgeIdInGraphRun && graphNetwork.current.body.edges[curEdgeIdInGraphRun]) {
      graphNetwork.current.body.edges[curEdgeIdInGraphRun].setOptions({
        color: {
          color: '#85db1e',
        },
        width: 3,
      });
    }
    graphNetwork.current.redraw();
  }, [curEdgeIdInGraphRun]);

  const handleSendText = () => {
    sendDataToDeviceOnFramerHandle('text', [textForSending], false);
    textForSendingChange('');
  };
  const updateGrid = () => {
    if (!graphNetwork.current || !graphNetworkBackGrid.current) {
      return;
    }
    const gn = graphNetwork.current;
    const scale = gn.getScale();
    const unSelectedNode = Object.values(gn.body.nodes).find((n) => !gn.getSelectedNodes().includes(n.id));
    let absCoords;
    if (!unSelectedNode) {
      absCoords = { x: 0, y: 0 };
    } else {
      absCoords = gn.canvasToDOM(gn.getPosition(unSelectedNode.id));
    }
    const newCellSize = CELLSIZE * scale;
    const offsetX = newCellSize - (absCoords.x % newCellSize);
    const offsetY = newCellSize - (absCoords.y % newCellSize);

    const draggingNode = gn.getSelectedNodes()[0];
    const isDraggingNode = draggingNode && gn.body.nodes[draggingNode];

    drawGrid(
      graphNetworkBackGrid.current,
      newCellSize,
      isDraggingNode ? gn.canvasToDOM(gn.getPosition(draggingNode)).x : null,
      isDraggingNode ? gn.canvasToDOM(gn.getPosition(draggingNode)).y : null,
      offsetX,
      offsetY
    );
  };

  const handleCreateNode = () => {
    const selectedNodeID = gn.getSelectedNodes();
    const newNode = {};
    const newEdge = {};
    const xyNewNode = getXYNewNode(gn, lastNodeInChain);
    newNode.x = xyNewNode.x;
    newNode.y = xyNewNode.y;
    const edgeData = chosenEdge[parseInt(EdgeDirActiveTab)];
    const lastOrderIndex = getLastOrderIndex(graphData);
    console.log(lastOrderIndex);
    if (edgeData) {
      newNode.screenshot_link = null;
      newEdge.order_indexes = [lastOrderIndex + 1].join(',');
      newEdge.action_type = edgeData.action_type;
      newEdge.xpath = edgeData.xpath;
      newEdge.swipe_data = edgeData.swipe_data;
      newEdge.input_text = edgeData.input_text;
    }

    handleNewNodeWithEdge(selectedNodeID[0], newNode, newEdge);
    unselectAll();
  };

  const handleAddEdgeToExistNode = () => {
    createEdgeToExistingNodeModeChange(!createEdgeToExistingNodeMode);
  };

  const handleOpenRenameNode = () => {
    editedNodePopupShowChange(!editedNodePopupShow);
    editedNodeLabelChange(editedNode.options.label);
    editedNodeDescrChange(editedNode.options.description);
    editedNodeGroupChange(editedNode.options.group_name);
  };

  const handleSaveNode = () => {
    const newNode = {
      name: editedNodeLabel,
      description: editedNodeDescr,
      group_name: editedNodeGroup,
      label: 'state',
      ui_position_x: editedNode.x,
      ui_position_y: editedNode.y,
      x: editedNode.x,
      y: editedNode.y,
    };
    handleUpdateNodeAPI(editedNode.id, newNode);
    editedNodePopupShowChange(false);
  };

  const onClickDeleteNode = () => {
    handleDeleteNode(editedNode.id, true);
    unselectAll();
  };

  const onChangePublishedSwitcher = (checked) => {
    handleUpdateNodeAPI(rootNodeID, {
      is_published: checked,
    });
  };

  const unselectAll = () => {
    // debugger
    graphNetwork.current.unselectAll();
    Object.values(graphNetwork.current.body.nodes).forEach((node) => {
      node.setOptions({
        font: {
          color: GRAPHCANVASOPTIONS.nodes.font.color,
        },
        color: {
          background: 'white',
        },
      });
    });
    updateGrid();
  };

  const handleGetNewScreenshotData = () => {
    newScreenshotWaitingChange(true);
    sendDataToDeviceOnFramerHandle('get_screen_data', {}, true);
  };

  const handleGetScreenData = (forceGetScreenData = false) => {
    sendDataToDeviceOnFramerHandle('get_screen_data', {}, forceGetScreenData);
  };

  const events = {
    dragEnd: (params) => {
      if (params.nodes[0]) {
        const coords = graphNetwork.current.getPosition(params.nodes[0]);
        let x = Math.round(Number(coords.x) / CELLSIZE) * CELLSIZE,
          y = Math.round(Number(coords.y) / CELLSIZE) * CELLSIZE;

        graphNetwork.current.moveNode(params.nodes[0], x, y);

        const node = graphNetwork.current.body.nodes[params.nodes[0]];
        handleUpdateNodeAPI(params.nodes[0], {
          name: node.name || node.options.label,
          label: 'state',
          x,
          y,
          ui_position_x: x,
          ui_position_y: y,
        });
      }
      // unselectAll();
    },
    selectNode: (params) => {
      const lastOrderIndex = getLastOrderIndex(graphData);
      const selectedNodeId = params.nodes[0];
      const existingEdge = graphData.edges.find((e) => e.from === lastNodeInChain && e.to === selectedNodeId);
      if (createEdgeToExistingNodeMode && existingEdge) {
        handleUpdateEdge(
          existingEdge.id,
          {
            ...existingEdge,
            order_indexes: `${existingEdge.order_indexes},${lastOrderIndex + 1}`,
            swipe_data: JSON.stringify(existingEdge.swipe_data),
          },
          true
        );
        createEdgeToExistingNodeModeChange(false);
        editedNodeChange(null);
        unselectAll();
        return;
      }
      if (createEdgeToExistingNodeMode) {
        // Create new edge
        const newEdge = {};
        const edgeData = chosenEdge[parseInt(EdgeDirActiveTab)];
        const lastOrderIndex = getLastOrderIndex(graphData);
        if (edgeData) {
          newEdge.order_indexes = [lastOrderIndex + 1].join(',');
          newEdge.action_type = edgeData.action_type;
          newEdge.xpath = edgeData.xpath;
          newEdge.swipe_data = JSON.stringify(edgeData.swipe_data);
          newEdge.input_text = edgeData.input_text;
        }
        handleNewEdgeForExistNodesAPI(lastNodeInChain, selectedNodeId, newEdge);
        createEdgeToExistingNodeModeChange(false);
        editedNodeChange(null);
        unselectAll();
        return;
      }

      graphNetwork.current.body.nodes[lastNodeInChain].setOptions({
        font: {
          color: 'white',
        },
        color: {
          background: '#5fb60e',
          border: '#5fb60',
        },
      });
      editedNodeChange(graphNetwork.current.body.nodes[selectedNodeId]);
      graphNetwork.current.body.nodes[selectedNodeId].setOptions({
        font: {
          color: 'white',
        },
        color: {
          background: '#2E74FF',
        },
      });
    },
    deselectNode: (params) => {
      const deselectedNode = params.previousSelection.nodes[0];
      if (deselectedNode.id === lastNodeInChain) {
        deselectedNode.setOptions({
          font: {
            color: 'white',
          },
          color: {
            background: '#5fb60e',
            border: '#5fb60',
          },
        });
      } else {
        deselectedNode.setOptions({
          font: {
            color: GRAPHCANVASOPTIONS.nodes.font.color,
          },
          color: {
            background: 'white',
          },
        });
      }

      if (!createEdgeToExistingNodeMode) {
        editedNodeChange(null);
      }
    },
    selectEdge: (params) => {
      if (params.edges[0] && !params.nodes[0]) {
        const relNodes = [gn.body.edges[params.edges[0]].fromId, gn.body.edges[params.edges[0]].toId];
        const selEdges = graphData.edges.filter((e) => relNodes.includes(e.from) && relNodes.includes(e.to));
        chosenEdgeChange(selEdges);
        isEdgeChosenChange(true);
      }
    },

    beforeDrawing: updateGrid,
  };

  const openNewEdgeForm = (selectedNodeId) => {
    chosenEdgeChange([
      {
        action_type: 'xpath',
        comment: 'action',
        description: 'WILL BE IN FUTURE',
        from: selectedNodeId,
        start_node_id: selectedNodeId,
        swipe_data: null,
        text: '',
        xpath: '',
      },
    ]);
    isEdgeChosenChange(true);
  };

  const handleRepaintNetwork = () => {
    GRAPHCANVASOPTIONS.layout.randomSeed = undefined;
    graphDataChange({
      nodes: graphData.nodes.map((n) => ({ ...n, x: undefined, y: undefined })),
      edges: graphData.edges,
    });
    keyVisChange(new Date().getTime());
    setTimeout(() => {
      graphDataChange({
        nodes: graphData.nodes.map((n) => {
          const coords = graphNetwork.current.getPosition(n.id);
          let x = Math.round(Number(coords.x) / CELLSIZE) * CELLSIZE,
            y = Math.round(Number(coords.y) / CELLSIZE) * CELLSIZE;
          return { ...n, x, y };
        }),
        edges: graphData.edges,
      });
      keyVisChange(new Date().getTime());
    }, 200);
  };

  const handleSaveFullGraph = () => {
    const positions = graphNetwork.current.getPositions();
    const nodes = Object.keys(positions).map((id) => ({
      id,
      ui_position_x: positions[id].x,
      ui_position_y: positions[id].y,
    }));
    console.log(nodes);
    saveFullGraphRequest(nodes);
  };

  return (
    <div className="video-and-graph-wrapper-flex-cont">
      <div className="video-and-screenshots">
        <div className="video-wrapper-flex-cont">
          <div className="node-management">
            {editedNode && (
              <div className="node-label-wrap">
                <div className="label-and-description">
                  <h3>
                    {editedNode.options.label} ({editedNode.id})
                    <span className="node-group-name">
                      {editedNode.options.group_name ? editedNode.options.group_name : ''}
                    </span>
                  </h3>
                  {editedNode && <div className="node-descr">{editedNode.options.description}</div>}
                </div>
                <div className="node-management-btns">
                  <img src={edit_node_ico} onClick={handleOpenRenameNode} />

                  <img src={sub_group_ico} />
                  <Popconfirm
                    placement="top"
                    title="Are you sure?"
                    onConfirm={onClickDeleteNode}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img src={delete_node_ico} />
                  </Popconfirm>
                </div>
              </div>
            )}

            {editedNode && editedNodePopupShow && (
              <div className="node-label-change-wrap">
                <Input
                  type="text"
                  value={editedNodeLabel}
                  onChange={(e) => editedNodeLabelChange(e.currentTarget.value)}
                />
                <Input.TextArea
                  rows={3}
                  placeholder="description"
                  value={editedNodeDescr}
                  onChange={(e) => editedNodeDescrChange(e.currentTarget.value)}
                />
                <Input value={editedNodeGroup} onChange={(e) => editedNodeGroupChange(e.currentTarget.value)} />
                <Button onClick={handleSaveNode}>Save</Button>
              </div>
            )}
          </div>
          {productData.data && deviceID && connectIsEnabled && farmerDevice && (
            <div className="videostream">
              {!interactionWithVideo && <div className="cover-video" />}
              <VideoStream
                sendDataToDeviceOnFramerHandle={sendDataToDeviceOnFramerHandle}
                farmerDevice={farmerDevice}
                farDevListOfApps={farDevListOfApps}
                streamURL={streamURL}
              />
            </div>
          )}
        </div>
        <div className="send-text-to-farmer">
          <Input value={textForSending} onChange={(e) => textForSendingChange(e.target.value)} />{' '}
          <Button onClick={handleSendText}>Send</Button>
        </div>
        {editedNode && (
          <div className="new-screenshot-btn">
            <Button onClick={handleGetNewScreenshotData}>
              <Icon type="camera" />
              New screenshot
            </Button>
          </div>
        )}
      </div>
      <div className="graph-wrapper-flex-cont">
        {/* <div className="record-context-switcher"> */}
        {/*  Interaction with video */}
        {/*  <Switch checked={interactionWithVideo} onChange={(checked) => interactionWithVideoChange(checked)} /> */}
        {/* </div> */}

        <div className="is-published-switcher">
          Published
          <Switch
            checked={
              graphData.nodes.find((n) => n.id === rootNodeID) &&
              graphData.nodes.find((n) => n.id === rootNodeID).is_published
            }
            onChange={onChangePublishedSwitcher}
          />
        </div>

        <div className={`network-graph-wrapper ${createEdgeToExistingNodeMode ? 'highlight' : ''}`}>
          <div className="confirm-new-or-existing">
            {!graphData.nodes[0].graph_builder && (
              <Button onClick={handleSaveFullGraph}>Save {isMassSaving && <Spin size="small" />} </Button>
            )}
            {!graphData.nodes[0].graph_builder && (
              <Button onClick={handleRepaintNetwork} style={{ marginRight: '2rem' }}>
                Repaint
              </Button>
            )}
            <Button disabled={automationState === automationStateEnum.Running} onClick={handleAddEdgeToExistNode}>
              Link to exist Node
            </Button>
            <Button disabled={automationState === automationStateEnum.Running} onClick={handleCreateNode}>
              New Node
            </Button>
          </div>
          <canvas id="graph-back-grid" width={canvasWidth} height={canvasHeight} />
          <Graph
            key={keyVis}
            graph={graphData}
            options={GRAPHCANVASOPTIONS}
            events={events}
            getNetwork={(network) => {
              graphNetwork.current = network;
            }}
          />

          {/* {lastEdgeDataFarmer && lastEdgeDataFarmer.context && <div className="last-data-farmer">
            <div><strong>Method:</strong> {lastEdgeDataFarmer.method} </div>
            Last data (for create edge and node): {
              lastEdgeDataFarmer.method === 'swipe' ?
                lastEdgeDataFarmer.context.node_xpaths.smart :
                lastEdgeDataFarmer.args[0]
            }
            {lastEdgeDataFarmer.context.screenshot && <div>Screenshot <img width={40} src={lastEdgeDataFarmer.context.screenshot} /></div>}
          </div>} */}
        </div>
      </div>
    </div>
  );
};

export default NetworkGraph;
