import React from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'redux/store';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { FlagsProvider } from 'react-unleash-flags';
import { AWS_CONFIG } from '../config/aws';
import Home from './Home';
import '../monitoring/datadog';
import { ReactQueryDevtools } from 'react-query/devtools';
import { workbenchTracking } from 'monitoring/wbTracking';

import { QueryClient, QueryClientProvider } from 'react-query';

const unleashConfig = {
  appName: 'production',
  url: 'https://gitlab.com/api/v4/feature_flags/unleash/9904514',
  instanceId: '56n6ziWU7DMXsudNjw8U',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // retry: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: false,
      onError(err) {
        workbenchTracking.endEventTrack('api_error', {
          error: err.stack || JSON.stringify(err),
          path: err.path,
          status: err.status,
        });
      },
    },
    mutations: {
      onError(err) {
        workbenchTracking.endEventTrack('api_error', {
          error: err.stack || JSON.stringify(err),
          path: err.path,
          status: err.status,
        });
      },
    },
  },
});

Amplify.configure({
  Auth: AWS_CONFIG,
});

const App = () => (
  <HashRouter>
    <FlagsProvider config={unleashConfig}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <Home />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </FlagsProvider>
  </HashRouter>
);

// Change includeGreetings to true if you'd like to have a logout button
export default withAuthenticator(App, {
  includeGreetings: true,
  usernameAttributes: 'email',
});
