import { Button, Carousel, Modal, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { getPersonasByApp } from '../../../../../api/insightsAPI';
import {
  createUserFlow,
  createUserFlowSet,
  createUserFlowSetAssets,
  getUserFlows,
} from '../../../../../api/userJourneysAPI';
import { saveDeviceScreenshotToS3, saveDeviceXMLToS3 } from '../../../../../api/workbenchAPI';
import { UJ_ACTION_TYPES } from '../../../utils/consts';
import { CanvasedImagePreview } from '../../insightsTabs/imagesPreview/canvasedImagePreview';
import './UserJourneysModal.scss';

export const UserJourneysModal = ({ onOk, imagesWithRects, app, releases, getReleases, resetScreens, ...rest }) => {
  const carouselRef = useRef(null);
  const [images, setImages] = useState(imagesWithRects);
  const [curImageIdx, setCurImageIdx] = useState(0);
  const queryClient = useQueryClient();
  const [userJourneyFields, setUserJourneyFields] = useState({
    userFlow: null,
    userFlowParent: null,
    app,
    release: null,
    persona: null,
  });
  const submitDisabled =
    !userJourneyFields.userFlow || !userJourneyFields.app || !userJourneyFields.release || !userJourneyFields.userFlow;

  useEffect(() => {
    getReleases(app.id);
  }, [app.id]);

  const { data: userFlows, isFetching: userFlowsFetching } = useQuery('GetUserFlows', getUserFlows);
  const { data: personas, isFetching: personasFetching } = useQuery(['GetPersonas', app.id], () =>
    getPersonasByApp(app.id)
  );

  const createUserFlowMutation = useMutation(
    'CreateUserFlow',
    ({ name, parent_id }) => createUserFlow({ name, parent_id }),
    {
      onSuccess() {
        queryClient.invalidateQueries('GetUserFlows');
      },
    }
  );

  const createUserFlowSetMutation = useMutation(
    'CreateUserFlowSet',
    ({ user_flow_id, persona_id, release_id, is_visible }) =>
      createUserFlowSet({ user_flow_id, persona_id, release_id, is_visible })
  );

  const uploadImagesMutation = useMutation('UploadImages', async (imagesWithRects) => {
    return await Promise.all(
      imagesWithRects.map(async ({ image, rects, ujAction, xml }) => {
        const { url } = await saveDeviceScreenshotToS3('2', {
          filedata: image.split(', ')[1],
        });

        const { url: xmlUrl } = await saveDeviceXMLToS3('2', {
          filedata: xml,
        });

        return {
          url,
          rects,
          ujAction,
          xmlUrl,
        };
      })
    );
  });

  const createUserFlowSetAssetsMutation = useMutation('CreateUserFlowSetAssets', ({ set_id, assets }) =>
    createUserFlowSetAssets({ set_id, assets })
  );

  const handleImageChange = (idx) => {
    setCurImageIdx(idx);
  };

  const handleRemoveImage = () => {
    setImages((prev) => prev.filter((_, idx) => idx !== curImageIdx));
  };

  const nextImage = () => {
    carouselRef.current.next();
  };

  const prevImage = () => {
    carouselRef.current.prev();
  };

  const handleCreateFlowParentOption = (value) => {
    createUserFlowMutation.mutateAsync({ name: value, parent_id: null });
  };

  const handleCreateFlowChildOption = (value) => {
    createUserFlowMutation.mutateAsync({
      name: value,
      parent_id: userJourneyFields.userFlowParent.id,
    });
  };

  const handleFlowOptionSelect = (isChild) => (option) => {
    if (!option) return;

    const userFlow = userFlows.data.find(({ id }) => id === option.value);

    setUserJourneyFields((prev) => ({
      ...prev,
      ...(isChild ? { userFlow } : { userFlowParent: userFlow }),
    }));
  };

  const handleReleaseSelect = (option) => {
    if (!option) return;

    setUserJourneyFields((prev) => ({
      ...prev,
      release: releases.find(({ id }) => id === option.value),
    }));
  };

  const handlePersonaSelect = (option) => {
    if (!option) return;

    setUserJourneyFields((prev) => ({
      ...prev,
      persona: personas.data.find(({ id }) => id === option.value),
    }));
  };

  const handleCreateUserJourney = () => {
    resetScreens();
    createUserFlowSetMutation.mutate(
      {
        user_flow_id: userJourneyFields.userFlow.id,
        persona_id: userJourneyFields.persona.id,
        release_id: userJourneyFields.release.id,
        is_visible: false,
      },
      {
        onSuccess(res) {
          const { data: userFlowSet } = res;
          queryClient.invalidateQueries('GetUserFlowSets');

          uploadImagesMutation.mutate(images, {
            async onSuccess(imagesUrls) {
              for (const { url, rects, ujAction, xmlUrl } of imagesUrls) {
                await createUserFlowSetAssetsMutation.mutateAsync({
                  set_id: userFlowSet.id,
                  assets: [
                    {
                      image_url: url,
                      ui_xml_url: xmlUrl,
                      text: '',
                      rects,
                      ...(ujAction
                        ? {
                            action_type: ujAction.type,
                            action_coords:
                              ujAction.type === UJ_ACTION_TYPES.Click
                                ? {
                                    x: ujAction.startX,
                                    y: ujAction.startY,
                                  }
                                : {
                                    x0: ujAction.startX,
                                    y0: ujAction.startY,
                                    x1: ujAction.endX,
                                    y1: ujAction.endY,
                                  },
                          }
                        : null),
                    },
                  ],
                });
              }

              message.success('User Journey Created Successfully');
              onOk();
            },
          });
        },
      }
    );
  };

  return (
    <Modal title="Create User Journey" footer={null} {...rest}>
      <div className={`uj-modal-container`}>
        <div className="uj-images-preview">
          <Carousel ref={carouselRef} afterChange={handleImageChange}>
            {images.map(({ image, rects, ujAction }) => (
              <CanvasedImagePreview
                key={image}
                imageSrc={image}
                rects={rects}
                ujAction={ujAction}
                canvasHeight={600}
                canvasWidth={270}
              />
            ))}
          </Carousel>
          <div className="uj-images-actions">
            <Button size="large" shape="circle" icon="left" onClick={prevImage} />
            <Button
              size="large"
              shape="circle"
              type="danger"
              icon="close"
              onClick={handleRemoveImage}
              disabled={images.length <= 1}
            />
            <Button size="large" shape="circle" icon="right" onClick={nextImage} />
          </div>
        </div>
        <div className="uj-fields">
          <div className="uj-field">
            <Select
              placeholder="Select a Release"
              options={
                releases
                  ? releases.map((release) => ({
                      label: release.release_name,
                      value: release.id,
                    }))
                  : []
              }
              onChange={handleReleaseSelect}
            />
          </div>
          <div className="uj-field">
            <Select
              placeholder="Select a Persona"
              isLoading={personasFetching}
              options={personas ? personas.data.map((persona) => ({ label: persona.title, value: persona.id })) : []}
              onChange={handlePersonaSelect}
            />
          </div>
          <div className="uj-field">
            <CreatableSelect
              placeholder="Select or Create a Flow Parent"
              isClearable
              isLoading={userFlowsFetching || createUserFlowMutation.isLoading}
              options={
                userFlows
                  ? userFlows.data
                      .filter(({ parent_id }) => parent_id === null)
                      .map(({ id, name }) => ({ value: id, label: name }))
                  : []
              }
              onCreateOption={handleCreateFlowParentOption}
              onChange={handleFlowOptionSelect(false)}
            />
          </div>
          <div className="uj-field">
            <CreatableSelect
              placeholder="Select or Create a Flow"
              isClearable
              isLoading={userFlowsFetching || createUserFlowMutation.isLoading}
              options={
                userFlows && userJourneyFields.userFlowParent
                  ? userFlows.data
                      .filter(({ parent_id }) => parent_id === userJourneyFields.userFlowParent.id)
                      .map(({ id, name }) => ({ value: id, label: name }))
                  : []
              }
              isDisabled={!userJourneyFields.userFlowParent}
              onCreateOption={handleCreateFlowChildOption}
              onChange={handleFlowOptionSelect(true)}
            />
          </div>
          <Button
            disabled={submitDisabled}
            className="uj-create-button"
            key="submit"
            type="primary"
            onClick={handleCreateUserJourney}
            loading={
              uploadImagesMutation.isLoading ||
              createUserFlowSetMutation.isLoading ||
              createUserFlowSetAssetsMutation.isLoading
            }
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
