import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Alert, message, Select, Spin } from 'antd';
import moment from 'moment';
import { getSortedScreenChanges } from './screanChangeSelector';
import {
  getScreenChangesForApp,
  getVersionGraphDataForApp,
  getAllAppsWithGraphs,
  getGraphDataForApp,
  createScreenChangeInsight,
  createComplaintForGraph,
  deleteComplaintForGraph,
} from '../../redux/actions/UIViewerPage';
import NetworkGraph from './NetworkGraph';
import { loadLabels } from '../../redux/actions/insightPage';

export const UIViewer = ({
  appsWithGraphs,
  screenChanges,
  graphDataProp,
  getScreenChangesForApp,
  getGraphDataForApp,
  getAllAppsWithGraphs,
  createScreenChangeInsight,
  labelsSuggestions,
  loadLabels,
  sendingComplaintStatus,
  createComplaintForGraph,
  deleteComplaintForGraph,
  location,
  history,
  error,
}) => {
  const root_id = location.pathname.split('/')[2];
  const [screenChangeCurApp, screenChangeCurAppChange] = useState(null);
  const [screenIndex, screenIndexChange] = useState(0);
  const [graphData, graphDataChange] = useState(null);
  const [loadingData, loadingDataChange] = useState(false);
  const [phashDiffQ, phashDiffQChange] = useState(2);
  const [graphKey, graphKeyChange] = useState(1);
  const [appID, appIDChange] = useState(null);
  const [app, appChange] = useState(null);
  const [chosenVer, chosenVerChange] = useState(null);

  const [ver1, ver1Change] = useState(null);
  const [ver2, ver2Change] = useState(null);

  const graphNetwork = useRef(null);
  window.gN = graphNetwork;

  useEffect(() => {
    getAllAppsWithGraphs();
    loadLabels();
  }, []);

  useEffect(() => {
    if (root_id && appsWithGraphs.length > 0) {
      const appWithGraph = appsWithGraphs.find((g) => g.id === Number(root_id));
      appIDChange(appWithGraph.app.id);
      appChange(appWithGraph);
      getScreenChangesForApp(appWithGraph.app.id);
      loadingDataChange(true);
    }
  }, [appsWithGraphs]);

  useEffect(() => {
    if (sendingComplaintStatus === 'sending') {
      message.loading('Sending complaint...');
    }
    if (sendingComplaintStatus === 'success') {
      message.destroy();
      message.success('Complaint was sent');
    }
    if (sendingComplaintStatus === 'deleting complaint') {
      message.success('Deleting complaint');
    }
    if (sendingComplaintStatus === 'complaint deleted') {
      message.destroy();
      message.success('Complaint was deleted');
    }
  }, [sendingComplaintStatus]);

  useEffect(() => {
    screenChangeCurAppChange(screenChanges);

    if (screenChanges && screenChanges.length > 1) {
      loadingDataChange(false);
      if (screenChanges.length > 0) {
        ver1Change(screenChanges[0]);
        if (screenChanges.length > 1) {
          ver2Change(screenChanges[1]);
        }
        handleChangeAppVer(`${screenChanges[0].version_code}___${screenChanges[0].version_name}`);
      }
    }
  }, [screenChanges]);

  useEffect(() => {
    if (!graphDataProp) {
      return;
    }

    let initCoords = {},
      nodeNames = {};

    if (app && localStorage.getItem(app.app.package_name)) {
      initCoords = JSON.parse(localStorage.getItem(app.app.package_name)).nodeCoords;
      nodeNames = JSON.parse(localStorage.getItem(app.app.package_name)).nodeNames;
    }

    const graphDataReformat = {
      nodes: graphDataProp.nodes.map((n, i) => {
        const phash_diff1 =
          ver1 && ver1.screens.find((s) => s.name === n.name) && ver1.screens.find((s) => s.name === n.name).phash_diff;
        const phash_diff2 =
          ver2 && ver2.screens.find((s) => s.name === n.name) && ver2.screens.find((s) => s.name === n.name).phash_diff;
        const title = `${phash_diff1 && phash_diff2 ? Math.abs(phash_diff2 - phash_diff1) : '--'} | ${n.name}`;
        let label = `${n.id} - ${n.name}`;
        label += ` - ${
          graphDataProp.insights.find((ins) => ins.node_id === n.id)
            ? graphDataProp.insights.find((ins) => ins.node_id === n.id).insights.length
            : '0'
        }`;
        return {
          id: n.id,
          label,
          title,
          shape: 'dot',
          size: 25 + 2.5 * graphDataProp.relationships.filter((edge) => edge.start_node_id === n.id).length,
          ...(phash_diff1 && phash_diff2 && Math.abs(phash_diff2 - phash_diff1) >= phashDiffQ
            ? { color: { background: '#ffff7a', highlight: { background: '#ffff31' } } }
            : { color: { background: '#D2E5FF', highlight: { background: '#D2E5FF' } } }),
          ...(app && n.id === app.id
            ? { color: { border: 'red', highlight: { border: 'red' } }, borderWidthSelected: 3, borderWidth: 3 }
            : {}),
          x: initCoords && initCoords[String(n.id)] ? initCoords[String(n.id)].x : undefined,
          y: initCoords && initCoords[String(n.id)] ? initCoords[String(n.id)].y : undefined,
        };
      }),

      edges: graphDataProp.relationships.map((rel) => {
        if (!graphDataProp.nodes.find((n) => n.name === rel.comment)) {
          console.log(rel);
        }

        const nodeFrom = graphDataProp.nodes.find((n) => n.id === rel.start_node_id);
        const nodeTo = graphDataProp.nodes.find((n) => n.id === rel.end_node_id);
        let countEdgeNodeFrom, countEdgeNodeTo;
        if (nodeFrom && nodeTo) {
          countEdgeNodeFrom = graphDataProp.relationships.filter((edge) => edge.start_node_id === nodeFrom.id).length;
          countEdgeNodeTo = graphDataProp.relationships.filter((edge) => edge.end_node_id === nodeTo.id).length;
        }

        return {
          length: countEdgeNodeFrom && countEdgeNodeFrom > 4 && countEdgeNodeTo && countEdgeNodeTo > 4 ? 450 : 200,
          from: rel.start_node_id,
          label: rel.input_type && rel.input_type !== 'developer' ? rel.input_type.split(' ')[0] : undefined,
          title: rel.input_type,
          to: rel.end_node_id,
        };
      }),
    };

    graphDataChange(graphDataReformat);
    loadingDataChange(false);
  }, [graphDataProp, ver1, ver2, graphKey]);

  const handleChangeApp = (appID) => {
    getScreenChangesForApp(appID);
    const appWithGraph = appsWithGraphs.find((a) => a.app.id === appID);
    appChange(appWithGraph);
    appIDChange(appID);
  };

  const handleChangeAppVer = (ver) => {
    chosenVerChange(ver);
    const graph_properties = screenChanges.find(
      (s) => `${s.version_code}___${s.version_name}` === ver
    ).graph_properties;
    const appWithGraph = appsWithGraphs.find(
      (g) => g.version === `${graph_properties.version_code}___${graph_properties.version_name}`
    );

    getGraphDataForApp(appWithGraph.id);
    loadingDataChange(true);
    ver1Change(screenChanges.find((sc) => sc.version_name === ver.split('___')[1]));
    history.replace(`/ui-viewer/${appWithGraph.id}`);
  };

  const handleChangePersona = (persona) => {
    const appWithGraph = appsWithGraphs.find((a) => a.persona.title === persona);
    appChange(appWithGraph);
    getScreenChangesForApp(appWithGraph.app.id);
    getGraphDataForApp(appWithGraph.id);
    screenChangeCurAppChange(null);
    graphDataChange(null);
    loadingDataChange(true);
    history.replace(`/ui-viewer/${appWithGraph.id}`);
  };

  const handleScreenIndexChange = (index) => {
    screenIndexChange(index);
    const node = graphData.nodes.find((n) => n.label === screenList[index]);
    if (!node) {
      return;
    }
    graphNetwork.current.focus(node.id, {
      scale: 1,
      animation: true,
    });
    graphNetwork.current.selectNodes([node.id]);
  };

  const createComplaintHandle = (values) => {
    createComplaintForGraph({
      id: app.id,
      description: values.description,
      graph_rank: values.graph_rank,
    });
  };
  const deleteComplaintHandle = (graph_id) => {
    deleteComplaintForGraph({
      graph_id,
    });
    console.log('deleting complaint', graph_id);
  };

  const screenList = screenChangeCurApp
    ? [...new Set(screenChangeCurApp.map((ver) => ver.screens.map((scr) => scr.name)).flat())]
    : [];
  const prevImage =
    screenChangeCurApp &&
    ver1 &&
    screenChangeCurApp.find((sC) => sC.version_code === ver1.version_code) &&
    screenChangeCurApp
      .find((sC) => sC.version_code === ver1.version_code)
      .screens.find((scr) => scr.name === screenList[screenIndex]) &&
    screenChangeCurApp
      .find((sC) => sC.version_code === ver1.version_code)
      .screens.find((scr) => scr.name === screenList[screenIndex]).url;
  const newImage =
    screenChangeCurApp &&
    ver2 &&
    screenChangeCurApp.find((sC) => sC.version_code === ver2.version_code) &&
    screenChangeCurApp
      .find((sC) => sC.version_code === ver2.version_code)
      .screens.find((scr) => scr.name === screenList[screenIndex]) &&
    screenChangeCurApp
      .find((sC) => sC.version_code === ver2.version_code)
      .screens.find((scr) => scr.name === screenList[screenIndex]).url;
  const prevImageAsset = {
    type: 'image',
    uploaded_file_name: prevImage,
    orientation: 'portrait',
    text: '',
    insight_asset_type: 'previous',
    is_hidden: true,
  };

  const currentImageAsset = {
    type: 'image',
    uploaded_file_name: newImage,
    orientation: 'portrait',
    text: '',
    insight_asset_type: 'current',
    is_hidden: true,
  };
  return (
    <div className="ui-viewer-page">
      {error && <Alert message={error.message} description={error && JSON.stringify(error)} type="error" showIcon />}
      <Select className="app-selectbox" onChange={handleChangeApp} placeholder="Choose App" value={appID}>
        {appsWithGraphs
          .reduce((acc, el) => (acc.find((a) => a.app.id === el.app.id) ? acc : [...acc, el]), [])
          .map((a, i) => (
            <Select.Option value={a.app.id} key={i}>
              {a.app.display_name} - {a.app.platform}
            </Select.Option>
          ))}
      </Select>
      {appID && screenChanges && (
        <Select
          className="app-selectbox"
          onChange={handleChangeAppVer}
          placeholder="Choose version"
          value={screenChanges.length > 0 && `${screenChanges[0].version_code}___${screenChanges[0].version_name}`}
        >
          {screenChanges.map((v, i) => (
            <Select.Option value={`${v.version_code}___${v.version_name}`} key={i}>
              Ver. {v.version_code} {v.version_name}
            </Select.Option>
          ))}
        </Select>
      )}
      {appID && app && (
        <Select
          className="app-selectbox"
          onChange={handleChangePersona}
          placeholder="Choose persona"
          value={app.persona ? app.persona.title : 'NO_PERSONA'}
        >
          {appsWithGraphs
            .filter((a) => a.app.id === appID && a.version === chosenVer)
            .map((a, i) => (
              <Select.Option value={a.persona ? a.persona.title : 'NO_PERSONA'} key={i}>
                {a.persona ? a.persona.title : 'NO_PERSONA'}
              </Select.Option>
            ))}
        </Select>
      )}

      {loadingData && !error && <Spin size="large" />}
      <div className="uiviewer-page-content">
        <NetworkGraph
          app={app}
          graphNetwork={graphNetwork}
          graphData={graphData}
          graphDataChange={graphDataChange}
          phashDiffQ={phashDiffQ}
          phashDiffQChange={phashDiffQChange}
          graphKey={graphKey}
          insightsByNodeID={graphDataProp ? graphDataProp.insights : []}
          complaints={graphDataProp ? graphDataProp.complaints : []}
          graphKeyChange={graphKeyChange}
          screenIndex={screenIndex}
          screenIndexChange={screenIndexChange}
          screenList={screenList}
          createScreenChangeInsight={(insight) => createScreenChangeInsight(insight, prevImageAsset, currentImageAsset)}
          labelsSuggestions={labelsSuggestions}
          createComplaintHandle={createComplaintHandle}
          deleteComplaintHandle={deleteComplaintHandle}
        />

        {screenChangeCurApp && screenChangeCurApp.length >= 2 && ver1 && (
          <div className="screen-changes-wrap">
            <div
              className="screen-changes-left-btn"
              disabled={screenIndex === 0}
              onClick={() => (screenIndex !== 0 ? handleScreenIndexChange(screenIndex - 1) : '')}
            >
              {' '}
              {`<`}{' '}
            </div>

            <div className="screen-changes-left-ver">
              <div className="ver-version-title">{ver1.version_name}</div>
              <div className="ver-date">{moment(ver1.created_at).format('ll')}</div>
              {ver1 &&
                screenChangeCurApp
                  .find((sC) => sC.version_code === ver1.version_code)
                  .screens.find((scr) => scr.name === screenList[screenIndex]) && (
                  <img
                    src={
                      screenChangeCurApp
                        .find((sC) => sC.version_code === ver1.version_code)
                        .screens.find((scr) => scr.name === screenList[screenIndex]).url
                    }
                    key={`${ver1.version_code}-${screenList[screenIndex]}`}
                  />
                )}
            </div>

            <div className="screen-changes-cur-screen">{screenList[screenIndex]}</div>

            <div className="screen-changes-right-ver">
              <Select
                className="ver-selectbox ver-selectbox-2"
                value={ver2.version_code}
                onChange={(val) => ver2Change(screenChangeCurApp.find((sC) => sC.version_code === val))}
              >
                {screenChangeCurApp
                  .filter((sC) => sC.version_code !== ver1.version_code)
                  .map((sC) => (
                    <Select.Option value={sC.version_code} key={sC.version_code}>
                      {sC.version_name}
                    </Select.Option>
                  ))}
              </Select>
              <div className="ver-date">{moment(ver2.created_at).format('ll')}</div>

              {screenChangeCurApp
                .find((sC) => sC.version_code === ver2.version_code)
                .screens.find((scr) => scr.name === screenList[screenIndex]) && (
                <img
                  src={
                    screenChangeCurApp
                      .find((sC) => sC.version_code === ver2.version_code)
                      .screens.find((scr) => scr.name === screenList[screenIndex]).url
                  }
                  key={`${ver2.version_code}-${screenList[screenIndex]}`}
                />
              )}
            </div>

            <div
              className="screen-changes-right-btn"
              disabled={screenIndex === screenList.length - 1}
              onClick={() => (screenIndex < screenList.length - 1 ? handleScreenIndexChange(screenIndex + 1) : '')}
            >
              {' '}
              {`>`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  apps: state.UIViewerReducer.apps,
  screenChanges: getSortedScreenChanges(state),
  graphDataProp: state.UIViewerReducer.graphData,
  appsWithGraphs: state.UIViewerReducer.appsWithGraphs,
  sendingComplaintStatus: state.UIViewerReducer.sendingComplaintStatus,
  labelsSuggestions: state.insightPage.labels,
  error: state.UIViewerReducer.error,
});

const mapDispatchToProps = {
  getScreenChangesForApp,
  getVersionGraphDataForApp,
  getAllAppsWithGraphs,
  getGraphDataForApp,
  createScreenChangeInsight,
  loadLabels,
  createComplaintForGraph,
  deleteComplaintForGraph,
};

export default connect(mapStateToProps, mapDispatchToProps)(UIViewer);
