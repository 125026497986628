import {
  getAllUserGroupsAPI,
  setPermUserGroupsAPI,
  setDomainUserGroupsAPI,
  setOrchestratorUserGroupsAPI,
  setAppUserGroupsAPI,
  getOrgAppsMissingGroupsAPI,
  addUserGroupsAPI,
  deleteUserGroupsAPI,
  addProductToUserGroupAPI,
  delProductFromUserGroupAPI,
  getAllUserGroupPermsAPI,
  setUserGroupsAPI,
} from '../actions/userGroupsPage';
import { getOrchestratorsListAPI } from '../actions/performancePage';
import * as AT from '../actionTypes';

const userGroupsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_ORCHESTRATORS_LIST: {
        dispatch(getOrchestratorsListAPI());
        return next(action);
      }
      case AT.GET_ALL_USER_GROUPS: {
        dispatch(getAllUserGroupsAPI());
        return next(action);
      }
      case AT.GET_ALL_USER_GROUP_PERMS: {
        dispatch(getAllUserGroupPermsAPI());
        return next(action);
      }
      case AT.SET_PERM_USER_GROUPS: {
        dispatch(setPermUserGroupsAPI(action.payload.org_id, action.payload.data));
        return next(action);
      }

      case AT.SET_DOMAIN_USER_GROUPS: {
        dispatch(setDomainUserGroupsAPI(action.payload.org_id, action.payload.data));
        return next(action);
      }

      case AT.SET_ORCHESTRATOR_USER_GROUPS: {
        dispatch(setOrchestratorUserGroupsAPI(action.payload.org_id, action.payload.data));
        return next(action);
      }

      case AT.SET_APP_USER_GROUPS: {
        dispatch(setAppUserGroupsAPI(action.payload.org_id, action.payload.data));
        return next(action);
      }

      case AT.SET_APP_USER_MISSING_GROUPS: {
        dispatch(getOrgAppsMissingGroupsAPI(action.payload.org_id));
        return next(action);
      }
      case AT.ADD_USER_GROUPS: {
        dispatch(addUserGroupsAPI(action.payload.name));
        return next(action);
      }
      case AT.SET_USER_GROUPS: {
        dispatch(setUserGroupsAPI(action.payload));
        return next(action);
      }
      case AT.DELETE_USER_GROUPS: {
        dispatch(deleteUserGroupsAPI(action.payload.org_id));
        return next(action);
      }
      case AT.ADD_PRODUCT_TO_USER_GROUP: {
        dispatch(addProductToUserGroupAPI(action.payload.organizationId, action.payload.productId));
        return next(action);
      }
      case AT.DEL_PRODUCT_FROM_USER_GROUP: {
        dispatch(delProductFromUserGroupAPI(action.payload.organizationId, action.payload.productId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default userGroupsMiddleware;
