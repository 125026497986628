/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Checkbox } from 'antd';
import {
  getAllPersonas,
  addPersona,
  updPersona,
  addPersonaOrganization,
  delPersonaOrganization,
  addProductSetting,
  delProductSetting,
  addPersonaProduct,
  updPersonaProduct,
  delPersonaProduct,
  updPaymentDetail,
  delPaymentMethod,
} from '../../../redux/actions/personas';
import { getAllSettings } from '../../../redux/actions/personaSettings';
import { getAllProducts } from '../../../redux/actions/products';
import { getAllUserGroups } from '../../../redux/actions/userGroupsPage';
import PersonasTable from './PersonasTable';
import PersonaModal from './PersonaModal';

const PersonasTab = ({
  personas,
  settings,
  getAllSettings,
  isLoading,
  error,
  products,
  organizations,
  isAdmin,
  getAllPersonas,
  addPersona,
  updPersona,
  addPersonaOrganization,
  delPersonaOrganization,
  getAllProducts,
  getAllUserGroups,
  addProductSetting,
  delProductSetting,
  addPersonaProduct,
  updPersonaProduct,
  delPersonaProduct,
  updPaymentDetail,
  delPaymentMethod,
}) => {
  const [isPersonModalVisible, setIsPersonModalVisible] = useState(false);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [currEditPersonaId, setCurrEditPersonaId] = useState(null);

  useEffect(() => {
    getAllPersonas();
    getAllProducts();
    getAllUserGroups();
    getAllSettings();
  }, []);

  const handleUpdPersonaModal = (personaId) => {
    setCurrEditPersonaId(personaId);
    setIsPersonModalVisible(true);
  };

  const handleUpdOrgPersonas = (persona, orgIds) => {
    const curOrgIds = persona.organizations.map((x) => x.id);
    orgIds
      .filter((x) => !curOrgIds.includes(x))
      .forEach((x) => addPersonaOrganization(persona.id, { organization_id: x }));
    curOrgIds.filter((x) => !orgIds.includes(x)).forEach((x) => delPersonaOrganization(persona.id, x));
  };

  return (
    <React.Fragment>
      {error && <Alert message={error.message} description={error && JSON.stringify(error)} type="error" showIcon />}
      {isPersonModalVisible && (
        <PersonaModal
          personas={personas}
          persona={personas.find((x) => x.id === currEditPersonaId)}
          products={products}
          orgs={organizations}
          handleHide={() => {
            setIsPersonModalVisible(false);
            setCurrEditPersonaId(null);
          }}
          addSubmit={addPersona}
          updSubmit={updPersona}
        />
      )}
      <Button onClick={() => setIsPersonModalVisible(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        New persona
      </Button>
      <Checkbox checked={showAllColumns} onChange={() => setShowAllColumns(!showAllColumns)}>
        Show All Columns
      </Checkbox>
      <PersonasTable
        isLoading={isLoading}
        personas={personas}
        organizations={organizations}
        settings={settings}
        isAdmin={isAdmin}
        onUpdPersona={(personaId) => handleUpdPersonaModal(personaId)}
        onUpdOrgPersonas={handleUpdOrgPersonas}
        products={products}
        addPersonaProduct={addPersonaProduct}
        updPersonaProduct={updPersonaProduct}
        delPersonaProduct={delPersonaProduct}
        addProductSetting={addProductSetting}
        delProductSetting={delProductSetting}
        updPaymentDetail={updPaymentDetail}
        delPaymentMethod={delPaymentMethod}
        showAllColumns={showAllColumns}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getAllSettings,
  getAllPersonas,
  addPersona,
  updPersona,
  addPersonaOrganization,
  delPersonaOrganization,
  getAllProducts,
  getAllUserGroups,
  addProductSetting,
  delProductSetting,
  addPersonaProduct,
  updPersonaProduct,
  delPersonaProduct,
  updPaymentDetail,
  delPaymentMethod,
};

const mapStateToProps = ({ personasPage, settingsPage, productsData, userGroupsPage }) => ({
  settings: settingsPage.settings,
  personas: personasPage.personas,
  isLoading: personasPage.isLoading,
  error: personasPage.error,
  products: productsData.productsData,
  organizations: userGroupsPage.allUserGroups,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonasTab);
