import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  settings: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTINGS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload.settings,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_SETTINGS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case actionTypes.UPD_SETTING: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: state.settings.map((x) => (x.id === action.payload.data.id ? action.payload.data : x)),
      };
    }
    case actionTypes.ADD_SETTING: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: [action.payload.data, ...state.settings],
      };
    }
    case actionTypes.DEL_SETTING: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        settings: state.settings.filter((x) => x.id !== action.payload.settingId),
      };
    }

    default: {
      return state;
    }
  }
};
