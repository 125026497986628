import * as AT from '../actionTypes';
import {
  addNotificationAPI,
  getAllNotificationsAPI,
  deleteNotificationsAPI,
  linkNotificationToUsersAPI,
  updateNotificationAPI,
} from '../actions/notifications';

const notificationsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.ADD_NOTIFICATION: {
        dispatch(addNotificationAPI(action.payload));
        return next(action);
      }
      case AT.GET_ALL_NOTIFICATIONS: {
        dispatch(getAllNotificationsAPI());
        return next(action);
      }
      case AT.DELETE_NOTIFICATION: {
        dispatch(deleteNotificationsAPI(action.payload));
        return next(action);
      }
      case AT.LINK_NOTIFICATION_TO_USER: {
        dispatch(linkNotificationToUsersAPI(action.payload));
        return next(action);
      }
      case AT.UPDATE_NOTIFICATION: {
        dispatch(updateNotificationAPI(action.payload));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default notificationsMiddleware;
