import React, { useState, useEffect } from 'react';
import { Button, Typography, Spin, Popconfirm, Select, message, DatePicker } from 'antd';
import { useQuery } from 'react-query';
import moment from 'moment';

import InsightType from './InsightComponents/InsightType';
import InsightReleases from './InsightComponents/InsightReleases';
import InsightLabels from './InsightComponents/InsightLabels';
import InsightFormTextArea from './InsightComponents/insightFormTextarea';
import InsightPersonas from './InsightComponents/InsightPersonas';
import InsightJson from './InsightComponents/InsightJson';
import InsightRelations from './InsightComponents/InsightRelations';
import InsightOverrideCountries from './InsightComponents/insightOverrideCountries';
import InsightImages from './InsightComponents/InsightImages';
import InsightMarketingType from './InsightComponents/InsightMarketingType';
import InsightCtas from './InsightComponents/ctas/InsightCtas';
import InsightTimeline from './InsightComponents/timeline/InsightTimeline';

import { getDashboardURL } from '../../utils/helpers';
import { PRIORITY_TYPS } from '../../constants/priorityTyps';
import rem_ins_ico from '../../assets/icons/rem-ins.svg';
import copy_ins_ico from '../../assets/icons/copy_ins_ico.svg';
import { getInsightPersonas } from '../../api/insightsAPI';
import './InsightDataForm.scss';
import { MARKETING_INSIGHT_TYPES } from '../../constants/insightType';
import { UpdateAppModal } from './InsightComponents/updateAppModal';
import { mixPanelTracking } from 'monitoring/mixpanel';
import CreatableSelect from 'react-select/creatable';
import { useGetFocusAreas, useCreateFocusArea } from 'hooks/focus-areas';

const { Paragraph } = Typography;
const PRIORITY_TYPE_OPTIONS = [
  ...Object.keys(PRIORITY_TYPS).map((type) => ({
    text: PRIORITY_TYPS[type],
    value: type,
    id: type,
    key: type,
  })),
];

export default function InsightDataForm({
  origInsight,
  isUpdating,
  appID,
  ABTestVariants,
  InsightAssets,
  InsightPersonasList,
  releasesList,
  labelsSuggestions,
  authUser,

  updateInsight,
  removeInsight,
  copyInsight,
  addVariantRelease,
  delVariantRelease,
  isMarketing,
  curApp,
  curUserDetails,
}) {
  const [ins, insChange] = useState(origInsight);
  const [labels, labelsChange] = useState([]);

  const insightPersonasQuery = useQuery(['insightPersonas', ins.id], () => getInsightPersonas(ins.id), {
    onSuccess: (data) => setInsightPersonas(data.data),
  });
  const [insightPersonas, setInsightPersonas] = useState([]);

  useEffect(() => {
    insChange(origInsight);
  }, [origInsight]);

  const handleInsChange = (data) => {
    updateInsight(ins.id, data);
  };

  const handlePublish = () => {
    message.destroy();
    if (!ins.should_show) {
      let validated = true;
      const reqFields = isMarketing
        ? ['title', 'type', 'priority']
        : ['title', 'description', 'current_release_id', 'type', 'priority'];
      reqFields.find((field) => {
        if (!ins[field]) {
          message.error(`${field} is required`);
          validated = false;
        }
      });
      if (labels.length === 0) {
        message.error(`Labels is required`);
        validated = false;
      }
      if (ins.type !== 'indication' && !ins.thumbnail) {
        // TODO indication
        message.error(`Thumbnail is required`);
        validated = false;
      }

      if (MARKETING_INSIGHT_TYPES.includes(ins.type) && !ins.focus_area) {
        message.error(`Focus Area is required`);
        validated = false;
      }

      if (origInsight.type === 'ab_test') {
        if (
          !(
            ABTestVariants.data &&
            ABTestVariants.data.length > 1 &&
            ABTestVariants.data.every((aL) => aL.assets.length > 0)
          )
        ) {
          message.error(`There aren't variants or assets in variants`);
          validated = false;
        }
        if (!ins.ab_test) {
          message.error(`There isn't ab test ID`);
          validated = false;
        }
      }
      if (origInsight.type !== 'ab_test' && origInsight.type !== 'indication') {
        if (!(InsightAssets.data && InsightAssets.data.find((aL) => aL.assets[0]))) {
          message.error(`there are no assets attached`);
          validated = false;
        }
      }
      if (insightPersonas.length === 0) {
        message.error(`Insight without personas can't be published`);
        validated = false;
      }
      if (validated) {
        mixPanelTracking.track('insight_published', {
          ...ins,
          username: curUserDetails && curUserDetails.name,
          app_name: curApp.name,
        });
        handleInsChange({ should_show: !ins.should_show });
      }
    } else {
      handleInsChange({ should_show: !ins.should_show });
    }
  };

  const getFormattedAssetsLists = () => {
    if (ins.type === 'ab_test') {
      if (ABTestVariants.data && ABTestVariants.data && origInsight.current_release_id) {
        return ABTestVariants.data;
      }
      return null;
    } else if (InsightAssets && InsightAssets.data) {
      return InsightAssets.data;
    }
    return null;
  };

  const handleCampaignStart = (start_time) => {
    const start_time_moment = moment(start_time);
    handleInsChange({ start_time: start_time_moment.format('YYYY-MM-DD') });
  };

  const [changeAppModalOpen, setChangeAppModalOpen] = useState(false);

  const handleChangeAppClick = () => {
    setChangeAppModalOpen(true);
  };

  const handleChangeAppModalClose = () => {
    setChangeAppModalOpen(false);
  };

  const { vertical_id } = curApp;

  const { data: focusAreasData, isFetching: isFocusAreasFetching } = useGetFocusAreas({
    vertical_id,
  });

  const focusAreas = isFocusAreasFetching || !focusAreasData ? [] : focusAreasData.data;

  const { mutateAsync: createFocusAreaAsync } = useCreateFocusArea();

  const onCreateFocusArea = async (value) => {
    const res = await createFocusAreaAsync({ name: value, vertical_id });
    onSelectFocusArea({ value: res.data.id });
  };

  const onSelectFocusArea = ({ value }) => {
    handleInsChange({
      focus_area_id: value,
    });
  };

  return (
    <div className="ins-form">
      <div className="ins-data">
        <div className="ins-manage">
          {isUpdating && <Spin />}
          <UpdateAppModal
            insight={ins}
            curApp={curApp}
            visible={changeAppModalOpen}
            onCancel={handleChangeAppModalClose}
          />
          {MARKETING_INSIGHT_TYPES.includes(ins.type) && <Button onClick={handleChangeAppClick}>Change App</Button>}
          {authUser.isAdmin && (
            <Popconfirm
              title={`${
                ins.should_show
                  ? 'Are you sure? This insight might already be exposed to client?'
                  : 'Are you sure to publish?'
              } `}
              onConfirm={handlePublish}
            >
              <Button type="primary">{ins.should_show ? 'Unpublish' : 'Publish'}</Button>
            </Popconfirm>
          )}
          <a
            href={
              isMarketing
                ? `${getDashboardURL()}/marketing/${ins.id}`
                : `${getDashboardURL()}/intelligence/versions/${appID}/${ins.current_release_id}/${ins.id}`
            }
            target="_blank"
          >
            <Button>Preview</Button>
          </a>

          <Popconfirm title="Are you sure to copy this insight?" onConfirm={copyInsight}>
            <Button className="copy-insigh-btn">
              <img src={copy_ins_ico} />
            </Button>
          </Popconfirm>

          {authUser.isAdmin && (
            <Popconfirm title="Are you sure?" onConfirm={removeInsight}>
              <Button className="remove-insigh-btn">
                <img src={rem_ins_ico} />
              </Button>
            </Popconfirm>
          )}
        </div>
        {/* TITLE */}
        <h2 className="ins-data-h2">
          <Paragraph copyable={{ text: ins.title }}>{ins.title ? ins.title : 'NO TITLE'}</Paragraph>
          <h4>
            <Paragraph copyable={{ text: ins.id }}>
              Insight
              <span>{ins.id}</span>
            </Paragraph>
            <span className="updated-time">
              Last updated {moment.utc(ins.updated_at).local().format('DD/MM/YYYY HH:mm:ss')} by{' '}
              {ins.updated_by ? ins.updated_by.name : 'NO_NAME'}
            </span>
          </h4>
        </h2>
        {/* TYPE and ABTEST */}

        <InsightType
          insight={ins}
          handleInsChange={handleInsChange}
          appID={appID}
          isMarketing={isMarketing}
          variants={ABTestVariants ? ABTestVariants.data : null}
        />

        {/* Personas */}
        <section>
          <div className="fields">
            <div className="field width-50p">
              <label>
                Personas <span className="red-star">*</span>
              </label>
              <InsightPersonas
                insight={ins}
                suggestions={(InsightPersonasList ? InsightPersonasList.data : []).map((x) => {
                  const personaProducts =
                    x.persona_products.find(({ product }) => curApp?.name?.includes(product?.name)) ||
                    x.persona_products[0];

                  return {
                    id: x.id,
                    title: x.title,
                    location: x.location,
                    roles: (personaProducts || { settings: [] }).settings
                      .filter((x) => x.type === 'role')
                      .map((x) => x.value),
                  };
                })}
                insightPersonas={insightPersonas}
                setInsightPersonas={setInsightPersonas}
                insightPersonasQuery={insightPersonasQuery}
              />
            </div>
            <div className="field width-50p">
              <label>Countries</label>
              <InsightOverrideCountries ins={ins} handleInsChange={handleInsChange} />
            </div>
          </div>
        </section>

        {isMarketing && (
          <section>
            <div className="fields">
              <div className="field width-25p">
                <label>Start Date/Release Date/Date in Dashboard </label>
                <DatePicker
                  showTime
                  onChange={handleCampaignStart}
                  value={moment(ins.start_time)}
                  format="YYYY-MM-DD"
                  allowClear={false}
                />
              </div>
              {vertical_id && MARKETING_INSIGHT_TYPES.includes(ins.type) && (
                <div className="field width-25p">
                  <label>
                    Focus Area <span className="red-star">*</span>
                  </label>
                  <CreatableSelect
                    isClearable={false}
                    placeholder="Select or type to create"
                    // onCreateOption={onCreateFocusArea}
                    onChange={onSelectFocusArea}
                    value={
                      ins.focus_area
                        ? {
                            label: ins.focus_area.name,
                            value: ins.focus_area.id,
                          }
                        : undefined
                    }
                    options={focusAreas.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    }))}
                  />
                </div>
              )}
            </div>
          </section>
        )}

        {isMarketing && (
          <section>
            <InsightMarketingType handleInsChange={handleInsChange} insight={ins} curApp={curApp} />
          </section>
        )}

        {/* Title and Priority */}
        <section className="sec-title">
          <div className="insight-data-descr">
            <div className="fields">
              <div className="field width-50p">
                <InsightFormTextArea
                  insight={ins}
                  handleInsChange={handleInsChange}
                  title="Title"
                  field="title"
                  textarea={false}
                  required
                />
                {/* <Paragraph editable={{ onChange: (newValue) => handleInsChange({ title: newValue }) }}>{ins.title}</Paragraph> */}
              </div>
              <div className="field width-25p">
                <label>
                  Priority <span className="red-star">*</span>
                </label>
                <Select
                  value={ins.priority}
                  onChange={(val) => handleInsChange({ priority: val })}
                  getPopupContainer={(trNode) => trNode}
                  className={!!ins.priority || 'status-error'}
                >
                  {PRIORITY_TYPE_OPTIONS.map((opt) => (
                    <Select.Option value={opt.value} key={opt.key}>
                      {opt.text}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </section>

        {/* Description */}
        <section>
          <div className="fields">
            <div className="field ins-descr-wrap">
              <InsightFormTextArea
                insight={ins ? { ...ins, description: ins.description || curApp.name + ' ' } : null}
                handleInsChange={handleInsChange}
                title="Description"
                field="description"
                required
              />
            </div>
          </div>
        </section>

        {/* Labels */}
        <section>
          <div className="fields">
            <div className="field full-width" style={{ width: '100%' }}>
              <label>
                Insight Labels<span className="red-star">*</span>
              </label>
              <InsightLabels insightId={ins.id} suggestions={labelsSuggestions} setLabels={labelsChange} />
            </div>
          </div>
        </section>

        {/* Release */}
        {!isMarketing && (
          <section>
            {releasesList && releasesList.data && (
              <InsightReleases insight={ins} handleInsChange={handleInsChange} releasesList={releasesList} />
            )}
          </section>
        )}

        {/* Related Insights */}
        <section>
          <div className="fields">
            <div className="field related-ins-wrap full-width">
              <label>Related Insights</label>
              <InsightRelations insightId={ins.id} />
            </div>
          </div>
        </section>

        {/* Internal Comment */}
        <section>
          <div className="fields">
            <div className="field full-width">
              <InsightFormTextArea
                insight={ins.content}
                handleInsChange={handleInsChange}
                insideContent
                title="Internal Comment"
                field="internalComment"
              />
            </div>
          </div>
        </section>

        {isMarketing && (
          <section>
            <InsightTimeline insight={ins} />
          </section>
        )}

        {isMarketing && (
          <section>
            <InsightCtas insight={ins} />
          </section>
        )}

        <section className="images-wrapper">
          {getFormattedAssetsLists() && (
            <InsightImages
              origInsight={ins}
              glAssetsLists={getFormattedAssetsLists()}
              isABTest={ins.type === 'ab_test'}
              addVariantRelease={addVariantRelease}
              delVariantRelease={delVariantRelease}
              isFetching={ins.type === 'ab_test' ? ABTestVariants.isFetching : InsightAssets.isFetching}
              abTestId={ins.ab_test ? ins.ab_test.id : null}
              releaseId={ins.current_release_id}
              isMarketing={isMarketing}
              isDisabled={false}
            />
          )}
        </section>

        {/* JSON */}
        <div className="jsons-data">
          <div className="ins-json-wrap">
            <InsightJson json={ins} title="Insight JSON" />
          </div>
          <div className="ins-json-wrap">
            <InsightJson
              json={ins.type === 'ab_test' && ABTestVariants.data ? ABTestVariants.data : getFormattedAssetsLists()}
              title="Images"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
