import { Collapse } from 'antd';
import React from 'react';
import { PROBLEMS_AND_SOLTIONS } from './Problems';
import './ProblemsAndSolutions.scss';

export const ProblemsAndSolutions = () => {
  return (
    <div className="problems-and-solutions-container">
      <Collapse>
        {PROBLEMS_AND_SOLTIONS.map(({ heading, description }) => (
          <Collapse.Panel header={heading} key={heading}>
            {description}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};
