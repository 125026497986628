import React, { useState, useLayoutEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Button, Modal } from 'antd';

import CanvasedImage from '../ImageCanvas/CanvasedImage';
import PreviewVideo from '../InsightImagesModalEdit/PreviewVideo';
import InsightFormTextArea from '../../InsightComponents/insightFormTextarea';

import {
  getPreviewForAsset,
  createPreviewForVideoAsset,
  getPreviewStatusForVideoAsset,
} from '../../../../api/insightsAPI';
import './InsightImagesModalEdit.scss';

export default function InsightImagesModalEdit({
  assetsList,
  isABTest,
  assetsListChange,
  tableLayout,
  actAssetIndex,
  updAsset,
  closeModal,
  updVariant,
  savingAsset,
  savingAssetChange,
  visible,
}) {
  const curImg = assetsList.assets[actAssetIndex] || assetsList.assets[0];

  const [rects, rectsChange] = useState(curImg ? curImg.rects : []);
  const [previewLink, previewLinkChange] = useState(null);

  const [isZoomInModalVisible, setIsZoomInModalVisible] = useState(false);

  const [previewRequestID, previewRequestIDChange] = useState(null);
  const [previewVideo, previewVideoChange] = useState(null);
  const [previewVideoStatus, previewVideoStatusChange] = useState('');

  useLayoutEffect(() => {
    rectsChange(assetsList.assets[actAssetIndex].rects || []);
    previewLinkChange(null);
    previewRequestIDChange(null);
    previewVideoChange(null);
    previewVideoStatusChange(null);
  }, [actAssetIndex]);

  const updateImagePreviewMut = useMutation(getPreviewForAsset, {
    onSuccess: (res, variables) => {
      previewLinkChange(res.data.url_annotated);
    },
  });

  const createVideoPreviewMut = useMutation(createPreviewForVideoAsset, {
    onSuccess: (res, variables) => {
      previewRequestIDChange(res.data.asset_preview_id);
      getVideoPreviewStatusMut.mutateAsync(res.data.asset_preview_id);
    },
  });
  const getVideoPreviewStatusMut = useMutation(getPreviewStatusForVideoAsset, {
    onSuccess: (res, variables) => {
      if (res.data.url_annotated) {
        previewVideoChange(res.data.url_annotated);
        previewRequestIDChange(null);
        previewVideoStatusChange('ready');
      } else {
        if (res.data.status && res.data.status.includes('fps')) {
          previewVideoStatusChange('blurring');
        } else {
          previewVideoStatusChange(res.data.status);
        }
        setTimeout(() => {
          getVideoPreviewStatusMut.mutateAsync(previewRequestID);
        }, 3000);
      }
    },
  });

  const handleSaveAsset = (closeAfter) => {
    savingAssetChange(true);
    if (curImg.type === 'image') {
      updAsset(curImg.id, { rects });
    } else if (curImg.type === 'video') {
      updAsset(curImg.id, { url_annotated: previewVideo || curImg.url_annotated || curImg.url_raw });
    }
    previewRequestIDChange(null);
    previewVideoChange(null);
    previewVideoStatusChange(null);
    previewLinkChange(null);
    if (closeAfter) {
      closeModal();
    }
  };

  const updatePreview = () => {
    if (curImg.type === 'image') {
      updateImagePreviewMut.mutateAsync({
        asset_id: curImg.id,
        data: {
          ...curImg,
          rects,
        },
      });
    }
  };

  const handleRectChange = (rect) => {
    rect = {
      ...rect,
      removed: rect.removed === undefined ? undefined : String(rect.removed),
      key: rect.key === undefined ? undefined : String(rect.key),
    };
    let newRects;
    if (rect.removed) {
      newRects = rects.filter((x) => x.key !== rect.removed);
    } else {
      const index = rects.map((x) => x.key).indexOf(rect.key);
      if (index === -1) {
        newRects = [...rects, { ...rect }];
      } else {
        newRects = rects.map((x) => (x.key === rect.key ? { ...rect } : x));
      }
    }
    rectsChange(newRects);
  };

  const changeVariantValue = (data) => {
    updVariant(assetsList.id, data);
  };

  const blurFaces = (confidence) => {
    createVideoPreviewMut.mutateAsync({
      asset_id: curImg.id,
      data: {
        blur_faces: true,
        confidence,
      },
    });
    previewRequestIDChange(null);
    previewVideoChange(null);
    previewVideoStatusChange('start');
  };

  if (!curImg) {
    return;
  }

  return (
    <div>
      <Modal
        title="Mark image"
        centered
        visible={isZoomInModalVisible}
        onOk={() => {
          setIsZoomInModalVisible(false);
          updatePreview();
        }}
        onCancel={() => setIsZoomInModalVisible(false)}
        width="95%"
        className="zoom-in-canvas"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <CanvasedImage
          imgSrc={curImg.url_raw}
          key={`${curImg.id}-${curImg.orientation}-${
            rects.length > 0 ? rects.reduce((acc, curr) => `${acc}-${curr.type}`) : 'none'
          }`}
          rects={Object.fromEntries((rects || []).map((x) => [x.key, x]))}
          onRectChange={handleRectChange}
          isLandscape={curImg.orientation === 'landscape'}
          asset={curImg}
          isZoomIn
        />
      </Modal>
      <Modal
        visible={visible}
        title={isABTest ? `Variant #${assetsList.id}` : assetsList.type}
        centered
        width="95%"
        height="85%"
        footer={null}
        onCancel={() => {
          closeModal();
          assetsListChange(null);
        }}
        wrapClassName="modal-assetslist-edit"
      >
        <div className="mae-flex-wrap">
          <div className="mae-flex-left-side-table">
            {isABTest && (
              <React.Fragment>
                <div className="variant-value-manage">
                  <InsightFormTextArea
                    maxLength={1}
                    insight={assetsList}
                    handleInsChange={changeVariantValue}
                    title="Type"
                    field="type"
                    textarea={false}
                  />
                </div>
                <div className="variant-value-manage">
                  <InsightFormTextArea
                    insight={assetsList}
                    handleInsChange={changeVariantValue}
                    title="Value"
                    field="value"
                    textarea={false}
                  />
                </div>
              </React.Fragment>
            )}
            {tableLayout()}
          </div>
          {curImg.type === 'html' ? (
            <div>
              <Button
                type="link"
                target="_blank"
                href={`https://translate.google.com/translate?hl=en&ie=UTF-8&u=${curImg.url_raw}&sl=auto&tl=en`}
              >
                Translate
              </Button>
              <div className="iframe-container">
                <iframe
                  src={curImg.url_raw}
                  style={{
                    width: '55rem',
                    minHeight: '40rem',
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="mae-flex-right-side-images">
              {assetsList && actAssetIndex !== null && (
                <div className={`preview-wrap ${curImg.orientation}`}>
                  {!isZoomInModalVisible && curImg.type === 'image' && (
                    <CanvasedImage
                      imgSrc={curImg.url_raw}
                      asset={curImg}
                      key={`${curImg.id}-${curImg.orientation}-${
                        rects.length > 0 ? rects.reduce((acc, curr) => `${acc}-${curr.type}`) : 'none'
                      }`}
                      rects={Object.fromEntries((rects || []).map((x) => [x.key, x]))}
                      onRectChange={handleRectChange}
                      isLandscape={curImg.orientation === 'landscape'}
                      isZoomIn={isZoomInModalVisible}
                      onZoomIn={() => setIsZoomInModalVisible(true)}
                      url_annotated={curImg.url_annotated}
                      previewLink={previewLink}
                      updatePreview={updatePreview}
                      updatePreviewIsloading={updateImagePreviewMut.isLoading}
                    />
                  )}
                  {curImg.type === 'video' && (
                    <PreviewVideo
                      asset={curImg}
                      updatePreview={updatePreview}
                      blurFaces={blurFaces}
                      previewRequestID={previewRequestID}
                      previewVideo={previewVideo}
                      previewVideoStatus={previewVideoStatus}
                    />
                  )}
                </div>
              )}
              <div className="asset-manage">
                <Button onClick={() => closeModal()} type="ghost" className="asset-manage-btn">
                  Cancel
                </Button>
                <Button
                  onClick={() => handleSaveAsset(false)}
                  type="default"
                  className="asset-manage-btn"
                  loading={savingAsset}
                >
                  Save
                </Button>
                <Button onClick={() => handleSaveAsset(true)} type="primary" className="asset-manage-btn">
                  Save and Close
                </Button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
