/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getAbTests, updAbTest, addAbTest, deleteAbTest } from '../../redux/actions/abTestsPage';
import { getAppsData, getReleases } from '../../redux/actions/appsReleasesPage';
import { Alert, Button, Select, Input } from 'antd';
import AbTestsTable from './abTestTable/AbTestsTable';
import ABTestsModal from './abTestTable/forms/ABtestsModal';

const { Search } = Input;

const AbTests = ({
  getAbTests,
  abTests,
  isLoading,
  error,
  currentPage,
  pageSize,
  abTestsTotal,
  getAppsData,
  apps,
  filterAppId,
  filterHasInsights,
  searchQuery,
  updAbTest,
  getReleases,
  releases,
  addAbTest,
  deleteAbTest,
}) => {
  const [appSearchQuery, setAppSearchQuery] = useState('');
  const [showABTestsAddModal, setShowABTestsAddModal] = useState(false);
  const [showABTestsEditModal, setShowABTestsEditModal] = useState(false);
  const [currentAPPID, setCurrentAppID] = useState(null);
  const [currentAbTestModalEdit, setCurrentAbTestModalEdit] = useState(null);

  useEffect(() => {
    getAppsData();
    getAbTests(currentPage, pageSize);
  }, []);

  useEffect(() => {
    getReleases(currentAPPID);
  }, [currentAPPID]);

  const filteredApps = appSearchQuery.length
    ? apps
        .filter(
          (x) =>
            x.package_name.includes(appSearchQuery.toLowerCase()) || x.app_name.includes(appSearchQuery.toLowerCase())
        )
        .slice(0, 100)
    : [];
  const handleSetAppID = (appID) => setCurrentAppID(appID);
  const currentAbTest = abTests.find((x) => x.id === currentAbTestModalEdit);

  const handleAddABTestsSubmit = (abTest) => {
    // submit to the backend
    setShowABTestsAddModal(false);
    const thisRelease = releases.find((release) => release.id === abTest.release);
    addAbTest({
      ...abTest,
      status: 'active',
      app_id: thisRelease.app_id,
      start_release_id: thisRelease.id,
      app_data_id: null,
      end_release_id: null,
    });
  };
  const handleUpdateABTest = (abTest) => {
    setShowABTestsEditModal(false);
    updAbTest(abTest.id, abTest);
  };
  const handleDeleteABTest = (abTest) => {
    deleteAbTest(abTest.id);
  };

  return (
    <div className="ab-tests">
      <ABTestsModal
        visible={showABTestsAddModal}
        handleHide={() => setShowABTestsAddModal(false)}
        handleSubmit={handleAddABTestsSubmit}
        apps={filteredApps.length > 0 ? filteredApps : apps}
        releases={releases}
        handleSetAppID={handleSetAppID}
      />
      {showABTestsEditModal && (
        <ABTestsModal
          visible={showABTestsEditModal}
          handleHide={() => setShowABTestsEditModal(false)}
          handleSubmit={handleUpdateABTest}
          apps={apps.filter((app) => app.id === currentAbTest.app_id)}
          releases={releases}
          abTest={currentAbTest}
          handleSetAppID={handleSetAppID}
        />
      )}
      <div
        className="filters"
        style={{
          margin: '5px 10px',
          display: 'flex',
        }}
      >
        <Select
          showSearch
          allowClear
          filterOption={false}
          value={filterAppId}
          onSearch={(value) => setAppSearchQuery(value)}
          onChange={(value) => getAbTests(1, pageSize, value, searchQuery, null, filterHasInsights)}
          placeholder="App"
          style={{ minWidth: '30rem' }}
        >
          {filteredApps.map((app) => (
            <Select.Option value={app.id} key={app.id}>
              {app.app_name}({app.package_name}) - {app.platform}
            </Select.Option>
          ))}
        </Select>
        <Select
          value={filterHasInsights}
          onChange={(value) => getAbTests(1, pageSize, filterAppId, searchQuery, null, value)}
          placeholder="Has Insights"
          style={{ minWidth: '10rem', margin: '0 0.5rem' }}
        >
          <Select.Option value={null} key={null}>
            &nbsp;
          </Select.Option>
          <Select.Option value={1} key="yes">
            Yes
          </Select.Option>
          <Select.Option value={0} key="no">
            No
          </Select.Option>
        </Select>
        <Search
          placeholder="Search by Release(Name, Code), Internal Name..."
          enterButton
          onSearch={(value) => getAbTests(1, pageSize, filterAppId, value, null, filterHasInsights)}
        />
      </div>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      <Button
        type="primary"
        style={{ margin: '16px 0 16px 5px' }}
        onClick={() => {
          setShowABTestsAddModal(true);
        }}
      >
        Add New AB Test
      </Button>
      <AbTestsTable
        isLoading={isLoading}
        abTests={abTests}
        currentPage={currentPage}
        pageSize={pageSize}
        abTestsTotal={abTestsTotal}
        getAbTests={(_page, _size) => getAbTests(_page, _size, filterAppId, searchQuery, null, filterHasInsights)}
        updAbTest={updAbTest}
        setShowAbTestEditModal={setShowABTestsEditModal}
        setCurrentAbTestModalEdit={setCurrentAbTestModalEdit}
        handleDeleteABTest={handleDeleteABTest}
      />
    </div>
  );
};

const mapDispatchToProps = {
  getAbTests,
  getAppsData,
  updAbTest,
  getReleases,
  addAbTest,
  deleteAbTest,
};

const mapStateToProps = ({ abTestsPage, appsReleasesPage }) => ({
  abTests: abTestsPage.abTests,
  isLoading: abTestsPage.isLoading,
  error: abTestsPage.error,
  abTestsTotal: abTestsPage.abTestsTotal,
  currentPage: abTestsPage.currentPage,
  pageSize: abTestsPage.pageSize,
  apps: abTestsPage.apps,
  filterAppId: abTestsPage.filterAppId,
  filterHasInsights: abTestsPage.filterHasInsights,
  searchQuery: abTestsPage.searchQuery,
  releases: appsReleasesPage.releases,
});

export default connect(mapStateToProps, mapDispatchToProps)(AbTests);
