import React from 'react';
import { Spin, Table, Tag } from 'antd';
import Img from 'react-image';
import moment from 'moment';
import { value } from 'lodash/seq';

const getWarningsByAbTestInsights = (insights) => {
  let warnings = [];
  const indications = insights.filter((x) => x.type === 'indication').length;
  if (indications > 1) {
    warnings = [...warnings, 'More than 1 indication insights'];
  }
  const sortedInsights = [...insights].sort((a, b) => moment(a.start_time) - moment(b.start_time));
  if (indications && sortedInsights[0].type !== 'indication') {
    warnings = [...warnings, 'Indication insight should be first'];
  }
  if (insights.length !== new Set(insights.map((x) => x.start_time)).size) {
    warnings = [...warnings, 'non-unique start_time'];
  }
  if (moment(sortedInsights[sortedInsights.length - 1].start_time) < moment().subtract(11, 'months')) {
    warnings = [...warnings, 'insight has active AB test but older that 11 months'];
  }
  return warnings;
};

export default ({ isLoading, insights }) => {
  const appFilters = [];
  insights.forEach((insightData) => {
    insightData.insights.forEach((i) => {
      const newVal = {
        text: i.app.name,
        value: i.app.name,
      };
      if (!appFilters.some((f) => f.text === newVal.text)) {
        appFilters.push(newVal);
      }
    });
  });

  const columns = [
    {
      title: 'AB Test ID',
      dataIndex: 'ab_test_id',
      width: '10rem',
    },
    {
      title: 'Warnings',
      dataIndex: 'warnings',
      width: '30rem',
      render: (value, record) => (
        <ul>
          {value.map((x) => (
            <li>
              <span
                style={{
                  background: record.warnings.length ? '#fff2f0' : undefined,
                  padding: '3px',
                }}
              >
                {x}
              </span>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: 'Insights',
      dataIndex: 'insights',
      filters: appFilters,
      width: '50rem',
      onFilter: (value, record) => record.insights.some((i) => i.app.name === value),

      render: (value) => (
        <React.Fragment>
          <ul>
            {value.map((x) => (
              <li style={{ display: 'flex' }}>
                <Img
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  src={[x.app && x.app.icon]}
                  alt={`icon ${x.id}`}
                  title={x.app && `#${x.app.id} ${x.app.name} - ${x.app.platform} (${x.app.package_name})`}
                  loader={<Spin />}
                  container={(children) => (
                    <div
                      style={{
                        width: '20px',
                        height: '20px',
                      }}
                    >
                      {children}
                    </div>
                  )}
                />
                <span style={{ marginLeft: '1rem' }}>
                  <b>{x.start_time}</b>&nbsp;
                  <Tag
                    color={
                      {
                        ab_test: 'green',
                        indication: 'purple',
                      }[x.type] || 'red'
                    }
                  >
                    {x.type}
                  </Tag>
                  <span style={{ marginRight: '1rem' }}>#{x.id}</span>
                  <a
                    href={`#/insights/${x.app && x.app.name}/${x.app && x.app.package_name}/${
                      x.app && x.app.platform
                    }/${x.id}`}
                    target="_blank"
                  >
                    {x.title}
                  </a>
                  <i>{x.analyst_name && ` by ${x.analyst_name}`}</i>
                </span>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div style={{ width: '120rem' }}>
      <Table
        loading={isLoading}
        dataSource={insights.map((x) => ({
          ...x,
          warnings: getWarningsByAbTestInsights(x.insights),
        }))}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
    </div>
  );
};
