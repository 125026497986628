import { backendApiRequest } from './request';

export const getPresignedUrl = async (filename) =>
  backendApiRequest({
    method: 'GET',
    path: '/reports/presigned-post-url',
    params: { filename },
  });

export const getReportEmailContent = async ({ reportId }) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/reports/${reportId}/email-content`,
  });

export const addReportProduct = async ({ reportId, productId }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/reports/${reportId}/products`,
    body: { product_id: productId },
  });

export const delReportProduct = async ({ reportId, productId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/reports/${reportId}/products/${productId}`,
  });
