/* eslint-disable react/prop-types */
import React from 'react';
import { Button } from 'antd';

export default ({ releaseID, reportDownloadUrls, onDownloadReports, reportDir, disabled }) => {
  const isLoading = reportDownloadUrls[releaseID] === null;
  const downloadUrl =
    reportDownloadUrls[releaseID] &&
    reportDownloadUrls[releaseID][reportDir] &&
    reportDownloadUrls[releaseID][reportDir].url;
  const error =
    reportDownloadUrls[releaseID] &&
    reportDownloadUrls[releaseID][reportDir] &&
    reportDownloadUrls[releaseID][reportDir].error;

  return (
    <React.Fragment>
      {downloadUrl ? (
        <a href={downloadUrl}>Download[{reportDir}]</a>
      ) : (
        <Button onClick={() => onDownloadReports(releaseID, reportDir)} loading={isLoading} disabled={disabled}>
          Get link[{reportDir}]
        </Button>
      )}
      {error && <b>{error}</b>}
    </React.Fragment>
  );
};
