import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  status: '',
  notificationsData: [],
};

const notificationsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_NOTIFICATION: {
      return { ...state, status: 'Adding notification' };
    }
    case actionTypes.ADD_NOTIFICATION_SUCCESS: {
      const { notification } = action.payload;
      return { ...state, status: '', notificationsData: [notification, ...state.notificationsData] };
    }
    case actionTypes.ADD_NOTIFICATION_FAILED: {
      const { errorStatus } = action.payload;
      return { ...state, status: `Error :${errorStatus}` };
    }
    case actionTypes.GET_ALL_NOTIFICATIONS_SUCCESS: {
      return { ...state, notificationsData: action.payload.notificationsData };
    }
    case actionTypes.DELETE_NOTIFICATION_SUCCESS: {
      const { id } = action.payload;
      return {
        ...state,
        notificationsData: state.notificationsData.filter((x) => x.id !== id),
      };
    }
    case actionTypes.LINK_NOTIFICATION_TO_USER_SUCCESS: {
      console.log(action.payload);
      return { ...state };
    }
    case actionTypes.LINK_NOTIFICATION_TO_USER_FAILED: {
      const { errorStatus } = action.payload;
      return { ...state, status: `Error :${errorStatus}` };
    }
    case actionTypes.UPDATE_NOTIFICATION_SUCCESS: {
      const { notification } = action.payload;
      return {
        ...state,
        notificationsData: state.notificationsData.map((x) => (x.id === notification.id ? notification : x)),
      };
    }
    default: {
      return state;
    }
  }
};

export default notificationsReducer;
