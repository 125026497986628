/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form, Input, InputNumber, Checkbox, Row, Col, Select } from 'antd';
import { PLATFORMS } from '../../../constants/platforms';

const { Option } = Select;

class AppForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProduct: props.app ? props.app.product.name : '',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };
  productChange = (value) => {
    this.setState({ selectedProduct: value });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="add-app-form">
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="App Name">
              {getFieldDecorator('app_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the App Name!',
                  },
                ],
                initialValue: this.props.app && this.props.app.app_name,
              })(<Input placeholder="App Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Display Name">
              {getFieldDecorator('display_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the App Display Name!',
                  },
                ],
                initialValue: this.props.app && this.props.app.display_name,
              })(<Input placeholder="Display Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Package Name">
              {getFieldDecorator('package_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the App Package Name',
                  },
                ],
                initialValue: this.props.app && this.props.app.package_name,
              })(<Input placeholder="Package Name" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Platform">
              {getFieldDecorator('platform', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter App Platform!',
                  },
                ],
                initialValue: this.props.app && this.props.app.platform,
              })(
                <Select placeholder="Platform">
                  {Object.values(PLATFORMS).map((x) => (
                    <Select.Option key={x} value={x}>
                      {x}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Product">
              {getFieldDecorator('product_id', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter a product',
                  },
                ],
                initialValue: this.props.app && this.props.app.product_id,
              })(
                <Select
                  showSearch
                  onChange={this.productChange}
                  placeholder="Product"
                  value={this.state.selectedProduct}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.props.products.map((x) => (
                    <Option key={x.id} value={x.id}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Developer">
              {getFieldDecorator('developer', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter the App Developer!',
                  },
                ],
                initialValue: this.props.app && this.props.app.developer,
              })(<Input placeholder="Developer" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item label="Icon">
              {getFieldDecorator('icon_original', {
                rules: [
                  {
                    type: 'url',
                    message: 'Please enter a valid url',
                  },
                ],
                initialValue: this.props.app && this.props.app.icon_original,
              })(<Input placeholder="Icon" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Downloads">
              {getFieldDecorator('downloads', {
                rules: [],
                initialValue: this.props.app && this.props.app.downloads,
              })(<Input placeholder="Downloads" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Raters">
              {getFieldDecorator('raters', {
                rules: [],
                initialValue: this.props.app && this.props.app.raters,
              })(<InputNumber placeholder="Raters" precision={0} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Rating">
              {getFieldDecorator('rating', {
                rules: [],
                initialValue: this.props.app && this.props.app.rating,
              })(<InputNumber placeholder="Rating" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('should_compare', {
                rules: [],
                valuePropName: 'checked',
                initialValue: this.props.app ? this.props.app.should_compare : false,
              })(<Checkbox>Should Compare</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('is_monitored', {
                rules: [],
                valuePropName: 'checked',
                initialValue: this.props.app ? this.props.app.is_monitored : false,
              })(<Checkbox>Is monitored</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedAppCreationForm = Form.create({ name: 'app_form' })(AppForm);

export default function AppModal({ visible, handleHide, handleSumit, app, products }) {
  let formRef = null;
  return (
    <Modal
      title={app ? `Change app #${app.id}` : 'Add New App'}
      centered
      visible={visible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            handleSumit({ ...values, id: app ? app.id : undefined });
            if (app) {
              formRef.props.form.resetFields();
            }
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
        if (app) {
          formRef.props.form.resetFields();
        }
      }}
    >
      <WrappedAppCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        app={app}
        products={products}
      />
    </Modal>
  );
}
