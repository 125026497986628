import { message } from 'antd';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import {
  addAssetToInsight,
  addAssetToVariant,
  addInsightPersona,
  addVarintToABTest,
  createInsight,
  getReleasesByApp,
  uploadFileToS3FromUrl,
} from '../../api/insightsAPI';
import {
  getDeviceScreen,
  getDevices,
  getDevicesApps,
  getInsights,
  getVersion,
  saveDeviceScreenshotToS3,
  saveInsight,
  getDeviceScreenshot,
} from '../../api/workbenchAPI';
import { ALL_PLATFORMS, PLATFORMS } from '../../constants/platforms';
import { loadApps } from '../../redux/actions/apps';
import { getReleases } from '../../redux/actions/appsReleasesPage';
import { changeWbConfig } from '../../redux/reducers/workbench';
import './Workbench.scss';
import { AddToInsight } from './components/addToInsight';
import { CanvasedDeviceViewer } from './components/canvasedDeviceViewer';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { HintsPanel } from './components/hintsPanel/HintsPanel';
import { InsightsTabs } from './components/insightsTabs';
import { useScreenTranslation } from './hooks/useScreenTranslation';
import { useUjAutoMode } from './hooks/useUjAutoMode';
import { useVideoCanvasSocket } from './hooks/useVideoCanvasSocket';
import { useVideoRecord } from './hooks/useVideoRecord';
import { useWorkbenchSocket } from './hooks/useWorkbenchSocket';
import { WbServicesMock } from 'components/workbench_v2/mocks/wb_services';
import {
  ASSET_TYPES,
  CUSTOM_DEVICE,
  EDITING_MODES,
  INSIGHT_ASSET_TYPES,
  LEFT_VIDEO_CONTAINER_ID,
  REMOTE_OPTIONS,
  RIGHT_VIDEO_CONTAINER_ID,
  SIMPLE_DEVICE_MANAGER_VERSION,
  URL_PARAMS,
  VIEWS,
} from './utils/consts';
import { compareVersions, replaceWbHistoryParams, showMessageByVersionDiff } from './utils/helpers';

const Workbench = ({ apps: appsFromState, loadApps, history, releases, getReleases, config, changeWbConfig }) => {
  const [error, setError] = useState(true);
  const [currApp, setCurrApp] = useState(null);
  const [devices, setDevices] = useState([]);
  const [leftDevice, setLeftDevice] = useState(null);
  const [rightDevice, setRightDevice] = useState(null);
  const [leftDeviceAppVer, setLeftDeviceAppVer] = useState(null);
  const [rightDeviceAppVer, setRightDeviceAppVer] = useState(null);
  const [editingModeStep, setEditingModeStep] = useState(EDITING_MODES.None);
  const [currentView, setCurrentView] = useState(VIEWS.VIEW_LEFT);
  const [insights, setInsights] = useState([]);
  const [persona, setPersona] = useState(null);
  const [leftRects, setLeftRects] = useState([]);
  const [rightRects, setRightRects] = useState([]);
  const [selectedAddToInsight, setSelectedAddToInsight] = useState(null);
  const [deviceError, setDeviceError] = useState(null);
  const [leftDeviceImages, setLeftDeviceImages] = useState([]);
  const [rightDeviceImages, setRightDeviceImages] = useState([]);
  const [progress, setProgress] = useState(null);
  const [diff, setDiff] = useState(null);
  const [linkDevices, setLinkDevices] = useState(false);
  const [serverEvents, setServerEvents] = useState([]);
  const [hintsProgress, setHintProgress] = useState(null);
  const [graphData, setGraphData] = useState({});
  const [rp, setRp] = useState({});

  const imagesWithRectsUJ = [...leftDeviceImages, ...rightDeviceImages]
    .filter(({ type }) => type !== INSIGHT_ASSET_TYPES.Video)
    .reverse();

  const apps = useMemo(
    () =>
      appsFromState
        .filter(
          ({ platform }) =>
            platform === PLATFORMS.android || platform === PLATFORMS.marketing || platform === PLATFORMS.ios
        )
        .map((app) => ({
          ...app,
          version_name: app.version_name || '',
        })),
    [appsFromState]
  );

  const rightVideoUrl = config.videoSocketUrlRight;
  let leftVideoUrl = config.videoSocketUrlLeft;

  if (rightDevice && leftDevice && rightDevice.serial === leftDevice.serial) {
    leftVideoUrl = rightVideoUrl;
  }

  const { handleAutoDelayTrigger, isAutoModeEnabled, setIsAutoModeEnabled } = useUjAutoMode();

  const { socket, leftDeviceStreamImage, rightDeviceStreamImage } = useWorkbenchSocket(
    config.wsUrl,
    leftDevice,
    rightDevice,
    setError,
    setDeviceError,
    deviceError,
    setProgress,
    setDiff,
    setLeftRects,
    setRightRects,
    setServerEvents,
    setHintProgress,
    setGraphData,
    setRp
  );

  const {
    sendMouseEvent: leftDeviceEvent,
    sendControlEvent: leftDeviceControlEvent,
    sendKeyEvent: leftDeviceKeyEvent,
    deviceWidth: leftDeviceWidth,
    deviceHeight: leftDeviceHeight,
    reconnectDevice: reconnectLeftDevice,
    deviceConnected: leftDeviceConnected,
  } = useVideoCanvasSocket(
    leftVideoUrl,
    LEFT_VIDEO_CONTAINER_ID,
    leftDevice ? leftDevice.serial : null,
    leftDevice ? leftDevice.platform : null,
    leftDevice ? leftDevice.width : null,
    leftDevice ? leftDevice.height : null,
    leftDeviceStreamImage
  );

  const {
    sendMouseEvent: rightDeviceEvent,
    sendControlEvent: rightDeviceControlEvent,
    sendKeyEvent: rightDeviceKeyEvent,
    deviceWidth: rightDeviceWidth,
    deviceHeight: rightDeviceHeight,
    reconnectDevice: reconnectRightDevice,
    deviceConnected: rightDeviceConnected,
  } = useVideoCanvasSocket(
    rightVideoUrl,
    RIGHT_VIDEO_CONTAINER_ID,
    rightDevice ? rightDevice.serial : null,
    rightDevice ? rightDevice.platform : null,
    rightDevice ? rightDevice.width : null,
    rightDevice ? rightDevice.height : null,
    rightDeviceStreamImage
  );

  const leftDeviceMouseEvent = (...args) => {
    leftDeviceEvent(...args);
  };

  const rightDeviceMouseEvent = (...args) => {
    rightDeviceEvent(...args);

    // if devices are linked right device controls both
    if (linkDevices && rightDevice.serial !== leftDevice.serial) {
      leftDeviceEvent(...args);
    }
  };

  const leftDeviceKeyEventHandle = (...args) => {
    leftDeviceKeyEvent(...args);
  };

  const rightDeviceKeyEventHandle = (...args) => {
    rightDeviceKeyEvent(...args);

    if (linkDevices && rightDevice.serial !== leftDevice.serial) {
      leftDeviceKeyEvent(...args);
    }
  };

  const queryClient = useQueryClient();

  const isAnyQueryLoading = Boolean(queryClient.isMutating() || queryClient.isFetching());

  const swapDevicesOnVersion = (leftDevice, rightDevice, leftDeviceAppVer, rightDeviceAppVer) => {
    if (!leftDeviceAppVer || !rightDeviceAppVer || !leftDevice || !rightDevice) return false;

    if (compareVersions(leftDeviceAppVer.version_name, rightDeviceAppVer.version_name) <= 0) return false;

    replaceWbHistoryParams(
      `${URL_PARAMS.LeftDevice}=${rightDevice.serial}&${URL_PARAMS.RightDevice}=${leftDevice.serial}`,
      history
    );

    return true;
  };

  useQuery('Version', getVersion, {
    onSuccess(res) {
      showMessageByVersionDiff(SIMPLE_DEVICE_MANAGER_VERSION, res.version);
    },
  });

  useQuery('Devices', getDevices, {
    onSuccess(res) {
      setDevices(res.devices);
    },
  });

  const addVariantMutation = useMutation(addVarintToABTest);

  const rightDeviceScreenQuery = useQuery('DeviceRightDeviceScreen', () => getDeviceScreenshot(rightDevice.serial), {
    enabled: false,
    onSuccess: (data) => {
      const newImage = {
        image: 'data:image/png;base64, ' + data.image,
        rects: JSON.parse(JSON.stringify(rightRects)),
      };
      setRightDeviceImages((prev) => [newImage, ...prev]);
    },
  });

  const leftDeviceScreenQuery = useQuery('DeviceLeftDeviceScreen', () => getDeviceScreenshot(leftDevice.serial), {
    enabled: false,
    onSuccess: (data) => {
      const newImage = {
        image: 'data:image/png;base64, ' + data.image,
        rects: JSON.parse(JSON.stringify(rightRects)),
      };
      setLeftDeviceImages((prev) => [newImage, ...prev]);
    },
  });

  const leftDeviceAppsQuery = useQuery(
    ['Devices', leftDevice ? leftDevice.serial : '', currApp ? currApp.package : ''],
    () => getDevicesApps(leftDevice.serial),
    {
      enabled: Boolean(leftDevice && leftDevice.serial !== CUSTOM_DEVICE.serial),
      onSuccess(res) {
        if (currApp) {
          const leftVersion = res.apps.find((app) => app.package === currApp.package);

          if (swapDevicesOnVersion(leftDevice, rightDevice, leftVersion, rightDeviceAppVer)) return;

          setLeftDeviceAppVer(leftVersion);
        }
      },
    }
  );

  const rightDeviceAppsQuery = useQuery(
    ['Devices', rightDevice ? rightDevice.serial : '', currApp ? currApp.package : ''],
    () => getDevicesApps(rightDevice.serial),
    {
      enabled: Boolean(rightDevice && rightDevice.serial !== CUSTOM_DEVICE.serial),
      onSuccess(res) {
        if (currApp) {
          const rightVersion = res.apps.find((app) => app.package === currApp.package);

          if (swapDevicesOnVersion(leftDevice, rightDevice, leftDeviceAppVer, rightVersion)) return;

          setRightDeviceAppVer(rightVersion);
        }
      },
    }
  );

  useQuery(['Insights', currApp ? currApp.package : ''], () => getInsights(currApp.package, currApp.platform), {
    enabled: !!(currApp && currApp.package),
    onSuccess(res) {
      if (currApp) {
        setInsights(res);
      }
    },
  });

  const appReleases = useQuery(['appReleases', currApp ? currApp.id : null], () => getReleasesByApp(currApp.id), {
    enabled: !!(currApp && currApp.id),
  });

  const setInsightDataMutation = useMutation(saveInsight, {
    onSuccess: (res) => {
      setInsights([{ ...res.data }, ...insights]);
    },
  });

  const addInsightPersonaMutation = useMutation(addInsightPersona, {
    onSuccess: (data) => {
      setPersona(data.data.persona);
    },
  });

  const addAssetMutation = useMutation(addAssetToInsight, {
    onSuccess: () => {
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
  });

  const addAssetToVariantMutation = useMutation(addAssetToVariant, {
    onSuccess() {
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
  });

  const createInsightMutation = useMutation(createInsight, {
    onSuccess: async (res, { leftDeviceImagesData, rightDeviceImagesData, ...vars }) => {
      setInsightDataMutation.mutate({ insightID: res.data.id, body: vars });

      if (persona) {
        addInsightPersonaMutation.mutate({
          insightId: res.data.id,
          personaId: persona.id,
        });
      } else {
        message.warn('Insight was created without a persona', 5);
      }

      message.success('Insight was created successfully!');

      const uploadAssetImages = async (imagesWithRects, type, variant_id) => {
        for (const { type: assetType, url, rects } of imagesWithRects) {
          if (variant_id) {
            await addAssetToVariantMutation.mutateAsync({
              variant_id,
              data: {
                insight_asset_type: type,
                orientation: 'portrait',
                type: assetType,
                uploaded_file_name: url,
                rects,
              },
            });
          } else {
            await addAssetMutation.mutateAsync({
              insight_id: res.data.id,
              data: {
                insight_asset_type: type,
                type: assetType,
                orientation: 'portrait',
                uploaded_file_name: url,
                text: '',
                is_hidden: false,
                rects,
              },
            });
          }
        }
      };

      if (vars.type === 'ab_test') {
        await addVariantMutation.mutateAsync(
          {
            abtest_id: vars.ab_test_id,
            data: {
              control: true,
              release_id: vars.current_release_id,
              type: 'A',
              value: '',
            },
          },
          {
            onSuccess(data) {
              const { id, type } = data.data;
              uploadAssetImages(leftDeviceImagesData, type, id);
            },
          }
        );

        await addVariantMutation.mutateAsync(
          {
            abtest_id: vars.ab_test_id,
            data: {
              control: false,
              release_id: vars.current_release_id,
              type: 'B',
              value: '',
            },
          },
          {
            onSuccess(data) {
              const { id, type } = data.data;
              uploadAssetImages(rightDeviceImagesData, type, id);
            },
          }
        );

        return;
      }

      uploadAssetImages(leftDeviceImagesData, ASSET_TYPES.Previous);
      uploadAssetImages(rightDeviceImagesData, ASSET_TYPES.Current);
    },
    onError: () => {
      message.error('Error occured while creating insight');
    },
  });

  const createScreenshotMutation = useMutation(
    async () => {
      const leftDeviceImagesData = await Promise.all(
        leftDeviceImages.map(async ({ type, videoUrl, image, rects }) => {
          if (type === INSIGHT_ASSET_TYPES.Video) {
            const { uploaded_file_name } = await uploadFileToS3FromUrl({ fileUrl: videoUrl });

            return {
              type: INSIGHT_ASSET_TYPES.Video,
              url: uploaded_file_name,
            };
          }

          const { url } = await saveDeviceScreenshotToS3(leftDevice.serial, {
            filedata: image.split(', ')[1],
          });

          return {
            type: INSIGHT_ASSET_TYPES.Image,
            url,
            rects,
          };
        })
      );

      const rightDeviceImagesData = await Promise.all(
        rightDeviceImages.map(async ({ type, videoUrl, image, rects }) => {
          if (type === INSIGHT_ASSET_TYPES.Video) {
            const { uploaded_file_name } = await uploadFileToS3FromUrl({ fileUrl: videoUrl });

            return {
              type: INSIGHT_ASSET_TYPES.Video,
              url: uploaded_file_name,
            };
          }

          const { url } = await saveDeviceScreenshotToS3(rightDevice.serial, {
            filedata: image.split(', ')[1],
          });

          return {
            type: INSIGHT_ASSET_TYPES.Image,
            url,
            rects,
          };
        })
      );

      return {
        leftDeviceImagesData,
        rightDeviceImagesData,
      };
    },
    {
      onSuccess: ({ leftDeviceImagesData, rightDeviceImagesData }, { mode, args }) => {
        if (mode === 'create') {
          createInsightMutation.mutateAsync({
            leftDeviceImagesData,
            rightDeviceImagesData,
            ...args,
          });
        }

        if (mode === 'add') {
          leftDeviceImagesData.forEach(({ type, url, rects }) =>
            addAssetMutation.mutateAsync({
              insight_id: selectedAddToInsight,
              data: {
                insight_asset_type: ASSET_TYPES.Previous,
                type,
                orientation: 'portrait',
                uploaded_file_name: url,
                text: '',
                is_hidden: false,
                rects: rects,
              },
            })
          );

          rightDeviceImagesData.forEach(({ type, url, rects }) =>
            addAssetMutation.mutateAsync({
              insight_id: selectedAddToInsight,
              data: {
                insight_asset_type: ASSET_TYPES.Current,
                type,
                orientation: 'portrait',
                uploaded_file_name: url,
                text: '',
                is_hidden: false,
                rects: rects,
              },
            })
          );

          message.success('Successfully added to insight');
        }
      },
    }
  );

  useLayoutEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const remote = params.get(URL_PARAMS.Remote);
    const mockEnabled = params.get(URL_PARAMS.MockEnabled);

    if (remote) {
      const { videoSocketUrlLeft, videoSocketUrlRight, deviceServicesHostUrl, wsUrl } = REMOTE_OPTIONS.find(
        (option) => option.name === remote
      );

      changeWbConfig({
        videoSocketUrlLeft,
        videoSocketUrlRight,
        deviceServicesHostUrl,
        wsUrl,
      });
    }

    if (mockEnabled) {
      WbServicesMock.setEnabled(true);
    }
  }, []);

  useEffect(() => {
    const sessionParams = sessionStorage.getItem('workbenchParams');

    loadApps(ALL_PLATFORMS);
    replaceWbHistoryParams(sessionParams, history);
  }, []);

  useEffect(() => {
    // in case one of the devices is custom and the version was chaged, check if devices need to be swapped
    if (
      rightDevice &&
      leftDevice &&
      (leftDevice.serial === CUSTOM_DEVICE.serial || rightDevice.serial === CUSTOM_DEVICE.serial)
    ) {
      swapDevicesOnVersion(leftDevice, rightDevice, leftDeviceAppVer, rightDeviceAppVer);
    }
  }, [rightDeviceAppVer, leftDeviceAppVer]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const remoteAddress = params.get(URL_PARAMS.Remote);

    if (!remoteAddress) {
      replaceWbHistoryParams(`${URL_PARAMS.Remote}=${REMOTE_OPTIONS[0].name}`, history);
      history.go(0);
    }

    if (devices.length && !params.get(URL_PARAMS.LeftDevice)) {
      setLeftDevice(devices[0]);
    }

    if (devices.length && !params.get(URL_PARAMS.RightDevice)) {
      setRightDevice(devices[0]);
    }

    if (apps && devices.length && history.location.search) {
      const params = new URLSearchParams(history.location.search);
      const appId = Number(params.get(URL_PARAMS.AppId));
      const leftDeviceSerial = params.get(URL_PARAMS.LeftDevice);
      const rightDeviceSerial = params.get(URL_PARAMS.RightDevice);
      const selectedApp = apps.find((app) => app.id === appId);
      const lDevice =
        leftDeviceSerial === CUSTOM_DEVICE.serial
          ? CUSTOM_DEVICE
          : devices.find((device) => device.serial === leftDeviceSerial);
      const rDevice =
        rightDeviceSerial === CUSTOM_DEVICE.serial
          ? CUSTOM_DEVICE
          : devices.find((device) => device.serial === rightDeviceSerial);

      if (rightDeviceSerial === CUSTOM_DEVICE.serial || leftDeviceSerial === CUSTOM_DEVICE.serial) {
        getReleases(appId);
      }

      // preserve previous right custom version if devices were swapped
      if (rightDevice && rightDevice.serial === CUSTOM_DEVICE.serial && rightDevice.serial !== rDevice.serial) {
        setLeftDeviceAppVer(rightDeviceAppVer);
      }

      // preserve previous left custom version if devices were swapped
      if (leftDevice && leftDevice.serial === CUSTOM_DEVICE.serial && leftDevice.serial !== lDevice.serial) {
        setRightDeviceAppVer(leftDeviceAppVer);
      }

      sessionStorage.setItem('workbenchParams', history.location.search);

      if (selectedApp) {
        setCurrApp(selectedApp);
      }

      setLeftDevice(lDevice || devices[0]);
      setRightDevice(rDevice || devices[0]);
    }
  }, [apps, history.location.search, devices]);

  const handleRectChange = (direction) => (rect) => {
    const rects = direction === VIEWS.VIEW_LEFT ? leftRects : rightRects;
    let newRects;

    rect = {
      ...rect,
      removed: rect.removed === undefined ? undefined : String(rect.removed),
      key: rect.key === undefined ? undefined : String(rect.key),
    };

    if (rect.removedAll) {
      newRects = [];
    } else if (rect.removed) {
      newRects = rects.filter((x) => x.key !== rect.removed);
    } else {
      const index = rects.map((x) => x.key).indexOf(rect.key);
      if (index === -1) {
        newRects = [...rects, { ...rect }];
      } else {
        newRects = rects.map((x) => (x.key === rect.key ? { ...rect } : x));
      }
    }

    if (direction === VIEWS.VIEW_LEFT) {
      setLeftRects(newRects);
    } else {
      setRightRects(newRects);
    }
  };

  const onCreateInsightHandle = (type, title, description, ab_test_id, current_release_id, start_time) => {
    const currDeviceArrVer = currentView === VIEWS.VIEW_LEFT ? leftDeviceAppVer : rightDeviceAppVer;
    const curDevice = currentView == VIEWS.VIEW_LEFT ? leftDevice : rightDevice;
    const versionName = currDeviceArrVer ? currDeviceArrVer.version_name : '';
    const thisRelease = currDeviceArrVer
      ? appReleases.data.data.find((release) => release.release_name === versionName)
      : null;

    if (!thisRelease && versionName)
      message.warn(`The version ${versionName} installed on device ${curDevice.serial} is not in the database`, 5);

    setLeftRects([]);
    setRightRects([]);
    setRightDeviceImages([]);
    setLeftDeviceImages([]);

    return createScreenshotMutation.mutate({
      mode: 'create',
      args: {
        app_id: currApp.id,
        type,
        title,
        description,
        current_release_id: thisRelease ? thisRelease.id : null,
        ...(type === 'ab_test'
          ? {
              ab_test_id,
              current_release_id,
              start_time,
            }
          : null),
      },
    });
  };

  const handleAddToInsight = () => {
    setLeftRects([]);
    setRightRects([]);
    setRightDeviceImages([]);
    setLeftDeviceImages([]);

    createScreenshotMutation.mutate({
      mode: 'add',
    });
    onAddToInsightClose();
  };

  const handleLinkCliked = () => {
    setLinkDevices((prev) => !prev);
  };

  const handleReconnectClick = () => {
    message.warn('Reconnecting Devices... This may take a couple of seconds');

    reconnectLeftDevice();
    reconnectRightDevice();
  };

  const handleCancel = () => {
    setRightDeviceImages([]);
    setLeftDeviceImages([]);
  };

  const onAddToInsightClose = () => {
    handleCancel();
    setSelectedAddToInsight(null);
    setEditingModeStep(EDITING_MODES.None);
  };

  const appsOnBothDevices = useMemo(() => {
    if (rightDeviceAppsQuery.data && leftDevice && leftDevice.serial === CUSTOM_DEVICE.serial) {
      return apps.filter((app) =>
        app.platform === PLATFORMS.marketing
          ? true
          : rightDeviceAppsQuery.data.apps.find((leftApp) => leftApp.package === app.package)
      );
    }

    if (leftDeviceAppsQuery.data && rightDevice && rightDevice.serial === CUSTOM_DEVICE.serial) {
      return apps.filter((app) =>
        app.platform === PLATFORMS.marketing
          ? true
          : leftDeviceAppsQuery.data.apps.find((rightApp) => rightApp.package === app.package)
      );
    }

    if (!rightDeviceAppsQuery.data || !leftDeviceAppsQuery.data || !apps) {
      return [];
    }

    const { apps: rightDeviceApps } = rightDeviceAppsQuery.data;
    const { apps: leftDeviceApps } = leftDeviceAppsQuery.data;

    return apps.filter((app) => {
      if (app.platform === PLATFORMS.marketing) return true;

      const rightDeviceAppExists = rightDeviceApps.find((rightApp) => rightApp.package === app.package);
      const leftDeviceAppExists = leftDeviceApps.find((leftApp) => leftApp.package === app.package);

      return rightDeviceAppExists && leftDeviceAppExists;
    });
  }, [rightDeviceAppsQuery.data, leftDeviceAppsQuery.data, apps]);

  const isCustomDeviceRight = Boolean(rightDevice && rightDevice.serial === CUSTOM_DEVICE.serial);
  const isCustomDeviceLeft = Boolean(leftDevice && leftDevice.serial === CUSTOM_DEVICE.serial);

  const {
    handleToggleVideo: handleLeftVideoToggle,
    isVideoLoading: isLeftVideoLoading,
    videoStarted: leftVideoStarted,
  } = useVideoRecord({
    device: leftDevice,
    deviceAppVer: leftDeviceAppVer,
    setImages: setLeftDeviceImages,
    setEditingModeStep,
  });

  let {
    handleToggleVideo: handleRightVideoToggle,
    isVideoLoading: isRightVideoLoading,
    videoStarted: rightVideoStarted,
  } = useVideoRecord({
    device: rightDevice,
    deviceAppVer: rightDeviceAppVer,
    setImages: setRightDeviceImages,
    setEditingModeStep,
  });

  if (leftDevice && rightDevice) {
    handleRightVideoToggle = rightDevice.serial === leftDevice.serial ? handleLeftVideoToggle : handleRightVideoToggle;
    rightVideoStarted = rightDevice.serial === leftDevice.serial ? leftVideoStarted : rightVideoStarted;
  }

  const handleVideoToggleBoth = () => {
    handleLeftVideoToggle();

    if (rightDevice.serial !== leftDevice.serial) handleRightVideoToggle();
  };

  const isVideoLoading = isLeftVideoLoading || isRightVideoLoading;

  const handlePrintScreenLeft = async () => {
    await leftDeviceScreenQuery.refetch();
    setCurrentView(VIEWS.VIEW_LEFT);
    setEditingModeStep(EDITING_MODES.PrintScreen);
  };

  const handlePrintScreenRight = async () => {
    await rightDeviceScreenQuery.refetch();
    setCurrentView(VIEWS.VIEW_RIGHT);
    setEditingModeStep(EDITING_MODES.PrintScreen);
  };

  const handlePrintScreenBoth = () => {
    handlePrintScreenLeft();
    handlePrintScreenRight();
  };

  const { handleTranslateScreen: handleTranslateScreenLeft } = useScreenTranslation({
    platform: leftDevice ? leftDevice.platform : null,
    serial: leftDevice ? leftDevice.serial : null,
    setDeviceRects: setLeftRects,
  });

  const { handleTranslateScreen: handleTranslateScreenRight } = useScreenTranslation({
    platform: rightDevice ? rightDevice.platform : null,
    serial: rightDevice ? rightDevice.serial : null,
    setDeviceRects: setRightRects,
  });

  const resetScreens = () => {
    setLeftDeviceImages([]);
    setRightDeviceImages([]);
  };

  return (
    <div className="main-wrapper">
      <div className="container">
        <div className="header">
          <Header
            isAnyQueryLoading={isAnyQueryLoading}
            error={error}
            apps={appsOnBothDevices}
            currApp={currApp}
            devices={devices}
            leftDevice={leftDevice}
            rightDevice={rightDevice}
            persona={persona}
            setPersona={setPersona}
            deviceError={deviceError}
            setDiff={setDiff}
            serverEvents={serverEvents}
            setProgress={setProgress}
          />
        </div>
        <div className="hints">
          <InsightsTabs
            diffObject={diff}
            selectedAddToInsight={selectedAddToInsight}
            setSelectedAddToInsight={setSelectedAddToInsight}
            insights={insights}
            appId={currApp ? currApp.id : null}
            editingModeStep={editingModeStep}
            setEditingModeStep={setEditingModeStep}
            onCreateInsight={onCreateInsightHandle}
            leftDeviceImages={leftDeviceImages}
            rightDeviceImages={rightDeviceImages}
            setLeftDeviceImages={setLeftDeviceImages}
            setRightDeviceImages={setRightDeviceImages}
            leftDevice={leftDevice}
            rightDevice={rightDevice}
            socket={socket}
            currApp={currApp}
            rp={rp}
            setRp={setRp}
            isAutoMode={isAutoModeEnabled}
            setIsAutoMode={setIsAutoModeEnabled}
            leftDeviceAppVer={leftDeviceAppVer}
            rightDeviceAppVer={rightDeviceAppVer}
            isIosDevice={rightDevice ? rightDevice.platform === PLATFORMS.ios : false}
          />
          {editingModeStep === EDITING_MODES.AddToInsight && (
            <AddToInsight
              onAddToInsight={handleAddToInsight}
              onCancel={onAddToInsightClose}
              selectedInsight={selectedAddToInsight}
            />
          )}
        </div>
        <CanvasedDeviceViewer
          classes={{
            container: 'left-video',
          }}
          handleTranslateScreen={handleTranslateScreenLeft}
          setAppVer={setLeftDeviceAppVer}
          customAppReleases={releases || []}
          curAppPackage={currApp ? currApp.package : null}
          isCustomDevice={isCustomDeviceLeft}
          handlePrintScreen={handlePrintScreenLeft}
          handleVideoToggle={handleLeftVideoToggle}
          videoStarted={leftVideoStarted}
          setDeviceImages={setLeftDeviceImages}
          imagesCount={leftDeviceImages.length}
          versionType="previous"
          asset={{ type: 'image' }}
          onRectChange={handleRectChange(VIEWS.VIEW_LEFT)}
          rects={leftRects}
          appId={currApp ? currApp.id : null}
          isLandscape={false}
          deviceAppVer={leftDeviceAppVer}
          view={VIEWS.VIEW_LEFT}
          hideLink
          deviceId={leftDevice ? leftDevice.serial : null}
          videoCanvasContainerId={LEFT_VIDEO_CONTAINER_ID}
          sendMouseEvent={leftDeviceMouseEvent}
          sendControlEvent={leftDeviceControlEvent}
          sendKeyEvent={leftDeviceKeyEventHandle}
          canvasWidth={leftDeviceWidth}
          canvasHeight={leftDeviceHeight}
          hintsProgress={hintsProgress}
          graphData={leftDevice && graphData[leftDevice.serial] ? graphData[leftDevice.serial] : null}
          deviceConnected={leftDeviceConnected}
          setDiff={setDiff}
          setProgress={setProgress}
          handleAutoDelayTrigger={handleAutoDelayTrigger}
          isVideoLoading={isVideoLoading}
          deviceWidth={leftDevice ? leftDevice.width : null}
          deviceHeight={leftDevice ? leftDevice.height : null}
          isIosDevice={leftDevice ? leftDevice.platform === PLATFORMS.ios : false}
        />
        <CanvasedDeviceViewer
          classes={{
            container: 'right-video',
          }}
          handleTranslateScreen={handleTranslateScreenRight}
          setAppVer={setRightDeviceAppVer}
          customAppReleases={releases || []}
          curAppPackage={currApp ? currApp.package : null}
          isCustomDevice={isCustomDeviceRight}
          handlePrintScreen={handlePrintScreenRight}
          handlePrintScreenBoth={handlePrintScreenBoth}
          handleVideoToggle={handleRightVideoToggle}
          handleVideoToggleBoth={handleVideoToggleBoth}
          videoStarted={rightVideoStarted}
          imagesCount={rightDeviceImages.length}
          setDeviceImages={setRightDeviceImages}
          versionType="current"
          asset={{ type: 'image' }}
          onRectChange={handleRectChange(VIEWS.VIEW_RIGHT)}
          rects={rightRects}
          appId={currApp ? currApp.id : null}
          isLandscape={false}
          deviceAppVer={rightDeviceAppVer}
          view={VIEWS.VIEW_RIGHT}
          deviceId={rightDevice ? rightDevice.serial : null}
          videoCanvasContainerId={RIGHT_VIDEO_CONTAINER_ID}
          sendMouseEvent={rightDeviceMouseEvent}
          sendControlEvent={rightDeviceControlEvent}
          sendKeyEvent={rightDeviceKeyEventHandle}
          onLinkClicked={handleLinkCliked}
          isLinked={linkDevices}
          canvasWidth={rightDeviceWidth}
          canvasHeight={rightDeviceHeight}
          hintsProgress={hintsProgress}
          graphData={rightDevice && graphData[rightDevice.serial] ? graphData[rightDevice.serial] : null}
          deviceConnected={rightDeviceConnected}
          setDiff={setDiff}
          setProgress={setProgress}
          handleAutoDelayTrigger={handleAutoDelayTrigger}
          isVideoLoading={isVideoLoading}
          deviceWidth={rightDevice ? rightDevice.width : null}
          deviceHeight={rightDevice ? rightDevice.height : null}
        />
        <div className="right-bar">
          {leftDevice && rightDevice && currApp && (
            <HintsPanel
              progress={progress}
              socket={socket}
              currApp={currApp}
              prevVersion={leftDeviceAppVer}
              currVersion={rightDeviceAppVer}
              setHintProgress={setHintProgress}
              isCustomDevice={isCustomDeviceLeft || isCustomDeviceRight}
              rightDevice={rightDevice}
              leftDevice={leftDevice}
            />
          )}
        </div>
        <div className="footer">
          <Footer
            onCancel={handleCancel}
            editingModeStep={editingModeStep}
            setEditingModeStep={setEditingModeStep}
            handleReconnectClick={handleReconnectClick}
            imagesWithRects={imagesWithRectsUJ}
            app={currApp}
            getReleases={getReleases}
            releases={releases}
            resetScreens={resetScreens}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  apps: state.apps.all,
  releases: state.appsReleasesPage.releases,
  config: state.workbench.config,
});

const mapDispatchToProps = {
  loadApps,
  getReleases,
  changeWbConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workbench);
