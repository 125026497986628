import { createSelector } from 'reselect';

const getScreenChanges = (state) => state.UIViewerReducer.screenChanges;

export const getSortedScreenChanges = createSelector([getScreenChanges], (screenChanges) => {
  if (screenChanges) {
    screenChanges.sort((a, b) => b.version_code - a.version_code);
  }
  return screenChanges;
});
