import { handleActions } from 'redux-actions';
import {
  ADD_NEW_PRODUCT,
  GET_ALL_PRODUCTS_SUCCESS,
  ADD_NEW_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
} from '../actionTypes';

const initialState = {
  productsData: [],
};

const productReducer = handleActions(
  {
    [GET_ALL_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      productsData: action.payload.productsData,
    }),
    [ADD_NEW_PRODUCT]: (state) => ({
      ...state,
    }),
    [ADD_NEW_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
      productsData: [action.payload.product, ...state.productsData],
    }),
    [DELETE_PRODUCT]: (state) => ({
      ...state,
    }),
    [DELETE_PRODUCT_SUCCESS]: (state, action) => {
      const { productID } = action.payload;
      return {
        ...state,
        productsData: state.productsData.filter((x) => x.id !== productID),
      };
    },
    [UPDATE_PRODUCT]: (state) => ({
      ...state,
    }),
    [UPDATE_PRODUCT_SUCCESS]: (state, action) => {
      const { product } = action.payload;

      return {
        ...state,
        productsData: state.productsData.map((x) => (x.id === product.id ? product : x)),
      };
    },
  },

  initialState
);

export default productReducer;
