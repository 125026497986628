import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { getReleases } from '../../../redux/actions/appsReleasesPage';
import { PLATFORMS, DEFAULT_PLATFORM } from '../../../constants/platforms';
import { loadInsights } from '../../../redux/actions/insightPage';

const { Option } = Select;

class ReleasesInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: '',
      selectedPlatform: props.platform || DEFAULT_PLATFORM,
    };
  }

  appChange = (value, option) => {
    this.props.onAppChange(option.key);
    this.props.getReleases(option.key);
    this.props.loadInsights(option.props.value, this.state.selectedPlatform);
  };

  changePlatform = (value, option) => {
    this.props.changePlatform(option.key);
    this.setState((prevState) => ({
      ...prevState,
      selectedPlatform: option.key,
    }));
    this.props.loadInsights(this.props.selectedAppPackage, option.props.value);
  };

  render() {
    const filteredAppList = (this.props.allApps || []).filter((app) => {
      if (this.state.searchQuery && this.state.searchQuery.length >= 3 && app.name && app.package) {
        return (
          app.name.toLowerCase().includes(this.state.searchQuery.toLowerCase()) ||
          app.package.toLowerCase().includes(this.state.searchQuery.toLowerCase())
        );
      }
      return false;
    });
    return (
      <div className="releases__dropdown">
        <Select
          showSearch
          filterOption={false}
          style={{ width: 350 }}
          placeholder="Select app..."
          onSearch={(value) => this.setState({ searchQuery: value })}
          onChange={this.appChange}
          defaultActiveFirstOption={false}
          value={this.props.selectedAppPackage}
        >
          {filteredAppList.map((app) => (
            <Option key={app.id} value={app.package} name={app.name}>
              {`${app.name}(${app.package})`}
            </Option>
          ))}
        </Select>
        <Select
          label="Platform"
          className="control-element"
          style={{ width: 175 }}
          placeholder="Select platform"
          value={this.state.selectedPlatform}
          optionFilterProp="children"
          onChange={this.changePlatform}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {Object.entries(PLATFORMS).map(([key, val], _) => (
            <Option key={key} value={key} name={key}>
              {val}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

const mapStateToProps = ({ usersPage }) => ({
  allApps: usersPage.allApps,
});

export default connect(mapStateToProps, {
  getReleases,
  loadInsights,
})(ReleasesInput);
