import React, { useState } from 'react';
import { Button } from 'antd';
import ReactJson from 'react-json-view';

export default ({ insight }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <React.Fragment>
      <Button onClick={() => setIsVisible(!isVisible)}>{isVisible ? 'Hide' : 'Show'} JSON</Button>
      {isVisible && <ReactJson src={insight} style={{ marginTop: '15px' }} />}
    </React.Fragment>
  );
};
