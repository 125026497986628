import * as AT from '../actionTypes';
import { loadEmailsAPI, getInsightByIDSApi } from '../actions/emailJSONPage';

const emailMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_INSIGHTS_BY_IDS: {
        console.log(action.payload);

        dispatch(getInsightByIDSApi(action.payload));
        return next(action);
      }
      case AT.LOAD_EMAILS: {
        dispatch(loadEmailsAPI());
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default emailMiddleware;
