import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const getUserSubscriptions = (userID) => ({
  type: AT.GET_USER_SUBSCRIPTIONS,
  payload: userID,
});

export const getUserSubscriptionsAPI = (userID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/subscriptions`,
    method: 'GET',
    label: NETWORK_LABELS.GET_USER_SUBSCRIPTION,
    onSuccess: (body, dispatch) => dispatch(getUserSubscriptionsSuccess(body.data)),
  },
});

export const getUserSubscriptionsSuccess = (data) => ({
  type: AT.GET_USER_SUBSCRIPTIONS_SUCCESS,
  payload: { data },
});

export const createSubscription = (userID, data) => ({
  type: AT.CREATE_SUBSCRIPTION,
  payload: {
    userID,
    data,
  },
});

export const createSubscriptionAPI = (userID, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/subscription`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.CREATE_SUBSCRIPTION,
    onSuccess: (body, dispatch) => dispatch(createSubscriptionSuccess(body.data)),
    onFailure: (error, dispatch) => console.error(error),
  },
});

export const createSubscriptionSuccess = (data) => ({
  type: AT.CREATE_SUBSCRIPTION_SUCCESS,
  payload: { data },
});

export const delSubscription = (userID, subscriptionID) => ({
  type: AT.DELETE_SUBSCRIPTION,
  payload: {
    userID,
    subscriptionID,
  },
});

export const delSubscriptionAPI = (userID, subscriptionID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/subscriptions/${subscriptionID}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DELETE_SUBSCRIPTION,
    onSuccess: (body, dispatch) => dispatch(delSubscriptionSuccess(userID, subscriptionID)),
    onFailure: (error, dispatch) => console.error(error),
  },
});

export const delSubscriptionSuccess = (userID, subscriptionID) => ({
  type: AT.DELETE_SUBSCRIPTION_SUCCESS,
  payload: {
    userID,
    subscriptionID,
  },
});

export const updSubscription = (userID, subscriptionID, subscription) => ({
  type: AT.UPDATE_SUBSCRIPTION,
  payload: {
    userID,
    subscriptionID,
    subscription,
  },
});

export const updSubscriptionAPI = (userID, subscriptionID, subscription) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/subscriptions/${subscriptionID}`,
    label: NETWORK_LABELS.UPDATE_SUBSCRIPTION,
    method: 'PUT',
    data: subscription,
    onSuccess: (body, dispatch) => dispatch(updSubscriptionSuccess(subscriptionID, body.data)),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const updSubscriptionSuccess = (subscriptionID, subscription) => ({
  type: AT.UPDATE_SUBSCRIPTION_SUCCESS,
  payload: {
    subscriptionID,
    subscription,
  },
});

export const updEmailPreferences = (userID, emailPreferences) => ({
  type: AT.UPDATE_EMAIL_PREFERENCES,
  payload: {
    userID,
    emailPreferences,
  },
});

export const updEmailPreferencesAPI = (userID, emailPreferences) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `user/${userID}/preferences/subscriptions`,
    label: NETWORK_LABELS.UPDATE_EMAIL_PREFERENCES,
    method: 'PUT',
    data: emailPreferences,
    onSuccess: (body, dispatch) => dispatch(updEmailPreferencesSuccess(body.email_preferences)),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const updEmailPreferencesSuccess = (email_preferences) => ({
  type: AT.UPDATE_EMAIL_PREFERENCES_SUCCESS,
  payload: {
    email_preferences,
  },
});
