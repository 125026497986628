/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { Table, Spin, Input, Button, Icon, Modal, Select, message, Checkbox } from 'antd';
import Img from 'react-image';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import WatchfulThumbnail from '../../assets/icons/default_thumbnail.png';
import {
  updateApps,
  getAppsData,
  addNewApp,
  updApp,
  setAppCategories,
  getLocations,
} from '../../redux/actions/appsReleasesPage';
import AppsSelectCell from '../releases/AppsSelectCell';
import { getAllProducts } from '../../redux/actions/products';
import { getConfiguration } from '../../redux/actions/emailJSONPage';
import {
  getAppEvents,
  addAppEvents,
  removeAppEvents,
  getAppDevices,
  addAppDevices,
  removeAppDevices,
} from '../../redux/actions/apps';
import AddAppModal from './forms/AppModal';

import { countries } from '../../constants/countries';
import { getColumnSearchProps } from '../../utils/helpers';
import AppOrgPermsModal from './forms/AppOrgPermsModal';
import CommonOrgPermsModal from './forms/CommonOrgPermsModal';

import CreatableSelect from 'react-select/creatable';
import { useGetVerticals, useCreateVertical } from 'hooks/verticals';

const { Option } = Select;

const l = (...a) => console.log('Apps', ...a);
class Apps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableSearchQuery: '',
      tableAppsChanges: {},
      showAppAddModal: false,
      showEventsModal: false,
      showDevicesModal: false,
      currentAppIDModalEdit: null,
      currentAppIDModalEvents: null,
      currentAppIDModalDevices: null,
      currentEventTypeForAdding: null,
      currentDeviceTypeForAdding: null,
      orgPermModalAppId: null,
      isCommonOrgPermModalVisible: false,
      showOnlyMonitoredApps: false,
    };
  }

  componentDidMount() {
    this.props.getAppsData();
    this.props.getLocations();
    this.props.getConfiguration();
    this.props.getAllProducts();
  }

  componentDidUpdate(prevProps) {
    if (this.props.appsData.length === 0) {
      if (Object.keys(this.state.tableAppsChanges).length !== 0) {
        this.setState({
          tableAppsChanges: {},
          showAppAddModal: false,
        });
      } else if (this.state.showAppAddModal) {
        this.setState({
          showAppAddModal: false,
        });
      }
    }
    if (!this.props.isApiRequestLoading && prevProps.isApiRequestLoading !== this.props.isApiRequestLoading) {
      message.success(this.props.status, 5);
    }
  }

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ tableSearchQuery: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ tableSearchQuery: '' });
  };

  handleSelectChange = (value, option) => {
    const currentAppChange = this.state.tableAppsChanges[option.props.appid] || {};
    if (value !== option.props.defaultValue) {
      // the new value is different from the db value
      currentAppChange[option.props.columnname] = value;
    } else if (option.props.columnname in currentAppChange) {
      // the db value is the same as the changed value
      delete currentAppChange[option.props.columnname];
    }
    this.setState((prevState) => {
      const returnObj = {
        tableAppsChanges: { ...prevState.tableAppsChanges, [option.props.appid]: currentAppChange },
      };
      if (
        returnObj.tableAppsChanges[option.props.appid] &&
        Object.keys(returnObj.tableAppsChanges[option.props.appid]).length === 0
      ) {
        delete returnObj.tableAppsChanges[option.props.appid];
      }
      return returnObj;
    });
  };

  handleAppAddSubmit = (app) => {
    // submit to the backend
    console.log('create app:', app);
    this.props.addNewApp(app);
  };

  handleUpdAppSubmit = (app) => {
    console.log('update app:', app);
    this.props.updApp(app);
  };

  handleCategoryChange(appid, value) {
    this.props.setAppCategories(appid, value);
    l('handlecatchange', appid, value);
  }
  handleCategorySearch(value) {
    const rt = [
      { id: 1, name: 'hardcode' },
      { id: 2, name: 'softcode' },
    ];
    if (!rt.map((r) => r.name).includes(value)) rt.push({ id: null, name: value });
    l('handleCategorySearch returning', rt);
    return rt.map((r) => r.name);
  }

  addEventFromApp() {
    this.props.addAppEvents({
      app_id: this.state.currentAppIDModalEvents,
      event_type_id: this.state.currentEventTypeForAdding,
    });
    this.setState({ currentEventTypeForAdding: null });
  }

  removeEventFromApp(id) {
    this.props.removeAppEvents({
      id,
      app_id: this.state.currentAppIDModalEvents,
    });
    console.log(`remove ${id} from ${this.state.currentAppIDModalEvents}`);
  }

  addDeviceFromApp() {
    this.props.addAppDevices({
      app_id: this.state.currentAppIDModalDevices,
      device_model_id: this.state.currentDeviceTypeForAdding,
    });
    this.setState({ currentDeviceTypeForAdding: null });
  }

  removeDeviceFromApp(id) {
    this.props.removeAppDevices({
      device_model_id: id,
      app_id: this.state.currentAppIDModalDevices,
    });
    console.log(`remove ${id} from ${this.state.currentAppIDModalDevices}`);
  }

  render() {
    const { appsData, appsEvents, eventTypes, devicesTypes, appsDevices, products } = this.props;
    const { currentAppIDModalEvents, currentAppIDModalDevices } = this.state;
    const getDeviceLabel = (device) =>
      `${device.product_name} (${device.platform}${device.os_version ? ` ${device.os_version}` : ''})`;
    const getAppsTableColumns = () => {
      const firstAppDataKeys =
        appsData.length > 0 && Object.keys(appsData[0]).filter((x) => !['product_id', 'icon_original'].includes(x));

      if (!firstAppDataKeys) {
        return [];
      }

      const verticalIdIdx = firstAppDataKeys.indexOf('vertical_id');

      if (verticalIdIdx > -1) {
        firstAppDataKeys[verticalIdIdx] = 'vertical';
      }

      // Automatically create the table columns, without the 'id' column
      let returnArr = firstAppDataKeys
        .map((appDataKey) => ({
          title: appDataKey,
          dataIndex: appDataKey,
          key: appDataKey,
          width: 250,
        }))
        .filter((column) => !['id'].includes(column.key));
      // Making sure the 'id' column is the first column regardless the position in the returned app objects
      returnArr.unshift({
        title: 'id',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        width: 100,
      });
      // Making the app_name column fixed as the id column
      returnArr = returnArr.map((column) => {
        if (column.key === 'app_name') {
          column.width = 300;
          column.fixed = 'left';
          column.render = (app_name, record) => <div>{app_name}1111</div>;
          column = { ...column, ...getColumnSearchProps(column.key, this.handleSearch, this.handleReset) };
        }
        // Add filter on 'should_....' columns
        if (column.key.includes('should_')) {
          column.filters = [
            { value: 'true', text: 'Yes' },
            { value: 'false', text: 'No' },
            { value: 'null', text: 'Null' },
          ];
          column.onFilter = (value, record) => record[column.key].props.defaultValue.indexOf(value) === 0;
        }
        if (column.key === 'is_monitored') {
          column.width = 120;
        }
        column.render = (text, rec) => {
          if (column.key === 'id') {
            return (
              <div>
                {text}
                <Button
                  type="small"
                  icon="edit"
                  loading={this.props.isApiRequestLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ currentAppIDModalEdit: text });
                  }}
                />
              </div>
            );
          } else if (column.key === 'app_name') {
            return (
              <div>
                <Highlighter
                  highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                  searchWords={[this.state.tableSearchQuery]}
                  autoEscape
                  textToHighlight={text.toString()}
                />
                <div>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        showEventsModal: true,
                        currentAppIDModalEvents: rec.id,
                        currentEventTypeForAdding: null,
                      });
                      this.props.getAppEvents(rec.id);
                    }}
                  >
                    Events
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        showDevicesModal: true,
                        currentAppIDModalDevices: rec.id,
                      });
                      this.props.getAppDevices(rec.id);
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    Models
                  </Button>
                  <Button
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ orgPermModalAppId: rec.id });
                    }}
                    style={{ marginLeft: '1rem' }}
                  >
                    Org Perms
                  </Button>
                </div>
              </div>
            );
          } else if (column.key === 'categories') {
            const cats = rec.categories;
            return (
              <Select
                mode="tags"
                onSearch={this.handleCategorySearch}
                onClick={(e) => {
                  l('Select.onClick', e);
                  e.stopPropagation();
                }}
                onChange={(nv) => {
                  l('Select.onChange');
                  this.handleCategoryChange(rec.id, nv);
                }}
                value={cats.map((c) => c.name)}
              />
            );
          } else if (column.key === 'product') {
            return <React.Fragment>{rec.product && rec.product.name}</React.Fragment>;
          } else if (column.key === 'is_monitored') {
            return (
              <Checkbox
                checked={rec.is_monitored}
                onClick={(e) => {
                  this.handleUpdAppSubmit({ id: rec.id, is_monitored: !rec.is_monitored });
                  e.stopPropagation();
                }}
              />
            );
          } else if (column.key === 'vertical') {
            return (
              <div onClick={(e) => e.stopPropagation()}>
                <CreatableSelect
                  isClearable={false}
                  onCreateOption={async (value) => {
                    const res = await this.props.createVerticalAsync({ name: value });
                    this.handleUpdAppSubmit({ id: rec.id, vertical_id: res.data.id });
                  }}
                  placeholder="Select or type to create"
                  onChange={({ value }) => this.handleUpdAppSubmit({ id: rec.id, vertical_id: value })}
                  value={
                    this.props.verticals.find((v) => v.id === rec.vertical_id)
                      ? {
                          value: rec.vertical_id,
                          label: this.props.verticals.find((v) => v.id === rec.vertical_id).name,
                        }
                      : undefined
                  }
                  options={this.props.verticals.map((vertical) => ({
                    value: vertical.id,
                    label: vertical.name,
                  }))}
                />
              </div>
            );
          }

          return <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>{text}</div>;
        };
        return column;
      });
      return returnArr;
    };
    const getAppsTableData = () =>
      appsData
        .filter((x) => !this.state.showOnlyMonitoredApps || x.is_monitored)
        .map((appData) => {
          const iconUrl = appData.icon;
          const shouldColumnsSelectComponents = {};
          Object.keys(appData).forEach((key) => {
            if (key.includes('should_')) {
              const defaultValue = key in appData && appData[key] === null ? 'null' : appData[key].toString();
              shouldColumnsSelectComponents[key] = (
                <AppsSelectCell
                  className={
                    this.state.tableAppsChanges[appData.id] &&
                    this.state.tableAppsChanges[appData.id][key] &&
                    this.state.tableAppsChanges[appData.id][key] !== defaultValue
                      ? 'select-changed'
                      : ''
                  }
                  defaultValue={defaultValue}
                  currentValue={this.state.tableAppsChanges[appData.id] && this.state.tableAppsChanges[appData.id][key]}
                  handleSelectChange={this.handleSelectChange}
                  columnName={key}
                  appID={appData.id}
                  options={[
                    { value: 'true', text: 'Yes' },
                    { value: 'false', text: 'No' },
                    { value: 'null', text: 'Null' },
                  ]}
                  style={{ width: '100%' }}
                />
              );
            }
          });
          return {
            ...appData,
            key: appData.id,
            icon: (
              <Img
                style={{ width: '100%', height: '100%' }}
                src={[iconUrl, WatchfulThumbnail]}
                alt=""
                loader={<Spin />}
                container={(children) => <div style={{ width: '25px', height: '25px' }}>{children}</div>}
              />
            ),
            ...shouldColumnsSelectComponents,
          };
        });
    if (!eventTypes) {
      return (
        <h2 style={{ textAlign: 'center' }}>
          {' '}
          Loading <Icon type="loading" />
        </h2>
      );
    }
    return (
      <div className="releases-content">
        <AddAppModal
          visible={this.state.showAppAddModal}
          handleHide={() => this.setState({ showAppAddModal: false })}
          handleSumit={this.handleAppAddSubmit}
          products={products}
        />
        <AddAppModal
          visible={!!this.state.currentAppIDModalEdit}
          handleHide={() => this.setState({ currentAppIDModalEdit: null })}
          handleSumit={this.handleUpdAppSubmit}
          app={this.props.appsData.find((x) => x.id === this.state.currentAppIDModalEdit)}
          products={products}
        />
        <Modal
          title="App's Events"
          visible={this.state.showEventsModal}
          onOk={() => {
            this.setState({ showEventsModal: false, currentAppIDModalEvents: null, currentEventTypeForAdding: null });
          }}
          onCancel={() => {
            this.setState({ showEventsModal: false, currentAppIDModalEvents: null, currentEventTypeForAdding: null });
          }}
          destroyOnClose
          closable={true}
          wrapClassName="app-events"
          cancelButtonProps={{
            className: 'app-events-cancel',
          }}
        >
          {!appsEvents[currentAppIDModalEvents] && (
            <h2 style={{ textAlign: 'center' }}>
              {' '}
              Loading <Icon type="loading" />
            </h2>
          )}
          <div style={{ display: 'table' }}>
            {currentAppIDModalEvents &&
              appsEvents[currentAppIDModalEvents] &&
              appsEvents[currentAppIDModalEvents].map((ev) => (
                <li style={{ display: 'table-row' }}>
                  <div style={{ display: 'table-cell' }}>
                    {eventTypes.find((event) => event.id === ev.event_type_id) &&
                      eventTypes.find((event) => event.id === ev.event_type_id).title}
                  </div>
                  <div style={{ display: 'table-cell' }}>
                    <Button
                      type="primary"
                      shape="round"
                      icon="close"
                      size="small"
                      onClick={() => this.removeEventFromApp(ev.id)}
                    />
                  </div>
                </li>
              ))}
          </div>
          {currentAppIDModalEvents &&
            appsEvents[currentAppIDModalEvents] &&
            appsEvents[currentAppIDModalEvents].length === 0 && <div>There are no events</div>}
          {currentAppIDModalEvents && appsEvents[currentAppIDModalEvents] && (
            <div className="events-adding" style={{ marginTop: '1rem' }}>
              <Select
                style={{ width: '20rem' }}
                onChange={(val) => this.setState({ currentEventTypeForAdding: val })}
                value={this.state.currentEventTypeForAdding}
              >
                {eventTypes &&
                  eventTypes
                    .filter(
                      (eventType) =>
                        !appsEvents[currentAppIDModalEvents].find((ev) => ev.event_type_id === eventType.id)
                    )
                    .map((eventType) => <Option value={eventType.id}>{eventType.title} </Option>)}
              </Select>
              {this.state.currentEventTypeForAdding && (
                <Button type="primary" onClick={() => this.addEventFromApp()}>
                  Add
                </Button>
              )}
            </div>
          )}
        </Modal>

        <Modal
          title="App's Models"
          visible={this.state.showDevicesModal}
          onOk={() => {
            this.setState({
              showDevicesModal: false,
              currentAppIDModalDevices: null,
              currentDeviceTypeForAdding: null,
            });
          }}
          onCancel={() => {
            this.setState({
              showDevicesModal: false,
              currentAppIDModalDevices: null,
              currentDeviceTypeForAdding: null,
            });
          }}
          destroyOnClose
          closable={true}
          wrapClassName="app-devices"
          cancelButtonProps={{
            className: 'app-devices-cancel',
          }}
        >
          {!appsDevices[currentAppIDModalDevices] && (
            <h2 style={{ textAlign: 'center' }}>
              {' '}
              Loading <Icon type="loading" />
            </h2>
          )}
          <div style={{ display: 'table' }}>
            {currentAppIDModalDevices &&
              appsDevices[currentAppIDModalDevices] &&
              appsDevices[currentAppIDModalDevices]
                .filter((deviceModel) => deviceModel.performance_enabled)
                .map((dev) => (
                  <li style={{ display: 'table-row' }}>
                    <div style={{ display: 'table-cell' }}>
                      {getDeviceLabel(devicesTypes.find((device) => device.id === dev.device_model_id))}
                    </div>
                    <div style={{ display: 'table-cell' }}>
                      <Button
                        type="primary"
                        shape="round"
                        icon="close"
                        size="small"
                        onClick={() => this.removeDeviceFromApp(dev.device_model_id)}
                      />
                    </div>
                  </li>
                ))}
          </div>
          {currentAppIDModalDevices &&
            appsDevices[currentAppIDModalDevices] &&
            appsDevices[currentAppIDModalDevices].length === 0 && <div>There are no models</div>}
          {currentAppIDModalDevices && appsDevices[currentAppIDModalDevices] && (
            <div className="events-adding" style={{ marginTop: '1rem' }}>
              <Select
                style={{ width: '20rem' }}
                onChange={(val) => this.setState({ currentDeviceTypeForAdding: val })}
                value={this.state.currentDeviceTypeForAdding}
              >
                {devicesTypes &&
                  devicesTypes
                    .filter(
                      (deviceType) =>
                        !appsDevices[currentAppIDModalDevices].find(
                          (ev) => ev.device_model_id === deviceType.id && ev.performance_enabled
                        )
                    )
                    .map((deviceType) => <Option value={deviceType.id}>{getDeviceLabel(deviceType)}</Option>)}
              </Select>
              {this.state.currentDeviceTypeForAdding && (
                <Button type="primary" onClick={() => this.addDeviceFromApp()}>
                  Add
                </Button>
              )}
            </div>
          )}
        </Modal>
        {this.state.orgPermModalAppId && (
          <AppOrgPermsModal
            appId={this.state.orgPermModalAppId}
            onClose={() => this.setState({ orgPermModalAppId: null })}
          />
        )}
        {this.state.isCommonOrgPermModalVisible && (
          <CommonOrgPermsModal
            apps={this.props.appsData}
            onClose={() => this.setState({ isCommonOrgPermModalVisible: false })}
          />
        )}

        <Button
          disabled={appsData.length === 0}
          type="primary"
          style={{ margin: '16px 0 16px 5px' }}
          loading={this.props.isApiRequestLoading}
          onClick={() => {
            this.setState({ showAppAddModal: true });
          }}
        >
          Add New App
        </Button>
        <Button
          type="primary"
          style={{ margin: '16px 0 16px 5px' }}
          disabled={appsData.length === 0 || !Object.keys(this.state.tableAppsChanges).length > 0}
          onClick={() => {
            this.props.updateApps(
              Object.entries(this.state.tableAppsChanges).map((appChange) => {
                if (Object.keys(appChange[1]).length > 0) {
                  return { id: Number(appChange[0]), ...appChange[1] };
                }
              })
            );
          }}
        >
          Save All Changes
        </Button>
        <Button
          onClick={() => this.setState({ isCommonOrgPermModalVisible: true })}
          type="primary"
          style={{ margin: '16px 0 16px 5px' }}
        >
          Org Permissions
        </Button>
        <Checkbox
          style={{ margin: '16px 0 16px 5px' }}
          checked={this.state.showOnlyMonitoredApps}
          onChange={(e) => this.setState({ showOnlyMonitoredApps: e.target.checked })}
        >
          Display Only Monitored Apps
        </Checkbox>
        <Table
          className="main-table"
          columns={getAppsTableColumns()}
          dataSource={getAppsTableData()}
          scroll={{ x: 4300, y: 300 }}
          pagination={{ pageSize: 50 }}
          loading={!appsData.length > 0}
          onRow={(record) => ({
            onClick: (event) => {
              if (
                !event.target.classList.contains('ant-select-selection__rendered') &&
                !event.target.classList.contains('ant-select-selection') &&
                !event.target.classList.contains('ant-select-selection-selected-value') &&
                !event.target.classList.contains('ant-select-dropdown-menu-item') &&
                !event.target.classList.contains('ant-select-search__field') && // the categories select
                !(event.target.tagName.toLowerCase() === 'input' && event.target.type === 'number') // apps marketing users
              ) {
                // Navigate to the releases page
                this.props.history.push(`/releases?appId=${record.id}&platform=${record.platform}`);
              }
            },
          })}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ appsReleasesPage, apps, emailJSONPage, productsData }) => ({
  appsData: appsReleasesPage.appsData,
  status: appsReleasesPage.status,
  isApiRequestLoading: appsReleasesPage.isApiRequestLoading,
  locations: appsReleasesPage.locations,
  appsEvents: apps.appsEvents,
  appsDevices: apps.appsDevices,
  eventTypes: emailJSONPage.configuration.event_types,
  devicesTypes: emailJSONPage.configuration.device_models,
  products: productsData.productsData,
});

const withVerticals = (WrappedComponent) => (props) => {
  const { data: verticals, isFetching } = useGetVerticals();
  const { mutateAsync: createVerticalAsync } = useCreateVertical();

  return (
    <WrappedComponent
      {...props}
      createVerticalAsync={createVerticalAsync}
      verticals={isFetching ? [] : verticals.data}
    />
  );
};

export default withVerticals(
  connect(mapStateToProps, {
    getAppsData,
    updateApps,
    addNewApp,
    updApp,
    setAppCategories,
    getLocations,
    getAppEvents,
    getConfiguration,
    addAppEvents,
    removeAppEvents,
    getAppDevices,
    addAppDevices,
    removeAppDevices,
    getAllProducts,
  })(Apps)
);
