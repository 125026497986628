import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Spin, Select, Button, Popconfirm, Switch, Alert } from 'antd';
import { useQuery } from 'react-query';
import {
  swipe2swipe_data,
  swipe_data2swipe,
  CELLSIZE,
  DEFAULT_EDGE_PROPERTIES,
  GRAPHCANVASOPTIONS,
  getLastOrderIndex,
  getLastNodeIDInChain,
  automationStateEnum,
} from './helpers';
import {
  getGraphProducts,
  getGraphProductData,
  getDeviceDataFromFarmer,
  sendDataToDeviceOnFramer,
  allocateDeviceForUser,
  getGraphActionsData,
  executeRunOnFramer,
} from '../../api/graphBuilderAPI';
import EdgeEditing from './EdgeEditing';

import { backendApiRequest } from '../../api/request';
import FarmerWebSocketConnection from './FarmerWebSocketConnection';

import NetworkGraph from './NetworkGraph';
import delete_node_ico from '../../assets/icons/graph_builder/delete_node.svg';

import GraphRun from './GraphRun';

import { v4 as uuidv4 } from 'uuid';

import { getAllAppsWithGraphs } from '../../redux/actions/UIViewerPage';

export const GraphBuilder = ({ history, getAllAppsWithGraphs, appsWithGraphs }) => {
  const url = new URLSearchParams(history.location.search);
  const u = (k) => url.get(k);

  // STATE
  const [productID, productIDChange] = useState(u('productID') ? Number(u('productID')) : null);
  const [appID, appIDChange] = useState(u('appID') ? Number(u('appID')) : null);
  const [versionID, versionIDChange] = useState(u('versionID') ? Number(u('versionID')) : null);
  const [personaID, personaIDChange] = useState(u('personaID') ? Number(u('personaID')) : null);
  const [deviceID, deviceIDChange] = useState(u('deviceID') ? Number(u('deviceID')) : null);
  const [connectStatus, connectStatusChange] = useState('');
  const [connectIsEnabled, connectIsEnabledChange] = useState(false);
  const [interactionWithVideo, interactionWithVideoChange] = useState(true);
  const [automationState, automationStateChange] = useState(automationStateEnum.NotRun);
  const [graphIDforCopy, graphIDforCopyChange] = useState(null);
  const [isMassSaving, isMassSavingChange] = useState(false);
  const [curEdgeIdInGraphRun, curEdgeIdInGraphRunChange] = useState(null);

  // GRAPH DATA
  const [graphData, graphDataChange] = useState(null);
  const [rootNodeID, rootNodeIDChange] = useState();
  const [recordContext, recordContextChange] = useState(false);
  const [selectedNodeAfterUpdateGraph, selectedNodeAfterUpdateGraphChange] = useState(null);
  const [editedNode, editedNodeChange] = useState(null);
  const [fetchingScreenShotData, fetchingScreenShotDataChange] = useState(false);
  const recordContextRef = useRef(true);
  const [isEdgeChosen, isEdgeChosenChange] = useState(false);
  const [chosenEdge, chosenEdgeChange] = useState(null);
  const [EdgeDirActiveTab, EdgeDirActiveTabChange] = useState('0');
  const [newScreenshotWaiting, newScreenshotWaitingChange] = useState(false);
  const [screenDataWaiting, screenDataWaitingChange] = useState(false);
  const [lastNodeInChain, lastNodeInChainChange] = useState(null);

  // DEVICE STATE
  const [farmerDevice, farmerDeviceChange] = useState(null);
  const [farDevListOfApps, farDevListOfAppsChange] = useState(null);
  const [lastScreenData, lastScreenDataChange] = useState(null);
  const [lastEdgeDataFarmer, lastEdgeDataFarmerChange] = useState('');
  const [lastCreatedNode, lastCreatedNodeChange] = useState(null);
  const [farmerDeviceState, farmerDeviceStateChange] = useState(false);
  const [farmerError, farmerErrorChange] = useState(null);
  const [userMistakeWarning, userMistakeWarningChange] = useState(null);

  // RUN
  const [stepIndex, stepIndexChange] = useState(null);
  const [startEdgeID, startEdgeIDChange] = useState(0);

  // API
  const graphsProducts = useQuery('GraphsProducts', getGraphProducts);
  const productData = useQuery(['ProductData', productID], () => getGraphProductData(productID), {
    enabled: !!productID,
  });

  const graphActions = useQuery(['graphActions', rootNodeID], () => getGraphActionsData(rootNodeID), {
    enabled: automationState === automationStateEnum.Running,
  });

  // EFFECTS
  useEffect(() => {
    if (productID !== Number(u('productID'))) {
      appIDChange(null);
      versionIDChange(null);
      personaIDChange(null);
      deviceIDChange(null);
    }
  }, [productID]);

  useEffect(() => {
    if (productID && appID && versionID && personaID && deviceID) {
      connectStatusChange('ready');
    } else {
      connectStatusChange('');
    }

    const dataForURL = {
      productID: productID || '',
      appID: appID || '',
      versionID: versionID || '',
      personaID: personaID || '',
      deviceID: deviceID || '',
    };
    const getsURL = new URLSearchParams(dataForURL).toString();

    history.replace(`${history.location.pathname}?${getsURL}`);
    getAllAppsWithGraphs();
  }, [productID, appID, versionID, personaID, deviceID]);

  // HANDLERS
  const getCurDeviceByID = () =>
    productData.data.apps
      .find((a) => a.id === appID)
      .releases.find((rel) => rel.id === versionID)
      .devices.find((dev) => dev.id === deviceID);
  const htmlTitle = (html) => {
    const container = document.createElement('div');
    container.innerHTML = html;
    return container;
  };
  const getCurReleaseByID = () =>
    productData.data.apps.find((a) => a.id === appID).releases.find((rel) => rel.id === versionID);

  const reformatGraphData = (backGraphData) => {
    console.log(backGraphData);
    const gd = {
      nodes: backGraphData.nodes.map((node) => {
        const newNode = {
          ...node,
          id: node.state_id,
          neo4j_label: node.labels[0],
          label: node.name,
          x: node.ui_position_x,
          y: node.ui_position_y,
        };
        if (node.screenshot_link) {
          newNode.title = htmlTitle(
            fetchingScreenShotData && editedNode && editedNode.id === node.id
              ? 'No image'
              : `<div class="node-main-popup"><img src="${node.screenshot_link}" alt="${node.name}" /></div>`
          );
        }
        return newNode;
      }),
      edges: backGraphData.relationships.map((edge) => ({
        ...edge,
        from: edge.start_node_id,
        to: edge.end_node_id,
        input_type: edge.input_type,
        comment: edge.comment,
        label: '',
        swipe_data: swipe2swipe_data(edge.x, edge.x_offset, edge.y, edge.y_offset, edge.swipe_duration),
      })),
    };
    return gd;
  };

  const handleConnect = (checked) => {
    const device = getCurDeviceByID();
    const persona = productData.data.personas.find((p) => p.id === personaID);
    const app = productData.data.apps.find((a) => a.id === appID);
    const ver = app.releases.find((rel) => rel.id === versionID);

    connectStatusChange(checked ? 'connecting' : 'ready');
    connectIsEnabledChange(checked);
    allocateDeviceForUser(device.api_url, device.farm_device_id, checked);
    if (!checked) {
      graphDataChange(null);
      chosenEdgeChange(null);
      selectedNodeAfterUpdateGraphChange(null);
      return;
    }

    backendApiRequest({
      method: 'GET',
      path: '/admin/graphs',
      params: {
        device_id: deviceID,
        app_id: appID,
        release_id: versionID,
        persona_id: personaID,
      },
    }).then((res) => {
      if (res.data[0]) {
        rootNodeIDChange(res.data[0].id);
        backendApiRequest({
          method: 'GET',
          path: `/admin/graphs/${res.data[0].id}`,
        }).then((graph) => {
          selectedNodeAfterUpdateGraphChange(res.data[0].id);
          const gd = reformatGraphData(graph.data);
          const lastNodeIDIChain = getLastNodeIDInChain(gd);
          const lastNode = gd.nodes.find((n) => n.id === lastNodeIDIChain);
          lastNode.font = {
            color: 'white',
          };
          lastNode.color = {
            background: '#5fb60e',
            border: '#5fb60',
          };
          lastNodeInChainChange(lastNodeIDIChain);
          graphDataChange(gd);
        });
      } else {
        createRootNode(device, persona, ver, app);
      }
    });

    getDeviceDataFromFarmer(device.api_url, device.farm_device_id).then((fDevice) => {
      farmerDeviceChange(fDevice);
      setTimeout(() => {
        const device = getCurDeviceByID();
        sendDataToDeviceOnFramer(device.api_url, 'get_installed_packages', device);
        const curAppPackage = productData.data.apps.find((app) => app.id === appID).package;
        sendDataToDeviceOnFramer(device.api_url, 'start_package', device, [curAppPackage]);
      }, 1000);
    });
  };
  const createRootNode = (device, persona, ver, app) => {
    backendApiRequest({
      method: 'POST',
      path: '/backoffice/graph/node',
      params: null,
      body: {
        label: 'root_state',
        name: 'Home',
        graph_builder: 1,
        'device_info.device_id': device.serial_number,
        'device_info.manufacturer': device.manufacturer,
        'device_info.product_model': device.model,
        'device_info.release_version': device.os_version,
        'device_info.platform': device.platform,
        'device_info.product_name': device.product_name,
        'persona.id': persona.id,
        'persona.gender': persona.gender,
        'persona.location': persona.location,
        'persona.title': persona.title,
        'persona.description': persona.description,
        'persona.geo_address': persona.geo_address,
        'persona.geo_billing_address': persona.geo_billing_address,
        'persona.birth_date': persona.birth_date,
        'persona.email': persona.email,
        'persona.email_password': persona.email_password,
        'persona.phone': persona.phone,
        'persona.created_at': persona.created_at,
        'persona.active': persona.active,
        'persona.status': persona.status,
        version: `${ver.version_code}___${ver.name}`,
        package_name: app.package,
        platform: app.platform,
        ui_position_x: 0,
        ui_position_y: 0,
      },
    }).then((node) => {
      rootNodeIDChange(node.identity);
      lastNodeInChainChange(node.identity);
      graphDataChange({
        nodes: [
          {
            neo4j_label: `root_state`,
            name: `Root ${node.identity}`,
            label: 'Home',
            id: node.identity,
            x: 0,
            y: 0,
            font: {
              color: 'white',
            },
            color: {
              background: '#5fb60e',
              border: '#5fb60',
            },
          },
        ],
        edges: [],
      });
    });
  };

  const handleNewNodeWithEdge = (src_node_id, newNode, newEdge) => {
    const body = {
      src_node_id: lastNodeInChain,
      node: {
        root_id: rootNodeID,
        label: 'state',
        name: 'new node',
        ui_position_x: newNode.x,
        ui_position_y: newNode.y,
        ...newNode,
      },
      edge: {
        ...DEFAULT_EDGE_PROPERTIES,
        root_id: rootNodeID,
        label: 'new edge',
        name: 'new edge',
        comment: 'action',
        description: 'WILL BE IN FUTURE',
        input_type: 'swipe',
        ...swipe_data2swipe(newEdge.swipe_data),
        ...newEdge,
        xpath: newEdge.xpath || '',
        action_type: newEdge.action_type || '',
      },
    };
    backendApiRequest({
      method: 'POST',
      path: `/admin/graphs/nodes/${lastNodeInChain}/connected-nodes`,
      params: null,
      body,
    }).then((res) => {
      const { node, edge } = res.data;
      const nodesCopy = graphData.nodes.slice();
      const edgesCopy = graphData.edges.slice();
      selectedNodeAfterUpdateGraphChange(node.id);

      const newNodeInGraph = {
        ...node,
        id: node.id,
        label: node.name,
        name: node.name,
        neo4j_label: node.label,
        x: node.ui_position_x,
        y: node.ui_position_y,
        ...(node.screenshot_link
          ? {
              title: htmlTitle(
                `<div class="node-main-popup"><img src="${node.screenshot_link}" alt="${node.name}" /></div>`
              ),
            }
          : {}),
        ...(node.state_id === node.id
          ? {
              font: {
                color: 'white',
              },
              color: {
                background: '#5fb60e',
                border: '#5fb60',
              },
            }
          : {
              font: {
                color: GRAPHCANVASOPTIONS.nodes.font.color,
              },
              color: {
                background: 'white',
              },
            }),
      };
      edgesCopy.push({
        ...edge,
        from: lastNodeInChain,
        to: node.id,
        swipe_data: swipe2swipe_data(edge.x, edge.x_offset, edge.y, edge.y_offset, edge.swipe_duration),
      });
      nodesCopy.push(newNodeInGraph);
      lastCreatedNodeChange(res.data);
      graphDataChange({
        nodes: nodesCopy,
        edges: edgesCopy,
      });
      lastNodeInChainChange(node.id);
    });
  };

  const handleNewEdgeForExistNodesAPI = (src_node_id, dst_node_id, newEdge) => {
    console.log(lastCreatedNode);
    backendApiRequest({
      method: 'POST',
      path: `/admin/graphs/nodes/${lastNodeInChain}/edges`,
      params: null,
      body: {
        ...DEFAULT_EDGE_PROPERTIES,
        node_id: dst_node_id,
        root_id: rootNodeID,
        label: 'new edge',
        name: 'new edge',
        comment: 'action',
        description: 'WILL BE IN FUTURE',
        ...swipe_data2swipe(null),
        input_type: 'swipe',
        ...newEdge,
        xpath: newEdge.xpath || '',
        action_type: newEdge.action_type || '',
      },
    }).then((newEdgeBackend) => {
      const nodesCopy = graphData.nodes.map((n) => ({
        ...n,
        ...(n.state_id === dst_node_id
          ? {
              font: {
                color: 'white',
              },
              color: {
                background: '#5fb60e',
                border: '#5fb60',
              },
            }
          : {
              font: {
                color: GRAPHCANVASOPTIONS.nodes.font.color,
              },
              color: {
                background: 'white',
              },
            }),
      }));
      const edgesCopy = graphData.edges.slice();
      selectedNodeAfterUpdateGraphChange(dst_node_id);
      edgesCopy.push({
        ...newEdgeBackend.data,
        from: lastNodeInChain,
        to: dst_node_id,
        swipe_data: swipe2swipe_data(
          newEdgeBackend.data.x,
          newEdgeBackend.data.x_offset,
          newEdgeBackend.data.y,
          newEdgeBackend.data.y_offset,
          newEdgeBackend.data.swipe_duration
        ),
      });
      graphDataChange({
        nodes: nodesCopy,
        edges: edgesCopy,
      });
      lastNodeInChainChange(dst_node_id);
    });
  };

  const handleUpdateEdge = (edge_id, edge, forceChangeLastNodeInChain = false) => {
    backendApiRequest({
      method: 'PUT',
      path: `/admin/graphs/edges/${edge_id}`,
      params: null,
      body: edge,
    }).then((res) => {
      const updatedEdge = res.data;
      let nodesCopy;
      if (forceChangeLastNodeInChain) {
        nodesCopy = graphData.nodes.map((n) => ({
          ...n,
          ...(n.state_id === edge.end_node_id
            ? {
                font: {
                  color: 'white',
                },
                color: {
                  background: '#5fb60e',
                  border: '#5fb60',
                },
              }
            : {
                font: {
                  color: GRAPHCANVASOPTIONS.nodes.font.color,
                },
                color: {
                  background: 'white',
                },
              }),
        }));
        lastNodeInChainChange(updatedEdge.end_node_id);
      } else {
        nodesCopy = graphData.nodes.slice();
      }
      const edgesCopy = graphData.edges.map((edge) =>
        edge.id !== edge_id
          ? edge
          : {
              ...updatedEdge,
              swipe_data: swipe2swipe_data(
                updatedEdge.x,
                updatedEdge.x_offset,
                updatedEdge.y,
                updatedEdge.y_offset,
                updatedEdge.swipe_duration
              ),
              from: updatedEdge.start_node_id,
              to: updatedEdge.end_node_id,
              input_type: updatedEdge.input_type,
              comment: updatedEdge.comment,
            }
      );
      graphDataChange({
        nodes: nodesCopy,
        edges: edgesCopy,
      });
    });
  };

  const handleUpdateNodeAPI = (nodeID, nodeData) => {
    backendApiRequest({
      method: 'PUT',
      path: `/admin/graphs/nodes/${nodeID}`,
      params: {},
      body: nodeData,
    }).then((res) => {
      const node = res.data;
      const nodesCopy = graphData.nodes.map((n) =>
        n.id !== nodeID
          ? n
          : {
              ...node,
              id: node.state_id,
              neo4j_label: node.labels[0],
              label: node.name,
              x: node.ui_position_x,
              y: node.ui_position_y,
              ...(node.screenshot_link
                ? {
                    title: htmlTitle(
                      `<div class="node-main-popup"><img src="${node.screenshot_link}" alt="${node.name}" /></div>`
                    ),
                  }
                : {}),
            }
      );
      graphDataChange({
        nodes: nodesCopy,
        edges: graphData.edges.slice(),
      });
    });
  };

  const handleDeleteNode = (nodeID, delete_root = 'False', label = 'state', createNewGraph, copyFromExist) => {
    backendApiRequest({
      method: 'DELETE',
      path: `/backoffice/graph/subgraph/${nodeID}`,
      params: { delete_root, label },
    }).then((graph) => {
      if (copyFromExist) {
        getAllAppsWithGraphs();
        return;
      }
      chosenEdgeChange(null);
      if (delete_root === 'True' && createNewGraph) {
        const device = getCurDeviceByID();
        const persona = productData.data.personas.find((p) => p.id === personaID);
        const app = productData.data.apps.find((a) => a.id === appID);
        const ver = app.releases.find((rel) => rel.id === versionID);
        createRootNode(device, persona, ver, app);
        return;
      }

      graphDataChange(reformatGraphData(graph));
    });
    lastCreatedNodeChange(null);
  };

  useEffect(() => {
    recordContextRef.current = recordContext;
  }, [recordContext]);

  const sendDataToDeviceOnFramerHandle = (method, args, forceTakeScreenData) => {
    const device = getCurDeviceByID();
    sendDataToDeviceOnFramer(device.api_url, method, device, args, forceTakeScreenData || recordContextRef.current);
  };

  if (productData.data && appID && versionID && deviceID && personaID) {
    let device, persona;
    try {
      device = productData.data.apps
        .find((a) => a.id === appID)
        .releases.find((rel) => rel.id === versionID)
        .devices.find((dev) => dev.id === deviceID);
      persona = productData.data.personas.find((p) => p.id === personaID);
      if (!device || !persona) new Error();
    } catch (e) {
      return (
        <div className="wrong-params">
          <h2>There is no graph with these parameters</h2>
          <Button
            onClick={() => {
              location.hash = '/graph-builder?';
              window.location.reload();
            }}
          >
            Reset
          </Button>
        </div>
      );
    }
  }
  const handleDeleteGraph = () => {
    handleDeleteNode(rootNodeID, 'True', 'root_state', true, false);
  };

  const updateChosenEdge = (i, key, value, subkey) => {
    const edgeCopy = { ...chosenEdge[i] };
    if (key === 'swipe_data_part') {
      if (!edgeCopy.swipe_data) {
        edgeCopy.swipe_data = {};
      }
      edgeCopy.swipe_data[subkey] = value;
    } else {
      edgeCopy[key] = value;
    }
    if (key === 'action_type') {
      edgeCopy.action_type = value;
    }
    chosenEdgeChange(chosenEdge.map((edge, index) => (index !== i ? edge : edgeCopy)));
  };

  const handleGetScreenData = (forceGetScreenData = false) => {
    sendDataToDeviceOnFramerHandle('get_screen_data', {}, forceGetScreenData);
  };

  const handleCopyGraph = () => {
    backendApiRequest({
      method: 'POST',
      path: `/admin/graphs/${graphIDforCopy}/copy`,
      body: {
        release_id: versionID,
        device_id: deviceID,
        persona_id: personaID,
      },
    }).then((graph) => {
      const oldRootNode = rootNodeID;
      rootNodeIDChange(graph.data.nodes[0].id);
      const newGraphData = reformatGraphData(graph.data);
      const lastNodeIDIChain = getLastNodeIDInChain(newGraphData);
      const lastNode = newGraphData.nodes.find((n) => n.id === lastNodeIDIChain);
      lastNode.font = {
        color: 'white',
      };
      lastNode.color = {
        background: '#5fb60e',
        border: '#5fb60',
      };
      lastNodeInChainChange(lastNodeIDIChain);
      graphDataChange(newGraphData);
      handleDeleteNode(oldRootNode, 'True', 'root_state', false, true);
    });
  };

  const saveFullGraphRequest = (nodes) => {
    isMassSavingChange(true);
    backendApiRequest({
      method: 'PUT',
      path: `/admin/graphs/nodes`,
      body: nodes,
    }).then((nodes) => {
      graphDataChange({
        nodes: nodes.data,
        edges: graphData.edges.slice(),
      });
      isMassSavingChange(false);
    });
  };

  const playActionOnFarmer = (contextEdge) => {
    const swipeData = contextEdge.swipe_data;
    const body = {
      clean_cache_package: false,
      xpath: contextEdge.xpath,
      comment: contextEdge.comment,
      description: contextEdge.description,
      input_type: contextEdge.action_type,
      exported_key: uuidv4(),
      use_laplace: true,
    };

    if (contextEdge.action_type === 'swipe_until' && contextEdge.x >= 0 && contextEdge.duration >= 0) {
      body.swipe_duration = contextEdge.duration;
      body.x = contextEdge.x;
      body.x_offset = contextEdge.x_offset;
      body.y = contextEdge.y;
      body.y_offset = contextEdge.y_offset;
    }

    if (
      contextEdge.action_type === 'swipe' ||
      (contextEdge.action_type === 'swipe_until' && swipeData && swipeData.x1)
    ) {
      body.swipe_duration = swipeData.duration;
      body.x = swipeData.x1 / farmerDevice.screen_res[0];
      body.x_offset = (swipeData.x2 - swipeData.x1) / farmerDevice.screen_res[0];
      body.y = swipeData.y1 / farmerDevice.screen_res[1];
      body.y_offset = (swipeData.y2 - swipeData.y1) / farmerDevice.screen_res[1];
    }

    if (contextEdge.action_type === 'xpath') {
      body.input_type = 'tap an element on the screen by its resource_id';
    }
    if (contextEdge.action_type === 'text') {
      body.input_type = 'text';
      body.params = contextEdge.input_text;
    }
    if (contextEdge.action_type === 'back') {
      body.input_type = 'key';
      body.params = 4;
    }
    if (contextEdge.action_type === 'press_return_key') {
      body.input_type = 'key';
      body.params = 66;
    }
    console.log(contextEdge, body);
    executeRunOnFramer(device, body, body.input_type);
  };

  const device = connectIsEnabled && getCurDeviceByID();
  const release = connectIsEnabled && getCurReleaseByID();

  return (
    <div className="graph-builder-wrapper">
      <div>{graphsProducts.isLoading && <Spin size="large" />}</div>
      <div>{graphsProducts.error}</div>
      <div className="graph-builder-filters">
        {graphsProducts.data && (
          <div className="graph-builder-filter graph-builder-filters-product">
            <label>Product</label>
            <Select
              showSearch
              disabled={connectIsEnabled}
              filterOption={(input, option) => option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0}
              className="products-selectbox"
              dropdownClassName="products-selectbox-dropdown"
              placeholder="Please choose product"
              onChange={(id) => productIDChange(id)}
              defaultValue={productID}
            >
              {graphsProducts.data.map((pr) => (
                <Select.Option key={pr.id} value={pr.id}>
                  <img src={pr.thumbnail} alt="product thumbnail" />
                  {pr.name}
                </Select.Option>
              ))}
            </Select>
            {productData.isLoading && <Spin size="small" />}
          </div>
        )}

        {productData.data && (
          <div className="graph-builder-filter graph-builder-filters-platforms">
            <label>Platform</label>
            <Select
              disabled={connectIsEnabled}
              className="products-apps-selectbox"
              dropdownClassName="products-apps-selectbox-dropdown"
              placeholder="Please choose platform"
              onChange={(id) => appIDChange(id)}
              defaultValue={appID}
            >
              {productData.data.apps.map((app) => (
                <Select.Option key={app.id} value={app.id}>
                  {app.platform} - {app.package}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {productData.data && (
          <div className="graph-builder-filter graph-builder-filters-versions">
            <label>Version</label>
            <Select
              disabled={!appID || connectIsEnabled}
              className="apps-versions-selectbox"
              dropdownClassName="apps-versions-selectbox-dropdown"
              placeholder="Please choose Version"
              onChange={(id) => versionIDChange(id)}
              defaultValue={versionID}
            >
              {appID &&
                productData.data.apps
                  .find((a) => a.id === appID)
                  .releases.sort((a, b) => (a.id > b.id ? -1 : 1))
                  .map((rel) => (
                    <Select.Option key={rel.id} value={rel.id}>
                      {rel.name} -{rel.date}
                    </Select.Option>
                  ))}
            </Select>
          </div>
        )}

        {productData.data && (
          <div className="graph-builder-filter graph-builder-filters-persona">
            <label>Persona</label>
            <Select
              disabled={connectIsEnabled}
              className="product-personas-selectbox"
              dropdownClassName="product-personas-selectbox-dropdown"
              placeholder="Please choose persona"
              onChange={(id) => personaIDChange(id)}
              defaultValue={personaID}
            >
              {productData.data.personas.map((pers) => (
                <Select.Option key={pers.id} value={pers.id}>
                  {pers.title}
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        {productData.data && (
          <div className="graph-builder-filter graph-builder-filters-devices">
            <label>Device</label>
            <Select
              disabled={!versionID || connectIsEnabled}
              className="apps-devices-selectbox"
              dropdownClassName="apps-devices-selectbox-dropdown"
              placeholder="Please choose device"
              onChange={(id) => deviceIDChange(id)}
              defaultValue={deviceID}
            >
              {versionID &&
                productData.data.apps
                  .find((a) => a.id === appID)
                  .releases.find((rel) => rel.id === versionID)
                  .devices.map((dev) => (
                    <Select.Option key={dev.id} value={dev.id} disabled={!!dev.allocated_to}>
                      {dev.model} {dev.allocated_to && `(${dev.allocated_to.split('@')[0]})`}{' '}
                      <span
                        className={`flag-icon flag-icon-${dev.orchestrator.location.toLowerCase()}`}
                        style={{
                          marginLeft: '1rem',
                        }}
                      />
                    </Select.Option>
                  ))}
            </Select>
          </div>
        )}
      </div>
      <div className="connect-status">
        <Switch
          onChange={(checked) => handleConnect(checked)}
          type="primary"
          disabled={!(appID && versionID && personaID && deviceID)}
        />
        {connectStatus === 'connecting' && <Spin size="small" />}
        {connectIsEnabled && device && (
          <div className="device-serial-number padding-left">Device ID : {device.serial_number}</div>
        )}
        {connectIsEnabled && device && (
          <div className="device-serial-number">
            OS: {device.platform} {device.os_version}
          </div>
        )}
        {connectIsEnabled && farmerDevice && (
          <div className="device-serial-number">
            <a href={`https://www.device-farmer.watchful.ai/?devId=${farmerDevice.id}&aslId=`}>
              Device: {farmerDevice.id}
            </a>
            <div
              className={`traffic-light ${farmerDeviceState && farmerDevice.video_stream_present ? 'green' : 'red'}`}
            />
          </div>
        )}
        {farmerError && (
          <div className="device-serial-number error">
            <Alert message={farmerError} type="error" closable onClose={() => farmerErrorChange(null)} />
          </div>
        )}
      </div>

      <div className="graph-builder-content">
        {connectIsEnabled && (
          <div className="main-graph">
            <div className="main-graph-content">
              <div className="graph-stats-header">
                <div className="remove-graph-wrapper">
                  <h2>Initial Map ({rootNodeID})</h2>
                  <Popconfirm
                    placement="top"
                    title="Delete the entire graph?"
                    onConfirm={handleDeleteGraph}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img src={delete_node_ico} className="delete-graph-icon" />
                  </Popconfirm>
                  {connectIsEnabled && graphData && (
                    <GraphRun
                      automationState={automationState}
                      automationStateChange={automationStateChange}
                      graphActions={graphActions}
                      playActionOnFarmer={playActionOnFarmer}
                      curEdgeIdInGraphRunChange={curEdgeIdInGraphRunChange}
                      farmerError={farmerError}
                      farmerErrorChange={farmerErrorChange}
                      stepIndex={stepIndex}
                      stepIndexChange={stepIndexChange}
                      startEdgeID={startEdgeID}
                      startEdgeIDChange={startEdgeIDChange}
                    />
                  )}
                  {appsWithGraphs && (
                    <Select style={{ width: '20rem' }} value={graphIDforCopy} onChange={graphIDforCopyChange}>
                      {appsWithGraphs
                        .filter((gr) => gr.app.id === appID && gr.id !== rootNodeID)
                        .map((gr) => (
                          <Select.Option
                            key={gr.id}
                            title={`${gr.version} - ${gr.persona.title} - ${gr.device.product_name}`}
                          >
                            {gr.id} - {gr.version.split('___')[1]} - {gr.persona.title} - {gr.device.product_name}
                          </Select.Option>
                        ))}
                    </Select>
                  )}
                  <Popconfirm
                    placement="top"
                    title="This graph will be overriden from copy. Are you sure?"
                    onConfirm={handleCopyGraph}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button>Copy graph</Button>
                  </Popconfirm>
                </div>
                <div className="graph-stats">
                  <div className="total-screens">Total screens: {graphData && graphData.nodes.length}</div>
                  <div className="total-vents">Total events: {graphData && graphData.edges.length}</div>
                </div>
                <div className="version">
                  <div className="text">
                    Release {release.name} ({release.date})
                  </div>
                </div>
              </div>

              <div className="web-socket-wrapper">
                {productData.data && deviceID && farmerDevice && (
                  <FarmerWebSocketConnection
                    connectStatusChange={connectStatusChange}
                    connectStatus={connectStatus}
                    webSocketUrl={device.ws_url}
                    deviceID={farmerDevice.id}
                    farDevListOfApps={farDevListOfApps}
                    farDevListOfAppsChange={farDevListOfAppsChange}
                    lastEdgeDataFarmerChange={lastEdgeDataFarmerChange}
                    lastScreenDataChange={lastScreenDataChange}
                    fetchingScreenShotDataChange={fetchingScreenShotDataChange}
                    handleGetScreenData={handleGetScreenData}
                    farmerDeviceStateChange={farmerDeviceStateChange}
                    farmerErrorChange={farmerErrorChange}
                    farmerDeviceChange={farmerDeviceChange}
                    userMistakeWarningChange={userMistakeWarningChange}
                  />
                )}
              </div>

              {productData.data && deviceID && graphData && (
                <NetworkGraph
                  selectedNodeAfterUpdateGraph={selectedNodeAfterUpdateGraph}
                  sendDataToDeviceOnFramerHandle={sendDataToDeviceOnFramerHandle}
                  farmerDevice={farmerDevice}
                  device={device}
                  farDevListOfApps={farDevListOfApps}
                  productData={productData}
                  deviceID={deviceID}
                  connectIsEnabled={connectIsEnabled}
                  streamURL={device.stream_url}
                  graphData={graphData}
                  handleNewNodeWithEdge={handleNewNodeWithEdge}
                  handleNewEdgeForExistNodesAPI={handleNewEdgeForExistNodesAPI}
                  handleUpdateNodeAPI={handleUpdateNodeAPI}
                  handleDeleteNode={handleDeleteNode}
                  handleUpdateEdge={handleUpdateEdge}
                  rootNodeID={rootNodeID}
                  lastEdgeDataFarmer={lastEdgeDataFarmer}
                  lastScreenData={lastScreenData}
                  lastScreenDataChange={lastScreenDataChange}
                  recordContext={recordContext}
                  recordContextChange={recordContextChange}
                  editedNode={editedNode}
                  editedNodeChange={editedNodeChange}
                  fetchingScreenShotData={fetchingScreenShotData}
                  isEdgeChosen={isEdgeChosen}
                  chosenEdge={chosenEdge}
                  isEdgeChosenChange={isEdgeChosenChange}
                  chosenEdgeChange={chosenEdgeChange}
                  EdgeDirActiveTab={EdgeDirActiveTab}
                  EdgeDirActiveTabChange={EdgeDirActiveTabChange}
                  selectedNodeAfterUpdateGraphChange={selectedNodeAfterUpdateGraphChange}
                  newScreenshotWaiting={newScreenshotWaiting}
                  newScreenshotWaitingChange={newScreenshotWaitingChange}
                  screenDataWaitingChange={screenDataWaitingChange}
                  lastNodeInChain={lastNodeInChain}
                  interactionWithVideo={interactionWithVideo}
                  interactionWithVideoChange={interactionWithVideoChange}
                  automationState={automationState}
                  automationStateChange={automationStateChange}
                  graphDataChange={graphDataChange}
                  saveFullGraphRequest={saveFullGraphRequest}
                  isMassSaving={isMassSaving}
                  isMassSavingChange={isMassSavingChange}
                  curEdgeIdInGraphRun={curEdgeIdInGraphRun}
                />
              )}
            </div>
          </div>
        )}

        {connectIsEnabled && graphData && (
          <EdgeEditing
            lastScreenData={lastScreenData}
            isEdgeChosen={isEdgeChosen && !!chosenEdge}
            graphData={graphData}
            updateChosenEdge={updateChosenEdge}
            chosenEdge={chosenEdge}
            handleUpdateEdge={handleUpdateEdge}
            handleGetScreenData={handleGetScreenData}
            farmerDevice={farmerDevice}
            device={device}
            automationStateChange={automationStateChange}
            lastEdgeDataFarmer={lastEdgeDataFarmer}
            chosenEdgeChange={chosenEdgeChange}
            EdgeDirActiveTab={EdgeDirActiveTab}
            EdgeDirActiveTabChange={EdgeDirActiveTabChange}
            lastScreenDataChange={lastScreenDataChange}
            screenDataWaiting={screenDataWaiting}
            screenDataWaitingChange={screenDataWaitingChange}
            playActionOnFarmer={playActionOnFarmer}
            userMistakeWarning={userMistakeWarning}
            userMistakeWarningChange={userMistakeWarningChange}
            startEdgeID={startEdgeID}
            startEdgeIDChange={startEdgeIDChange}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appsWithGraphs: state.UIViewerReducer.appsWithGraphs,
});

const mapDispatchToProps = { getAllAppsWithGraphs };

export default connect(mapStateToProps, mapDispatchToProps)(GraphBuilder);
