import React from 'react';
import { useMutation } from 'react-query';
import { connect } from 'react-redux';
import { Button, Icon, Popconfirm, Select, Table, Typography } from 'antd';
import { RELEASE_ANALYSIS_ISSUE_STATES } from '../../constants/releases';
import { updReleaseAnalysisIssue, delReleaseAnalysisIssue, addReleaseAnalysisIssue } from '../../api/releasesAPI';
import {
  addReleaseAnalysisIssueSuccess,
  updReleaseAnalysisIssueSuccess,
  delReleaseAnalysisIssueSuccess,
} from '../../redux/actions/appsReleasesPage';

const { Paragraph } = Typography;
const { Option } = Select;

const AnalysisIssues = ({ dispatch, releaseId, analysisIssues }) => {
  const updIssueMutation = useMutation(updReleaseAnalysisIssue, {
    onSuccess: (data) => dispatch(updReleaseAnalysisIssueSuccess(data.data)),
  });
  const delIssueMutation = useMutation(delReleaseAnalysisIssue, {
    onSuccess: (data, { issueId }) => dispatch(delReleaseAnalysisIssueSuccess(issueId)),
  });
  const addIssueMutation = useMutation(addReleaseAnalysisIssue, {
    onSuccess: (data) => dispatch(addReleaseAnalysisIssueSuccess(data.data)),
  });
  const updIssue = (issueId, body) => updIssueMutation.mutateAsync({ issueId, body });
  const delIssue = (issueId) => delIssueMutation.mutateAsync({ issueId });
  const addIssue = () => addIssueMutation.mutateAsync({ releaseId, body: { issue: '' } });

  const isLoading = [updIssueMutation, delIssueMutation, addIssueMutation].some((x) => x.isLoading);

  return (
    <React.Fragment>
      <Button onClick={addIssue}>Create Issue</Button>
      <Table
        loading={isLoading}
        dataSource={analysisIssues}
        pagination={false}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Created',
            dataIndex: 'created_at',
          },
          {
            title: 'User',
            dataIndex: 'user.name',
          },
          {
            title: 'Issue',
            dataIndex: 'issue',
            render: (value, record) => (
              <Paragraph editable={{ onChange: (issue) => updIssue(record.id, { issue }) }}>{value}</Paragraph>
            ),
          },
          {
            title: 'State',
            dataIndex: 'state',
            render: (value, record) => (
              <Select
                style={{ width: '8rem' }}
                onChange={(state) => updIssue(record.id, { state })}
                value={value.toLowerCase()}
              >
                {Object.entries(RELEASE_ANALYSIS_ISSUE_STATES).map(([key, value], _) => (
                  <Option value={key}>{value}</Option>
                ))}
              </Select>
            ),
          },
          {
            title: 'Resolution',
            dataIndex: 'resolution',
            render: (value, record) => (
              <Paragraph editable={{ onChange: (resolution) => updIssue(record.id, { resolution }) }}>
                {value}
              </Paragraph>
            ),
          },
          {
            title: 'Closed',
            dataIndex: 'closed_at',
          },
          {
            title: 'Actions',
            render: (record) => (
              <Popconfirm
                title="Are you sure?"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={() => delIssue(record.id)}
              >
                <Button icon="delete" type="danger small" />
              </Popconfirm>
            ),
          },
        ]}
      />
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({ dispatch }))(AnalysisIssues);
