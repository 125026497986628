import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const getLocations = () => ({
  type: AT.GET_LOCATIONS,
});

export const getLocationsSuccess = (locs) => ({
  type: AT.GET_LOCATIONS_SUCCESS,
  payload: locs,
});

export const getLocationsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'performance/locations',
    onSuccess: (body, dispatch) => dispatch(getLocationsSuccess(body)),
  },
});
export const getAppsData = () => ({
  type: AT.GET_APPS_DATA,
});

export const getAppsDataAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'app/data',
    label: NETWORK_LABELS.GET_APPS_DATA,
    onSuccess: (body, dispatch) => dispatch(getAppsDataSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAppsDataFailed(error)),
  },
});

export const getAppsDataSuccess = (appsData) => ({
  type: AT.GET_APPS_DATA_SUCCESS,
  payload: { appsData },
});

export const getAppsDataFailed = (errorStatus) => ({
  type: AT.GET_APPS_DATA_FAILED,
  payload: { errorStatus },
});

export const getReleases = (appId) => ({
  type: AT.GET_RELEASES,
  payload: { appId },
});

export const getReleasesAPI = (appId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `releases/get_app_releases?appId=${appId}`,
    label: NETWORK_LABELS.GET_RELEASES,
    onSuccess: (body, dispatch) => dispatch(getReleasesSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getReleasesFailed(error)),
  },
});

export const getReleasesSuccess = (releases) => ({
  type: AT.GET_RELEASES_SUCCESS,
  payload: { releases },
});

export const getReleasesFailed = (errorStatus) => ({
  type: AT.GET_RELEASES_FAILED,
  payload: { errorStatus },
});

export const setReleaseState = ({ releaseId, state }) => ({
  type: AT.SET_RELEASE_STATE,
  payload: { releaseId, state },
});

export const setReleaseIsVisible = ({ releaseId, isVisible }) => ({
  type: AT.SET_RELEASE_IS_VISIBLE,
  payload: { releaseId, isVisible },
});

export const setReleaseIsComparisonCompleted = ({ releaseId, isComparisonCompleted }) => ({
  type: AT.SET_RELEASE_IS_COMPARISON_COMPLETED,
  payload: { releaseId, isComparisonCompleted },
});

export const setReleaseParentId = ({ releaseId, paraentId }) => ({
  type: AT.SET_RELEASE_PARENT_ID,
  payload: { releaseId, paraentId },
});

export const setAppCategories = (appId, categories) => ({
  type: AT.SET_APP_CATEGORIES,
  payload: { appId, categories },
});

// ADD_APPS
export const addNewApp = (app) => ({
  type: AT.ADD_NEW_APPS,
  payload: { app },
});

export const updApp = (app) => ({
  type: AT.UPDATE_APP,
  payload: { app },
});

export const setAppCategoriesAPI = ({ appId, categories }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'app/categories',
    label: NETWORK_LABELS.ADD_NEW_APPS,
    method: 'PATCH',
    data: { app_id: appId, categories },
    onSuccess: (body, dispatch) => dispatch(setAppCategoriesSuccess(body)),
    onFailure: (error, dispatch) => dispatch(setAppCategoriesFailed(error)),
  },
});

export const addNewAppAPI = (app) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'app',
    label: NETWORK_LABELS.ADD_NEW_APPS,
    method: 'POST',
    data: app,
    onSuccess: (body, dispatch) => dispatch(addNewAppSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addNewAppFailed(error)),
  },
});

export const updAppAPI = (app) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/${app.id}`,
    label: NETWORK_LABELS.UPDATE_APP,
    method: 'POST',
    data: app,
    onSuccess: (body, dispatch) => dispatch(updAppSuccess(body)),
    onFailure: (error, dispatch) => dispatch(updAppFailed(error)),
  },
});

export const setAppCategoriesSuccess = (payload) => ({
  type: AT.SET_APP_CATEGORIES_SUCCESS,
  payload,
});
export const setAppCategoriesFailed = (errorStatus) => ({
  type: AT.SET_APP_CATEGORIES_FAILED,
  payload: { errorStatus },
});

export const addNewAppSuccess = (body) => ({
  type: AT.ADD_NEW_APPS_SUCCESS,
  payload: { app: body.data },
});

export const addNewAppFailed = (errorStatus) => ({
  type: AT.ADD_NEW_APPS_FAILED,
  payload: { errorStatus },
});

export const updAppSuccess = (body) => ({
  type: AT.UPDATE_APP_SUCCESS,
  payload: { app: body.data },
});

export const updAppFailed = (errorStatus) => ({
  type: AT.UPDATE_APP_FAILED,
  payload: { errorStatus },
});

// UPDATE_APPS
export const updateApps = (appsData) => ({
  type: AT.UPDATE_APPS,
  payload: { appsData },
});

export const updateAppsAPI = (appsData) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'app/data',
    label: NETWORK_LABELS.UPDATE_APPS,
    method: 'PUT',
    data: appsData,
    onSuccess: (body, dispatch) => dispatch(updateAppsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(updateAppsFailed(error)),
  },
});

export const updateAppsSuccess = () => ({ type: AT.GET_APPS_DATA });

export const updateAppsFailed = (errorStatus) => ({
  type: AT.UPDATE_APPS_FAILED,
  payload: { errorStatus },
});

// ADD_RELLEASE
export const addRelease = (release) => ({
  type: AT.ADD_RELEASE,
  payload: { release },
});

export const addReleaseAPI = (release) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'releases',
    label: NETWORK_LABELS.ADD_RELEASE,
    method: 'POST',
    data: release,
    onSuccess: (body, dispatch) => dispatch(addReleaseSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addReleaseFailed(error)),
  },
});

export const addReleaseSuccess = (body) => ({
  type: AT.ADD_RELEASE_SUCCESS,
  payload: { release: body.data },
});

export const addReleaseFailed = (errorStatus) => ({
  type: AT.ADD_RELEASE_FAILED,
  payload: { errorStatus },
});

// UPDATE RELEASE

export const updRelease = (releaseID, data) => ({
  type: AT.UPD_RELEASE,
  payload: { releaseID, data },
});

export const updReleaseAPI = (releaseID, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `releases/${releaseID}`,
    label: NETWORK_LABELS.UPD_RELEASE,
    method: 'PUT',
    data,
    onSuccess: (body, dispatch) => dispatch(updReleaseSuccess(body)),
    onFailure: (error, dispatch) => dispatch(updReleaseFailed(error)),
  },
});

export const updReleaseSuccess = (body) => ({
  type: AT.UPD_RELEASE_SUCCESS,
  payload: { release: body.data },
});

export const updReleaseFailed = (errorStatus) => ({
  type: AT.UPD_RELEASE_FAILED,
  payload: { errorStatus },
});

// DELETE RELEASE

export const delRelease = (releaseID) => ({
  type: AT.DEL_RELEASE,
  payload: { releaseID },
});

export const delReleaseAPI = (releaseID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `releases/${releaseID}`,
    label: NETWORK_LABELS.DEL_RELEASE,
    method: 'DELETE',
    onSuccess: (body, dispatch) => dispatch(delReleaseSuccess(releaseID)),
    onFailure: (error, dispatch) => dispatch(delReleaseFailed(error)),
  },
});

export const delReleaseSuccess = (releaseID) => ({
  type: AT.DEL_RELEASE_SUCCESS,
  payload: { releaseID },
});

export const delReleaseFailed = (errorStatus) => ({
  type: AT.DEL_RELEASE_FAILED,
  payload: { errorStatus },
});

// REFRESH PRESIGNED URL
export const refreshPresignedUrl = () => ({
  type: AT.REFRESH_PRESIGNED_URL,
  payload: {},
});

export const refreshPresignedUrlAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'releases/presigned_url',
    label: NETWORK_LABELS.REFRESH_PRESIGNED_URL,
    method: 'GET',
    onSuccess: (body, dispatch) => dispatch(refreshPresignedUrlSuccess(body)),
    onFailure: (error, dispatch) => dispatch(refreshPresignedUrlFailed(error)),
  },
});

export const refreshPresignedUrlSuccess = (body) => ({
  type: AT.REFRESH_PRESIGNED_URL_SUCCESS,
  payload: { ...body },
});

export const refreshPresignedUrlFailed = (errorStatus) => ({
  type: AT.REFRESH_PRESIGNED_URL_FAILED,
  payload: { errorStatus },
});

// GET RELEASE REPORT LINK

export const getReportLink = (releaseID, reportDir) => ({
  type: AT.GET_RELEASE_REPORT,
  payload: { releaseID, reportDir },
});

export const getReportLinkAPI = (releaseID, reportDir) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `releases/${releaseID}/reports?dir=${reportDir}`,
    label: NETWORK_LABELS.GET_RELEASE_REPORT,
    method: 'GET',
    onSuccess: (body, dispatch) => dispatch(getReportLinkSuccess(body)),
    onFailure: (error, dispatch) => {
      if (error.response.statusCode === 404) {
        return dispatch(getReportLinkEmpty(error));
      }
      return dispatch(getReportLinkFailed(error));
    },
  },
});

export const getReportLinkSuccess = (body) => ({
  type: AT.GET_RELEASE_REPORT_SUCCESS,
  payload: { ...body },
});

export const getReportLinkFailed = (error) => ({
  type: AT.GET_RELEASE_REPORT_FAILED,
  payload: { error },
});

export const getReportLinkEmpty = (error) => ({
  type: AT.GET_RELEASE_REPORT_EMPTY,
  payload: { ...error.response.body },
});

// Release Analysis Issues

export const addReleaseAnalysisIssueSuccess = (issue) => ({
  type: AT.ADD_RELEASE_ANALYSIS_ISSUE_SUCCESS,
  payload: { issue },
});

export const updReleaseAnalysisIssueSuccess = (issue) => ({
  type: AT.UPD_RELEASE_ANALYSIS_ISSUE_SUCCESS,
  payload: { issue },
});

export const delReleaseAnalysisIssueSuccess = (issueId) => ({
  type: AT.DEL_RELEASE_ANALYSIS_ISSUE_SUCCESS,
  payload: { issueId },
});
