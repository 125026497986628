import { handleActions } from 'redux-actions';
import * as AT from '../actionTypes';

const initialState = {
  subscriptions: [],
};

const subscriptionsReducer = handleActions(
  {
    [AT.GET_USER_SUBSCRIPTIONS]: (state) => ({
      ...state,
    }),
    [AT.GET_USER_SUBSCRIPTIONS_SUCCESS]: (state, action) => ({
      ...state,
      subscriptions: action.payload.data,
    }),

    [AT.CREATE_SUBSCRIPTION]: (state) => ({
      ...state,
    }),
    [AT.CREATE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
      ...state,
      subscriptions: [...state.subscriptions, action.payload.data],
    }),
    [AT.DELETE_SUBSCRIPTION]: (state) => ({
      ...state,
    }),
    [AT.DELETE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
      ...state,
      subscriptions: state.subscriptions.filter((sub) => sub.id !== action.payload.subscriptionID),
    }),
    [AT.UPDATE_SUBSCRIPTION]: (state) => ({
      ...state,
    }),
    [AT.UPDATE_SUBSCRIPTION_SUCCESS]: (state, action) => ({
      ...state,
      subscriptions: state.subscriptions.map((x) =>
        x.id === action.payload.subscriptionID ? action.payload.subscription : x
      ),
    }),
  },
  initialState
);

export default subscriptionsReducer;
