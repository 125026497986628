import * as AT from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  orchestrators: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case AT.API_REQUEST_ORCHESTRATOR_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    }
    case AT.GET_ORCHESTRATORS_LIST: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AT.GET_ORCHESTRATORS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orchestrators: action.payload.data,
      };
    }
    case AT.ADD_NEW_ORCHESTRATOR: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AT.ADD_NEW_ORCHESTRATOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orchestrators: [action.payload.orchestrator, ...state.orchestrators],
      };
    }
    case AT.UPDATE_ORCHESTRATOR: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AT.UPDATE_ORCHESTRATOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orchestrators: state.orchestrators.map((x) =>
          x.id === action.payload.orchestrator.id ? action.payload.orchestrator : x
        ),
      };
    }
    case AT.DELETE_ORCHESTRATOR: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case AT.DELETE_ORCHESTRATOR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        orchestrators: state.orchestrators.filter((x) => x.id !== action.payload.orchestratorId),
      };
    }
    default: {
      return state;
    }
  }
}
