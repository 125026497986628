import { getInsightsAPI } from '../actions/InsightsListPage';
import { copyInsightAPI } from '../actions/insightPage';
import * as AT from '../actionTypes';

const insightsListMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_INSIGHTS_LIST: {
        dispatch(getInsightsAPI(action.payload));
        return next(action);
      }
      case AT.COPY_INSIGHT: {
        dispatch(copyInsightAPI(action.payload));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default insightsListMiddleware;
