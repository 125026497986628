export const RELEASE_STATES = [
  'new',
  'in_review',
  'analysis_pending',
  'analysis_in_progress',
  'analysis_included',
  'published',
  'completed',
];
export const RELEASE_ANALYSIS_ISSUE_STATES = {
  opened: 'Opened',
  in_progress: 'In progress',
  closed: 'Closed',
};
