export const EDITING_MODES = {
  None: 'None',
  PrintScreen: 'PrintScreen',
  InsightCreation: 'InsightCreation',
  AddToInsight: 'AddToInsight',
};

export const VIEWS = {
  VIEW_LEFT: 'ViewLeft',
  VIEW_RIGHT: 'ViewRight',
};

export const SIMPLE_DEVICE_MANAGER_VERSION = '1.0.0';
export const FRAMES_SERVICE_VERSION = '2.1.0';

export const URL_PARAMS = {
  AppId: 'appId',
  LeftDevice: 'leftDevice',
  RightDevice: 'rightDevice',
  MockEnabled: 'mockEnabled',
  Remote: 'remote',
};

export const ASSET_TYPES = {
  Previous: 'previous',
  Current: 'current',
};

export const SOCKET_SEND_EVENTS = {
  GetDiffData: 'get-diff-data',
  HintsAnalysisStart: 'hints-analysis-start',
  HintsAnalysisStop: 'hints-analysis-stop',
  RpStart: 'rp-start',
  RpStop: 'rp-stop',
  VideoStart: 'video-start',
  VideoStop: 'video-stop',
};

export const LEFT_DEVICE_STREAM_PORT = 8055;
export const RIGHT_DEVICE_STREAM_PORT = 8056;

export const RIGHT_VIDEO_CONTAINER_ID = 'video-viewer-right';
export const LEFT_VIDEO_CONTAINER_ID = 'video-viewer-left';

export const CUSTOM_DEVICE = {
  serial: 'CUSTOM',
  device_name: '',
  model: '',
  os_version: 'None',
  platform: '',
};

export const UJ_ACTION_TYPES = {
  Click: 'tap',
  Swipe: 'swipe',
};

export const INSIGHT_ASSET_TYPES = {
  Image: 'image',
  Video: 'video',
};

export const REMOTE_OPTIONS = [
  {
    name: 'Local',
    videoSocketUrlLeft: 'ws://localhost:8055',
    videoSocketUrlRight: 'ws://localhost:8056',
    deviceServicesHostUrl: 'http://localhost:4567',
    wsUrl: 'ws://localhost:8008',
  },
  {
    name: 'Office',
    videoSocketUrlLeft: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18055',
    videoSocketUrlRight: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18056',
    deviceServicesHostUrl: 'https://4rfedb3gnz.tunn3lz.watchful.ai:14567',
    wsUrl: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18008',
  },
];
