import { Button } from 'antd';
import React from 'react';
import './AddToInsight.scss';

export const AddToInsight = ({ onCancel, onAddToInsight, selectedInsight }) => {
  return (
    <div className="addToInsightContainer">
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={onAddToInsight} disabled={!selectedInsight}>
        Add
      </Button>
    </div>
  );
};
