import React from 'react';
import './Spinner.scss';
import spinner from '../../../../assets/icons/spinner.png';

const Spinner = () => (
  <div className="spinner">
    <img src={spinner} alt="spinner" className="spin" />
  </div>
);

export default Spinner;
