// LOGIN
export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

// INSIGHTS
export const LOAD_INSIGHT = 'LOAD_INSIGHT';
export const LOAD_APPS = 'LOAD_APPS';
export const SET_APPS = 'SET_APPS';
export const SELECT_APP = 'SELECT_APP';
export const API_REQUEST = 'API_REQUEST';
export const START_NETWORK = 'START_NETWORK';
export const END_NETWORK = 'END_NETWORK';
export const LOAD_INSIGHTS = 'LOAD_INSIGHTS';
export const SET_INSIGHTS = 'SET_INSIGHTS';
export const LOAD_LABELS = 'LOAD_LABELS';
export const SET_LABELS = 'SET_LABELS';
export const SELECT_INSIGHT = 'SELECT_INSIGHT';
export const SET_APP_VERSIONS = 'SET_APP_VERSIONS';
export const INSIGHT_SAVED = 'INSIGHT_SAVED';
export const BEFORE_INSIGHT_SAVED = 'BEFORE_INSIGHT_SAVED';
export const INSIGHT_SAVED_ERROR = 'INSIGHT_SAVED_ERROR';
export const SET_INSIGHTS_IMPORTANCE = 'SET_INSIGHTS_IMPORTANCE';
export const UPD_INSIGHT_THUMBNAIL = 'SET_INSIGHT_THUMBNAIL';
export const UPD_INSIGHT_THUMBNAIL_SUCCESS = 'SET_INSIGHT_THUMBNAIL_SUCCESS';
export const GET_INSIGHT_PERSONAS = 'GET_INSIGHT_PERSONAS';
export const GET_INSIGHT_PERSONAS_SUCCESS = 'GET_INSIGHT_PERSONAS_SUCCESS';
export const COPY_INSIGHT = 'COPY_INSIGHT';
export const COPY_INSIGHT_SUCCESS = 'COPY_INSIGHT_SUCCESS';
//
export const CREATE_INSIGHT = 'CREATE_INSIGHT';
export const CREATE_INSIGHT_SUCCESS = 'CREATE_INSIGHT_SUCCESS';
export const CREATE_INSIGHT_FAILED = 'CREATE_INSIGHT_FAILED';
//
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_IMAGE_SUCCESS = 'ADD_IMAGE_SUCCESS';
export const ADD_IMAGE_FAILED = 'ADD_IMAGE_FAILED';
export const ADD_IMAGE_PROGRESS = 'ADD_IMAGE_PROGRESS';
//
export const GET_INSIGHTS_BY_IDS = 'GET_INSIGHTS_BY_IDS';
export const GET_INSIGHTS_BY_IDS_SUCCESS = 'GET_INSIGHTS_BY_IDS_SUCCESS';

// VARIANTS
export const GET_VARIANTS = 'GET_VARIANTS';
export const GET_VARIANTS_SUCCESS = 'GET_VARIANTS_SUCCESS';
export const ADD_VARIANT = 'ADD_VARIANT';
export const ADD_VARIANT_SUCCESS = 'ADD_VARIANT_SUCCESS';
export const DEL_VARIANT = 'DEL_VARIANT';
export const DEL_VARIANT_SUCCESS = 'DEL_VARIANT_SUCCESS';
export const UPD_VARIANT = 'UPD_VARIANT';
export const UPD_VARIANT_SUCCESS = 'UPD_VARIANT_SUCCESS';
export const VARIANTS_API_REQUEST_FAILED = 'VARIANTS_API_REQUEST_FAILED';

// VARIANT ASSETS
export const ADD_VARIANT_ASSET = 'ADD_VARIANT_ASSET';
export const ADD_VARIANT_ASSET_SUCCESS = 'ADD_VARIANT_ASSET_SUCCESS';
export const ADD_VARIANT_ASSET_PROGRESS = 'ADD_VARIANT_ASSET_PROGRESS';
export const DEL_VARIANT_ASSET = 'DEL_VARIANT_ASSET';
export const DEL_VARIANT_ASSET_SUCCESS = 'DEL_VARIANT_ASSET_SUCCESS';
export const UPD_VARIANT_ASSET = 'UPD_VARIANT_ASSET';
export const UPD_VARIANT_ASSET_SUCCESS = 'UPD_VARIANT_ASSET_SUCCESS';

// VARIANT RELEASE
export const ADD_VARIANT_RELEASE = 'ADD_VARIANT_RELEASE';
export const ADD_VARIANT_RELEASE_SUCCESS = 'ADD_VARIANT_RELEASE_SUCCESS';
export const DEL_VARIANT_RELEASE = 'DEL_VARIANT_RELEASE';
export const DEL_VARIANT_RELEASE_SUCCESS = 'DEL_VARIANT_RELEASE_SUCCESS';

// INSIGHT ASSETS
export const GET_INSIGHT_ASSETS = 'GET_INSIGHT_ASSETS';
export const GET_INSIGHT_ASSETS_SUCCESS = 'GET_INSIGHT_ASSETS_SUCCESS';
export const ADD_INSIGHT_ASSET = 'ADD_INSIGHT_ASSET';
export const ADD_INSIGHT_ASSET_SUCCESS = 'ADD_INSIGHT_ASSET_SUCCESS';
export const ADD_INSIGHT_ASSET_PROGRESS = 'ADD_INSIGHT_ASSET_PROGRESS';
export const DEL_INSIGHT_ASSET = 'DEL_INSIGHT_ASSET';
export const DEL_INSIGHT_ASSET_SUCCESS = 'DEL_INSIGHT_ASSET_SUCCESS';
export const UPD_INSIGHT_ASSET = 'UPD_INSIGHT_ASSET';
export const UPD_INSIGHT_ASSET_SUCCESS = 'UPD_INSIGHT_ASSET_SUCCESS';
export const INSIGHT_ASSETS_API_REQUEST_FAILED = 'INSIGHT_ASSETS_API_REQUEST_FAILED';
export const REFRESH_INSIGHT_PRESIGNED_URL = 'REFRESH_INSIGHT_PRESIGNED_URL';
export const REFRESH_INSIGHT_PRESIGNED_URL_SUCCESS = 'REFRESH_INSIGHT_PRESIGNED_URL_SUCCESS';

// INSIGHT CTAS
export const INSIGHT_CTA_API_REQUEST_FAILED = 'INSIGHT_CTA_API_REQUEST_FAILED';
export const GET_INSIGHT_CTAS = 'GET_INSIGHT_CTAS';
export const GET_INSIGHT_CTAS_SUCCESS = 'GET_INSIGHT_CTAS_SUCCESS';
export const ADD_INSIGHT_CTA = 'ADD_INSIGHT_CTA';
export const ADD_INSIGHT_CTA_SUCCESS = 'ADD_INSIGHT_CTA_SUCCESS';
export const UPD_INSIGHT_CTA = 'UPD_INSIGHT_CTA';
export const UPD_INSIGHT_CTA_SUCCESS = 'UPD_INSIGHT_CTA_SUCCESS';
export const DEL_INSIGHT_CTA = 'DEL_INSIGHT_CTA';
export const DEL_INSIGHT_CTA_SUCCESS = 'DEL_INSIGHT_CTA_SUCCESS';

// INSIGHT TIMELINE
export const INSIGHT_TIMELINE_API_REQUEST_FAILED = 'INSIGHT_TIMELINE_API_REQUEST_FAILED';
export const ADD_INSIGHT_TIMELINE = 'ADD_INSIGHT_TIMELINE';
export const ADD_INSIGHT_TIMELINE_SUCCESS = 'ADD_INSIGHT_TIMELINE_SUCCESS';
export const UPD_INSIGHT_TIMELINE = 'UPD_INSIGHT_TIMELINE';
export const UPD_INSIGHT_TIMELINE_SUCCESS = 'UPD_INSIGHT_TIMELINE_SUCCESS';
export const DEL_INSIGHT_TIMELINE = 'DEL_INSIGHT_TIMELINE';
export const DEL_INSIGHT_TIMELINE_SUCCESS = 'DEL_INSIGHT_TIMELINE_SUCCESS';

// PERFORMANCE
export const GET_APPS_LIST = 'GET_APPS_LIST';
export const GET_APPS_LIST_SUCCESS = 'GET_APPS_LIST_SUCCESS';
export const SET_PERF_APPS = 'SET_PERF_APPS;';

export const GET_APP_EVENTS = 'GET_APP_EVENTS';
export const GET_APP_EVENTS_SUCCESS = 'GET_APP_EVENTS_SUCCESS';
export const GET_APP_EVENTS_FAILED = 'GET_APP_EVENTS_FAILED';

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS';
export const GET_ALL_PRODUCTS_SUCCESS = 'GET_ALL_PRODUCTS_SUCCESS';
export const ADD_NEW_PRODUCT = 'ADD_NEW_PRODUCT';
export const ADD_NEW_PRODUCT_SUCCESS = 'ADD_NEW_PRODUCT_SUCCESS';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';

export const ADD_APP_EVENTS = 'ADD_APP_EVENTS';
export const ADD_APP_EVENTS_SUCCESS = 'ADD_APP_EVENTS_SUCCESS';

export const REMOVE_APP_EVENTS = 'REMOVE_APP_EVENTS';
export const REMOVE_APP_EVENTS_SUCCESS = 'REMOVE_APP_EVENTS_SUCCESS';

export const GET_APP_DEVICES = 'GET_APP_DEVICES';
export const GET_APP_DEVICES_SUCCESS = 'GET_APP_DEVICES_SUCCESS';
export const GET_APP_DEVICES_FAILED = 'GET_APP_DEVICES_FAILED';

export const ADD_APP_DEVICES = 'ADD_APP_DEVICES';
export const ADD_APP_DEVICES_SUCCESS = 'ADD_APP_DEVICES_SUCCESS';
export const ADD_APP_DEVICES_FAILED = 'ADD_APP_DEVICES_FAILED';

export const REMOVE_APP_DEVICES = 'REMOVE_APP_DEVICES';
export const REMOVE_APP_DEVICES_SUCCESS = 'REMOVE_APP_DEVICES_SUCCESS';
export const REMOVE_APP_DEVICES_FAILED = 'REMOVE_APP_DEVICES_FAILED';

export const LOAD_EMAILS = 'LOAD_EMAILS';
export const LOAD_EMAILS_SUCCESS = 'LOAD_EMAILS_SUCCESS';

// Users
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';
export const GET_ALL_APPS = 'GET_ALL_APPS';
export const GET_ALL_APPS_SUCCESS = 'GET_ALL_APPS_SUCCESS';
export const GET_ALL_APPS_FAILED = 'GET_ALL_APPS_FAILED';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const INIT_USER_MSG = 'INIT_USER_MSG';
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const DELETE_USER_APP = 'DELETE_USER_APP';
export const DELETE_USER_APP_SUCCESS = 'DELETE_USER_APP_SUCCESS';
export const DELETE_USER_APP_FAILED = 'DELETE_USER_APP_FAILED';
export const REMOVE_USER_APP = 'REMOVE_USER_APP';
export const ADD_USER_APP = 'ADD_USER_APP';
export const EDIT_USER_APPS = 'EDIT_USER_APPS';
export const EDIT_USER_APPS_SUCCESS = 'EDIT_USER_APPS_SUCCESS';
export const EDIT_USER_APPS_FAILED = 'EDIT_USER_APPS_FAILED';
export const EDIT_USER_DEATILS = 'EDIT_USER_DEATILS';
export const EDIT_USER_DEATILS_SUCCESS = 'EDIT_USER_DEATILS_SUCCESS';
export const EDIT_USER_DEATILS_FAILED = 'EDIT_USER_DEATILS_FAILED';
export const GET_USER_APPS = 'GET_USER_APPS';
export const GET_USER_APPS_SUCCESS = 'GET_USER_APPS_SUCCESS';
export const GET_USER_APPS_FAILED = 'GET_USER_APPS_FAILED';
export const SET_USER_PASSWORD = 'SET_USER_PASSWORD';
export const SET_USER_PASSWORD_SUCCESS = 'SET_USER_PASSWORD_SUCCESS';
export const SET_USER_PASSWORD_FAILED = 'SET_USER_PASSWORD_FAILED';

// Organization
export const ADD_USER_GROUPS = 'ADD_USER_GROUPS';
export const ADD_USER_GROUPS_SUCCESS = 'ADD_USER_GROUPS_SUCCESS';
export const ADD_USER_GROUPS_FAILED = 'ADD_USER_GROUPS_FAILED';

export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_USER_GROUPS_SUCCESS = 'SET_USER_GROUPS_SUCCESS';
export const SET_USER_GROUPS_FAILED = 'SET_USER_GROUPS_FAILED';

export const DELETE_USER_GROUPS = 'DELETE_USER_GROUPS';
export const DELETE_USER_GROUPS_SUCCESS = 'DELETE_USER_GROUPS_SUCCESS';
export const DELETE_USER_GROUPS_FAILED = 'DELETE_USER_GROUPS_FAILED';

export const GET_ALL_USER_GROUPS = 'GET_ALL_USER_GROUPS';
export const GET_ALL_USER_GROUPS_SUCCESS = 'GET_ALL_USER_GROUPS_SUCCESS';
export const GET_ALL_USER_GROUPS_FAILED = 'GET_ALL_USER_GROUPS_FAILED';

export const GET_ALL_USER_GROUP_PERMS = 'GET_ALL_USER_GROUP_PERMS';
export const GET_ALL_USER_GROUP_PERMS_SUCCESS = 'GET_ALL_USER_GROUP_PERMS_SUCCESS';
export const GET_ALL_USER_GROUP_PERMS_FAILED = 'GET_ALL_USER_GROUP_PERMS_FAILED';

export const SET_PERM_USER_GROUPS = 'SET_PERM_USER_GROUPS';
export const SET_PERM_USER_GROUPS_SUCCESS = 'SET_PERM_USER_GROUPS_SUCCESS';
export const SET_PERM_USER_GROUPS_FAILED = 'SET_PERM_USER_GROUPS_FAILED';

export const SET_DOMAIN_USER_GROUPS = 'SET_DOMAIN_USER_GROUPS';
export const SET_DOMAIN_USER_GROUPS_SUCCESS = 'SET_DOMAIN_USER_GROUPS_SUCCESS';
export const SET_DOMAIN_USER_GROUPS_FAILED = 'SET_DOMAIN_USER_GROUPS_FAILED';

export const SET_ORCHESTRATOR_USER_GROUPS = 'SET_ORCHESTRATOR_USER_GROUPS';
export const SET_ORCHESTRATOR_USER_GROUPS_SUCCESS = 'SET_ORCHESTRATOR_USER_GROUPS_SUCCESS';
export const SET_ORCHESTRATOR_USER_GROUPS_FAILED = 'SET_ORCHESTRATOR_USER_GROUPS_FAILED';

export const SET_APP_USER_GROUPS = 'SET_APP_USER_GROUPS';
export const SET_APP_USER_GROUPS_SUCCESS = 'SET_APP_USER_GROUPS_SUCCESS';
export const SET_APP_USER_GROUPS_FAILED = 'SET_APP_USER_GROUPS_FAILED';

export const SET_APP_USER_MISSING_GROUPS = 'SET_APP_USER_MISSING_GROUPS';
export const SET_APP_USER_MISSING_GROUPS_SUCCESS = 'SET_APP_USER_MISSING_GROUPS_SUCCESS';

export const GET_ORCHESTRATORS_LIST = 'GET_ORCHESTRATORS_LIST';
export const GET_ORCHESTRATORS_LIST_SUCCESS = 'GET_ORCHESTRATORS_LIST_SUCCESS';
export const API_REQUEST_ORCHESTRATOR_FAILED = 'API_REQUEST_ORCHESTRATOR_FAILED';
export const ADD_NEW_ORCHESTRATOR = 'ADD_NEW_ORCHESTRATOR';
export const ADD_NEW_ORCHESTRATOR_SUCCESS = 'ADD_NEW_ORCHESTRATOR_SUCCESS';
export const UPDATE_ORCHESTRATOR = 'UPDATE_ORCHESTRATOR';
export const UPDATE_ORCHESTRATOR_SUCCESS = 'UPDATE_ORCHESTRATOR_SUCCESS';
export const DELETE_ORCHESTRATOR = 'DELETE_ORCHESTRATOR';
export const DELETE_ORCHESTRATOR_SUCCESS = 'DELETE_ORCHESTRATOR_SUCCESS';
export const ADD_PRODUCT_TO_USER_GROUP = 'ADD_PRODUCT_TO_USER_GROUP';
export const ADD_PRODUCT_TO_USER_GROUP_SUCCESS = 'ADD_PRODUCT_TO_USER_GROUP_SUCCESS';
export const DEL_PRODUCT_FROM_USER_GROUP = 'DEL_PRODUCT_FROM_USER_GROUP';
export const DEL_PRODUCT_FROM_USER_GROUP_SUCCESS = 'DEL_PRODUCT_FROM_USER_GROUP_SUCCESS';
export const USER_GROUPS_PRODUCTS_API_REQUEST_FAILED = 'USER_GROUPS_PRODUCTS_API_REQUEST_FAILED';

// AppsReleases
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_LOCATIONS_FAILED = 'GET_LOCATIONS_FAILED';
export const GET_APPS_DATA = 'GET_APPS_DATA';
export const GET_APPS_DATA_SUCCESS = 'GET_APPS_DATA_SUCCESS';
export const GET_APPS_DATA_FAILED = 'GET_APPS_DATA_FAILED';
export const GET_RELEASES = 'GET_RELEASES';
export const GET_RELEASES_SUCCESS = 'GET_RELEASES_SUCCESS';
export const GET_RELEASES_FAILED = 'GET_RELEASES_FAILED';
export const GET_RELEASE_REPORT = 'GET_RELEASE_REPORT';
export const GET_RELEASE_REPORT_SUCCESS = 'GET_RELEASE_REPORT_SUCCESS';
export const GET_RELEASE_REPORT_FAILED = 'GET_RELEASE_REPORT_FAILED';
export const GET_RELEASE_REPORT_EMPTY = 'GET_RELEASE_REPORT_EMPTY';
export const SET_RELEASE_STATE = 'SET_RELEASE_STATE';
export const SET_RELEASE_IS_VISIBLE = 'SET_RELEASE_IS_VISIBLE';
export const SET_RELEASE_IS_COMPARISON_COMPLETED = 'SET_RELEASE_IS_COMPARISON_COMPLETED';
export const SET_RELEASE_PARENT_ID = 'SET_RELEASE_PARENT_ID';
export const ADD_RELEASE_ANALYSIS_ISSUE_SUCCESS = 'ADD_RELEASE_ANALYSIS_ISSUE_SUCCESS';
export const UPD_RELEASE_ANALYSIS_ISSUE_SUCCESS = 'UPD_RELEASE_ANALYSIS_ISSUE_SUCCESS';
export const DEL_RELEASE_ANALYSIS_ISSUE_SUCCESS = 'DEL_RELEASE_ANALYSIS_ISSUE_SUCCESS';
export const ADD_NEW_APPS = 'ADD_NEW_APPS';
export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS';
export const UPDATE_APP_FAILED = 'UPDATE_APP_FAILED';
export const SET_APP_CATEGORIES = 'SET_APP_CATEGORIES';
export const SET_APP_CATEGORIES_FAILED = 'SET_APP_CATEGORIES_FAILED';
export const SET_APP_CATEGORIES_SUCCESS = 'SET_APP_CATEGORIES_SUCCESS';
export const SET_USER = 'SET_USER';
export const ADD_NEW_APPS_SUCCESS = 'ADD_NEW_APPS_SUCCESS';
export const ADD_NEW_APPS_FAILED = 'ADD_NEW_APPS_FAILED';
export const UPDATE_APPS = 'UPDATE_APPS';
export const UPDATE_APPS_SUCCESS = 'UPDATE_APPS_SUCCESS';
export const UPDATE_APPS_FAILED = 'UPDATE_APPS_FAILED';
export const ADD_RELEASE = 'ADD_RELEASE';
export const ADD_RELEASE_SUCCESS = 'ADD_RELEASE_SUCCESS';
export const ADD_RELEASE_FAILED = 'ADD_RELEASE_FAILED';
export const UPD_RELEASE = 'UPD_RELEASE';
export const UPD_RELEASE_SUCCESS = 'UPD_RELEASE_SUCCESS';
export const UPD_RELEASE_FAILED = 'UPD_RELEASE_FAILED';
export const DEL_RELEASE = 'DEL_RELEASE';
export const DEL_RELEASE_SUCCESS = 'DEL_RELEASE_SUCCESS';
export const DEL_RELEASE_FAILED = 'DEL_RELEASE_FAILED';
export const REFRESH_PRESIGNED_URL = 'REFRESH_PRESIGNED_URL';
export const REFRESH_PRESIGNED_URL_SUCCESS = 'REFRESH_PRESIGNED_URL_SUCCESS';
export const REFRESH_PRESIGNED_URL_FAILED = 'REFRESH_PRESIGNED_URL_FAILED';

// EmailJSON
export const CLEAR_EMAIL_JSON_INSIGHTS = 'CLEAR_EMAIL_JSON_INSIGHTS';
export const GET_EMAIL_PREVIEW = 'GET_EMAIL_PREVIEW';
export const SET_EMAIL_PREVIEW = 'SET_EMAIL_PREVIEW';
export const SET_EMAIL_PREVIEW_FAILED = 'SET_EMAIL_PREVIEW_FAILED';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED';
export const EMAIL_SENT = 'EMAIL_SENT';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';

// Possible emails
export const GET_POSSIBLE_INSIGHTS_BY_EMAILS = 'GET_POSSIBLE_INSIGHTS_BY_EMAILS';
export const GET_POSSIBLE_INSIGHTS_BY_EMAILS_SUCCESS = 'GET_POSSIBLE_INSIGHTS_BY_EMAILS_SUCCESS';

// Annotation Verification Filters
export const GET_ALL_VERIFICATION_FILTERS = 'GET_ALL_VERIFICATION_FILTERS';
export const GET_ALL_VERIFICATION_FILTERS_SUCCESS = 'GET_ALL_VERIFICATION_FILTERS_SUCCESS';
export const GET_ALL_VERIFICATION_FILTERS_FAILED = 'GET_ALL_VERIFICATION_FILTERS_FAILED';
export const UPD_VERIFICATION_FILTER = 'SET_VERIFICATION_FILTER';
export const UPD_VERIFICATION_FILTER_SUCCESS = 'UPD_VERIFICATION_FILTER_SUCCESS';
export const UPD_VERIFICATION_FILTER_FAILED = 'UPD_VERIFICATION_FILTER_FAILED';
export const ADD_VERIFICATION_FILTER = 'ADD_VERIFICATION_FILTER';
export const ADD_VERIFICATION_FILTER_SUCCESS = 'ADD_VERIFICATION_FILTER_SUCCESS';
export const ADD_VERIFICATION_FILTER_FAILED = 'ADD_VERIFICATION_FILTER_FAILED';
export const DEL_VERIFICATION_FILTER = 'DEL_VERIFICATION_FILTER';
export const DEL_VERIFICATION_FILTER_SUCCESS = 'DEL_VERIFICATION_FILTER_SUCCESS';
export const DEL_VERIFICATION_FILTER_FAILED = 'DEL_VERIFICATION_FILTER_FAILED';

// Ab Tests
export const GET_AB_TESTS = 'GET_AB_TESTS';
export const GET_AB_TESTS_SUCCESS = 'GET_AB_TESTS_SUCCESS';
export const GET_AB_TESTS_FAILED = 'GET_AB_TESTS_FAILED';
export const UPD_AB_TEST = 'UPD_AB_TEST';
export const UPD_AB_TEST_SUCCESS = 'UPD_AB_TEST_SUCCESS';
export const UPD_AB_TEST_FAILED = 'UPD_AB_TEST_FAILED';
export const ADD_AB_TEST = 'ADD_AB_TEST';
export const ADD_AB_TEST_SUCCESS = 'ADD_AB_TEST_SUCCESS';
export const ADD_AB_TEST_FAILED = 'ADD_AB_TEST_FAILED';
export const DELETE_AB_TEST = 'DELETE_AB_TEST';
export const DELETE_AB_TEST_SUCCESS = 'DELETE_AB_TEST_SUCCESS';
export const DELETE_AB_TEST_FAILED = 'DELETE_AB_TEST_FAILED';

export const GET_AB_TEST_DATA = 'GET_AB_TEST_DATA';
export const GET_AB_TEST_DATA_SUCCESS = 'GET_AB_TEST_DATA_SUCCESS';
export const GET_AB_TEST_DATA_FAILED = 'GET_AB_TEST_DATA_FAILED';

// devices
export const API_REQUEST_DEVICES_FAILED = 'API_REQUEST_DEVICES_FAILED';
export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const UPD_DEVICE = 'UPD_DEVICE';
export const UPD_DEVICE_SUCCESS = 'UPD_DEVICE_SUCCESS';
export const ADD_DEVICE = 'ADD_DEVICE';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const DEL_DEVICE = 'DEL_DEVICE';
export const DEL_DEVICE_SUCCESS = 'DEL_DEVICE_SUCCESS';

// device models
export const API_REQUEST_DEVICE_MODELS_FAILED = 'API_REQUEST_DEVICE_MODELS_FAILED';
export const GET_DEVICE_MODELS = 'GET_DEVICE_MODELS';
export const GET_DEVICE_MODELS_SUCCESS = 'GET_DEVICE_MODELS_SUCCESS';
export const UPD_DEVICE_MODEL = 'UPD_DEVICE_MODEL';
export const UPD_DEVICE_MODEL_SUCCESS = 'UPD_DEVICE_MODEL_SUCCESS';
export const ADD_DEVICE_MODEL = 'ADD_DEVICE_MODEL';
export const ADD_DEVICE_MODEL_SUCCESS = 'ADD_DEVICE_MODEL_SUCCESS';
export const DEL_DEVICE_MODEL = 'DEL_DEVICE_MODEL';
export const DEL_DEVICE_MODEL_SUCCESS = 'DEL_DEVICE_MODEL_SUCCESS';

// personas
export const API_REQUEST_PERSONAS_FAILED = 'API_REQUEST_PERSONAS_FAILED';
export const GET_PERSONAS = 'GET_PERSONAS';
export const GET_PERSONAS_SUCCESS = 'GET_PERSONAS_SUCCESS';
export const ADD_PERSONA = 'ADD_PERSONA';
export const ADD_PERSONA_SUCCESS = 'ADD_PERSONA_SUCCESS';
export const UPD_PERSONA = 'UPD_PERSONA';
export const UPD_PERSONA_SUCCESS = 'UPD_PERSONA_SUCCESS';
export const ADD_PERSONA_ORGANIZATION = 'ADD_PERSONA_ORGANIZATION';
export const ADD_PERSONA_ORGANIZATION_SUCCESS = 'ADD_PERSONA_ORGANIZATION_SUCCESS';
export const DEL_PERSONA_ORGANIZATION = 'DEL_PERSONA_ORGANIZATION';
export const DEL_PERSONA_ORGANIZATION_SUCCESS = 'DEL_PERSONA_ORGANIZATION_SUCCESS';
export const ADD_PRODUCT_SETTING = 'ADD_PRODUCT_SETTING';
export const ADD_PRODUCT_SETTING_SUCCESS = 'ADD_PRODUCT_SETTING_SUCCESS';
export const DEL_PRODUCT_SETTING = 'DEL_PRODUCT_SETTING';
export const DEL_PRODUCT_SETTING_SUCCESS = 'DEL_PRODUCT_SETTING_SUCCESS';
export const ADD_PERSONA_PRODUCT = 'ADD_PERSONA_PRODUCT';
export const ADD_PERSONA_PRODUCT_SUCCESS = 'ADD_PERSONA_PRODUCT_SUCCESS';
export const ADD_PERSONA_PRODUCT_FAILURE = 'ADD_PERSONA_PRODUCT_FAILURE';
export const UPD_PERSONA_PRODUCT = 'UPD_PERSONA_PRODUCT';
export const UPD_PERSONA_PRODUCT_SUCCESS = 'UPD_PERSONA_PRODUCT_SUCCESS';
export const UPD_PERSONA_PRODUCT_FAILURE = 'UPD_PERSONA_PRODUCT_FAILURE';
export const DEL_PERSONA_PRODUCT = 'DEL_PERSONA_PRODUCT';
export const DEL_PERSONA_PRODUCT_SUCCESS = 'DEL_PERSONA_PRODUCT_SUCCESS';
export const DEL_PERSONA_PRODUCT_FAILURE = 'DEL_PERSONA_PRODUCT_FAILURE';

// settings
export const API_REQUEST_SETTINGS_FAILED = 'API_REQUEST_SETTINGS_FAILED';
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const ADD_SETTING = 'ADD_SETTING';
export const ADD_SETTING_SUCCESS = 'ADD_SETTING_SUCCESS';
export const UPD_SETTING = 'UPD_SETTING';
export const UPD_SETTING_SUCCESS = 'UPD_SETTING_SUCCESS';
export const DEL_SETTING = 'DEL_SETTING';
export const DEL_SETTING_SUCCESS = 'DEL_SETTING_SUCCESS';

// payment detail
export const UPDATE_PAYMENT_DETAIL = 'UPDATE_PAYMENT_DETAIL';
export const UPDATE_PAYMENT_DETAIL_SUCCESS = 'UPDATE_PAYMENT_DETAIL_SUCCESS';
export const DEL_PAYMENT_DETAIL = 'DEL_PAYMENT_DETAIL';
export const DEL_PAYMENT_DETAIL_SUCCESS = 'DEL_PAYMENT_DETAIL_SUCCESS';

// Tiktok api
export const GET_TIKTOK_EFFECTS = 'GET_TIKTOK_EFFECTS';
export const GET_TIKTOK_EFFECTS_SUCCESS = 'GET_TIKTOK_EFFECTS_SUCCESS';
export const GET_TIKTOK_CHALLENGES = 'GET_TIKTOK_CHALLENGES';
export const GET_TIKTOK_CHALLENGES_SUCCESS = 'GET_TIKTOK_CHALLENGES_SUCCESS';
// notifications
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const ADD_NOTIFICATION_SUCCESS = 'ADD_NOTIFICATION_SUCCESS';
export const ADD_NOTIFICATION_FAILED = 'ADD_NOTIFICATION_FAILED';
export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'GET_ALL_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const LINK_NOTIFICATION_TO_USER = 'LINK_NOTIFICATION_TO_USER';
export const LINK_NOTIFICATION_TO_USER_SUCCESS = 'LINK_NOTIFICATION_TO_USER_SUCCESS';
export const LINK_NOTIFICATION_TO_USER_FAILED = 'LINK_NOTIFICATION_TO_USER_FAILED';

// reports
export const API_REQUEST_REPORTS_FAILED = 'API_REQUEST_REPORTS_FAILED';
export const GET_TAGS = 'GET_TAGS';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';
export const GET_REPORTS = 'GET_REPORTS';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const ADD_REPORT = 'ADD_REPORT';
export const ADD_REPORT_SUCCESS = 'ADD_REPORT_SUCCESS';
export const UPD_REPORT = 'UPD_REPORT';
export const UPD_REPORT_SUCCESS = 'UPD_REPORT_SUCCESS';
export const DEL_REPORT = 'DEL_REPORT';
export const DEL_REPORT_SUCCESS = 'DEL_REPORT_SUCCESS';
export const ADD_REPORT_TAG = 'ADD_REPORT_TAG';
export const ADD_REPORT_TAG_SUCCESS = 'ADD_REPORT_TAG_SUCCESS';
export const DEL_REPORT_TAG = 'DEL_REPORT_TAG';
export const DEL_REPORT_TAG_SUCCESS = 'DEL_REPORT_TAG_SUCCESS';
export const ADD_ORG_REPORT = 'ADD_ORG_REPORT';
export const ADD_ORG_REPORT_SUCCESS = 'ADD_ORG_REPORT_SUCCESS';
export const DEL_ORG_REPORT = 'DEL_ORG_REPORT';
export const DEL_ORG_REPORT_SUCCESS = 'DEL_ORG_REPORT_SUCCESS';

// abandoned insights
export const API_REQUEST_ABANDONED_INSIGHTS_FAILED = 'API_REQUEST_ABANDONED_INSIGHTS_FAILED';
export const GET_ABANDONED_INSIGHTS = 'GET_ABANDONED_INSIGHTS';
export const GET_ABANDONED_INSIGHTS_SUCCESS = 'GET_ABANDONED_INSIGHTS_SUCCESS';
export const GET_ABANDONED_INSIGHTS_COUNT = 'GET_ABANDONED_INSIGHTS_COUNT';
export const GET_ABANDONED_INSIGHTS__COUNT_SUCCESS = 'GET_ABANDONED_INSIGHTS__COUNT_SUCCESS';

// subscriptions
export const GET_USER_SUBSCRIPTIONS = 'GET_USER_SUBSCRIPTIONS';
export const GET_USER_SUBSCRIPTIONS_SUCCESS = 'GET_USER_SUBSCRIPTIONS_SUCCESS';
export const GET_USER_SUBSCRIPTIONS_FAILED = 'GET_USER_SUBSCRIPTIONS_FAILED';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';

// products per user
export const GET_PRODUCTS_FOR_USER = 'GET_PRODUCTS_FOR_USER';
export const GET_PRODUCTS_FOR_USER_SUCCESS = 'GET_PRODUCTS_FOR_USER_SUCCESS';
export const GET_PRODUCTS_FOR_USER_FAILED = 'GET_PRODUCTS_FOR_USER_FAILED';

// user details
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';
export const UPDATE_EMAIL_PREFERENCES = 'UPDATE_EMAIL_PREFERENCES';
export const UPDATE_EMAIL_PREFERENCES_SUCCESS = 'UPDATE_EMAIL_PREFERENCES_SUCCESS';

// App Graph
export const GET_ALL_APPS_WITH_GRAPHS = 'GET_ALL_APPS_WITH_GRAPHS';
export const GET_ALL_APPS_WITH_GRAPHS_SUCCESS = 'GET_ALL_APPS_WITH_GRAPHS_SUCCESS';
export const GET_ALL_APPS_WITH_GRAPHS_FAILURE = 'GET_ALL_APPS_WITH_GRAPHS_FAILURE';

export const GET_APPS_WITH_SCREEN_CHANGES = 'GET_APPS_WITH_SCREEN_CHANGES';
export const GET_APPS_WITH_SCREEN_CHANGES_SUCCESS = 'GET_APPS_WITH_SCREEN_CHANGES_SUCCESS';
export const GET_APPS_WITH_SCREEN_CHANGES_FAILED = 'GET_APPS_WITH_SCREEN_CHANGES_FAILED';

export const GET_SCREEN_CHANGES_FOR_APP = 'GET_SCREEN_CHANGES_FOR_APP';
export const GET_SCREEN_CHANGES_FOR_APP_SUCCESS = 'GET_SCREEN_CHANGES_FOR_APP_SUCCESS';
export const GET_SCREEN_CHANGES_FOR_APP_FAILED = 'GET_SCREEN_CHANGES_FOR_APP_FAILED';

export const GET_VERSION_GRAPH_DATA_FOR_APP = 'GET_VERSION_GRAPH_DATA_FOR_APP';
export const GET_VERSION_GRAPH_DATA_FOR_APP_SUCCESS = 'GET_VERSION_GRAPH_DATA_FOR_APP_SUCCESS';
export const GET_VERSION_GRAPH_DATA_FOR_APP_FAILED = 'GET_VERSION_GRAPH_DATA_FOR_APP_FAILED';

export const GET_GRAPH_DATA_FOR_APP = 'GET_GRAPH_DATA_FOR_APP';
export const GET_GRAPH_DATA_FOR_APP_SUCCESS = 'GET_GRAPH_DATA_FOR_APP_SUCCESS';
export const GET_GRAPH_DATA_FOR_APP_FAILED = 'GET_GRAPH_DATA_FOR_APP_FAILED';

export const CREATE_SCREEN_CHANGE_INSIGHT = 'CREATE_SCREEN_CHANGE_INSIGHT';
export const CREATE_SCREEN_CHANGE_INSIGHT_SUCCESS = 'CREATE_SCREEN_CHANGE_INSIGHT_SUCCESS';

export const CREATE_COMPLAINT_FOR_GRAPH = 'CREATE_COMPLAIN_FOR_GRAPH';
export const CREATE_COMPLAINT_FOR_GRAPH_SUCCESS = 'CREATE_COMPLAIN_FOR_GRAPH_SUCCESS';
export const DELETE_COMPLAINT_FOR_GRAPH = 'DELETE_COMPLAIN_FOR_GRAPH';
export const DELETE_COMPLAINT_FOR_GRAPH_SUCCESS = 'DELETE_COMPLAIN_FOR_GRAPH_SUCCESS';

export const GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID = 'GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID';
export const GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID_SUCCESS = 'GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID_SUCCESS';

// Insights list
export const GET_INSIGHTS_LIST = 'GET_INSIGHTS_LIST';
export const GET_INSIGHTS_LIST_SUCCESS = 'GET_INSIGHTS_LIST_SUCCESS';

// current user details
export const GET_CUR_USER_DETAILS = 'GET_CUR_USER_DETAILS';
export const GET_CUR_USER_DETAILS_SUCCESS = 'GET_CUR_USER_DETAILS_SUCCESS';
