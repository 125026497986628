import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import NotificationsModal from './forms/NotificationsModal';

import NotificationsTable from './Table';

import {
  getAllNotifications,
  addNotification,
  deleteNotification,
  updateNotification,
} from '../../redux/actions/notifications';

const Notifications = ({
  // eslint-disable-next-line no-shadow,no-unused-vars
  notifications,
  updateNotification,
  addNotification,
  deleteNotification,
  getAllNotifications,
}) => {
  const [showNotificationsAddModal, setShowNotificationsAddModal] = useState(false);
  const [showNotificationsEditModal, setShowNotificationsEditModal] = useState(false);
  const [currentNotificationsModalEdit, setCurrentNotificationsModalEdit] = useState(null);
  useEffect(() => {
    getAllNotifications();
  }, []);

  const handleAddNotificationSubmit = (notification) => {
    // submit to the backend
    console.log('create notification:', notification);
    setShowNotificationsAddModal(false);
    addNotification(notification);
  };

  const handleDeleteNotification = (notification) => {
    deleteNotification(notification);
  };
  const currentNotification = notifications.filter((x) => x.id === currentNotificationsModalEdit);

  return (
    <div>
      <NotificationsModal
        visible={showNotificationsAddModal}
        handleHide={() => setShowNotificationsAddModal(false)}
        handleSubmit={handleAddNotificationSubmit}
      />

      {showNotificationsEditModal && (
        <NotificationsModal
          visible={showNotificationsEditModal}
          handleHide={() => setShowNotificationsEditModal(false)}
          handleSubmit={(notification) => updateNotification(notification)}
          notification={currentNotification[0]}
        />
      )}

      <div style={{ display: 'table' }}>
        <Button
          // disabled={props.productData.length === 0}
          type="primary"
          style={{ margin: '16px 0 16px 5px' }}
          // loading={isApiRequestLoading}
          onClick={() => {
            setShowNotificationsAddModal(true);
          }}
        >
          Add New Notification
        </Button>
        <NotificationsTable
          notificationsData={notifications}
          setNotificationID={setCurrentNotificationsModalEdit}
          setShowNotificationsEditModal={setShowNotificationsEditModal}
          deleteNotification={handleDeleteNotification}
        />
      </div>
    </div>
  );
};

const getNotifications = (state) => state.notifications.notificationsData;

const notificationsSelector = createSelector([getNotifications], (notifications) =>
  notifications.sort((noteA, noteB) => noteB.id - noteA.id)
);
// eslint-disable-next-line no-shadow
const mapStateToProps = (state) => ({
  notifications: notificationsSelector(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllNotifications,
      addNotification,
      deleteNotification,
      updateNotification,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
