import { message } from 'antd';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getPersonasByApp } from '../../../api/insightsAPI';
import {
  createUserFlow,
  createUserFlowSet,
  createUserFlowSetAssets,
  deleteUserFlow,
  deleteUserFlowSet,
  deleteUserFlowSetAsset,
  getUserFlowSets,
  getUserFlows,
  updateUserFlow,
  updateUserFlowSet,
  updateUserFlowSetAssetIndex,
  updateUserFlowSetAssets,
} from '../../../api/userJourneysAPI';

const strInUserFlowSet = ({ persona, release, user_flow }, str) => {
  return (
    persona.title.toLowerCase().includes(str.toLowerCase()) ||
    release.release_name.toLowerCase().includes(str.toLowerCase()) ||
    user_flow.name.toLowerCase().includes(str.toLowerCase())
  );
};

const convertArrayToTree = (userFlows, userFlowSets, isVisible, search) => {
  const map = {};
  const tree = [];

  userFlows.forEach((node) => {
    map[node.id] = {
      ...node,
      children: [],
      sets: userFlowSets.filter(
        (userFlowSet) =>
          userFlowSet.user_flow_id === node.id &&
          userFlowSet.is_visible === isVisible &&
          strInUserFlowSet(userFlowSet, search)
      ),
    };
  });

  userFlows.forEach((node) => {
    if (node.parent_id !== null) {
      map[node.parent_id].children.push(map[node.id]);
    } else {
      tree.push(map[node.id]);
    }
  });

  return tree;
};

export const useUserFlows = (appId) => {
  const queryClient = useQueryClient();
  const { data: userFlows } = useQuery(['GetUserFlows'], getUserFlows);
  const { data: userFlowSets } = useQuery(['GetUserFlowSets', appId], () => getUserFlowSets(appId), {
    enabled: !!appId,
  });
  const { data: personas } = useQuery(['GetPersonas', appId], () => getPersonasByApp(appId), {
    enabled: !!appId,
  });

  const [userFlowsSearch, setUserFlowsSearch] = useState('');

  const userFlowTreesDraft = useMemo(
    () =>
      userFlows && userFlowSets ? convertArrayToTree(userFlows.data, userFlowSets.data, false, userFlowsSearch) : [],
    [userFlows, userFlowSets, userFlowsSearch]
  );

  const userFlowTreesPublished = useMemo(
    () =>
      userFlows && userFlowSets ? convertArrayToTree(userFlows.data, userFlowSets.data, true, userFlowsSearch) : [],
    [userFlows, userFlowSets, userFlowsSearch]
  );

  const createUserFlowMutation = useMutation(createUserFlow, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('User Flow Created');
    },
  });
  const createUserFlowSetMutation = useMutation(createUserFlowSet, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Set Created');
    },
  });
  const createUserFlowSetAssetsMutation = useMutation(createUserFlowSetAssets, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Asset Created');
    },
  });

  const updateUserFlowMutation = useMutation(updateUserFlow, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows', 'GetUserFlowSets']);
    },
  });
  const updateUserFlowSetMutation = useMutation(updateUserFlowSet, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('User Flow Set Updated');
    },
  });
  const updateUserFlowSetAssetsMutation = useMutation(updateUserFlowSetAssets, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Asset Updated');
    },
  });
  const updateUserFlowSetAssetIndexMutation = useMutation(updateUserFlowSetAssetIndex, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Order Updated');
    },
  });

  const deleteUserFlowMutation = useMutation(deleteUserFlow, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('User Flow Deleted');
    },
  });
  const deleteUserFlowSetMutation = useMutation(deleteUserFlowSet, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Set Deleted Successfully');
    },
  });
  const deleteUserFlowSetAssetsMutation = useMutation(deleteUserFlowSetAsset, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.success('Asset Deleted Successfully');
    },
  });

  const handleCreateUserFlow = ({ name, parent_id }) => {
    createUserFlowMutation.mutate({ name, parent_id });
  };

  const handleCreateUserFlowSet = async ({ user_flow_id, persona_id, release_id }) => {
    return createUserFlowSetMutation.mutateAsync({
      user_flow_id,
      persona_id,
      release_id,
      is_visible: false,
    });
  };

  const handleCreateUserSetFlowAssets = ({ set_id, assets }) => {
    createUserFlowSetAssetsMutation.mutate({ set_id, assets });
  };

  const handleDeleteUserFlowSet = (set_id) => {
    deleteUserFlowSetMutation.mutate({ set_id });
  };

  const handleDeletUserFlowSetAsset = (asset_id) => {
    deleteUserFlowSetAssetsMutation.mutate(asset_id);
  };

  const handleUpdateUserFlowSetAsset = ({ asset_id, rects, is_hidden, text, orientation }) => {
    updateUserFlowSetAssetsMutation.mutate({ asset_id, rects, is_hidden, text, orientation });
  };

  const handleUpdateUserFlowSet = ({ set_id, is_visible, user_flow_id, persona_id, release_id }) => {
    updateUserFlowSetMutation.mutate({
      set_id,
      persona_id,
      user_flow_id,
      release_id,
      is_visible,
    });
  };

  const handleUpdateUserFlowSetAssetIndex = (assets) => {
    updateUserFlowSetAssetIndexMutation.mutate(assets);
  };

  const handleUserFlowSearch = (search) => {
    setUserFlowsSearch(search);
  };

  return {
    userFlows: userFlows ? userFlows.data : [],
    userFlowSets: userFlowSets ? userFlowSets.data : [],
    personas: personas ? personas.data : [],
    userFlowTreesDraft,
    userFlowTreesPublished,

    handleUserFlowSearch,

    handleCreateUserFlow,
    handleCreateUserFlowSet,
    isCreateUserFlowSetLoading: createUserFlowSetMutation.isLoading,
    handleCreateUserSetFlowAssets,

    updateUserFlowMutation,
    handleUpdateUserFlowSet,
    handleUpdateUserFlowSetAsset,
    handleUpdateUserFlowSetAssetIndex,

    deleteUserFlowMutation,
    handleDeleteUserFlowSet,
    handleDeletUserFlowSetAsset,
  };
};
