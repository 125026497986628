import { getAllDevicesAPI, addDeviceAPI, updDeviceAPI, delDeviceAPI } from '../actions/devices';
import {
  getAllDeviceModelsAPI,
  addDeviceModelAPI,
  updDeviceModelAPI,
  delDeviceModelAPI,
} from '../actions/deviceModels';
import * as AT from '../actionTypes';

const devicesMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_DEVICES: {
        dispatch(getAllDevicesAPI());
        return next(action);
      }
      case AT.ADD_DEVICE: {
        dispatch(addDeviceAPI(action.payload.data));
        return next(action);
      }
      case AT.UPD_DEVICE: {
        dispatch(updDeviceAPI(action.payload.deviceId, action.payload.data));
        return next(action);
      }
      case AT.DEL_DEVICE: {
        dispatch(delDeviceAPI(action.payload.deviceId));
        return next(action);
      }
      case AT.GET_DEVICE_MODELS: {
        dispatch(getAllDeviceModelsAPI());
        return next(action);
      }
      case AT.ADD_DEVICE_MODEL: {
        dispatch(addDeviceModelAPI(action.payload.data));
        return next(action);
      }
      case AT.UPD_DEVICE_MODEL: {
        dispatch(updDeviceModelAPI(action.payload.deviceModelId, action.payload.data));
        return next(action);
      }
      case AT.DEL_DEVICE_MODEL: {
        dispatch(delDeviceModelAPI(action.payload.deviceModelId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default devicesMiddleware;
