import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Tabs } from 'antd';
import DevicesTab from './devicesTab/Tab';
import DeviceModelsTab from './deviceModelsTab/Tab';
import OrchestratorTab from './orchestratorsTab/Tab';
import { getAllDevices, addDevice, updDevice, delDevice } from '../../redux/actions/devices';
import { getAllDeviceModels, addDeviceModel, updDeviceModel, delDeviceModel } from '../../redux/actions/deviceModels';
import { getOrchestratorsList } from '../../redux/actions/performancePage';
import { addNewOrchestrator, updOrchestrator, delOrchestrator } from '../../redux/actions/orchestrators';

const { TabPane } = Tabs;

function Devices({
  isLoading,
  error,
  devices,
  deviceModels,
  getAllDevices,
  addDevice,
  updDevice,
  delDevice,
  getAllDeviceModels,
  addDeviceModel,
  updDeviceModel,
  delDeviceModel,
  orchestrators,
  getOrchestratorsList,
  orchestratorsLoading,
  addNewOrchestrator,
  updOrchestrator,
  delOrchestrator,
}) {
  useEffect(() => {
    getAllDevices();
    getAllDeviceModels();
    getOrchestratorsList();
  }, []);

  return (
    <Tabs type="card">
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      <TabPane tab="Devices" key="1">
        <DevicesTab
          isLoading={isLoading}
          devices={devices}
          deviceModels={deviceModels}
          addDevice={addDevice}
          updDevice={updDevice}
          delDevice={delDevice}
          orchestrators={orchestrators}
        />
      </TabPane>
      <TabPane tab="Device Models" key="2">
        <DeviceModelsTab
          isLoading={isLoading}
          deviceModels={deviceModels}
          addDeviceModel={addDeviceModel}
          updDeviceModel={updDeviceModel}
          delDeviceModel={delDeviceModel}
        />
      </TabPane>
      <TabPane tab="Orchestrators" key="3">
        <OrchestratorTab
          isLoading={orchestratorsLoading}
          orchestrators={orchestrators}
          addOrchestrator={addNewOrchestrator}
          updOrchestrator={updOrchestrator}
          onDelOrchestrator={delOrchestrator}
        />
      </TabPane>
    </Tabs>
  );
}

const mapDispatchToProps = {
  getAllDevices,
  addDevice,
  updDevice,
  delDevice,
  getAllDeviceModels,
  addDeviceModel,
  updDeviceModel,
  delDeviceModel,
  getOrchestratorsList,
  addNewOrchestrator,
  updOrchestrator,
  delOrchestrator,
};

const mapStateToProps = ({ devicesPage, orchestrators }) => ({
  devices: devicesPage.devices,
  deviceModels: devicesPage.deviceModels,
  isLoading: devicesPage.isLoading,
  error: devicesPage.error,
  orchestrators: orchestrators.orchestrators,
  orchestratorsLoading: orchestrators.isLoading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Devices);
