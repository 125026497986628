/* eslint-disable react/prop-types */
import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

export default function AppsSelectCell({
  defaultValue,
  options,
  columnName,
  appID,
  handleSelectChange,
  className,
  currentValue,
}) {
  return (
    <Select
      className={className}
      defaultValue={defaultValue}
      value={currentValue || defaultValue}
      style={{ width: '100%' }}
      onChange={handleSelectChange}
    >
      {options.map((option) => (
        <Option
          defaultValue={defaultValue}
          columnname={columnName}
          appid={appID}
          key={`${appID}_${columnName}_select`}
          value={option.value}
        >
          {option.text}
        </Option>
      ))}
    </Select>
  );
}
