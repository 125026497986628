import React, { useState } from 'react';
import { Table, Spin, Button, Input, Icon, Popconfirm } from 'antd';
import Img from 'react-image';

import moment from 'moment';

const PossibleInsightsByEmailsTable = (props) => {
  const getPossibleEmailTableColumns = () => [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, posEmail) => (
        <div>
          <a
            href={`#/insights/${posEmail.product_name}/${posEmail.app_package_name}/${posEmail.platform}/${posEmail.insight_id}`}
            target="_blank"
          >
            {text}
          </a>
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text, posEmail) => <div>{moment(posEmail.created_at).format('ll')}</div>,
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      width: '230px',
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      width: '280px',
      render: (text, posEmails) => (
        <div>
          {posEmails.to.map((email) => (
            <li>{email}</li>
          ))}
        </div>
      ),
    },
    {
      title: 'HTML Preview',
      dataIndex: 'html_preview',
      key: 'html',
      width: '700px',
      render: (html, posEmails) => (
        <div className="iframe-wrap">
          <iframe height="450" width="670" src={posEmails.html} title="HTML preview" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="main-table-possible-insights-by-emails"
        columns={getPossibleEmailTableColumns()}
        dataSource={props.emails}
        scroll={{ y: 660 }}
        pagination={{ pageSize: 50 }}
      />
    </div>
  );
};
export default PossibleInsightsByEmailsTable;
