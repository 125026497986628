import { Tabs } from 'antd';
import React from 'react';
import { useMutation } from 'react-query';
import { updateRpConfig } from '../../../../../api/workbenchAPI';
import './RPPanel.scss';
import { RPTab } from './rpTab';

export const RPPanel = ({ leftDevice, rightDevice, leftDeviceAppVer, rightDeviceAppVer, rp, setRp, ...rest }) => {
  const leftDeviceKey = leftDevice.device_name || leftDevice.serial;
  const rightDeviceKey = rightDevice.device_name || rightDevice.serial;
  const updateRpConfigMutation = useMutation(['UpdateRpConfig'], ({ serial, body }) => updateRpConfig(serial, body));

  const resetRp = (serial) => () => {
    setRp((prev) => ({
      ...prev,
      [serial]: null,
    }));
  };

  const updateTestValue = async ({ serial, tracking_type_str, name, newValue }) => {
    const curDeviceAppVer = serial === leftDevice.serial ? leftDeviceAppVer : rightDeviceAppVer;

    await updateRpConfigMutation.mutateAsync({
      serial,
      body: {
        package: curDeviceAppVer.package,
        version_name: curDeviceAppVer.version_name,
        version_code: curDeviceAppVer.version_code,
        experiment_name: name,
        new_value: newValue,
      },
    });

    setRp((prev) => ({
      ...prev,
      [serial]: {
        ...prev[serial],
        experiments: prev[serial].experiments.map((test) => {
          if (test.name === name && test.tracking_type_str === tracking_type_str) {
            return {
              ...test,
              value: newValue,
            };
          }

          return test;
        }),
      },
    }));
  };

  return (
    <Tabs defaultActiveKey={leftDeviceKey} className="rp-panel" type={'card'}>
      <Tabs.TabPane tab={leftDeviceKey} key={leftDeviceKey}>
        <RPTab
          serial={leftDevice.serial}
          rp={rp ? rp[leftDevice.serial] : null}
          resetRp={resetRp(leftDevice.serial)}
          updateTestValue={updateTestValue}
          {...rest}
        />
      </Tabs.TabPane>
      {leftDeviceKey !== rightDeviceKey && (
        <Tabs.TabPane tab={rightDeviceKey} key={rightDeviceKey}>
          <RPTab
            serial={rightDevice.serial}
            rp={rp ? rp[rightDevice.serial] : null}
            resetRp={resetRp(rightDevice.serial)}
            updateTestValue={updateTestValue}
            {...rest}
          />
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};
