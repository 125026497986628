import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';
import { stringify } from 'query-string';
import { apiRequestPersonasFailed } from './personas';

export const login = (user) => ({
  type: AT.LOGIN,
  payload: { user },
});

export const loginManager = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'auth/login',
    method: 'POST',
    label: NETWORK_LABELS.LOGIN,
    onSuccess: (body, dispatch) => dispatch(loginSuccess()),
    onFailure: (error, dispatch) => dispatch(loginFailed(error.message)),
  },
});

export const loginSuccess = () => ({
  type: AT.LOGIN_SUCCESS,
  payload: { message: 'success' },
});

export const loginFailed = (message) => ({
  type: AT.LOGIN_FAILED,
  payload: { message },
});

// GET_CUR_USER_DETAILS
export const getCurUserDetails = () => ({
  type: AT.GET_CUR_USER_DETAILS,
});

export const getCurUserDetailsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'user/preferences/details',
    label: NETWORK_LABELS.GET_CUR_USER_DETAILS,
    onSuccess: (body, dispatch) => dispatch(getAllPersonasSuccess(body)),
    onFailure: (error, dispatch) => dispatch(console.error),
  },
});

export const getAllPersonasSuccess = (body) => ({
  type: AT.GET_CUR_USER_DETAILS_SUCCESS,
  payload: { ...body },
});
