import React, { useState, useEffect } from 'react';
import { Button, Tabs, Icon, Radio, Input, InputNumber, Spin, Alert } from 'antd';
import { XMLParser } from 'fast-xml-parser';
import XMLViewer from 'react-xml-viewer';
import { parseInt } from 'lodash/string';
import { swipe_data2swipe, automationStateEnum } from './helpers';

function attributeValueProcessor(name, val, jPath) {}

const getSmartXpath = (attrs) => {
  const listSelectors = ['class', 'text', 'resource-id', 'label', 'identifier'];
  console.log(attrs);
  return `//node[${Object.entries(attrs)
    .filter(([k, v]) => listSelectors.includes(k) && v !== '""' && v !== null && v !== false)
    .map(([k, v]) => `@${k}=${v}`)
    .join(' and ')}]`;
};

const tabsStyle = [
  {
    label: 'Forward',
    icon: <Icon type="arrow-right" />,
  },
  {
    label: 'Back',
    icon: <Icon type="arrow-left" />,
  },
];

const EdgeEditing = ({
  lastScreenData,

  isEdgeChosen,
  graphData,
  updateChosenEdge,
  chosenEdge,
  handleUpdateEdge,
  handleGetScreenData,
  farmerDevice,
  playActionOnFarmer,
  startEdgeID,
  startEdgeIDChange,
  automationStateChange,

  EdgeDirActiveTab,
  EdgeDirActiveTabChange,
  screenDataWaiting,
  screenDataWaitingChange,
  userMistakeWarning,
  userMistakeWarningChange,
}) => {
  const [xml, xmlChange] = useState(null);
  const [editEdgeMode, editEdgeModeChange] = useState(null);
  let downEvXY, downEvXYTs;

  const canvasMouseMove = (e) => {
    if (downEvXY && ['swipe', 'swipe_until'].includes(chosenEdge[Number(EdgeDirActiveTab)].action_type)) {
      drawLine(downEvXY.x, downEvXY.y, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    }
  };

  const canvasMouseDown = (ev) => {
    downEvXY = {
      x: ev.nativeEvent.offsetX,
      y: ev.nativeEvent.offsetY,
    };
    console.log('MouseDown', ev);
    downEvXYTs = new Date();
  };

  const canvasMouseUp = (e) => {
    const canvas = document.getElementById('swipe-canvas');
    const multX = farmerDevice.screen_res[0] / canvas.width,
      multY = farmerDevice.screen_res[1] / canvas.height;
    if (chosenEdge[Number(EdgeDirActiveTab)].action_type === 'xpath') {
      if (xml) {
        const p = new XMLParser({
          ignoreAttributes: false,
          allowBooleanAttributes: true,
          alwaysCreateTextNode: true,
          attributeValueProcessor,
        });
        let data = p.parse(xml);
        if (data.hierarchy) {
          data = data.hierarchy;
        } else if (data.node) {
          data = data.node;
        }
        const parseBounds = (b) => {
          const bp = b
            .split(']')
            .map((b) => b.replace('[', ''))
            .slice(0, 2)
            .map((p) => p.split(',').map((n) => parseInt(n)));
          const [[x2, y2], [x1, y1]] = bp;
          const rt = {
            x1,
            y1,
            x2,
            y2,
          };

          return rt;
        };
        let selItem = { sizePercent: 1 };
        const PARKEY = '/h';
        const countChildren = (d, screenSize, selectedCoords, parKey = PARKEY, depth = 0) => {
          d.key = parKey;
          if (d['@_bounds']) {
            const b = parseBounds(d['@_bounds']);
            const sz = [b.x1 - b.x2, b.y1 - b.y2];
            const elSize = sz[0] * sz[1];
            d.size = elSize;
            d.sizePercent = elSize / screenSize;
            if (selectedCoords) {
              const doesfit = (b) => {
                const rt =
                  b.x1 >= selectedCoords[0] &&
                  b.x2 <= selectedCoords[0] &&
                  b.y1 >= selectedCoords[1] &&
                  b.y2 <= selectedCoords[1];
                return rt;
              };

              if (doesfit(b)) {
                if (!selItem.sizePercent || selItem.sizePercent >= d.sizePercent) {
                  selItem = d;
                }
              }
            }
          }

          // count total siblings
          if (!d.totalSiblings) d.totalSiblings = 0;
          if (Array.isArray(d.node)) {
            d.totalSiblings +=
              d.node.length +
              d.node
                .map((nd, idx) => countChildren(nd, screenSize, selectedCoords, `${parKey}[${idx + 1}]`, depth + 1))
                .reduce((pv, cv) => pv + cv.totalSiblings, 0);
          } else if (d.node) {
            d.totalSiblings += countChildren(
              d.node,
              screenSize,
              selectedCoords,
              `${parKey}[0]`,
              depth + 1
            ).totalSiblings;
          }

          return d;
        };

        countChildren(data, farmerDevice.screen_res[0] * farmerDevice.screen_res[1], [
          Math.round(downEvXY.x * multX),
          Math.round(downEvXY.y * multY),
        ]);
        const bounds = parseBounds(selItem['@_bounds']);
        drawLine(null, null, null, null, bounds, multX, multY);
        const selItemKeys = Object.keys(selItem);
        const newSelItem = {};
        selItemKeys.forEach((key) => {
          if (key.includes('@_')) {
            newSelItem[key.slice(2, key.length)] = `"${selItem[key]}"`;
          } else {
            newSelItem[key] = `"${selItem[key]}"`;
          }
        });
        console.log(newSelItem);
        updateChosenEdge(Number(EdgeDirActiveTab), 'xpath', getSmartXpath(newSelItem));
        const selItems = findElementByBounds(bounds);
        highlightElement(selItems);
      }
    } else {
      updateChosenEdge(Number(EdgeDirActiveTab), 'swipe_data', {
        x1: Math.round(downEvXY.x * multX),
        y1: Math.round(downEvXY.y * multY),
        x2: Math.round(e.nativeEvent.offsetX * multX),
        y2: Math.round(e.nativeEvent.offsetY * multY),
        duration: parseInt(new Date() - downEvXYTs),
      });

      downEvXY = null;
    }

    console.log('MouseUp');
  };

  const onLoadHandle = (e) => {
    const canvas = document.getElementById('swipe-canvas');
    const img = document.getElementById('swipe-img');
    if (!canvas || !img) return;
    const w = img.offsetWidth,
      h = img.offsetHeight;
    canvas.width = w;
    canvas.height = h;
  };

  const drawLine = (x1, y1, x2, y2, rectFromXml, multX, multY) => {
    const canvas = document.getElementById('swipe-canvas');
    const ctx = canvas.getContext('2d');
    window._ctx = ctx;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Draw swipe line
    if (x1 !== null) {
      ctx.beginPath();
      ctx.lineWidth = 15;
      ctx.lineCap = 'round';
      ctx.strokeStyle = '#8c8cff';
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
    }

    // Draw rect from XML
    if (rectFromXml) {
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.strokeStyle = 'rgb(54, 170, 54)';
      const w = rectFromXml.x2 / multX - rectFromXml.x1 / multX;
      const h = rectFromXml.y2 / multX - rectFromXml.y1 / multX;
      ctx.rect(rectFromXml.x1 / multX, rectFromXml.y1 / multY, w, h);
      ctx.stroke();
    }
  };

  const drawSwipe = (rectFromXml) => {
    const canvas = document.getElementById('swipe-canvas');
    const img = document.getElementById('swipe-img');
    if (!canvas || !img || !farmerDevice) return;

    const swipe = chosenEdge[EdgeDirActiveTab].swipe_data;
    let multX = farmerDevice.screen_res[0] / canvas.width,
      multY = farmerDevice.screen_res[1] / canvas.height;
    if (swipe) {
      drawLine(swipe.x1 / multX, swipe.y1 / multY, swipe.x2 / multX, swipe.y2 / multY, rectFromXml, multX, multY);
    } else {
      drawLine(null, null, null, null, rectFromXml, multX, multY);
    }
  };

  const playEdgeHandle = () => {
    const contextEdge = chosenEdge[parseInt(EdgeDirActiveTab)];
    playActionOnFarmer(contextEdge);
  };

  const forceGetActualData = () => {
    handleGetScreenData(true);
    xmlChange(null);
    screenDataWaitingChange(true);
  };

  useEffect(() => {
    if (isEdgeChosen) {
      // editEdgeModeChange(false)
      drawSwipe();
    }
  }, [isEdgeChosen]);

  useEffect(() => {
    console.log('DrawSwipeChange');
    if (chosenEdge && chosenEdge[Number(EdgeDirActiveTab)].swipe_data) {
      drawSwipe();
    }
  }, [EdgeDirActiveTab]);

  useEffect(() => {
    if (xml) {
      screenDataWaitingChange(false);
      setTimeout(() => {
        [].forEach.call(document.querySelectorAll('#screen-data-xml div'), (el) => {
          el.addEventListener('click', (e) => {
            const parsedAttrs = Array.from(el.childNodes[2].childNodes)
              .map((at) => at.innerText.trim().split('='))
              .reduce((acc, d) => {
                acc[d[0]] = d[1];
                return acc;
              }, {});
            console.log(parsedAttrs['resource-id']);
            [].forEach.call(document.querySelectorAll('.xml-node-selected'), (selNode) => {
              selNode.classList.remove('xml-node-selected');
            });
            const bounds = parsedAttrs.bounds.match(/\d+/g);
            drawSwipe({
              x1: Number(bounds[0]),
              y1: Number(bounds[1]),
              x2: Number(bounds[2]),
              y2: Number(bounds[3]),
            });
            console.log(parsedAttrs);
            console.log(getSmartXpath(parsedAttrs));
            updateChosenEdge(Number(EdgeDirActiveTab), 'xpath', getSmartXpath(parsedAttrs));

            el.classList.add('xml-node-selected');
            e.stopPropagation();
          });
        });
      }, 100);
    }
  }, [xml]);

  useEffect(() => {
    if (lastScreenData && lastScreenData.ui_xml_data_link) {
      fetch(lastScreenData.ui_xml_data_link, {
        headers: {
          'Content-Type': 'application/xml; charset=utf-8',
        },
      })
        .then((response) => response.text())
        .then((data) => xmlChange(data));
    }
  }, [lastScreenData]);

  useEffect(() => {
    editEdgeModeChange(true);
  }, []);

  const handleSaveEdge = (EdgeDirActiveTab) => {
    const edge_id = chosenEdge[parseInt(EdgeDirActiveTab)].id;
    let edge = {
      ...chosenEdge[parseInt(EdgeDirActiveTab)],
    };
    edge = { ...edge, ...swipe_data2swipe(edge.swipe_data) };
    handleUpdateEdge(edge_id, edge);
  };

  const findElementByBounds = (bounds) => {
    const query = `"[${bounds.x2},${bounds.y2}][${bounds.x1},${bounds.y1}]"`;
    const selItems = [...document.querySelectorAll('#screen-data-xml span')].filter((a) => a.textContent === query);
    return selItems[0];
  };

  const highlightElement = (element) => {
    [].forEach.call(document.querySelectorAll('.xml-node-selected'), (selNode) => {
      selNode.classList.remove('xml-node-selected');
    });
    const parentDiv = element.closest('div');

    parentDiv.classList.add('xml-node-selected');
    parentDiv.scrollIntoView();
  };

  const runFromCertainEdge = () => {
    startEdgeIDChange(chosenEdge[parseInt(EdgeDirActiveTab)].id);
    automationStateChange(automationStateEnum.Running);
  };

  if (!chosenEdge) {
    return null;
  }
  return (
    <div className="edge-edit-wrapper">
      <h2>
        {chosenEdge[parseInt(EdgeDirActiveTab)] && !chosenEdge[parseInt(EdgeDirActiveTab)].to
          ? 'New Edge'
          : 'Edit Edge'}
      </h2>
      <div className="edge-edit-content full">
        {chosenEdge && (
          <div>
            <div>
              <Tabs activeKey={EdgeDirActiveTab} onChange={EdgeDirActiveTabChange}>
                {chosenEdge.map((edge, i) => (
                  <Tabs.TabPane
                    tab={
                      <span>
                        {tabsStyle[i].label}
                        {tabsStyle[i].icon}
                      </span>
                    }
                    key={i}
                  >
                    <div>
                      <div className="edge-data flex">
                        <div>
                          <label>From</label>
                          <div>
                            {graphData.nodes.find((e) => e.id === edge.from)
                              ? graphData.nodes.find((e) => e.id === edge.from).label
                              : ''}
                          </div>
                        </div>
                        <div>
                          <label>To</label>
                          <div>
                            {graphData.nodes.find((e) => e.id === edge.to)
                              ? graphData.nodes.find((e) => e.id === edge.to).label
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="edge-data">
                        <label>Order</label>
                        <div>{edge.order_indexes}</div>
                      </div>
                      <div className="edge-data">
                        <label>Sleep after</label>
                        <div>
                          <Input
                            size="small"
                            value={edge.sleep_after ? edge.sleep_after : ''}
                            onChange={(e) => updateChosenEdge(i, 'sleep_after', e.target.value)}
                          />
                        </div>
                      </div>
                      <Radio.Group
                        value={edge.action_type}
                        onChange={(e) => updateChosenEdge(i, 'action_type', e.target.value)}
                      >
                        {farmerDevice && farmerDevice.typ !== 'ios' && (
                          <div className="edge-data">
                            <Radio value="back">
                              <label>Press Back</label>
                            </Radio>
                          </div>
                        )}
                        {farmerDevice && farmerDevice.typ !== 'ios' && (
                          <div className="edge-data">
                            <Radio value="press_return_key">
                              <label>Press Enter</label>
                            </Radio>
                          </div>
                        )}
                        <div className="edge-data textarea">
                          <Radio value="text">
                            <label>Text</label>
                            <div className="edge-action-type-val">
                              <Input.TextArea
                                value={edge.input_text}
                                onChange={(e) => updateChosenEdge(i, 'input_text', e.currentTarget.value)}
                              />
                            </div>
                          </Radio>
                        </div>

                        <div className="edge-data">
                          <Radio value="xpath">
                            <label>Xpath</label>
                            <div className="edge-action-type-val textarea">
                              <Input.TextArea
                                value={edge.xpath}
                                onChange={(e) => updateChosenEdge(i, 'xpath', e.currentTarget.value)}
                              />
                            </div>
                          </Radio>
                        </div>
                        <div className="edge-data">
                          <Radio value="swipe_until">
                            <label>Swipe until</label>
                          </Radio>
                        </div>
                        <div className="edge-data">
                          <Radio value="swipe">
                            <label>Swipe</label>
                            <div className=" edge-action-type-val swipe-data">
                              <div>
                                x1{' '}
                                <InputNumber
                                  size="small"
                                  value={edge.swipe_data ? edge.swipe_data.x1 : 0}
                                  onChange={(value) => updateChosenEdge(i, 'swipe_data_part', value, 'x1')}
                                />
                                y1{' '}
                                <InputNumber
                                  size="small"
                                  value={edge.swipe_data ? edge.swipe_data.y1 : 0}
                                  onChange={(value) => updateChosenEdge(i, 'swipe_data_part', value, 'y1')}
                                />
                              </div>
                              <div>
                                x2{' '}
                                <InputNumber
                                  size="small"
                                  value={edge.swipe_data ? edge.swipe_data.x2 : 0}
                                  onChange={(value) => updateChosenEdge(i, 'swipe_data_part', value, 'x2')}
                                />
                                y2{' '}
                                <InputNumber
                                  size="small"
                                  value={edge.swipe_data ? edge.swipe_data.y2 : 0}
                                  onChange={(value) => updateChosenEdge(i, 'swipe_data_part', value, 'y2')}
                                />
                              </div>
                              <div>
                                Duration{' '}
                                <InputNumber
                                  size="small"
                                  value={edge.swipe_data ? edge.swipe_data.duration : 0}
                                  onChange={(value) => updateChosenEdge(i, 'swipe_data_part', value, 'duration')}
                                />
                              </div>
                            </div>
                          </Radio>
                        </div>
                      </Radio.Group>
                    </div>
                    <div className="edge-management-btns">
                      <Button
                        onClick={() => handleSaveEdge(EdgeDirActiveTab)}
                        disabled={chosenEdge[parseInt(EdgeDirActiveTab)] && !chosenEdge[parseInt(EdgeDirActiveTab)].to}
                      >
                        Save edge
                      </Button>
                      <Button onClick={forceGetActualData}>Get Actual Screen</Button>
                      <Button onClick={playEdgeHandle}>Play</Button>
                      <br />
                      <Button
                        onClick={runFromCertainEdge}
                        disabled={chosenEdge[parseInt(EdgeDirActiveTab)] && !chosenEdge[parseInt(EdgeDirActiveTab)].to}
                      >
                        Run from this edge
                      </Button>
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
              {userMistakeWarning && (
                <div className="warnings">
                  <Alert
                    message={userMistakeWarning}
                    type="warning"
                    closable
                    onClose={() => userMistakeWarningChange(null)}
                  />
                </div>
              )}
            </div>
            <div className="screen-data-image">
              <canvas
                id="swipe-canvas"
                onMouseMove={canvasMouseMove}
                onMouseDown={canvasMouseDown}
                onMouseUp={canvasMouseUp}
              />
              {editEdgeMode && lastScreenData ? (
                <img
                  src={lastScreenData.screenshot_link}
                  key={lastScreenData.last_update}
                  id="swipe-img"
                  onLoad={onLoadHandle}
                />
              ) : chosenEdge[EdgeDirActiveTab] &&
                graphData.nodes.length > 0 &&
                graphData.nodes.find((n) => n.id === chosenEdge[EdgeDirActiveTab].from) &&
                graphData.nodes.find((n) => n.id === chosenEdge[EdgeDirActiveTab].from).screenshot_link &&
                chosenEdge[parseInt(EdgeDirActiveTab)] &&
                !chosenEdge[parseInt(EdgeDirActiveTab)].to ? (
                <img
                  src={graphData.nodes.find((n) => n.id === chosenEdge[EdgeDirActiveTab].from).screenshot_link}
                  id="swipe-img"
                  onLoad={onLoadHandle}
                />
              ) : (
                "There isn't screenshot for node"
              )}
            </div>
            <div id="screen-data-xml">
              {xml && !screenDataWaiting && <XMLViewer xml={xml} />}
              {screenDataWaiting && <Spin size="small" />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EdgeEditing;
