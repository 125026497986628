import { Button, Select, Spin, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { getPersonasByApp } from '../../../../api/insightsAPI';
import { getCurrentApp } from '../../../../api/workbenchAPI';
import device_icon from '../../../../assets/icons/device-icon.svg';
import location_icon from '../../../../assets/icons/location-icon.svg';
import { CUSTOM_DEVICE, URL_PARAMS } from '../../utils/consts';
import { replaceWbHistoryParams } from '../../utils/helpers';
import './Header.scss';

const { Option } = Select;

const appUI = (displayName, platform, app_icon) => (
  <div className="app-wrapper">
    <div className="app-name-wrapper">
      <div className="app">{displayName}</div>
      <div className="platform">{platform}</div>
    </div>
  </div>
);

const deviceUI = (model, osVer, platform) => (
  <div className="app-wrapper">
    <div className="app-name-wrapper">
      <div className="app">{model}</div>
      <div className="platform">
        {platform}
        <div className="os">{osVer}</div>
      </div>
    </div>
  </div>
);

const HeaderBase = ({
  apps,
  currApp,
  devices,
  leftDevice,
  rightDevice,
  persona,
  setPersona,
  error,
  deviceError,
  history,
  isAnyQueryLoading,
  setDiff,
  serverEvents,
  setProgress,
}) => {
  const [searchQuery, searchQueryChange] = useState('');

  const isRightDeviceCustom = rightDevice && rightDevice.serial === CUSTOM_DEVICE.serial;
  const isLeftDeviceCustom = leftDevice && leftDevice.serial === CUSTOM_DEVICE.serial;
  let currentNotCustomDevice = rightDevice || null;

  if (isRightDeviceCustom) currentNotCustomDevice = leftDevice;
  if (isLeftDeviceCustom) currentNotCustomDevice = rightDevice;

  const AppPersonasQuery = useQuery(['AppPersonas', currApp ? currApp.id : null], () => getPersonasByApp(currApp.id), {
    enabled: !!(currApp && currApp.id),
  });

  const currentAppQuery = useQuery(
    ['CurrentApp', currentNotCustomDevice ? currentNotCustomDevice.serial : null],
    () => getCurrentApp(currentNotCustomDevice.serial),
    {
      enabled: false,
      staleTime: 0,
      onSuccess: (data) => {
        const currentApp = apps.find((app) => {
          return (
            app.package === data.package && app.platform && app.platform.toLowerCase() === data.platform.toLowerCase()
          );
        });

        if (!currentApp) {
          return message.warn('The app does not exists on both selected devices');
        }

        replaceWbHistoryParams(`${URL_PARAMS.AppId}=${currentApp.id || ''}`, history);
      },
    }
  );

  useEffect(() => {
    if (AppPersonasQuery.status === 'success') {
      setPersona(AppPersonasQuery.data.data[0]);
    }
  }, [AppPersonasQuery.data]);

  const filteredAppList = apps.filter((app) => {
    if (searchQuery && searchQuery.length >= 3) {
      const match =
        app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.package.toLowerCase().includes(searchQuery.toLowerCase());
      return match;
    }
    return false;
  });

  const selectDeviceHandle = (serial, leftOrRightDevice) => {
    const device = serial === CUSTOM_DEVICE.serial ? CUSTOM_DEVICE : devices.find((device) => device.serial === serial);

    if (leftOrRightDevice === 'left') {
      replaceWbHistoryParams(`${URL_PARAMS.LeftDevice}=${device.serial || ''}`, history);
    }

    if (leftOrRightDevice === 'right') {
      replaceWbHistoryParams(`${URL_PARAMS.RightDevice}=${device.serial || ''}`, history);
    }
  };

  const handleAppSelected = (value, option) => {
    const currentApp = apps.find((app) => app.id === option.props.value);
    replaceWbHistoryParams(`${URL_PARAMS.AppId}=${currentApp.id || ''}`, history);
    setDiff(null);
    setProgress(null);
    searchQueryChange('');
  };

  const handleGetCurrentApp = () => {
    setDiff(null);
    setProgress(null);
    currentAppQuery.refetch();
  };

  const handleOnServerEvents = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(serverEvents, null, 4))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'fe-event-logs.json';
    link.click();
  };

  return (
    <div className="header-main">
      <div className="select-container">
        <div className="app-icon">{currApp && <img src={currApp.icon} />}</div>
        <div className="select">
          <Select
            showSearch
            filterOption={false}
            placeholder="Select an app..."
            onSearch={(value) => searchQueryChange(value)}
            onChange={handleAppSelected}
            defaultActiveFirstOption={false}
            value={currApp ? currApp.name : ''}
            style={{ width: 200, border: '1px solid gray', borderRadius: '5px' }}
          >
            {filteredAppList.map((app) => (
              <Option key={app.id} value={app.id}>
                {appUI(app.name, app.platform)}
              </Option>
            ))}
          </Select>
        </div>
        <Button
          className="current-app-icon"
          type="primary"
          loading={currentAppQuery.isLoading || currentAppQuery.isFetching}
          disabled={currentAppQuery.isLoading}
          onClick={handleGetCurrentApp}
        >
          {!currentAppQuery.isLoading && !currentAppQuery.isFetching && <img src={location_icon} />}
        </Button>
        <Spin spinning={isAnyQueryLoading} className="loading-indication" size="large" />
      </div>

      <div className="device-persona-wrapper">
        <div className="device-icon">
          <img src={device_icon} />
        </div>
        <div className="select-container">
          <div className="select">
            <Select
              style={{ width: 200 }}
              value={
                leftDevice &&
                deviceUI(leftDevice.device_name || leftDevice.serial, leftDevice.os_version, leftDevice.platform)
              }
              onSelect={(serial) => selectDeviceHandle(serial, 'left')}
            >
              {devices.map((device) => (
                <Option value={device.serial} key={device.serial}>
                  {' '}
                  {deviceUI(device.device_name || device.serial, device.os_version, device.platform)}
                </Option>
              ))}
              {leftDevice && rightDevice && rightDevice.serial !== CUSTOM_DEVICE.serial && (
                <Option value={CUSTOM_DEVICE.serial}>{deviceUI(CUSTOM_DEVICE.serial, CUSTOM_DEVICE.os_version)}</Option>
              )}
            </Select>
          </div>
          {deviceError && deviceError.device_serial === leftDevice.serial && (
            <div className="device-error">{deviceError.error}</div>
          )}
        </div>
      </div>
      <div className="select" />
      <div className="device-persona-wrapper">
        <div className="device-icon">
          <img src={device_icon} />
        </div>
        <div className="select-container">
          <div className="select">
            <Select
              style={{ width: 200 }}
              value={
                rightDevice &&
                deviceUI(rightDevice.device_name || rightDevice.serial, rightDevice.os_version, rightDevice.platform)
              }
              onSelect={(serial) => selectDeviceHandle(serial, 'right')}
            >
              {devices.map((device) => (
                <Option value={device.serial} key={device.serial}>
                  {' '}
                  {deviceUI(device.device_name || device.serial, device.os_version, device.platform)}
                </Option>
              ))}
              {rightDevice && leftDevice && leftDevice.serial !== CUSTOM_DEVICE.serial && (
                <Option value={CUSTOM_DEVICE.serial}>{deviceUI(CUSTOM_DEVICE.serial, CUSTOM_DEVICE.os_version)}</Option>
              )}
            </Select>
          </div>
          {deviceError && deviceError.device_serial === rightDevice.serial && (
            <div className="device-error">{deviceError.error}</div>
          )}
        </div>
        <div className="select-container">
          <div className="select">
            <div className="persona-label">Persona:</div>
            <Select
              value={persona && persona.title}
              style={{ width: 200 }}
              onSelect={(id) => {
                setPersona(AppPersonasQuery.data.data.find((per) => per.id === id));
              }}
            >
              {AppPersonasQuery &&
                AppPersonasQuery.data &&
                AppPersonasQuery.data.data.map((per) => (
                  <Option value={per.id} key={per.id}>
                    <div className="persona">{per.title}</div>
                  </Option>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="connect-container">
        <div className="connect">
          <div className={`connect-status ${error ? 'red' : 'green'}`} />
          <div className="connect-text">{`server ${error ? 'dis' : ''}connected`}</div>
        </div>
        <Button className="events-button" type="ghost" onClick={handleOnServerEvents}>
          {`Events (${serverEvents.length})`}
        </Button>
      </div>
    </div>
  );
};

export const Header = withRouter(HeaderBase);
