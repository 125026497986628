import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Button } from 'antd';
import { getAllSettings, addSetting, updSetting, delSetting } from '../../../redux/actions/personaSettings';
import SettingsTable from './SettingsTable';
import SettingsModal from './SettingsModal';

const SettingsTab = ({ settings, isLoading, error, getAllSettings, addSetting, updSetting, delSetting }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currEditSettingId, setCurrEditSettingId] = useState(null);

  useEffect(() => {
    getAllSettings();
  }, []);

  const handleUpdSetting = (settingId) => {
    setCurrEditSettingId(settingId);
    setIsModalVisible(true);
  };
  return (
    <React.Fragment>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      {isModalVisible && (
        <SettingsModal
          setting={settings.find((x) => x.id === currEditSettingId)}
          handleHide={() => {
            setIsModalVisible(false);
            setCurrEditSettingId(null);
          }}
          addSubmit={addSetting}
          updSubmit={updSetting}
        />
      )}
      <Button onClick={() => setIsModalVisible(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        New setting
      </Button>
      <SettingsTable
        isLoading={isLoading}
        settings={settings}
        onUpdSetting={(settingId) => handleUpdSetting(settingId)}
        onDelSetting={delSetting}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getAllSettings,
  addSetting,
  updSetting,
  delSetting,
};

const mapStateToProps = ({ settingsPage }) => ({
  settings: settingsPage.settings,
  isLoading: settingsPage.isLoading,
  error: settingsPage.error,
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTab);
