import {
  addUserAPI,
  getAllUsersAPI,
  deleteUserAPI,
  deleteUserAppAPI,
  editUserAppsAPI,
  editUserDetailsAPI,
  getUserAppsAPI,
  getAllAppsAPI,
  changeUserPasswordAPI,
  getProductsForUserAPI,
  getUserDetailsAPI,
} from '../actions/usersPage';
import * as AT from './../actionTypes';

const usersMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_ALL_USERS: {
        dispatch(getAllUsersAPI());
        return next(action);
      }
      case AT.GET_ALL_APPS: {
        dispatch(getAllAppsAPI(action.payload.platform));
        return next(action);
      }
      case AT.ADD_USER: {
        dispatch(addUserAPI(action.payload.user));
        return next(action);
      }
      case AT.DELETE_USER: {
        dispatch(deleteUserAPI(action.payload.user));
        return next(action);
      }
      case AT.EDIT_USER_APPS: {
        dispatch(editUserAppsAPI(action.payload.userID, action.payload.userApps));
        return next(action);
      }
      case AT.DELETE_USER_APP: {
        dispatch(deleteUserAppAPI(action.payload.userID, action.payload.appID));
        return next(action);
      }
      case AT.EDIT_USER_DEATILS: {
        dispatch(editUserDetailsAPI(action.payload.user));
        return next(action);
      }
      case AT.GET_USER_APPS: {
        dispatch(getUserAppsAPI(action.payload.user));
        return next(action);
      }
      case AT.SET_USER_PASSWORD: {
        dispatch(changeUserPasswordAPI(action.payload.userID, action.payload.password));
        return next(action);
      }

      case AT.GET_PRODUCTS_FOR_USER: {
        dispatch(getProductsForUserAPI(action.payload.userID));
        return next(action);
      }
      case AT.GET_USER_DETAILS: {
        dispatch(getUserDetailsAPI(action.payload.userID));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default usersMiddleware;
