import React from 'react';
import { Button, Icon, Popconfirm, Table, Divider } from 'antd';

export default ({ settings, isLoading, onUpdSetting, onDelSetting }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '150px',
      render: (id, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '5px' }}>{id}</div>
          <Button type="small" icon="edit" title="Change segment" onClick={() => onUpdSetting(record.id)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              onDelSetting(record.id);
            }}
          >
            <Button icon="delete" type="danger small" title="Delete segment" />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '150px',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '150px',
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={settings}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ y: 600 }}
        pagination={false}
      />
    </div>
  );
};
