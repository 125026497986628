import { message } from 'antd';
import { useRef, useState } from 'react';

const SCREEN_CHANGE_TIMEOUT = 2000;

export const useUjAutoMode = () => {
  const [isAutoModeEnabled, setIsAutoModeEnabled] = useState(false);
  const autoDelayTimeoutRef = useRef(null);

  const handleAutoDelayTrigger = (fn) => {
    if (!isAutoModeEnabled) return;

    if (autoDelayTimeoutRef.current) clearTimeout(autoDelayTimeoutRef.current);

    autoDelayTimeoutRef.current = setTimeout(() => {
      message.success('Click Saved');
      fn();
    }, SCREEN_CHANGE_TIMEOUT);
  };

  return {
    isAutoModeEnabled,
    setIsAutoModeEnabled,
    handleAutoDelayTrigger,
  };
};
