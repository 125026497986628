import { Icon, Radio, Select } from 'antd';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getFilteredABTests, updABtestByID } from '../../../api/insightsAPI';
import { AB_TEST_STATUS } from '../../../constants/abTest';
import { INSIGHT_TYPE } from '../../../constants/insightType';
import { useCopyToClipboard } from '../hooks/useCopyToClipboard';

const { Option } = Select;

const AB_TEST_STATUS_OPTIONS = Object.keys(AB_TEST_STATUS).map((type) => ({
  text: AB_TEST_STATUS[type],
  value: type,
  id: type,
  key: type,
  disabled: type === 'abandoned_no_data',
}));

export default function InsightType({ insight, handleInsChange, appID, variants, isMarketing }) {
  const [searchQuery, setSearchQuery] = useState('');
  const isABTest = ['ab_test', 'indication'].includes(insight.type);
  const { isCopied, copyToClipBoard } = useCopyToClipboard();

  // API
  const AppABTests = useQuery(
    ['AbTestsByApp', appID, searchQuery],
    () => getFilteredABTests(appID, searchQuery || ''),
    {
      enabled: isABTest,
    }
  );
  const updateABTestMutation = useMutation(updABtestByID, {
    onSuccess: (res, variables) => {
      handleInsChange({
        ab_test: res.data,
      });
      message.destroy();
      message.success("Insight's ABTest was saved");
    },
    onError: (data) => {
      message.destroy();
      message.error(JSON.parse(data.message).msg);
    },
  });

  const internals = AppABTests.data ? AppABTests.data.data : [];
  const onSearch = (value) => {
    setSearchQuery(value);
  };
  const onSelect = (value) => {
    handleInsChange({ ab_test_id: Number(value) });
    setSearchQuery('');
  };
  const handleChangeType = (e) => {
    const newData = {
      type: e.target.value,
    };
    if (!['ab_test', 'indication'].includes(e.target.value)) {
      newData.ab_test_id = null;
    }
    handleInsChange(newData);
  };
  const handleABStatusChange = (e) => {
    updateABTestMutation.mutateAsync({
      abtest_id: insight.ab_test.id,
      data: {
        status: e,
      },
    });
  };
  const handleChampionVariantChange = (e) => {
    updateABTestMutation.mutateAsync({
      abtest_id: insight.ab_test.id,
      data: {
        champion_variant_id: e,
      },
    });
  };

  const handleCopyClick = (text) => () => {
    copyToClipBoard(text);
  };

  return (
    <section className="insight-data-item insight-type">
      <h3 className="insight-data-item-title">Type</h3>
      <div className="insight-data-item-input">
        {isMarketing ? (
          <Radio.Group onChange={handleChangeType} value={insight.type}>
            <Radio value="email_campaign">Email Campaign</Radio>
            <Radio value="push_notification_campaign">Push Notification</Radio>
            <Radio value="inapp_campaign">In App</Radio>
          </Radio.Group>
        ) : (
          <Radio.Group onChange={handleChangeType} value={insight.type}>
            <Radio value="ab_test">A/B Test</Radio>
            <Radio value="indication">Indication</Radio>
            <Radio value="screen_change">UI Change</Radio>
            <Radio value="new_feature">New Feature</Radio>
            <Radio value="removed_feature">Removed Feature</Radio>
          </Radio.Group>
        )}
      </div>
      {insight.type && (
        <section>
          <h3 className="insight-data-item-title">{INSIGHT_TYPE[insight.type]} Test Data Section</h3>
          {['ab_test', 'indication'].includes(insight.type) && (
            <div className="insight-data-abtest">
              <div className="fields">
                <div className="field width-50p">
                  <label>ID and Internal Name</label>
                  <Select
                    showSearch
                    filterOption={false}
                    className="ab-test-autocomplete"
                    placeholder="Search by Internal Name..."
                    onSelect={onSelect}
                    onSearch={(value) => onSearch(value, insight.app_id)}
                    value={
                      searchQuery || (insight.ab_test ? `${insight.ab_test.id} - ${insight.ab_test.internal_name}` : '')
                    }
                    getPopupContainer={(trNode) => trNode}
                  >
                    {internals.map((internal) => (
                      <Option key={internal.id} value={String(internal.id)}>
                        {internal.id} - {internal.internal_name}
                      </Option>
                    ))}
                  </Select>
                  <Icon
                    type={isCopied ? 'check' : 'copy'}
                    style={{ color: isCopied ? '#32db5f' : '#1890ff' }}
                    className="copy-icon"
                    onClick={handleCopyClick(
                      searchQuery || (insight.ab_test ? `${insight.ab_test.id} - ${insight.ab_test.internal_name}` : '')
                    )}
                  />
                </div>

                {insight.ab_test && (
                  <div className="field ab-test-status-select ab-test-status width-25p">
                    <label>Status</label>
                    <Select
                      value={insight.ab_test.status}
                      onChange={handleABStatusChange}
                      getPopupContainer={(trNode) => trNode}
                    >
                      {AB_TEST_STATUS_OPTIONS.map((absts) => (
                        <Option value={absts.value} key={absts.key} disabled={absts.disabled}>
                          {absts.text}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
                {insight.ab_test && insight.ab_test.status === 'integrated' && (
                  <div className="field width-25p">
                    <label>Champion Variant</label>
                    <Select
                      value={insight.ab_test.champion_variant_id}
                      onChange={handleChampionVariantChange}
                      getPopupContainer={(trNode) => trNode}
                    >
                      {variants
                        ? variants
                            .filter((v) => !v.control)
                            .map((v) => <Option value={v.id}>{`#${v.id} | Variant ${v.type} `}</Option>)
                        : []}
                    </Select>
                    {!insight.ab_test.champion_variant_id && (
                      <div style={{ color: '#e13e3e' }}>!Please choose champion variant</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </section>
      )}
    </section>
  );
}
