import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';
import { DEFAULT_PLATFORM, ALL_PLATFORMS } from '../../constants/platforms';

export const setApps = (body) => ({
  type: AT.SET_APPS,
  payload: { apps: body },
});

export const setAppVersions = (appPackage, appPlatform, body) => ({
  type: AT.SET_APP_VERSIONS,
  payload: { appPackage, appPlatform, versions: body },
});

export const loadApps = (platform = DEFAULT_PLATFORM) => ({
  type: AT.API_REQUEST,
  payload: {
    url: platform === ALL_PLATFORMS ? `app/list` : `app/list?platform=${platform}`,
    label: NETWORK_LABELS.LOAD_APPS,
    onSuccess: (body, dispatch) => dispatch(setApps(body)),
    onFailure: (error) => console.log(`Failed to load apps: ${error.message || error.response.text}`),
  },
});

export const loadPerfApps = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'performance/apps',
    label: NETWORK_LABELS.LOAD_PERF_APPS,
    onSuccess: (apps, dispatch) => dispatch(setPerfApps(apps)),
  },
});

export const setPerfApps = (apps) => ({
  type: AT.SET_PERF_APPS,
  payload: { apps },
});

export const loadAppVersions = (appPackage, appPlatform) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/versions?app=${appPackage}&platform=${appPlatform}`,
    label: NETWORK_LABELS.LOAD_APP_VERSIONS,
    onSuccess: (body, dispatch) => dispatch(setAppVersions(appPackage, appPlatform, body)),
    // onFailure: (error, dispatch) =>
    //   dispatch(setSystemError(`Failed to load app versions: ${error.message || error.response.text}`)),
  },
});

// load events
export const getAppEvents = (app_id) => ({
  type: AT.GET_APP_EVENTS,
  payload: app_id,
});

export const getAppEventsAPI = (app_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/events_all?app_id=${app_id}`,
    label: 'GET_APP_EVENTS',
    onSuccess: (body, dispatch) => dispatch(getAppEventsSuccess({ body, app_id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const getAppEventsSuccess = (data) => ({
  type: AT.GET_APP_EVENTS_SUCCESS,
  payload: { data },
});

// add events
export const addAppEvents = (data) => ({
  type: AT.ADD_APP_EVENTS,
  payload: data,
});

export const addAppEventsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/events`,
    method: 'POST',
    data,
    label: 'ADD_APP_EVENTS',
    onSuccess: (body, dispatch) => dispatch(addAppEventsSuccess({ body, app_id: data.app_id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const addAppEventsSuccess = (data) => ({
  type: AT.ADD_APP_EVENTS_SUCCESS,
  payload: { data },
});

// remove events
export const removeAppEvents = (data) => ({
  type: AT.REMOVE_APP_EVENTS,
  payload: data,
});

export const removeAppEventsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/events/${data.id}`,
    method: 'DELETE',
    label: 'REMOVE_APP_EVENTS',
    onSuccess: (body, dispatch) => dispatch(removeAppEventsSuccess({ body, app_id: data.app_id, id: data.id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const removeAppEventsSuccess = (data) => ({
  type: AT.REMOVE_APP_EVENTS_SUCCESS,
  payload: { data },
});

//--------------------------------

// load Devices
export const getAppDevices = (app_id) => ({
  type: AT.GET_APP_DEVICES,
  payload: app_id,
});

export const getAppDevicesAPI = (app_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/${app_id}/device_models`,
    label: 'GET_APP_DEVICES',
    onSuccess: (body, dispatch) => dispatch(getAppDevicesSuccess({ body, app_id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const getAppDevicesSuccess = (data) => ({
  type: AT.GET_APP_DEVICES_SUCCESS,
  payload: { data },
});

// add events
export const addAppDevices = (data) => ({
  type: AT.ADD_APP_DEVICES,
  payload: data,
});

export const addAppDevicesAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/${data.app_id}/device_models/${data.device_model_id}`,
    method: 'PUT',
    data: {
      performance_enabled: 1,
      device_model_id: data.device_model_id,
    },
    label: 'ADD_APP_DEVICES',
    onSuccess: (body, dispatch) =>
      dispatch(addAppDevicesSuccess({ body, app_id: data.app_id, device_model_id: data.device_model_id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const addAppDevicesSuccess = (data) => ({
  type: AT.ADD_APP_DEVICES_SUCCESS,
  payload: { data },
});

// remove events
export const removeAppDevices = (data) => ({
  type: AT.REMOVE_APP_DEVICES,
  payload: data,
});

export const removeAppDevicesAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/${data.app_id}/device_models/${data.device_model_id}`,
    method: 'PUT',
    label: 'REMOVE_APP_DEVICES',
    data: { performance_enabled: false },
    onSuccess: (body, dispatch) =>
      dispatch(removeAppDevicesSuccess({ app_id: data.app_id, device_model_id: data.device_model_id })),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const removeAppDevicesSuccess = (data) => ({
  type: AT.REMOVE_APP_DEVICES_SUCCESS,
  payload: { data },
});
