import React from 'react';
import { useQuery } from 'react-query';
import { Alert } from 'antd';
import GraphMappingTable from './GraphMappingTable';
import { getReleases } from '../../api/graphMappingAPI';

export default () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const releases = useQuery(['releases', currentPage], () => getReleases({ page_num: currentPage }));

  if (releases.isError) {
    return <Alert message={releases.error.message} description="" type="error" showIcon />;
  }

  return (
    <React.Fragment>
      <GraphMappingTable
        isLoading={releases.isLoading}
        releases={releases.data && releases.data.data}
        currentPage={currentPage}
        pageSize={100}
        totalSize={releases.data && releases.data.meta.total}
        getReleases={setCurrentPage}
      />
    </React.Fragment>
  );
};
