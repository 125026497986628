"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RP = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
var WorkbenchContext_1 = require("components/workbench_v2/contexts/workbench-context/WorkbenchContext");
require("./RP.scss");
var rp_device_1 = require("./rp-device");
var react_query_1 = require("react-query");
// @ts-ignore
var workbenchAPI_1 = require("api/workbenchAPI");
var consts_1 = require("components/workbench_v2/utils/consts");
var RP = function () {
    var _a = (0, WorkbenchContext_1.useWorkbenchContext)(), leftDevice = _a.leftDevice, rightDevice = _a.rightDevice, rp = _a.rp, setRp = _a.setRp, leftDeviceAppVer = _a.leftDeviceAppVer, rightDeviceAppVer = _a.rightDeviceAppVer;
    var leftDeviceKey = leftDevice ? leftDevice.device_name || leftDevice.serial : null;
    var rightDeviceKey = rightDevice ? rightDevice.device_name || rightDevice.serial : null;
    var _b = (0, react_1.useState)(null), selectedDeviceKey = _b[0], setSelectedDeviceKey = _b[1];
    var onDeviceChange = function (value) { return function () {
        setSelectedDeviceKey(value);
    }; };
    var updateRpConfigMutation = (0, react_query_1.useMutation)(['UpdateRpConfig'], function (_a) {
        var serial = _a.serial, body = _a.body;
        return (0, workbenchAPI_1.updateRpConfig)(serial, body);
    });
    var resetRp = function (serial) { return function () {
        setRp(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[serial] = null, _a)));
        });
    }; };
    var updateTestValue = function (_a) {
        var serial = _a.serial, name = _a.name, newValue = _a.newValue;
        return __awaiter(void 0, void 0, void 0, function () {
            var curDeviceAppVer;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!leftDevice || !rightDevice || !leftDeviceAppVer || !rightDeviceAppVer)
                            return [2 /*return*/];
                        curDeviceAppVer = serial === leftDevice.serial ? leftDeviceAppVer : rightDeviceAppVer;
                        return [4 /*yield*/, updateRpConfigMutation.mutateAsync({
                                serial: serial,
                                body: {
                                    package: curDeviceAppVer.package,
                                    version_name: curDeviceAppVer.version_name,
                                    version_code: curDeviceAppVer.version_code,
                                    experiment_name: name,
                                    new_value: newValue,
                                },
                            })];
                    case 1:
                        _b.sent();
                        setRp(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[serial] = __assign(__assign({}, prev[serial]), { experiments: prev[serial].experiments.map(function (test) {
                                    if (test.name === name) {
                                        return __assign(__assign({}, test), { value: newValue });
                                    }
                                    return test;
                                }) }), _a)));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return (react_1.default.createElement("div", { className: "wb-rp-container" },
        react_1.default.createElement("div", { className: "wb-rp-header" },
            leftDeviceKey && leftDeviceKey !== consts_1.CUSTOM_DEVICE.serial && (react_1.default.createElement(antd_1.Button, { type: selectedDeviceKey === leftDeviceKey ? 'primary' : 'default', size: "large", style: { width: '100%' }, onClick: onDeviceChange(leftDeviceKey) }, leftDeviceKey)),
            rightDeviceKey && rightDeviceKey !== leftDeviceKey && rightDeviceKey !== consts_1.CUSTOM_DEVICE.serial && (react_1.default.createElement(antd_1.Button, { type: selectedDeviceKey === rightDeviceKey ? 'primary' : 'default', size: "large", style: { width: '100%' }, onClick: onDeviceChange(rightDeviceKey) }, rightDeviceKey))),
        leftDevice && rightDevice && leftDeviceAppVer && rightDeviceAppVer && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(rp_device_1.RPDevice, { resetRp: resetRp(leftDevice.serial), updateTestValue: updateTestValue, serial: leftDevice.serial, show: selectedDeviceKey === leftDeviceKey, deviceRP: rp ? rp[leftDevice.serial] : null, currAppVer: leftDeviceAppVer }),
            leftDevice !== rightDevice && (react_1.default.createElement(rp_device_1.RPDevice, { resetRp: resetRp(rightDevice.serial), updateTestValue: updateTestValue, serial: rightDevice.serial, show: selectedDeviceKey === rightDeviceKey, deviceRP: rp ? rp[rightDevice.serial] : null, currAppVer: rightDeviceAppVer }))))));
};
exports.RP = RP;
