export const FE_UPDATES = [
  {
    date: '2023-12-06T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added option to save multiple screenshots for each device'],
  },
  {
    date: '2023-12-06T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added common problems and solutions modal'],
  },
  {
    date: '2023-06-05T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added new hints config options', 'Every config change and stops and starts the hints again'],
  },
  {
    date: '2023-04-31T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added download app graph button', 'Added device control buttons (back/home/collaps all)'],
  },
  {
    date: '2023-04-18T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added support for 1 device and custom versions'],
  },
  {
    date: '2023-04-17T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added keyboard control', 'Added device wake on double click and power button'],
  },
  {
    date: '2023-04-15T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added diff pagination', 'Finished AB Tests Design'],
  },
  {
    date: '2023-05-15T07:51:16.490Z',
    version: '2.0.0',
    changes: ['Added whats new drawer'],
  },
];
