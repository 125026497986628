import React, { useEffect, useState } from 'react';
import { Alert, DatePicker } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import AbandonedInsightsTable from './AbandonedInsightsTable';
import { getAbandonedInsights } from '../../../redux/actions/abandonedInsightsPage';

const AbandonedInsights = ({ error, isLoading, insights, getAbandonedInsights }) => {
  const [untilDate, setUntilDate] = useState(moment().subtract(11, 'months'));
  useEffect(() => {
    getAbandonedInsights(untilDate.format(moment.HTML5_FMT.DATE));
  }, [untilDate]);

  return (
    <div>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && error.response.body.error}
          type="error"
          showIcon
        />
      )}
      <div style={{ display: 'flex' }}>
        <span style={{ padding: '5px' }}>Until Date:</span>
        <DatePicker
          value={untilDate}
          onChange={(e, dateString) => setUntilDate(moment(dateString))}
          showToday={false}
          renderExtraFooter={() => (
            <div>
              <a className="ant-calendar-today-btn" onClick={() => setUntilDate(moment().subtract(11, 'months'))}>
                11 Months Ago
              </a>
              <span style={{ paddingLeft: '3px' }}>|</span>
              <a className="ant-calendar-today-btn" onClick={() => setUntilDate(moment().subtract(3, 'months'))}>
                3 Months Ago
              </a>
              <span style={{ paddingLeft: '3px' }}>|</span>
              <a className="ant-calendar-today-btn" onClick={() => setUntilDate(moment())}>
                Today
              </a>
            </div>
          )}
        />
      </div>
      <AbandonedInsightsTable isLoading={isLoading} insights={insights} />
    </div>
  );
};

const mapDispatchToProps = {
  getAbandonedInsights,
};

const mapStateToProps = ({ abandonedInsights }) => ({
  error: abandonedInsights.error,
  isLoading: abandonedInsights.isLoading,
  insights: abandonedInsights.insights,
});

export default connect(mapStateToProps, mapDispatchToProps)(AbandonedInsights);
