import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  personas: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PERSONAS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_PERSONAS_SUCCESS: {
      return {
        ...state,
        personas: action.payload.personas,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_PERSONAS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case actionTypes.UPD_PERSONA: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_PERSONA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((x) => (x.id === action.payload.data.id ? action.payload.data : x)),
      };
    }
    case actionTypes.ADD_PERSONA: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_PERSONA_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        personas: [action.payload.data, ...state.personas],
      };
    }

    case actionTypes.ADD_PERSONA_ORGANIZATION: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_PERSONA_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((x) =>
          x.id === action.payload.data.persona.id ? action.payload.data.persona : x
        ),
      };
    }
    case actionTypes.DEL_PERSONA_ORGANIZATION: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_PERSONA_ORGANIZATION_SUCCESS: {
      const { personaId, organizationId } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                organizations: p.organizations.filter((o) => o.id !== organizationId),
              }
            : p
        ),
      };
    }

    case actionTypes.ADD_PRODUCT_SETTING: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_PRODUCT_SETTING_SUCCESS: {
      const { personaId } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                persona_products: p.persona_products.map((pp) =>
                  pp.id === action.payload.data.persona_product_id
                    ? {
                        ...pp,
                        settings: [action.payload.data.persona_setting, ...pp.settings],
                      }
                    : pp
                ),
              }
            : p
        ),
      };
    }
    case actionTypes.DEL_PRODUCT_SETTING: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_PRODUCT_SETTING_SUCCESS: {
      const { personaId, productId, settingId } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                persona_products: p.persona_products.map((pp) =>
                  pp.product_id === productId
                    ? {
                        ...pp,
                        settings: pp.settings.filter((set) => set.id !== settingId),
                      }
                    : pp
                ),
              }
            : p
        ),
      };
    }

    case actionTypes.ADD_PERSONA_PRODUCT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_PERSONA_PRODUCT_SUCCESS: {
      const { persona_id } = action.payload.data;

      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === persona_id
            ? {
                ...p,
                persona_products: [action.payload.data, ...p.persona_products],
              }
            : p
        ),
      };
    }
    case actionTypes.UPD_PERSONA_PRODUCT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_PERSONA_PRODUCT_SUCCESS: {
      const { persona_id, id } = action.payload.data;

      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === persona_id
            ? {
                ...p,
                persona_products: p.persona_products.map((pp) => (pp.id === id ? action.payload.data : pp)),
              }
            : p
        ),
      };
    }

    case actionTypes.DEL_PERSONA_PRODUCT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }

    case actionTypes.DEL_PERSONA_PRODUCT_SUCCESS: {
      const { personaId, productId } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                persona_products: p.persona_products.filter((pp) => pp.product_id !== productId),
              }
            : p
        ),
      };
    }

    case actionTypes.UPDATE_PAYMENT_DETAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.UPDATE_PAYMENT_DETAIL_SUCCESS: {
      const { personaId, personaProductId, data } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                persona_products: p.persona_products.map((pp) =>
                  pp.id === personaProductId ? { ...pp, payment_detail: data.data.payment_detail } : pp
                ),
              }
            : p
        ),
      };
    }

    case actionTypes.DEL_PAYMENT_DETAIL: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actionTypes.DEL_PAYMENT_DETAIL_SUCCESS: {
      const { personaId, personaProductId } = action.payload;
      return {
        ...state,
        isLoading: false,
        personas: state.personas.map((p) =>
          p.id === personaId
            ? {
                ...p,
                persona_products: p.persona_products.map((pp) =>
                  pp.id === personaProductId
                    ? {
                        ...pp,
                        payment_detail: null,
                      }
                    : pp
                ),
              }
            : p
        ),
      };
    }

    default: {
      return state;
    }
  }
}
