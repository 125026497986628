import React from 'react';
import { Button, Icon, Popconfirm, Table } from 'antd';

export default ({ ctas, isLoading, onUpdCta, onDelCta }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Url',
      dataIndex: 'url',
    },
    {
      title: 'utms',
      dataIndex: 'utms',
    },
    {
      title: '',
      key: 'actions',
      render: (value, record) => (
        <div>
          <Button style={{ margin: '4px' }} type="small" icon="edit" onClick={() => onUpdCta(record.id)} />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              onDelCta(record.id);
            }}
          >
            <Button icon="delete" type="danger small" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={ctas}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ y: 600 }}
        pagination={false}
      />
    </div>
  );
};
