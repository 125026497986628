import { Select } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { REMOTE_OPTIONS, URL_PARAMS } from '../../../utils/consts';
import { replaceWbHistoryParams } from '../../../utils/helpers';
import './RemoteSelect.scss';

const RemoteSelectBase = ({ options = REMOTE_OPTIONS, history }) => {
  const params = new URLSearchParams(history.location.search);
  const remoteAddress = params.get(URL_PARAMS.Remote);

  const onRemoteSelect = (value) => {
    replaceWbHistoryParams(`${URL_PARAMS.Remote}=${value}`, history);
    history.go(0);
  };

  return (
    <div className="rm-select-container">
      <Select value={remoteAddress} style={{ width: 150 }} placeholder="Select a remote" onSelect={onRemoteSelect}>
        {options.map((option) => (
          <Select.Option value={option.name}>{option.name}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const RemoteSelect = withRouter(RemoteSelectBase);
