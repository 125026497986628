import { handleActions } from 'redux-actions';
import * as AT from '../actionTypes';

const initialState = {
  isLoading: false,
  insights: [],
  meta: null,
};

const insightsListPageReudcer = handleActions(
  {
    [AT.GET_INSIGHTS_LIST]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.GET_INSIGHTS_LIST_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      insights: action.payload.data,
      meta: action.payload.meta,
    }),
  },
  initialState
);

export default insightsListPageReudcer;
