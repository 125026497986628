import { loadInsights, createInsightAPI, loadLabelsAPI } from 'redux/actions/insightPage';
import {
  loadAppVersions,
  getAppEventsAPI,
  addAppEventsAPI,
  removeAppEventsAPI,
  getAppDevicesAPI,
  addAppDevicesAPI,
  removeAppDevicesAPI,
} from 'redux/actions/apps';
import * as AT from '../actionTypes';

const appSelectionMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.SELECT_APP:
        dispatch(loadAppVersions(action.payload.appPackage, action.payload.appPlatform));
        dispatch(loadInsights(action.payload.appPackage, action.payload.appPlatform));
        dispatch(loadLabelsAPI());
        return next(action);
      case AT.CREATE_INSIGHT:
        dispatch(createInsightAPI(action.payload.insight));
        return next(action);

      // app's events and devices
      case AT.GET_APP_EVENTS:
        dispatch(getAppEventsAPI(action.payload));
        return next(action);
      case AT.ADD_APP_EVENTS:
        dispatch(addAppEventsAPI(action.payload));
        return next(action);
      case AT.REMOVE_APP_EVENTS:
        dispatch(removeAppEventsAPI(action.payload));
        return next(action);

      case AT.GET_APP_DEVICES:
        dispatch(getAppDevicesAPI(action.payload));
        return next(action);
      case AT.ADD_APP_DEVICES:
        dispatch(addAppDevicesAPI(action.payload));
        return next(action);
      case AT.REMOVE_APP_DEVICES:
        dispatch(removeAppDevicesAPI(action.payload));
        return next(action);
      case AT.LOAD_LABELS:
        dispatch(loadLabelsAPI(action.payload));
        return next(action);

      default:
        return next(action);
    }
  };

export default appSelectionMiddleware;
