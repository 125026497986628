import { isNull } from 'lodash/lang';
import { v4 as uuidv4 } from 'uuid';

export const CELLSIZE = 35;
export const canvasWidth = 750;
export const canvasHeight = 650;

export const GRAPHCANVASOPTIONS = {
  layout: {
    hierarchical: false,
  },
  autoResize: false,
  nodes: {
    shape: 'box',
    margin: 0.1,
    color: {
      border: '#2E74FF',
      background: 'white',
      highlight: {
        background: '#2E74FF',
      },
    },
    font: {
      color: '#2E74FF',
    },

    widthConstraint: {
      maximum: CELLSIZE * 2,
      minimum: CELLSIZE * 2,
    },
    heightConstraint: {
      minimum: CELLSIZE * 2,
    },
    shapeProperties: {
      borderRadius: 6,
    },
  },
  edges: {
    arrows: {
      to: {
        type: 'vee',
      },
      middle: {
        enabled: true,
        imageHeight: 17,
        imageWidth: 17,
        src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACPSURBVHgB7ZQtDoNAEIU/Gi7QysrWNjhkRU2T3qEH4ABwBQTchnNgkBjAsBKOsECCYrPhbwWCz0zykveSmZeM5fhSsgdJZQ8zi9mEaOAXwgUDGAmxp0KUQC70BvcB3ncmJK8hLdByv6racW5yhqgoFX9efY03vcF9Lgj5v1mNuXVEyybq0Wc5gSzZQf+Nqg7c3hrQa3YnagAAAABJRU5ErkJggg==',
        type: 'image',
      },
    },
    endPointOffset: {
      to: 50,
    },
  },
  width: `${canvasWidth}px`,
  height: `${canvasHeight}px`,
  physics: {
    enabled: false,
  },
};

export const swipe_data2swipe = (swipe_data) => {
  if (isNull(swipe_data)) {
    return {
      x: null,
      y: null,
      x_offset: null,
      y_offset: null,
      swipe_duration: null,
    };
  }

  return {
    x: swipe_data.x1,
    y: swipe_data.y1,
    x_offset: swipe_data.x2 - swipe_data.x1,
    y_offset: swipe_data.y2 - swipe_data.y1,
    swipe_duration: swipe_data.duration,
  };
};

export const swipe2swipe_data = (x, x_offset, y, y_offset, swipe_duration) => ({
  x1: x,
  x2: x + x_offset,
  y1: y,
  y2: y + y_offset,
  duration: swipe_duration,
});

export const DEFAULT_EDGE_PROPERTIES = {
  iterations_count: 0,
  exported_key: uuidv4(),
  custom_threshold: '',
  wait_for_element: false,
  use_laplace: true,
  is_strict_tap_cmd: false,
  repeat: 0,
  idx: 0,
  sleep_after: 0,
};
export const automationStateEnum = {
  NotRun: 'NotRun',
  Ran: 'Ran',
  Running: 'Running',
};

// methods
export const getLastOrderIndex = (graphData) =>
  Math.max(
    ...graphData.edges
      .map((e) => e.order_indexes)
      .reduce((acc, el) => [...acc, el.split(',')], [0])
      .flat()
      .map((order) => parseInt(order))
  );

export const getLastNodeIDInChain = (graphData) => {
  try {
    if (graphData.edges[0] && graphData.edges[0].order_indexes) {
      const lastEdgeInChain = graphData.edges.reduce((prevEl, newEl) => {
        const maxPrevEl = Math.max(...prevEl.order_indexes.split(',').map((order) => parseInt(order)));
        const maxNewEl = Math.max(...newEl.order_indexes.split(',').map((order) => parseInt(order)));
        return maxNewEl > maxPrevEl ? newEl : prevEl;
      }, graphData.edges[0]);
      return lastEdgeInChain.to;
    }
    return graphData.nodes[0].id;
  } catch (e) {
    return graphData.nodes[0].id;
  }
};

export const getXYNewNode = (gn, nodeID) => {
  const scale = gn.getScale();
  const offset = CELLSIZE * 4 * scale;
  const DOMXY = gn.canvasToDOM(gn.getPosition(nodeID));
  let newCoords = { x: DOMXY.x + offset + CELLSIZE * scale, y: DOMXY.y + CELLSIZE * scale };
  if (!gn.getNodeAt({ x: DOMXY.x + offset, y: DOMXY.y })) {
    // right
    newCoords = { x: DOMXY.x + offset, y: DOMXY.y };
  } else if (!gn.getNodeAt({ x: DOMXY.x + offset, y: DOMXY.y + offset })) {
    // right-down
    newCoords = { x: DOMXY.x + offset, y: DOMXY.y + offset };
  } else if (!gn.getNodeAt({ x: DOMXY.x, y: DOMXY.y + offset })) {
    // down
    newCoords = { x: DOMXY.x, y: DOMXY.y + offset };
  } else if (!gn.getNodeAt({ x: DOMXY.x - offset, y: DOMXY.y + offset })) {
    // left-down
    newCoords = { x: DOMXY.x - offset, y: DOMXY.y + offset };
  } else if (!gn.getNodeAt({ x: DOMXY.x - offset, y: DOMXY.y })) {
    // left
    newCoords = { x: DOMXY.x - offset, y: DOMXY.y };
  } else if (!gn.getNodeAt({ x: DOMXY.x - offset, y: DOMXY.y - offset })) {
    // left-up
    newCoords = { x: DOMXY.x - offset, y: DOMXY.y - offset };
  } else if (!gn.getNodeAt({ x: DOMXY.x, y: DOMXY.y - offset })) {
    // up
    newCoords = { x: DOMXY.x, y: DOMXY.y - offset };
  } else if (!gn.getNodeAt({ x: DOMXY.x + offset, y: DOMXY.y - offset })) {
    // right-up
    newCoords = { x: DOMXY.x + offset, y: DOMXY.y - offset };
  }
  return gn.DOMtoCanvas(newCoords);
};

export const drawGrid = (ctx, step, highliteLineX, highliteLineY, offsetX = 0, offsetY = 0) => {
  const w = canvasWidth,
    h = canvasHeight;
  ctx.clearRect(0, 0, w, h);
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.strokeStyle = '#E5E5E5';
  for (let x = -offsetX, k = 0; x <= w; x += step, k++) {
    ctx.moveTo(x, 0);
    ctx.lineTo(x, h);
  }

  ctx.stroke();
  ctx.beginPath();
  for (let y = -offsetY, k = 0; y <= h; y += step, k++) {
    ctx.moveTo(0, y);
    ctx.lineTo(w, y);
  }
  ctx.stroke();
  if (highliteLineX) {
    ctx.beginPath();
    ctx.strokeStyle = '#1890ff';
    ctx.moveTo(highliteLineX, 0);
    ctx.lineTo(highliteLineX, h);

    ctx.moveTo(0, highliteLineY);
    ctx.lineTo(w, highliteLineY);
    ctx.stroke();
  }
};
