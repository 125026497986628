import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { addInsightPersona, delInsightPersona, getInsightPersonas } from '../../../api/insightsAPI';

const { Option } = Select;

export default ({ insight, suggestions, insightPersonas, setInsightPersonas, insightPersonasQuery }) => {
  const addInsightPersonaMutation = useMutation(addInsightPersona, {
    onSuccess: (data) => {
      setInsightPersonas([...insightPersonas, data.data.persona]);
      message.destroy();
      message.success('Persona was added');
    },
  });
  const delInsightPersonaMutation = useMutation(delInsightPersona, {
    onSuccess: (data, variables) => {
      setInsightPersonas(insightPersonas.filter((x) => x.id !== variables.personaId));
      message.destroy();
      message.success('Persona was deleted');
    },
  });
  const handleUpdPersonas = (personaIds) => {
    if (insight.should_show && personaIds.length === 0) {
      message.destroy();
      message.error("Can't have a published insight without personas");
      return;
    }
    insightPersonas
      .filter((x) => !personaIds.includes(x.id))
      .forEach((x) =>
        delInsightPersonaMutation.mutateAsync({
          insightId: insight.id,
          personaId: x.id,
        })
      );
    personaIds
      .filter((y) => !insightPersonas.map((x) => x.id).includes(y))
      .forEach((personaId) =>
        addInsightPersonaMutation.mutateAsync({
          insightId: insight.id,
          personaId,
        })
      );
  };
  const isLoading = [insightPersonasQuery, addInsightPersonaMutation, delInsightPersonaMutation].some(
    (x) => x.isLoading
  );
  return (
    <Select
      mode="multiple"
      placeholder="Personas"
      style={{ width: '100%' }}
      onChange={(value) => handleUpdPersonas(value)}
      filterOption={(input, option) => option.props.children[3].toLowerCase().indexOf(input.toLowerCase()) >= 0}
      value={insightPersonas.map((x) => x.id)}
      loading={isLoading}
      disabled={isLoading}
      getPopupContainer={(trNode) => trNode}
      className={!!insightPersonas.length || 'status-error'}
    >
      {suggestions.map((persona) => (
        <Option value={persona.id} key={persona.id}>
          #{persona.id}
          <span style={{ margin: '0px 2px' }} className={`flag-icon flag-icon-${persona.location.toLowerCase()}`} />
          {persona.title}
          {persona.roles.length ? <i> ({persona.roles.join(', ')})</i> : null}
        </Option>
      ))}
    </Select>
  );
};
