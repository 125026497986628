import React from 'react';
import { Modal, Form, Input, Select } from 'antd';

const settingsTypes = ['segment', 'interest', 'pattern', 'role'];

class SettingForm extends React.Component {
  render() {
    const { form, setting } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form>
        <Form.Item label="Type">
          {getFieldDecorator('type', {
            rules: [
              {
                required: true,
              },
            ],
            initialValue: setting && setting.type,
          })(
            <Select placeholder="Type">
              {settingsTypes.map((type) => (
                <Select.Option key={type} value={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Value">
          {getFieldDecorator('value', {
            rules: [
              {
                required: true,
                max: 150,
              },
            ],
            initialValue: setting && setting.value,
          })(<Input placeholder="Setting value" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(SettingForm);

export default ({ setting, addSubmit, updSubmit, handleHide }) => {
  let formRef = null;

  return (
    <Modal
      title={setting ? `Change setting #${setting.id}` : 'Add new setting'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (setting) {
              updSubmit(setting.id, values);
            } else {
              addSubmit(values);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        setting={setting}
      />
    </Modal>
  );
};
