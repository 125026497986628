import moment from 'moment';
import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const getEmailPreview = (emailContent, callback) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'manager/email/preview',
    method: 'POST',
    data: emailContent,
    label: NETWORK_LABELS.GET_EMAIL_PREVIEW,
    onSuccess: (body, dispatch) => (callback ? callback(body) : dispatch(setEmailPreview(body))),
  },
});

export const getMeaningfulInsightEmailPreview = (insight) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insight.insight.id}/critical-insight-email-review`,
    method: 'GET',
    data: insight,
    label: NETWORK_LABELS.GET_EMAIL_PREVIEW,
    onSuccess: (body, dispatch) => dispatch(setEmailPreview(body.data)),
    onFailure: (error, dispatch) => dispatch(setEmailPreviewFailed(error.response.body)),
  },
});

export const addNotification = (insightID, sendEmail) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightID}/notifications`,
    method: 'POST',
    data: { send_email: sendEmail },
    label: NETWORK_LABELS.SET_INSIGHTS_IMPORTANCE,
    isLoading: true,
    onSuccess: (body, dispatch) => console.log('notification creation succeeded'),
    onFailure: (error, dispatch) => dispatch(sendEmailFailed(error.response.body)),
  },
});

const setEmailPreview = (data) => ({
  type: AT.SET_EMAIL_PREVIEW,
  payload: { data },
});

const setEmailPreviewFailed = (error) => ({
  type: AT.SET_EMAIL_PREVIEW_FAILED,
  payload: { error },
});

export const sendEmail = (htmlContent, recipients, date, testEmail = false, subject, textContent = '') => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'manager/email',
    method: 'POST',
    data: {
      htmlContent: encodeURIComponent(htmlContent),
      recipients,
      currentDate: date,
      subject,
      textContent,
    },
    label: NETWORK_LABELS.SEND_EMAIL,
    onSuccess: (body, dispatch) => dispatch(emailSent(body, testEmail)),
  },
});

const emailSent = (body, testEmail = false) => ({
  type: AT.EMAIL_SENT,
  payload: {
    test_email: testEmail,
  },
});

export const getConfiguration = () => ({
  type: AT.API_REQUEST,
  label: NETWORK_LABELS.GET_CONFIGURATION,
  payload: {
    url: `performance/configuration-all`,
    onSuccess: (defs, dispatch) => dispatch(setconfiguration(defs)),
  },
});

const setconfiguration = (defs) => ({
  type: AT.SET_CONFIGURATION,
  payload: {
    defs,
  },
});

// INSIGHTS BY IDS
export const getInsightByIDS = (data) => ({
  type: AT.GET_INSIGHTS_BY_IDS,
  payload: data,
});

export const getInsightByIDSApi = (data) => ({
  type: AT.API_REQUEST,
  label: 'GET_INSIGHTS_BY_IDS',
  payload: {
    url: `insight/list?ids=${data}`,
    onSuccess: (insights, dispatch) => dispatch(getInsightByIDSSuccess(insights)),
  },
});

const getInsightByIDSSuccess = (insights) => ({
  type: AT.GET_INSIGHTS_BY_IDS_SUCCESS,
  payload: {
    insights,
  },
});

export const getTiktokEffects = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `content-intel/tiktok-effects?from_date=${moment().subtract(7, 'days').format('YYYY-MM-DD')}`,
    method: 'GET',
    label: 'getTiktokEffects',
    onSuccess: (body, dispatch) => dispatch(getTiktokEffectsSuccess(body)),
  },
});

const getTiktokEffectsSuccess = (json) => ({
  type: AT.GET_TIKTOK_EFFECTS_SUCCESS,
  payload: { json },
});

export const getTiktokChallenges = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `content-intel/tiktok-challenges?from_date=${moment()
      .subtract(7, 'days')
      .format('YYYY-MM-DD')}&countries=US,BR`,
    method: 'GET',
    label: 'getTiktokChallenges',
    onSuccess: (body, dispatch) => dispatch(getTiktokChallengesSuccess(body)),
  },
});

const getTiktokChallengesSuccess = (json) => ({
  type: AT.GET_TIKTOK_CHALLENGES_SUCCESS,
  payload: { json },
});

export const loadEmails = () => ({
  type: AT.LOAD_EMAILS,
});

export const loadEmailsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'user/emails',
    label: NETWORK_LABELS.LOAD_EMAILS,
    onSuccess: (body, dispatch) => dispatch(loadEmailsSuccess(body)),
    onFailure: (error) => console.log(`Failed to load emails: ${error.message || error.response.text}`),
  },
});

export const loadEmailsSuccess = (emails) => ({
  type: AT.LOAD_EMAILS_SUCCESS,
  payload: { emails },
});

const sendEmailFailed = (error) => ({
  type: AT.SEND_EMAIL_FAILED,
  payload: { error },
});
