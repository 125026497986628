import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { startVideoRecording, stopVideoRecording } from 'api/workbenchAPI';
import { EDITING_MODES, INSIGHT_ASSET_TYPES } from 'components/workbench_v2/utils/consts';
import _ from 'lodash';

export const useVideoRecord = ({ device, deviceAppVer, setImages, setEditingModeStep }) => {
  const [videoStarted, setVideoStarted] = useState(false);

  const startVideoMutation = useMutation(startVideoRecording);
  const stopVideoMutation = useMutation(stopVideoRecording);

  useEffect(() => {
    return () => {
      if (videoStarted) stopVideoMutation.mutateAsync({ serial: device.serial });
    };
  }, [videoStarted]);

  const handleToggleVideo = async () => {
    if (videoStarted) {
      const res = await stopVideoMutation.mutateAsync({ serial: device.serial });

      setImages((prev) => [
        {
          type: INSIGHT_ASSET_TYPES.Video,
          videoUrl: res.remote_path,
          id: _.uniqueId(),
        },
        ...prev,
      ]);
      setEditingModeStep(EDITING_MODES.PrintScreen);
    } else {
      await startVideoMutation.mutateAsync({
        serial: device.serial,
        package_name: deviceAppVer.package,
        version_code: deviceAppVer.version_code,
        version_name: deviceAppVer.version_name,
      });
      message.info('Video recording started it will stopped automatically after 6 minutes');
    }

    setVideoStarted((prev) => !prev);
  };

  return {
    videoStarted,
    isVideoLoading: startVideoMutation.isLoading || stopVideoMutation.isLoading,
    handleToggleVideo,
  };
};
