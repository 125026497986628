"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteFocusArea = exports.DELETE_FOCUS_AREA_KEY = exports.useUpdateFocusArea = exports.UPDATE_FOCUS_AREA_KEY = exports.useCreateFocusArea = exports.CREATE_FOCUS_AREA_KEY = exports.useGetFocusAreas = exports.GET_FOCUS_AREAS_KEY = void 0;
var react_query_1 = require("react-query");
var insightsAPI_1 = require("api/insightsAPI");
var antd_1 = require("antd");
exports.GET_FOCUS_AREAS_KEY = ['GET_FOCUS_AREAS'];
var useGetFocusAreas = function (_a) {
    var vertical_id = _a.vertical_id;
    return (0, react_query_1.useQuery)([exports.GET_FOCUS_AREAS_KEY, vertical_id], function () { return (vertical_id ? (0, insightsAPI_1.getFocusAreasForVertical)({ vertical_id: vertical_id }) : (0, insightsAPI_1.getAllFocusAreas)()); }, {
        enabled: vertical_id === undefined || !!vertical_id,
    });
};
exports.useGetFocusAreas = useGetFocusAreas;
exports.CREATE_FOCUS_AREA_KEY = ['CREATE_FOCUS_AREA'];
var useCreateFocusArea = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.CREATE_FOCUS_AREA_KEY, insightsAPI_1.createFocusArea, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_FOCUS_AREAS_KEY);
            antd_1.message.success('Focus Area Created');
        },
        onError: function () {
            antd_1.message.error('Focus Area Creation Failed');
        },
    });
};
exports.useCreateFocusArea = useCreateFocusArea;
exports.UPDATE_FOCUS_AREA_KEY = ['UPDATE_FOCUS_AREA'];
var useUpdateFocusArea = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.UPDATE_FOCUS_AREA_KEY, insightsAPI_1.updateFocusArea, {
        onSuccess: function () {
            queryClient.invalidateQueries([exports.GET_FOCUS_AREAS_KEY]);
            antd_1.message.success('Focus Area Updated');
        },
        onError: function () {
            antd_1.message.error('Focus Area Update Failed');
        },
    });
};
exports.useUpdateFocusArea = useUpdateFocusArea;
exports.DELETE_FOCUS_AREA_KEY = ['DELETE_FOCUS_AREA'];
var useDeleteFocusArea = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.DELETE_FOCUS_AREA_KEY, insightsAPI_1.deleteFocusArea, {
        onSuccess: function () {
            queryClient.invalidateQueries([exports.GET_FOCUS_AREAS_KEY]);
            antd_1.message.success('Focus Area Deleted');
        },
        onError: function () {
            antd_1.message.error('Focus Area Deletion Failed');
        },
    });
};
exports.useDeleteFocusArea = useDeleteFocusArea;
