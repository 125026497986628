import React, { useEffect, useState } from 'react';
import { Col, Row, Alert, Button, Icon, InputNumber, Modal, Spin } from 'antd';
import AssetsTable from './AssetsTable';
import AssetUploader from './AssetUploader';
import CanvasedImage from '../CanvasedImage';

import moment from 'moment';
import Img from 'react-image';

const ASSET_TYPE_CURRENT = 'current';
const ASSET_TYPE_PREVIOUS = 'previous';
const DEFAULT_ASSET_TYPE = {
  new_feature: ASSET_TYPE_CURRENT,
  indication: ASSET_TYPE_CURRENT,
  push_notification_campaign: ASSET_TYPE_CURRENT,
  removed_feature: ASSET_TYPE_PREVIOUS,
};

export default ({
  isLoading,
  error,
  uploadingStatus,
  assets,
  addAsset,
  updAsset,
  delAsset,
  setInsightThumbnail,
  variants,
  presignedUrl,
  refreshPresignedUrl,
  isDisabled,
  insightType,
}) => {
  const [activeAssetId, setActiveAssetId] = useState(assets.length ? assets[0].id : null);
  const [changedAsset, setChangedAsset] = useState(null);
  const [isZoomInModalVisible, setIsZoomInModalVisible] = useState(false);
  const [confidence, confidenceChange] = useState(0.2);

  useEffect(() => {
    refreshPresignedUrl();
  }, []);

  const getActiveAsset = () => changedAsset || assets.find((x) => x.id === activeAssetId);

  const handleAddAsset = (type, uploadedFileName, originalFileName) => {
    addAsset({
      type,
      uploaded_file_name: uploadedFileName,
      original_file_name: originalFileName,
      orientation: 'portrait',
      insight_asset_type: DEFAULT_ASSET_TYPE[insightType] || ASSET_TYPE_CURRENT,
    });
  };

  const handleRectChange = (rect) => {
    rect = {
      ...rect,
      removed: rect.removed === undefined ? undefined : String(rect.removed),
      key: rect.key === undefined ? undefined : String(rect.key),
    };
    const activeAsset = getActiveAsset();
    let rects;
    if (rect.removed) {
      rects = (activeAsset.rects || []).filter((x) => x.key !== rect.removed);
    } else {
      const index = (activeAsset.rects || []).map((x) => x.key).indexOf(rect.key);
      if (index === -1) {
        rects = [...(activeAsset.rects || []), { ...rect }];
      } else {
        rects = (activeAsset.rects || []).map((x) => (x.key === rect.key ? { ...rect } : x));
      }
    }
    setChangedAsset({
      ...getActiveAsset(),
      rects,
    });
  };

  const handleApply = () => {
    const activeAsset = getActiveAsset();
    updAsset(activeAsset.id, { rects: activeAsset.rects });
    setChangedAsset(null);
  };

  const handleSelectAssetId = (assetId) => {
    setActiveAssetId(assetId);
    setChangedAsset(null);
  };

  const handleDelAsset = (assetId) => {
    delAsset(assetId);
    setActiveAssetId(null);
    setChangedAsset(null);
  };

  const l = console.log;

  const handleBlurFaces = () => {
    l('handleBlurFaces');
    const a = getActiveAsset();
    l({ a });
    updAsset(a.id, { blur_faces: !a.blur_faces, confidence });
    l('after updAsset');
  };
  return (
    <div>
      {error && (
        <Alert
          message={error.message}
          description={JSON.stringify((error.response && error.response.body && error.response.body.error) || error)}
          type="error"
          showIcon
        />
      )}
      {isZoomInModalVisible && (
        <Modal
          title="Mark image"
          centered
          visible
          onOk={() => setIsZoomInModalVisible(false)}
          onCancel={() => setIsZoomInModalVisible(false)}
          width="95%"
          className="zoom-in-canvas"
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          <CanvasedImage
            imgSrc={getActiveAsset().url_raw}
            key={`${getActiveAsset().id}-${getActiveAsset().orientation}`}
            rects={Object.fromEntries((getActiveAsset().rects || []).map((x) => [x.key, x]))}
            onRectChange={handleRectChange}
            isLandscape={getActiveAsset().orientation === 'landscape'}
            isZoomIn
          />
        </Modal>
      )}
      <Row gutter={[12, 2]}>
        <Col span={12}>
          <div>
            <AssetsTable
              assets={assets}
              selectAssetId={handleSelectAssetId}
              activeIndex={getActiveAsset() && getActiveAsset().order_index}
              delAsset={handleDelAsset}
              updAsset={updAsset}
              setInsightThumbnail={setInsightThumbnail}
              isLoading={isLoading}
              variants={variants}
              isDisabled={isDisabled}
            />
            <div style={{ display: 'flex' }}>
              <AssetUploader
                type="image"
                onLoad={handleAddAsset}
                presignedUrl={presignedUrl}
                refreshPresignedUrl={refreshPresignedUrl}
              />
              <AssetUploader
                type="video"
                onLoad={handleAddAsset}
                presignedUrl={presignedUrl}
                refreshPresignedUrl={refreshPresignedUrl}
              />
              <AssetUploader
                type="html"
                onLoad={handleAddAsset}
                presignedUrl={presignedUrl}
                refreshPresignedUrl={refreshPresignedUrl}
              />
            </div>
            <div>{uploadingStatus}</div>
          </div>
        </Col>

        <Col span={12}>
          <div
            className={`canvas-and-image-wrap ${
              getActiveAsset() && getActiveAsset().orientation ? getActiveAsset().orientation : ''
            }`}
          >
            {getActiveAsset() &&
              (getActiveAsset().type === 'image' ? (
                <div className="image-resources__body">
                  {!isZoomInModalVisible && (
                    <CanvasedImage
                      imgSrc={getActiveAsset().url_raw}
                      key={`${getActiveAsset().id}-${getActiveAsset().orientation}`}
                      rects={Object.fromEntries((getActiveAsset().rects || []).map((x) => [x.key, x]))}
                      onRectChange={handleRectChange}
                      isLandscape={getActiveAsset().orientation === 'landscape'}
                      isZoomIn={isZoomInModalVisible}
                      onZoomIn={() => setIsZoomInModalVisible(true)}
                    />
                  )}
                </div>
              ) : getActiveAsset().type === 'video' ? (
                <React.Fragment>
                  <Button.Group>
                    <InputNumber
                      placeholder="Confidence"
                      value={confidence}
                      onChange={(e) => {
                        confidenceChange(e);
                      }}
                      style={{ marginRight: '1rem' }}
                    />
                    <Button
                      onClick={handleBlurFaces}
                      disabled={
                        getActiveAsset().status &&
                        !moment(getActiveAsset().status_at).add(300, 'seconds').isBefore(moment()) &&
                        (getActiveAsset().status === 'beginning' || getActiveAsset().status.includes('fps'))
                      }
                    >
                      <Icon name="square" /> Blur faces
                    </Button>
                    {getActiveAsset().status}
                  </Button.Group>
                  <video src={getActiveAsset().url_annotated} controls width="360" height="640" />
                </React.Fragment>
              ) : getActiveAsset().type === 'html' ? (
                <iframe
                  src={getActiveAsset().url_raw}
                  style={{
                    width: '55rem',
                    minHeight: '40rem',
                  }}
                />
              ) : (
                'format is not supported'
              ))}

            {getActiveAsset() && getActiveAsset().type === 'image' && (
              <div className="canvased-img">
                <Button
                  onClick={handleApply}
                  style={{ margin: '7px 17px' }}
                  loading={isLoading}
                  disabled={!changedAsset}
                >
                  Apply
                </Button>
                {getActiveAsset().url_annotated && (
                  <Img
                    style={{
                      width: '90%',
                      display: 'block',
                      margin: '0 auto',
                    }}
                    src={getActiveAsset().url_annotated}
                    alt={`Asset ${getActiveAsset().id}`}
                    loader={<Spin />}
                  />
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};
