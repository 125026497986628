import { useState } from 'react';

export const useCopyToClipboard = (copyTimeout = 800) => {
  const [isCopied, setIsCopied] = useState(false);

  return {
    isCopied,
    copyToClipBoard(text) {
      if (isCopied) return;

      navigator.clipboard.writeText(text);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, copyTimeout);
    },
  };
};
