import { backendApiRequest } from './request';

export const addReleaseAnalysisIssue = async ({ releaseId, body }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/releases/${releaseId}/analysis-issues`,
    body,
  });

export const updReleaseAnalysisIssue = async ({ issueId, body }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/releases/analysis-issues/${issueId}`,
    body,
  });

export const delReleaseAnalysisIssue = async ({ issueId }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/releases/analysis-issues/${issueId}`,
  });
