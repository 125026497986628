import React from 'react';
import { Button, Divider, Icon, Popconfirm, Table } from 'antd';
import './Orchestrator.scss';

const OrchestratorsTable = ({ orchestrators, isLoading, onUpdOrchestrator, onDelOrchestrator }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10rem',
      render: (id, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '5px' }}>{id}</div>
          <Button type="small" icon="edit" title="Update product" onClick={() => onUpdOrchestrator(id)} />
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              onDelOrchestrator(id);
            }}
          >
            <Button icon="delete" type="danger small" title="Delete orchestrator" />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Host name',
      dataIndex: 'hostname',
      width: '30rem',
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '10rem',
    },
  ];
  return (
    <div className="main-wrapper">
      <Table
        className="table"
        dataSource={orchestrators}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
};

export default OrchestratorsTable;
