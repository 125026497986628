"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateInsight = exports.ParamsToUpdate = void 0;
var react_query_1 = require("react-query");
var insightsAPI_1 = require("api/insightsAPI");
var antd_1 = require("antd");
exports.ParamsToUpdate = {
    title: 'title',
    description: 'description',
    type: 'type',
    priority: 'priority',
    internalComment: 'internalComment',
    ab_test_id: 'ab_test_id',
    current_release_id: 'current_release_id',
    focus_area_id: 'focus_area_id',
};
var useUpdateInsight = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    var onSuccess = function (msg) { return function () {
        antd_1.message.success(msg ? msg : 'Insight updated successfully');
        queryClient.invalidateQueries(['Insights']);
        queryClient.invalidateQueries(['EditInsight']);
    }; };
    var updateInsightMutation = (0, react_query_1.useMutation)(insightsAPI_1.updInsightByID, {
        onSuccess: onSuccess(),
    });
    var addInsightLabelMutation = (0, react_query_1.useMutation)(insightsAPI_1.addInsightLabel, {
        onSuccess: onSuccess(),
    });
    var delInsightLabelMutation = (0, react_query_1.useMutation)(insightsAPI_1.delInsightLabel, {
        onSuccess: onSuccess(),
    });
    var addAssetMutation = (0, react_query_1.useMutation)(insightsAPI_1.addAssetToInsight, {
        onSuccess: onSuccess(),
    });
    var removeAssetMutation = (0, react_query_1.useMutation)(insightsAPI_1.removeAssetFromInsight, {
        onSuccess: onSuccess(),
    });
    var updateInsightAssetsOrderMutation = (0, react_query_1.useMutation)(insightsAPI_1.reOrderInsightsAssets, {
        onSuccess: onSuccess(),
    });
    var deleteInsightMutation = (0, react_query_1.useMutation)(insightsAPI_1.removeInsight, {
        onSuccess: onSuccess('Insight Deleted Successfully'),
    });
    var updateInsightParam = function (_a) {
        var insight_id = _a.insight_id, field = _a.field, value = _a.value;
        return __awaiter(void 0, void 0, void 0, function () {
            var body;
            var _b, _c;
            return __generator(this, function (_d) {
                body = field === exports.ParamsToUpdate.internalComment ? { content: (_b = {}, _b[field] = value, _b) } : (_c = {}, _c[field] = value, _c);
                return [2 /*return*/, updateInsightMutation.mutateAsync({
                        insight_id: insight_id,
                        data: body,
                    })];
            });
        });
    };
    var updateInsightLabel = function (_a) {
        var insightId = _a.insightId, labelId = _a.labelId, labelName = _a.labelName, action = _a.action;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                if (action === 'add') {
                    return [2 /*return*/, addInsightLabelMutation.mutateAsync({
                            insightId: insightId,
                            labelName: labelName,
                        })];
                }
                else {
                    return [2 /*return*/, delInsightLabelMutation.mutateAsync({
                            insightId: insightId,
                            labelId: labelId,
                        })];
                }
                return [2 /*return*/];
            });
        });
    };
    var addAssetsToInsight = function (_a) {
        var insight_id = _a.insight_id, asset = _a.asset;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, addAssetMutation.mutateAsync({ insight_id: insight_id, data: asset })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    var removeAssetsFromInsight = function (_a) {
        var insight_id = _a.insight_id, asset_id = _a.asset_id;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, removeAssetMutation.mutateAsync({
                            insight_id: insight_id,
                            asset_id: asset_id,
                        })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    var updateAssetsOrder = function (_a) {
        var assets = _a.assets;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, updateInsightAssetsOrderMutation.mutateAsync({ assets: assets })];
                    case 1: return [2 /*return*/, _b.sent()];
                }
            });
        });
    };
    var deleteInsight = function (insight_id) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteInsightMutation.mutateAsync({ insight_id: insight_id })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    }); };
    return {
        updateInsightParam: updateInsightParam,
        updateInsightLabel: updateInsightLabel,
        addAssetsToInsight: addAssetsToInsight,
        removeAssetsFromInsight: removeAssetsFromInsight,
        updateAssetsOrder: updateAssetsOrder,
        deleteInsight: deleteInsight,
    };
};
exports.useUpdateInsight = useUpdateInsight;
