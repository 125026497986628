/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Form, Input, Checkbox, Button } from 'antd';

class ChangePasswordForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const genPass = () => Math.random().toString(36).slice(-8);

    return (
      <Form onSubmit={this.handleSubmit} className="add-user-form">
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Please enter the user Password!',
              },
              {
                min: 6,
                message: 'Password must has at least 6 characters',
              },
            ],
          })(<Input placeholder="Password" id="password" autoComplete="off" />)}
        </Form.Item>
        <Button onClick={() => this.props.form.setFieldsValue({ password: genPass() })}>Generate password</Button>
        <Button
          onClick={() => {
            document.querySelector('#password').select();
            document.execCommand('copy');
          }}
        >
          Copy to clipboard
        </Button>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(ChangePasswordForm);

export default function ChangePasswordModal({ hide, userID, changePassword }) {
  let formRef = null;
  return (
    <Modal
      title={`Change password for user #${userID}`}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            changePassword(userID, values.password);
            hide();
          });
        }
      }}
      onCancel={() => {
        hide(false);
        formRef.props.form.resetFields();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
      />
    </Modal>
  );
}
