import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

// add UG
export const addUserGroups = (data) => ({
  type: AT.ADD_USER_GROUPS,
  payload: data,
});

export const addUserGroupsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'organization',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(addUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addUserGroupsFailed(error.message)),
  },
});

export const addUserGroupsSuccess = (data) => ({
  type: AT.ADD_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const addUserGroupsFailed = (errorStatus) => ({
  type: AT.ADD_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// Update UG
export const setUserGroups = (data) => ({
  type: AT.SET_USER_GROUPS,
  payload: data,
});

export const setUserGroupsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${data.id}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.SET_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setUserGroupsSuccess(body)),
  },
});

export const setUserGroupsSuccess = (data) => ({
  type: AT.SET_USER_GROUPS_SUCCESS,
  payload: { data },
});

// delete UG
export const deleteUserGroups = (org_id) => ({
  type: AT.DELETE_USER_GROUPS,
  payload: { org_id },
});

export const deleteUserGroupsAPI = (org_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DELETE_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(deleteUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(deleteUserGroupsFailed(error.message)),
  },
});

export const deleteUserGroupsSuccess = (data) => ({
  type: AT.DELETE_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const deleteUserGroupsFailed = (errorStatus) => ({
  type: AT.DELETE_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// Get all org perms

export const getAllUserGroupPerms = () => ({
  type: AT.GET_ALL_USER_GROUP_PERMS,
});

export const getAllUserGroupPermsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'organization/permissions',
    label: NETWORK_LABELS.GET_ALL_USER_GROUP_PERMS,
    onSuccess: (body, dispatch) => dispatch(getAllUserGroupPermsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllUserGroupPermsFailed(error.message)),
  },
});

export const getAllUserGroupPermsSuccess = (body) => ({
  type: AT.GET_ALL_USER_GROUP_PERMS_SUCCESS,
  payload: { perms: body.data },
});

export const getAllUserGroupPermsFailed = (errorStatus) => ({
  type: AT.GET_ALL_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// GET ALL USERS GROUP
export const getAllUserGroups = () => ({
  type: AT.GET_ALL_USER_GROUPS,
});

export const getAllUserGroupsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'organization',
    label: NETWORK_LABELS.GET_ALL_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(getAllUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllUserGroupsFailed(error.message)),
  },
});

export const getAllUserGroupsSuccess = (data) => ({
  type: AT.GET_ALL_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const getAllUserGroupsFailed = (errorStatus) => ({
  type: AT.GET_ALL_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// SET_PERMISSION
export const setPermUserGroups = (data) => ({
  type: AT.SET_PERM_USER_GROUPS,
  payload: data,
});

export const setPermUserGroupsAPI = (org_id, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}/permissions`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.SET_PERM_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setPermUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(setPermUserGroupsFailed(error.message)),
  },
});

export const setPermUserGroupsSuccess = (data) => ({
  type: AT.SET_PERM_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const setPermUserGroupsFailed = (errorStatus) => ({
  type: AT.SET_PERM_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// SET_DOMAINS
export const setDomainUserGroups = (data) => ({
  type: AT.SET_DOMAIN_USER_GROUPS,
  payload: data,
});

export const setDomainUserGroupsAPI = (org_id, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}/domains`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.SET_DOMAIN_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setDomainUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(setDomainUserGroupsFailed(error.message)),
  },
});

export const setDomainUserGroupsSuccess = (data) => ({
  type: AT.SET_DOMAIN_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const setDomainUserGroupsFailed = (errorStatus) => ({
  type: AT.SET_DOMAIN_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// Set orchestrators
export const setOrchestratorUserGroups = (data) => ({
  type: AT.SET_ORCHESTRATOR_USER_GROUPS,
  payload: data,
});

export const setOrchestratorUserGroupsAPI = (org_id, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}/orchestrators`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.SET_LOC_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setOrchestratorUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(setOrchestratorUserGroupsFailed(error.message)),
  },
});

export const setOrchestratorUserGroupsSuccess = (data) => ({
  type: AT.SET_ORCHESTRATOR_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const setOrchestratorUserGroupsFailed = (errorStatus) => ({
  type: AT.SET_ORCHESTRATOR_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

// Set apps
export const setAppUserGroups = (data) => ({
  type: AT.SET_APP_USER_GROUPS,
  payload: data,
});

export const setAppUserGroupsAPI = (org_id, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}/apps`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.SET_APP_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setAppUserGroupsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(setAppUserGroupsFailed(error.message)),
  },
});
export const setAppUserGroupsSuccess = (data) => ({
  type: AT.SET_APP_USER_GROUPS_SUCCESS,
  payload: { data },
});

export const setAppUserGroupsFailed = (errorStatus) => ({
  type: AT.SET_APP_USER_GROUPS_FAILED,
  payload: { errorStatus },
});

export const getOrgAppsMissingGroups = (org_id) => ({
  type: AT.SET_APP_USER_MISSING_GROUPS,
  payload: { org_id },
});

export const getOrgAppsMissingGroupsAPI = (org_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${org_id}/apps/missing`,
    label: NETWORK_LABELS.SET_APP_USER_GROUPS,
    onSuccess: (body, dispatch) => dispatch(setAppMissingUserGroupsSuccess({ body, org_id })),
    // onFailure: (error, dispatch) => dispatch(setAppUserGroupsFailed(error.message)),
  },
});
export const setAppMissingUserGroupsSuccess = (data) => ({
  type: AT.SET_APP_USER_MISSING_GROUPS_SUCCESS,
  payload: { data },
});

// Add/remove products to/from organization

export const addProductToUserGroup = (organizationId, productId) => ({
  type: AT.ADD_PRODUCT_TO_USER_GROUP,
  payload: {
    organizationId,
    productId,
  },
});

export const addProductToUserGroupAPI = (organizationId, productId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${organizationId}/products`,
    method: 'POST',
    data: { product_id: productId },
    label: NETWORK_LABELS.ADD_PRODUCT_TO_USER_GROUP,
    onSuccess: (body, dispatch) => dispatch(addProductToUserGroupSuccess(body)),
    onFailure: (error, dispatch) => dispatch(userGroupApiRequestFailed(error)),
  },
});
export const addProductToUserGroupSuccess = (data) => ({
  type: AT.ADD_PRODUCT_TO_USER_GROUP_SUCCESS,
  payload: data.data,
});

export const delProductFromUserGroup = (organizationId, productId) => ({
  type: AT.DEL_PRODUCT_FROM_USER_GROUP,
  payload: {
    organizationId,
    productId,
  },
});

export const delProductFromUserGroupAPI = (organizationId, productId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `organization/${organizationId}/products/${productId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_PRODUCT_FROM_USER_GROUP,
    onSuccess: (body, dispatch) => dispatch(delProductFromUserGroupSuccess(body)),
    onFailure: (error, dispatch) => dispatch(userGroupApiRequestFailed(error)),
  },
});
export const delProductFromUserGroupSuccess = (data) => ({
  type: AT.DEL_PRODUCT_FROM_USER_GROUP_SUCCESS,
  payload: data.data,
});

export const userGroupApiRequestFailed = (error) => ({
  type: AT.USER_GROUPS_PRODUCTS_API_REQUEST_FAILED,
  payload: { error },
});
