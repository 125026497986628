import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import { connect } from 'react-redux';
import { Alert, Button } from 'antd';
import ReportsTable from './ReportsTable';
import {
  getTags,
  getReports,
  addReport,
  updReport,
  delReport,
  addReportTag,
  delReportTag,
  addOrgReport,
  delOrgReport,
  updReportSuccess,
} from '../../redux/actions/reports';
import ReportsModal from './ReportsModal';
import { getAllUserGroups } from '../../redux/actions/userGroupsPage';
import { addReportProduct, delReportProduct, getReportEmailContent } from '../../api/reportsAPI';
import { getProducts } from '../../api/productsAPI';

const Reports = ({
  reports,
  getReports,
  addReport,
  updReport,
  delReport,
  addReportTag,
  delReportTag,
  tags,
  organizations,
  getAllUserGroups,
  addOrgReport,
  delOrgReport,
  getTags,
  isLoading,
  error,
  updReportSuccess,
}) => {
  const [isReportModalVisible, setIsReportModalVisible] = useState(false);
  const [curEditReportId, setCurEditReportId] = useState(null);

  const emailContentMutation = useMutation((reportId) => getReportEmailContent({ reportId }), {
    onSuccess: (data) =>
      window.open('').document.write(data.data.content.replace('<head>', `<head><title>${data.data.subject}</title>`)),
  });
  const productsQuery = useQuery(['products'], () => getProducts());
  const addReportProductMutation = useMutation(addReportProduct, {
    onSuccess: (data, { reportId }) => {
      const report = reports.find((x) => x.id === reportId);
      updReportSuccess({ data: { ...report, products: [...report.products, data.data.product] } });
    },
  });
  const delReportProductMutation = useMutation(delReportProduct, {
    onSuccess: (data, { reportId, productId }) => {
      const report = reports.find((x) => x.id === reportId);
      updReportSuccess({ data: { ...report, products: report.products.filter((x) => x.id !== productId) } });
    },
  });
  useEffect(() => {
    getAllUserGroups();
    getTags();
    getReports();
  }, []);

  const handleUpdTags = (report, tags) => {
    report.tags.filter((x) => !tags.includes(x.name)).forEach((x) => delReportTag(report.id, x.id));
    tags
      .filter((y) => !report.tags.map((x) => x.name).includes(y))
      .forEach((x) => addReportTag(report.id, { tag_name: x }));
  };

  const handleUpdOrgReports = (report, orgIds) => {
    const curOrgIds = report.orgs.map((x) => x.id);
    const toAdd = orgIds.includes('all')
      ? organizations.filter((x) => !curOrgIds.includes(x.id)).map((x) => x.id)
      : orgIds.filter((x) => !curOrgIds.includes(x));
    if (toAdd.length) {
      addOrgReport(report.id, { organization_ids: toAdd });
    }
    curOrgIds.filter((x) => !orgIds.includes(x)).forEach((x) => delOrgReport(report.id, x));
  };

  const isCommonLoading = [
    isLoading,
    productsQuery.isLoading,
    addReportProductMutation.isLoading,
    delReportProductMutation.isLoading,
  ].some((x) => x);

  return (
    <React.Fragment>
      {error && (
        <Alert
          message={error.message}
          description={error.response.body && (error.response.body.error || JSON.stringify(error.response.body))}
          type="error"
          showIcon
        />
      )}
      {isReportModalVisible && (
        <ReportsModal
          report={reports.find((x) => x.id === curEditReportId)}
          onHide={() => {
            setIsReportModalVisible(false);
            setCurEditReportId(null);
          }}
          onAddReport={addReport}
          onUpdReport={updReport}
        />
      )}
      <Button
        onClick={() => setIsReportModalVisible(true)}
        type="primary"
        style={{ margin: 16 }}
        loading={isCommonLoading}
      >
        Add report
      </Button>
      <ReportsTable
        isLoading={isCommonLoading}
        reports={reports}
        tags={tags}
        organizations={organizations}
        products={productsQuery.isFetched ? productsQuery.data.data : []}
        onDelReport={delReport}
        onUpdReport={(reportId) => {
          setCurEditReportId(reportId);
          setIsReportModalVisible(true);
        }}
        onUpdTags={handleUpdTags}
        onUpdOrgReports={handleUpdOrgReports}
        updReport={updReport}
        previewEmailContent={(reportId) => emailContentMutation.mutateAsync(reportId)}
        delProduct={(reportId, productId) => delReportProductMutation.mutateAsync({ reportId, productId })}
        addProduct={(reportId, productId) => addReportProductMutation.mutateAsync({ reportId, productId })}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  getReports,
  getTags,
  addReport,
  updReport,
  delReport,
  addReportTag,
  delReportTag,
  getAllUserGroups,
  addOrgReport,
  delOrgReport,
  updReportSuccess,
};

const mapStateToProps = ({ reportsPage, userGroupsPage }) => ({
  isLoading: reportsPage.isLoading,
  error: reportsPage.error,
  reports: reportsPage.reports,
  tags: reportsPage.tags,
  organizations: userGroupsPage.allUserGroups,
});

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
