import { Auth } from 'aws-amplify';
import { swipe2swipe_data } from '../components/GraphBuilder/helpers';

export const getGraphProducts = async () => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(`${API_HOST}/admin/products?is_installed=1`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const q = await res.json();
  return q.data;
};

export const getGraphProductData = async (id) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(`${API_HOST}/admin/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const q = await res.json();
  return q.data;
};

export const getGraphActionsData = async (graph_id) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(`${API_HOST}/backoffice/graph/${graph_id}/actions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const q = await res.json();
  const actions = q.data;
  actions.relationships.forEach((edge, index, relations) => {
    relations[index] = {
      ...edge,
      swipe_data: swipe2swipe_data(edge.x, edge.x_offset, edge.y, edge.y_offset, edge.swipe_duration),
    };
  });
  return actions;
};

export const getDeviceDataFromFarmer = async (url, farmer_device_id) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  const res = await fetch(`${url}/devices?id=eq.${farmer_device_id}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const q = await res.json();
  return q[0];
};

export const getEnabledDevicesFromFarmer = async (url) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  const res = await fetch(`${url}/devices?is_enabled=eq.true`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  const q = await res.json();

  return q;
};

export const allocateDeviceForUser = async (url, deviceID, allocate) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  const cognitoUserID = currentAWSSession.idToken.payload.sub;

  const res = await fetch(`${url}/devices?id=eq.${deviceID}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      Prefer: `return=representation`,
    },
    body: JSON.stringify({ id: deviceID, allocated_to: allocate ? cognitoUserID : null }),
  });
  const q = await res.json();

  return q;
};

export const sendDataToDeviceOnFramer = async (url, method, device, data = {}, recordContext) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;

  const path = `rpc/cmd`;

  // debugger

  const res = await fetch(`${url}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      load: {
        method,
        devId: device.farm_device_id,
        args: data,
        recordContext,
        recordContextSync: true,
      },
    }),
  });
  const q = await res.json();

  return q;
};

export const executeRunOnFramer = async (device, data = {}, inputType) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  const path = `rpc/asl_player_cmd`;

  const res = await fetch(`${device.api_url}/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      load: {
        devId: device.farm_device_id,
        instruction: { ...data, input_type: inputType },
        cmd: 'playInstruction',
      },
    }),
  });
  const q = await res.json();
  return q;
};
