import React, { useState } from 'react';
import { Modal, Form, Input, Upload, Button, Checkbox, Row, Col } from 'antd';
import { useMutation } from 'react-query';
import { getPresignedUrl } from '../../api/reportsAPI';

const DEFAULT_EMAIL_SUBJECT_TPL = 'A new Watchful {type} is now available';

class ReportForm extends React.Component {
  render() {
    const { form, report, presignedUrl, getPresignedUrlMutation, isUploading, setIsUploading } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const emailSubject = DEFAULT_EMAIL_SUBJECT_TPL.replace(
      '{type}',
      getFieldValue('is_newsletter') ? 'newsletter' : 'report'
    );
    const beforeUpload = async (file) => {
      setIsUploading(true);
      await getPresignedUrlMutation.mutateAsync(file.name);
    };
    const onUpload = (info) => {
      info.file.url = presignedUrl.url;
      if (['success', 'done'].includes(info.file.status)) {
        setIsUploading(false);
      }
    };
    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Name">
              {getFieldDecorator('name', {
                rules: [
                  {
                    required: true,
                    max: 200,
                  },
                ],
                initialValue: report && report.name,
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Email Subject">
              {getFieldDecorator('email_subject', {
                rules: [{ max: 200 }],
                initialValue: emailSubject,
              })(<Input placeholder="Email Subject" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Report File">
              {getFieldDecorator('file', {
                rules: [{ required: !report }],
              })(
                <Upload
                  name="file"
                  multiple={false}
                  listType="text"
                  accept="*"
                  beforeUpload={beforeUpload}
                  action={() => presignedUrl.s3_data.url}
                  data={() => presignedUrl.s3_data.fields}
                  onChange={onUpload}
                  disabled={isUploading}
                >
                  <Button icon="upload">Click to upload</Button>
                  <div>{getPresignedUrlMutation.isLoading && 'Requesting resigned url...'}</div>
                  <div>
                    {getPresignedUrlMutation.isError && `Error: ${JSON.stringify(getPresignedUrlMutation.error)}`}
                  </div>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        {getFieldValue('file') && !getFieldValue('file').file.name.endsWith('.pdf') && (
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Form.Item label="Preview File (PDF)">
                {getFieldDecorator('preview_file', {
                  rules: [{ required: true }],
                })(
                  <Upload
                    name="file"
                    multiple={false}
                    listType="text"
                    accept=".pdf"
                    beforeUpload={beforeUpload}
                    action={() => presignedUrl.s3_data.url}
                    data={() => presignedUrl.s3_data.fields}
                    onChange={onUpload}
                    disabled={isUploading}
                  >
                    <Button icon="upload">Click to upload</Button>
                  </Upload>
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: report ? report.description : '',
              })(<Input.TextArea />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Screenshot">
              {getFieldDecorator('screenshot_file', {
                rules: [{ required: false }],
              })(
                <Upload
                  name="file"
                  multiple={false}
                  listType="text"
                  accept="*"
                  beforeUpload={beforeUpload}
                  action={() => presignedUrl.s3_data.url}
                  data={() => presignedUrl.s3_data.fields}
                  onChange={onUpload}
                  disabled={isUploading}
                >
                  <Button icon="upload">Click to upload</Button>
                  <div>{getPresignedUrlMutation.isLoading && 'Requesting resigned url...'}</div>
                  <div>
                    {getPresignedUrlMutation.isError && `Error: ${JSON.stringify(getPresignedUrlMutation.error)}`}
                  </div>
                </Upload>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('is_visible', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: report ? report.is_visible : true,
              })(<Checkbox>Is Visible</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('is_newsletter', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: report ? report.is_newsletter : false,
              })(<Checkbox>Is Newsletter</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('send_email', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: report ? report.send_email : false,
              })(<Checkbox>Send Email & Slack</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(ReportForm);

export default ({ report, onAddReport, onUpdReport, onHide }) => {
  let formRef = null;

  const [presignedUrl, setPresignedUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const getPresignedUrlMutation = useMutation(getPresignedUrl, {
    onSuccess: (data) => setPresignedUrl(data.data),
  });

  return (
    <Modal
      title={report ? `Change report #${report.id}` : 'Add new report'}
      centered
      visible
      okButtonProps={{ disabled: isUploading }}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            let submitData = {
              ...values,
            };
            if (values.file && values.file.fileList.length) {
              submitData = {
                ...submitData,
                url: values.file.fileList[0].url,
                size_bytes: values.file.fileList[0].originFileObj.size,
              };
            }
            if (values.screenshot_file && values.screenshot_file.fileList.length) {
              submitData = {
                ...submitData,
                screenshot_url: values.screenshot_file.fileList[0].url,
              };
            }
            submitData = {
              ...submitData,
              preview_url:
                values.preview_file && values.preview_file.fileList.length
                  ? values.preview_file.fileList[0].url
                  : submitData.url,
              file: undefined,
              preview_file: undefined,
              screenshot_file: undefined,
            };
            (report ? (data) => onUpdReport(report.id, data) : onAddReport)(submitData);
            formRef.props.form.resetFields();
            onHide();
          });
        }
      }}
      onCancel={() => {
        onHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        report={report}
        presignedUrl={presignedUrl}
        getPresignedUrlMutation={getPresignedUrlMutation}
        isUploading={isUploading}
        setIsUploading={setIsUploading}
      />
    </Modal>
  );
};
