import { Button, Icon, Input } from 'antd';
import React from 'react';

export const fixURLforAvoidWebPformat = (url) => {
  if (url.indexOf('googleusercontent.com') !== -1 && url.endsWith('-rw')) {
    return url.substr(0, url.length - 3);
  }
  return url;
};

export const getDashboardURL = () => {
  const { location } = window;
  return location.origin.replace('manager', 'app').replace('localhost:3020', 'localhost:3000');
};

export const getColumnSearchProps = (dataIndex, handleSearch, handleReset) => {
  let searchInput = null;
  return {
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      // get nested value from Object by key like 'key1.sub1.sub2'
      const recValue = dataIndex.split('.').reduce((acc, cur) => acc[cur], record);
      return recValue ? recValue.toString().toLowerCase().includes(value.toLowerCase()) : false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  };
};
