import { Auth } from 'aws-amplify';
import { backendApiRequest } from './request';

export const getAppsWithDiffDocuments = async (platform, packageName) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef

  //
  const res = await fetch(
    `https://acvjziihrekoyziys26tkwnb2i0mqccj.lambda-url.us-east-1.on.aws/?authorization=${token}&method=getProductsWithGenericDocs`,
    {
      headers: {
        // Authorization: `Bearer ${token}`
      },
    }
  );
  const q = await res.json();
  return q.response;
};

export const getNewApp = async (url) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(
    `https://acvjziihrekoyziys26tkwnb2i0mqccj.lambda-url.us-east-1.on.aws/?authorization=${token}&method=analyze&url=${url}`,
    {
      headers: {
        // Authorization: `Bearer ${token}`
      },
    }
  );
  const q = await res.json();
  return q.response;
};

export const getVersionsForApp = async (platform, packageName) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(
    `https://acvjziihrekoyziys26tkwnb2i0mqccj.lambda-url.us-east-1.on.aws/?authorization=${token}&method=getAllVersionsWithDocs&platform=${platform}&packageName=${packageName}`,
    {
      headers: {
        // Authorization: `Bearer ${token}`
      },
    }
  );
  const q = await res.json();
  return q.response;
};

export const getDiffDataForVersion = async (ver1guid, ver2guid) => {
  const currentAWSSession = await Auth.currentSession();
  const token = currentAWSSession.idToken.jwtToken;
  // eslint-disable-next-line no-undef
  const res = await fetch(
    `https://acvjziihrekoyziys26tkwnb2i0mqccj.lambda-url.us-east-1.on.aws/?authorization=${token}&method=getDiffDoc&guid1=${ver1guid}&guid2=${ver2guid}`,
    {
      headers: {
        // Authorization: `Bearer ${token}`
      },
    }
  );
  const q = await res.json();
  return q.response;
};

export const getPresignedUrl = async (filename) =>
  backendApiRequest({
    method: 'GET',
    path: '/admin/apps/presigned-url',
    params: { filename },
  });
