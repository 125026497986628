export const PLATFORMS = {
  android: 'Android',
  ios: 'iOS',
  web: 'Web',
  marketing: 'Marketing',
  chrome_extension: 'Chrome Extension',
  windows: 'Windows',
};
export const DEFAULT_PLATFORM = 'android';
export const ALL_PLATFORMS = '_all';
