import React, { useState, useEffect } from 'react';
import { Alert, Button, message } from 'antd';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getTimelineForInsight,
  createTimelineRecForInsight,
  setTimelineRecForInsight,
  remTimelineRecForInsight,
} from '../../../../api/insightsAPI';

import TimelineTable from './TimelineTable';
import TimelineModal from './TimelineModal';

const InsightTimeLine = ({ insight }) => {
  const [timeline, timelineChange] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditTimelineId, setCurEditTimelineId] = useState(null);

  const { data, isLoading, error, status } = useQuery(['InsightTimeline', insight ? insight.id : null], () =>
    getTimelineForInsight(insight.id)
  );
  useEffect(() => {
    if (data && data.data) {
      timelineChange(data.data);
    }
  }, [status]);

  const addTimeLineMutation = useMutation(createTimelineRecForInsight, {
    onSuccess: (data) => {
      timelineChange([...timeline, data.data]);
      message.destroy();
      message.success('Timeline was added');
    },
  });
  const updTimelineMutation = useMutation(setTimelineRecForInsight, {
    onSuccess: (data, variables) => {
      timelineChange(timeline.map((x) => (x.id !== variables.timeRecID ? x : data.data)));
      message.destroy();
      message.success('Timeline was updated');
    },
  });
  const remTimelineMutation = useMutation(remTimelineRecForInsight, {
    onSuccess: (data, timeRecID) => {
      timelineChange(timeline.filter((x) => x.id !== timeRecID));
      message.destroy();
      message.success('Timeline was deleted');
    },
  });

  const addTimeline = (data) => {
    addTimeLineMutation.mutateAsync({
      insight_id: insight.id,
      data,
    });
  };
  const updTimeline = (id, data) => {
    updTimelineMutation.mutateAsync({
      timeRecID: id,
      data,
    });
  };

  const delTimeline = (timeRecID) => {
    remTimelineMutation.mutateAsync(timeRecID);
  };

  return (
    <div className="insight-manage-timeline">
      <h3 className="insight-data-item-title">Timeline</h3>
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        className="add-something"
        icon="plus"
        style={{ marginBottom: 16 }}
        loading={isLoading}
      >
        Add Timeline
      </Button>
      {error && (
        <Alert
          message={error.message}
          description={JSON.stringify(
            (error.response && error.response.body && error.response.body.error) || error.response.body.validation_error
          )}
          type="error"
          showIcon
        />
      )}
      {isModalVisible && (
        <TimelineModal
          timeline={timeline.find((x) => x.id === curEditTimelineId)}
          onHide={() => {
            setIsModalVisible(false);
            setCurEditTimelineId(null);
          }}
          onAddTimeline={addTimeline}
          onUpdTimeline={updTimeline}
        />
      )}
      <TimelineTable
        isLoading={isLoading}
        timeline={[...timeline].sort((a, b) => a.day - b.day)}
        onUpdTimeline={(timelineId) => {
          setIsModalVisible(true);
          setCurEditTimelineId(timelineId);
        }}
        onDelTimeline={delTimeline}
      />
    </div>
  );
};

export default InsightTimeLine;
