import React, { useState, useRef, useCallback } from 'react';
import { Button } from 'antd';

import Graph from 'react-graph-vis';
import { createInsight } from './createInsight/createInsight';
import CreateInsightModal from './createInsight/CreateInsightModal';
import CreateGraphReport from './createReportModal';
import { debounce } from 'lodash';
import moment from 'moment';

const options = {
  layout: {
    hierarchical: false,
  },
  autoResize: false,
  edges: {
    color: '#000000',
  },
  height: '450px',
  width: '1000px',

  physics: {
    enabled: false,
  },
};

export default function NetworkGraph({
  app,
  graphData,
  graphDataChange,
  phashDiffQ,
  phashDiffQChange,
  graphKey,
  graphKeyChange,
  screenIndexChange,
  screenList,
  createScreenChangeInsight,
  labelsSuggestions,
  graphNetwork,
  createComplaintHandle,
  deleteComplaintHandle,
  complaints,
  insightsByNodeID,
}) {
  const [editNode, editNodeChange] = useState(null);
  const [createInsightModalVisible, createInsightModalVisibleChange] = useState(false);
  const [showReportModal, showReportModalChange] = useState(false);

  const [key, keyChange] = useState(1);

  const events = {
    selectNode: (event) => {
      const node = graphData.nodes.find((n) => n.id === Number(event.nodes[0]));
      screenIndexChange(screenList.findIndex((s) => s === node.label.split(' ')[2]));
      console.log(node);
      editNodeChange(node);
    },
    deselectNode: () => {
      editNodeChange(null);
    },
  };
  const handleSaveView = () => {
    const nodeNames = {};
    graphData.nodes.forEach((n) => {
      nodeNames[String(n.id)] = n.label;
    });
    const saveData = {
      nodeCoords: graphNetwork.current.getPositions(),
      scale: graphNetwork.current.getScale(),
      viewPosition: graphNetwork.current.getViewPosition(),
      nodeNames,
    };
    window.localStorage.setItem(app.app.package_name, JSON.stringify(saveData));
  };

  const handleRepaintNetwork = () => {
    window.localStorage.removeItem(app.app.package_name);
    options.layout.randomSeed = undefined;
    graphDataChange({
      nodes: graphData.nodes.map((n) => ({ ...n, x: undefined, y: undefined })),
      edges: graphData.edges,
    });

    keyChange(new Date().getTime());
  };

  const handleEditNode = (ev) => {
    graphDataChange({
      nodes: graphData.nodes.map((n) =>
        n.id === editNode.id ? { ...n, ...graphNetwork.current.getPosition(editNode.id), label: ev.target.value } : n
      ),
      edges: graphData.edges,
    });
  };

  const handleQDiffChange = () => {
    graphKeyChange(new Date().getTime());
    console.log('UPDATE');
  };
  const debouncedHandleQDiffChange = useCallback(debounce(handleQDiffChange, 300), []);

  const filteredInsights = insightsByNodeID
    .filter((ins) => !(editNode && ins.node_id !== Number(editNode.id)))
    .map((insByNode) => insByNode.insights)
    .flat()
    .sort((a, b) => moment(a.created).isBefore(moment(b.created) ? -1 : 1));

  return (
    <div className="graph-comp-wrapper">
      {app && app.persona && (
        <div className="persona-data">
          {['description', 'gender', 'location', 'title'].map((k) => (
            <div className="persona-data-cell" key={k}>
              <div className="person-data-title">{k}:</div>
              <div className="person-data-value">{app.persona[k]}</div>
            </div>
          ))}
        </div>
      )}
      {app && app.device && (
        <div className="device-data">
          {Object.keys(app.device).map((k) => (
            <div className="device-data-cell" key={k}>
              <div className="device-data-title">{k}:</div>
              <div className="device-data-value">{app.device[k]}</div>
            </div>
          ))}
        </div>
      )}

      {graphData && (
        <div className="network-graph-wrapper">
          <div className="network-graph-manage">
            <Button onClick={handleRepaintNetwork}>Repaint</Button>
            {/* <Button onClick={() => { graphKeyChange((new Date()).getTime()); }}>Load View</Button>
        <Button onClick={handleSaveView}>Save View</Button> */}
            Q diff images -{' '}
            <input
              type="range"
              className="slider-q-phash_diff"
              value={phashDiffQ}
              onChange={(e) => {
                phashDiffQChange(e.target.value);
                handleSaveView();
                options.layout.randomSeed = gN.current.getSeed();
                debouncedHandleQDiffChange(e.target.value);
              }}
              min={2}
              max={20}
            />{' '}
            {phashDiffQ}
            {/* {editNode && <input key={editNode.id} defaultValue={editNode.label} onChange={handleEditNode} />} */}
            {editNode && (
              <Button
                className="create-insight-btn"
                onClick={() => createInsightModalVisibleChange(true)}
                type="primary"
                {...(!editNode ? { title: 'Please choose node to create Insight' } : {})}
              >
                Create insight
              </Button>
            )}
            <Button className="create-report-btn" onClick={() => showReportModalChange(true)}>
              Complaints
            </Button>
          </div>

          <div className="network-graph" style={{ width: '1000px' }}>
            <Graph
              key={key}
              graph={graphData}
              options={options}
              events={events}
              getNetwork={(network) => {
                graphNetwork.current = network;
                if (app && localStorage.getItem(app.app.package_name)) {
                  const initCoords = JSON.parse(localStorage.getItem(app.app.package_name));
                  network.moveTo({ position: initCoords.viewPosition, scale: initCoords.scale });
                }
              }}
            />
          </div>
          <div className="graph-insights">
            <h3>
              Related insights{' '}
              {editNode
                ? `to node ${
                    graphData.nodes.find((node) => node.id === Number(editNode.id))
                      ? graphData.nodes.find((node) => node.id === Number(editNode.id)).label
                      : ''
                  }`
                : ''}
            </h3>
            {filteredInsights &&
              filteredInsights.map((ins, i) => (
                <div className="rel-insight-to-graph" key={i}>
                  <div>{ins.id}</div>
                  <div>{ins.type}</div>
                  <div>{moment(ins.created).format('ll')}</div>
                  {app && (
                    <a
                      href={`#/insights/${app.app.display_name}/${app.app.package_name}/${app.app.platform}/${ins.id}`}
                      target="_blank"
                    >
                      {ins.title}
                    </a>
                  )}
                </div>
              ))}
            {!filteredInsights && <div>—</div>}
          </div>
          {showReportModal && (
            <CreateGraphReport
              onHide={() => showReportModalChange(false)}
              complaints={complaints}
              createReport={(values) => {
                createComplaintHandle(values);
              }}
              deleteComplaintHandle={deleteComplaintHandle}
            />
          )}
          {createInsightModalVisible && (
            <CreateInsightModal
              labelsSuggestions={labelsSuggestions}
              onHide={() => {
                createInsightModalVisibleChange(false);
              }}
              onCreateInsight={(values) => {
                createInsight(createScreenChangeInsight, app, editNode.id, values);
                createInsightModalVisibleChange(false);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}
