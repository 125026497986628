import { getAllProductsAPI, addNewProductAPI, deleteProductAPI, updateProductAPI } from '../actions/products';
import * as AT from '../actionTypes';

const productsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    let product = null;
    switch (action.type) {
      case AT.GET_ALL_PRODUCTS:
        dispatch(getAllProductsAPI());
        return next(action);

      case AT.ADD_NEW_PRODUCT:
        // eslint-disable-next-line prefer-destructuring
        product = action.payload.product;
        dispatch(addNewProductAPI(product));
        return next(action);
      case AT.DELETE_PRODUCT:
        const { productID } = action.payload;
        dispatch(deleteProductAPI(productID));
        return next(action);
      case AT.UPDATE_PRODUCT:
        // eslint-disable-next-line prefer-destructuring
        product = action.payload.product;
        dispatch(updateProductAPI(product));
        return next(action);

      default:
        return next(action);
    }
  };

export default productsMiddleware;
