import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import DevicesTable from './Table';
import DeviceModal from './Modal';
import ReactJson from 'react-json-view';

export default ({ isLoading, devices, deviceModels, addDevice, updDevice, delDevice, orchestrators }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditDeviceId, setCurEditDeviceId] = useState(null);
  const [curJsonModalDeviceId, setCurJsonModalDeviceId] = useState(null);

  const handleUpdDevice = (deviceId) => {
    setCurEditDeviceId(deviceId);
    setIsModalVisible(true);
  };

  return (
    <React.Fragment>
      {isModalVisible && (
        <DeviceModal
          device={devices.find((x) => x.id === curEditDeviceId)}
          deviceModels={deviceModels}
          handleHide={() => {
            setIsModalVisible(false);
            setCurEditDeviceId(null);
          }}
          addSubmit={addDevice}
          updSubmit={updDevice}
          orchestrators={orchestrators}
        />
      )}
      {curJsonModalDeviceId && (
        <Modal
          title={`Farm device state #${curJsonModalDeviceId}`}
          visible
          centered
          width="60%"
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={() => setCurJsonModalDeviceId(null)}
        >
          <ReactJson
            src={
              devices.map((x) => x.id).includes(curJsonModalDeviceId) &&
              devices.find((x) => x.id === curJsonModalDeviceId).farm_device_state_body
            }
            collapsed
          />
        </Modal>
      )}
      <Button onClick={() => setIsModalVisible(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        Add Device
      </Button>
      <DevicesTable
        devices={devices}
        isLoading={isLoading}
        onUpdDevice={handleUpdDevice}
        onDelDevice={delDevice}
        orchestrators={orchestrators}
        updDevice={updDevice}
        showFarmDeviceStateModal={setCurJsonModalDeviceId}
      />
    </React.Fragment>
  );
};
