import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import TimelineTable from './TimelineTable';
import TimelineModal from './TimelineModal';

export default ({ timeline, isLoading, error, addTimeline, updTimeline, delTimeline }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditTimelineId, setCurEditTimelineId] = useState(null);

  return (
    <div>
      {error && (
        <Alert
          message={error.message}
          description={JSON.stringify(
            (error.response && error.response.body && error.response.body.error) || error.response.body.validation_error
          )}
          type="error"
          showIcon
        />
      )}
      {isModalVisible && (
        <TimelineModal
          timeline={timeline.find((x) => x.id === curEditTimelineId)}
          onHide={() => {
            setIsModalVisible(false);
            setCurEditTimelineId(null);
          }}
          onAddTimeline={addTimeline}
          onUpdTimeline={updTimeline}
        />
      )}
      <TimelineTable
        isLoading={isLoading}
        timeline={[...timeline].sort((a, b) => a.day - b.day)}
        onUpdTimeline={(timelineId) => {
          setIsModalVisible(true);
          setCurEditTimelineId(timelineId);
        }}
        onDelTimeline={delTimeline}
      />
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        type="primary"
        style={{ margin: 16 }}
        loading={isLoading}
      >
        Add Timeline
      </Button>
    </div>
  );
};
