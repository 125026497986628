import { Drawer, Timeline } from 'antd';
import React from 'react';
import { FRAMES_SERVICE_VERSION } from '../../../utils/consts';
import './UpdatesDrawer.scss';

export const UpdatesDrawer = ({ updates = [], ...rest }) => (
  <Drawer width={640} placement="left" {...rest}>
    <div className="updates-container">
      <h2 className="updates-header">Updates Log</h2>
      <h3>Current BE Version: {FRAMES_SERVICE_VERSION}</h3>
      <Timeline className="updates-timeline">
        {updates.map(({ date, version, changes }) => (
          <Timeline.Item key={date}>
            <h4 className="updates-item-header">
              Date: {new Date(date).toLocaleDateString()} | Version: {version}
            </h4>
            {changes.map((change) => (
              <p className="updates-item-change" key={change}>
                - {change}
              </p>
            ))}
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  </Drawer>
);
