import React from 'react';
import { Modal } from 'antd';
import InsightAssets from '../assets/InsightAssets';

export default ({
  isLoading,
  uploadingStatus,
  error,
  variants,
  variant,
  handleHide,
  addVariantAsset,
  updVariantAsset,
  delVariantAsset,
  setInsightThumbnail,
  presignedUrl,
  refreshPresignedUrl,
}) => (
  <Modal
    title={`Variant #${variant.id}`}
    centered
    visible
    width="90%"
    height="70%"
    footer={null}
    onCancel={() => {
      handleHide();
    }}
  >
    <InsightAssets
      isLoading={isLoading}
      error={error}
      uploadingStatus={uploadingStatus}
      assets={variant.assets}
      addAsset={(data) => addVariantAsset(variant.id, data)}
      updAsset={updVariantAsset}
      delAsset={(assetId) => delVariantAsset(variant.id, assetId)}
      setInsightThumbnail={setInsightThumbnail}
      variants={variants}
      presignedUrl={presignedUrl}
      refreshPresignedUrl={refreshPresignedUrl}
      insightType="ab_test"
    />
  </Modal>
);
