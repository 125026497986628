import { Select } from 'antd';
import React, { useMemo, useState } from 'react';

const MAX_APPS_TO_SHOW = 30;

export const AppSelect = ({ curApp, apps, onSelect }) => {
  const [search, setSearch] = useState('');
  const filteredApps = useMemo(
    () =>
      apps
        .filter(
          (app) =>
            app.name.toLowerCase().includes(search.toLowerCase()) ||
            app.package.toLowerCase().includes(search.toLowerCase())
        )
        .slice(0, MAX_APPS_TO_SHOW),
    [search]
  );

  const handleSearch = (val) => {
    setSearch(val);
  };

  return (
    <Select
      showSearch
      style={{ width: 250 }}
      placeholder="Select a Application"
      optionFilterProp="children"
      onChange={onSelect}
      onSearch={handleSearch}
      value={curApp ? `${curApp.name} - (${curApp.platform})` : null}
    >
      {filteredApps.map((app) => (
        <Select.Option key={app.id} value={app.id}>
          {app.name} ({app.package}) - ({app.platform})
        </Select.Option>
      ))}
    </Select>
  );
};
