import { message } from 'antd';
import { WbServicesMock } from 'components/workbench_v2/mocks/wb_services';
import { CUSTOM_DEVICE, FRAMES_SERVICE_VERSION } from 'components/workbench_v2/utils/consts';
import { showMessageByVersionDiff } from 'components/workbench_v2/utils/helpers';
import _ from 'lodash';
import { workbenchTracking } from 'monitoring/wbTracking';
import { useEffect, useRef, useState } from 'react';

const SOCKET_EVENTS = {
  ERROR: 'error',
  DIFF_PROGRESS: 'diff-progress',
  DIFF: 'diff',
  SCREEN: 'screen',
  VERSION: 'version',
  HINT_PROGRESS: 'hint-progress',
  HINT: 'hint',
  HINT_ERROR: 'hint-error',
  DIFF_ERROR: 'diff-error',
  GRAPH_ANALYSIS_FINISHED: 'graph-analysis-finished',
  GRAPH_ANALYSIS_DATA: 'graph-analysis-data',
  START_RP: 'start-rp',
  RP_CONFIG: 'rp-config',
  RP_NEW_EXPERIMENTS: 'rp-new-experiments',
  RP_TRACKING_ERROR: 'tracking-error',
  RP_INFO: 'info',
};

const SocketHintBatchTypes = {
  screen_data_hint: 'screen_data_hint',
  element_bounds_change: 'element_bounds_change',
  diff_hint: 'diff_hint',
};

export const HintTypeToMarkerType = {
  screen_data_hint: 'screenDataHint',
  element_bounds_change: 'elementBoundsChangeHint',
  diff_hint: 'diffHint',
};

const DIFF_MARGIN = 5;

const EXCLUDED_LOGFILE_EVENTS = [
  SOCKET_EVENTS.SCREEN,
  SOCKET_EVENTS.GRAPH_ANALYSIS_DATA,
  SOCKET_EVENTS.RP_NEW_EXPERIMENTS,
];

export const useWorkbenchSocket = ({
  wsUrl,
  leftDevice,
  rightDevice,
  setError,
  setDeviceError,
  deviceError,
  setProgress,
  setDiff,
  setLeftRects,
  setRightRects,
  setServerEvents,
  setHintProgress,
  setGraphData,
  setRp,
}) => {
  const socket = useRef(null);
  const [rightDeviceStreamImage, setRightDeviceStreamImage] = useState(null);
  const [leftDeviceStreamImage, setLeftDeviceStreamImage] = useState(null);

  const handleData = async (event) => {
    const data = JSON.parse(typeof event.data.text === 'function' ? await event.data.text() : event.data);

    if (!EXCLUDED_LOGFILE_EVENTS.includes(data.event)) setServerEvents((prev) => [data, ...prev]);

    switch (data.event) {
      case SOCKET_EVENTS.ERROR:
        if (!deviceError) {
          setDeviceError(data);
        }
        break;

      case SOCKET_EVENTS.VERSION:
        showMessageByVersionDiff(FRAMES_SERVICE_VERSION, data.version);
        setError(false);
        break;

      case SOCKET_EVENTS.SCREEN:
        if (data.device_serial === rightDevice.serial) {
          setRightDeviceStreamImage(data.screen);
        }

        if (data.device_serial === leftDevice.serial) {
          setLeftDeviceStreamImage(data.screen);
        }

        break;

      case SOCKET_EVENTS.DIFF_PROGRESS:
        setProgress({ percent: 50 });
        break;

      case SOCKET_EVENTS.DIFF:
        setProgress({ message: 'Diff Ready', percent: 100 });
        setDiff({ diff: data.diff.diff });

        workbenchTracking.endEventTrack('diff_loading_time', {
          diff_loading_time:
            (new Date().getTime() -
              new Date(workbenchTracking.getStartEventData('diff_loading_time').diff_started_at).getTime()) /
            1000,
          diff_finished_at: new Date().toLocaleString(),
        });

        break;

      case SOCKET_EVENTS.HINT_PROGRESS:
        setHintProgress({ message: 'Hints In Progress' });
        break;

      case SOCKET_EVENTS.HINT:
        const rightDeviceHints = [];
        const leftDeviceHints = [];

        const removeDuplicateBounds = (hint, index, self) =>
          index ===
          self.findIndex(
            (otherHint) => JSON.stringify(hint.element_bounds) === JSON.stringify(otherHint.element_bounds)
          );

        const formatHintToRect = ({ element_bounds, type }) => ({
          top: element_bounds.top,
          right: type === SocketHintBatchTypes.diff_hint ? element_bounds.right + DIFF_MARGIN : element_bounds.right,
          bottom: type === SocketHintBatchTypes.diff_hint ? element_bounds.bottom + DIFF_MARGIN : element_bounds.bottom,
          left: element_bounds.left,
          type: 'marker',
          hintType: HintTypeToMarkerType[type] || '',
          key: _.uniqueId().toString(),
          isHint: true,
        });

        data.hints.forEach(({ serial, element_bounds, type }) => {
          const hintObj = {
            element_bounds,
            type,
          };

          if (rightDevice.serial === serial) {
            rightDeviceHints.push(hintObj);
          }

          if (leftDevice.serial === serial) {
            leftDeviceHints.push(hintObj);
          }
        });

        const noDuplicatesRight = rightDeviceHints.filter(removeDuplicateBounds);
        const noDuplicatesLeft = leftDeviceHints.filter(removeDuplicateBounds);

        setLeftRects((prev) => [...prev.filter((rect) => !rect.isHint), ...noDuplicatesLeft.map(formatHintToRect)]);
        setRightRects((prev) => [...prev.filter((rect) => !rect.isHint), ...noDuplicatesRight.map(formatHintToRect)]);

        if (leftDeviceHints.length === 0 || rightDeviceHints.length === 0) {
          workbenchTracking.endEventTrack('hints_loading_time', {
            hints_loading_time:
              (new Date().getTime() -
                new Date(workbenchTracking.getStartEventData('hints_loading_time').hints_started_at).getTime()) /
              1000,
            hints_finished_at: new Date().toLocaleString(),
          });
        }

        break;

      case SOCKET_EVENTS.DIFF_ERROR:
        setProgress(null);
        setHintProgress(null);
        break;

      case SOCKET_EVENTS.HINT_ERROR:
        // bugfix: in case be returns error should reset progress to null
        setHintProgress(null);
        message.error(data.error, 5);
        break;

      case SOCKET_EVENTS.GRAPH_ANALYSIS_FINISHED:
        setGraphData((prev) => ({
          ...prev,
          [data.serial]: {
            ...prev[data.serial],
            graphLink: data.link,
          },
        }));
        break;

      case SOCKET_EVENTS.GRAPH_ANALYSIS_DATA:
        setGraphData((prev) => ({
          ...prev,
          [data.serial]: {
            ...prev[data.serial],
            dataset: data.graph_data.dataset,
          },
        }));
        break;

      case SOCKET_EVENTS.RP_INFO:
        message.info(data['info_message'], 5);
        break;

      case SOCKET_EVENTS.RP_TRACKING_ERROR:
        message.error(data['error_message'], 5);
        break;

      case SOCKET_EVENTS.START_RP:
        message.info(data.step, 5);
        break;

      case SOCKET_EVENTS.RP_CONFIG:
        setRp((prev) => ({
          ...prev,
          [data.serial]: {
            ...prev[data.serial],
            config: data.config,
          },
        }));
        break;

      case SOCKET_EVENTS.RP_NEW_EXPERIMENTS:
        const convertToCountArray = (arr) =>
          Object.values(
            arr.reduce(
              (acc, obj) => (
                acc[obj.name]
                  ? (acc[obj.name] = { ...obj, count: acc[obj.name].count + 1, time: new Date() })
                  : (acc[obj.name] = { ...obj, name: obj.name, count: 1, time: new Date() }),
                acc
              ),
              {}
            )
          );
        const currentExperimentsWithCounts = convertToCountArray(data.experiments);

        setRp((prev) => {
          if (!prev[data.serial]) {
            workbenchTracking.endEventTrack('rp_loading_time', {
              rp_device_serial: data.serial,
              rp_loading_time:
                (new Date().getTime() -
                  new Date(workbenchTracking.getStartEventData('rp_loading_time').rp_started_at).getTime()) /
                1000,
              rp_finished_at: new Date().toLocaleString(),
            });
          }

          const allExperimentsWithCounts =
            prev[data.serial] && prev[data.serial].experiments
              ? convertToCountArray(prev[data.serial].experiments.concat(data.experiments))
              : currentExperimentsWithCounts;

          return {
            ...prev,
            [data.serial]: {
              ...prev[data.serial],
              experiments: allExperimentsWithCounts,
            },
          };
        });
        break;

      default:
        return;
    }
  };

  const handleError = (event) => {
    setError(true);
    console.log(event);
  };

  const handleClosed = (socketUrl) => {
    setError(true);
    const newWs = new WebSocket(socketUrl);
    newWs.onmessage = handleData;
    newWs.onerror = handleError;
    newWs.onclose = () => handleClosed(socketUrl);
    socket.current = newWs;
  };

  const closeSocket = () => {
    socket.current.onclose = null;
    socket.current.close();
  };

  useEffect(() => {
    if (leftDevice && rightDevice) {
      const leftDeviceId = leftDevice.serial !== CUSTOM_DEVICE.serial ? leftDevice.serial : '';
      const rightDeviceId = rightDevice.serial !== CUSTOM_DEVICE.serial ? rightDevice.serial : '';

      const socketUrl = `${wsUrl}?serials=${leftDeviceId},${rightDeviceId}`;
      console.log('re-opening socket, url:', socketUrl);

      // if socket exists when device changes, close it
      if (socket.current) closeSocket();
      // open a new socket for the connected devices
      if (WbServicesMock.mockEnabled) {
        socket.current = WbServicesMock.initMockSocket(handleData);
      } else {
        socket.current = new WebSocket(socketUrl);
        socket.current.onmessage = handleData;
        socket.current.onerror = handleError;
        socket.current.onclose = () => handleClosed(socketUrl);
      }

      return closeSocket;
    }
  }, [leftDevice, rightDevice]);

  useEffect(() => {
    if (socket.current) socket.current.onmessage = handleData;
  }, [deviceError]);

  return {
    socket: {
      send(data) {
        if (socket.current && socket.current.readyState === 1) {
          socket.current.send(JSON.stringify(data));
        }
      },
    },
    leftDeviceStreamImage,
    rightDeviceStreamImage,
  };
};
