import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import ReactJson from 'react-json-view';
import { Button } from 'semantic-ui-react';
import InsightForm from './InsightForm';
import AppSelector from './AppSelector';
import CreateInsight from './CreateInsight';
import InsightList from './InsightList';
import { selectApp, selectInsight, getInsightPersonas } from '../../redux/actions/insightPage';
import { loadApps } from '../../redux/actions/apps';
import { ALL_PLATFORMS } from '../../constants/platforms';
import InsightJson from './InsightJson';

class InsightsPage extends React.PureComponent {
  componentDidMount() {
    this.props.onLoadApps(ALL_PLATFORMS);
    const path = this.props.history.location.pathname.split('/');
    this.props.history.replace(`/insights`);
    if (path.length > 2) {
      const appName = path[2];
      const appPackage = path[3];
      const appPlatform = path[4];
      const insightId = path[5];
      this.props.onAppSelected(appName, appPackage, appPlatform);
      this.props.onInsightSelected(Number(insightId));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedInsightId && this.props.selectedInsightId !== prevProps.selectedInsightId) {
      this.props.history.replace(
        `/insights/${this.props.selectedAppName}/${this.props.selectedAppPackage}/${this.props.selectedAppPlatform}/${this.props.selectedInsightId}`
      );
    }
    if (
      this.props.insights.length &&
      (this.props.insights.length ? this.props.insights[0].app_id : null) !==
        (prevProps.insights.length ? prevProps.insights[0].app_id : null)
    ) {
      this.props.getInsightPersonas(this.props.insights[0].app_id);
    }
  }
  render() {
    const RefreshButton = () => (
      <Button
        secondary
        onClick={() =>
          this.props.onRefreshData(
            this.props.selectedAppName,
            this.props.selectedAppPackage,
            this.props.selectedAppPlatform
          )
        }
      >
        Reload Data
      </Button>
    );

    const insight = this.props.insights.find((insight) => insight.id === this.props.selectedInsightId);

    return (
      <div className="insight-page page">
        <div className="insight-page__head">
          <AppSelector />
          {this.props.selectedAppName !== null && !this.props.user.reviewer && <CreateInsight />}
          {this.props.selectedAppName !== null && <RefreshButton />}
        </div>
        <div className="insight-page__body">
          <div className="insight-page__body__left">
            {this.props.insights.length > 0 && <InsightList insights={this.props.insights} />}
          </div>
          {insight && (
            <div className="insight-page__body__right">
              <InsightForm
                key={insight.id}
                insight={insight}
                appVersions={this.props.appVersions}
                user={this.props.user}
                insights={this.props.insights}
                personas={this.props.personas}
              />
              <InsightJson insight={insight} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

InsightsPage.defaultProps = {
  insight: null,
};

InsightsPage.propTypes = {
  insight: PropTypes.shape({ id: PropTypes.number.isRequired }),
  appVersions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLoadApps: PropTypes.func.isRequired,
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      created: PropTypes.string,
      id: PropTypes.number,
      enabled: PropTypes.bool,
      analyst_name: PropTypes.string,
    })
  ).isRequired,
  onAppSelected: PropTypes.func.isRequired,
  onInsightSelected: PropTypes.func.isRequired,
};

const getAppVersions = (state) =>
  state.apps.versions[[state.insightPage.selectedAppPackage, state.insightPage.selectedAppPlatform].toString()] || [];

const getVersions = createSelector([getAppVersions], (appVersions) => {
  const newAppVersions = appVersions.map((version) => ({
    value: version.releaseId,
    key: version.releaseId,
    id: version.releaseId,
    text: `${version.releaseName}(${version.versionCode}) - ${version.releaseDate} - ${version.state} and ${
      version.is_visible ? 'visible' : 'invisible'
    }`,
    className: `${version.is_visible ? 'green' : 'white'}`,
    date: version.releaseDate,
  }));
  newAppVersions.unshift({
    value: -1,
    key: -1,
    id: -1,
    text: 'No Release',
  });
  return newAppVersions;
});

const mapStateToProps = (state) => ({
  appVersions: getVersions(state),
  selectedInsightId: state.insightPage.selectedInsightId,
  insights: state.insightPage.insights,
  selectedAppName: state.insightPage.selectedAppName,
  selectedAppPackage: state.insightPage.selectedAppPackage,
  selectedAppPlatform: state.insightPage.selectedAppPlatform,
  onAppSelected: PropTypes.func.isRequired,
  user: state.usersPage.user,
  personas: state.insightPage.personas,
});

const mapDispatchToProps = (dispatch) => ({
  onLoadApps: (platform) => {
    dispatch(loadApps(platform));
  },
  onRefreshData: (appName, appPackage, appPlatform) => {
    dispatch(selectApp(appName, appPackage, appPlatform));
  },
  onAppSelected: (appName, appPackage, appPlatform) => {
    dispatch(selectApp(appName, appPackage, appPlatform));
  },
  onInsightSelected: (insightId) => {
    dispatch(selectInsight(insightId));
  },
  getInsightPersonas: (appId) => dispatch(getInsightPersonas(appId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightsPage);
