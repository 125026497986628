"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterDiff = exports.DiffFilterTypes = void 0;
exports.DiffFilterTypes = {
    Strings: 'strings',
    Layouts: 'layouts',
};
var filterStringsDiff = function (searchString, stringsDiff) {
    if (!searchString)
        return;
    var result = {};
    if (Array.isArray(stringsDiff.added)) {
        result.added = stringsDiff.added.filter(function (addedString) {
            return JSON.stringify(addedString).toLowerCase().includes(searchString);
        });
    }
    if (Array.isArray(stringsDiff.removed)) {
        result.removed = stringsDiff.removed.filter(function (removedString) {
            return JSON.stringify(removedString).toLowerCase().includes(searchString);
        });
    }
    if (Array.isArray(stringsDiff.changed)) {
        result.changed = stringsDiff.changed.filter(function (changedString) {
            return JSON.stringify(changedString).toLowerCase().includes(searchString);
        });
    }
    if (result.added && !result.added.length)
        delete result.added;
    if (result.changed && !result.changed.length)
        delete result.changed;
    if (result.removed && !result.removed.length)
        delete result.removed;
    return result;
};
var filterLayoutsDiff = function (searchString, layoutsDiff) {
    if (!searchString)
        return;
    var result = {};
    if (Array.isArray(layoutsDiff.added)) {
        result.added = layoutsDiff.added.filter(function (item) { return item.toLowerCase().includes(searchString); });
    }
    if (Array.isArray(layoutsDiff.removed)) {
        result.removed = layoutsDiff.removed.filter(function (item) { return item.toLowerCase().includes(searchString); });
    }
    if (Array.isArray(layoutsDiff.changed)) {
        result.changed = layoutsDiff.changed.filter(function (item) { return item.toLowerCase().includes(searchString); });
    }
    if (result.added && !result.added.length)
        delete result.added;
    if (result.changed && !result.changed.length)
        delete result.changed;
    if (result.removed && !result.removed.length)
        delete result.removed;
    return result;
};
var filterDiff = function (searchString, diffObject) {
    if (!searchString)
        return;
    var filteredResult = {
        diff: {},
    };
    Object.entries(diffObject.diff).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        switch (key) {
            case exports.DiffFilterTypes.Strings:
                filteredResult.diff[key] = filterStringsDiff(searchString, value);
                break;
            case exports.DiffFilterTypes.Layouts:
                filteredResult.diff[key] = filterLayoutsDiff(searchString, value);
                break;
            default:
                break;
        }
    });
    return filteredResult;
};
exports.filterDiff = filterDiff;
