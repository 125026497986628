import {
  getTagsAPI,
  getReportsAPI,
  addReportAPI,
  updReportAPI,
  delReportAPI,
  addReportTagAPI,
  delReportTagAPI,
  addOrgReportAPI,
  delOrgReportAPI,
} from '../actions/reports';
import * as AT from '../actionTypes';

const personasMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_TAGS: {
        dispatch(getTagsAPI());
        return next(action);
      }
      case AT.GET_REPORTS: {
        dispatch(getReportsAPI());
        return next(action);
      }
      case AT.ADD_REPORT: {
        dispatch(addReportAPI(action.payload.data));
        return next(action);
      }
      case AT.UPD_REPORT: {
        dispatch(updReportAPI(action.payload.reportId, action.payload.data));
        return next(action);
      }
      case AT.DEL_REPORT: {
        dispatch(delReportAPI(action.payload.reportId));
        return next(action);
      }
      case AT.ADD_REPORT_TAG: {
        const { reportId, data } = action.payload;
        dispatch(addReportTagAPI(reportId, data));
        return next(action);
      }
      case AT.DEL_REPORT_TAG: {
        const { reportId, tagId } = action.payload;
        dispatch(delReportTagAPI(reportId, tagId));
        return next(action);
      }
      case AT.ADD_ORG_REPORT: {
        const { reportId, data } = action.payload;
        dispatch(addOrgReportAPI(reportId, data));
        return next(action);
      }
      case AT.DEL_ORG_REPORT: {
        const { reportId, organizationId } = action.payload;
        dispatch(delOrgReportAPI(reportId, organizationId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default personasMiddleware;
