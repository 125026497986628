import React from 'react';
import { Select } from 'antd';
import moment from 'moment';
import _ from 'lodash';

const NoRelese = {
  value: -1,
  key: -1,
  id: -1,
  version_code: '-',
  release_name: 'No Release',
};

export default function InsightReleases({ insight, handleInsChange, releasesList }) {
  const handleCurrentReleaseChange = (val) => {
    const newStartTime = releasesList.data.find((rel) => rel.id === Number(val)).release_date;
    handleInsChange({
      current_release_id: Number(val),
      start_time: moment(newStartTime).format('YYYY-MM-DD'),
    });
  };

  return (
    <section className="insight-data-item insight-release">
      <h3 className="insight-data-item-title">Release Section</h3>
      <div className="fields">
        <div className="field width-50p">
          <label>
            Current Release <span className="red-star">*</span>
          </label>
          <Select
            value={insight.current_release_id || -1}
            onChange={handleCurrentReleaseChange}
            getPopupContainer={(trNode) => trNode}
          >
            {[NoRelese, ..._.orderBy(releasesList.data, ['release_date'], ['desc'])].map((rel) => (
              <Select.Option value={rel.id} className={`release-option ${rel.is_visible ? 'green' : 'white'}`}>
                {rel.release_name} ({rel.version_code}) | {moment(rel.release_date).format('YYYY-MM-DD')} | {rel.state}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className=" width-50p field">
          <label>Start Date (from release)</label>
          <div className="disabled-value">{moment(insight.start_time).format('YYYY-MM-DD')}</div>
        </div>
      </div>
    </section>
  );
}
