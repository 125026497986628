import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { useMutation, useQuery } from 'react-query';
import { addInsightPersona, delInsightPersona, getInsightPersonas } from '../../api/insightsAPI';

const { Option } = Select;

export default ({ insightId, suggestions, setPersonas }) => {
  const insightPersonasQuery = useQuery(['insightPersonas', insightId], () => getInsightPersonas(insightId));
  const [insightPersonas, setInsightPersonas] = useState([]);
  useEffect(() => {
    if (insightPersonasQuery.isFetched) {
      setInsightPersonas(insightPersonasQuery.data.data);
    }
  }, [insightId, insightPersonasQuery.status]);
  useEffect(() => setPersonas(insightPersonas), [insightPersonas]);
  const addInsightPersonaMutation = useMutation(addInsightPersona, {
    onSuccess: (data) => setInsightPersonas([...insightPersonas, data.data.persona]),
  });
  const delInsightPersonaMutation = useMutation(delInsightPersona, {
    onSuccess: (data, variables) => setInsightPersonas(insightPersonas.filter((x) => x.id !== variables.personaId)),
  });
  const handleUpdPersonas = (personaIds) => {
    insightPersonas
      .filter((x) => !personaIds.includes(x.id))
      .forEach((x) =>
        delInsightPersonaMutation.mutateAsync({
          insightId,
          personaId: x.id,
        })
      );
    personaIds
      .filter((y) => !insightPersonas.map((x) => x.id).includes(y))
      .forEach((personaId) =>
        addInsightPersonaMutation.mutateAsync({
          insightId,
          personaId,
        })
      );
  };
  const isLoading = [insightPersonasQuery, addInsightPersonaMutation, delInsightPersonaMutation].some(
    (x) => x.isLoading
  );
  return (
    <Select
      mode="multiple"
      placeholder="Personas"
      style={{ width: '100%' }}
      onChange={(value) => handleUpdPersonas(value)}
      filterOption={(input, option) => option.props.children[3].toLowerCase().indexOf(input.toLowerCase()) >= 0}
      value={insightPersonas.map((x) => x.id)}
      loading={isLoading}
      disabled={isLoading}
    >
      {suggestions.map((persona) => (
        <Option value={persona.id} key={persona.id}>
          #{persona.id}
          <span style={{ margin: '0px 2px' }} className={`flag-icon flag-icon-${persona.location.toLowerCase()}`} />
          {persona.title}
        </Option>
      ))}
    </Select>
  );
};
