/* eslint-disable react/no-multi-comp */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Stage, Layer, Image } from 'react-konva';
import { Button, Icon } from 'semantic-ui-react';
import TransformerComponent from './TranformerComponent';
import Rectangle from './Rectangle';

const CANVAS_WIDTH_PORTRAIT = 360;
const CANVAS_HEIGHT_PORTRAIT = 640;
const CANVAS_WIDTH_LANDSCAPE = 640;
const CANVAS_HEIGHT_LANDSCAPE = 320;

const normalizeX = (x, imageWidth, canvasWidth) => Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y, imageHeight, canvasHeight) => Math.round((canvasHeight * y) / imageHeight);

const deNormalizeX = (x, imageWidth, canvasWidth) => Math.round((imageWidth * x) / canvasWidth);
const deNormalizeY = (y, imageHeight, canvasHeight) => Math.round((imageHeight * y) / canvasHeight);

const ControlButtons = ({ onAddMarker, onAddBlur, onRemoveRect, disableAddMarker, disableRemove }) => (
  <Button.Group>
    <Button icon disabled={disableAddMarker} onClick={onAddMarker}>
      <Icon name="square outline" /> Mark
    </Button>
    <Button icon onClick={onAddBlur}>
      <Icon name="square" /> Blur
    </Button>
    <Button icon disabled={disableRemove} onClick={onRemoveRect}>
      <Icon name="minus" />
    </Button>
  </Button.Group>
);

ControlButtons.propTypes = {
  onAddMarker: PropTypes.func.isRequired,
  onAddBlur: PropTypes.func.isRequired,
  onRemoveRect: PropTypes.func.isRequired,
  disableAddMarker: PropTypes.bool.isRequired,
  disableRemove: PropTypes.bool.isRequired,
};

class CanvasedImage extends React.PureComponent {
  constructor(props) {
    super(props);
    let canvasWidth = CANVAS_WIDTH_PORTRAIT;
    let canvasHeight = CANVAS_HEIGHT_PORTRAIT;

    if (props.isLandscape) {
      CANVAS_WIDTH_LANDSCAPE;
      CANVAS_HEIGHT_LANDSCAPE;
      canvasWidth = CANVAS_WIDTH_LANDSCAPE; //window.screen.width * 0.8;
      canvasHeight = CANVAS_HEIGHT_LANDSCAPE; // window.screen.height * 0.8;
    }

    if (props.isZoomIn) {
      canvasWidth *= 2;
      canvasHeight *= 2;
    }

    this.state = {
      image: null,
      internalRects: {},
      showControlButtons: false,
      selectedRectKey: null,
      selectedRect: null,
      canvasWidth,
      canvasHeight,
    };
  }

  handleClickOutside = (event) => {
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ selectedRect: null, selectedRectKey: null });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    const { rects } = this.props;
    const image = new window.Image();
    image.src = this.props.imgSrc;
    image.onload = () => {
      const internalRects = !rects
        ? {}
        : Object.entries(rects).reduce((result, entry) => {
            const rect = entry[1];
            result[entry[0]] = {
              x: normalizeX(rect.left, image.width, this.state.canvasWidth),
              y: normalizeY(rect.top, image.height, this.state.canvasHeight),
              width: normalizeX(rect.right - rect.left, image.width, this.state.canvasWidth),
              height: normalizeY(rect.bottom - rect.top, image.height, this.state.canvasHeight),
              type: rect.type,
            };
            return result;
          }, {});
      this.setState({ image, internalRects, showControlButtons: true });
    };
  }

  handleImageMouseDown = (key, e) => {
    const clickedOnTransformer = e.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    this.setState({
      selectedRect: e.target,
      selectedRectKey: key,
    });
  };

  handleRectChange = (key, newProps) => {
    const width = newProps.width > 1 ? newProps.width : 100;
    const height = newProps.height > 1 ? newProps.height : 100;
    console.log(newProps.width, newProps.height);

    newProps = { ...newProps, width, height };
    newProps.key = key;
    this.onRectChange(newProps);
  };

  onRectChange(rect) {
    const { image } = this.state;
    this.props.onRectChange({
      top: deNormalizeY(rect.y, image.height, this.state.canvasHeight),
      left: deNormalizeX(rect.x, image.width, this.state.canvasWidth),
      right: deNormalizeX(rect.width + rect.x, image.width, this.state.canvasWidth),
      bottom: deNormalizeY(rect.height + rect.y, image.height, this.state.canvasHeight),
      key: rect.key,
      type: rect.type,
    });
    this.setState({
      selectedRectKey: rect.key,
    });
  }

  handleDeleteRect = () => {
    const { selectedRectKey, internalRects } = this.state;
    if (selectedRectKey) {
      this.props.onRectChange({ removed: selectedRectKey });
      delete internalRects[selectedRectKey];
      this.setState({
        internalRects,
        selectedRectKey: null,
        selectedRect: null,
      });
    }
  };
  handleAddRect = (rectType) => {
    const { image } = this.state;
    const newRect = {
      x: 0,
      y: 0,
      width: normalizeX(500, image.width, this.state.canvasWidth),
      height: normalizeY(250, image.height, this.state.canvasHeight),
    };

    newRect.key = new Date().getTime();
    newRect.type = rectType;
    this.onRectChange(newRect);
    this.state.internalRects[newRect.key] = newRect;
    this.setState({ internalRects: this.state.internalRects });
  };

  handleAddMarker = () => {
    this.handleAddRect('marker');
  };
  handleAddBlur = () => {
    this.handleAddRect('blur');
  };

  render() {
    return (
      <div className="canvased-image">
        <div className="canvased-image__controls">
          {this.state.showControlButtons && (
            <ControlButtons
              onRemoveRect={this.handleDeleteRect}
              onAddMarker={this.handleAddMarker}
              onAddBlur={this.handleAddBlur}
              disableAddMarker={false}
              disableRemove={!this.state.selectedRectKey}
            />
          )}
          {!this.props.isZoomIn && (
            <Button onClick={this.props.onZoomIn}>
              <Icon name="zoom-in" /> Zoom In
            </Button>
          )}
        </div>
        <div className="canvased-image__canvas">
          <Stage width={this.state.canvasWidth} height={this.state.canvasHeight}>
            <Layer>
              {this.state.image && (
                <Image
                  image={this.state.image}
                  width={this.state.canvasWidth}
                  height={this.state.canvasHeight}
                  name="image"
                />
              )}
              {Object.entries(this.state.internalRects).map(([key, value]) => (
                <Rectangle
                  {...value}
                  name="rect"
                  key={key}
                  onTransform={(newProps) => this.handleRectChange(key, newProps)}
                  onMouseDown={(e) => this.handleImageMouseDown(key, e)}
                />
              ))}
              <TransformerComponent selectedRect={this.state.selectedRect} />
            </Layer>
          </Stage>
        </div>
      </div>
    );
  }
}

CanvasedImage.defaultProps = {
  rects: null,
};

CanvasedImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  rects: PropTypes.shape({}),
  onRectChange: PropTypes.func.isRequired,
  isLandscape: PropTypes.bool.isRequired,
};
export default CanvasedImage;
