import React from 'react';
import { Checkbox, Button, Icon, Popconfirm, Select, Table, Typography } from 'antd';

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import moment from 'moment';

const { Paragraph } = Typography;

const DragHandle = SortableHandle(() => (
  <Icon
    type="menu"
    style={{
      cursor: 'grab',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} style={{ zIndex: 1001 }} />);
const ASortableContainer = SortableContainer((props) => <tbody {...props} />);

const l = console.log;

export default ({
  assets,
  variants,
  activeIndex,
  isLoading,
  selectAssetId,
  updAsset,
  delAsset,
  setInsightThumbnail,
  isDisabled,
}) => {
  const columns = [
    {
      dataIndex: 'order_index',
      width: '30px',
      render: () => <DragHandle />,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '70px',
      render: (value, record) => <div title={`asset #${record.id}`}>{value}</div>,
    },
    ...(variants
      ? [
          {
            title: 'Variant',
            dataIndex: 'variant_id',
            width: '230px',
            render: (value, record) => (
              <Select
                placeholder="Variant"
                value={value}
                onChange={(variantId) => updAsset(record.id, { ab_test_variant_id: variantId })}
                width="7rem"
              >
                {variants.map((x) => (
                  <Select.Option key={x.id} value={x.id} title={`#${x.id} ctrl(${x.control}) - ${x.type} - ${x.value}`}>
                    {`#${x.id} control(${x.control}) - ${x.type} - ${x.value}`}
                  </Select.Option>
                ))}
              </Select>
            ),
          },
        ]
      : [
          {
            title: 'Asset type',
            dataIndex: 'insight_asset_type',
            width: '230px',
            render: (value, record) => (
              <React.Fragment>
                {record.order_index === activeIndex ? (
                  <Select
                    placeholder="insight_asset_type"
                    value={value}
                    onChange={(val) => updAsset(record.id, { insight_asset_type: val })}
                    width="7rem"
                  >
                    {['current', 'previous', null].map((x) => (
                      <Select.Option key={x} value={x} title={x}>
                        {x}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  value
                )}
              </React.Fragment>
            ),
          },
        ]),
    {
      title: 'Orientation',
      dataIndex: 'orientation',
      width: '120px',
      render: (value, record) => (
        <React.Fragment>
          {record.order_index === activeIndex ? (
            <Select
              placeholder="Platform"
              value={value}
              onChange={(orientation) => updAsset(record.id, { orientation })}
              width="7rem"
            >
              {['portrait', 'landscape'].map((x) => (
                <Select.Option key={x} value={x}>
                  {x}
                </Select.Option>
              ))}
            </Select>
          ) : (
            value
          )}
        </React.Fragment>
      ),
    },
    {
      title: 'Is Hidden',
      dataIndex: 'is_hidden',
      width: '65px',
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(e) => updAsset(record.id, { is_hidden: e.target.checked })}
          disabled={!value}
        />
      ),
    },
    {
      title: 'Blur faces',
      dataIndex: 'blur_faces',
      width: '95px',
      render: (value, record) => (
        <React.Fragment>
          {record.type === 'video' ? (
            <Checkbox
              checked={record.blur_faces}
              disabled={
                record.status &&
                !moment(record.status_at).add(300, 'seconds').isBefore(moment()) &&
                (record.status === 'beginning' || record.status.includes('fps'))
              }
              onChange={(e) => updAsset(record.id, { blur_faces: e.target.checked })}
            />
          ) : null}
          {record.status && (record.status === 'beginning' || record.status.includes('fps')) ? 'blurring...' : ''}
          <div>
            {record.status &&
            (record.status === 'beginning' || record.status.includes('fps')) &&
            moment(record.status_at).add(300, 'seconds').isBefore(moment())
              ? 'possibly stuck'
              : ''}
          </div>
        </React.Fragment>
      ),
    },

    {
      title: 'Text',
      dataIndex: 'text',
      width: '120px',
      render: (value, record) => (
        <Paragraph editable={{ onChange: (val) => updAsset(record.id, { text: val }) }}>{value}</Paragraph>
      ),
    },
    {
      title: 'Operations',
      key: 'actions',
      width: '170px',
      render: (value, record) => (
        <div style={{ display: 'inline-flex' }}>
          {record.type === 'image' && (
            <Button
              style={{ margin: '0 4px' }}
              loading={isLoading}
              onClick={() =>
                setInsightThumbnail({
                  url_raw: record.url_raw,
                  url_annotated: record.url_annotated,
                  orientation: record.orientation,
                  rects: record.rects,
                  blur_faces: record.blur_faces,
                })
              }
            >
              Set as thumbnail
            </Button>
          )}
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              delAsset(record.id);
            }}
          >
            <Button icon="delete" type="danger small" />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      updAsset(assets.find((x) => x.order_index === oldIndex).id, { order_index: newIndex });
      updAsset(assets.find((x) => x.order_index === newIndex).id, { order_index: oldIndex });
    }
  };

  const DraggableContainer = (props) => (
    <ASortableContainer useDragHandle helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => (
    <SortableItem
      index={restProps['data-row-key']}
      {...restProps}
      className={restProps['data-row-key'] === activeIndex ? 'bg-variant-asset-selected' : undefined}
    />
  );

  return (
    <div style={isDisabled ? { pointerEvents: 'none' } : null}>
      <Table
        loading={isLoading}
        dataSource={[...assets].sort((a, b) => a.order_index - b.order_index)}
        columns={columns}
        rowKey="order_index"
        onRowClick={(record) => selectAssetId(record.id)}
        scroll={{
          y: 600,
        }}
        pagination={false}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};
