import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const apiRequestDeviceModelsFailed = (error) => ({
  type: AT.API_REQUEST_DEVICES_FAILED,
  payload: { error },
});

// Get all device models

export const getAllDeviceModels = () => ({
  type: AT.GET_DEVICE_MODELS,
});

export const getAllDeviceModelsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'devices/device_models',
    label: NETWORK_LABELS.GET_DEVICE_MODELS,
    onSuccess: (body, dispatch) => dispatch(getAllDeviceModelsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDeviceModelsFailed(error)),
  },
});

export const getAllDeviceModelsSuccess = (data) => ({
  type: AT.GET_DEVICE_MODELS_SUCCESS,
  payload: { deviceModels: data.data },
});

// Update device model

export const updDeviceModel = (deviceModelId, data) => ({
  type: AT.UPD_DEVICE_MODEL,
  payload: {
    deviceModelId,
    data,
  },
});

export const updDeviceModelAPI = (deviceModelId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `devices/device_models/${deviceModelId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_DEVICE_MODEL,
    onSuccess: (body, dispatch) => dispatch(updDeviceModelSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDeviceModelsFailed(error)),
  },
});

export const updDeviceModelSuccess = (body) => ({
  type: AT.UPD_DEVICE_MODEL_SUCCESS,
  payload: { data: body.data },
});

// Create device model

export const addDeviceModel = (data) => ({
  type: AT.ADD_DEVICE_MODEL,
  payload: { data },
});

export const addDeviceModelAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'devices/device_models',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_DEVICE_MODEL,
    onSuccess: (body, dispatch) => dispatch(addDeviceModelSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDeviceModelsFailed(error)),
  },
});

export const addDeviceModelSuccess = (body) => ({
  type: AT.ADD_DEVICE_MODEL_SUCCESS,
  payload: { data: body.data },
});

// Delete device model

export const delDeviceModel = (deviceModelId) => ({
  type: AT.DEL_DEVICE_MODEL,
  payload: { deviceModelId },
});

export const delDeviceModelAPI = (deviceModelId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `devices/device_models/${deviceModelId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_DEVICE_MODEL,
    onSuccess: (body, dispatch) => dispatch(delDeviceModelSuccess(deviceModelId)),
    onFailure: (error, dispatch) => dispatch(apiRequestDeviceModelsFailed(error)),
  },
});

export const delDeviceModelSuccess = (deviceModelId) => ({
  type: AT.DEL_DEVICE_MODEL_SUCCESS,
  payload: { deviceModelId },
});
