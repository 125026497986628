import React from 'react';
import { Modal, Form, Input, Select, Col, Row } from 'antd';

class DeviceModelForm extends React.Component {
  render() {
    const { form, deviceModel } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Manufacturer">
              {getFieldDecorator('manufacturer', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: deviceModel && deviceModel.manufacturer,
              })(<Input placeholder="Manufacturer" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Product Name">
              {getFieldDecorator('product_name', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: deviceModel && deviceModel.product_name,
              })(<Input placeholder="Product Name" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Model">
              {getFieldDecorator('model', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: deviceModel && deviceModel.model,
              })(<Input placeholder="Model" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="RAM">
              {getFieldDecorator('ram', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.ram,
              })(<Input placeholder="RAM" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="CPU">
              {getFieldDecorator('cpu', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.cpu,
              })(<Input placeholder="CPU" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="GPU">
              {getFieldDecorator('gpu', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.gpu,
              })(<Input placeholder="GPU" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Storage">
              {getFieldDecorator('storage', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.storage,
              })(<Input placeholder="Storage" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Device Type">
              {getFieldDecorator('device_type', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.device_type,
              })(<Input placeholder="Device Type" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Screen Size">
              {getFieldDecorator('screen_size', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.screen_size,
              })(<Input placeholder="Screen Size" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Screen Density">
              {getFieldDecorator('screen_density', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.screen_density,
              })(<Input placeholder="Screen Density" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Platform">
              {getFieldDecorator('platform', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.platform,
              })(<Input placeholder="Platform" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="OS Version">
              {getFieldDecorator('os_version', {
                rules: [{ max: 100 }],
                initialValue: deviceModel && deviceModel.os_version,
              })(<Input placeholder="OS Version" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Category">
              {getFieldDecorator('category', {
                initialValue: deviceModel && deviceModel.category,
              })(
                <Select placeholder="Category">
                  {['high-end', 'mid-range', 'low-end'].map((x) => (
                    <Select.Option key={x} value={x}>
                      {x}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(DeviceModelForm);

export default ({ deviceModel, handleHide, addSubmit, updSubmit }) => {
  let formRef = null;

  return (
    <Modal
      title={deviceModel ? `Change device model #${deviceModel.id}` : 'Add new device model'}
      centered
      visible
      width="60%"
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (deviceModel) {
              updSubmit(deviceModel.id, values);
            } else {
              addSubmit(values);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        deviceModel={deviceModel}
      />
    </Modal>
  );
};
