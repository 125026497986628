"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RPDevice = exports.ExperimentTypes = exports.BooleanTestTypes = void 0;
var antd_1 = require("antd");
var react_1 = __importStar(require("react"));
require("./RPDevice.scss");
var consts_1 = require("components/workbench_v2/utils/consts");
var WorkbenchContext_1 = require("components/workbench_v2/contexts/workbench-context/WorkbenchContext");
var rp_experiment_1 = require("./rp-experiment");
var wbTracking_1 = require("monitoring/wbTracking");
var react_query_1 = require("react-query");
// @ts-ignore
var workbenchAPI_1 = require("api/workbenchAPI");
var Collapse = antd_1.Collapse;
var Tabs = antd_1.Tabs;
var RP_DISABLE_TIMEOUT = 5000;
var RP_ENABLE_TIMEOUT = 10000;
exports.BooleanTestTypes = {
    true: true,
    false: false,
    'true ->  false': false,
    'false ->  true': true,
    'false ->  false': false,
    'true ->  true': true,
};
var MAX_EXPERIMENTS = 150;
var RP_COLLAPSE_KEYS = {
    EXPERIMENTS_DIFF: 'experiments-diff',
    EXPERIMENTS_CURRENT: 'experiments-current',
};
var EXPERIMENTS_DIFF_TABS = {
    added: 'added',
    removed: 'removed',
    changed: 'changed',
    static_experiments: 'static experiments',
};
exports.ExperimentTypes = {
    Boolean: 'Boolean',
    String: 'String',
    Integer: 'Integer',
};
var getTestType = function (value, type) {
    if (value.includes('false') || value.includes('true')) {
        return exports.ExperimentTypes.Boolean;
    }
    return type;
};
var RPModes = {
    TRACK_INDIVIDUAL: 'TRACK_INDIVIDUAL',
    MUTE_INDIVIDUAL: 'MUTE_INDIVIDUAL',
    ADDED: 'ADDED',
    N2F: 'N2F',
    ADDED_N2F: 'ADDED_N2F',
};
var RPDevice = function (_a) {
    var show = _a.show, resetRp = _a.resetRp, serial = _a.serial, updateTestValue = _a.updateTestValue, deviceRP = _a.deviceRP, currAppVer = _a.currAppVer;
    var _b = (0, WorkbenchContext_1.useWorkbenchContext)(), socket = _b.socket, currApp = _b.currApp, leftDevice = _b.leftDevice, rightDevice = _b.rightDevice, setRp = _b.setRp, diffObject = _b.diffObject, experimentAddToFavorites = _b.experimentAddToFavorites, experimentRemoveFromFavorites = _b.experimentRemoveFromFavorites, favoriteExperiments = _b.favoriteExperiments;
    var _c = (0, react_1.useState)(false), buttonDisabled = _c[0], setButtonDisabled = _c[1];
    var _d = (0, react_1.useState)(''), search = _d[0], setSearch = _d[1];
    var _e = (0, react_1.useState)(false), isRPStarted = _e[0], setIsRPStarted = _e[1];
    var _f = (0, react_1.useState)(), currentMode = _f[0], setCurrentMode = _f[1];
    var _g = (0, react_1.useState)([]), addedExperiments = _g[0], setAddedExperiments = _g[1];
    var updateTrackAllMutation = (0, react_query_1.useMutation)(workbenchAPI_1.updateRpTrackAll);
    var updateTrackOneMutation = (0, react_query_1.useMutation)(workbenchAPI_1.updateRpTrackOne);
    var experiments = (0, react_1.useMemo)(function () {
        return deviceRP
            ? deviceRP.experiments
                .filter(function (_a) {
                var name = _a.name;
                return name.toLowerCase().includes(search.toLowerCase());
            })
                .filter(function (_a) {
                var name = _a.name;
                return currentMode === RPModes.ADDED ? addedExperiments.find(function (addedName) { return name.includes(addedName); }) : true;
            })
                .slice(0, MAX_EXPERIMENTS)
                .map(function (experiment) { return (__assign(__assign({}, experiment), (Array.isArray(favoriteExperiments)
                ? {
                    is_favorite: favoriteExperiments.find(function (_a) {
                        var name = _a.name;
                        return name === experiment.name;
                    }),
                }
                : null))); })
            : [];
    }, [search, deviceRP, addedExperiments, favoriteExperiments]);
    var handleRPClick = function () {
        if (!currApp || !leftDevice || !rightDevice)
            return;
        var currDevice = serial === leftDevice.serial ? leftDevice : rightDevice;
        wbTracking_1.workbenchTracking.startEventTrack('rp_loading_time', {
            rp_started_at: new Date().toLocaleString(),
        });
        socket.send({
            event: isRPStarted ? consts_1.SOCKET_SEND_EVENTS.RpStop : consts_1.SOCKET_SEND_EVENTS.RpStart,
            serial: serial,
            package: currApp.package,
            platform: currDevice.platform,
        });
        setIsRPStarted(function (prev) { return !prev; });
        if (isRPStarted) {
            resetRp();
        }
        setButtonDisabled(true);
        setTimeout(function () {
            setButtonDisabled(false);
        }, isRPStarted ? RP_DISABLE_TIMEOUT : RP_ENABLE_TIMEOUT);
    };
    var handleSearch = function (e) {
        setSearch(e.target.value);
    };
    var handleBooleanTestToggle = function (_a) {
        var name = _a.name, value = _a.value;
        return function () {
            // @ts-ignore
            var newValue = (!exports.BooleanTestTypes[value.toLocaleLowerCase()]).toString();
            updateTestValue({
                serial: serial,
                name: name,
                newValue: newValue,
            });
            wbTracking_1.workbenchTracking.endEventTrack('experiment_value_changed', {
                experiment_name: name,
                old_value: value,
                new_value: newValue,
            });
        };
    };
    var handleStringTestChange = function (_a) {
        var name = _a.name, value = _a.value;
        updateTestValue({
            serial: serial,
            name: name,
            newValue: value,
        });
        wbTracking_1.workbenchTracking.endEventTrack('experiment_value_changed', {
            experiment_name: name,
            old_value: value,
            new_value: value,
        });
    };
    var handleRpModeChange = function (mode) { return function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCurrentMode(mode);
                    if (mode === RPModes.ADDED) {
                        setAddedExperiments(diffObject.diff[EXPERIMENTS_DIFF_TABS.static_experiments][EXPERIMENTS_DIFF_TABS.added].map(function (test) { return Object.keys(test)[0]; }));
                        return [2 /*return*/];
                    }
                    setAddedExperiments([]);
                    if (!(mode === RPModes.TRACK_INDIVIDUAL || mode === RPModes.MUTE_INDIVIDUAL)) return [3 /*break*/, 2];
                    return [4 /*yield*/, updateTrackAllMutation.mutateAsync({
                            serial: serial,
                            body: {
                                package: currAppVer.package,
                                version_name: currAppVer.version_name,
                                version_code: currAppVer.version_code,
                                track_all: mode === RPModes.MUTE_INDIVIDUAL,
                            },
                        })];
                case 1:
                    _a.sent();
                    setRp(function (prev) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[serial] = __assign(__assign({}, prev[serial]), { experiments: prev[serial].experiments.map(function (test) { return (__assign(__assign({}, test), { is_tracked: mode === RPModes.MUTE_INDIVIDUAL })); }) }), _a)));
                    });
                    return [2 /*return*/];
                case 2: return [2 /*return*/];
            }
        });
    }); }; };
    var handleTrackToggle = function (_a) {
        var name = _a.name, add = _a.add;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, updateTrackOneMutation.mutateAsync({
                            serial: serial,
                            body: {
                                package: currAppVer.package,
                                version_name: currAppVer.version_name,
                                version_code: currAppVer.version_code,
                                experiment_name: name,
                                add: add,
                            },
                        })];
                    case 1:
                        _b.sent();
                        setRp(function (prev) {
                            var _a;
                            return (__assign(__assign({}, prev), (_a = {}, _a[serial] = __assign(__assign({}, prev[serial]), { experiments: prev[serial].experiments.map(function (test) { return (__assign(__assign({}, test), (test.name === name ? { is_tracked: add } : {}))); }) }), _a)));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    var renderStringDiff = function (value) {
        return react_1.default.createElement("li", { key: value }, value);
    };
    var onToggleFavorite = function (experiment_name) { return function () {
        if (!experiment_name || !favoriteExperiments)
            return;
        var favoriteExperiment = favoriteExperiments.find(function (_a) {
            var name = _a.name;
            return name === experiment_name;
        });
        if (favoriteExperiment) {
            experimentRemoveFromFavorites(favoriteExperiment.id);
        }
        else {
            experimentAddToFavorites(experiment_name);
        }
    }; };
    var renderObjectDiff = function (value) {
        return Object.entries(value).map(function (_a) {
            var k = _a[0], v = _a[1];
            if (v !== null && v.constructor === Object) {
                return (react_1.default.createElement("li", { key: k },
                    react_1.default.createElement("h3", { className: "diff-title" }, k),
                    react_1.default.createElement("ul", { className: "diff-list-values-inner" }, renderObjectDiff(v))));
            }
            return (react_1.default.createElement("li", { key: k },
                react_1.default.createElement("h3", { className: "diff-title" }, k),
                react_1.default.createElement("p", { className: "diff-desc" }, v)));
        });
    };
    (0, react_1.useEffect)(function () {
        if (deviceRP && deviceRP.experiments) {
            handleRpModeChange(RPModes.MUTE_INDIVIDUAL);
        }
    }, [deviceRP]);
    return (react_1.default.createElement("div", { className: "wb-rp-device-container", style: !show ? { display: 'none' } : {} },
        react_1.default.createElement("div", { className: "wb-rp-device-header" },
            react_1.default.createElement(antd_1.Button, { disabled: buttonDisabled, size: "large", style: __assign({ width: '100%' }, (isRPStarted
                    ? {
                        backgroundColor: '#5B8C00',
                        color: '#fff',
                    }
                    : {})), onClick: handleRPClick }, !isRPStarted ? 'Start RP' : 'Stop RP')),
        react_1.default.createElement("div", { className: "wb-rp-search" },
            react_1.default.createElement(antd_1.Input, { value: search || undefined, onChange: handleSearch, placeholder: "Search Experiments" })),
        react_1.default.createElement(Collapse, { bordered: false, defaultActiveKey: Object.values(RP_COLLAPSE_KEYS), expandIconPosition: "right" },
            react_1.default.createElement(Collapse.Panel, { key: RP_COLLAPSE_KEYS.EXPERIMENTS_DIFF, header: "Experiments Diff" }, diffObject && diffObject.diff && diffObject.diff[EXPERIMENTS_DIFF_TABS.static_experiments] && (react_1.default.createElement(Tabs, null, Object.entries(diffObject.diff[EXPERIMENTS_DIFF_TABS.static_experiments]).map(function (_a) {
                var type = _a[0], values = _a[1];
                return (react_1.default.createElement(Tabs.TabPane, { tab: type, key: type, className: "wb-rp-experiments-diff-tab" },
                    react_1.default.createElement("div", { className: "wb-rp-experiments-diff-tab-content" },
                        react_1.default.createElement("ul", { className: "diff-list-values" }, values.map(function (value) {
                            return typeof value === 'string' ? renderStringDiff(value) : renderObjectDiff(value);
                        })))));
            })))),
            react_1.default.createElement(Collapse.Panel, { className: "wb-rp-experiments-current-container", key: RP_COLLAPSE_KEYS.EXPERIMENTS_CURRENT, header: "Current Experiments" },
                react_1.default.createElement("div", { className: "wb-rp-experiments-current" },
                    react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio" },
                        react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-upper" },
                            react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-item" },
                                react_1.default.createElement(antd_1.Radio, { checked: currentMode === RPModes.TRACK_INDIVIDUAL, onChange: handleRpModeChange(RPModes.TRACK_INDIVIDUAL), disabled: !experiments || !experiments.length }),
                                "Track individual"),
                            react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-item" },
                                react_1.default.createElement(antd_1.Radio, { checked: currentMode === RPModes.MUTE_INDIVIDUAL, onChange: handleRpModeChange(RPModes.MUTE_INDIVIDUAL), disabled: !experiments || !experiments.length }),
                                "Mute individual")),
                        react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-lower" },
                            react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-item" },
                                react_1.default.createElement(antd_1.Radio, { checked: currentMode === RPModes.ADDED, onChange: handleRpModeChange(RPModes.ADDED), disabled: !experiments || !experiments.length }),
                                "Added"),
                            react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-item" },
                                react_1.default.createElement(antd_1.Radio, { checked: currentMode === RPModes.N2F, onChange: handleRpModeChange(RPModes.N2F), disabled: true }),
                                "N2F"),
                            react_1.default.createElement("div", { className: "wb-rp-experiments-current-radio-item" },
                                react_1.default.createElement(antd_1.Radio, { checked: currentMode === RPModes.ADDED_N2F, onChange: handleRpModeChange(RPModes.ADDED_N2F), disabled: true }),
                                "Added & N2F"))),
                    react_1.default.createElement("div", { className: "wb-rp-experiments-current-list" }, experiments.map(function (_a) {
                        var name = _a.name, value = _a.value, data_type = _a.data_type, time = _a.time, count = _a.count, is_tracked = _a.is_tracked, is_favorite = _a.is_favorite;
                        return (react_1.default.createElement(rp_experiment_1.RPExperiment, { key: name, name: name, time: new Date(time).toLocaleTimeString(), type: getTestType(value, data_type), value: value, count: count, handleBooleanTestToggle: handleBooleanTestToggle, handleStringTestChange: handleStringTestChange, isTracked: is_tracked, handleTrackToggle: handleTrackToggle, isFavorite: is_favorite, onToggleFavorite: onToggleFavorite(name) }));
                    })))))));
};
exports.RPDevice = RPDevice;
