import { uniqBy } from 'lodash';
import * as actionTypes from '../actionTypes/index';
import { NETWORK_LABELS } from '../../constants/networkLabels';

const INIT_STATE = {
  appsInsights: [],
  htmlContent: undefined,
  sendingEmail: false,
  configuration: {},
  ranks: undefined,
  ranksCompre: undefined,
  insightsByIDS: [],
  criticalInsights: [],
  tiktokEffects: null,
  tiktokChallenges: null,
  emails: [],
  errorMessage: null,
};

const insightComparer = (insightA, insightB) => {
  const dateA = new Date(insightA.created);
  const dateB = new Date(insightB.created);

  if (dateA < dateB) {
    return 1;
  }
  if (dateA > dateB) {
    return -1;
  }
  return 0;
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.SET_INSIGHTS: {
      return {
        ...state,
        appsInsights: uniqBy(
          [
            ...state.appsInsights,
            ...action.payload.insights
              .map((insight) => ({ ...insight, appPackageName: action.payload.appPackageName }))
              .sort(insightComparer),
          ],
          'id'
        ),
      };
    }
    case actionTypes.CLEAR_EMAIL_JSON_INSIGHTS: {
      return {
        ...state,
        appsInsights: [],
        htmlContent: undefined,
        sendingEmail: false,
        errorMessage: null,
      };
    }
    case actionTypes.API_REQUEST: {
      if (action.payload.label === NETWORK_LABELS.GET_EMAIL_PREVIEW) {
        return { ...state, htmlContent: undefined };
      }
      if (action.payload.label === NETWORK_LABELS.SEND_EMAIL) {
        return { ...state, sendingEmail: true };
      }
      return {
        ...state,
      };
    }
    case actionTypes.SET_EMAIL_PREVIEW: {
      const { data } = action.payload;
      return {
        ...state,
        htmlContent: data.html || data,
        sendingEmail: false,
        errorMessage: null,
      };
    }
    case actionTypes.SET_EMAIL_PREVIEW_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        htmlContent: null,
        sendingEmail: false,
        errorMessage: error.msg,
      };
    }
    case actionTypes.EMAIL_SENT: {
      const updatedState = {
        ...state,
        sendingEmail: false,
        errorMessage: null,
      };
      if (!action.payload.test_email) {
        updatedState.appsInsights = [];
        updatedState.htmlContent = undefined;
      }

      return updatedState;
    }
    case actionTypes.SEND_EMAIL_FAILED: {
      return {
        ...state,
        sendingEmail: false,
        errorMessage: action.payload.error.msg,
      };
    }
    case actionTypes.SET_CONFIGURATION: {
      return {
        ...state,
        configuration: action.payload.defs,
      };
    }

    case actionTypes.GET_INSIGHTS_BY_IDS_SUCCESS: {
      return {
        ...state,
        criticalInsights: action.payload.insights,
      };
    }

    case actionTypes.GET_TIKTOK_EFFECTS_SUCCESS: {
      return {
        ...state,
        tiktokEffects: action.payload.json,
      };
    }

    case actionTypes.GET_TIKTOK_CHALLENGES_SUCCESS: {
      return {
        ...state,
        tiktokChallenges: action.payload.json,
      };
    }

    case actionTypes.LOAD_EMAILS_SUCCESS: {
      const emails = action.payload.emails.sort((a, b) => {
        const first = a.email.substring(a.email.indexOf('@') + 1);
        const second = b.email.substring(b.email.indexOf('@') + 1);
        return first.localeCompare(second);
      });
      return {
        ...state,
        emails,
      };
    }

    default: {
      return state;
    }
  }
}
