import { Collapse, Icon, List } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import './UJList.scss';

const UJListBase = ({ userFlowTrees, history, title }) => {
  const handleListItemClick = (userFlowSetId) => () => {
    const pathname = history.location.pathname.split('/');

    if (pathname.length < 3) pathname.push(userFlowSetId.toString());
    else pathname[3] = userFlowSetId.toString();

    history.push(pathname.join('/'));
  };

  const renderSubTree = (subTree) => {
    if (!subTree) {
      return;
    }

    if (!subTree.children.length && !subTree.sets.length) return;

    if (subTree.children.length && !subTree.children.some((child) => child.sets.length)) {
      return;
    }

    return (
      <Collapse.Panel header={subTree.name} key={subTree.id}>
        {subTree.children.length > 0 && <Collapse>{subTree.children.map((child) => renderSubTree(child))}</Collapse>}
        {subTree.sets.length > 0 && (
          <List
            size="small"
            header={null}
            footer={null}
            bordered
            dataSource={subTree.sets}
            renderItem={(item) => (
              <List.Item onClick={handleListItemClick(item.id)}>
                {item.release.release_name} - {item.persona.title} - {moment(item.create_at).format('DD/MM/YYYY')}
              </List.Item>
            )}
          />
        )}
      </Collapse.Panel>
    );
  };

  return (
    <div className="ujlist">
      <h4 className="ujlist-title">{title}</h4>
      <Collapse switcherIcon={<Icon type="down" />}>{userFlowTrees.map((subTree) => renderSubTree(subTree))}</Collapse>
    </div>
  );
};

export const UJList = withRouter(UJListBase);
