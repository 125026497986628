import React from 'react';
import { INSIGHT_TYPE, INSIGHT_CAMPAIGN_SUBTYPE, MARKETING_INSIGHT_TYPES } from '../../../constants/insightType';
import { Select, Input } from 'antd';
import { useState } from 'react';

const CATEGORIES = {
  mass_market: 'Mass Market',
  mass_market_by_segment: 'Mass Market by Segment',
  automated: 'Automated',
};

const CATEGORY_OPTIONS = Object.keys(CATEGORIES).map((key) => ({
  text: CATEGORIES[key],
  value: key,
  id: key,
  key,
}));

const FUNNEL_STAGES = {
  prospects_nursery: 'Prospects/Nursery',
  onboarding: 'Onboarding',
  retention: 'Cross-Sell, Up-Sell & Retention',
  lapsed: 'Lapsed',
  transactional: 'Transactional/Process',
};

const FUNNEL_STAGE_OPTIONS = Object.keys(FUNNEL_STAGES).map((key) => ({
  text: FUNNEL_STAGES[key],
  value: key,
  id: key,
  key,
}));

const INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS = Object.keys(INSIGHT_CAMPAIGN_SUBTYPE).map((type) => ({
  text: INSIGHT_CAMPAIGN_SUBTYPE[type],
  value: type,
  id: type,
  key: type,
}));

export default function InsightMarketingType({ insight, handleInsChange }) {
  const [locEmailTitle, locEmailTitleChange] = useState(insight.content.emailTitle);

  const updateMarkType = (key, val) => {
    handleInsChange({
      content: {
        ...insight.content,
        [key]: val,
      },
    });
  };

  return (
    <div className="ins-marketing-types">
      <div className="fields">
        <div className="field width-25p">
          <label>Email Title</label>
          <Input
            value={locEmailTitle}
            onChange={(e) => locEmailTitleChange(e.target.value)}
            onBlur={(e) => updateMarkType('emailTitle', e.target.value)}
          />
        </div>

        <div className="field width-25p">
          <label>Category(sub_type)</label>
          <Select
            value={insight.content.sub_type}
            onChange={(val) => updateMarkType('sub_type', val)}
            getPopupContainer={(trNode) => trNode}
          >
            {INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="field width-25p">
          <label>Category</label>
          <Select
            value={insight.content.category}
            onChange={(val) => updateMarkType('category', val)}
            getPopupContainer={(trNode) => trNode}
          >
            {CATEGORY_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="field width-25p">
          <label>Funnel Stage</label>
          <Select
            value={insight.content.funnelStage}
            onChange={(val) => updateMarkType('funnelStage', val)}
            getPopupContainer={(trNode) => trNode}
          >
            {FUNNEL_STAGE_OPTIONS.map((opt) => (
              <Select.Option value={opt.value} key={opt.key}>
                {opt.text}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
}
