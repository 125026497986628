"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WbServicesMock = void 0;
var consts_1 = require("components/workbench_v2/utils/consts");
var diff_1 = require("./diff");
var screens_1 = require("./screens");
var RESPONSE_TIME_MS = 500;
var SOCKET_RESPONSE_TIME_MS = 1500;
var MOCK_SERIAL = 'R5CRC1WXD4V';
var mockResponseWrapper = function (data) {
    return new Promise(function (res, _) {
        return setTimeout(function () {
            res(data);
        }, RESPONSE_TIME_MS);
    });
};
var WbServices = /** @class */ (function () {
    function WbServices() {
        var _a;
        this.mockEnabled = false;
        this.eventToMock = (_a = {},
            _a[consts_1.SOCKET_SEND_EVENTS.GetDiffData] = diff_1.DIFF_MOCK,
            _a);
    }
    WbServices.prototype.setEnabled = function (isEnabled) {
        this.mockEnabled = Boolean(isEnabled);
    };
    WbServices.prototype.getDevicesMock = function () {
        var data = {
            devices: [
                {
                    serial: MOCK_SERIAL,
                    model: 'SM-G991B',
                    platform: 'android',
                    os_version: '11',
                    device_name: 'IN-SDK-5',
                },
            ],
            meta: {
                add_client_ver: '41',
                adb_client_host: 'host.docker.internal',
                adb_client_port: 5037,
            },
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.getVersionMock = function () {
        var data = { version: '1.0.0' };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.getDevicesAppsMock = function () {
        var data = {
            apps: [
                {
                    package: 'com.google.android.youtube',
                    version_code: '1520954816',
                    version_name: '18.32.36',
                },
            ],
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.getCurrentAppMock = function () {
        var data = {
            package: 'com.google.android.youtube',
            version_code: '1520954816',
            version_name: '18.32.36',
            platform: 'android',
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.getDeviceScreenMock = function () {
        var data = {
            image: screens_1.SCREEN_MOCK,
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.saveDeviceScreenshotToS3Mock = function () {
        var data = {
            url: 'https://watchful-raw-screenshots.s3.amazonaws.com/devices/R5CRC1WXD4V/1680512039557392.png',
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.saveDeviceXmlToS3Mock = function () {
        var data = {
            url: '',
        };
        return mockResponseWrapper(data);
    };
    WbServices.prototype.getWbServicesMockEndpoints = function (serial) {
        var _a, _b;
        if (serial === void 0) { serial = MOCK_SERIAL; }
        return {
            GET: (_a = {
                    '/devices': this.getDevicesMock,
                    '/version': this.getVersionMock
                },
                _a["/devices/".concat(serial, "/apps")] = this.getDevicesAppsMock,
                _a["/devices/".concat(serial, "/current_app")] = this.getCurrentAppMock,
                _a["/devices/".concat(serial, "/screen")] = this.getDeviceScreenMock,
                _a["/devices/".concat(serial, "/screenshot")] = this.getDeviceScreenMock,
                _a),
            POST: (_b = {},
                _b["/devices/".concat(serial, "/s3/screenshot")] = this.saveDeviceScreenshotToS3Mock,
                _b["/devices/".concat(serial, "/s3/xml")] = this.saveDeviceXmlToS3Mock,
                _b),
        };
    };
    WbServices.prototype.initMockSocket = function (handleData) {
        var _this = this;
        var returnAsData = function (obj) {
            return {
                data: {
                    text: function () {
                        return JSON.stringify(obj);
                    },
                },
            };
        };
        var socket = {
            onmessage: handleData,
            send: function (evt) {
                var data = JSON.parse(evt);
                var mockResponse = _this.eventToMock[data.event];
                if (!mockResponse)
                    return;
                handleData(returnAsData(mockResponse));
            },
            onerror: function () { },
            onclose: function () { },
            close: function () { },
            readyState: 1,
        };
        var interval = setInterval(function () {
            socket.onmessage(returnAsData({
                event: 'screen',
                device_serial: 'R5CRC1WXD4V',
                timestamp: '2023-04-03T10:28:34.708941',
                screen: screens_1.SCREEN_MOCK,
            }));
        }, SOCKET_RESPONSE_TIME_MS);
        socket.close = function () {
            clearInterval(interval);
        };
        return socket;
    };
    return WbServices;
}());
exports.WbServicesMock = new WbServices();
