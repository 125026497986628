/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Highlighter from 'react-highlight-words';
import { Button, Checkbox, Typography, Table } from 'antd';
import { getColumnSearchProps } from '../../../utils/helpers';

const { Paragraph } = Typography;

export default ({ verificationFilters, isLoading, handleChangeVerificationFilter, handleRemoveVerificationFilter }) => {
  const [appSearchQuery, appSearchQueryChange] = useState('');

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    appSearchQueryChange(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    appSearchQueryChange('');
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'App',
      key: 'app',
      ...getColumnSearchProps('app.app_name', handleSearch, handleReset),
      render: (value, { app }) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[appSearchQuery]}
          autoEscape
          textToHighlight={`${app.app_name}(${app.package_name}) - ${app.platform}`}
        />
      ),
    },
    {
      title: 'Device Model',
      dataIndex: 'device_model.model',
      filters: [...new Set(verificationFilters.map((x) => x.device_model.model).filter((x) => x))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, record) => record.device_model.model === value,
      filterMultiple: true,
    },
    {
      title: 'Orchestrator',
      dataIndex: 'orchestrator.hostname',
      filters: [...new Set(verificationFilters.map((x) => x.orchestrator.hostname).filter((x) => x))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, record) => record.orchestrator.hostname === value,
      filterMultiple: true,
    },
    {
      title: 'Ignore Deviation',
      dataIndex: 'ignore_deviation',
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={() => handleChangeVerificationFilter(record.id, { ignore_deviation: !value })}
        />
      ),
    },
    {
      title: 'Ignore New Version',
      dataIndex: 'ignore_new_version',
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={() => handleChangeVerificationFilter(record.id, { ignore_new_version: !value })}
        />
      ),
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      render: (value, record) => (
        <Paragraph editable={{ onChange: (x) => handleChangeVerificationFilter(record.id, { reason: x }) }}>
          {value || ''}
        </Paragraph>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) => (
        <div>
          <Button icon="delete" onClick={() => handleRemoveVerificationFilter(record.id)} />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={verificationFilters.filter((x) =>
          x.app.app_name.toLowerCase().includes(appSearchQuery.toLowerCase())
        )}
        columns={columns}
        rowKey={(record) => record.id}
      />
    </div>
  );
};
