/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, message } from 'antd';
import { isEmpty } from 'lodash';
import {
  deleteUser,
  addUser,
  editUserDetails,
  editUserApps,
  deleteUserApp,
  getAllUsers,
  getAllApps,
  getUserApps,
  changeUserPassword,
  getProductsForUser,
  getUserDetails,
} from '../../redux/actions/usersPage';
import EditableUserTable from './usersTable/editableUserTable';
import EditSubscriptionModal from './subscriptions/editSubscriptionModal';
import AddUserModal from './addUser/AddUserModal';
import EditUserAppsModal from './editUsersApps/EditUserAppsModal';
import { ALL_PLATFORMS } from '../../constants/platforms';
import ChangePasswordModal from './changePassword/ChangePasswordModal';
import { loadLabels } from '../../redux/actions/insightPage';
import {
  getUserSubscriptions,
  createSubscription,
  updSubscription,
  delSubscription,
  updEmailPreferences,
} from '../../redux/actions/subscriptions';
import { getAllUserGroups } from '../../redux/actions/userGroupsPage';
import { getAllPersonas } from "../../redux/actions/personas";

class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addUserModalVisible: false,
      editUserAppsModalVisible: false,
      userID: null,
      changePasswordMovalVisible: false,
      changePasswordUserID: null,
      editUserSubscriptionsModalVisible: false,
    };
  }

  componentDidMount = () => {
    if (isEmpty(this.props.allApps)) {
      this.props.getAllApps(ALL_PLATFORMS);
    }
    if (isEmpty(this.props.allUsers)) {
      this.props.getAllUsers();
    }
    if (isEmpty(this.props.labelsSuggestions.filter((l) => l.id !== -1))) {
      this.props.loadLabels();
    }
    if (isEmpty(this.props.allUserGroups)) {
      this.props.getAllUserGroups();
    }
    this.props.getAllPersonas();
  };

  editUserApps = (userID) => {
    this.setState({ editUserAppsModalVisible: true, userID });
    this.props.getUserApps({ id: userID });
  };

  editUserSubscriptions = (userID) => {
    this.setState({ editUserSubscriptionsModalVisible: true, userID });
    this.props.getUserSubscriptions({ userID });
    this.props.getProductsForUser({ userID });
    this.props.getUserDetails({ userID });
  };

  changePassword = (userID) => {
    this.setState({ changePasswordMovalVisible: true, changePasswordUserID: userID });
  };

  handleChangePassword = (userID, password) => {
    this.props.changeUserPassword(userID, password);
  };

  handleSaveAppsForUser = (userID, userApps) => {
    console.log(userID, userApps);
    this.props.editUserApps(userID, userApps);
  };

  handleDeleteAppForUser = (userID, appID) => {
    this.props.deleteUserApp(userID, appID);
  };

  handleAddUser = (values) => {
    const org = this.props.allUserGroups.find((x) => x.id === values.organization_id);

    const userOfThisOrg = this.props.allUsers.filter((user) => user.organization_id === org.id);
    if (org.user_num_limit < userOfThisOrg.length) {
      message.error(`Can't create user ${values.email}, max num of users for ${org.name} is reached`, 5);
      return;
    }
    this.props.addUser({
      first_name: values.first_name,
      last_name: values.last_name,
      organization_id: values.organization_id,
      role: values.role,
      department: values.department,
      phone: values.phone,
      email: values.email,
      password: values.password,
    });
  };

  handleCreateSubscription = (userID, data) => {
    const modData = {
      ...data,
      critical_insight: data.label_ids.includes(-1),
      label_ids: data.label_ids.filter((l) => l !== -1),
    };
    this.props.createSubscription(userID, modData);
  };

  handleEditSubscription = (userID, subscriptionID, data) => {
    const modData = {
      ...data,
      critical_insight: data.label_ids.includes(-1),
      label_ids: data.label_ids.filter((l) => l !== -1),
    };
    this.props.updSubscription(userID, subscriptionID, modData);
  };

  render() {
    const { allUsers, status, error, allUserGroups } = this.props;
    if (allUsers.length === 0) {
      return <div className="users">{status}</div>;
    }

    return (
      <div className="users">
        <Button onClick={() => this.setState({ addUserModalVisible: true })} type="primary" style={{ margin: 16 }}>
          Add User
        </Button>
        {error && (
          <Alert
            message={error.message}
            description={error.response.body && (error.response.body.error || JSON.stringify(error.response.body))}
            type="error"
            showIcon
          />
        )}
        <EditableUserTable
          allUsers={allUsers}
          deleteUser={(id) => this.props.deleteUser({ id })}
          saveUserData={(userData) => this.props.editUserDetails(userData)}
          editUserApps={(id) => this.editUserApps(id)}
          changePassword={this.changePassword}
          status={this.props.status}
          editUserSubscriptions={(id) => this.editUserSubscriptions(id)}
          orgs={allUserGroups}
        />
        <AddUserModal
          addUser={this.handleAddUser}
          modalVisible={this.state.addUserModalVisible}
          setIsVisible={(visible) => this.setState({ addUserModalVisible: visible })}
          orgs={allUserGroups}
        />
        {this.state.changePasswordMovalVisible && (
          <ChangePasswordModal
            hide={() => this.setState({ changePasswordMovalVisible: false })}
            userID={this.state.changePasswordUserID}
            changePassword={this.handleChangePassword}
          />
        )}

        <EditUserAppsModal
          modalVisible={this.state.editUserAppsModalVisible}
          setIsVisible={(visible) => this.setState({ editUserAppsModalVisible: visible })}
          selectedUser={this.props.allUsers.find((user) => user.id === this.state.userID)}
          allApps={this.props.allApps}
          allUsers={this.props.allUsers}
          getAppsForUser={(id) => this.props.getUserApps({ id })}
          saveAppsForUser={this.handleSaveAppsForUser}
          deleteAppForUser={this.handleDeleteAppForUser}
          status={this.props.status}
          personas={this.props.personas}
        />
        <EditSubscriptionModal
          modalVisible={this.state.editUserSubscriptionsModalVisible}
          setIsVisible={(visible) => this.setState({ editUserSubscriptionsModalVisible: visible })}
          selectedUser={this.props.allUsers.find((user) => user.id === this.state.userID)}
          subscriptions={this.props.subscriptions}
          products={this.props.products}
          tagsList={this.props.labelsSuggestions}
          userDetails={this.props.userDetails}
          createSubscription={(data) => this.handleCreateSubscription(this.state.userID, data)}
          editSubscription={(subscriptionID, subscription) =>
            this.handleEditSubscription(this.state.userID, subscriptionID, subscription)
          }
          deleteSubscription={(subscriptionID) => this.props.delSubscription(this.state.userID, subscriptionID)}
          updEmailPreferences={(emailPreferences) =>
            this.props.updEmailPreferences(this.state.userID, emailPreferences)
          }
        />
      </div>
    );
  }
}

const mapStateToProps = ({ usersPage, insightPage, subscriptions, userGroupsPage, personasPage }) => ({
  allApps: usersPage.allApps,
  allAppsObject: usersPage.allAppsObject,
  allUsers: usersPage.allUsers,
  status: usersPage.status,
  error: usersPage.error,
  subscriptions: subscriptions.subscriptions,
  products: usersPage.productsForUser,
  labelsSuggestions: insightPage.labels ? [{ id: -1, name: 'critical insight' }, ...insightPage.labels] : null,
  userDetails: usersPage.userDetails,
  allUserGroups: userGroupsPage.allUserGroups,
  personas: personasPage.personas,
});

export default connect(mapStateToProps, {
  getAllUsers,
  getAllApps,
  deleteUser,
  addUser,
  deleteUserApp,
  editUserDetails,
  editUserApps,
  getUserApps,
  changeUserPassword,
  getUserSubscriptions,
  getProductsForUser,
  getUserDetails,
  loadLabels,
  createSubscription,
  updSubscription,
  delSubscription,
  updEmailPreferences,
  getAllUserGroups,
  getAllPersonas,
})(Users);
