import { getAbandonedInsightsApi, getAbandonedInsightsCountApi } from '../actions/abandonedInsightsPage';
import * as AT from '../actionTypes';

export default ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_ABANDONED_INSIGHTS: {
        dispatch(getAbandonedInsightsApi(action.payload.untilDate));
        return next(action);
      }
      case AT.GET_ABANDONED_INSIGHTS_COUNT: {
        dispatch(getAbandonedInsightsCountApi());
        return next(action);
      }
      default:
        return next(action);
    }
  };
