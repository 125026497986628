import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Checkbox, Icon, message, Popconfirm, Popover, Select, Table, Tooltip } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import InsightImagesModalEdit from './InsightImagesModalEdit/InsightImagesModalEdit';
import InsightAssetUploader2 from './InsightAssetUploader2';
import {
  addAssetToInsight,
  addAssetToVariant,
  addReleaseLinkToVariant,
  addVarintToABTest,
  copyVariantsOfInsight,
  getInsightsOfAbtest,
  removeAssetFromInsight,
  removeAssetFromVariant,
  remReleaseLinkToVariant,
  reOrderInsightsAssets,
  updAssetByID,
  updInsThumb,
  updVariantInABTest,
} from '../../../api/insightsAPI';

import ins_ass_video_ico from '../../../assets/icons/ins_ass_video.svg';
import ins_ass_img_ico from '../../../assets/icons/ins_ass_img.svg';
import ins_ass_html_ico from '../../../assets/icons/ins_ass_html.svg';

import ins_ass_del_ico from '../../../assets/icons/ins_ass_del.svg';
import ins_ass_edit_ico from '../../../assets/icons/ins_ass_edit.svg';
import ins_ass_blur_faces_ico from '../../../assets/icons/ins_ass_blur_faces.svg';
import { ABBR_MAP_TYPE } from '../../../constants/insightType';
import duplicate_variants_ico from '../../../assets/icons/duplicate_variant.svg';

const DragHandle = SortableHandle((props) => (
  <Icon
    type="menu"
    style={{
      cursor: 'grab',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} style={{ zIndex: 1001 }} />);
const ASortableContainer = SortableContainer((props) => <tbody {...props} />);

const abc = 'ABCDEFGHIJK'.split('');

const onErrorCommon = (data) => {
  message.destroy();
  message.error(JSON.parse(data.message).msg);
};

function InsightImages({
  origInsight,
  glAssetsLists,
  isABTest,
  isFetching,
  abTestId,
  releaseId,
  isDisabled,
  isMarketing,
}) {
  const queryClient = useQueryClient();
  window.queryClient = queryClient;
  const [assetsListForModal, assetsListForModalChange] = useState(null);
  const [activeAssetIndex, activeAssetIndexChange] = useState(0);
  const [expRows, expRowsChange] = useState([]);
  const [waitNewAssetList, waitNewAssetListChange] = useState(false);
  const [assetModalOpen, setAssetModalOpen] = useState(false);

  const [assetsLists, assetsListsChange] = useState(glAssetsLists);
  const insightsOfAbTestQuery = useQuery(
    ['insightsOfAbtest', abTestId],
    isABTest ? () => getInsightsOfAbtest(abTestId) : null
  );
  const [insightsOfAbTest, insightsOfAbTestChange] = useState([]);
  const [savingAsset, savingAssetChange] = useState(false);

  useEffect(() => {
    if (!isFetching) {
      assetsListsChange(glAssetsLists);
    }
  }, [isFetching]);

  useEffect(() => {
    if (insightsOfAbTestQuery.isFetched && insightsOfAbTestQuery.data) {
      insightsOfAbTestChange(insightsOfAbTestQuery.data.data);
    }
  }, [abTestId, insightsOfAbTestQuery.status]);

  useEffect(() => {
    if (assetsListForModal) {
      if (isABTest) {
        assetsListForModalChange(assetsLists.find((AL) => AL.id === assetsListForModal.id));
      } else {
        assetsListForModalChange(assetsLists.find((AL) => AL.type === assetsListForModal.type));
      }
      if (waitNewAssetList) {
        const curAl = assetsLists.find((AL) => AL.id === waitNewAssetList.id);
        activeAssetIndexChange(curAl.assets.length - 1);
        waitNewAssetListChange(false);
      }
    }
    if (waitNewAssetList) {
      const curAl = assetsLists.find((AL) => AL.id === waitNewAssetList.id);
      if (curAl.assets.length > waitNewAssetList.assets.length) {
        activeAssetIndexChange(curAl.assets.length - 1);
        assetsListForModalChange(curAl);
        waitNewAssetListChange(false);
      }
    }
  }, [assetsLists]);

  const addAssetToVariantMutation = useMutation(addAssetToVariant, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Asset was added');
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
    onError: onErrorCommon,
  });
  const addAssetToInsMutation = useMutation(addAssetToInsight, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Asset was added');
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
    onError: onErrorCommon,
  });
  const removeAssetToVariantMutation = useMutation(removeAssetFromVariant, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Asset was removed');
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
    onError: onErrorCommon,
  });
  const removeAssetToInsMutation = useMutation(removeAssetFromInsight, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Asset was removed');
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
    },
    onError: onErrorCommon,
  });
  const updateAssetMutation = useMutation(updAssetByID, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Asset was saved');
      queryClient.invalidateQueries(['ABTestVarints']);
      queryClient.invalidateQueries(['InsightAssets']);
      savingAssetChange(false);
    },
    onError: onErrorCommon,
  });
  const updateThumbnailMutation = useMutation(updInsThumb, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Thumbnail was updated');
      queryClient.setQueryData(['InsightData', variables.insight_id], {
        data: {
          ...origInsight,
          ...res.data,
        },
      });
    },
    onError: onErrorCommon,
  });
  const addVariantMutation = useMutation(addVarintToABTest, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Variant was created');
      queryClient.invalidateQueries(['ABTestVarints']);
    },
    onError: onErrorCommon,
  });

  const addReleaseMutation = useMutation(addReleaseLinkToVariant, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Variant was updated');
      queryClient.invalidateQueries(['ABTestVarints']);
    },
    onError: onErrorCommon,
  });
  const remReleaseMutation = useMutation(remReleaseLinkToVariant, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Variant was updated');
      queryClient.invalidateQueries(['ABTestVarints']);
    },
    onError: onErrorCommon,
  });
  const updVariantInABTestMutation = useMutation(updVariantInABTest, {
    onSuccess: (res, variables) => {
      message.destroy();
      message.success('Variant was updated');
      queryClient.invalidateQueries(['ABTestVarints']);
    },
    onError: onErrorCommon,
  });
  const reOrderInsightsAssetsMut = useMutation(reOrderInsightsAssets, {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(['InsightAssets']);
      queryClient.invalidateQueries(['ABTestVarints']);
    },
    onError: onErrorCommon,
  });

  const copyVariantsMutation = useMutation(copyVariantsOfInsight, {
    onSuccess: (res, variables) => {
      queryClient.invalidateQueries(['insightsOfAbtest', abTestId]);
      assetsListsChange(res.data);
      expRowsChange(res.data.map((aL) => String(aL.id)));
    },
    onError: onErrorCommon,
  });

  const updAsset = (asset_id, data) => {
    updateAssetMutation.mutateAsync({ asset_id, data });
  };
  const updThumb = (data) => {
    updateThumbnailMutation.mutateAsync({ insight_id: origInsight.id, data });
  };

  const updVariant = (variant_id, data) => {
    updVariantInABTestMutation.mutateAsync({
      variant_id,
      data,
    });
  };

  const addVar = () => {
    if (addVariantMutation.isLoading) {
      return;
    }
    const data = {
      value: '',
    };
    if (!releaseId) {
      message.destroy();
      message.error('Release must be choosen');
      return;
    }
    if (!abTestId) {
      message.destroy();
      message.error('abTest must be choosen');
      return;
    }
    if (releaseId) {
      data.release_id = releaseId;
    }
    if (!assetsLists.find((aL) => aL.control)) {
      data.control = true;
      data.type = '';
      addVariantMutation.mutateAsync({ abtest_id: abTestId, data });
      return;
    }
    data.control = false;
    data.type = abc.find((l) => !assetsLists.filter((aL) => aL.type).find((aL) => aL.type.toUpperCase() === l));
    addVariantMutation.mutateAsync({ abtest_id: abTestId, data });
  };
  const onReleaseAdd = (variant_id, release_id) => {
    addReleaseMutation.mutateAsync({
      abtest_id: abTestId,
      variant_id,
      data: { release_id },
    });
  };
  const onReleaseRemove = (variant_id, release_id) => {
    remReleaseMutation.mutateAsync({
      abtest_id: abTestId,
      variant_id,
      release_id,
    });
  };

  const addAssetCommon = (data, asset_type, variant_id) => {
    let curAL;
    if (isABTest) {
      addAssetToVariantMutation.mutateAsync({
        variant_id,
        data: {
          ...data,
          insight_asset_type: asset_type,
        },
      });
      curAL = assetsLists.find((aL) => aL.id === variant_id);
    } else {
      addAssetToInsMutation.mutateAsync({
        insight_id: origInsight.id,
        data: {
          ...data,
          insight_asset_type: asset_type,
        },
      });
      curAL = assetsLists.find((aL) => aL.type === asset_type);
    }
    waitNewAssetListChange(curAL);
  };

  const removeAssetCommon = (asset_id) => {
    if (isABTest) {
      removeAssetToVariantMutation.mutateAsync(asset_id);
    } else {
      removeAssetToInsMutation.mutateAsync({
        insight_id: origInsight.id,
        asset_id,
      });
    }
  };

  const copyVariantsHandle = (source_insight_id, target_insight_id) => {
    copyVariantsMutation.mutateAsync({ source_insight_id, target_insight_id });
  };

  const openModalForEdit = (rec, index, assetList) => {
    const curAL = assetList || assetsLists.find((aL) => aL.assets.find((a) => a.id === rec.id));
    activeAssetIndexChange(index);
    assetsListForModalChange(curAL);
    setAssetModalOpen(true);
  };

  const columnsListAssets = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (t, rec) => <span className="asset-type-label">{getControlLabel(rec)} </span>,
    },
    { title: 'Value', dataIndex: 'value', key: 'value' },
    {
      title: 'Images',
      dataIndex: 'images',
      key: 'Images',
      render: (t, rec) => getCountAssetsByType(rec, 'image'),
    },
    {
      title: 'Videos',
      dataIndex: 'videos',
      key: 'Videos',
      render: (t, rec) => getCountAssetsByType(rec, 'video'),
    },
    {
      title: '',
      key: 'operations',
      render: (val, rec) => (
        <div className="al-ass-manage">
          <div className="item">
            <img src={ins_ass_edit_ico} onClick={() => openModalForEdit(null, 0, rec)} alt="edit variant" />
          </div>
          <Popconfirm
            title="Are you sure you want to delete this variant?"
            onConfirm={() => onReleaseRemove(rec.id, releaseId)}
          >
            <div className="item">
              <img src={ins_ass_del_ico} alt="delete variant" />
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsListAssetsIsMarketing = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (t, rec) => <span className="asset-type-label">{getControlLabel(rec)} </span>,
    },
    {
      title: 'Images',
      dataIndex: 'images',
      key: 'Images',
      render: (t, rec) => getCountAssetsByType(rec, 'image'),
    },
    {
      title: 'HTMLs',
      dataIndex: 'htmls',
      key: 'HTMLs',
      render: (t, rec) => getCountAssetsByType(rec, 'html'),
    },
    {
      title: '',
      key: 'operations',
      render: (val, rec) => (
        <div className="al-ass-manage">
          <img src={ins_ass_edit_ico} onClick={() => openModalForEdit(null, 0, rec)} />
        </div>
      ),
    },
  ];

  const columnsAsset = [
    {
      dataIndex: 'order_index',
      width: '30px',
      render: (val, rec) => <DragHandle rec={rec} />,
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (text, a, index) =>
        a.type === 'image' ? (
          <Popover
            overlayClassName="asset-preview-full"
            content={<img className="asset-preview-full-img" src={a.url_annotated} />}
          >
            <img className="asset-preview" src={a.url_annotated} onClick={() => openModalForEdit(a, index)} />
          </Popover>
        ) : (
          <Popover
            overlayClassName="asset-preview-full"
            content={<video src={a.url_annotated} autoPlay loop muted className="asset-preview-full-img" />}
          >
            <video src={a.url_annotated} autoPlay loop muted className="asset-preview" />
          </Popover>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'type',
      render: (text, a) => getAssetTypeIco(a),
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation',
      key: 'orientation',
      render: (value, record) =>
        record.type !== 'html' && (
          <Select
            placeholder="Platform"
            value={value}
            onChange={(orientation) => updAsset(record.id, { orientation })}
            style={{ width: '120px' }}
          >
            {['portrait', 'landscape'].map((x) => (
              <Select.Option key={x} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        ),
    },
    {
      title: 'Visible',
      dataIndex: 'is_hidden',
      width: '65px',
      render: (value, record) => (
        <Checkbox
          checked={!value}
          onChange={(e) => updAsset(record.id, { is_hidden: !e.target.checked })}
        />
      ),
    },
    {
      title: 'Blur Faces',
      dataIndex: 'blur_faces',
      key: 'blur_faces',
      render: (val, record) =>
        record.type === 'video' && record.blur_faces ? <img src={ins_ass_blur_faces_ico} /> : null,
    },
    {
      title: 'Set as thumbnail',
      dataIndex: '',
      key: 'thumb',
      render: (value, record) => (
        <div>
          {record.type === 'image' && (
            <Button
              style={{ margin: '0 4px' }}
              onClick={() =>
                updThumb({
                  url_raw: record.url_raw,
                  url_annotated: record.url_annotated,
                  orientation: record.orientation,
                  rects: record.rects,
                  blur_faces: record.blur_faces,
                })
              }
            >
              Set as thumbnail
            </Button>
          )}
        </div>
      ),
    },
    {
      title: '',
      key: '',
      render: (val, rec, index) => (
        <div className="ins-ass-manage">
          <img src={ins_ass_edit_ico} onClick={() => openModalForEdit(rec, index)} />
          <Popconfirm title="Are you sure?" onConfirm={() => removeAssetCommon(rec.id)}>
            <img src={ins_ass_del_ico} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsAssetModal = [
    {
      dataIndex: 'order_index',
      width: '30px',
      render: (val, rec) => <DragHandle rec={rec} />,
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (text, a) =>
        a.type === 'image' ? (
          <Popover
            overlayClassName="asset-preview-full"
            content={<img className="asset-preview-full-img" src={a.url_annotated} />}
          >
            <img className="asset-preview" src={a.url_annotated} />
          </Popover>
        ) : (
          <div className="ins-video-preview">
            <video src={a.url_annotated} autoPlay loop muted />
          </div>
        ),
    },
    {
      title: 'Type',
      dataIndex: 'Type',
      key: 'type',
      render: (text, a) => getAssetTypeIco(a),
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation',
      key: 'orientation',
      render: (value, record) => (
        <Select
          placeholder="Platform"
          value={value}
          onChange={(orientation) => updAsset(record.id, { orientation })}
          style={{ width: '120px' }}
        >
          {['portrait', 'landscape'].map((x) => (
            <Select.Option key={x} value={x}>
              {x}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Visible',
      dataIndex: 'is_hidden',
      width: '65px',
      render: (value, record) => (
        <Checkbox
          checked={!value}
          onChange={(e) => updAsset(record.id, { is_hidden: !e.target.checked })}
        />
      ),
    },
    {
      title: 'Set as thumbnail',
      dataIndex: '',
      key: 'thumb',
      render: (value, record) => (
        <div>
          {record.type === 'image' && (
            <Button
              style={{ margin: '0 4px' }}
              onClick={() =>
                updThumb({
                  url_raw: record.url_raw,
                  url_annotated: record.url_annotated,
                  orientation: record.orientation,
                  rects: record.rects,
                  blur_faces: record.blur_faces,
                })
              }
            >
              Set as thumbnail
            </Button>
          )}
        </div>
      ),
    },
    {
      title: '',
      key: '',
      render: (val, rec) => (
        <div className="ins-ass-manage">
          <Popconfirm title="Are you sure?" onConfirm={() => removeAssetCommon(rec.id)}>
            <img src={ins_ass_del_ico} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const getControlLabel = (assetsList) => {
    if (isABTest && assetsList.control) {
      return 'Control';
    }
    if (isABTest && !assetsList.control) {
      return assetsList.type;
    }
    return assetsList.type;
  };
  const getCountAssetsByType = (assetsList, type) => assetsList.assets.filter((a) => a.type === type).length;

  const getAssetTypeIco = (a) => {
    if (a.type === 'image') {
      return <img src={ins_ass_img_ico} />;
    }
    if (a.type === 'video') {
      return <img src={ins_ass_video_ico} />;
    }
    if (a.type === 'html') {
      return <img src={ins_ass_html_ico} />;
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }, aLID) => {
    if (oldIndex !== newIndex) {
      const curAL = assetsLists.find((aL) => aL.id === aLID);
      const newOrder = [...curAL.assets];
      const assetForReorder = newOrder.splice(oldIndex, 1)[0];
      newOrder.splice(newIndex, 0, assetForReorder);
      assetsListsChange(
        assetsLists.map((aL) =>
          aL.id !== aLID
            ? aL
            : {
                ...aL,
                assets: newOrder,
              }
        )
      );
      const qAssetList = assetsLists.findIndex((aL) => aL.id === aLID);
      reOrderInsightsAssetsMut.mutateAsync({
        assets: newOrder.map((ass, i) => ({
          id: ass.id,
          order_index: i + 100 * qAssetList,
        })),
      });
    }
  };

  const DraggableContainer = ({ assetsList, ...props }) => (
    <ASortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={(data) => onSortEnd(data, assetsList.id)}
      assetsListID={assetsList.id}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, assetsList, ...restProps }) => (
    <SortableItem
      index={restProps['data-row-key']}
      assetsListID={assetsList.id}
      {...restProps}
      className={restProps['data-row-key'] === activeAssetIndex ? 'table-bg-variant-asset-selected' : undefined}
    />
  );

  const assetsListTableRender = (assetsList, index, props) => (
    <div className="insight-assets-list">
      {assetsList.assets.length > 0 && (
        <Table
          className="insight-data-assets"
          columns={columnsAsset}
          dataSource={assetsList.assets}
          pagination={false}
          bordered
          rowKey={index}
          components={{
            body: {
              wrapper: (props) => DraggableContainer({ ...props, assetsList }),
              row: (props) => DraggableBodyRow({ ...props, assetsList }),
            },
          }}
          {...props}
        />
      )}
      <InsightAssetUploader2
        addAsset={(data) => addAssetCommon(data, getControlLabel(assetsList), assetsList.id)}
        isMarketing={isMarketing}
      />
    </div>
  );
  const dataSource = isABTest
    ? assetsLists.filter((variant) => variant.releases.some((release) => release.id === releaseId))
    : assetsLists;
  const insightToCopyFrom = isABTest && (
    <Select
      defaultValue={
        <div className="duplicate-wrapper">
          <img src={duplicate_variants_ico} />
          Duplicate variants from...
        </div>
      }
      onChange={(source_insight_id) => copyVariantsHandle(source_insight_id, origInsight.id)}
    >
      {insightsOfAbTest
        .filter((ins) => ins.id !== origInsight.id)
        .map((ins) => (
          <Select.Option key={ins.id} value={ins.id}>
            {moment(ins.start_time).format('YYYY-MM-DD')} [{ABBR_MAP_TYPE[ins.type]}] {ins.title}
          </Select.Option>
        ))}
    </Select>
  );
  return (
    <div className="insight-screens">
      <h3 className="insight-data-item-title">{isMarketing ? 'Campaign Layout' : 'Screens'}</h3>
      {/* Thumbnail */}
      {origInsight.thumbnail && (
        <div className={`thumbnail-wrapper ${origInsight.type !== 'ab_test' ? 'not-ab-test' : ''}`}>
          <div className="thumbnail-text">Thumbnail</div>
          <div className="ins-thumbnail">
            <img src={origInsight.thumbnail} alt="thumbnail" />
          </div>
        </div>
      )}
      {isABTest && (
        <div className="ab-test-add-variant">
          <Button
            onClick={addVar}
            className="add-something"
            icon="plus"
            disabled={
              addVariantMutation.isLoading ||
              queryClient.getQueryState(['ABTestVarints', origInsight.ab_test.id]).isFetching
            }
          >
            Add Variant
          </Button>
          {dataSource.length > 0 && (
            <div className="copy-variant-wrapper">
              <div className="icon">
                <Tooltip
                  placement="top"
                  title="Start over by overwriting the existing variants with content from other insight"
                >
                  <Icon type="info-circle" />
                </Tooltip>
              </div>
              <div className="insight-select">{insightToCopyFrom}</div>
            </div>
          )}
        </div>
      )}

      {dataSource.length > 0 ? (
        <Table
          className="insight-list-assets"
          columns={isMarketing ? columnsListAssetsIsMarketing : columnsListAssets}
          expandedRowRender={(list, index) => assetsListTableRender(list, index, 'full')}
          dataSource={dataSource}
          pagination={false}
          rowKey={(rec) => String(rec.id)}
          expandedRowKeys={expRows}
          expandIcon={(props) =>
            props.expanded ? (
              <Icon
                className="exp-icon"
                type="up"
                {...props}
                onClick={() => expRowsChange(expRows.filter((id) => id !== String(props.record.id)))}
              />
            ) : (
              <Icon
                className="exp-icon"
                type="down"
                {...props}
                onClick={() => expRowsChange([...expRows, String(props.record.id)])}
              />
            )
          }
        />
      ) : (
        <div className="no-variants">
          <div className="insight-select">{insightToCopyFrom}</div>

          <div className="text">
            <bold>New!</bold>Changes you make to variants here won't affect other insights. Each insight now has its own
            variants for experiments. You can start by copying variants over from a previous insight, or build them out
            from scratch
          </div>
        </div>
      )}

      {assetsListForModal && assetsListForModal.assets[0] && (
        <InsightImagesModalEdit
          assetsList={assetsListForModal}
          closeModal={() => {
            setAssetModalOpen(false);
            assetsListForModalChange(null);
          }}
          isABTest={isABTest}
          origInsight={origInsight}
          actAssetIndex={activeAssetIndex}
          assetsListChange={assetsListForModalChange}
          updAsset={updAsset}
          updVariant={updVariant}
          isMarketing={isMarketing}
          tableLayout={() =>
            assetsListTableRender(assetsListForModal, 0, {
              onRowClick: (record, i) => activeAssetIndexChange(i),
              rowClassName: (record, index) => `asset-row ${index === activeAssetIndex ? 'active' : ''}`,
              columns: columnsAssetModal,
            })
          }
          savingAsset={savingAsset}
          savingAssetChange={savingAssetChange}
          visible={assetModalOpen}
        />
      )}
    </div>
  );
}

export default InsightImages;
