import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

// Get all verification filters

export const getAllVerificationFilters = () => ({
  type: AT.GET_ALL_VERIFICATION_FILTERS,
});

export const getAllVerificationFiltersAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'performance/annotations/verification_filters',
    label: NETWORK_LABELS.GET_ALL_VERIFICATION_FILTERS,
    onSuccess: (body, dispatch) => dispatch(getAllVerificationFiltersSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllVerificationFiltersFailed(error)),
  },
});

export const getAllVerificationFiltersSuccess = (data) => ({
  type: AT.GET_ALL_VERIFICATION_FILTERS_SUCCESS,
  payload: { data },
});

export const getAllVerificationFiltersFailed = (error) => ({
  type: AT.GET_ALL_VERIFICATION_FILTERS_FAILED,
  payload: { error },
});

// Update verification filter

export const updVerificationFilter = (filter_id, data) => ({
  type: AT.UPD_VERIFICATION_FILTER,
  payload: {
    filter_id,
    data,
  },
});

export const updVerificationFilterAPI = (filter_id, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/annotations/verification_filters/${filter_id}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_VERIFICATION_FILTER,
    onSuccess: (body, dispatch) => dispatch(updVerificationFilterSuccess(body)),
    onFailure: (error, dispatch) => dispatch(updVerificationFilterFailed(error)),
  },
});

export const updVerificationFilterSuccess = (body) => ({
  type: AT.UPD_VERIFICATION_FILTER_SUCCESS,
  payload: { data: body.data },
});

export const updVerificationFilterFailed = (error) => ({
  type: AT.UPD_VERIFICATION_FILTER_FAILED,
  payload: { error },
});

// Create verification filter

export const addVerificationFilter = (data) => ({
  type: AT.ADD_VERIFICATION_FILTER,
  payload: { data },
});

export const addVerificationFilterAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'performance/annotations/verification_filters',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_VERIFICATION_FILTER,
    onSuccess: (body, dispatch) => dispatch(addVerificationFilterSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addVerificationFilterFailed(error)),
  },
});

export const addVerificationFilterSuccess = (body) => ({
  type: AT.ADD_VERIFICATION_FILTER_SUCCESS,
  payload: { data: body.data },
});

export const addVerificationFilterFailed = (error) => ({
  type: AT.ADD_VERIFICATION_FILTER_FAILED,
  payload: { error },
});

// Delete verification filter

export const delVerificationFilter = (filter_id) => ({
  type: AT.DEL_VERIFICATION_FILTER,
  payload: { filter_id },
});

export const delVerificationFilterAPI = (filter_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `performance/annotations/verification_filters/${filter_id}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_VERIFICATION_FILTER,
    onSuccess: (body, dispatch) => dispatch(delVerificationFilterSuccess(filter_id)),
    onFailure: (error, dispatch) => dispatch(delVerificationFilterFailed(error)),
  },
});

export const delVerificationFilterSuccess = (filter_id) => ({
  type: AT.DEL_VERIFICATION_FILTER_SUCCESS,
  payload: { filter_id },
});

export const delVerificationFilterFailed = (error) => ({
  type: AT.DEL_VERIFICATION_FILTER_FAILED,
  payload: { error },
});
