"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDeleteVertical = exports.DELETE_VERTICAL_KEY = exports.useUpdateVertical = exports.UPDATE_VERTICAL_KEY = exports.useCreateVertical = exports.CREATE_VERTICAL_KEY = exports.useGetVerticals = exports.GET_VERTICALS_KEY = void 0;
var react_query_1 = require("react-query");
// @ts-ignore
var insightsAPI_1 = require("api/insightsAPI");
exports.GET_VERTICALS_KEY = ['GET_VERTICALS'];
var useGetVerticals = function () {
    return (0, react_query_1.useQuery)(exports.GET_VERTICALS_KEY, insightsAPI_1.getVerticals);
};
exports.useGetVerticals = useGetVerticals;
exports.CREATE_VERTICAL_KEY = ['CREATE_VERTICAL'];
var useCreateVertical = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.CREATE_VERTICAL_KEY, insightsAPI_1.createVertical, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_VERTICALS_KEY);
        },
    });
};
exports.useCreateVertical = useCreateVertical;
exports.UPDATE_VERTICAL_KEY = ['UPDATE_VERTICAL'];
var useUpdateVertical = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.UPDATE_VERTICAL_KEY, insightsAPI_1.updateVertical, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_VERTICALS_KEY);
        },
    });
};
exports.useUpdateVertical = useUpdateVertical;
exports.DELETE_VERTICAL_KEY = ['DELETE_VERTICAL'];
var useDeleteVertical = function () {
    var queryClient = (0, react_query_1.useQueryClient)();
    return (0, react_query_1.useMutation)(exports.DELETE_VERTICAL_KEY, insightsAPI_1.deleteVertical, {
        onSuccess: function () {
            queryClient.invalidateQueries(exports.GET_VERTICALS_KEY);
        },
    });
};
exports.useDeleteVertical = useDeleteVertical;
