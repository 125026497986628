import { useEffect, useRef, useState } from 'react';
import { PLATFORMS } from 'constants/platforms';
import { WbServicesMock } from 'components/workbench_v2/mocks/wb_services';
import { CUSTOM_DEVICE } from 'components/workbench_v2/utils/consts';
import { delay } from 'components/workbench_v2/utils/helpers';
import Player from 'components/workbench_v2/vendor/Player';

export const ActionTypes = {
  MouseDown: 0,
  MouseUp: 1,
  MouseMove: 2,
  ScreenOn: 3,
  KeyDown: 4,
  KeyUp: 5,
};

const INITIAL_CANVAS_WIDTH = 360;
const INITIAL_CANVAS_HEIGHT = 800;

const SOCKET_RETRY_WAIT_TIME = 2500;

const fitDeviceToCanvasHeight = (deviceWidth, deviceHeight, canvasHeight) => {
  return {
    width: (deviceWidth / deviceHeight) * canvasHeight + 5,
    height: canvasHeight,
  };
};

export const useVideoCanvasSocket = (
  videoSocketUrl,
  canvasContainerId,
  deviceId,
  platform,
  deviceWidth,
  deviceHeight,
  deviceStreamImage
) => {
  const [deviceConnected, setDeviceConnected] = useState(false);
  const socketRef = useRef(null);
  const playerRef = useRef(null);
  const { width, height } =
    !deviceWidth || !deviceHeight
      ? { width: INITIAL_CANVAS_WIDTH, height: INITIAL_CANVAS_HEIGHT }
      : fitDeviceToCanvasHeight(deviceWidth, deviceHeight, INITIAL_CANVAS_HEIGHT);

  const handleMessage = (msg) => {
    if (!deviceConnected) {
      setDeviceConnected(true);
    }
    playerRef.current.decode(new Uint8Array(msg.data));
  };

  const handleClosed = async (socketUrl) => {
    await delay(SOCKET_RETRY_WAIT_TIME);

    console.log('opening socket again at ', socketUrl);

    const newWs = new WebSocket(socketUrl);
    newWs.binaryType = 'arraybuffer';
    newWs.onmessage = handleMessage;
    newWs.onclose = () => handleClosed(socketUrl);
    socketRef.current = newWs;
  };

  const closeSocket = () => {
    socketRef.current.onclose = null;
    socketRef.current.close();
  };

  // ios images
  useEffect(() => {
    if (platform && platform.includes(PLATFORMS.ios) && deviceStreamImage) {
      const image = new Image();
      image.src = `data:image/png;base64,${deviceStreamImage}`;
      setDeviceConnected(true);

      image.onload = () => {
        image.width = width;
        image.height = height;

        const playerElement = document.getElementById(canvasContainerId);

        if (playerElement.children.length > 1) {
          playerElement.removeChild(playerElement.lastChild);
        }

        playerElement.appendChild(image);
      };
    }
  }, [platform, deviceStreamImage]);

  // android video
  useEffect(() => {
    if (deviceId && videoSocketUrl && !platform.includes(PLATFORMS.ios)) {
      playerRef.current = new Player({
        useWorker: false,
        webgl: 'auto',
        size: { width, height },
      });

      const playerElement = document.getElementById(canvasContainerId);

      if (!playerElement) return;

      if (playerElement.children.length > 1) {
        playerElement.removeChild(playerElement.lastChild);
      }

      playerElement.appendChild(playerRef.current.canvas);
      playerElement.lastChild.style.width = `${width}px`;
      playerElement.lastChild.style.height = `${height}px`;

      const socketUrl = `${videoSocketUrl}?deviceId=${deviceId}`;

      if (deviceId !== CUSTOM_DEVICE.serial && !WbServicesMock.mockEnabled) {
        console.log('opening socket at ', socketUrl);

        socketRef.current = new WebSocket(socketUrl);
        socketRef.current.binaryType = 'arraybuffer';
        socketRef.current.onmessage = handleMessage;
        socketRef.current.onclose = () => handleClosed(socketUrl);

        return closeSocket;
      }
    }
  }, [deviceId, videoSocketUrl, platform]);

  return {
    reconnectDevice() {
      setDeviceConnected(false);
      socketRef.current.close();
    },
    deviceConnected,
    deviceWidth: width,
    deviceHeight: height,
    sendMouseEvent(action, layerX, layerY) {
      if (!socketRef.current || socketRef.current.readyState !== 1) return;

      const x = Math.round(layerX);
      const y = Math.round(layerY);

      socketRef.current.send(
        JSON.stringify({
          actionType: action,
          x,
          y,
        })
      );
    },
    sendControlEvent(action) {
      if (!socketRef.current || socketRef.current.readyState !== 1) return;

      socketRef.current.send(
        JSON.stringify({
          actionType: action,
        })
      );
    },
    sendKeyEvent(action, keycode) {
      if (!socketRef.current || socketRef.current.readyState !== 1) return;

      socketRef.current.send(
        JSON.stringify({
          actionType: action,
          keycode,
        })
      );
    },
  };
};
