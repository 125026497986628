import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Input, Switch, Popconfirm, Menu, Dropdown, Icon } from 'antd';

import { automationStateEnum } from './helpers';

const DEFAULT_TIMEOUT = 4000;

export default function GraphRun({
  automationState,
  automationStateChange,
  graphActions,
  playActionOnFarmer,
  curEdgeIdInGraphRunChange,
  farmerError,
  farmerErrorChange,

  stepIndex,
  stepIndexChange,
  startEdgeID,
  startEdgeIDChange,
}) {
  const [runinng, runinngChange] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    if (automationState === automationStateEnum.Running && graphActions.data && !runinng) {
      console.log('==================== READY TO RUN =====================');
      runinngChange(true);
      startRun();
      farmerErrorChange(null);
    }
  }, [automationState, graphActions]);

  useEffect(() => {
    if (farmerError && runinng) {
      stopRun();
    }
  }, [farmerError]);

  const startRun = () => {
    const index = startEdgeID ? graphActions.data.relationships.findIndex((e) => e.action_id === startEdgeID) : 0;
    playStep(index + 1);
  };

  const playStep = (index) => {
    const contextEdge = graphActions.data.relationships[index - 1];

    if (index - 1 === graphActions.data.relationships.length) {
      stopRun();
      return;
    }
    if (index > graphActions.data.relationships.length) {
      return;
    }
    curEdgeIdInGraphRunChange(contextEdge.action_id);
    playActionOnFarmer(contextEdge);
    stepIndexChange(index);
    timeout.current = setTimeout(
      () => {
        playStep(index + 1);
      },
      contextEdge.sleep_after !== 0 ? contextEdge.sleep_after * 1000 : DEFAULT_TIMEOUT
    );
  };

  const stopRun = () => {
    clearTimeout(timeout.current);
    automationStateChange(automationStateEnum.NotRun);
    runinngChange(false);
    stepIndexChange(null);
    startEdgeIDChange(0);
    curEdgeIdInGraphRunChange(null);
    timeout.current = null;
  };

  const executeRun = () => {
    if (timeout.current) {
      stopRun();
    } else {
      stepIndexChange(1);
      automationStateChange(automationStateEnum.Running);
    }
  };

  return (
    <div className="execute-button">
      <Button type="primary" onClick={executeRun}>
        {automationState === automationStateEnum.Running ? (
          <Icon type="loading" />
        ) : (
          <Icon type="check" style={{ color: automationState === automationStateEnum.Ran ? 'green' : 'red' }} />
        )}
        {stepIndex && graphActions.data && (
          <span className="count-step">
            {stepIndex} / {graphActions.data.relationships.length}
          </span>
        )}
        Run automation
      </Button>
    </div>
  );
}
