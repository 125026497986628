import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';
import { stringify } from 'query-string';

export const setInsightsImportance = ({ insightID, removeImportance }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightID}/user_state`,
    method: 'POST',
    data: { relevancy: removeImportance ? null : 1 },
    label: NETWORK_LABELS.SET_INSIGHTS_IMPORTANCE,
    onSuccess: (body, dispatch) => console.log(`Updated importance for insight id: ${insightID}`),
    onFailure: (error, dispatch) => console.log(`Error updating importance for insight id: ${insightID}`),
  },
});

export const selectApp = (appName, appPackage, appPlatform) => ({
  type: AT.SELECT_APP,
  payload: {
    appName,
    appPackage,
    appPlatform,
  },
});

export const selectInsight = (insightId) => ({
  type: AT.SELECT_INSIGHT,
  payload: {
    insightId,
  },
});

export const setInsights = (body, appPackage) => ({
  type: AT.SET_INSIGHTS,
  payload: { insights: body, appPackageName: appPackage },
});

export const setLabels = (body) => ({
  type: AT.SET_LABELS,
  payload: { labels: body },
});

export const insightSaved = (insight, response) => ({
  type: AT.INSIGHT_SAVED,
  payload: { insight, response },
});

export const insightSavingError = (errorMessage) => ({
  type: AT.INSIGHT_SAVED_ERROR,
  payload: { errorMessage },
});

export const beforeSaveInsight = () => ({
  type: AT.BEFORE_INSIGHT_SAVED,
  payload: {},
});

export const saveInsight = (insight) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'insight/set',
    method: 'POST',
    label: NETWORK_LABELS.SAVE_INSIGHT,
    data: { insight },
    onSuccess: (body, dispatch) => dispatch(insightSaved(insight, body)),
    onFailure: (error, dispatch) => dispatch(insightSavingError(`Failed to save insight: ${error.response.text}`)),
  },
});

export const saveInsightToServer = (insight) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'insight/set',
    label: NETWORK_LABELS.SAVE_INSIGHT,
    data: { insight },
    onSuccess: (body, dispatch) => dispatch(insightSaved()),
    // onFailure: (error, dispatch) =>
    //   dispatch(setSystemError(`Failed to load insights: ${error.message || error.response.text}`)),
  },
});

export const loadInsights = (appPackage, appPlatform) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/list?app=${appPackage}&platform=${appPlatform}&no_hashflags=1`,
    label: NETWORK_LABELS.LOAD_INSIGHTS,
    onSuccess: (body, dispatch) => dispatch(setInsights(body, appPackage)),
    // onFailure: (error, dispatch) =>
    //   dispatch(setSystemError(`Failed to load insights: ${error.message || error.response.text}`)),
  },
});

export const loadLabels = () => ({
  type: AT.LOAD_LABELS,
});

export const loadLabelsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'insight/labels/all',
    label: NETWORK_LABELS.LOAD_LABELS,
    onSuccess: (body, dispatch) => dispatch(setLabels(body)),
    // onFailure: (error, dispatch) =>
    //   dispatch(setSystemError(`Failed to load insights: ${error.message || error.response.text}`)),
  },
});

export const creatingInsight = (insight) => ({
  type: AT.CREATE_INSIGHT,
  payload: { insight },
});

export const createInsightAPI = (insight) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'screenshot/new',
    method: 'POST',
    data: { ...insight },
    label: NETWORK_LABELS.CREATE_INSIGHT,
    onSuccess: (body, dispatch) => dispatch(createInsightSuccess(body)),
    onFailure: (error, dispatch) => dispatch(createInsightFailed(error)),
  },
});

export const createInsightSuccess = (body) => ({
  type: AT.CREATE_INSIGHT_SUCCESS,
  payload: { ...body },
});

export const createInsightFailed = (error) => ({
  type: AT.CREATE_INSIGHT_FAILED,
  payload: { error },
});

// A/B Test Variants

export const getVariants = (abTestId) => ({
  type: AT.GET_VARIANTS,
  payload: { abTestId },
});

export const getVariantsAPI = (abTestId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestId}/variants`,
    method: 'GET',
    label: NETWORK_LABELS.GET_VARIANTS,
    onSuccess: (body, dispatch) => dispatch(getVariantsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const getVariantsSuccess = (body) => ({
  type: AT.GET_VARIANTS_SUCCESS,
  payload: body.data,
});

export const delVariant = (variantId) => ({
  type: AT.DEL_VARIANT,
  payload: { variantId },
});

export const delVariantAPI = (variantId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/variants/${variantId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_VARIANT,
    onSuccess: (body, dispatch) => dispatch(delVariantSuccess(variantId)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const delVariantSuccess = (variantId) => ({
  type: AT.DEL_VARIANT_SUCCESS,
  payload: { variantId },
});

export const updVariant = (variantId, data) => ({
  type: AT.UPD_VARIANT,
  payload: { variantId, data },
});

export const updVariantAPI = (variantId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/variants/${variantId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_VARIANT,
    onSuccess: (body, dispatch) => dispatch(updVariantSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const updVariantSuccess = (body) => ({
  type: AT.UPD_VARIANT_SUCCESS,
  payload: { variant: body.data },
});

export const addVariant = (abTestId, data) => ({
  type: AT.ADD_VARIANT,
  payload: { abTestId, data },
});

export const addVariantAPI = (abTestId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestId}/variants`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.UPD_VARIANT,
    onSuccess: (body, dispatch) => dispatch(addVariantSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const addVariantSuccess = (body) => ({
  type: AT.ADD_VARIANT_SUCCESS,
  payload: { variant: body.data },
});

export const variantsApiRequestFailed = (error) => ({
  type: AT.VARIANTS_API_REQUEST_FAILED,
  payload: { error },
});

// A/B Test Variant Assets

export const delVariantAsset = (variantId, assetId) => ({
  type: AT.DEL_VARIANT_ASSET,
  payload: { variantId, assetId },
});

export const delVariantAssetAPI = (variantId, assetId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/variants/assets/${assetId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_VARIANT_ASSET,
    onSuccess: (body, dispatch) => dispatch(delVariantAssetSuccess(variantId, assetId)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const delVariantAssetSuccess = (variantId, assetId) => ({
  type: AT.DEL_VARIANT_ASSET_SUCCESS,
  payload: { variantId, assetId },
});

export const updVariantAsset = (assetId, data) => ({
  type: AT.UPD_VARIANT_ASSET,
  payload: { assetId, data },
});

export const updVariantAssetAPI = (assetId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/variants/assets/${assetId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_VARIANT_ASSET,
    onSuccess: (body, dispatch) => dispatch(updVariantAssetSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const updVariantAssetSuccess = (body) => ({
  type: AT.UPD_VARIANT_ASSET_SUCCESS,
  payload: { asset: body.data },
});

export const addVariantAsset = (variantId, data) => ({
  type: AT.ADD_VARIANT_ASSET,
  payload: { variantId, data },
});

export const addVariantAssetAPI = (variantId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/variants/${variantId}/assets`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_VARIANT_ASSET,
    onSuccess: (body, dispatch) => dispatch(addVariantAssetSuccess(variantId, body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
    onProgress: (event, dispatch) => dispatch(addVariantAssetProgress(event)),
  },
});

export const addVariantAssetSuccess = (variantId, body) => ({
  type: AT.ADD_VARIANT_ASSET_SUCCESS,
  payload: { variantId, asset: body.data },
});

export const addVariantAssetProgress = (event) => ({
  type: AT.ADD_VARIANT_ASSET_PROGRESS,
  payload: { event },
});

// AB Test Variant Release

export const addVariantRelease = (abTestId, variantId, releaseId) => ({
  type: AT.ADD_VARIANT_RELEASE,
  payload: { abTestId, variantId, releaseId },
});

export const addVariantReleaseAPI = (abTestId, variantId, releaseId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestId}/variants/${variantId}/releases`,
    method: 'POST',
    data: { release_id: releaseId },
    label: NETWORK_LABELS.ADD_VARIANT_RELEASE,
    onSuccess: (body, dispatch) => dispatch(addVariantReleaseSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const addVariantReleaseSuccess = (body) => ({
  type: AT.ADD_VARIANT_RELEASE_SUCCESS,
  payload: { variant: body.data },
});

export const delVariantRelease = (abTestId, variantId, releaseId) => ({
  type: AT.DEL_VARIANT_RELEASE,
  payload: { abTestId, variantId, releaseId },
});

export const delVariantReleaseAPI = (abTestId, variantId, releaseId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestId}/variants/${variantId}/releases/${releaseId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_VARIANT_RELEASE,
    onSuccess: (body, dispatch) => dispatch(delVariantReleaseSuccess(abTestId, variantId, releaseId)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const delVariantReleaseSuccess = (abTestId, variantId, releaseId) => ({
  type: AT.DEL_VARIANT_RELEASE_SUCCESS,
  payload: { abTestId, variantId, releaseId },
});

// Insight thumbnail

export const updInsightThumbnail = (insightId, data) => ({
  type: AT.UPD_INSIGHT_THUMBNAIL,
  payload: { insightId, data },
});

export const updInsightThumbnailAPI = (insightId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/thumbnail`,
    method: 'PUT',
    data: { ...data },
    label: NETWORK_LABELS.UPD_INSIGHT_THUMBNAIL,
    onSuccess: (body, dispatch) => dispatch(updInsightThumbnailSuccess(body)),
    onFailure: (error, dispatch) => dispatch(variantsApiRequestFailed(error)),
  },
});

export const updInsightThumbnailSuccess = (body) => ({
  type: AT.UPD_INSIGHT_THUMBNAIL_SUCCESS,
  payload: { insight: body.data },
});

// Insight Assets

export const insightAssetsApiRequestFailed = (error) => ({
  type: AT.INSIGHT_ASSETS_API_REQUEST_FAILED,
  payload: { error },
});

export const getInsightAssets = (insightId) => ({
  type: AT.GET_INSIGHT_ASSETS,
  payload: { insightId },
});

export const getInsightAssetsAPI = (insightId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/assets`,
    method: 'GET',
    label: NETWORK_LABELS.GET_INSIGHT_ASSETS,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_INSIGHT_ASSETS_SUCCESS,
        payload: body.data,
      }),
    onFailure: (error, dispatch) => dispatch(insightAssetsApiRequestFailed(error)),
  },
});

export const delInsightAsset = (insightId, assetId) => ({
  type: AT.DEL_INSIGHT_ASSET,
  payload: { insightId, assetId },
});

export const delInsightAssetAPI = (insightId, assetId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/assets/${assetId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_INSIGHT_ASSET,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.DEL_INSIGHT_ASSET_SUCCESS,
        payload: { insightId, assetId },
      }),
    onFailure: (error, dispatch) => dispatch(insightAssetsApiRequestFailed(error)),
  },
});

export const updInsightAsset = (insightId, assetId, data) => ({
  type: AT.UPD_INSIGHT_ASSET,
  payload: { insightId, assetId, data },
});

export const updInsightAssetAPI = (insightId, assetId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/assets/${assetId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_INSIGHT_ASSET,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.UPD_INSIGHT_ASSET_SUCCESS,
        payload: { asset: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightAssetsApiRequestFailed(error)),
  },
});

export const addInsightAsset = (insightId, data) => ({
  type: AT.ADD_INSIGHT_ASSET,
  payload: { insightId, data },
});

export const addInsightAssetAPI = (insightId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/assets`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_INSIGHT_ASSET,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.ADD_INSIGHT_ASSET_SUCCESS,
        payload: { insightId, asset: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightAssetsApiRequestFailed(error)),
    onProgress: (event, dispatch) =>
      dispatch({
        type: AT.ADD_INSIGHT_ASSET_PROGRESS,
        payload: { event },
      }),
  },
});

// presigned url

export const refreshPresignedUrl = () => ({
  type: AT.REFRESH_INSIGHT_PRESIGNED_URL,
});

export const refreshPresignedUrlAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'insight/presigned-post-url',
    method: 'GET',
    label: NETWORK_LABELS.REFRESH_INSIGHT_PRESIGNED_URL,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.REFRESH_INSIGHT_PRESIGNED_URL_SUCCESS,
        payload: {
          postData: body.data.s3_post_data,
          uploadedFileName: body.data.uploaded_file_name,
          url: body.data.url,
        },
      }),
    onFailure: (error, dispatch) => dispatch(insightAssetsApiRequestFailed(error)),
  },
});

// insight ctas

export const insightCtaApiRequestFailed = (error) => ({
  type: AT.INSIGHT_CTA_API_REQUEST_FAILED,
  payload: { error },
});

export const getInsightCtas = (insightId) => ({
  type: AT.GET_INSIGHT_CTAS,
  payload: { insightId },
});

export const getInsightCtasAPI = (insightId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/ctas`,
    method: 'GET',
    label: NETWORK_LABELS.GET_INSIGHT_CTAS,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_INSIGHT_CTAS_SUCCESS,
        payload: { ctas: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightCtaApiRequestFailed(error)),
  },
});

export const addInsightCta = (insightId, data) => ({
  type: AT.ADD_INSIGHT_CTA,
  payload: { insightId, data },
});

export const addInsightCtaAPI = (insightId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/ctas`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_INSIGHT_CTA,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.ADD_INSIGHT_CTA_SUCCESS,
        payload: { insightId, cta: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightCtaApiRequestFailed(error)),
  },
});

export const updInsightCta = (insightId, ctaId, data) => ({
  type: AT.UPD_INSIGHT_CTA,
  payload: { insightId, ctaId, data },
});

export const updInsightCtaAPI = (insightId, ctaId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/ctas/${ctaId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_INSIGHT_CTA,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.UPD_INSIGHT_CTA_SUCCESS,
        payload: { insightId, cta: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightCtaApiRequestFailed(error)),
  },
});

export const delInsightCta = (insightId, ctaId) => ({
  type: AT.DEL_INSIGHT_CTA,
  payload: { insightId, ctaId },
});

export const delInsightCtaAPI = (insightId, ctaId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/ctas/${ctaId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_INSIGHT_CTA,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.DEL_INSIGHT_CTA_SUCCESS,
        payload: { insightId, ctaId },
      }),
    onFailure: (error, dispatch) => dispatch(insightCtaApiRequestFailed(error)),
  },
});

// insight timeline

export const insightTimelineApiRequestFailed = (error) => ({
  type: AT.INSIGHT_TIMELINE_API_REQUEST_FAILED,
  payload: { error },
});

export const addInsightTimeline = (insightId, data) => ({
  type: AT.ADD_INSIGHT_TIMELINE,
  payload: { insightId, data },
});

export const addInsightTimelineAPI = (insightId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/${insightId}/timeline`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_INSIGHT_TIMELINE,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.ADD_INSIGHT_TIMELINE_SUCCESS,
        payload: { insightId, timeline: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightTimelineApiRequestFailed(error)),
  },
});

export const updInsightTimeline = (insightId, timelineId, data) => ({
  type: AT.UPD_INSIGHT_TIMELINE,
  payload: { insightId, timelineId, data },
});

export const updInsightTimelineAPI = (insightId, timelineId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/timeline/${timelineId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_INSIGHT_TIMELINE,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.UPD_INSIGHT_TIMELINE_SUCCESS,
        payload: { insightId, timeline: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(insightTimelineApiRequestFailed(error)),
  },
});

export const delInsightTimeline = (insightId, timelineId) => ({
  type: AT.DEL_INSIGHT_TIMELINE,
  payload: { insightId, timelineId },
});

export const delInsightTimelineAPI = (insightId, timelineId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/timeline/${timelineId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_INSIGHT_TIMELINE,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.DEL_INSIGHT_TIMELINE_SUCCESS,
        payload: { insightId, timelineId },
      }),
    onFailure: (error, dispatch) => dispatch(insightTimelineApiRequestFailed(error)),
  },
});

export const createScreenChangeInsightAPI = (initialInsight, prevScreenAsset, currentScreenAsset, insight) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'screenshot/new',
    method: 'POST',
    data: initialInsight,
    label: NETWORK_LABELS.CREATE_SCREEN_CHANGE_INSIGHT,
    onSuccess: (body, dispatch) => {
      const insightID = body.insight.id;
      dispatch(addInsightAssetAPI(insightID, prevScreenAsset));
      dispatch(addInsightAssetAPI(insightID, currentScreenAsset));
      dispatch(saveInsight({ ...body.insight, ...insight }));
      dispatch(
        createScreenChangeInsightSuccess(insight.app, {
          ...insight,
          id: body.insight.id,
        })
      );
    },
    onFailure: (error, dispatch) => dispatch(createInsightFailed(error)),
  },
});

export const createScreenChangeInsightSuccess = (app, insight) => ({
  type: AT.CREATE_SCREEN_CHANGE_INSIGHT_SUCCESS,
  payload: {
    app,
    insight,
  },
});

// INSIGHT_PERSONAS

export const getInsightPersonas = (appId) => ({
  type: AT.GET_INSIGHT_PERSONAS,
  payload: { appId },
});

export const getInsightPersonasAPI = (appId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/all?${stringify({ app_id: appId })}`,
    label: NETWORK_LABELS.GET_INSIGHT_PERSONAS,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_INSIGHT_PERSONAS_SUCCESS,
        payload: body,
      }),
  },
});

// GET_ROOT_NODE

export const getRootNodeByInsightNodeID = (node_id) => ({
  type: AT.GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID,
  payload: node_id,
});

export const getRootNodeByInsightNodeIDAPI = (node_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/graphs/nodes/${node_id}`,
    method: 'GET',
    label: 'GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID',
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID_SUCCESS,
        payload: body,
      }),
  },
});

export const copyInsight = (insightId) => ({
  type: AT.COPY_INSIGHT,
  payload: insightId,
});

export const copyInsightAPI = (insightId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/insights/${insightId}/copy`,
    method: 'POST',
    label: NETWORK_LABELS.COPY_INSIGHT,
    data: {},
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.COPY_INSIGHT_SUCCESS,
        payload: body,
      }),
    onFailure: (error, dispatch) => dispatch(insightSavingError(`Failed to save insight: ${error.response.text}`)),
  },
});
