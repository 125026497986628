import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  emails: null,
  isRequesting: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_POSSIBLE_INSIGHTS_BY_EMAILS: {
      return {
        ...state,
        isRequesting: true,
      };
    }

    case actionTypes.GET_POSSIBLE_INSIGHTS_BY_EMAILS_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        emails: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
