import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import VersionsDiff from './VersionsDiff';
import NewAppDiff from './NewAppDiff';

const { TabPane } = Tabs;

const DiffTabs = ({ match, history, location }) => {
  const path = location.pathname.split('/');

  console.log(path);

  return (
    <Tabs type="card" defaultActiveKey={path && path[2] === 'new' ? '2' : '1'}>
      <TabPane tab="Diff" key="1">
        <VersionsDiff match={match} history={history} />
      </TabPane>
      <TabPane tab="Create new" key="2">
        <NewAppDiff />
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = ({}) => ({});

export default connect(mapStateToProps, {})(DiffTabs);
