import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

// orchestrators
export const getOrchestratorsList = () => ({
  type: AT.GET_ORCHESTRATORS_LIST,
});

export const getOrchestratorsListAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'admin/orchestrators',
    label: NETWORK_LABELS.GET_ORCHESTRATORS_LIST,
    onSuccess: (body, dispatch) => dispatch(getOrchestratorsListSuccess(body)),
    onFailure: (error) => console.log(error.message, error.response.text),
  },
});

export const getOrchestratorsListSuccess = (list) => ({
  type: AT.GET_ORCHESTRATORS_LIST_SUCCESS,
  payload: list,
});
