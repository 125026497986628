import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const addNotification = (notification) => ({
  type: AT.ADD_NOTIFICATION,
  payload: notification,
});

export const addNotificationAPI = (notification) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `notifications`,
    data: notification,
    label: NETWORK_LABELS.ADD_NOTIFICATION,
    method: 'POST',
    onSuccess: (body, dispatch) => {
      notification.id = body.data.id;
      dispatch(addNotificationSuccess(body.data));
      dispatch(linkNotificationToUsers(notification));
    },
    onFailure: (error, dispatch) => {
      dispatch(addNotificationFailed(error));
    },
  },
});

const addNotificationSuccess = (data) => ({
  type: AT.ADD_NOTIFICATION_SUCCESS,
  payload: { notification: data },
});

const addNotificationFailed = (error) => ({
  type: AT.ADD_NOTIFICATION_FAILED,
  payload: { error },
});

const linkNotificationToUsers = (notification) => ({
  type: AT.LINK_NOTIFICATION_TO_USER,
  payload: notification,
});

export const linkNotificationToUsersAPI = (notification) => {
  console.log(notification);
  return {
    type: AT.API_REQUEST,
    payload: {
      url: `notifications/${notification.id}/users`,
      data: notification,
      label: NETWORK_LABELS.LINK_NOTIFICATION_TO_USER,
      method: 'POST',
      onSuccess: (body, dispatch) => {
        dispatch(linkNotificationToUsersSuccess(body));
      },
      onFailure: (error, dispatch) => {
        dispatch(linkNotificationToUsersFailed(error));
      },
    },
  };
};

const linkNotificationToUsersSuccess = (body) => ({
  type: AT.LINK_NOTIFICATION_TO_USER_SUCCESS,
  payload: body,
});

const linkNotificationToUsersFailed = (error) => ({
  type: AT.LINK_NOTIFICATION_TO_USER_FAILED,
  payload: error,
});

export const getAllNotifications = () => ({
  type: AT.GET_ALL_NOTIFICATIONS,
});
export const getAllNotificationsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `notifications/all`,
    label: NETWORK_LABELS.GET_ALL_NOTIFICATIONS,
    method: 'GET',
    onSuccess: (body, dispatch) => dispatch(getAllNotificationsSuccess(body)),
    onFailure: (error, dispatch) => console.log(`getAllNotificationsAPI fetched: ${error}`),
  },
});

const getAllNotificationsSuccess = (body) => ({
  type: AT.GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: { notificationsData: body.data },
});

export const deleteNotification = (notification) => ({
  type: AT.DELETE_NOTIFICATION,
  payload: notification.id,
});

export const deleteNotificationsAPI = (id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `notifications/${id}`,
    label: NETWORK_LABELS.DELETE_NOTIFICATION,
    method: 'DELETE',
    onSuccess: (body, dispatch) => dispatch(deleteNotificationsSuccess(body, id)),
    onFailure: (error, dispatch) => console.log(`deleteNotifications fetched: ${error}`),
  },
});

export const deleteNotificationsSuccess = (body, id) => ({
  type: AT.DELETE_NOTIFICATION_SUCCESS,
  payload: { id },
});

export const updateNotification = (notification) => ({
  type: AT.UPDATE_NOTIFICATION,
  payload: notification,
});

export const updateNotificationAPI = (notification) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `notifications/${notification.id}`,
    data: notification,
    label: NETWORK_LABELS.UPDATE_NOTIFICATION,
    method: 'PUT',
    onSuccess: (body, dispatch) => dispatch(updateNotificationSuccess(body.data)),
    onFailure: (error, dispatch) => console.log(`updateNotification fetched: ${error}`),
  },
});

const updateNotificationSuccess = (data) => ({
  type: AT.UPDATE_NOTIFICATION_SUCCESS,
  payload: { notification: data },
});
