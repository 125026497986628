import { Select } from 'antd';
import React, { useState } from 'react';

export default ({ products, onSelect, style }) => {
  const [value, setValue] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const filteredProducts = searchQuery.length
    ? products.filter((x) => x.name.toLowerCase().includes(searchQuery.toLowerCase())).slice(0, 100)
    : [];

  const handleSelect = (val) => {
    setValue(null);
    return onSelect(val);
  };

  return (
    <Select
      value={value}
      showSearch
      filterOption={false}
      onSearch={setSearchQuery}
      onSelect={handleSelect}
      placeholder="Product"
      allowClear
      style={style}
    >
      {filteredProducts.map((product) => (
        <Select.Option value={product.id} key={product.id}>
          <img src={product.thumbnail} width={16} /> {product.name}
        </Select.Option>
      ))}
    </Select>
  );
};
