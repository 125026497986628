import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  status: '',
  curUserDetails: null,
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        status: action.payload.message,
      };
    }
    case actionTypes.LOGIN_FAILED: {
      return {
        ...state,
        status: action.payload.message,
      };
    }
    case actionTypes.GET_CUR_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        curUserDetails: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
