import { Button, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import './UJEdit.scss';

const UJEditBase = ({
  curUserFlowSet,
  releases,
  personas,
  userFlows,
  handleDelete,
  handleUpdate,
  handleCreateUserFlow,
  history,
  packageName,
}) => {
  const [parentId, setParentId] = useState(-1);

  const userFlowsParents = userFlows.filter(({ parent_id }) => parent_id === null);
  const release = curUserFlowSet ? curUserFlowSet.release : null;
  const persona = curUserFlowSet ? curUserFlowSet.persona : null;
  const userFlow = curUserFlowSet ? curUserFlowSet.user_flow : null;
  const userFlowParent = curUserFlowSet && userFlowsParents ? userFlowsParents.find(({ id }) => id === parentId) : null;
  const userFlowsChildren = userFlowParent ? userFlows.filter(({ parent_id }) => parent_id === userFlowParent.id) : [];

  useEffect(() => {
    if (curUserFlowSet) {
      setParentId(curUserFlowSet.user_flow.parent_id);
    }
  }, [curUserFlowSet]);

  const onDelete = () => {
    handleDelete(curUserFlowSet.id);
    history.replace(history.location.pathname.split('/').slice(0, 3).join('/'));
  };

  const publishDraftClick = () => {
    handleUpdate({ set_id: curUserFlowSet.id, is_visible: !curUserFlowSet.is_visible });
  };

  const handleSelect =
    (fieldName) =>
    ({ value }) => {
      handleUpdate({ set_id: curUserFlowSet.id, [fieldName]: value });
    };

  const handleParentSelect = ({ value }) => {
    setParentId(value);
  };

  const handleCreate = (isChild) => (value) => {
    handleCreateUserFlow({ name: value, parent_id: !isChild ? null : parentId });
  };

  return (
    <div className="ujedit-container">
      <div className="ujedit-header">
        <h5 className="ujedit-heading">
          {curUserFlowSet.app.name} ({packageName}) - {curUserFlowSet.release.release_name}{' '}
          {curUserFlowSet.user_flow.name} - {curUserFlowSet.persona.title}
        </h5>
        <div className="ujedit-heading-buttons">
          <Button type="primary" onClick={publishDraftClick}>
            {curUserFlowSet.is_visible ? 'Draft' : 'Publish'}
          </Button>
          <Popconfirm
            placement="top"
            title="Are you sure you want to delete?"
            onConfirm={onDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" shape="circle" ghost icon="delete" />
          </Popconfirm>
        </div>
      </div>
      <div className="ujedit-main">
        <div className="ujedit-fields">
          <div className="ujedit-field">
            <label>Release*</label>
            <Select
              placeholder="Select a Release"
              onChange={handleSelect('release_id')}
              value={release ? { label: release.release_name, value: release.id } : null}
              options={
                releases
                  ? releases.map((release) => ({
                      label: release.release_name,
                      value: release.id,
                    }))
                  : []
              }
            />
          </div>
          <div className="ujedit-field">
            <label>Persona*</label>
            <Select
              onChange={handleSelect('persona_id')}
              placeholder="Select a Persona"
              value={persona ? { label: persona.title, value: persona.id } : null}
              options={personas ? personas.map((persona) => ({ label: persona.title, value: persona.id })) : []}
            />
          </div>
          <div className="ujedit-field">
            <label>Parent Flow*</label>
            <CreatableSelect
              onCreateOption={handleCreate(false)}
              onChange={handleParentSelect}
              placeholder="Select or Create a Flow Parent"
              isClearable
              value={userFlowParent ? { label: userFlowParent.name, value: userFlowParent.id } : null}
              options={
                userFlowsParents
                  ? userFlowsParents.map((userFlow) => ({
                      label: userFlow.name,
                      value: userFlow.id,
                    }))
                  : []
              }
            />
          </div>
          <div className="ujedit-field">
            <label>Flow*</label>
            <CreatableSelect
              onCreateOption={handleCreate(true)}
              onChange={handleSelect('user_flow_id')}
              placeholder="Select or Create a Flow"
              isClearable
              value={userFlow ? { label: userFlow.name, value: userFlow.id } : null}
              options={
                userFlowsChildren
                  ? userFlowsChildren.map((userFlow) => ({
                      label: userFlow.name,
                      value: userFlow.id,
                    }))
                  : []
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const UJEdit = withRouter(UJEditBase);
