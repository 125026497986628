import React, { useEffect, useState } from 'react';
import { Circle, Image, Layer, Stage } from 'react-konva';
import './CanvasedImagePreview.scss';

const normalizeX = (x, imageWidth, canvasWidth) => Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y, imageHeight, canvasHeight) => Math.round((canvasHeight * y) / imageHeight);

export const CanvasedImagePreview = ({
  imageSrc,
  action_type,
  action_coords,
  circleRadius,
  canvasWidth,
  canvasHeight,
}) => {
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const image = new window.Image();
    image.src = imageSrc;
    image.onload = () => {
      setImagePreview(image);
    };
  }, [imageSrc]);

  return (
    <div className="uj-asset-preview">
      <div className="canvased-image__canvas">
        <Stage width={canvasWidth} height={canvasHeight}>
          <Layer>
            {imagePreview && (
              <React.Fragment>
                <Image image={imagePreview} name="image" width={canvasWidth} height={canvasHeight} />
                {action_type === 'tap' && action_coords && (
                  <Circle
                    radius={circleRadius}
                    stroke="#f00000"
                    fill="rgba(233, 7, 7, 0.54)"
                    opacity={0.8}
                    strokeWidth={3}
                    x={normalizeX(action_coords.x, imagePreview.width, canvasWidth)}
                    y={normalizeY(action_coords.y, imagePreview.height, canvasHeight)}
                  />
                )}
              </React.Fragment>
            )}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};
