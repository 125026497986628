/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Modal, Form, Input, Checkbox, Select } from 'antd';

class UserCreationForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="add-user-form">
        <Form.Item label="First Name">
          {getFieldDecorator('first_name', {
            rules: [{ required: true, message: 'Please enter the user First Name!' }],
          })(<Input placeholder="First Name" />)}
        </Form.Item>
        <Form.Item label="Last Name">
          {getFieldDecorator('last_name', {
            rules: [{ required: true, message: 'Please enter the user Last Name!' }],
          })(<Input placeholder="Last Name" />)}
        </Form.Item>
        <Form.Item label="Organization">
          {getFieldDecorator('organization_id', {
            rules: [{ required: true, message: 'Please choose organization!' }],
          })(
            <Select
              placeholder="Organization"
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
            >
              {this.props.orgs.map((org) => (
                <Select.Option value={org.id} key={org.id}>
                  {org.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [
              { required: true, message: 'Please enter the user Email!' },
              { type: 'email', message: 'Please enter a valid Email!' },
            ],
          })(<Input placeholder="Email" />)}
        </Form.Item>
        <Form.Item label="Password">
          {getFieldDecorator('password', {
            rules: [
              { required: true, message: 'Please enter the user Password!' },
              { min: 6, message: 'Password must has at least 6 characters' },
            ],
          })(<Input type="password" placeholder="Password" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedUserCreationForm = Form.create({ name: 'user_creation' })(UserCreationForm);

export default function AddUserModal({ modalVisible, setIsVisible, addUser, orgs }) {
  let formRef = null;
  return (
    <Modal
      title="Add user details"
      centered
      visible={modalVisible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            addUser(values);
            setIsVisible(false);
          });
        }
      }}
      onCancel={() => {
        setIsVisible(false);
        formRef.props.form.resetFields();
      }}
    >
      <WrappedUserCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        orgs={orgs}
      />
    </Modal>
  );
}
