import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { List } from 'semantic-ui-react';
import { selectInsight } from '../../redux/actions/insightPage';

class InsightList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick(insightId) {
    this.props.onInsightSelected(insightId);
  }

  render() {
    return (
      <div className="insight-list">
        <List divided relaxed selection onChange={this.handleSelect}>
          {this.props.insights.map((insight) => (
            <List.Item key={insight.id} onClick={() => this.handleClick(insight.id)}>
              <List.Icon
                name={insight.should_show ? 'check square' : 'stop'}
                size="large"
                verticalAlign="middle"
                style={insight.id === this.props.selectedInsightId ? { color: 'blue' } : {}}
              />
              <List.Content>
                <List.Header>{insight.title || 'No Title'}</List.Header>
                <List.Description>
                  {`${insight.id} | ${moment(insight.created).format('DD/MM/YYYY HH:mm:ss')}${
                    insight.analyst_name ? ` | ${insight.analyst_name}` : ''
                  }`}
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </div>
    );
  }
}

InsightList.defaultProps = {
  selectedInsightId: null,
};

InsightList.propTypes = {
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      created: PropTypes.string,
      id: PropTypes.number,
      enabled: PropTypes.bool,
    })
  ).isRequired,
  onInsightSelected: PropTypes.func.isRequired,
  selectedInsightId: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => ({
  onInsightSelected: (insightId) => {
    dispatch(selectInsight(insightId));
  },
});

const mapStateToProps = (state) => ({
  selectedInsightId: state.insightPage.selectedInsightId,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightList);
