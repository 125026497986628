import React from 'react';
import { Table, Spin, Button, Input, Icon } from 'antd';
import Img from 'react-image';

const NotificationsTable = (props) => {
  const onDelete = (notiication) => {
    console.log(`deleting notification ${notiication.id}`);
    props.deleteNotification(notiication);
  };

  const getNotificationsTableColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
      render: (text, notification) => (
        <div>
          {text}
          <Button
            style={{ margin: '4px' }}
            type="small"
            icon="edit"
            onClick={(e) => {
              e.stopPropagation();
              props.setShowNotificationsEditModal(true);
              props.setNotificationID(notification.id);
            }}
          />
          <Button type="danger small" icon="delete" onClick={() => onDelete(notification)} />
        </div>
      ),
    },
    {
      title: 'Action type',
      dataIndex: 'action_type',
      key: 'action_type',
      width: '100px',
      filters: [...new Set(props.notificationsData.map((x) => x.action_type).filter((x) => x))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, record) => record.action_type === value,
      filterMultiple: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '300px',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '300px',
    },
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      width: '75px',
      render: (text) => (
        <Img
          style={{ width: '100%', height: '100%' }}
          src={[text]}
          alt=""
          loader={<Spin />}
          container={(children) => <div style={{ width: '25px', height: '25px' }}>{children}</div>}
        />
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'icon_platform_overlay',
      key: 'icon_platform_overlay',
      width: '100px',
    },
    {
      title: 'Link',
      dataIndex: 'link',
      key: 'link',
      width: '300px',
    },
    {
      title: 'Show in',
      dataIndex: 'display_place',
      key: 'display_place',
      width: '100px',
    },
    {
      title: 'Is visible',
      dataIndex: 'is_visible',
      render: (value) => (value ? 'Yes' : 'No'),
    },
  ];

  return (
    <Table
      className="main-table"
      columns={getNotificationsTableColumns()}
      dataSource={props.notificationsData}
      scroll={{ x: 1500, y: 600 }}
      pagination={{ pageSize: 50 }}
    />
  );
};
export default NotificationsTable;
