import React, { useEffect, useState } from 'react';
import { Select, Table, Pagination, Button, Input } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { getInsights } from '../../../redux/actions/InsightsListPage';

import { getAllUserGroups } from '../../../redux/actions/userGroupsPage';
import { PRIORITY_TYPS } from '../../../constants/priorityTyps';

const InsightsListPage = ({ isLoading, insights, getInsights, meta, getAllUserGroups, allUserGroups, history }) => {
  const [currentPage, currentPageChange] = useState(1);
  const [orgID, orgIDChange] = useState('');
  const [insID, insIDChange] = useState('');
  const [priority, priorityChange] = useState([]);
  const [textSearch, textSearchChange] = useState('');

  useEffect(() => {
    getAllUserGroups();
    requestInsights();
    history.replace(`/insights/list`);
  }, []);

  useEffect(() => {
    requestInsights();
  }, [currentPage]);

  const requestInsights = (override = {}) => {
    // url: `admin/insights?id=&search=text&org_id=1&priority=high,very_high&page_num=1&num_items=100`,
    const data = {
      page_num: currentPage,
      num_items: 100,
    };
    if (orgID) {
      data.org_id = orgID;
    }
    if (insID) {
      data.id = insID;
    }
    if (priority.length) {
      data.priority = priority.join(',');
    }
    if (textSearch) {
      data.search = textSearch;
    }
    Object.keys(override).forEach((key) => {
      if (override[key] && override[key].length) {
        data[key] = override[key];
      } else {
        delete data[key];
      }
    });
    getInsights(data);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '3rem',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '20rem',
      render: (title, insight) => (
        <div>
          <a
            href={`/#/insights/${insight.app.name}/${insight.app.package}/${insight.app.platform}/${insight.id}`}
            target="_blank"
          >
            {title}{' '}
          </a>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '10rem',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      width: '10rem',
      render: (created, insight) => <div>{moment(created).format('ll')}</div>,
    },
    {
      title: 'Show',
      dataIndex: 'should_show',
      width: '4rem',
      render: (should_show, insight) => <div>{should_show ? '✓' : ''}</div>,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '5rem',
    },
    {
      title: 'App',
      dataIndex: 'app',
      width: '10rem',
      render: (app, insight) => (
        <div className="app-data">
          <img src={app.icon} />
          {app.name}
        </div>
      ),
    },
    {
      title: 'Release',
      dataIndex: 'release',
      width: '10rem',
      render: (release, insight) => <div>{release ? release.name : '-'}</div>,
    },
    {
      title: 'Analyst',
      dataIndex: 'analyst',
      width: '10rem',
      render: (analyst, insight) => <div>{analyst ? analyst.name : ''}</div>,
    },
  ];

  const handleSearch = () => {
    requestInsights();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      requestInsights();
    }
  };
  const handleChangeOrg = (val) => {
    orgIDChange(val);
    requestInsights({ org_id: val });
  };
  const handleChangePriority = (val) => {
    priorityChange(val);
    requestInsights({ priority: val });
  };

  if (!meta) {
    return 'Loading...';
  }

  return (
    <div className="insights-list">
      <div className="insights-list-filters">
        <div className="insights-list-filters-org">
          <h4>Organization</h4>
          <Select value={orgID} onChange={handleChangeOrg}>
            <Select.Option key="all" value="">
              All
            </Select.Option>
            {allUserGroups.map((org) => (
              <Select.Option key={org.id} value={org.id}>
                {org.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="insights-list-filters-ids">
          <h4>ID</h4>
          <Input value={insID} onChange={(e) => insIDChange(e.target.value)} onKeyPress={handleKeyPress} />
        </div>
        <div className="insights-list-filters-priority">
          <h4>Priority</h4>
          <Select mode="multiple" value={priority} onChange={handleChangePriority}>
            {Object.entries(PRIORITY_TYPS).map(([key, value]) => (
              <Select.Option key={key} value={key}>
                {value}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="insights-list-filters-text">
          <h4>Title or Description contents...</h4>
          <Input value={textSearch} onChange={(e) => textSearchChange(e.target.value)} onKeyPress={handleKeyPress} />
        </div>
        <div className="insights-list-filters-find">
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </div>
      </div>
      <Table
        loading={isLoading}
        dataSource={insights}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
      <Pagination
        total={meta.total}
        pageSize={100}
        current={meta.page_num}
        showTotal={(total) => `Total ${total} items`}
        onChange={(page) => currentPageChange(page)}
      />
    </div>
  );
};

const mapStateToProps = ({ insightsList, userGroupsPage }) => ({
  isLoading: insightsList.isLoading,
  insights: insightsList.insights,
  meta: insightsList.meta,
  allUserGroups: userGroupsPage.allUserGroups,
});

const mapDispatchToProps = {
  getInsights,
  getAllUserGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsListPage);
