import React, { useState } from 'react';
import { Checkbox, Modal } from 'antd';
import './IllegalABTestModal.scss';

const IllegalABTestModal = ({ onOk, onCancel, visible }) => {
  const [confirmationClicked, confirmationClickedChange] = useState(false);
  return (
    <div className="main">
      <Modal
        title="Only one insight for AB test"
        onOk={() => {
          confirmationClickedChange(false);
          if (confirmationClicked) {
            onOk();
          } else {
            onCancel();
          }
        }}
        onCancel={() => {
          confirmationClickedChange(false);
          onCancel();
        }}
        visible={visible}
      >
        <div className="body-wrapper">
          <div className="red">
            Are you sure you want to integrate/abandon this AB test though it has only one insight???
          </div>
          <Checkbox onChange={(e) => confirmationClickedChange(e.target.checked)} checked={confirmationClicked}>
            I'm positive, that's what I want!!!
          </Checkbox>
        </div>
      </Modal>
    </div>
  );
};

export default IllegalABTestModal;
