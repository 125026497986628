import { combineReducers } from 'redux';
import appsReducer from './apps';
import insightPageReudcer from './insightPage';
import loginReducer from './login';
import usersReducer from './users';
import userGroupsReducer from './user_groups';
import appsReleasesReducer from './appsReleases';
import emailJSONPageReducer from './emailJSONPage';
import annotationVerification from './annotationVerification';
import productReducer from './products';
import abTestsReducer from './abTests';
import devicesReducer from './devices';
import personasReducer from './personas';
import settingsReducer from './personaSettings';
import notificationsReducer from './notifications';
import reportsReducer from './reports';
import posInsightsByEmailsReducer from './possibleInsightsByEmails';
import abandonedInsights from './abandonedInsights';
import subscriptions from './subscriptions';
import UIViewerReducer from './UIViewer';
import insightsListPageReudcer from './insightsListPage';
import orchestrators from './orchestrators';
import { workbenchReducer } from './workbench';

export default combineReducers({
  apps: appsReducer,
  insightPage: insightPageReudcer,
  loginData: loginReducer,
  usersPage: usersReducer,
  userGroupsPage: userGroupsReducer,
  appsReleasesPage: appsReleasesReducer,
  emailJSONPage: emailJSONPageReducer,
  annotationVerificationPage: annotationVerification,
  abTestsPage: abTestsReducer,
  productsData: productReducer,
  devicesPage: devicesReducer,
  personasPage: personasReducer,
  settingsPage: settingsReducer,
  notifications: notificationsReducer,
  reportsPage: reportsReducer,
  possInsightsByEmails: posInsightsByEmailsReducer,
  UIViewerReducer,
  abandonedInsights,
  subscriptions,
  insightsList: insightsListPageReudcer,
  orchestrators,
  workbench: workbenchReducer,
});
