import * as AT from '../actionTypes';

// GET Insights
export const getInsights = (data) => ({
  type: AT.GET_INSIGHTS_LIST,
  payload: data,
});

export const getInsightsAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/insights?${new URLSearchParams(data).toString()}`,
    method: 'GET',
    label: 'GET_INSIGHTS_LIST',
    onSuccess: (body, dispatch) => dispatch(getInsightsSuccess(body)),
  },
});

export const getInsightsSuccess = (body) => ({
  type: AT.GET_INSIGHTS_LIST_SUCCESS,
  payload: body,
});
