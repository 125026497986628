"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWorkbenchTracking = void 0;
var react_1 = require("react");
var wbTracking_1 = require("monitoring/wbTracking");
var useWorkbenchTracking = function (_a) {
    var currApp = _a.currApp, leftDevice = _a.leftDevice, persona = _a.persona, rightDevice = _a.rightDevice, leftDeviceAppVer = _a.leftDeviceAppVer, rightDeviceAppVer = _a.rightDeviceAppVer;
    (0, react_1.useEffect)(function () {
        var personaCurrentProduct = persona && currApp && persona.persona_products
            ? persona.persona_products.find(function (_a) {
                var product = _a.product;
                return product.name.toLowerCase().includes(currApp.name.toLowerCase());
            })
            : null;
        var personaRole = personaCurrentProduct && personaCurrentProduct.settings
            ? personaCurrentProduct.settings.find(function (_a) {
                var type = _a.type;
                return type === 'role';
            })
            : null;
        wbTracking_1.workbenchTracking.updateMetadata(__assign(__assign(__assign(__assign(__assign(__assign({}, (currApp ? { selected_app_name: currApp.name, selected_app_package: currApp.package } : null)), (leftDevice
            ? {
                previous_device_platform: leftDevice.platform.toLowerCase(),
                previous_device_serial: leftDevice.serial,
            }
            : null)), (rightDevice
            ? {
                current_device_platform: rightDevice.platform.toLowerCase(),
                current_device_serial: rightDevice.serial,
            }
            : null)), (persona
            ? {
                persona_selected_id: persona.id.toString(),
                persona_selected_role: personaRole ? personaRole.value : persona.title,
            }
            : null)), (leftDeviceAppVer ? { previous_release: leftDeviceAppVer.version_name } : null)), (rightDeviceAppVer ? { current_release: rightDeviceAppVer.version_name } : null)));
    }, [currApp, leftDevice, rightDevice, persona, leftDeviceAppVer, rightDeviceAppVer]);
    (0, react_1.useEffect)(function () {
        // track when app changes (new session and reset old session)
        if (currApp) {
            wbTracking_1.workbenchTracking.startEventTrack('wb_session_time', {
                session_started_at: new Date().toLocaleString(),
            });
            return function () {
                var endTime = new Date();
                if (wbTracking_1.workbenchTracking.getStartEventData('wb_session_time')) {
                    wbTracking_1.workbenchTracking.endEventTrack('wb_session_time', {
                        session_finished_at: endTime.toLocaleString(),
                        wb_session_time: (endTime.getTime() -
                            new Date(wbTracking_1.workbenchTracking.getStartEventData('wb_session_time').session_started_at).getTime()) /
                            1000,
                    });
                }
            };
        }
    }, [currApp]);
};
exports.useWorkbenchTracking = useWorkbenchTracking;
