import { Button, Carousel } from 'antd';
import React, { useRef, useState } from 'react';
import { INSIGHT_ASSET_TYPES } from '../../../utils/consts';
import './ImagesPreview.scss';
import { CanvasedImagePreview } from './canvasedImagePreview';
import { VideoPlayer } from './videoPlayer';

const CarouselActions = {
  Next: 'Next',
  Prev: 'Prev',
};

export const ImagesPreview = ({ leftDeviceImages, rightDeviceImages, setLeftDeviceImages, setRightDeviceImages }) => {
  const [currentRightImageIdx, setCurrentRightImageIdx] = useState(0);
  const [currentLeftImageIdx, setCurrentLeftImageIdx] = useState(0);
  const leftCarouselRef = useRef(null);
  const rightCarouselRef = useRef(null);

  const handleLeftImageChange = (idx) => {
    setCurrentLeftImageIdx(idx);
  };

  const handleRightImageChange = (idx) => {
    setCurrentRightImageIdx(idx);
  };

  const handleLeftImageDelete = () => {
    setLeftDeviceImages((prev) => prev.filter((_, idx) => idx !== currentLeftImageIdx));
  };

  const handleRightImageDelete = () => {
    setRightDeviceImages((prev) => prev.filter((_, idx) => idx !== currentRightImageIdx));
  };

  const changeLeftImageSlide = (action) => () => {
    if (action === CarouselActions.Next) {
      leftCarouselRef.current.next();
    }

    if (action === CarouselActions.Prev) {
      leftCarouselRef.current.prev();
    }
  };

  const changeRightImageSlide = (action) => () => {
    if (action === CarouselActions.Next) {
      rightCarouselRef.current.next();
    }

    if (action === CarouselActions.Prev) {
      rightCarouselRef.current.prev();
    }
  };

  return (
    <div className="preview-screen-image">
      <div className="devices-previews-container">
        {leftDeviceImages.length > 0 && (
          <React.Fragment>
            <h5 className="previous">PREVIOUS</h5>
            <Carousel ref={leftCarouselRef} afterChange={handleLeftImageChange} className="device-previews">
              {leftDeviceImages.map(({ type, videoUrl, image, rects, ujAction }) =>
                type === INSIGHT_ASSET_TYPES.Video ? (
                  <VideoPlayer videoUrl={videoUrl} />
                ) : (
                  <CanvasedImagePreview key={image} imageSrc={image} rects={rects} ujAction={ujAction} />
                )
              )}
            </Carousel>
            <div className="devices-previews-buttons">
              <Button icon="left" size="small" onClick={changeLeftImageSlide(CarouselActions.Prev)} />
              <Button type="danger" shape="circle" icon="close" size="small" onClick={handleLeftImageDelete} />
              <Button icon="right" size="small" onClick={changeLeftImageSlide(CarouselActions.Next)} />
            </div>
          </React.Fragment>
        )}
      </div>
      <div className="devices-previews-container">
        {rightDeviceImages.length > 0 && (
          <React.Fragment>
            <h5 className="current">CURRENT</h5>
            <Carousel ref={rightCarouselRef} afterChange={handleRightImageChange} className="device-previews">
              {rightDeviceImages.map(({ type, videoUrl, image, rects, ujAction }) =>
                type === INSIGHT_ASSET_TYPES.Video ? (
                  <VideoPlayer videoUrl={videoUrl} />
                ) : (
                  <CanvasedImagePreview key={image} imageSrc={image} rects={rects} ujAction={ujAction} />
                )
              )}
            </Carousel>
            <div className="devices-previews-buttons">
              <Button icon="left" size="small" onClick={changeRightImageSlide(CarouselActions.Prev)} />
              <Button type="danger" shape="circle" icon="close" size="small" onClick={handleRightImageDelete} />
              <Button icon="right" size="small" onClick={changeRightImageSlide(CarouselActions.Next)} />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
