import React, { useState } from 'react';
import { Modal, Table, Button, Popconfirm, Tooltip, Icon } from 'antd';
import moment from 'moment';
import './subscriptions.scss';
import CreateNewSubscriptionModal from './CreateNewSubscriptionModal';
import { getUserLicence } from '../../common/user_helper';
import MarketingIcon from '../../../assets/icons/Marketing-non-selected.svg';
import IntelligenceIcon from '../../../assets/icons/Intelligence-non-selected.svg';
import EmailPreferences from '../emailPreferences/EmailPreferences';

export const L_INTELLIGENCE = 'intelligence';
export const L_BENCHMARK = 'benchmark';
export const L_MARKETING = 'marketing';
const LICENCES = [L_INTELLIGENCE, L_BENCHMARK, L_MARKETING];
export const LICENCES_DATA = {
  [L_INTELLIGENCE]: 'Product Intelligence',
  [L_BENCHMARK]: 'Product Benchmark',
  [L_MARKETING]: 'Marketing Campaigns',
};
const MATCH_TYPES = ['Match Any', 'Match All'];

const EditSubscriptionModal = ({
  modalVisible,
  setIsVisible,
  subscriptions,
  products,
  tagsList,
  userDetails,
  createSubscription,
  editSubscription,
  deleteSubscription,
  updEmailPreferences,
}) => {
  const [createSubscriptionVisible, createSubscriptionVisibleChange] = useState(false);
  const [editSubscriptionID, editSubscriptionIDChange] = useState(null);
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '8rem',
      render: (value, record) => (
        <div>
          <div>{value}</div>
          <Button
            type="small"
            icon="edit"
            title="Change persona"
            onClick={() => {
              editSubscriptionIDChange(record.id);
              createSubscriptionVisibleChange(true);
            }}
          />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => deleteSubscription(record.id)}
          >
            <Button style={{ marginLeft: '3px' }} icon="delete" type="danger small" title="Delete persona" />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '12rem',
      sorter: (sub1, sub2) => (sub1.name < sub2.name ? 1 : -1),
      render: (text, sub) => (
        <div className="subscription-name">
          <div className="subscription-created">{moment(sub.created).format('ll')}</div>
          <h3>{text}</h3>
        </div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Tags',
      dataIndex: 'name',
      key: 'tags',
      width: '18rem',
      render: (text, sub) => (
        <div className="subscription-tags">
          <div className="subscription-tags-match">{sub.tag_filter === 'any' ? MATCH_TYPES[0] : MATCH_TYPES[1]}</div>
          {sub.labels.length > 0 ? (
            <div className="subscription-tags-wrapper">
              {sub.critical_insight && (
                <div className="tags__tags-tag-element" data-tag-id="-1" key="-1">
                  critical insight
                </div>
              )}
              {sub.labels.map((tag, i) => (
                <div className="tags__tags-tag-element" key={i}>
                  {tag.name}
                </div>
              ))}
            </div>
          ) : (
            <strong>All tag</strong>
          )}
        </div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Apps',
      dataIndex: 'products',
      key: 'products',
      width: '14rem',
      render: (text, sub) => (
        <div className="products-wrapper">
          {sub.products.map((product, i) => (
            <Tooltip title={product.name} key={product.name}>
              <img className="product" src={product.thumbnail} alt="Product thumbnail" />
            </Tooltip>
          ))}
        </div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Product',
      dataIndex: 'licences',
      key: 'licences',
      render: (text, sub) => (
        <div className="subscription-licences-wrap">
          {sub[L_INTELLIGENCE] && (
            <div className="subscription-licence ">
              <img src={IntelligenceIcon} alt="Intelligence icon" />
            </div>
          )}
          {sub[L_MARKETING] && (
            <div className="subscription-licence ">
              <img src={MarketingIcon} alt="Marketing icon" />
            </div>
          )}
        </div>
      ),
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (text, sub) => <div className="subscription-frequency-wrap">{sub.email ? sub.frequency : 'never'}</div>,
    },
  ];
  const createNewSubscription = () => {
    editSubscriptionIDChange(null);
    createSubscriptionVisibleChange(true);
  };
  return (
    <div>
      <CreateNewSubscriptionModal
        subscription={
          editSubscriptionID
            ? subscriptions.find((sub) => sub.id === editSubscriptionID)
            : {
                name: '',
                labels: [],
                tag_filter: 'any',
                products: [],
              }
        }
        modalShow={createSubscriptionVisible}
        closeWindow={() => createSubscriptionVisibleChange(false)}
        products={products}
        tagsList={tagsList}
        licences={getUserLicence(userDetails)}
        createSubscription={createSubscription}
        editSubscription={(data) => editSubscription(editSubscriptionID, data)}
      />
      <Modal
        className="edit-subscription-modal-window"
        title="User preferences and subscriptions"
        width="80rem"
        centered
        visible={modalVisible}
        onOk={() => {
          setIsVisible(false);
        }}
        onCancel={() => {
          setIsVisible(false);
        }}
      >
        <Button style={{ marginBottom: '13px' }} type="primary" onClick={createNewSubscription}>
          New Subscription
        </Button>
        <Table columns={columns} dataSource={subscriptions} pagination={false} />
        <EmailPreferences
          subscriptions={subscriptions}
          userData={userDetails}
          editSubscription={(subscriptionID, data) => editSubscription(subscriptionID, data)}
          updEmailPreferences={updEmailPreferences}
        />
      </Modal>
    </div>
  );
};

export default EditSubscriptionModal;
