import React from 'react';
import { Button, Checkbox, Icon, Popconfirm, Table } from 'antd';

export default ({
  devices,
  isLoading,
  onUpdDevice,
  onDelDevice,
  orchestrators,
  updDevice,
  showFarmDeviceStateModal,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
    },
    {
      title: 'Device Model',
      dataIndex: 'device_model.model',
    },
    {
      title: 'OS Version',
      dataIndex: 'os_version',
    },
    {
      title: 'OS Build',
      dataIndex: 'os_build',
    },
    {
      title: 'Orchestrator',
      dataIndex: 'orchestrator_id',
      render: (value, record) => (
        <React.Fragment>
          {orchestrators.find((orchestrator) => orchestrator.id === value)
            ? orchestrators.find((orchestrator) => orchestrator.id === value).hostname
            : ''}
        </React.Fragment>
      ),
    },
    {
      title: 'API url',
      dataIndex: 'api_url',
    },
    {
      title: 'Is Rooted',
      dataIndex: 'is_rooted',
      render: (value) => <React.Fragment>{value ? 'Yes' : 'No'}</React.Fragment>,
    },
    {
      title: 'Farm JSON',
      dataIndex: 'farm_device_state_body',
      render: (value, record) =>
        record.farm_device_state_body && (
          <Button
            type="small"
            icon="file-text"
            onClick={() => showFarmDeviceStateModal(record.id)}
            title="farm device state"
          >
            View JSON
          </Button>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) =>
        record._deleted ? (
          <div>
            <div style={{ color: '#ff1a1a' }}>Device is deleted</div>
            <div>
              <Button onClick={() => updDevice(record.id, { _deleted: !record._deleted })}>Enable</Button>
            </div>
          </div>
        ) : (
          <div>
            <Button style={{ margin: '4px' }} type="small" icon="edit" onClick={() => onUpdDevice(record.id)} />
            <Popconfirm
              title="Are you sure?"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => {
                onDelDevice(record.id);
              }}
            >
              <Button icon="delete" type="danger small" />
            </Popconfirm>
          </div>
        ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={devices}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
    </div>
  );
};
