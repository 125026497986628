import React from 'react';
import versionEmpty from '../../../assets/icons/version-empty.svg';

export const SubscriptionEmpty = () => (
  <div className="empty-subs">
    <h3>Start creating a Subscription!</h3>
    <div className="empty-subs-desc">And get notified when a new insight with the same topic is published.</div>
    <div className="empty-subs-img">
      <img src={versionEmpty} alt="empty img" />
    </div>
  </div>
);

export default SubscriptionEmpty;
