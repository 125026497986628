import { stringify } from 'query-string';
import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

// Get A/B Tests

export const getAbTests = (page, pageSize, appId, searchQuery, abTestID, hasInsights) => ({
  type: AT.GET_AB_TESTS,
  payload: {
    page,
    pageSize,
    appId,
    searchQuery,
    abTestID,
    hasInsights,
  },
});

export const getAbTestsAPI = (page, pageSize, appId, searchQuery, abTestID, hasInsights) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests?${stringify({
      num_items: pageSize,
      page_num: page,
      app_id: appId,
      search_query: searchQuery,
      id: abTestID || undefined,
      has_insights: hasInsights === null ? undefined : hasInsights,
    })}`,
    label: NETWORK_LABELS.GET_AB_TESTS,
    onSuccess: (body, dispatch) => dispatch(getAbTestsSuccess(body, page, pageSize, appId, searchQuery, hasInsights)),
    onFailure: (error, dispatch) => dispatch(getAbTestsFailed(error)),
  },
});

export const getAbTestsSuccess = (data, page, pageSize, appId, searchQuery, hasInsights) => ({
  type: AT.GET_AB_TESTS_SUCCESS,
  payload: {
    ...data,
    page,
    pageSize,
    appId,
    searchQuery,
    hasInsights,
  },
});

export const getAbTestData = (abTestID) => ({
  type: AT.GET_AB_TEST_DATA,
  payload: {
    abTestID,
  },
});

export const getAbTestDataAPI = (abTestID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests?${stringify({
      num_items: 50,
      page_num: 1,
      id: abTestID || undefined,
    })}`,
    label: NETWORK_LABELS.GET_AB_TEST_DATA,
    onSuccess: (body, dispatch) => dispatch(getAbTestDataSuccess(body)),
  },
});

export const getAbTestDataSuccess = (data) => ({
  type: AT.GET_AB_TEST_DATA_SUCCESS,
  payload: {
    ...data,
  },
});

export const getAbTestsFailed = (error) => ({
  type: AT.GET_AB_TESTS_FAILED,
  payload: { error },
});

// Update A/B Tests

export const updAbTest = (abTestId, data) => ({
  type: AT.UPD_AB_TEST,
  payload: {
    abTestId,
    data,
  },
});

export const updAbTestAPI = (abTestId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_AB_TEST,
    onSuccess: (body, dispatch) => dispatch(updAbTestSuccess(abTestId, body)),
    onFailure: (error, dispatch) => dispatch(updAbTestFailed(error)),
  },
});

export const updAbTestSuccess = (abTestId, body) => ({
  type: AT.UPD_AB_TEST_SUCCESS,
  payload: {
    abTestId,
    abTest: body.data,
  },
});

export const updAbTestFailed = (error) => ({
  type: AT.UPD_AB_TEST_FAILED,
  payload: { error },
});

export const addAbTest = (abTest) => ({
  type: AT.ADD_AB_TEST,
  payload: abTest,
});

export const addAbTestAPI = (abTest) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests`,
    method: 'POST',
    data: abTest,
    label: NETWORK_LABELS.ADD_AB_TEST,
    onSuccess: (body, dispatch) => dispatch(addAbTestSuccess(body)),
    onFailure: (error, dispatch) => dispatch(addAbTestFailed(error)),
  },
});

const addAbTestSuccess = (body) => ({
  type: AT.ADD_AB_TEST_SUCCESS,
  payload: body,
});

const addAbTestFailed = (error) => ({
  type: AT.ADD_AB_TEST_FAILED,
  payload: { error },
});

export const deleteAbTest = (abTestsID) => ({
  type: AT.DELETE_AB_TEST,
  payload: abTestsID,
});

export const deleteAbTestAPI = (abTestID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `ab-tests/${abTestID}`,
    method: 'DELETE',

    label: NETWORK_LABELS.DELETE_AB_TEST,
    onSuccess: (body, dispatch) => dispatch(deleteAbTestSuccess(abTestID)),
    onFailure: (error, dispatch) => dispatch(deleteAbTestFailed(error)),
  },
});

const deleteAbTestSuccess = (abTestID) => ({
  type: AT.DELETE_AB_TEST_SUCCESS,
  payload: abTestID,
});

const deleteAbTestFailed = (error) => ({
  type: AT.DELETE_AB_TEST_FAILED,
  payload: { error },
});
