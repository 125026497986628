/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import moment from 'moment';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Checkbox, Dropdown, Form, Button, TextArea } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { INSIGHT_TYPE, INSIGHT_CAMPAIGN_SUBTYPE, MARKETING_INSIGHT_TYPES } from '../../constants/insightType';
import {
  saveInsight,
  beforeSaveInsight,
  getInsightAssets,
  delInsightAsset,
  updInsightAsset,
  addInsightAsset,
  updInsightThumbnail,
  refreshPresignedUrl,
  addInsightCta,
  updInsightCta,
  delInsightCta,
  getRootNodeByInsightNodeID,
  addInsightTimeline,
  updInsightTimeline,
  delInsightTimeline,
  copyInsight,
  getInsightCtas,
} from '../../redux/actions/insightPage';
import { AB_TEST_STATUS } from '../../constants/abTest';
import { PRIORITY_TYPS } from '../../constants/priorityTyps';

import { countries } from '../../constants/countries';
import wwf from '../../assets/icons/world-wide-flag.svg';
import AbTest from './AbTest';
import Variants from './variants/Variants';

import { DatePicker, Select, Input, Popconfirm, Icon, message } from 'antd';
import { getAbTests, getAbTestData, updAbTest } from '../../redux/actions/abTestsPage';
import InsightAssets from './assets/InsightAssets';
import InsightCtas from './ctas/InsightCtas';
import InsightTimeline from './timeline/InsightTimeline';
import IllegalABTestModal from './abTests/IllegalABTestModal';
import InsightLabels from './InsightLabels';
import InsightPersonas from './InsightPersonas';
import InsightRelations from './InsightRelations';

const { Option } = Select;

const PRIORITY_TYPE_OPTIONS = [
  {
    text: '',
    value: null,
    id: '',
    key: '',
  },
  ...Object.keys(PRIORITY_TYPS).map((type) => ({
    text: PRIORITY_TYPS[type],
    value: type,
    id: type,
    key: type,
  })),
];

const TYPE_OPTIONS = Object.keys(INSIGHT_TYPE).map((type) => ({
  text: INSIGHT_TYPE[type],
  value: type,
  id: type,
  key: type,
}));

const INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS = Object.keys(INSIGHT_CAMPAIGN_SUBTYPE).map((type) => ({
  text: INSIGHT_CAMPAIGN_SUBTYPE[type],
  value: type,
  id: type,
  key: type,
}));

const AB_TEST_STATUS_OPTIONS = Object.keys(AB_TEST_STATUS).map((type) => ({
  text: AB_TEST_STATUS[type],
  value: type,
  id: type,
  key: type,
}));

const CATEGORIES = {
  mass_market: 'Mass Market',
  mass_market_by_segment: 'Mass Market by Segment',
  automated: 'Automated',
};

const CATEGORY_OPTIONS = Object.keys(CATEGORIES).map((key) => ({
  text: CATEGORIES[key],
  value: key,
  id: key,
  key,
}));

const FUNNEL_STAGES = {
  prospects_nursery: 'Prospects/Nursery',
  onboarding: 'Onboarding',
  retention: 'Cross-Sell, Up-Sell & Retention',
  lapsed: 'Lapsed',
  transactional: 'Transactional/Process',
};

const FUNNEL_STAGE_OPTIONS = Object.keys(FUNNEL_STAGES).map((key) => ({
  text: FUNNEL_STAGES[key],
  value: key,
  id: key,
  key,
}));

class InsightForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      insight: this.props.insight,
      labels: [],
      personas: [],
      abTest: null,
      showIllegalAbTest: false,
      singleInsightPerABTestOverride: false,
      tempABTestSelection: null,
    };
    this.debouncedSaveInsight = debounce(this.handleSave, 2000);
  }

  componentDidMount() {
    this.props.getAssets(this.props.insight.id);
    this.props.getInsightCtas(this.props.insight.id);
    if (this.props.insight.ab_test_id) {
      this.props.getAbTestData(this.props.insight.ab_test_id);
    }
    if (this.props.insight.node_id) {
      this.props.getRootNodeByInsightNodeID(this.props.insight.node_id);
    }

    try {
      document.querySelector('.insight-page__body__right').scrollTo(0, 0);
    } catch (e) {
      console.log(e);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.insight !== this.state.insight && this.props.insight !== this.state.insight) {
      this.debouncedSaveInsight();
    }
    if (!!this.props.saveMessage && prevProps.saveMessage !== this.props.saveMessage) {
      message.destroy();
      if (this.props.saveMessage.endsWith('...')) {
        message.loading(this.props.saveMessage);
      } else if (this.props.saveMessage.endsWith('Successfully!')) {
        message.success(this.props.saveMessage);
      } else {
        message.error(this.props.saveMessage);
      }
    }
    if (prevState.abTest !== this.props.currentABTestInsightPage) {
      this.setState({ abTest: this.props.currentABTestInsightPage });
    }
    if (
      prevState.abTest &&
      prevState.abTest !== this.state.abTest &&
      this.state.abTest === this.props.currentABTestInsightPage
    ) {
      message.destroy();
      message.success('abTest was saved');
    }
    if (prevProps.insight.id !== this.props.insight.id) {
      this.setState({ abTest: null });
    }
  }

  componentWillUnmount() {
    this.debouncedSaveInsight.cancel();
  }

  handleShouldShowChange = () => {
    if (
      !this.state.insight.should_show &&
      !this.state.insight.current_release_id &&
      !this.state.insight.type.includes('_campaign')
    ) {
      message.error('Please set current release');
      return;
    }

    // eslint-disable-next-line no-bitwise
    if (!this.state.insight.should_show && this.state.insight.type !== 'indication' && !this.props.insight.thumbnail) {
      message.error('Thumbnail and image(s) must be added to show insight');
      return;
    }

    this.setState({
      insight: { ...this.state.insight, should_show: !this.state.insight.should_show },
    });
  };

  handleAbTestIdChange = (e) => {
    this.setAbTest(parseInt(e.target.value) || null);
  };
  setAbTest = (value) => {
    this.setState({
      insight: { ...this.state.insight, ab_test_id: value },
    });
    this.props.getAbTestData(value);
  };

  handleTitleChange = (e) => {
    const title = e.target.value;
    this.setState({
      insight: { ...this.state.insight, title },
    });
  };

  handleNodeIDchange = (e) => {
    const nodeID = e.target.value;
    this.setState({
      insight: { ...this.state.insight, node_id: Number(nodeID) },
    });
  };

  handleDescriptionChange = (e) => {
    const description = e.target.value;
    this.setState({
      insight: { ...this.state.insight, description },
    });
  };

  handleInternalCommentChange = (e) => {
    const internalComment = e.target.value;
    this.setState({
      insight: {
        ...this.state.insight,
        content: { ...this.state.insight.content, internalComment },
      },
    });
  };

  handleCurrentReleaseChange = (e, selection) => {
    let currentReleaseId = selection.value;
    currentReleaseId = currentReleaseId === -1 ? null : parseInt(currentReleaseId, 10);
    const release = this.props.appVersions.find((appV) => appV.id === currentReleaseId);
    this.setState({
      insight: {
        ...this.state.insight,
        current_release_id: currentReleaseId,
        start_time: release ? moment(release.date).format('YYYY-MM-DD') : this.state.insight.start_time,
      },
    });
  };

  handleOtherReleaseChange = (e, selection) => {
    let otherReleaseId = selection.value;
    otherReleaseId = otherReleaseId === -1 ? null : parseInt(otherReleaseId, 10);
    this.setState({
      insight: { ...this.state.insight, other_release_id: otherReleaseId },
    });
  };

  handleTypeChange = (e, selection) => {
    const newType = selection.value;
    let newInsight;
    if (['ab_test', 'indication'].includes(newType)) {
      newInsight = {
        ...this.state.insight,
        type: newType,
        content: {
          ...this.state.insight.content,
        },
      };
    } else {
      newInsight = { ...this.state.insight, type: newType, ab_test_id: null };
    }
    this.setState({
      insight: newInsight,
    });
  };

  handlePersonaLocationsChange = (selectedValue) => {
    if (
      (selectedValue.includes('ww') && selectedValue.length > 1) ||
      this.state.insight.override_locations.includes('ww')
    ) {
      message.error("You cannot select 'World wide' and other countries");
      return;
    }
    this.setState({
      insight: {
        ...this.state.insight,
        override_locations: [...selectedValue],
      },
    });
  };

  handleABStatusChange = (e, selection) => {
    const status = selection.value;
    if (
      ['integrated', 'abandoned'].includes(status) &&
      this.state.abTest.insights.length === 1 &&
      !this.state.singleInsightPerABTestOverride
    ) {
      this.setState({
        showIllegalAbTest: true,
        tempABTestSelection: selection,
      });
      return;
    }
    this.saveABTestByStatus(status);
  };

  saveABTestByStatus = (status) => {
    this.setState(
      {
        abTest: {
          ...this.state.abTest,
          status,
          champion_variant_id: null,
        },
      },
      () => {
        if (status !== 'integrated') {
          this.saveABTest();
        }
      }
    );
  };

  preUpdAssetHandle = (id, assetId, data) => {
    this.props.updInsightAsset(id, assetId, data);
    if (data.blur_faces) {
      // Kinda polling
      const pollInterval = setInterval(() => {
        if (
          !this.props.insight.ab_test_id &&
          this.props.assets.find(
            (ass) => ass.type === 'video' && ass.status && (ass.status === 'beginning' || ass.status.includes('fps'))
          )
        ) {
          this.props.getAssets(this.props.insight.id);
        } else {
          clearInterval(pollInterval);
        }
      }, 5000);
    }
  };

  handleChampionVariantChange = (e, selection) => {
    const championVariant = selection.value;
    this.setState(
      {
        abTest: {
          ...this.state.abTest,
          champion_variant_id: championVariant,
        },
      },
      () => this.saveABTest()
    );
  };

  handlePriorityChange = (e, selection) => {
    const priority = selection.value;
    this.setState({
      insight: {
        ...this.state.insight,
        priority,
      },
    });
  };

  checkThumbnail = () => {
    const {
      insight: { content },
    } = this.state;
    if (!content.images) return false;
    for (const image of content.images) {
      if (image.generateThumbnail && image.generateThumbnail === true) {
        return true;
      }
    }
    return false;
  };

  handleSave = () => {
    const foundThumbnail = this.checkThumbnail();
    this.setState({ foundThumbnail });
    if (
      this.state.insight.type === 'ab_test' &&
      (this.state.insight.ab_test_id === null || this.state.insight.ab_test_id === '')
    ) {
      message.error('ab test id cannot be empty');
      return;
    }
    if ((this.state.insight.content.images || []).find((x) => x.type === 'ab_on' && !x.varientValue)) {
      message.error('field "Variant Value" is required');
      return;
    }
    if (!this.state.labels.length) {
      message.error('field "Insight Labels" is required');
      return;
    }
    if (this.state.insight.type === 'ab_test' && !this.props.variants.find((v) => v.control)) {
      message.error('There is no control variant for AB test ');
      return;
    }
    if (this.state.insight.type === 'ab_test' && !this.props.variants.find((v) => !v.control)) {
      message.error('There is no non-control variant for AB test ');
      return;
    }
    if (this.state.insight.priority === null) {
      message.error('field "Priority" is required');
      return;
    }
    if (
      this.state.insight.should_show &&
      this.state.insight.type === 'indication' &&
      this.state.insight.ab_test_id &&
      this.state.abTest
    ) {
      const startTimesAbTestInsights = this.state.abTest.insights
        .filter((x) => x.id !== this.state.insight.id)
        .map((abTestInsID) => this.props.insights.find((ins) => ins.id === abTestInsID).start_time);
      if (
        startTimesAbTestInsights.some((abTestInsStartTime) =>
          moment(abTestInsStartTime).isBefore(this.state.insight.start_time)
        )
      ) {
        message.error('Indication insight in ABTest must be first. before any other insight (check start date)');
        return;
      }
    }
    if (this.state.personas.length === 0) {
      message.error('At least one persona is necessary for an insight? John Doe perhaps?');
      return;
    }
    if (!this.state.insight.type.endsWith('_campaign') && !this.state.insight.current_release_id) {
      message.error('Current Release is required');
      return;
    }
    this.props.onSave({ ...this.state.insight, thumbnail: this.props.insight.thumbnail });
  };

  saveABTest = () => {
    message.loading('Saving abTest...');
    this.props.updAbTest(this.state.abTest);
  };

  handleDelete = () => {
    const insight = { ...this.state.insight, is_deleted: true };
    this.setState({ insight });
    this.props.onSave(insight);
  };

  handleCampaignStart = (start_time) => {
    const start_time_moment = moment(start_time);
    this.setState((state) => ({
      insight: {
        ...state.insight,
        start_time: start_time_moment.format('YYYY-MM-DD'),
      },
    }));
  };

  handleABTestInsightEndDate = (date) => {
    this.setState((state) => ({
      insight: {
        ...state.insight,
        content: {
          ...this.state.insight.content,
          end_time: date ? date.format('MMMM DD, YYYY') : '',
        },
      },
    }));
  };

  handleSubTypeChange = (e, selection) => {
    const newSubType = selection.value;
    this.setState((state) => ({
      insight: {
        ...state.insight,
        content: {
          ...this.state.insight.content,
          sub_type: newSubType,
        },
      },
    }));
  };

  handleCategoryChange = (e, selection) => {
    const category = selection.value;
    this.setState({
      insight: { ...this.state.insight, content: { ...this.state.insight.content, category } },
    });
  };

  handleFunnelStageChange = (e, selection) => {
    const funnelStage = selection.value;
    this.setState({
      insight: { ...this.state.insight, content: { ...this.state.insight.content, funnelStage } },
    });
  };

  handleUserSegmentationChange = (e) => {
    const userSegmentation = e.target.value;
    this.setState({
      insight: {
        ...this.state.insight,
        content: { ...this.state.insight.content, userSegmentation },
      },
    });
  };

  handleIsPersonalizedChange = (e) => {
    this.setState({
      insight: {
        ...this.state.insight,
        content: { ...this.state.insight.content, isPersonalized: !this.state.insight.content.isPersonalized },
      },
    });
  };

  handleEmailTitleChange = (e) => {
    const emailTitle = e.target.value;
    this.setState({
      insight: {
        ...this.state.insight,
        content: { ...this.state.insight.content, emailTitle },
      },
    });
  };

  handleReceivedFromChange = (e) => {
    const receivedFrom = e.target.value;
    this.setState({
      insight: {
        ...this.state.insight,
        content: { ...this.state.insight.content, receivedFrom },
      },
    });
  };

  render() {
    const { insight, abTest } = this.state;
    const { personas, currentABTestInsightPage } = this.props;
    const allCountries = [
      {
        name: 'World Wide',
        code: 'ww',
      },
      ...countries,
    ];

    return (
      <div className={`insight-form `}>
        <div
          className={`insight-form__form ${
            (this.props.user && this.props.user.reviewer) || this.props.duringSave ? 'insight-form-disable-all' : ''
          }`}
        >
          <h1>
            {insight.title}
            {this.props.insight.node_id && this.props.root_node_id && (
              <a href={`/#/ui-viewer/${this.props.root_node_id}`} target="_blank" className="link-to-graph">
                graph
              </a>
            )}
          </h1>
          <h3 className="insight-form__form__title">{`Insight ${insight.id}`}</h3>
          <Form>
            <div className="inline fields">
              <Popconfirm
                title={
                  insight.should_show
                    ? `Are you sure? This insight might already be exposed to client`
                    : 'Are you sure?'
                }
                placement="top"
                onConfirm={this.handleShouldShowChange}
              >
                <Checkbox label="Should Show" checked={insight.should_show} />
              </Popconfirm>
            </div>
            <div className="fields">
              <div className="field">
                <label>Type</label>
                <Dropdown selection options={TYPE_OPTIONS} value={insight.type} onChange={this.handleTypeChange} />
              </div>
            </div>
            <div className="fields">
              {insight.type === 'ab_test' &&
                ['integrated', 'abandoned'].includes(currentABTestInsightPage && currentABTestInsightPage.status) && (
                  <div className="field">
                    <label>End Date</label>
                    <DatePicker
                      onChange={this.handleABTestInsightEndDate}
                      size="large"
                      value={moment(insight.content.end_time)}
                    />
                  </div>
                )}
            </div>
            {['ab_test', 'indication'].includes(insight.type) && (
              <div>
                <hr />
                <h2>AB TEST DATA Section</h2>
                <div className="ten wide field" style={{ display: 'flex' }}>
                  <div className="field">
                    <label>
                      AB Test<span className="red-star">*</span>
                    </label>
                    <Input
                      type="number"
                      value={insight.ab_test_id || ''}
                      onChange={this.handleAbTestIdChange}
                      required
                    />
                  </div>
                  {(currentABTestInsightPage || !this.props.insight.type) && (
                    <AbTest
                      insight={insight}
                      getAbTests={(searchQuery) => this.props.getAbTests(this.state.insight.app_id, searchQuery)}
                      abTests={this.props.abTests}
                      setAbTestID={this.setAbTest}
                      currentABTestInsightPage={
                        currentABTestInsightPage && currentABTestInsightPage.id === this.props.insight.ab_test_id
                          ? currentABTestInsightPage
                          : {}
                      }
                    />
                  )}
                </div>
                {abTest && abTest.insights && (
                  <div className="fields">
                    <div className="field">
                      <label>AB Test Insights</label>
                      <ul>
                        {abTest.insights.map((x) => (
                          <li>{x.id}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            )}
            {['ab_test', 'indication'].includes(insight.type) &&
              abTest &&
              abTest.id === this.props.insight.ab_test_id && (
                <div className="fields">
                  <div className="field">
                    <label>Status</label>
                    <Dropdown
                      selection
                      options={AB_TEST_STATUS_OPTIONS}
                      value={abTest.status}
                      onChange={this.handleABStatusChange}
                    />
                  </div>
                  {abTest && abTest.status === 'integrated' && (
                    <div className="field">
                      <label>Champion Variant</label>
                      <Dropdown
                        selection
                        options={
                          this.props.variants
                            ? this.props.variants
                                .filter((v) => !v.control)
                                .map((v) => ({
                                  text: `#${v.id} | Type ${v.type} | Control = ${v.control ? 'true' : 'false'}`,
                                  value: v.id,
                                }))
                            : []
                        }
                        value={abTest.champion_variant_id}
                        onChange={this.handleChampionVariantChange}
                      />
                      {!abTest.champion_variant_id && (
                        <div style={{ color: '#e13e3e' }}>!Need choose champion variant</div>
                      )}
                    </div>
                  )}
                </div>
              )}
            <hr />

            <div className="fields">
              <React.Fragment>
                <div className="field">
                  <label>Start Date/Release Date/Date in Dashboard </label>
                  <DatePicker
                    showTime
                    onChange={this.handleCampaignStart}
                    value={moment(insight.start_time)}
                    format="YYYY-MM-DD"
                    allowClear={false}
                  />
                </div>
                {this.state.insight.current_release_id && (
                  <div className="field btn-date-get-release">
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        const release = this.props.appVersions.find(
                          (appV) => appV.id === this.state.insight.current_release_id
                        );
                        if (release) {
                          this.handleCampaignStart(moment(release.date));
                        }
                      }}
                      style={{ marginLeft: '1rem' }}
                    >
                      {' '}
                      Get Date from Release (for app discoveries)
                    </Button>
                  </div>
                )}
                <div className="field">
                  <label>Insight updated</label>
                  {moment.utc(this.props.insight.updated_at).local().format('DD/MM/YYYY HH:mm:ss')}
                  {insight.updated_by && ` by ${insight.updated_by}`}
                </div>
              </React.Fragment>
            </div>

            <div className="fields">
              {MARKETING_INSIGHT_TYPES.includes(insight.type) && (
                <div className="field">
                  <label>Category(sub_type)</label>
                  <Dropdown
                    selection
                    options={INSIGHT_CAMPAIGN_SUBTYPE_OPTIONS}
                    value={insight.content.sub_type}
                    onChange={this.handleSubTypeChange}
                  />
                </div>
              )}
            </div>
            {MARKETING_INSIGHT_TYPES.includes(insight.type) && (
              <React.Fragment>
                <div className="fields">
                  <div className="field">
                    <label>Category</label>
                    <Dropdown
                      selection
                      options={CATEGORY_OPTIONS}
                      value={insight.content.category}
                      onChange={this.handleCategoryChange}
                    />
                  </div>
                  <div className="field">
                    <label>Funnel Stage</label>
                    <Dropdown
                      selection
                      options={FUNNEL_STAGE_OPTIONS}
                      value={insight.content.funnelStage}
                      onChange={this.handleFunnelStageChange}
                    />
                  </div>
                </div>
                <div className="field">
                  <label>User Segmentation</label>
                  <Input value={insight.content.userSegmentation} onChange={this.handleUserSegmentationChange} />
                </div>
              </React.Fragment>
            )}
            <div className="fields">
              {MARKETING_INSIGHT_TYPES.includes(insight.type) && (
                <div className="field">
                  <label>Timeline</label>
                  <InsightTimeline
                    isLoading={this.props.isLoading}
                    error={this.props.error}
                    timeline={this.props.insight.timeline}
                    addTimeline={(data) => this.props.addInsightTimeline(this.props.insight.id, data)}
                    updTimeline={(timelineId, data) =>
                      this.props.updInsightTimeline(this.props.insight.id, timelineId, data)
                    }
                    delTimeline={(timelineId) => this.props.delInsightTimeline(this.props.insight.id, timelineId)}
                  />
                </div>
              )}
            </div>
            <div className="fields">
              <div className="field">
                <label>
                  Personas<span className="red-star">*</span>
                </label>
                <InsightPersonas
                  insightId={this.props.insight.id}
                  suggestions={personas}
                  setPersonas={(personas) => this.setState({ personas })}
                />
              </div>
              <div className="field" style={{ maxWidth: '400px' }}>
                <label>Countries</label>
                <Checkbox
                  checked={insight.override_locations !== null}
                  onClick={(e) => {
                    this.setState({
                      insight: { ...insight, override_locations: insight.override_locations !== null ? null : [] },
                    });
                  }}
                  label="Override countries"
                />
                <Select
                  mode="multiple"
                  placeholder="Override Countries"
                  onChange={this.handlePersonaLocationsChange}
                  value={insight.override_locations}
                  disabled={insight.override_locations === null}
                >
                  {allCountries.map((country) =>
                    country.code === 'ww' ? (
                      <Option value={country.code} key={country.code}>
                        <img src={wwf} alt="world wide" />
                        {country.name}
                      </Option>
                    ) : (
                      <Option value={country.code} key={country.code}>
                        <span
                          style={{ marginRight: '1rem' }}
                          className={`flag-icon flag-icon-${country.code.toLowerCase()}`}
                        />
                        {country.name}
                      </Option>
                    )
                  )}
                </Select>
                <div style={{ fontSize: '14px' }}>*Not recommended for the faint of heart </div>
              </div>
              <div className="field">
                <label>Node ID</label>
                <input
                  type="number"
                  value={insight.node_id || ''}
                  onChange={this.handleNodeIDchange}
                  style={{ 'pointer-events': 'auto' }}
                />
              </div>
            </div>
            <div className="fields">
              <div className="ten wide field">
                <label>Title</label>
                <input
                  type="text"
                  value={insight.title || ''}
                  onChange={this.handleTitleChange}
                  style={{ 'pointer-events': 'auto' }}
                />
              </div>
              <div className="field">
                <label>
                  Priority<span className="red-star">*</span>
                </label>
                <Dropdown
                  selection
                  options={PRIORITY_TYPE_OPTIONS}
                  value={insight.priority}
                  onChange={this.handlePriorityChange}
                />
              </div>
            </div>
            <div className="field">
              <label>Long Text</label>
              <TextArea
                value={insight.description}
                onChange={this.handleDescriptionChange}
                className={insight.description !== this.props.insight.description ? 'changed' : ''}
                style={{ 'pointer-events': 'auto', maxWidth: '88rem' }}
              />
            </div>
            <div className="releases-wrapper">
              <div className="fields">
                <div className="ten wide field">
                  <label>
                    Current Release <span className="red-star">*</span>
                  </label>
                  <Dropdown
                    selection
                    options={this.props.appVersions}
                    value={insight.current_release_id || -1}
                    onChange={this.handleCurrentReleaseChange}
                  />
                </div>
                <div className="field">
                  <label>Local Id</label>
                  <input type="text" value={insight.local_id || ''} disabled />
                </div>
              </div>
              <div className="ten wide field">
                <label>Other Release</label>
                <Dropdown
                  selection
                  options={this.props.appVersions}
                  value={insight.other_release_id || -1}
                  onChange={this.handleOtherReleaseChange}
                />
              </div>
            </div>

            <div className="field">
              <label>Related Insights</label>
              <InsightRelations insightId={this.props.insight.id} />
            </div>
            <div className="field">
              <label>
                Insight Labels<span className="red-star">*</span>
              </label>
              <InsightLabels
                insightId={this.props.insight.id}
                suggestions={this.props.labelsSuggestions}
                setLabels={(labels) => this.setState({ labels })}
              />
            </div>
            {MARKETING_INSIGHT_TYPES.includes(insight.type) && (
              <React.Fragment>
                <div className="field">
                  <Checkbox
                    label="Is Personalized"
                    checked={insight.content.isPersonalized || false}
                    onChange={this.handleIsPersonalizedChange}
                  />
                </div>
                <div className="field">
                  <label>Email Title</label>
                  <input type="text" value={insight.content.emailTitle} onChange={this.handleEmailTitleChange} />
                </div>
                <div className="field">
                  <label>Received From</label>
                  <TextArea value={insight.content.receivedFrom} onChange={this.handleReceivedFromChange} />
                </div>
                <InsightCtas
                  isLoading={this.props.isLoading}
                  error={this.props.error}
                  ctas={this.props.ctas}
                  addCta={(data) => this.props.addInsightCta(this.props.insight.id, data)}
                  updCta={(ctaId, data) => this.props.updInsightCta(this.props.insight.id, ctaId, data)}
                  delCta={(ctaId) => this.props.delInsightCta(this.props.insight.id, ctaId)}
                />
              </React.Fragment>
            )}
            <div className="field internal-comment">
              <label>Internal Comment</label>
              <TextArea value={insight.content.internalComment} onChange={this.handleInternalCommentChange} />
            </div>
          </Form>

          <div className="field">
            <br />
            <label>
              <b>Thumbnail</b>
            </label>
            <div style={{ margin: '2rem 0' }}>
              {this.props.insight.thumbnail ? (
                <img src={this.props.insight.thumbnail} alt="insight thumbnail" />
              ) : (
                <div>no thumbnail</div>
              )}
            </div>
          </div>

          <div className="field">
            {insight.ab_test_id && insight.current_release_id && (
              <Variants
                insightId={insight.id}
                abTestId={insight.ab_test_id}
                releaseId={insight.current_release_id}
                isDisabled={this.props.user.reviewer || this.props.duringSave}
              />
            )}
          </div>

          <div
            className="field"
            style={this.props.user.reviewer || this.props.duringSave ? { 'pointer-events': 'auto' } : null}
          >
            {insight.type !== 'ab_test' && (
              <InsightAssets
                isLoading={this.props.isLoading}
                error={this.props.error}
                uploadingStatus={this.props.uploadingStatus}
                assets={this.props.assets}
                addAsset={(data) => this.props.addInsightAsset(insight.id, data)}
                updAsset={(assetId, data) => this.preUpdAssetHandle(insight.id, assetId, data)}
                delAsset={(assetId) => this.props.delInsightAsset(insight.id, assetId)}
                setInsightThumbnail={(data) => this.props.updInsightThumbnail(insight.id, data)}
                presignedUrl={this.props.presignedUrl}
                refreshPresignedUrl={this.props.refreshPresignedUrl}
                isDisabled={this.props.user.reviewer || this.props.duringSave}
                insightType={insight.type}
              />
            )}
          </div>
        </div>
        <IllegalABTestModal
          onOk={() => {
            this.setState(
              {
                showIllegalAbTest: false,
                singleInsightPerABTestOverride: true,
              },
              () => {
                this.handleABStatusChange(null, this.state.tempABTestSelection);
                this.setState({
                  showIllegalAbTest: false,
                  tempABTestSelection: null,
                  singleInsightPerABTestOverride: false,
                });
              }
            );
          }}
          onCancel={() =>
            this.setState({
              showIllegalAbTest: false,
              tempABTestSelection: null,
              singleInsightPerABTestOverride: false,
            })
          }
          visible={this.state.showIllegalAbTest}
        />
        <div className="insight-form__save">
          <Button disabled={this.props.duringSave} onClick={() => this.props.copyInsight(insight.id)} primary>
            COPY
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this insight?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={this.handleDelete}
          >
            <Button disabled={this.props.duringSave} primary style={{ background: '#ed553b' }}>
              DELETE
            </Button>
          </Popconfirm>
        </div>
        <div className="insight-form__delete" />
      </div>
    );
  }
}
InsightForm.defaultProps = {
  saveMessage: null,
};
InsightForm.propTypes = {
  insight: PropTypes.shape({
    id: PropTypes.number.isRequired,
    should_show: PropTypes.bool.isRequired,
  }).isRequired,
  appVersions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      key: PropTypes.number,
      text: PropTypes.string,
      date: PropTypes.string,
    })
  ).isRequired,
  onSave: PropTypes.func.isRequired,
  duringSave: PropTypes.bool.isRequired,
  saveMessage: PropTypes.string,
  user: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onSave: (insight) => {
    dispatch(beforeSaveInsight());
    dispatch(saveInsight(insight));
  },
  copyInsight: (insightId) => dispatch(copyInsight(insightId)),
  getAbTests: (app_id, searchQuery) => dispatch(getAbTests(1, 50, app_id, searchQuery, '')),
  getAssets: (insightId) => dispatch(getInsightAssets(insightId)),
  delInsightAsset: (insightId, assetId) => dispatch(delInsightAsset(insightId, assetId)),
  updInsightAsset: (insightId, assetId, data) => dispatch(updInsightAsset(insightId, assetId, data)),
  addInsightAsset: (insightId, data) => dispatch(addInsightAsset(insightId, data)),
  updInsightThumbnail: (insightId, data) => dispatch(updInsightThumbnail(insightId, data)),
  refreshPresignedUrl: () => dispatch(refreshPresignedUrl()),
  getAbTestData: (abTestID) => dispatch(getAbTestData(abTestID)),
  updAbTest: (abTest) => dispatch(updAbTest(abTest.id, abTest)),
  addInsightCta: (insightId, data) => dispatch(addInsightCta(insightId, data)),
  updInsightCta: (insightId, ctaId, data) => dispatch(updInsightCta(insightId, ctaId, data)),
  delInsightCta: (insightId, ctaId) => dispatch(delInsightCta(insightId, ctaId)),
  getRootNodeByInsightNodeID: (node_id) => dispatch(getRootNodeByInsightNodeID(node_id)),
  addInsightTimeline: (insightId, data) => dispatch(addInsightTimeline(insightId, data)),
  updInsightTimeline: (insightId, timelineId, data) => dispatch(updInsightTimeline(insightId, timelineId, data)),
  delInsightTimeline: (insightId, timelineId) => dispatch(delInsightTimeline(insightId, timelineId)),
  getInsightCtas: (insightId) => dispatch(getInsightCtas(insightId)),
});

const mapStateToProps = (state) => ({
  duringSave: state.insightPage.duringSave,
  saveMessage: state.insightPage.saveMessage,
  labelsSuggestions: state.insightPage.labels,
  abTests: state.abTestsPage.abTests,
  assets: state.insightPage.assets,
  ctas: state.insightPage.ctas,
  isLoading: state.insightPage.isLoading,
  uploadingStatus: state.insightPage.uploadingStatus,
  error: state.insightPage.error,
  progressPercentUpload: state.insightPage.addImage.progressPercentUpload,
  presignedUrl: state.insightPage.presignedUrlData,
  variants: state.insightPage.variants,
  currentABTestInsightPage: state.abTestsPage.currentABTestInsightPage
    ? state.abTestsPage.currentABTestInsightPage[0]
    : null,
  root_node_id: state.insightPage.root_node_id,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightForm);
