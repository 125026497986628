import React, { useState } from 'react';
import { Button } from 'antd';
import DevicesModelsTable from './Table';
import DeviceModelModal from './Modal';

export default ({ isLoading, deviceModels, addDeviceModel, updDeviceModel, delDeviceModel }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditDeviceModelId, setCurEditDeviceModelId] = useState(null);

  const handleUpdDeviceModel = (deviceModelId) => {
    setCurEditDeviceModelId(deviceModelId);
    setIsModalVisible(true);
  };

  return (
    <React.Fragment>
      {isModalVisible && (
        <DeviceModelModal
          deviceModel={deviceModels.find((x) => x.id === curEditDeviceModelId)}
          handleHide={() => {
            setIsModalVisible(false);
            setCurEditDeviceModelId(null);
          }}
          addSubmit={addDeviceModel}
          updSubmit={updDeviceModel}
        />
      )}
      <Button onClick={() => setIsModalVisible(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        Add Device Model
      </Button>
      <DevicesModelsTable
        deviceModels={deviceModels}
        isLoading={isLoading}
        onUpdDeviceModel={handleUpdDeviceModel}
        onDelDeviceModel={delDeviceModel}
        onChangePriority={(deviceModelId, priority) => updDeviceModel(deviceModelId, { priority })}
        updDeviceModel={updDeviceModel}
      />
    </React.Fragment>
  );
};
