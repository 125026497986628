import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Modal, Select, Table } from 'antd';
import { getOrgPerms, updOrgPerm, delOrgPerm } from '../../../api/appsAPI';
import { getOrgs } from '../../../api/organizationsAPI';
import { ORG_PERMS } from '../../../constants/apps';

export default ({ appId, onClose }) => {
  const [currOrgId, setCurrOrgId] = useState(null);
  const [curOrgPerm, setCurOrgPerm] = useState('nothing');
  const queryClient = useQueryClient();
  const orgs = useQuery(['orgs'], getOrgs);
  const orgPerms = useQuery(['orgPerms', appId], () => appId && getOrgPerms(appId));
  const updOrgPermMutation = useMutation(updOrgPerm, {
    onSuccess: () => queryClient.invalidateQueries(['orgPerms']),
  });
  const delOrgPermMutation = useMutation(delOrgPerm, {
    onSuccess: () => queryClient.invalidateQueries(['orgPerms']),
  });
  const orgsList = orgs.isFetched ? orgs.data.data : [];
  const orgPermsList = orgPerms.isFetched ? orgPerms.data.data : [];
  const isLoading = [orgs, orgPerms, updOrgPermMutation, delOrgPermMutation].some((x) => x.isLoading);

  return (
    <Modal
      title={`Org Permissions for App #${appId}`}
      visible
      onOk={onClose}
      onCancel={onClose}
      closable
      centered
      width="60%"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <Select
        placeholder="Organization"
        style={{ width: '30%' }}
        loading={isLoading}
        onSelect={(value) => setCurrOrgId(value)}
        value={currOrgId}
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
      >
        {orgsList
          .filter((x) => !orgPermsList.map((y) => y.id).includes(x.id))
          .map((org) => (
            <Select.Option value={org.id} key={org.id}>
              {org.name}
            </Select.Option>
          ))}
      </Select>
      <Select
        loading={isLoading}
        value={curOrgPerm}
        style={{ minWidth: '16rem', margin: '0 1rem' }}
        onSelect={(value) => setCurOrgPerm(value)}
      >
        {Object.entries(ORG_PERMS).map(([key, value]) => (
          <Select.Option value={key}>{value}</Select.Option>
        ))}
      </Select>
      <Button
        loading={isLoading}
        disabled={!currOrgId}
        onClick={() => {
          updOrgPermMutation.mutateAsync({
            appId,
            orgId: currOrgId,
            body: {
              has_performance: ['both', 'performance'].includes(curOrgPerm),
              has_experiments: ['both', 'experiments'].includes(curOrgPerm),
            },
          });
          setCurrOrgId(null);
        }}
      >
        Add
      </Button>
      <Table
        dataSource={orgPermsList}
        loading={isLoading}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
          },
          {
            title: 'Perms',
            render: (record) => (
              <Select
                value={
                  record.has_performance && record.has_experiments
                    ? 'both'
                    : record.has_performance
                      ? 'performance'
                      : record.has_experiments
                        ? 'experiments'
                        : 'nothing'
                }
                style={{ minWidth: '16rem' }}
                onChange={(value) =>
                  updOrgPermMutation.mutateAsync({
                    appId,
                    orgId: record.id,
                    body: {
                      has_performance: ['both', 'performance'].includes(value),
                      has_experiments: ['both', 'experiments'].includes(value),
                    },
                  })
                }
              >
                {Object.entries(ORG_PERMS).map(([key, value]) => (
                  <Select.Option value={key}>{value}</Select.Option>
                ))}
              </Select>
            ),
          },
          {
            title: '',
            render: (value, record) => (
              <Button
                icon="delete"
                type="danger small"
                onClick={() =>
                  delOrgPermMutation.mutateAsync({
                    appId,
                    orgId: record.id,
                  })
                }
                disabled
              />
            ),
          },
        ]}
        pagination={false}
      />
    </Modal>
  );
};
