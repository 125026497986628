import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'antd';
import { Button } from 'semantic-ui-react';
import VariantTable from './VariantTable';
import {
  getVariants,
  addVariant,
  updVariant,
  delVariant,
  addVariantAsset,
  updVariantAsset,
  delVariantAsset,
  addVariantRelease,
  delVariantRelease,
  updInsightThumbnail,
  refreshPresignedUrl,
} from '../../../redux/actions/insightPage';

import VariantModal from './VariantModal';
import AssetsModal from './AssetsModal';
import { useRef } from 'react';

const Variants = ({
  insightId,
  abTestId,
  releaseId,
  isLoading,
  uploadingStatus,
  error,
  variants,
  getVariants,
  addVariant,
  updVariant,
  delVariant,
  addVariantAsset,
  updVariantAsset,
  delVariantAsset,
  updInsightThumbnail,
  addVariantRelease,
  delVariantRelease,
  presignedUrl,
  refreshPresignedUrl,
  isDisabled,
}) => {
  useEffect(() => {
    getVariants(abTestId);
  }, [abTestId]);

  const [isVariantModalVisible, setIsVariantModalVisible] = useState(false);
  const [isAssetsModalVisible, setIsAssetsModalVisible] = useState(false);
  const [activeVariantId, setActiveVariantId] = useState(null);

  const pollInterval = useRef(null);

  const handleVariantEdit = (variantId) => {
    setIsVariantModalVisible(true);
    setActiveVariantId(variantId);
  };

  const handleAssetsEdit = (variantId) => {
    setIsAssetsModalVisible(true);
    setActiveVariantId(variantId);
  };

  const handleSetInsightThumbnail = (data) => {
    updInsightThumbnail(insightId, data);
  };

  const prepUpdVariantAsset = (id, data) => {
    updVariantAsset(id, data);
    if (data.blur_faces) {
      //Kinda polling
      pollInterval.current = setInterval(() => {
        getVariants(abTestId);
      }, 5000);
    }
  };

  useEffect(() => {
    if (!pollInterval.current) {
      return;
    }
    if (
      !variants.some((variant) =>
        variant.assets.find(
          (ass) => ass.type === 'video' && ass.status && (ass.status === 'beginning' || ass.status.includes('fps'))
        )
      )
    ) {
      clearInterval(pollInterval.current);
    }
  }, [variants]);

  return (
    <div>
      {error && error.message && error.response && error.response.body && (
        <Alert
          message={error.message}
          description={
            error && error.response && error.response.body && error.response.body.error
              ? error.response.body.error
              : error
          }
          type="error"
          showIcon
        />
      )}
      {isVariantModalVisible && (
        <VariantModal
          abTestId={abTestId}
          releaseId={releaseId}
          variant={variants.find((x) => x.id === activeVariantId)}
          handleHide={() => {
            setIsVariantModalVisible(false);
            setActiveVariantId(null);
          }}
          addSubmit={addVariant}
          updSubmit={updVariant}
        />
      )}
      {isAssetsModalVisible && (
        <AssetsModal
          variant={variants.find((x) => x.id === activeVariantId)}
          variants={variants}
          handleHide={() => {
            setIsAssetsModalVisible(false);
            setActiveVariantId(null);
          }}
          isLoading={isLoading}
          uploadingStatus={uploadingStatus}
          error={error}
          addVariantAsset={addVariantAsset}
          updVariantAsset={prepUpdVariantAsset}
          delVariantAsset={delVariantAsset}
          setInsightThumbnail={handleSetInsightThumbnail}
          presignedUrl={presignedUrl}
          refreshPresignedUrl={refreshPresignedUrl}
        />
      )}
      <VariantTable
        variants={variants}
        releaseId={releaseId}
        isLoading={isLoading}
        onVariantRemove={delVariant}
        onVariantEdit={handleVariantEdit}
        onAssetsEdit={handleAssetsEdit}
        onReleaseAdd={(variantId, releaseId) => addVariantRelease(abTestId, variantId, releaseId)}
        onReleaseRemove={(variantId, releaseId) => delVariantRelease(abTestId, variantId, releaseId)}
        isDisabled={isDisabled}
      />
      <Button type="primary" onClick={() => setIsVariantModalVisible(true)} icon="add" loading={isLoading}>
        Add Variant
      </Button>
    </div>
  );
};

const mapDispatchToProps = {
  getVariants,
  addVariant,
  updVariant,
  delVariant,
  addVariantAsset,
  updVariantAsset,
  delVariantAsset,
  addVariantRelease,
  delVariantRelease,
  updInsightThumbnail,
  refreshPresignedUrl,
};

const mapStateToProps = ({ insightPage }) => ({
  variants: insightPage.variants,
  isLoading: insightPage.isLoading,
  uploadingStatus: insightPage.uploadingStatus,
  error: insightPage.error,
  presignedUrl: insightPage.presignedUrlData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Variants);
