import { backendApiRequest } from './request';

export const getSettings = async () =>
  backendApiRequest({
    method: 'GET',
    path: '/admin/settings',
  });

export const setSetting = async ({ name, value }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/settings/${name}`,
    body: { value },
  });
