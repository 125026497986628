import { backendApiRequest } from './request';
import { Auth } from 'aws-amplify';

const SLACK_SERVICE_API = 'https://nc4qu6gawyuhsuheii7acgjeom0mxtta.lambda-url.us-east-1.on.aws//?_path';

export const getOrgs = async () =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/organizations`,
  });

export const getOrgHighlights = async (orgId) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/organizations/${orgId}/highlights`,
  });

export const getSlackRecipients = async () => {
  const requestParams = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
    },
  };
  const res = await fetch(`${SLACK_SERVICE_API}=recipients`, requestParams);
  return res.json();
};

export const getSlackSenders = async () => {
  const requestParams = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
    },
  };
  const res = await fetch(`${SLACK_SERVICE_API}=senders`, requestParams);
  return res.json();
};

export const getSlackMessage = async () => {
  const requestParams = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
    },
  };
  const res = await fetch(`${SLACK_SERVICE_API}=messages`, requestParams);
  return res.json();
};

export const postSlackMessage = async (body) => {
  const requestParams = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(await Auth.currentSession()).idToken.jwtToken}`,
    },
    body: JSON.stringify(body),
  };
  const res = await fetch(`${SLACK_SERVICE_API}=messages`, requestParams);
  const json = await res;
  if (!res.ok) {
    throw new Error(JSON.stringify(json));
  }

  return json;
};
