import React, { useState } from 'react';
import { Select, Modal, Popconfirm, Button } from 'antd';
import './highlightsModal.scss';
import share from '../../../assets/icons/265-2656111_connection-android-share-icon-png.png';
import { useMutation, useQuery } from 'react-query';
import { postSlackMessage } from '../../../api/organizationsAPI';
import { getHighlightSlackMessage } from '../../../api/insightsAPI';

const { Option } = Select;

const SlackHighlightNotification = ({ visible, visibleChange, recipients, senders, highlight }) => {
  const [sender, senderChange] = useState(null);
  const [recipient, recipientChange] = useState(null);
  const [isSlackSent, isSlackSentChange] = useState(false);
  const shareSlackMutation = useMutation(postSlackMessage);
  const slackMessage = useQuery(['highlightSlackMessage', highlight.id], () =>
    getHighlightSlackMessage({ highlightId: highlight.id })
  );

  const shareSlack = async () => {
    const message = {
      recipient_id: recipient,
      sender_id: sender,
      text: 'Insight Hightlights',
      blocks: slackMessage.data.data.blocks,
    };
    await shareSlackMutation.mutateAsync(message);
    isSlackSentChange(true);
  };

  return (
    <Modal visible={visible} onCancel={() => visibleChange(null)} width="50%" centered>
      <div className="highlights-wrapper">
        <div className="column">
          Sender:
          <Select style={{ width: '200px' }} onChange={(val) => senderChange(val)}>
            {senders.map((sender) => (
              <Option value={sender.id} key={sender.id}>
                {sender.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="column">
          Receiver:
          <Select style={{ width: '200px' }} onChange={(val) => recipientChange(val)}>
            {recipients.map((recipient) => (
              <Option value={recipient.id} key={recipient.id}>
                {recipient.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="column">
          Send highlight
          <Popconfirm
            title="Are you sure you want to share this highlight?"
            onConfirm={shareSlack}
            disabled={isSlackSent || !slackMessage.isSuccess}
          >
            <Button type="primary" disabled={isSlackSent}>
              <img src={share} width="20px" height="15px" style={{ marginRight: '10px' }} />
              Share
            </Button>
          </Popconfirm>
        </div>
      </div>
    </Modal>
  );
};

export default SlackHighlightNotification;
