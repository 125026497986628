import { handleActions } from 'redux-actions';
import * as AT from '../actionTypes';
import { isUndefined } from 'lodash/lang';

const initialState = {
  selectedAppName: null,
  selectedAppPackage: null,
  insights: [],
  labels: [],
  personas: [],
  selectedInsightId: null,
  duringSave: false,
  saveMessage: null,
  addImage: {
    data: {},
    status: 'init',
  },
  createInsightStatus: '',
  isLoading: false,
  uploadingStatus: null,
  error: null,
  variants: [],
  assets: [],
  presignedUrlData: null,
  root_node_id: null,
  ctas: [],
};

const insightComparer = (insightA, insightB) => {
  const dateA = new Date(insightA.created);
  const dateB = new Date(insightB.created);

  if (dateA > dateB) {
    return -1;
  }
  if (dateB > dateA) {
    return 1;
  }
  return 0;
};

const insightPageReudcer = handleActions(
  {
    [AT.SELECT_APP]: (state, action) => ({
      ...state,
      selectedAppName: action.payload.appName,
      selectedAppPackage: action.payload.appPackage,
      selectedAppPlatform: action.payload.appPlatform,
      insights: [],
    }),
    [AT.SET_INSIGHTS]: (state, action) => ({
      ...state,
      insights: (action.payload.insights || []).sort(insightComparer),
    }),
    [AT.SET_LABELS]: (state, action) => ({
      ...state,
      labels: action.payload.labels || [],
    }),
    [AT.SELECT_INSIGHT]: (state, action) => ({
      ...state,
      selectedInsightId: action.payload.insightId,
      saveMessage: null,
      duringSave: false,
    }),
    [AT.INSIGHT_SAVED]: (state, action) => ({
      ...state,
      duringSave: false,
      saveMessage: 'Saved Successfully!',
      insights: state.insights.map((insight) => {
        if (insight.id === action.payload.insight.id) {
          return {
            ...action.payload.insight,
            analyst_name: action.payload.response.insight.analyst_name,
            updated_at: action.payload.response.insight.updated_at,
            updated_by: action.payload.response.insight.updated_by,
          };
        }
        return insight;
      }),
    }),
    [AT.INSIGHT_SAVED_ERROR]: (state, action) => ({
      ...state,
      duringSave: false,
      saveMessage: action.payload.errorMessage,
    }),
    [AT.BEFORE_INSIGHT_SAVED]: (state) => ({
      ...state,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.CREATE_INSIGHT]: (state) => ({
      ...state,
      createInsightStatus: 'Creating...',
    }),
    [AT.CREATE_INSIGHT_SUCCESS]: (state, action) => {
      const { insight } = action.payload;
      const insights = [{ ...insight, priority: null }, ...state.insights];
      return {
        ...state,
        insights,
        createInsightStatus: 'Done',
      };
    },
    [AT.CREATE_INSIGHT_FAILED]: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        createInsightStatus: `${error.message}(${JSON.stringify(error.response.body)})`,
      };
    },
    [AT.ADD_IMAGE]: (state) => {
      const addImage = { data: {}, status: 'upload' };
      return {
        ...state,
        addImage: {
          ...addImage,
          progressPercentUpload: 0,
        },
      };
    },
    [AT.ADD_IMAGE_SUCCESS]: (state, action) => {
      const addImage = { data: action.payload, status: 'done' };
      return {
        ...state,
        addImage: {
          ...addImage,
          progressPercentUpload: 100,
        },
      };
    },
    [AT.ADD_IMAGE_PROGRESS]: (state, action) => ({
      ...state,
      addImage: {
        ...state.addImage,
        progressPercentUpload: action.payload.percent,
      },
    }),
    [AT.GET_INSIGHT_PERSONAS_SUCCESS]: (state, action) => ({
      ...state,
      personas: action.payload.data,
    }),

    [AT.VARIANTS_API_REQUEST_FAILED]: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
      };
    },
    [AT.GET_VARIANTS]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.GET_VARIANTS_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      variants: action.payload,
    }),
    [AT.ADD_VARIANT]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.ADD_VARIANT_SUCCESS]: (state, action) => {
      const { variant } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: [...state.variants, variant],
      };
    },
    [AT.UPD_VARIANT]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.UPD_VARIANT_SUCCESS]: (state, action) => {
      const { variant } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants.map((x) => (x.id === variant.id ? variant : x)),
      };
    },
    [AT.DEL_VARIANT]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.DEL_VARIANT_SUCCESS]: (state, action) => {
      const { variantId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants.filter((x) => x.id !== variantId),
      };
    },

    [AT.ADD_VARIANT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
      uploadingStatus: 'Uploading...',
    }),
    [AT.ADD_VARIANT_ASSET_PROGRESS]: (state, action) => {
      const { event } = action.payload;
      return {
        ...state,
        isLoading: true,
        uploadingStatus: `Uploading... ${event.percent}%`,
      };
    },
    [AT.ADD_VARIANT_ASSET_SUCCESS]: (state, action) => {
      const { variantId, asset } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        uploadingStatus: 'Done',
        variants: state.variants.map((v) => (v.id === variantId ? { ...v, assets: [...v.assets, asset] } : v)),
      };
    },
    [AT.UPD_VARIANT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.UPD_VARIANT_ASSET_SUCCESS]: (state, action) => {
      const { asset } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants.map((v) => ({
          ...v,
          assets: [...v.assets.filter((a) => a.id !== asset.id), ...(v.id === asset.variant_id ? [asset] : [])],
        })),
      };
    },
    [AT.DEL_VARIANT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.DEL_VARIANT_ASSET_SUCCESS]: (state, action) => {
      const { variantId, assetId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants.map((v) =>
          v.id === variantId ? { ...v, assets: v.assets.filter((a) => a.id !== assetId) } : v
        ),
      };
    },
    [AT.UPD_INSIGHT_THUMBNAIL]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
    }),
    [AT.UPD_INSIGHT_THUMBNAIL_SUCCESS]: (state, action) => {
      const { insight } = action.payload;
      return {
        ...state,
        saveMessage: 'Saved Successfully!',
        isLoading: false,
        duringSave: false,
        insights: state.insights.map((x) =>
          x.id === insight.insight_id
            ? {
                ...x,
                thumbnail: insight.thumbnail,
                thumbnail_original: insight.thumbnail_original,
                thumbnail_orientation: insight.thumbnail_orientation,
              }
            : x
        ),
      };
    },

    [AT.ADD_VARIANT_RELEASE]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.ADD_VARIANT_RELEASE_SUCCESS]: (state, action) => {
      const { variant } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants.map((x) => (x.id === variant.id ? variant : x)),
      };
    },

    [AT.DEL_VARIANT_RELEASE]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.DEL_VARIANT_RELEASE_SUCCESS]: (state, action) => {
      const { variantId, releaseId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        variants: state.variants
          .map((x) => (x.id === variantId ? { ...x, releases: x.releases.filter((r) => r.id !== releaseId) } : x))
          .filter((x) => x.releases.length),
      };
    },
    [AT.INSIGHT_ASSETS_API_REQUEST_FAILED]: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    },
    [AT.GET_INSIGHT_ASSETS]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.GET_INSIGHT_ASSETS_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      assets: action.payload,
    }),
    [AT.DEL_INSIGHT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.DEL_INSIGHT_ASSET_SUCCESS]: (state, action) => {
      const { assetId } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        assets: state.assets.filter((x) => x.id !== assetId),
      };
    },
    [AT.UPD_INSIGHT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.UPD_INSIGHT_ASSET_SUCCESS]: (state, action) => {
      const { asset } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        assets: state.assets.map((x) => (x.id === asset.id ? asset : x)),
      };
    },
    [AT.ADD_INSIGHT_ASSET]: (state, action) => ({
      ...state,
      isLoading: true,
      uploadingStatus: 'Uploading...',
    }),
    [AT.ADD_INSIGHT_ASSET_PROGRESS]: (state, action) => {
      const { event } = action.payload;
      return {
        ...state,
        isLoading: true,
        uploadingStatus: `Uploading... ${event.percent}%`,
      };
    },
    [AT.ADD_INSIGHT_ASSET_SUCCESS]: (state, action) => {
      const { asset } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        uploadingStatus: 'Done',
        assets: [...state.assets, asset],
      };
    },
    [AT.REFRESH_INSIGHT_PRESIGNED_URL]: (state, action) => ({
      ...state,
    }),
    [AT.REFRESH_INSIGHT_PRESIGNED_URL_SUCCESS]: (state, action) => {
      const { postData, uploadedFileName, url } = action.payload;
      return {
        ...state,
        presignedUrlData: { postData, uploadedFileName, url },
      };
    },
    [AT.INSIGHT_CTA_API_REQUEST_FAILED]: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
        saveMessage: JSON.stringify(error.response.body),
      };
    },
    [AT.GET_INSIGHT_CTAS]: (state, action) => ({
      ...state,
      isLoading: true,
    }),
    [AT.GET_INSIGHT_CTAS_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      error: null,
      ctas: action.payload.ctas,
    }),
    [AT.ADD_INSIGHT_CTA]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.ADD_INSIGHT_CTA_SUCCESS]: (state, action) => {
      const { cta } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        saveMessage: 'Saved Successfully!',
        duringSave: false,
        ctas: [...state.ctas, cta],
      };
    },
    [AT.UPD_INSIGHT_CTA]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.UPD_INSIGHT_CTA_SUCCESS]: (state, action) => {
      const { cta } = action.payload;
      return {
        ...state,
        isLoading: false,
        duringSave: false,
        saveMessage: 'Saved Successfully!',
        error: null,
        ctas: state.ctas.map((x) => (x.id === cta.id ? cta : x)),
      };
    },
    [AT.DEL_INSIGHT_CTA]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.DEL_INSIGHT_CTA_SUCCESS]: (state, action) => {
      const { ctaId } = action.payload;
      return {
        ...state,
        isLoading: false,
        duringSave: false,
        saveMessage: 'Saved Successfully!',
        error: null,
        ctas: state.ctas.filter((x) => x.id !== ctaId),
      };
    },
    [AT.GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID]: (state, action) => ({
      ...state,
      root_node_id: null,
    }),
    [AT.GET_ROOT_NODE_ID_BY_INSIGHT_NODE_ID_SUCCESS]: (state, action) => ({
      ...state,
      root_node_id: action.payload.data.root_node_id,
    }),
    [AT.INSIGHT_TIMELINE_API_REQUEST_FAILED]: (state, action) => {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
        saveMessage: JSON.stringify(error.response.body),
      };
    },
    [AT.ADD_INSIGHT_TIMELINE]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.ADD_INSIGHT_TIMELINE_SUCCESS]: (state, action) => {
      const { insightId, timeline } = action.payload;
      return {
        ...state,
        isLoading: false,
        error: null,
        saveMessage: 'Saved Successfully!',
        duringSave: false,
        insights: state.insights.map((x) => (x.id === insightId ? { ...x, timeline: [...x.timeline, timeline] } : x)),
      };
    },
    [AT.UPD_INSIGHT_TIMELINE]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.UPD_INSIGHT_TIMELINE_SUCCESS]: (state, action) => {
      const { insightId, timeline } = action.payload;
      return {
        ...state,
        isLoading: false,
        duringSave: false,
        saveMessage: 'Saved Successfully!',
        error: null,
        insights: state.insights.map((x) =>
          x.id === insightId
            ? { ...x, timeline: x.timeline.map((y) => (y.id === timeline.id ? { ...y, ...timeline } : y)) }
            : x
        ),
      };
    },
    [AT.DEL_INSIGHT_TIMELINE]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Saving...',
    }),
    [AT.DEL_INSIGHT_TIMELINE_SUCCESS]: (state, action) => {
      const { insightId, timelineId } = action.payload;
      return {
        ...state,
        isLoading: false,
        duringSave: false,
        saveMessage: 'Saved Successfully!',
        error: null,
        insights: state.insights.map((x) =>
          x.id === insightId ? { ...x, timeline: x.timeline.filter((timeline) => timeline.id !== timelineId) } : x
        ),
      };
    },
    [AT.COPY_INSIGHT]: (state, action) => ({
      ...state,
      isLoading: true,
      duringSave: true,
      saveMessage: 'Copying...',
    }),
    [AT.COPY_INSIGHT_SUCCESS]: (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        isLoading: false,
        duringSave: false,
        saveMessage: 'Copied Successfully!',
        error: null,
        insights: [data, ...state.insights],
      };
    },
  },
  initialState
);

export default insightPageReudcer;
