/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Modal, Table, Checkbox, Select, Button, Popconfirm, Icon, InputNumber } from 'antd';

import { getOrgAppsMissingGroups } from '../../../redux/actions/userGroupsPage';

const { Option } = Select;

function EditUsersAppsModal({
  modalVisible,
  setIsVisible,
  allApps,
  orgsApps,
  orgsUsers,
  addAppsForUser,
  removeAppForUser,
  orgID,
  getOrgAppsMissingGroups,
  missingApps,
}) {
  const [selectedUser, selectedUserChange] = useState();
  const [missingUserApps, missingUserAppsChange] = useState(null);

  useEffect(() => {
    getOrgAppsMissingGroups(orgID);
  }, [orgID]);

  useEffect(() => {
    if (missingApps && missingApps[orgID]) {
      missingUserAppsChange(missingApps[orgID]);
    }
  }, [missingApps]);

  const getAppLayot = (appID) => {
    const app = allApps.find((a) => a.id === appID);
    return (
      <div>
        <img src={app.icon} width={20} height={20} style={{ marginRight: '1rem' }} alt="app icon" />
        <span style={{ marginRight: '1rem' }}>{app.name}</span>
        <span>({app.platform})</span>
      </div>
    );
  };

  return (
    <Modal
      title="Edit org's user apps"
      centered
      visible={modalVisible}
      width={750}
      onOk={() => {}}
      onCancel={() => {
        setIsVisible(false);
      }}
    >
      <div style={{ minHeight: '30rem' }}>
        {missingUserApps && missingUserApps.org_missing_all.length > 0 && (
          <div style={{ marginBottom: '2rem' }}>
            <h3>All missing apps from all org's users</h3>
            {missingUserApps && <div>{missingUserApps.org_missing_all.map((appID) => getAppLayot(appID))}</div>}
          </div>
        )}

        <div className="new-app-search">
          <Select value={selectedUser} onChange={(e) => selectedUserChange(e)} placeholder="Select user">
            {orgsUsers.map((user) => (
              <Option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name} - ${user.email}`}</Option>
            ))}
          </Select>
        </div>

        {selectedUser && missingUserApps && missingUserApps.users && missingUserApps.users[selectedUser] && (
          <div style={{ marginTop: '2rem' }}>
            <h3>Missing user's apps for Org</h3>
            <div>{missingUserApps.users[selectedUser].org_missing.map((appID) => getAppLayot(appID))}</div>
          </div>
        )}

        {selectedUser && missingUserApps && missingUserApps.users && missingUserApps.users[selectedUser] && (
          <div style={{ marginTop: '2rem' }}>
            <h3>Missing Org apps for user</h3>
            <div>{missingUserApps.users[selectedUser].user_missing.map((appID) => getAppLayot(appID))}</div>
          </div>
        )}

        {selectedUser && missingUserApps && missingUserApps.users && !missingUserApps.users[selectedUser] && (
          <h3>Apps for users the same as for Organization</h3>
        )}
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ userGroupsPage }) => ({
  missingApps: userGroupsPage.missingApps,
});

export default connect(mapStateToProps, {
  getOrgAppsMissingGroups,
})(EditUsersAppsModal);
