import React, { useState, useEffect } from 'react';
import { Button, Upload, message } from 'antd';
import { Auth } from 'aws-amplify';

export default ({ type, onLoad, presignedUrl }) => {
  const accept = `${type}/*`;
  const [fileList, fileListChange] = useState([]);

  const addAmzData = async (file) => {
    const currentAWSSession = await Auth.currentSession();
    const token = currentAWSSession.idToken.jwtToken;
    // eslint-disable-next-line no-undef
    const res = await fetch(`${API_HOST}/insight/presigned-post-url`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const q = await res.json();
    file.amzData = q.data;
    file.key = q.data.uploaded_file_name;
    file.percent = 0;
    file.status = 'uploading';
    fileListChange((fileList) => [...fileList, file]);

    const formData = new FormData();
    Object.keys(file.amzData.s3_post_data.fields).forEach((key) => {
      formData.append(key, file.amzData.s3_post_data.fields[key]);
    });
    formData.append('file', file);
    fetch(file.amzData.s3_post_data.url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        onLoad(type, file.amzData.uploaded_file_name, file.name);
        fileListChange((fileList) =>
          fileList.map((f) =>
            f.uid === file.uid
              ? {
                  ...file,
                  name: file.name,
                  percent: 100,
                  status: 'done',
                }
              : f
          )
        );
        setTimeout(() => {
          fileListChange((fileList) => fileList.filter((f) => f.uid !== file.uid));
        }, 1500);
      })
      .catch(() => {
        message.error('upload failed.');
      });
  };

  return (
    <div className="add_image">
      {presignedUrl ? (
        <Upload
          multiple
          listType="text"
          accept={accept}
          fileList={fileList}
          beforeUpload={(file) => {
            addAmzData(file);
            return false;
          }}
          className="main-uploader"
        >
          <Button icon="upload">Upload {type}</Button>
        </Upload>
      ) : (
        <div>refreshing presigned url...</div>
      )}
    </div>
  );
};
