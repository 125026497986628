import { ADD_PRODUCT_SETTING, GET_ABANDONED_INSIGHTS } from '../redux/actionTypes';

export const NETWORK_LABELS = {
  LOAD_APPS: 'LOAD_APPS',
  LOAD_INSIGHTS: 'LOAD_INSIGHTS',
  LOAD_LABELS: 'LOAD_LABELS',
  LOAD_APP_VERSIONS: 'LOAD_APP_VERSIONS',
  SAVE_INSIGHT: 'SAVE_INSIGHT',
  COPY_INSIGHT: 'COPY_INSIGHT',
  SET_INSIGHTS_IMPORTANCE: 'SET_INSIGHTS_IMPORTANCE',
  LOGIN: 'LOGIN',
  LOAD_EMAILS: 'LOAD_EMAILS',
  GET_APPS_LIST: 'GET_APPS_LIST',
  ADD_USER: 'ADD_USER',
  GET_USER: 'GET_USER',
  GET_ALL_USERS: 'GET_ALL_USERS',
  GET_ALL_USER_GROUPS: 'GET_ALL_USER_GROUPS',
  GET_ALL_USER_GROUP_PERMS: 'GET_ALL_USER_GROUP_PERMS',
  SET_USER_GROUPS: 'SET_USER_GROUPS',
  SET_PERM_USER_GROUPS: 'SET_PERM_USER_GROUPS',
  SET_DOMAIN_USER_GROUPS: 'SET_DOMAIN_USER_GROUPS',
  SET_LOC_USER_GROUPS: 'SET_LOC_USER_GROUPS',
  SET_APP_USER_GROUPS: 'SET_APP_USER_GROUPS',
  ADD_PRODUCT_TO_USER_GROUP: 'ADD_PRODUCT_TO_USER_GROUP',
  DEL_PRODUCT_FROM_USER_GROUP: 'DEL_PRODUCT_FROM_USER_GROUP',
  DELETE_USER: 'DELETE_USER',
  DELETE_USER_APP: 'DELETE_USER_APP',
  EDIT_USER_APPS: 'EDIT_USER_APPS',
  EDIT_USER_DETAILS: 'EDIT_USER_DETAILS',
  GET_USER_APPS: 'GET_USER_APPS',
  SET_USER_PASSWORD: 'SET_USER_PASSWORD',
  GET_RELEASES: 'GET_RELEASES',
  GET_RELEASE_REPORT: 'GET_RELEASE_REPORT',
  ADD_RELEASE: 'ADD_RELEASE',
  UPD_RELEASE: 'UPD_RELEASE',
  DEL_RELEASE: 'DEL_RELEASE',
  ADD_NEW_APPS: 'ADD_NEW_APPS',
  UPDATE_APP: 'UPDATE_APP',
  GET_APPS_DATA: 'GET_APPS_DATA',
  UPDATE_APPS: 'UPDATE_APPS',
  REFRESH_PRESIGNED_URL: 'REFRESH_PRESIGNED_URL',
  CREATE_INSIGHT: 'CREATE_INSIGHT',
  ADD_IMAGE: 'ADD_IMAGE',
  GET_EMAIL_PREVIEW: 'GET_EMAIL_PREVIEW',
  SEND_EMAIL: 'SEND_EMAIL',
  GET_CONFIGURATION: 'GET_CONFIGURATION',
  GET_RANKS: 'GET_RANKS',
  LOAD_PERF_APPS: 'LOAD_PERF_APPS',
  GET_ALL_VERIFICATION_FILTERS: 'GET_ALL_VERIFICATION_FILTERS',
  UPD_VERIFICATION_FILTER: 'UPD_VERIFICATION_FILTER',
  ADD_VERIFICATION_FILTER: 'ADD_VERIFICATION_FILTER',
  DEL_VERIFICATION_FILTER: 'DEL_VERIFICATION_FILTER',
  GET_AB_TESTS: 'GET_AB_TESTS',
  UPD_AB_TEST: 'UPD_AB_TEST',
  ADD_AB_TEST: 'ADD_AB_TEST',
  GET_AB_TEST_DATA: 'GET_AB_TEST_DATA',
  DELETE_AB_TEST: 'DELETE_AB_TEST',
  GET_ALL_PRODUCTS: 'GET_ALL_PRODUCTS',
  ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
  DEL_PRODUCT: 'DEL_PRODUCT',
  UPDATE_PRODUCT: 'UPDATE_PRODUCT',
  GET_DEVICES: 'GET_DEVICES',
  UPD_DEVICE: 'UPD_DEVICE',
  ADD_DEVICE: 'ADD_DEVICE',
  DEL_DEVICE: 'DEL_DEVICE',
  GET_DEVICE_MODELS: 'GET_DEVICE_MODELS',
  UPD_DEVICE_MODEL: 'UPD_DEVICE_MODEL',
  ADD_DEVICE_MODEL: 'ADD_DEVICE_MODEL',
  DEL_DEVICE_MODEL: 'DEL_DEVICE_MODEL',
  GET_PERSONAS: 'GET_PERSONAS',
  ADD_PERSONA: 'ADD_PERSONA',
  UPD_PERSONA: 'UPD_PERSONA',
  DEL_PERSONA: 'DEL_PERSONA',
  ADD_PERSONA_ORGANIZATION: 'ADD_PERSONA_ORGANIZATION',
  DEL_PERSONA_ORGANIZATION: 'DEL_PERSONA_ORGANIZATION',
  ADD_PRODUCT_SETTING: 'ADD_PRODUCT_SETTING',
  DEL_PERSONA_SEGMENT: 'DEL_PERSONA_SEGMENT',
  GET_SETTINGS: 'GET_SETTINGS',
  ADD_SETTING: 'ADD_SETTING',
  UPD_SETTING: 'UPD_SETTING',
  DEL_SETTING: 'DEL_SETTING',
  ADD_PERSONA_PRODUCT: 'ADD_PERSONA_PRODUCT',
  UPD_PERSONA_PRODUCT: 'UPD_PERSONA_PRODUCT',
  DEL_PERSONA_PRODUCT: 'DEL_PERSONA_PRODUCT',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  GET_ALL_NOTIFICATIONS: 'GET_ALL_NOTIFICATIONS',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  LINK_NOTIFICATION_TO_USER: 'LINK_NOTIFICATION_TO_USER',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  GET_TAGS: 'GET_TAGS',
  GET_REPORTS: 'GET_REPORTS',
  DEL_REPORT: 'DEL_REPORT',
  UPD_REPORT: 'UPD_REPORT',
  ADD_REPORT: 'ADD_REPORT',
  ADD_REPORT_TAG: 'ADD_REPORT_TAG',
  DEL_REPORT_TAG: 'DEL_REPORT_TAG',
  ADD_ORG_REPORT: 'ADD_ORG_REPORT',
  DEL_ORG_REPORT: 'DEL_ORG_REPORT',
  GET_VARIANTS: 'GET_VARIANTS',
  ADD_VARIANT: 'ADD_VARIANT',
  UPD_VARIANT: 'UPD_VARIANT',
  DEL_VARIANT: 'DEL_VARIANT',
  ADD_VARIANT_ASSET: 'ADD_VARIANT_ASSET',
  UPD_VARIANT_ASSET: 'UPD_VARIANT_ASSET',
  DEL_VARIANT_ASSET: 'DEL_VARIANT_ASSET',
  ADD_VARIANT_RELEASE: 'ADD_VARIANT_RELEASE',
  DEL_VARIANT_RELEASE: 'DEL_VARIANT_RELEASE',
  UPD_INSIGHT_THUMBNAIL: 'UPD_INSIGHT_THUMBNAIL',
  REFRESH_REPORT_PRESIGNED_URL: 'REFRESH_REPORT_PRESIGNED_URL',
  GET_INSIGHT_ASSETS: 'GET_INSIGHT_ASSETS',
  ADD_INSIGHT_ASSET: 'ADD_INSIGHT_ASSET',
  UPD_INSIGHT_ASSET: 'UPD_INSIGHT_ASSET',
  DEL_INSIGHT_ASSET: 'DEL_INSIGHT_ASSET',
  REFRESH_INSIGHT_PRESIGNED_URL: 'REFRESH_INSIGHT_PRESIGNED_URL',
  GET_POSSIBLE_EMAILS: 'GET_POSSIBLE_EMAILS',
  GET_INSIGHT_CTAS: 'GET_INSIGHT_CTAS',
  ADD_INSIGHT_CTA: 'ADD_INSIGHT_CTA',
  UPD_INSIGHT_CTA: 'UPD_INSIGHT_CTA',
  DEL_INSIGHT_CTA: 'DEL_INSIGHT_CTA',
  ADD_INSIGHT_TIMELINE: 'ADD_INSIGHT_TIMELINE',
  UPD_INSIGHT_TIMELINE: 'UPD_INSIGHT_TIMELINE',
  DEL_INSIGHT_TIMELINE: 'DEL_INSIGHT_TIMELINE',
  GET_ABANDONED_INSIGHTS: 'GET_ABANDONED_INSIGHTS',
  GET_ABANDONED_INSIGHTS_COUNT: 'GET_ABANDONED_INSIGHTS_COUNT',
  UPD_PAYMENT_DETAIL: 'UPD_PAYMENT_DETAIL',
  DEL_PAYMENT_DETAIL: 'DEL_PAYMENT_DETAIL',
  GET_USER_SUBSCRIPTION: 'GET_USER_SUBSCRIPTION',
  CREATE_SUBSCRIPTION: 'CREATE_SUBSCRIPTION',
  UPDATE_SUBSCRIPTION: 'UPDATE_SUBSCRIPTION',
  DELETE_SUBSCRIPTION: 'DELETE_SUBSCRIPTION',
  GET_PRODUCTS_FOR_USER: 'GET_PRODUCTS_FOR_USER',
  GET_USER_DETAILS: 'GET_USER_DETAILS',
  UPDATE_EMAIL_PREFERENCES: 'UPDATE_EMAIL_PREFERENCES',
  CREATE_SCREEN_CHANGE_INSIGHT: 'CREATE_SCREEN_CHANGE_INSIGHT',
  GET_ORCHESTRATORS_LIST: 'GET_ORCHESTRATORS_LIST',
  ADD_NEW_ORCHESTRATOR: 'ADD_NEW_ORCHESTRATOR',
  UPDATE_ORCHESTRATOR: 'UPDATE_ORCHESTRATOR',
  DELETE_ORCHESTRATOR: 'DELETE_ORCHESTRATOR',
  GET_INSIGHT_PERSONAS: 'GET_INSIGHT_PERSONAS',
  GET_CUR_USER_DETAILS: 'GET_CUR_USER_DETAILS',
};
