import React, { useEffect, useState } from 'react';
import { Button, Modal, Checkbox, Input, Select, Tooltip, Radio } from 'antd';
import { isEqual } from 'lodash';
import { L_BENCHMARK, L_MARKETING, MATCH_TYPES } from '../../common/user_helper';
import './subscriptions.scss';
import MarketingIcon from '../../../assets/icons/Marketing-non-selected.svg';
import IntelligenceIcon from '../../../assets/icons/Intelligence-non-selected.svg';

const CreateNewSubscriptionModal = ({
  subscription,
  modalShow,
  closeWindow,
  createSubscription,
  editSubscription,
  products,
  tagsList,
  licences,
}) => {
  const ftags = subscription.labels ? [...subscription.labels] : [];

  if (subscription && subscription.critical_insight) {
    ftags.unshift({ id: '-1', name: 'critical insight' });
  }

  const [errors, errorsChange] = useState([]);
  const [filterTags, filterTagsChange] = useState(subscription ? ftags : []);
  const [currentProducts, currentProductsChange] = useState(subscription ? subscription.products : []);
  const [subscrName, subscrNameChange] = useState(subscription ? subscription.name : '');
  const [currentLicences, currentLicencesChange] = useState(
    Object.keys(licences).filter((l) => l !== L_BENCHMARK && licences[l])
  );
  // eslint-disable-next-line no-nested-ternary
  const [filterTagsMatch, filterTagsMatchChange] = useState(
    subscription ? (subscription.tag_filter === 'any' ? MATCH_TYPES[0] : MATCH_TYPES[1]) : MATCH_TYPES[0]
  );

  useEffect(() => {
    if (subscription) {
      const ftags = subscription.labels ? [...subscription.labels] : [];

      if (subscription && subscription.critical_insight) {
        ftags.unshift({ id: '-1', name: 'critical insight' });
      }
      filterTagsChange(ftags);
      currentProductsChange(subscription.products);
      subscrNameChange(subscription.name);
      filterTagsMatchChange(subscription.tag_filter === 'any' ? MATCH_TYPES[0] : MATCH_TYPES[1]);
      currentLicencesChange(
        Object.keys(licences).filter((l) => l !== L_BENCHMARK && subscription && licences[l] && subscription[l])
      );
    }
  }, [subscription, licences]);

  const createSubscriptionHandle = () => {
    const data = {
      name: subscrName,
      intelligence: currentLicences.includes('intelligence'),
      marketing: currentLicences.includes('marketing'),
      frequency: 'weekly',
      tag_filter: filterTagsMatch === MATCH_TYPES[0] ? 'any' : 'all',
      product_ids: currentProducts.map((p) => p.id),
      label_ids: filterTags.map((l) => l.id),
      email: true,
    };
    const errs = validationData([]);

    if (errs.length === 0) {
      createSubscription(data);
      closeWindow(false);
    } else {
      errorsChange(errs);
      setTimeout(() => errorsChange([]), 3000);
    }
  };
  const redStar = (text) => (
    <div className="red-star-wrapper">
      <div>{text}</div>
      <div className="star">*</div>
    </div>
  );

  const editSubscriptionHandle = () => {
    const data = {
      name: subscrName,
      intelligence: currentLicences.includes('intelligence'),
      marketing: currentLicences.includes('marketing'),
      frequency: subscription ? subscription.frequency : 'weekly',
      tag_filter: filterTagsMatch === MATCH_TYPES[0] ? 'any' : 'all',
      product_ids: currentProducts.map((p) => p.id),
      label_ids: filterTags.map((l) => l.id),
      email: true,
    };
    const errs = validationData([]);
    if (errs.length === 0) {
      if (subscription) {
        editSubscription(data);
      }
      closeWindow(false);
    } else {
      errorsChange(errs);
      setTimeout(() => errorsChange([]), 3000);
    }
  };
  const validationData = (errs) => {
    if (subscrName === '') {
      errs.push('Subscription name is required field');
    }
    if (currentLicences.length === 0) {
      errs.push('At least 1 solution must be chosen');
    }
    return errs;
  };
  const handleCheckboxChange = (e) => {
    const val = e.target.value;
    const copyCurrentLicences = [...currentLicences];

    if (currentLicences.includes(val)) {
      currentLicencesChange(copyCurrentLicences.filter((l) => l !== val));
    } else {
      copyCurrentLicences.push(val);
      currentLicencesChange(copyCurrentLicences);
    }
  };

  const handleProductChange = (values) => {
    const chosenProducts = values.map((val) => products.find((p) => p.name === val));
    currentProductsChange(chosenProducts);
  };

  return (
    <Modal
      title={subscription && subscription.name ? `Edit Subscription` : `Create Subscription`}
      visible={modalShow}
      onCancel={() => closeWindow(false)}
      wrapClassName="modal-subscription"
      key="modal-add-subscription"
      maskClosable={false}
      footer={[
        <Button key="cancel" type="default" onClick={() => closeWindow(false)}>
          Cancel
        </Button>,
        subscription && subscription.name ? (
          <Button key="submit" type="primary" onClick={editSubscriptionHandle}>
            Save
          </Button>
        ) : (
          <Button key="submit" type="primary" onClick={createSubscriptionHandle}>
            Create
          </Button>
        ),
      ]}
    >
      <div className="add-subs-descr">
        Create a Subscription to a specific insight topic and get notified when a new insight with the same topic is
        published.
      </div>

      <div className="add-subs-title">{redStar('SUBSCRIPTION NAME')}</div>
      <Input value={subscrName} onChange={(e) => subscrNameChange(e.target.value)} placeholder="Subscription Name" />
      <div className="add-subs-title">
        <span className="text">SUBSCRIPTION SPECIFICATIONS</span>
      </div>
      <div className="add-subs-specs">
        <Radio.Group value={filterTagsMatch} onChange={(e) => filterTagsMatchChange(e.target.value)}>
          {MATCH_TYPES.map((type) => (
            <Tooltip title={type === MATCH_TYPES[0] ? 'Ex: Tag1 or Tag2 or...' : 'Ex: Tag1 and Tag2 and...'}>
              <Radio value={type}>{type}</Radio>
            </Tooltip>
          ))}
        </Radio.Group>
        <div className="specs-wrapper">
          Tags:
          <Select
            style={{ width: 200 }}
            mode="multiple"
            showSearch
            value={filterTags.map((tag) => tag.name)}
            onChange={(selected) => {
              const newTags = selected
                .filter((selTag) => tagsList.find((tag) => tag.name.toLowerCase() === selTag.toLowerCase()))
                .map((selTag) => {
                  const thisTag = tagsList.find((tag) => tag.name.toLowerCase() === selTag.toLowerCase());
                  return thisTag;
                });
              if (!isEqual(newTags, filterTags)) {
                filterTagsChange(newTags);
              }
            }}
          >
            {tagsList.map((tag) => (
              <Select.Option key={tag.name} value={tag.name}>
                {tag.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="spec-product">
          {redStar('Products')}
          <Select
            style={{ width: 200 }}
            onChange={handleProductChange}
            mode="multiple"
            showSearch
            value={currentProducts.map((p) => p.name)}
          >
            {products.map((product) => (
              <Select.Option key={product.name} value={product.name}>
                <img
                  src={product.thumbnail}
                  style={{ width: '20px', height: '20px', marginRight: '2px' }}
                  alt="product thumbnail"
                />{' '}
                {product.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="spec-licence">
          Solutions
          <div className="licences-wrapper">
            {Object.keys(licences)
              .filter((l) => l !== L_BENCHMARK)
              .map((l, i) => (
                <div className="license" key={i}>
                  <Checkbox
                    checked={currentLicences.includes(l)}
                    value={l}
                    key={i}
                    onChange={handleCheckboxChange}
                    disabled={!licences[l]}
                  >
                    <img src={l === L_MARKETING ? MarketingIcon : IntelligenceIcon} alt="MarketingIcon" />
                  </Checkbox>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="mandatory-wrapper">
        <span className="star">*</span>
        <span className="text">Mandatory fields</span>
      </div>

      <div className="subscription-errors">
        {errors.map((er) => (
          <div key={er.message} className="subscription-error">
            {er}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default CreateNewSubscriptionModal;
