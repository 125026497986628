import { datadogRum } from '@datadog/browser-rum';

const envCheck = process.env.NODE_ENV === 'production' && process.env.REACT_APP_VERSION;
if (envCheck) {
  console.log(`running datadog in production mode with version ${process.env.REACT_APP_VERSION}`);
  datadogRum.init({
    applicationId: '858c9f89-7efb-4991-b936-0e937f58af80',
    clientToken: 'pub87e0c0b08c81d9741178a8328570eda6',
    site: 'datadoghq.com',
    service: 'mananger.watchful.ai',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
} else {
  console.log('NODE_ENV', process.env.NODE_ENV);
  console.log('REACT_APP_VERSION', process.env.REACT_APP_VERSION);
}
