import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon } from 'antd';

const ReactWSPlayer = ({ streamURL, farmerDevice, sendDataToDeviceOnFramerHandle }) => {
  const [error, errorChange] = useState('');
  const [magicIsEnabled, magicIsEnabledChange] = useState(true);

  let ws, lastPacket;

  const video_ref = useRef(null);

  window.magicIsEnabledChange = magicIsEnabledChange;

  let downEvXY, downEvXYTs;
  const mouseDown = (ev) => {
    downEvXY = {
      x: ev.nativeEvent.offsetX,
      y: ev.nativeEvent.offsetY,
    };
    downEvXYTs = new Date();
  };

  const restart = () => {
    if (ws) ws.close();
  };

  const mouseUp = (upEv) => {
    if (!downEvXY) return;
    let multX = farmerDevice.screen_res[0] / video_ref.current.clientWidth,
      multY = farmerDevice.screen_res[1] / video_ref.current.clientHeight;

    const args = [
      downEvXY.x * multX,
      downEvXY.y * multY,
      upEv.nativeEvent.offsetX * multX,
      upEv.nativeEvent.offsetY * multY,
      parseInt(new Date() - downEvXYTs),
    ];
    downEvXY = null;
    console.log(args);
    sendDataToDeviceOnFramerHandle('swipe', args);
  };

  useEffect(() => {
    const mediaSource = new MediaSource();
    video_ref.current.src = window.URL.createObjectURL(mediaSource);
    let wasWaitingForData = false;

    let buffer = null;
    const queue = [];

    function concatArrayBuffers(bufs) {
      let offset = 0;
      let bytes = 0;
      const bufs2 = bufs.map((buf, total) => {
        bytes += buf.byteLength;
        return buf;
      });
      const buffer = new ArrayBuffer(bytes);
      const store = new Uint8Array(buffer);
      bufs2.forEach((buf) => {
        store.set(new Uint8Array(buf.buffer || buf, buf.byteOffset), offset);
        offset += buf.byteLength;
      });
      return buffer;
    }

    video_ref.current.onerror = function () {
      console.log(`Error ${video_ref.current.error.code}; details: ${video_ref.current.error.message}`);
      errorChange(video_ref.current.error);
      restart();
    };

    video_ref.current.onwaiting = (event) => {
      console.log('Video is waiting for more data.');
      wasWaitingForData = true;
    };

    video_ref.current.onpause = (event) => {
      console.log('Video is paused.');
      // debugger
    };

    video_ref.current.onprogress = (event) => {
      // console.log('video progressing. End of buffer is at:', video_ref.current.buffered.end(video_ref.current.buffered.length - 1));
      if (wasWaitingForData && video_ref.current && video_ref.current.buffered.length > 0 && magicIsEnabled) {
        const potentialJump = Math.max(video_ref.current.buffered.end(video_ref.current.buffered.length - 1) - 0.2, 0);
        if (video_ref.current.currentTime - 0.1 < potentialJump) {
          video_ref.current.currentTime = potentialJump;
        }
        wasWaitingForData = false;
      }
    };

    video_ref.current.onstalled = (event) => {
      console.log('Failed to fetch data, but trying.');
    };

    function checkRestartNeeded() {
      return;
      if (video_ref.current && lastPacket && lastPacket < new Date() - 3000) {
        restart();
        console.log('----------FORCE RECONNECT SOCKET----------');
      }
      setTimeout(checkRestartNeeded, 1000);
    }

    function sourceBufferHandle() {
      // console.log(farmerDevice.video_stream_mime)
      buffer = mediaSource.addSourceBuffer(farmerDevice.video_stream_mime);
      buffer.mode = 'sequence';

      buffer.addEventListener('updateend', () => {
        // TEMP
        if (!video_ref.current) {
          return;
        }
        const isPlaying =
          video_ref.current.currentTime > 0 &&
          !video_ref.current.paused &&
          !video_ref.current.ended &&
          video_ref.current.readyState > video_ref.current.HAVE_CURRENT_DATA;

        if (!isPlaying) {
          video_ref.current.play();
        }
      });

      initWS();
    }

    mediaSource.addEventListener('sourceopen', sourceBufferHandle);

    function initWS() {
      ws = new WebSocket(streamURL, 'echo-protocol');

      ws.binaryType = 'arraybuffer';

      ws.onopen = () => {
        console.log('-----------------SOCKET OPEN--------------');
      };

      ws.onmessage = (event) => {
        lastPacket = new Date();
        if (typeof event.data === 'object') {
          if (!buffer) {
            sourceBufferHandle();
          }
          if (buffer && !buffer.updating) {
            if (queue.length > 0) {
              queue.push(event.data);
              buffer.appendBuffer(concatArrayBuffers(queue));
              queue.length = 0;
            } else {
              buffer.appendBuffer(event.data);
            }
          } else {
            queue.push(event.data);
          }
        }
      };
      ws.onclose = () => {
        console.log('-----------------SOCKET CLOSED--------------');
        initWS();
      };
    }
    checkRestartNeeded();
  }, [streamURL]);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }
  return (
    <div className="ws-video-wpapper">
      <video id="video" ref={video_ref} onMouseDown={mouseDown} onMouseUp={mouseUp} />
    </div>
  );
};

export default ReactWSPlayer;
