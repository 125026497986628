import React, { useEffect, useState } from 'react';
import { message, Select } from 'antd';
import { useMutation, useQuery } from 'react-query';
import {
  getInsightRelationsSuggestions,
  getInsightRelations,
  addInsightRelation,
  delInsightRelation,
} from '../../../api/insightsAPI';
import { ABBR_MAP_TYPE } from '../../../constants/insightType';

const { Option } = Select;

export default ({ insightId }) => {
  const insightRelationsQuery = useQuery(['insightRelations', insightId], () => getInsightRelations(insightId));
  const [insightRelations, setInsightRelations] = useState([]);

  const insightRelationsSuggestionsQuery = useQuery(['insightRelationsSuggestions', insightId], () =>
    getInsightRelationsSuggestions(insightId)
  );

  useEffect(() => {
    if (insightRelationsQuery.isFetched) {
      setInsightRelations(insightRelationsQuery.data.data);
    }
  }, [insightId, insightRelationsQuery.status]);
  const addInsightRelationMutation = useMutation(addInsightRelation, {
    onSuccess: (data) => {
      setInsightRelations([...insightRelations, data.data.relation]);
      message.destroy();
      message.success('Related insight was added');
    },
  });
  const delInsightRelationMutation = useMutation(delInsightRelation, {
    onSuccess: (data, variables) => {
      setInsightRelations(insightRelations.filter((x) => x.id !== variables.relatedInsightId));
      message.destroy();
      message.success('Related insight was deleted');
    },
  });
  const handleUpdRelations = (relationIds) => {
    insightRelations
      .filter((x) => !relationIds.includes(x.id))
      .forEach((x) =>
        delInsightRelationMutation.mutateAsync({
          insightId,
          relatedInsightId: x.id,
        })
      );
    relationIds
      .filter((y) => !insightRelations.map((x) => x.id).includes(y))
      .forEach((relatedInsightId) =>
        addInsightRelationMutation.mutateAsync({
          insightId,
          relatedInsightId,
        })
      );
  };
  const isLoading = [
    insightRelationsQuery,
    insightRelationsSuggestionsQuery,
    addInsightRelationMutation,
    delInsightRelationMutation,
  ].some((x) => x.isLoading);
  const errors = [
    insightRelationsQuery,
    insightRelationsSuggestionsQuery,
    addInsightRelationMutation,
    delInsightRelationMutation,
  ]
    .filter((x) => x.isError)
    .map((x) => x.error.message);

  return (
    <React.Fragment>
      {errors.length ? <b style={{ color: 'red' }}>{JSON.stringify(errors)}</b> : null}
      <Select
        mode="multiple"
        placeholder="Related Insights"
        style={{ width: '100%' }}
        onChange={(value) => handleUpdRelations(value)}
        filterOption={(input, option) => option.props.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={insightRelations.map((x) => x.id)}
        loading={isLoading}
        disabled={isLoading}
        getPopupContainer={(trNode) => trNode}
      >
        {(insightRelationsSuggestionsQuery.isFetched ? insightRelationsSuggestionsQuery.data.data : []).map(
          (insight) => (
            <Option value={insight.id} key={insight.id}>
              #{insight.id} [{ABBR_MAP_TYPE[insight.type]}] {insight.title} - {insight.platform}
            </Option>
          )
        )}
      </Select>
    </React.Fragment>
  );
};
