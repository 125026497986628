export const authTypes = ['basic_auth', 'phone_number', 'identity_provider'];
export const tariffPlans = ['regular', 'freemium', 'premium', 'enterprise'];
export const paymentMethods = ['none', 'credit_card', 'paypal'];

export const getPaymentDetails = (personaProduct) => {
  let paymentData;
  if (personaProduct.payment_method === paymentMethods[1]) {
    // credit card
    paymentData = {
      payment_method: personaProduct.payment_method,
      credit_card_owner: personaProduct.credit_card_owner,
      credit_card_number: personaProduct.credit_card_number,
      expiration_month: personaProduct.expiration_month.format('MM-YY'),
      paypal_email: '',
    };
  } else {
    // paypal
    paymentData = {
      payment_method: personaProduct.payment_method,
      credit_card_owner: '',
      credit_card_number: '',
      expiration_month: '',
      paypal_email: personaProduct.paypal_email,
    };
  }

  return paymentData;
};
