import React from 'react';
import moment from 'moment';
import { Modal, Form, Input, Checkbox, Select, DatePicker, Col, Row, Upload, Button, message } from 'antd';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { countries } from '../../../constants/countries';
import { GENDER } from '../Personas';
import './personas.scss';
import { ORG_WATCHFUL_ID } from '../../../constants/orgs';

const dateFormat = 'YYYY-MM-DD';
const scopes = ['product', 'marketing', 'both'];
const UPLOAD_FILE_MAX_SIZE_MB = 3;

class PersonaForm extends React.Component {
  render() {
    const { form, persona, orgs } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Form>
        <Form.Item label="Name">
          {getFieldDecorator('title', {
            rules: [
              {
                required: true,
                max: 100,
              },
            ],
            initialValue: persona && persona.title,
          })(<Input placeholder="Name" />)}
        </Form.Item>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item label="Birth date">
              {getFieldDecorator('birth_date', {
                rules: [{ required: false }],
                initialValue: persona && persona.birth_date ? moment(persona.birth_date) : moment(),
              })(<DatePicker placeholder="Birth date" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Gender">
              {getFieldDecorator('gender', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: persona && persona.gender,
              })(
                <Select style={{ width: '100%' }} placeholder="Gender" optionFilterProp="children">
                  {GENDER.map((gender) => (
                    <Select.Option key={gender} value={gender} style={{ textTransform: 'capitalize' }}>
                      <div style={{ textTransform: 'capitalize' }}>{gender}</div>
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                rules: [
                  {
                    required: true,
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                ],
                initialValue: persona && persona.email,
              })(<Input placeholder="Persona email" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Email Password">
              {getFieldDecorator('email_password', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: persona && persona.email_password,
              })(<Input placeholder="Persona email's password" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <Form.Item label="phone">
              {getFieldDecorator('phone', {
                rules: [{ max: 100 }],
                initialValue: persona ? persona.phone : '',
              })(<PhoneInput placeholder="phone" className="phone-input" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Picture">
              {getFieldDecorator('picture', {
                rules: [
                  { required: !persona },
                  {
                    validator: (_, value, cb) => {
                      if (!value || !value.file) {
                        return cb();
                      }

                      const fileSize = value.file.size / 1024 / 1024;

                      if (fileSize > UPLOAD_FILE_MAX_SIZE_MB) {
                        return cb(`Image must be smaller than ${UPLOAD_FILE_MAX_SIZE_MB}MB`);
                      }

                      return cb();
                    },
                  },
                ],
              })(
                <Upload
                  name="picture"
                  multiple={false}
                  listType="text"
                  accept="image/png,image/jpeg,image/jpg"
                  beforeUpload={() => false}
                >
                  <Button icon="upload">Upload</Button>
                </Upload>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Status">
              {getFieldDecorator('status', {
                rules: [
                  {
                    required: true,
                    max: 100,
                  },
                ],
                initialValue: persona && persona.status,
              })(<Input placeholder="Status" />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Location">
              {getFieldDecorator('location', {
                rules: [
                  {
                    required: true,
                    max: 2,
                  },
                ],
                initialValue: persona && persona.location,
              })(
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Location"
                  optionFilterProp="children"
                  onChange={this.handleChangeCountries}
                  filterOption={(input, option) =>
                    option.props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countries.map((country) => (
                    <Select.Option key={country.code} value={country.code}>
                      <span
                        className={`flag-icon flag-icon-${country.code.toLowerCase()}`}
                        style={{
                          marginRight: '1rem',
                        }}
                      />
                      {country.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <Form.Item label="Geo Address">
              {getFieldDecorator('geo_address', {
                rules: [{ max: 300 }],
                initialValue: persona ? persona.geo_address : '',
              })(<Input placeholder="Geo Address" country="US" />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Geo Billing Address">
              {getFieldDecorator('geo_billing_address', {
                rules: [{ max: 200 }],
                initialValue: persona ? persona.geo_billing_address : '',
              })(<Input placeholder="Geo Billing Address" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [{ max: 1000 }],
                initialValue: persona ? persona.description : '',
              })(<Input.TextArea placeholder="Description" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            {getFieldDecorator('org_ids', {
              rules: [{ required: true }],
              initialValue: persona ? persona.organizations.map((x) => x.id) : [ORG_WATCHFUL_ID],
            })(
              <Select
                style={{ width: '100%' }}
                placeholder="Organizations"
                mode="multiple"
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
              >
                {orgs.map((x) => (
                  <Select.Option key={x.id} value={x.id}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('active', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: persona ? persona.active : true,
              })(<Checkbox>Active</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('is_default', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: persona ? persona.is_default : false,
              })(<Checkbox>Is Default</Checkbox>)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Scope">
              {getFieldDecorator('scope', {
                rules: [
                  {
                    required: true,
                  },
                ],
                initialValue: persona && persona.scope,
              })(
                <Select style={{ width: '100%' }} placeholder="Scope" optionFilterProp="children">
                  {scopes.map((scope) => (
                    <Select.Option key={scope} value={scope}>
                      {scope}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(PersonaForm);

export default ({ persona, personas, orgs, handleHide, addSubmit, updSubmit }) => {
  let formRef = null;
  const submitHandle = (persona, copyValues) => {
    if (persona) {
      updSubmit(persona.id, copyValues);
    } else {
      addSubmit(copyValues);
    }
    handleHide();
  };

  return (
    <Modal
      title={persona ? `Change persona #${persona.id}` : 'New persona'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (!persona && personas.some((p) => p.email === values.email)) {
              message.error(`${values.email} already in use by at least one other persona`, 5);
              return;
            }
            let copyValues = {
              ...values,
              birth_date: values.birth_date && values.birth_date.format(dateFormat),
            };
            const reader = new FileReader();
            if (values.picture) {
              reader.readAsDataURL(values.picture.fileList[0].originFileObj);
              reader.onload = () => {
                copyValues = { ...copyValues, picture_data: reader.result.split('base64,')[1] };
                submitHandle(persona, copyValues);
              };
            } else {
              submitHandle(persona, copyValues);
            }
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        persona={persona}
        orgs={orgs}
      />
    </Modal>
  );
};
