/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-did-mount-set-state */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { isEmpty, isEqual } from 'lodash';
import 'antd/dist/antd.css';
import { Table, Input, Popconfirm, Form, Button, Divider, Icon, Checkbox } from 'antd';
import Highlighter from 'react-highlight-words';
import moment from 'moment';

const l = (...args) => console.log('editableUserTable', ...args);

const EditableContext = React.createContext();

class EditableCell extends React.Component {
  getInput = () => {
    if (this.props.inputType === 'checkbox') {
      return <Checkbox>{this.props.title}</Checkbox>;
    }
    return <Input />;
  };

  renderCell = ({ getFieldDecorator }) => {
    const { editing, dataIndex, title, inputType, record, index, children, required, ...restProps } = this.props;
    return (
      <td {...restProps}>
        {editing ? (
          inputType === 'checkbox' ? (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                valuePropName: 'checked',
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          ) : (
            <Form.Item style={{ margin: 0 }}>
              {getFieldDecorator(dataIndex, {
                rules: [
                  {
                    required: required !== false,
                    message: `Please Input ${title}!`,
                  },
                ],
                initialValue: record[dataIndex],
              })(this.getInput())}
            </Form.Item>
          )
        ) : (
          children
        )}
      </td>
    );
  };

  render() {
    return <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>;
  }
}

class EditableTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      editingKey: '',
      filteredInfo: null,
      searchQuery: '',
    };
  }

  getColumnSearchProps = (dataIndex1, dataIndex2) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex1}/${dataIndex2}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      const firstDataIndexMatch = record[dataIndex1]
        ? record[dataIndex1].toString().toLowerCase().includes(value.toLowerCase())
        : false;
      const secondDataIndexMatch = record[dataIndex2].toString().toLowerCase().includes(value.toLowerCase());
      if (firstDataIndexMatch || secondDataIndexMatch) {
        return true;
      }
      return false;
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchQuery]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ),
  });

  componentDidMount() {
    if (!isEmpty(this.props.allUsers)) {
      this.setState({
        data: this.props.allUsers.map((user) => ({
          key: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          organization_id: user.organization_id,
          role: user.role,
          department: user.department,
          phone: user.phone,
          created: user.created,
          admin: user.admin,
          annotator: user.annotator,
          rawData: user.raw_data,
          reviewer: user.reviewer,
          annotator_admin: user.annotator_admin,
          is_disabled: user.is_disabled,
          is_org_admin: user.is_org_admin,
        })),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.allUsers, prevProps.allUsers)) {
      this.setState({
        data: this.props.allUsers.map((user) => ({
          key: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          organization_id: user.organization_id,
          role: user.role,
          department: user.department,
          phone: user.phone,
          created: user.created,
          admin: user.admin,
          annotator: user.annotator,
          rawData: user.raw_data,
          reviewer: user.reviewer,
          annotator_admin: user.annotator_admin,
          is_disabled: user.is_disabled,
          is_org_admin: user.is_org_admin,
        })),
      });
    }
  }

  isEditing = (record) => record.key === this.state.editingKey;

  cancel = () => {
    this.setState({ editingKey: '' });
  };

  handleDelete = (id) => {
    const data = [...this.state.data];
    this.props.deleteUser(id);
    this.setState({ data: data.filter((item) => item.key !== id) });
  };

  handleAdd = () => {};

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchQuery: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchQuery: '' });
  };

  save(form, key, record) {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const ud = {
        first_name: row.first_name,
        last_name: row.last_name,
        oldPassword: '',
        newPassword: '',
        email: record.email,
        organization_id: record.organization_id,
        role: row.role,
        department: row.department,
        phone: record.phone,
        created: record.created,
        id: key,
        admin: row.admin,
        annotator: row.annotator,
        rawData: row.rawData,
        reviewer: row.reviewer,
        annotator_admin: row.annotator_admin,
        isAdminClicked: false,
        is_disabled: row.is_disabled,
        is_org_admin: row.is_org_admin,
      };
      l('invoking saveUserData', ud);
      this.props.saveUserData(ud);

      const newData = [...this.state.data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        this.setState({ data: newData, editingKey: '' });
      } else {
        newData.push(row);
        this.setState({ data: newData, editingKey: '' });
      }
    });
  }

  edit(key) {
    this.setState({ editingKey: key });
  }

  editApps(key) {
    this.props.editUserApps(key);
  }
  editSubscriptions(key) {
    this.props.editUserSubscriptions(key);
  }

  handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
    });
  };

  render() {
    let { filteredInfo } = this.state;
    const { data } = this.state;
    filteredInfo = filteredInfo || {};

    if (!data) {
      return null;
    }

    const components = {
      body: {
        cell: EditableCell,
      },
    };

    const userEmails = [
      ...new Set(
        data.map((user) => {
          const userEmailParts = user.email.split('@');
          return `@${userEmailParts[1]}`;
        })
      ),
    ];

    const rawColumns = [
      {
        title: 'ID',
        dataIndex: 'key',
        width: '5%',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.key - b.key,
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        width: '8%',
        editable: true,
        ...this.getColumnSearchProps('first_name', 'email'),
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        width: '8%',
        editable: true,
        ...this.getColumnSearchProps('last_name', 'email'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        width: '10%',
        editable: false,
        filters: userEmails.map((email) => ({ text: email, value: email })),
        filteredValue: filteredInfo.email || null,
        onFilter: (value, record) => record.email.includes(value),
        render: (text) => (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchQuery]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ),
      },
      {
        title: 'Org',
        dataIndex: 'organization_id',
        width: '7%',
        editable: false,
        render: (org_id) => (this.props.orgs.find((x) => x.id === org_id) || { name: org_id }).name,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        width: '7%',
        editable: true,
        required: false,
      },
      {
        title: 'Department',
        dataIndex: 'department',
        width: '7%',
        editable: true,
        required: false,
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        width: '7%',
        editable: false,
      },
      {
        title: 'Created',
        dataIndex: 'created',
        editable: false,
        render: (value) => (
          <React.Fragment>
            {value && (
              <div>
                <div style={{ whiteSpace: 'nowrap' }}>{moment(value).format('YYYY-MM-DD')}</div>
                <div style={{ whiteSpace: 'nowrap' }}>{moment(value).format('HH:mm:ss')}</div>
              </div>
            )}
          </React.Fragment>
        ),
      },
      {
        title: 'Is Disabled?',
        dataIndex: 'is_disabled',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.is_disabled}>
              Is Disabled?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Is Admin?',
        dataIndex: 'admin',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.admin}>
              Is Admin?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Raw Data Visible?',
        dataIndex: 'rawData',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.rawData}>
              Raw Data Visible?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Is Annotator?',
        dataIndex: 'annotator',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.annotator}>
              Is Annotator?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Is Annotator Admin?',
        dataIndex: 'annotator_admin',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.annotator_admin}>
              Is Annotator Admin?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Is Reviewer?',
        dataIndex: 'reviewer',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <span>
            <Checkbox disabled checked={record.reviewer}>
              Is Reviewer?
            </Checkbox>
          </span>
        ),
      },
      {
        title: 'Is Org Admin',
        dataIndex: 'is_org_admin',
        type: 'checkbox',
        editable: true,
        render: (text, record) => (
          <div title="Org admin can receive user creation requests via email">
            <Checkbox disabled checked={record.is_org_admin}>
              Is Org Admin?
            </Checkbox>
          </div>
        ),
      },
      {
        title: 'Operations',
        dataIndex: 'operation',
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          return editable ? (
            <span>
              <EditableContext.Consumer>
                {(form) => (
                  <a onClick={() => this.save(form, record.key, record)} style={{ marginRight: 8 }}>
                    Save
                  </a>
                )}
              </EditableContext.Consumer>
              <Popconfirm title="Sure to cancel?" onConfirm={() => this.cancel(record.key)}>
                <a>Cancel</a>
              </Popconfirm>
              <div style={{ marginTop: '5px' }}>
                <Popconfirm
                  disabled={editingKey === ''}
                  title="Are you sure? By deleting a user there is a risk of losing all related data (e.g. comments)"
                  icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                  onConfirm={() => {
                    this.handleDelete(record.key);
                    this.setState({ editingKey: '' });
                  }}
                >
                  <Button size="small" type="danger" icon="delete" disabled={editingKey === ''}>
                    Delete 💀
                  </Button>
                </Popconfirm>
              </div>
            </span>
          ) : (
            <span>
              <Button type="default" icon="edit" disabled={editingKey !== ''} onClick={() => this.edit(record.key)}>
                Edit
              </Button>
              <Divider type="vertical" />
              <Button
                type="default"
                icon="edit"
                disabled={editingKey !== ''}
                onClick={() => this.props.changePassword(record.key)}
              >
                Password
              </Button>
              <Divider type="vertical" />
              <Button
                type="default"
                icon="unordered-list"
                disabled={editingKey !== ''}
                onClick={() => this.editApps(record.key)}
              >
                Apps
              </Button>
              <Divider type="vertical" />
              <Button
                type="default"
                icon="unordered-list"
                disabled={editingKey !== ''}
                onClick={() => this.editSubscriptions(record.key)}
              >
                Subscriptions
              </Button>
            </span>
          );
        },
      },
    ];

    const columns = rawColumns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.type === 'checkbox' ? 'checkbox' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          required: col.required,
          editing: this.isEditing(record),
        }),
      };
    });
    return (
      <EditableContext.Provider value={this.props.form}>
        <Table
          components={components}
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          scroll={{ y: 600 }}
          pagination={{
            pageSize: 15,
            showSizeChanger: true,
            onChange: this.cancel,
          }}
          loading={
            this.props.status !== '' &&
            this.props.status !== 'Saved Successfully!' &&
            this.props.status !== 'Updating Successfully!'
          }
          onChange={this.handleChange}
        />
      </EditableContext.Provider>
    );
  }
}

export default Form.create()(EditableTable);
