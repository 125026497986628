import React from 'react';
import { Modal, Form, Input, Select, Checkbox, message } from 'antd';

class VariantForm extends React.Component {
  render() {
    const { form, variant } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Form.Item>
          {getFieldDecorator('control', {
            rules: [{ required: true }],
            valuePropName: 'checked',
            initialValue: variant ? variant.control : false,
          })(<Checkbox>Control</Checkbox>)}
        </Form.Item>
        <Form.Item label="Type">
          {getFieldDecorator('type', {
            initialValue: variant ? variant.type : null,
          })(
            <Select placeholder="Type">
              <Select.Option key={null} value={null}>
                &nbsp;
              </Select.Option>
              {['A', 'B', 'C', 'D', 'E', 'F'].map((x) => (
                <Select.Option key={x} value={x}>
                  {x}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Value">
          {getFieldDecorator('value', {
            rules: [{ max: 200 }],
            initialValue: variant ? variant.value : '',
          })(<Input placeholder="Value" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(VariantForm);

export default ({ abTestId, releaseId, variant, handleHide, addSubmit, updSubmit }) => {
  let formRef = null;

  return (
    <Modal
      title={variant ? `Change variant #${variant.id}` : 'Add new variant'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (!formRef.props.form.getFieldValue('control') && !formRef.props.form.getFieldValue('type')) {
              message.error('Type should be selected if control is false');
              return;
            }
            const bodyParams = { ...values, release_id: releaseId };
            if (variant) {
              updSubmit(variant.id, bodyParams);
            } else {
              addSubmit(abTestId, bodyParams);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        variant={variant}
      />
    </Modal>
  );
};
