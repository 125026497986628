import * as AT from '../actionTypes';
import { addNewOrchestratorAPI, delOrchestratorAPI, updOrchestratorAPI } from '../actions/orchestrators';

const orchestratorsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.ADD_NEW_ORCHESTRATOR: {
        dispatch(addNewOrchestratorAPI(action.payload.orchestrator));
        return next(action);
      }
      case AT.UPDATE_ORCHESTRATOR: {
        dispatch(updOrchestratorAPI(action.payload.orchestrator));
        return next(action);
      }
      case AT.DELETE_ORCHESTRATOR: {
        dispatch(delOrchestratorAPI(action.payload.orchestratorId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default orchestratorsMiddleware;
