import { handleActions } from 'redux-actions';
import * as AT from '../actionTypes';

const initialState = {
  apps: [],
  appsWithGraphs: [],
  screenChanges: null,
  graphData: null,
  sendingComplaintStatus: null,
  error: null,
};

const UIViewerReducer = handleActions(
  {
    [AT.GET_APPS_WITH_SCREEN_CHANGES_SUCCESS]: (state, action) => ({
      ...state,
      apps: action.payload.data,
    }),
    [AT.GET_SCREEN_CHANGES_FOR_APP_SUCCESS]: (state, action) => ({
      ...state,
      screenChanges: action.payload.data.data,
    }),
    [AT.GET_GRAPH_DATA_FOR_APP]: (state, action) => ({
      ...state,
      graphData: null,
    }),
    [AT.GET_GRAPH_DATA_FOR_APP_SUCCESS]: (state, action) => ({
      ...state,
      graphData: action.payload.data.data,
    }),
    [AT.GET_ALL_APPS_WITH_GRAPHS]: (state, action) => ({
      ...state,
      error: null,
    }),
    [AT.GET_ALL_APPS_WITH_GRAPHS_SUCCESS]: (state, action) => ({
      ...state,
      appsWithGraphs: action.payload.data.data,
    }),
    [AT.GET_ALL_APPS_WITH_GRAPHS_FAILURE]: (state, action) => {
      console.log(action.payload);
      return {
        ...state,
        error: action.payload.error,
      };
    },

    [AT.CREATE_COMPLAINT_FOR_GRAPH]: (state, action) => ({
      ...state,
      sendingComplaintStatus: 'sending',
    }),
    [AT.CREATE_COMPLAINT_FOR_GRAPH_SUCCESS]: (state, action) => ({
      ...state,
      sendingComplaintStatus: 'success',
      graphData: {
        ...state.graphData,
        complaints: [action.payload.data.data, ...(state.graphData.complaints ? state.graphData.complaints : [])],
      },
    }),
    [AT.DELETE_COMPLAINT_FOR_GRAPH]: (state, action) => ({
      ...state,
      sendingComplaintStatus: 'deleting complaint',
    }),
    [AT.DELETE_COMPLAINT_FOR_GRAPH_SUCCESS]: (state, action) => ({
      ...state,
      sendingComplaintStatus: 'complaint deleted',
      graphData: {
        ...state.graphData,
        complaints: [],
      },
    }),
    [AT.CREATE_SCREEN_CHANGE_INSIGHT_SUCCESS]: (state, action) => {
      const { app, insight } = action.payload;
      let insightCopy = [...state.graphData.insights];
      const newInsight = {
        id: insight.id,
        title: insight.title,
        type: insight.type,
        created: insight.created,
      };
      if (state.graphData.insights.find((insByNode) => insByNode.node_id === insight.node_id)) {
        insightCopy = insightCopy.map((insByNode) =>
          insByNode.node_id !== insight.node_id
            ? insByNode
            : {
                node_id: insByNode.node_id,
                insights: [
                  ...insightCopy.find((inByNode) => inByNode.node_id === insight.node_id).insights,
                  newInsight,
                ],
              }
        );
      } else {
        insightCopy.push({
          node_id: insight.node_id,
          insights: [newInsight],
        });
      }

      return {
        ...state,
        graphData: {
          ...state.graphData,
          insights: insightCopy,
        },
      };
    },
  },
  initialState
);

export default UIViewerReducer;
