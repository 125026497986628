import { stringify } from 'query-string';
import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';
import { getPaymentDetails, paymentMethods } from '../../components/personas/perosnas_helper';

export const apiRequestPersonasFailed = (error) => ({
  type: AT.API_REQUEST_PERSONAS_FAILED,
  payload: { error },
});

// Get all personas

export const getAllPersonas = (appId) => ({
  type: AT.GET_PERSONAS,
  payload: { appId },
});

export const getAllPersonasAPI = (appId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/all?${stringify({ app_id: appId })}`,
    label: NETWORK_LABELS.GET_PERSONAS,
    onSuccess: (body, dispatch) => dispatch(getAllPersonasSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const getAllPersonasSuccess = (data) => ({
  type: AT.GET_PERSONAS_SUCCESS,
  payload: { personas: data.data },
});

// Update persona

export const updPersona = (personaId, data) => ({
  type: AT.UPD_PERSONA,
  payload: {
    personaId,
    data,
  },
});

export const updPersonaAPI = (personaId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_PERSONA,
    onSuccess: (body, dispatch) => dispatch(updPersonaSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const updPersonaSuccess = (body) => ({
  type: AT.UPD_PERSONA_SUCCESS,
  payload: { data: body.data },
});

// Create persona

export const addPersona = (data) => ({
  type: AT.ADD_PERSONA,
  payload: { data },
});

export const addPersonaAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'personas',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_PERSONA,
    onSuccess: (body, dispatch) => dispatch(addPersonaSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const addPersonaSuccess = (body) => ({
  type: AT.ADD_PERSONA_SUCCESS,
  payload: { data: body.data },
});

// Create org_persona

export const addPersonaOrganization = (personaId, data) => ({
  type: AT.ADD_PERSONA_ORGANIZATION,
  payload: {
    personaId,
    data,
  },
});

export const addPersonaOrganizationAPI = (personaId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/organizations`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_PERSONA_ORGANIZATION,
    onSuccess: (body, dispatch) => dispatch(addPersonaOrganizationSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const addPersonaOrganizationSuccess = (body) => ({
  type: AT.ADD_PERSONA_ORGANIZATION_SUCCESS,
  payload: { data: body.data },
});

// Delete org_persona

export const delPersonaOrganization = (personaId, organizationId) => ({
  type: AT.DEL_PERSONA_ORGANIZATION,
  payload: {
    personaId,
    organizationId,
  },
});

export const delPersonaOrganizationAPI = (personaId, organizationId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/organizations/${organizationId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_PERSONA_ORGANIZATION,
    onSuccess: (body, dispatch) => dispatch(delPersonaOrganizationSuccess(personaId, organizationId)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const delPersonaOrganizationSuccess = (personaId, organizationId) => ({
  type: AT.DEL_PERSONA_ORGANIZATION_SUCCESS,
  payload: {
    personaId,
    organizationId,
  },
});

// Create product_setting

export const addProductSetting = (personaId, productId, data) => ({
  type: AT.ADD_PRODUCT_SETTING,
  payload: {
    personaId,
    productId,
    data,
  },
});

export const addProductSettingAPI = (personaId, productId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/products/${productId}/settings`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_PRODUCT_SETTING,
    onSuccess: (body, dispatch) => dispatch(addProductSettingSuccess(personaId, body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const addProductSettingSuccess = (personaId, body) => ({
  type: AT.ADD_PRODUCT_SETTING_SUCCESS,
  payload: {
    personaId,
    data: body.data,
  },
});

// Delete product_setting

export const delProductSetting = (personaId, productId, settingId) => ({
  type: AT.DEL_PRODUCT_SETTING,
  payload: {
    personaId,
    productId,
    settingId,
  },
});

export const delProductSettingAPI = (personaId, productId, settingId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/products/${productId}/settings/${settingId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_PERSONA_SEGMENT,
    onSuccess: (body, dispatch) => dispatch(delPersonaSettingSuccess(personaId, productId, settingId)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const delPersonaSettingSuccess = (personaId, productId, settingId) => ({
  type: AT.DEL_PRODUCT_SETTING_SUCCESS,
  payload: {
    personaId,
    productId,
    settingId,
  },
});

export const addPersonaProduct = (personaId, data) => ({
  type: AT.ADD_PERSONA_PRODUCT,
  payload: {
    personaId,
    data,
  },
});

export const addPersonaProductAPI = (personaId, data) => {
  console.log(data);
  return {
    type: AT.API_REQUEST,
    payload: {
      url: `personas/${personaId}/products`,
      method: 'POST',
      data,
      label: NETWORK_LABELS.ADD_PERSONA_PRODUCT,
      onSuccess: (body, dispatch) => {
        dispatch(addPersonaProductSuccess(body));
        if (!(data.payment_method === paymentMethods[0])) {
          dispatch(updPaymentDetail(personaId, body.data.id, getPaymentDetails(data)));
        }
      },
      onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
    },
  };
};

export const updPersonaProductAPI = (personaId, personaProductId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/products/${personaProductId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_PERSONA_PRODUCT,
    onSuccess: (body, dispatch) => {
      dispatch(updPersonaProductSuccess(body));
      if (data.payment_method === paymentMethods[0]) {
        dispatch(delPaymentMethod(personaId, body.data.id));
      } else {
        dispatch(updPaymentDetail(personaId, body.data.id, getPaymentDetails(data)));
      }
    },
    onFailure: (error, dispatch) => {
      dispatch(apiRequestPersonasFailed(error));
    },
  },
});

export const addPersonaProductSuccess = (body) => ({
  type: AT.ADD_PERSONA_PRODUCT_SUCCESS,
  payload: { data: body.data },
});

export const updPersonaProduct = (personaId, productId, data) => ({
  type: AT.UPD_PERSONA_PRODUCT,
  payload: {
    personaId,
    productId,
    data,
  },
});

export const updPersonaProductSuccess = (body) => ({
  type: AT.UPD_PERSONA_PRODUCT_SUCCESS,
  payload: { data: body.data },
});

export const delPersonaProduct = (personaId, productId) => ({
  type: AT.DEL_PERSONA_PRODUCT,
  payload: {
    personaId,
    productId,
  },
});

export const delPersonaProductAPI = (personaId, productId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/${personaId}/products/${productId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_PERSONA_PRODUCT,
    onSuccess: (body, dispatch) => dispatch(delPersonaProductSuccess(personaId, productId)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const delPersonaProductSuccess = (personaId, productId) => ({
  type: AT.DEL_PERSONA_PRODUCT_SUCCESS,
  payload: {
    personaId,
    productId,
  },
});

export const updPaymentDetail = (personaId, personaProductId, data) => ({
  type: AT.UPDATE_PAYMENT_DETAIL,
  payload: {
    personaId,
    personaProductId,
    data,
  },
});

export const updPaymentDetailAPI = (personaId, personaProductId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/persona-products/${personaProductId}/payment-detail`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.UPD_PAYMENT_DETAIL,
    onSuccess: (body, dispatch) => dispatch(updPaymentDetailSuccess(personaId, personaProductId, body)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const updPaymentDetailSuccess = (personaId, personaProductId, data) => ({
  type: AT.UPDATE_PAYMENT_DETAIL_SUCCESS,
  payload: {
    personaId,
    personaProductId,
    data,
  },
});

export const delPaymentMethod = (personaId, personaProductId) => ({
  type: AT.DEL_PAYMENT_DETAIL,
  payload: {
    personaId,
    personaProductId,
  },
});

export const delPaymentMethodAPI = (personaId, personaProductId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `personas/persona-products/${personaProductId}/payment-detail`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_PAYMENT_DETAIL,
    onSuccess: (body, dispatch) => dispatch(delPaymentMethodSuccess(personaId, personaProductId)),
    onFailure: (error, dispatch) => dispatch(apiRequestPersonasFailed(error)),
  },
});

export const delPaymentMethodSuccess = (personaId, personaProductId) => ({
  type: AT.DEL_PAYMENT_DETAIL_SUCCESS,
  payload: {
    personaId,
    personaProductId,
  },
});
