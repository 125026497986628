import { getAllSettingsAPI, addSettingAPI, updSettingAPI, delSettingAPI } from '../actions/personaSettings';
import * as AT from '../actionTypes';

const settingsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_SETTINGS: {
        dispatch(getAllSettingsAPI());
        return next(action);
      }
      case AT.ADD_SETTING: {
        dispatch(addSettingAPI(action.payload.data));
        return next(action);
      }
      case AT.UPD_SETTING: {
        dispatch(updSettingAPI(action.payload.settingId, action.payload.data));
        return next(action);
      }
      case AT.DEL_SETTING: {
        dispatch(delSettingAPI(action.payload.settingId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default settingsMiddleware;
