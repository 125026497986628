import { handleActions } from 'redux-actions';
import * as AT from '../actionTypes';

const initialState = {
  all: [],
  versions: {},
  perfApps: [],
  appsEvents: {},
  appsDevices: {},
};

const appsReducer = handleActions(
  {
    [AT.SET_APPS]: (state, action) => ({
      ...state,
      all: (action.payload.apps && action.payload.apps.filter((app) => app.name)) || [],
    }),
    [AT.SET_APP_VERSIONS]: (state, action) => ({
      ...state,
      versions: {
        ...state.versions,
        [[action.payload.appPackage, action.payload.appPlatform]]: action.payload.versions,
      },
    }),
    [AT.SET_PERF_APPS]: (state, action) => ({
      ...state,
      perfApps: action.payload.apps,
    }),

    // EVENTS
    [AT.GET_APP_EVENTS_SUCCESS]: (state, action) => {
      const copyAppsEvents = { ...state.appsEvents };
      copyAppsEvents[action.payload.data.app_id] = action.payload.data.body;
      return {
        ...state,
        appsEvents: copyAppsEvents,
      };
    },

    [AT.ADD_APP_EVENTS_SUCCESS]: (state, action) => {
      const copyAppsEvents = { ...state.appsEvents };
      copyAppsEvents[action.payload.data.app_id] = [
        ...copyAppsEvents[action.payload.data.app_id],
        action.payload.data.body,
      ];
      return {
        ...state,
        appsEvents: copyAppsEvents,
      };
    },
    [AT.REMOVE_APP_EVENTS_SUCCESS]: (state, action) => {
      const copyAppsEvents = { ...state.appsEvents };
      copyAppsEvents[action.payload.data.app_id] = copyAppsEvents[action.payload.data.app_id].filter(
        (ev) => ev.id !== action.payload.data.id
      );
      return {
        ...state,
        appsEvents: copyAppsEvents,
      };
    },
    // DEVICES

    [AT.GET_APP_DEVICES_SUCCESS]: (state, action) => {
      const copyAppsDevices = { ...state.appsDevices };
      copyAppsDevices[action.payload.data.app_id] = action.payload.data.body;
      return {
        ...state,
        appsDevices: copyAppsDevices,
      };
    },
    [AT.ADD_APP_DEVICES_SUCCESS]: (state, action) => {
      const copyAppsDevices = { ...state.appsDevices };
      copyAppsDevices[action.payload.data.app_id] = [
        ...copyAppsDevices[action.payload.data.app_id],
        action.payload.data.body,
      ];
      return {
        ...state,
        appsDevices: copyAppsDevices,
      };
    },
    [AT.REMOVE_APP_DEVICES_SUCCESS]: (state, action) => {
      const copyAppsDevices = { ...state.appsDevices };
      copyAppsDevices[action.payload.data.app_id] = copyAppsDevices[action.payload.data.app_id].filter(
        (dev) => dev.device_model_id !== action.payload.data.device_model_id
      );
      return {
        ...state,
        appsDevices: copyAppsDevices,
      };
    },
  },
  initialState
);

export default appsReducer;
