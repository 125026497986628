import * as AT from '../actionTypes';

const APP_PACKAGE_KEY = 'lastAppPackage';
const APP_NAME_KEY = 'lastAppName';
const APP_PLATFORM_KEY = 'lastAppPlatform';
const localStorageMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.SELECT_APP:
        localStorage.setItem(APP_NAME_KEY, action.payload.appName);
        localStorage.setItem(APP_PACKAGE_KEY, action.payload.appPackage);
        localStorage.setItem(APP_PLATFORM_KEY, action.payload.appPlatform) || 'Android';
        return next(action);
      default:
        return next(action);
    }
  };

export default localStorageMiddleware;
