import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  insights: [],
  totalCount: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_ABANDONED_INSIGHTS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_ABANDONED_INSIGHTS_SUCCESS: {
      return {
        ...state,
        insights: action.payload.insights,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.GET_ABANDONED_INSIGHTS_COUNT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_ABANDONED_INSIGHTS__COUNT_SUCCESS: {
      return {
        ...state,
        totalCount: action.payload.count,
        error: null,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_ABANDONED_INSIGHTS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};
