import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const apiRequestReportsFailed = (error) => ({
  type: AT.API_REQUEST_REPORTS_FAILED,
  payload: { error },
});

// Get all tags

export const getTags = () => ({
  type: AT.GET_TAGS,
});

export const getTagsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/tags`,
    label: NETWORK_LABELS.GET_TAGS,
    onSuccess: (body, dispatch) => dispatch(getTagsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const getTagsSuccess = (data) => ({
  type: AT.GET_TAGS_SUCCESS,
  payload: { tags: data.data },
});

// Get all reports

export const getReports = () => ({
  type: AT.GET_REPORTS,
});

export const getReportsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/all`,
    label: NETWORK_LABELS.GET_REPORTS,
    onSuccess: (body, dispatch) => dispatch(getReportsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const getReportsSuccess = (data) => ({
  type: AT.GET_REPORTS_SUCCESS,
  payload: { reports: data.data },
});

// Create persona

export const addReport = (data) => ({
  type: AT.ADD_REPORT,
  payload: { data },
});

export const addReportAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'reports',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_REPORT,
    onSuccess: (body, dispatch) => dispatch(addReportSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const addReportSuccess = (body) => ({
  type: AT.ADD_REPORT_SUCCESS,
  payload: { report: body.data },
});

// Update persona

export const updReport = (reportId, data) => ({
  type: AT.UPD_REPORT,
  payload: {
    reportId,
    data,
  },
});

export const updReportAPI = (reportId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_REPORT,
    onSuccess: (body, dispatch) => dispatch(updReportSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const updReportSuccess = (body) => ({
  type: AT.UPD_REPORT_SUCCESS,
  payload: { report: body.data },
});

// Delete persona

export const delReport = (reportId) => ({
  type: AT.DEL_REPORT,
  payload: { reportId },
});

export const delReportAPI = (reportId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_REPORT,
    onSuccess: (body, dispatch) => dispatch(delReportSuccess(reportId)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const delReportSuccess = (reportId) => ({
  type: AT.DEL_REPORT_SUCCESS,
  payload: { reportId },
});

// Create report_tag

export const addReportTag = (reportId, data) => ({
  type: AT.ADD_REPORT_TAG,
  payload: {
    reportId,
    data,
  },
});

export const addReportTagAPI = (reportId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}/tags`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_REPORT_TAG,
    onSuccess: (body, dispatch) => dispatch(addReportTagSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const addReportTagSuccess = (body) => ({
  type: AT.ADD_REPORT_TAG_SUCCESS,
  payload: { report: body.data },
});

// Delete report_tag

export const delReportTag = (reportId, tagId) => ({
  type: AT.DEL_REPORT_TAG,
  payload: {
    reportId,
    tagId,
  },
});

export const delReportTagAPI = (reportId, tagId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}/tags/${tagId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_REPORT_TAG,
    onSuccess: (body, dispatch) => dispatch(delReportTagSuccess(reportId, tagId)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const delReportTagSuccess = (reportId, tagId) => ({
  type: AT.DEL_REPORT_TAG_SUCCESS,
  payload: {
    reportId,
    tagId,
  },
});

// Create org_report

export const addOrgReport = (reportId, data) => ({
  type: AT.ADD_ORG_REPORT,
  payload: {
    reportId,
    data,
  },
});

export const addOrgReportAPI = (reportId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}/organizations`,
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_ORG_REPORT,
    onSuccess: (body, dispatch) => dispatch(addOrgReportSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const addOrgReportSuccess = (body) => ({
  type: AT.ADD_ORG_REPORT_SUCCESS,
  payload: { report: body.data },
});

// Delete org_report

export const delOrgReport = (reportId, organizationId) => ({
  type: AT.DEL_ORG_REPORT,
  payload: {
    reportId,
    organizationId,
  },
});

export const delOrgReportAPI = (reportId, organizationId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `reports/${reportId}/organizations/${organizationId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_ORG_REPORT,
    onSuccess: (body, dispatch) => dispatch(delOrgReportSuccess(reportId, organizationId)),
    onFailure: (error, dispatch) => dispatch(apiRequestReportsFailed(error)),
  },
});

export const delOrgReportSuccess = (reportId, organizationId) => ({
  type: AT.DEL_ORG_REPORT_SUCCESS,
  payload: {
    reportId,
    organizationId,
  },
});
