import { Button, Input, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getFilteredABTests, getReleasesByApp } from '../../../../../../api/insightsAPI';
import { INSIGHT_TYPE } from '../../../../../../constants/insightType';
import './NewInsight.scss';

export const NewInsight = ({ createInsight, newInsightCreationClose, appId }) => {
  const [title, titleChange] = useState('');
  const [description, descriptionChange] = useState('');
  const [type, typeChange] = useState('');
  const [idAndInternalName, setIdAndInternalName] = useState({
    id: null,
    internalName: null,
  });
  const [abTestsSearch, setAbTestsSearch] = useState('');
  const [releaseId, setReleaseId] = useState(null);

  const isSubmitDisabled =
    !title ||
    !description ||
    !type ||
    (type === 'ab_test' && (!releaseId || !idAndInternalName.id || !idAndInternalName.internalName));

  const { data: abTests } = useQuery(
    ['AbTestsByApp', appId, abTestsSearch],
    () => getFilteredABTests(appId, abTestsSearch || ''),
    {
      enabled: !!(appId && abTestsSearch && type === 'ab_test'),
    }
  );

  const { data: appReleases } = useQuery(['AppReleases', appId], () => getReleasesByApp(appId), {
    enabled: !!(appId && type === 'ab_test'),
  });

  const releases = appReleases ? appReleases.data : [];
  const internals = abTests ? abTests.data : [];

  const handleAbTestSearch = (value) => {
    setAbTestsSearch(value);
  };

  const handleAbTestSelect = (abTestId) => {
    setIdAndInternalName({
      id: abTestId,
      internalName: internals.find(({ id }) => Number(abTestId) === id).internal_name,
    });
  };

  const handleReleaseSelect = (releaseId) => {
    setReleaseId(Number(releaseId));
  };

  const handleCreateInsight = () => {
    const newStartTime = type === 'ab_test' ? releases.find((rel) => rel.id === Number(releaseId)).release_date : '';

    createInsight(type, title, description, idAndInternalName.id, releaseId, moment(newStartTime).format('YYYY-MM-DD'));
    newInsightCreationClose();
  };

  return (
    <div className="new-insight-wrapper">
      <div className="item">
        <Select showSearch value={type} onChange={(value) => typeChange(value)} style={{ width: 200 }}>
          {Object.keys(INSIGHT_TYPE).map((type) => (
            <Select.Option value={type} key={type}>
              {INSIGHT_TYPE[type]}
            </Select.Option>
          ))}
        </Select>
      </div>
      {type === 'ab_test' && (
        <React.Fragment>
          <div className="item">
            <Select
              showSearch
              filterOption={false}
              onSelect={handleAbTestSelect}
              onSearch={handleAbTestSearch}
              value={
                idAndInternalName.id && idAndInternalName.internalName
                  ? `${idAndInternalName.id} - ${idAndInternalName.internalName}`
                  : 'ID and internal name'
              }
              style={{ width: 330 }}
            >
              {internals.map((internal) => (
                <Select.Option key={internal.id} value={String(internal.id)}>
                  {internal.id} - {internal.internal_name}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="item">
            <Select placeholder="Release" value={releaseId || ''} onChange={handleReleaseSelect} style={{ width: 330 }}>
              {[..._.orderBy(releases, ['release_date'], ['desc'])].map((rel) => (
                <Select.Option value={rel.id} className={`release-option ${rel.is_visible ? 'green' : 'white'}`}>
                  {rel.release_name} ({rel.version_code}) | {moment(rel.release_date).format('YYYY-MM-DD')} |{' '}
                  {rel.state}
                </Select.Option>
              ))}
            </Select>
          </div>
        </React.Fragment>
      )}
      <div className="item">
        <Input value={title} placeholder="Title..." onChange={(e) => titleChange(e.currentTarget.value)} />
      </div>
      <div className="item">
        <Input.TextArea
          value={description}
          placeholder="Description..."
          onChange={(e) => descriptionChange(e.currentTarget.value)}
        />
      </div>
      <div className="item">
        <div className="buttons">
          <Button
            onClick={() => {
              newInsightCreationClose();
            }}
          >
            cancel
          </Button>
          <Button disabled={isSubmitDisabled} onClick={handleCreateInsight} type="primary">
            create
          </Button>
        </div>
      </div>
    </div>
  );
};
