import React from 'react';
import { Modal, Form, Input, Row, Col } from 'antd';

class CtaForm extends React.Component {
  render() {
    const { form, cta } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                rules: [{ max: 255 }],
                initialValue: cta ? cta.title : '',
              })(<Input placeholder="Name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Url">
              {getFieldDecorator('url', {
                initialValue: cta ? cta.url : '',
              })(<Input.TextArea placeholder="Url" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Utms">
              {getFieldDecorator('utms', {
                initialValue: cta ? cta.utms : '',
              })(<Input.TextArea placeholder="Utms" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(CtaForm);

export default ({ cta, onHide, onAddCta, onUpdCta }) => {
  let formRef = null;

  return (
    <Modal
      title={cta ? `Change CTA #${cta.id}` : 'Add new CTA'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (cta) {
              onUpdCta(cta.id, values);
            } else {
              onAddCta(values);
            }
            formRef.props.form.resetFields();
            onHide();
          });
        }
      }}
      onCancel={() => {
        onHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        cta={cta}
      />
    </Modal>
  );
};
