import React, { useState } from 'react';
import { Button, Icon, Popconfirm, Table, Divider, Spin, Select, Tooltip, Dropdown, Menu } from 'antd';
import Img from 'react-image';
import moment from 'moment';
import { countries } from '../../../constants/countries';
import PersonaProductModal from '../Products/PersonaProductModal';
import { getColumnSearchProps } from '../../../utils/helpers';
import Highlighter from 'react-highlight-words';

const COUNTRY_MAP = Object.fromEntries(countries.map((x) => [x.code, x.name]));

const PersonasTable = ({
  settings,
  products,
  personas,
  organizations,
  isLoading,
  isAdmin,
  onUpdPersona,
  onUpdOrgPersonas,
  addPersonaProduct,
  updPersonaProduct,
  delPersonaProduct,
  addProductSetting,
  delProductSetting,
  updPaymentDetail,
  delPaymentMethod,
  showAllColumns = true,
}) => {
  const [isPersonProductModalVisible, setIsPersonProductModalVisible] = useState(false);
  const [currEditPersona, setCurrEditPersona] = useState(null);
  const [currEditPersonaProductId, setCurrEditPersonaProductId] = useState(null);
  const [tableSearchQuery, tableSearchQueryChange] = useState('');

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    tableSearchQueryChange(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    tableSearchQueryChange('');
  };
  // eslint-disable-next-line camelcase
  const handleOnPersonaProductOpen = (persona) => {
    setIsPersonProductModalVisible(true);
    setCurrEditPersona(persona);
  };

  const handleOnPersonaProductEdit = (persona, personaProduct) => {
    setIsPersonProductModalVisible(true);
    setCurrEditPersona(persona);
    setCurrEditPersonaProductId(personaProduct.id);
  };

  const productMenu = (persona, personaProduct, isAdmin) => (
    <Menu>
      <Menu.Item>
        <Button
          type="small"
          icon="edit"
          title="Update product"
          onClick={() => handleOnPersonaProductEdit(persona, personaProduct)}
        />
      </Menu.Item>
      {isAdmin && (
        <Menu.Item danger>
          <Popconfirm
            title="Remove product from this persona?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => delPersonaProduct(persona.id, personaProduct.product_id)}
          >
            <Button icon="delete" type="danger small" title="Delete product" />
          </Popconfirm>
        </Menu.Item>
      )}
    </Menu>
  );

  const actualProducts = personas
    .map((a) => a.persona_products)
    .flat()
    .map((pr) => pr.product)
    .reduce((acc, el) => (acc.find((p) => p.id === el.id) ? acc : [...acc, el]), []);

  const getPersonaProductUI = (personaProduct) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>{personaProduct.product.name}</div>
      {personaProduct.settings.map((setting) => (
        <div style={{ background: 'purple' }}>
          {setting.type}: {setting.value}
        </div>
      ))}
    </div>
  );
  const scopeFilters = [
    {
      text: 'Marketing',
      value: 'marketing',
    },
    {
      text: 'Intelligence',
      value: 'product',
    },
    {
      text: 'Both',
      value: 'both',
    },
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10em',
      render: (value, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{value}</div>
          <Button
            type="small"
            icon="edit"
            title="Change persona"
            style={{ marginLeft: '5px' }}
            onClick={() => onUpdPersona(record.id)}
          />
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'title',
      width: showAllColumns ? undefined : '20rem',
      ...getColumnSearchProps('title', handleSearch, handleReset),
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[tableSearchQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ),
    },
    {
      title: 'Organizations',
      dataIndex: 'organizations',
      width: showAllColumns ? '10rem' : undefined,
      render: (value, record) => (
        <Select
          mode="multiple"
          placeholder="Organizations"
          onChange={(v) => onUpdOrgPersonas(record, v)}
          value={record.organizations.map((x) => x.id)}
        >
          <Select.Option value="all">Select All</Select.Option>
          {organizations.map((org) => (
            <Select.Option value={org.id} key={org.id}>
              {org.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'created_at',
      render: (value) => <div title={value}>{moment(value).format('YYYY-MM-DD')}</div>,
      visible: showAllColumns,
    },
    {
      title: 'Is Default',
      dataIndex: 'is_default',
      render: (value) => (value ? 'Yes' : 'No'),
      visible: showAllColumns,
    },
    {
      title: 'Scope',
      dataIndex: 'scope',
      filters: scopeFilters,
      onFilter: (value, record) => record.scope.includes(value),
      visible: showAllColumns,
    },
    {
      title: 'Birth Date',
      dataIndex: 'birth_date',
      width: '130px',
      visible: showAllColumns,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: '100px',
      render: (value) => value[0],
      visible: showAllColumns,
    },
    {
      title: 'Email + Password',
      dataIndex: 'email',
      width: '150px',
      render: (email, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{email}</div>
          <div>{record.email_password}</div>
        </div>
      ),
      visible: showAllColumns,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      visible: showAllColumns,
    },
    {
      title: 'Pic',
      dataIndex: 'picture',
      width: '70px',
      render: (pic) => (
        <Img
          style={{
            width: '100%',
            height: '100%',
          }}
          src={pic}
          alt="persona pic"
          loader={<Spin />}
          container={(children) => (
            <div
              style={{
                width: '50px',
                height: '50px',
                marginRight: '4px',
              }}
            >
              {children}
            </div>
          )}
        />
      ),
      visible: showAllColumns,
    },
    {
      title: 'Products',
      dataIndex: 'persona_products',
      filters: actualProducts
        .sort((a, b) => (a.name[0].toLowerCase() > b.name[0].toLowerCase() ? 1 : -1))
        .map((product) => ({
          text: product.name,
          value: product.name,
        })),
      onFilter: (value, record) => record.persona_products.find((prd) => prd.product.name === value),
      render: (personaProducts, record) => (
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
            {personaProducts.map((personaProd) => (
              <div style={{ marginBottom: '3px' }}>
                <Tooltip placement="top" title={getPersonaProductUI(personaProd)} mouseLeaveDelay={0}>
                  <Dropdown overlay={productMenu(record, personaProd, isAdmin)}>
                    <Img
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={[personaProd.product.thumbnail]}
                      alt=""
                      loader={<Spin />}
                      container={(children) => (
                        <div
                          style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '4px',
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                  </Dropdown>
                </Tooltip>
              </div>
            ))}
          </div>
          <div style={{}}>
            <Button
              type="small"
              icon="plus"
              title="Add new product"
              onClick={() => handleOnPersonaProductOpen(record)}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '100px',
      render: (value) => <React.Fragment>{value ? 'Yes' : 'No'}</React.Fragment>,
      visible: showAllColumns,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '100px',
      render: (value) => (
        <div style={{ margin: '0 auto', display: 'table' }}>
          <Tooltip placement="top" title={COUNTRY_MAP[value] || 'Unknown'} mouseLeaveDelay={0}>
            <span
              className={`flag-icon flag-icon-${value.toLowerCase()}`}
              title={COUNTRY_MAP[value] || 'Unknown'}
              style={{
                marginRight: '1rem',
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: 'Geo',
      dataIndex: 'geo_address',
      width: '220px',
      render: (geoAddress, record) => (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>Address: {geoAddress}</div>
          <div>Billing: {record.geo_billing_address}</div>
        </div>
      ),
      visible: showAllColumns,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '100px',
      visible: showAllColumns,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      visible: showAllColumns,
    },
  ];
  return (
    <div>
      {isPersonProductModalVisible && (
        <PersonaProductModal
          personaProduct={
            currEditPersona &&
            personas
              .find((persona) => persona.id === currEditPersona.id)
              .persona_products.find((personasProduct) => personasProduct.id === currEditPersonaProductId)
          }
          products={products}
          settings={settings}
          handleHide={() => {
            setIsPersonProductModalVisible(false);
            setCurrEditPersona(null);
            setCurrEditPersonaProductId(null);
          }}
          persona={currEditPersona}
          addSubmit={(data) => addPersonaProduct(currEditPersona.id, data)}
          updSubmit={(productID, data) => updPersonaProduct(currEditPersona.id, productID, data)}
          addProductSetting={(productID, data) => addProductSetting(currEditPersona.id, productID, data)}
          delProductSetting={(productID, settingID) => delProductSetting(currEditPersona.id, productID, settingID)}
          updPaymentDetail={(productID, data) => updPaymentDetail(currEditPersona.id, productID, data)}
          delPaymentMethod={(productID) => delPaymentMethod(currEditPersona.id, productID)}
        />
      )}
      <Table
        loading={isLoading}
        dataSource={personas.filter((persona) => persona.title.toLowerCase().includes(tableSearchQuery.toLowerCase()))}
        columns={columns.filter((x) => x.visible !== false)}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        className="personas-table"
        pagination={false}
      />
    </div>
  );
};

export default PersonasTable;
