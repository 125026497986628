import { Button, Empty, Input, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SOCKET_SEND_EVENTS } from '../../../../utils/consts';
import './RPTab.scss';

const RP_DISABLE_TIMOUT = 5000;
const RP_ENABLE_TIMOUT = 10000;

const RP_DIVIDER_TIMEOUT = 1500;
const DIVIDER_STR = '───────────────────────────';

const BooleanTestTypes = {
  true: true,
  false: false,
  'true ->  false': false,
  'false ->  true': true,
  'false ->  false': false,
  'true ->  true': true,
};

const isBooleanTest = (value) => {
  return Object.keys(BooleanTestTypes).includes(value);
};

export const RPTab = ({ socket, serial, currApp, rp, resetRp, updateTestValue }) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [search, setSearch] = useState('');
  const [isRPStarted, setIsRPStarted] = useState(false);
  const timeoutRef = useRef(null);
  const [rpListWithDividers, setRpListWithDividers] = useState([]);
  const [dividerIdxs, setDividerIdxs] = useState([]);

  useEffect(() => {
    if (!rp || !rp.experiments) return;

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setRpListWithDividers((prev) => [...prev, { isDivider: true }]);
    }, RP_DIVIDER_TIMEOUT);

    setRpListWithDividers(
      rp.experiments.filter(({ name, isDivider }) =>
        isDivider ? true : name.toLowerCase().includes(search.toLowerCase())
      )
    );

    return () => clearTimeout(timeoutRef.current);
  }, [rp]);

  useEffect(() => {
    const rpListEle = document.getElementById('rp-experiments-list');
    rpListEle.scrollTop = rpListEle.scrollHeight;
  }, [rpListWithDividers]);

  const handleRPClick = () => {
    socket.send({
      event: isRPStarted ? SOCKET_SEND_EVENTS.RpStop : SOCKET_SEND_EVENTS.RpStart,
      platform: currApp.platform,
      serial,
      package: currApp.package,
    });
    setIsRPStarted((prev) => !prev);

    if (isRPStarted) {
      resetRp();
    }

    setButtonDisabled(true);
    setTimeout(
      () => {
        setButtonDisabled(false);
      },
      isRPStarted ? RP_DISABLE_TIMOUT : RP_ENABLE_TIMOUT
    );
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    if (value && dividerIdxs.length === 0) {
      const idxs = rpListWithDividers.reduce((indexes, { isDivider }, index) => {
        if (isDivider) {
          indexes.push(index);
        }

        return indexes;
      }, []);

      setDividerIdxs(idxs);
    }

    if (value) {
      setRpListWithDividers((prev) =>
        prev.filter(({ name, isDivider }) => (isDivider ? false : name.toLowerCase().includes(value.toLowerCase())))
      );
    } else {
      const rpList = [...rp.experiments];

      dividerIdxs.forEach((idx) => {
        rpList.splice(idx, 0, { isDivider: true });
      });

      setRpListWithDividers(rpList);
      setDividerIdxs([]);
    }

    setSearch(value);
  };

  const handleBooleanTestToggle =
    ({ tracking_type_str, name, value }) =>
    () => {
      updateTestValue({
        serial,
        tracking_type_str,
        name,
        newValue: (!BooleanTestTypes[value]).toString(),
      });
    };

  return (
    <div className="rp-drawer-container">
      <Button
        type={isRPStarted ? 'danger' : 'primary'}
        shape="round"
        icon="poweroff"
        size="large"
        onClick={handleRPClick}
        disabled={buttonDisabled}
      >
        {isRPStarted ? 'Stop' : 'Start'} RP
      </Button>
      <Input value={search} onChange={handleSearch} placeholder="Search Feature Flag" />
      <div id="rp-experiments-list" className={`rp-ff-list ${rpListWithDividers.length ? '' : 'no-data'}`}>
        {rpListWithDividers.length ? (
          rpListWithDividers.map(({ tracking_type_str, name, value, more_info_str, isDivider }, idx) =>
            isDivider && search ? null : (
              <div key={idx} className={`ff-list-item ${idx % 2 === 0 ? 'dark' : ''}`}>
                {isDivider ? (
                  DIVIDER_STR
                ) : (
                  <React.Fragment>
                    {tracking_type_str} {name} : {value} <br /> {more_info_str} <br />
                    <br />{' '}
                    {isBooleanTest(value) && (
                      <Switch
                        checked={BooleanTestTypes[value]}
                        onChange={handleBooleanTestToggle({
                          tracking_type_str,
                          name,
                          value,
                        })}
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            )
          )
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </div>
  );
};
