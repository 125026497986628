import { backendApiRequest, workbenchApiRequest } from './request';

export const getDevices = async () =>
  workbenchApiRequest({
    method: 'GET',
    path: '/devices',
  });

export const getVersion = async () =>
  workbenchApiRequest({
    method: 'GET',
    path: '/version',
  });

export const getDevicesApps = async (serial) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/apps`,
  });

export const getInsights = async (packageName, platform) =>
  backendApiRequest({
    method: 'GET',
    path: `/insight/list?app=${packageName}&platform=${platform}&no_hashflags=1`,
  });

export const saveInsight = async ({ insightID, body }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/insights/${insightID}`,
    body,
  });

export const getDeviceScreen = async (serial) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/screen`,
  });

export const getDeviceScreenshot = async (serial) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/screenshot`,
  });

export const getDeviceScreenData = async (serial) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/xml`,
  });

export const getCurrentApp = async (serial) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/current_app`,
  });

export const saveDeviceScreenshotToS3 = async (serial, body) =>
  workbenchApiRequest({
    method: 'POST',
    path: `/devices/${serial}/s3/screenshot`,
    body,
  });

export const startVideoRecording = async ({ serial, package_name, version_name, version_code }) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/screen_recording/start`,
    params: {
      package_name,
      version_name,
      version_code,
    },
  });

export const stopVideoRecording = async ({ serial }) =>
  workbenchApiRequest({
    method: 'GET',
    path: `/devices/${serial}/screen_recording/stop`,
  });

export const saveDeviceXMLToS3 = async (serial, body) =>
  workbenchApiRequest({
    method: 'POST',
    path: `/devices/${serial}/s3/xml`,
    body,
  });

export const translateText = async ({ text, targetLang }) => {
  const res = await fetch(
    `https://corsproxy.io/?url=${encodeURIComponent('https://deep-translator-api.azurewebsites.net/google/')}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source: 'auto',
        target: targetLang,
        text,
        proxies: [],
      }),
    }
  );

  return res.json();
};

export const updateRpConfig = async (serial, body) =>
  workbenchApiRequest({
    method: 'PUT',
    path: `/update-rp-config/${serial}`,
    body,
  });

export const updateRpTrackAll = async ({ serial, body }) =>
  workbenchApiRequest({
    method: 'PUT',
    path: `/update-rp-track-all/${serial}`,
    body,
  });

export const updateRpTrackOne = async ({ serial, body }) =>
  workbenchApiRequest({
    method: 'PUT',
    path: `/update-rp-track-one/${serial}`,
    body,
  });

export const getFavoriteExperiments = async ({ app_id }) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/apps/${app_id}/ab-tests/favorites`,
  });

export const addExperimentToFavorites = async ({ app_id, name }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/apps/${app_id}/ab-tests/favorites`,
    body: { name },
  });

export const removeExperimentFromFavorites = async ({ favorite_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/apps/ab-tests/favorites/${favorite_id}`,
  });
