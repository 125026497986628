import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIsFeatureByUserID } from '../../utils/features';
import InsightPage2 from '../Insight2/InsightsPage2';
import PossibleInsightsByEmails from '../possInsightsByEmails/PosInsightsByEmails';
import InsightPage from './InsightsPage';
import AbandonedInsights from './abandonedInsights/AbandonedInsights';
// eslint-disable-next-line import/no-named-as-default
import { getAbandonedInsightsCount } from '../../redux/actions/abandonedInsightsPage';
import { UserJourneys } from '../userJourneys';
import Workbench from '../workbench';
import { Workbench2, WorkbenchPath } from '../workbench_v2';
import InsightsListPage from './InsightsList/InsightsListPage';
import { FocusAreas } from './focus-areas';

const { TabPane } = Tabs;

const InsightPageTabs = ({
  isLoading,
  error,
  match,
  history,
  abandonedInsightsCount,
  getAbandonedInsightsCount,
  user,
  location,
  curUserDetails,
}) => {
  const isCurUserReviewer = user.reviewer;
  const isShowInsightCreation = useIsFeatureByUserID('show_insight_creation_page', user.email);
  useEffect(() => {
    getAbandonedInsightsCount();
  }, []);

  const path = location.pathname.split('/');

  const getDefTab = () => {
    if (path[2] === 'list') return 'list';
    if (path[1] === 'intelligence') return 'intelligence';
    if (path[1] === 'marketing' || user.isAnnotatorAdmin) return 'marketing';
    if (path[1] === 'workbench') return 'workbench';
    if (path[1] === 'user-journeys') return 'user-journeys';
    if (path[1] === WorkbenchPath) return WorkbenchPath;
    return 'Insights';
  };

  const [actTab, actTabChange] = useState(getDefTab());

  return (
    <Tabs type="card" defaultActiveKey={getDefTab()} onChange={actTabChange} activeKey={actTab}>
      {user.isAdmin && (
        <TabPane tab="Workbench2" key={WorkbenchPath}>
          <Workbench2 match={match} history={history} />
        </TabPane>
      )}
      {user.isAdmin && (
        <TabPane tab="Workbench" key="workbench">
          <Workbench match={match} history={history} />
        </TabPane>
      )}
      {user.isAdmin && (
        <TabPane tab="UserJourneys" key="user-journeys">
          <UserJourneys match={match} history={history} />
        </TabPane>
      )}
      {user.isAdmin && isShowInsightCreation && (
        <TabPane tab="Insights" key="Insights">
          <InsightPage match={match} history={history} />
        </TabPane>
      )}
      {user.isAdmin && (
        <TabPane tab="Intelligence" key="intelligence">
          <InsightPage2 match={match} history={history} />
        </TabPane>
      )}
      {(user.isAdmin || user.isAnnotatorAdmin) && (
        <TabPane tab="Marketing" key="marketing">
          <InsightPage2 match={match} history={history} isMarketing />
        </TabPane>
      )}
      {user.isAdmin && !isCurUserReviewer && (
        <TabPane tab={`Active AB Tests(${isLoading ? 'Loading...' : abandonedInsightsCount})`} key="activeAB">
          <AbandonedInsights isLoading={isLoading} error={error} />
        </TabPane>
      )}
      {user.isAdmin && !isCurUserReviewer && (
        <TabPane tab="Auto-created Email Insights" key="autoEmails">
          <PossibleInsightsByEmails />
        </TabPane>
      )}
      {user.isAdmin && (
        <TabPane tab="Insights List" key="list">
          <InsightsListPage match={match} history={history} />
        </TabPane>
      )}
      {curUserDetails?.is_org_admin && (
        <TabPane tab="Focus Areas" key="focusAreas">
          <FocusAreas history={history} />
        </TabPane>
      )}
    </Tabs>
  );
};

const mapDispatchToProps = {
  getAbandonedInsightsCount,
};

const mapStateToProps = ({ abandonedInsights, usersPage, loginData }) => {
  return {
    error: abandonedInsights.error,
    isLoading: abandonedInsights.isLoading,
    abandonedInsightsCount: abandonedInsights.totalCount,
    user: usersPage.user,
    curUserDetails: loginData.curUserDetails,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightPageTabs);
