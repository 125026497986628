import { createStore, compose, applyMiddleware } from 'redux';
import appReducer from './reducers';
import apiMiddleware from './middlewares/api';
import localStorageMiddleware from './middlewares/localStorage';
import appSelectionMiddleware from './middlewares/appSelection';
import usersMiddleware from './middlewares/users';
import userGroupsMiddleware from './middlewares/user_groups';
import loginMiddleware from './middlewares/login';
import releasesMiddleware from './middlewares/releases';
import emailMiddleware from './middlewares/email';
import annotationVerificationMiddleware from './middlewares/annotationVerification';
import abTestsMiddleware from './middlewares/abTests';
import productsMiddleware from './middlewares/products';
import devicesMiddleware from './middlewares/devices';
import personasMiddleware from './middlewares/personas';
import settingsMiddleware from './middlewares/pesonaSettings';
import notificationsMiddleware from './middlewares/notifications';
import reportsMiddleware from './middlewares/reports';
import possibleInsightsByEmailsMiddleware from './middlewares/possibleInsightsByEmails';
import insightCtasMiddleware from './middlewares/insightCtas';
import insightTimelineMiddleware from './middlewares/insightTimeline';
import abandonedInsightsMiddleware from './middlewares/abandonedInsights';
import subscriptionsMiddleware from './middlewares/subscriptions';
import UIViewerMiddleware from './middlewares/UIViewer';
import insightsListMiddleware from './middlewares/insightsList';
import orchestratorMiddleware from './middlewares/orchestrators';
import insightPersonasMiddleware from './middlewares/insightPersonas';

const middlewares = [
  apiMiddleware,
  localStorageMiddleware,
  appSelectionMiddleware,
  usersMiddleware,
  userGroupsMiddleware,
  loginMiddleware,
  releasesMiddleware,
  emailMiddleware,
  annotationVerificationMiddleware,
  abTestsMiddleware,
  productsMiddleware,
  devicesMiddleware,
  personasMiddleware,
  settingsMiddleware,
  notificationsMiddleware,
  reportsMiddleware,
  possibleInsightsByEmailsMiddleware,
  insightCtasMiddleware,
  insightTimelineMiddleware,
  abandonedInsightsMiddleware,
  subscriptionsMiddleware,
  UIViewerMiddleware,
  insightsListMiddleware,
  orchestratorMiddleware,
  insightPersonasMiddleware,
];

const isDev = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = (isDev && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

if (isDev) {
  // eslint-disable-next-line global-require
  middlewares.push(require('redux-freeze'));
}

const store = createStore(appReducer, composeEnhancers(applyMiddleware(...middlewares)));

export default store;
