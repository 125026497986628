import React, { useState } from 'react';
import { Button, Icon, Input } from 'antd';
import './PreviewVideo.scss';
import Spinner from '../ImageCanvas/Spinner';

export default function PreviewVideo({
  updatePreview,
  asset,
  blurFaces,

  previewRequestID,
  previewVideo,
  previewVideoStatus,
}) {
  const [conf, confChange] = useState(0.2);

  const handleBlurFacesClick = () => {
    if (previewRequestID) return;
    blurFaces(conf);
  };

  return (
    <div className="video-preview-wrap">
      <div className="canvased-image__controls">
        <div className="canv-btn-row">
          <Button onClick={handleBlurFacesClick} className="canv-btn-full row-2" disabled={previewRequestID}>
            Blur Faces
          </Button>
        </div>
        <div className="canv-btn-row">
          <div>
            <label>Confidence</label>
          </div>
          <div>
            <Input value={conf} onChange={(e) => confChange(e.target.value)} />
          </div>
        </div>
        <div className="canv-btn-row">{previewVideoStatus}</div>
      </div>
      <div className="canvased-image__canvas">
        <div className="orig-video">
          <video src={asset.url_raw} controls muted />
        </div>
      </div>
      <div className="preview-image">
        <div className="preview">
          <div className="preview-label">Preview</div>
        </div>
        {/* BLURED FACES */}
        <video src={previewVideo || asset.url_annotated} controls muted />
        {(previewRequestID || previewVideoStatus === 'start') && <Spinner />}
      </div>
    </div>
  );
}
