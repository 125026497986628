import React from 'react';
import { Modal, Form, Input, Row, Col, Select } from 'antd';

class ABTestForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };
  appChange = (appID) => {
    this.props.handleSetAppID(appID);
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const { abTest, releases } = this.props;
    if (abTest) {
      this.appChange(abTest.app_id);
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={[8, 8]}>
          <Col span={16}>
            <Form.Item label="App name">
              {getFieldDecorator('app_name', {
                rules: [
                  {
                    required: true,
                    message: 'App name',
                  },
                ],
                initialValue: abTest ? abTest.app_name : '',
              })(
                <Select
                  showSearch
                  placeholder="app name"
                  filterOption={(input, option) =>
                    option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={this.appChange}
                >
                  {this.props.apps.map((app) => (
                    <Select.Option key={app.id} value={app.id}>
                      {app.app_name}({app.package_name})
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Internal name">
              {getFieldDecorator('internal_name', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter internal_name',
                  },
                ],
                initialValue: abTest ? abTest.internal_name : '',
              })(<Input placeholder="Please enter internal_name" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Start Release">
              {getFieldDecorator('release', {
                rules: [
                  {
                    required: true,
                    message: '',
                  },
                ],
                initialValue: releases.length > 0 && abTest ? abTest.start_release_id : null,
              })(
                <Select placeholder="release name">
                  <Select.Option key="0" value={null}>
                    null
                  </Select.Option>
                  {releases.map((release) => (
                    <Select.Option key={release.id} value={release.id}>
                      {release.release_name} - {release.version_code} - {release.release_date}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Source">
              {getFieldDecorator('source', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter s',
                  },
                ],
                initialValue: abTest ? abTest.source : 'manual',
              })(<Input placeholder="Please enter source" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Where">
              {getFieldDecorator('text_where', {
                rules: [
                  {
                    required: false,
                    message: '',
                  },
                ],
                initialValue: abTest ? abTest.text_where : '',
              })(<Input placeholder="Where?" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="What">
              {getFieldDecorator('text_what', {
                rules: [
                  {
                    required: false,
                    message: '',
                  },
                ],
                initialValue: abTest ? abTest.text_what : '',
              })(<Input placeholder="What?" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Comment">
              {getFieldDecorator('text_comment', {
                rules: [
                  {
                    required: false,
                    message: '',
                  },
                ],
                initialValue: abTest ? abTest.text_comment : '',
              })(<Input placeholder="Your comment here..." />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedABTestCreationForm = Form.create({ name: 'abTest_form' })(ABTestForm);

export default function ABTestsModal({ visible, handleHide, handleSubmit, abTest, apps, releases, handleSetAppID }) {
  let formRef = null;

  return (
    <Modal
      title={abTest ? `Change ab test #${abTest.id}` : 'Add new ab test'}
      centered
      visible={visible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }

            handleSubmit({ ...abTest, ...values, id: abTest ? abTest.id : undefined });

            if (abTest) {
              formRef.props.form.resetFields();
            }
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
        if (abTest) {
          formRef.props.form.resetFields();
        }
      }}
    >
      <WrappedABTestCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        abTest={abTest}
        apps={apps}
        releases={releases}
        handleSetAppID={handleSetAppID}
      />
    </Modal>
  );
}
