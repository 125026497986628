import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { withRouter } from 'react-router-dom';
import { updInsightByID } from '../../../../api/insightsAPI';
import { PLATFORMS } from '../../../../constants/platforms';
import AppSelector from '../../AppSelector';
import './UpdateAppModal.scss';

const UpdateAppModalBase = ({ insight, curApp, history, ...rest }) => {
  const [currentSelectedApp, setCurrentSelectedApp] = useState(curApp);
  const updateInsightMutation = useMutation(updInsightByID);

  const handleAppSelected = (app) => {
    setCurrentSelectedApp(app);
  };

  const onSubmit = async () => {
    await updateInsightMutation.mutateAsync({
      insight_id: insight.id,
      data: { app_id: currentSelectedApp.id },
    });
    history.push(`/marketing/${currentSelectedApp.id}/${insight.id}`);
    window.location.reload();
  };

  return (
    <Modal
      title="Update App"
      footer={[
        <Button key="back" onClick={rest.onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={updateInsightMutation.isLoading} onClick={onSubmit}>
          Save
        </Button>,
      ]}
      {...rest}
    >
      <div className="update-app-modal-container">
        <AppSelector
          isMarketing
          allowedPlatforms={PLATFORMS.marketing}
          curApp={currentSelectedApp}
          onAppSelected={handleAppSelected}
        />
      </div>
    </Modal>
  );
};

export const UpdateAppModal = withRouter(UpdateAppModalBase);
