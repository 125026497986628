/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Button, Modal, Select, Table, Typography } from 'antd';
import ReleasesDropdown from './releasesDropdown/ReleasesDropdown';
import ReleasesTable from './releasesTable/ReleasesTable';
import { getAllApps } from '../../redux/actions/usersPage';

import {
  getReleases,
  addRelease,
  updRelease,
  delRelease,
  refreshPresignedUrl,
  getReportLink,
} from '../../redux/actions/appsReleasesPage';
import ReleaseModal from './forms/ReleaseModal';
import { DEFAULT_PLATFORM } from '../../constants/platforms';
import AnalysisIssues from './AnalysisIssues';

class Releases extends Component {
  constructor(props) {
    super(props);

    this.state = {
      appId: null,
      showReleaseAddModal: false,
      editRelease: null,
      curAnalysisIssuesReleaseId: null,
    };
    if (this.props.location && this.props.location.search) {
      const { appId, platform } = queryString.parse(this.props.location.search);
      this.state.appId = Number(appId);
      this.defaultPlatform = platform;
    }
  }

  componentDidMount = () => {
    this.props.getAllApps(this.defaultPlatform || DEFAULT_PLATFORM);
    this.props.getReleases(this.state.appId);
  };

  handleReleaseAddSubmit = (release) => {
    console.log('create release:', release);
    this.props.addRelease(release);
  };

  handleReleaseEditSubmit = (releaseID, data) => {
    console.log('update release:', releaseID, data);
    this.props.updRelease(releaseID, data);
  };

  handleEditRelease = (release) => {
    console.log('edit release', release);
    this.props.refreshPresignedUrl();
    this.setState({ editRelease: release });
  };

  handleDeleteRelease = (releaseID) => {
    console.log('delete release', releaseID);
    this.props.delRelease(releaseID);
  };

  handleDownloadReports = (releaseID, reportDir) => {
    console.log('download release reports', releaseID, reportDir);
    this.props.getReportLink(releaseID, reportDir);
  };

  changePlatform = (value, option) => {
    this.props.getAllApps(value);
  };

  render() {
    const { allApps, appsStatus, status, insights, user } = this.props;
    const { curAnalysisIssuesReleaseId } = this.state;
    if (allApps.length === 0) {
      return <div className="users">{appsStatus === '' ? 'Loading...' : appsStatus}</div>;
    }

    const currApp = allApps.find((app) => app.id === Number(this.state.appId));
    const curAnalysisIssuesRelease = this.props.releases.find((x) => x.id === curAnalysisIssuesReleaseId);

    return (
      <div className="releases">
        {this.state.showReleaseAddModal && (
          <ReleaseModal
            handleHide={() => this.setState({ showReleaseAddModal: false })}
            handleSubmit={this.handleReleaseAddSubmit}
            appId={this.state.appId}
            releases={this.props.releases}
            isLoading={this.props.isRefreshingPresignedUrl}
            presignedUrl={this.props.presignedUrl}
            insights={insights}
            isAdmin={user.isAdmin}
          />
        )}
        {this.state.editRelease && (
          <ReleaseModal
            handleHide={() => this.setState({ editRelease: null })}
            handleSubmit={this.handleReleaseEditSubmit}
            appId={this.state.appId}
            release={this.state.editRelease}
            releases={this.props.releases}
            isLoading={this.props.isRefreshingPresignedUrl}
            presignedUrl={this.props.presignedUrl}
            insights={insights.filter((insight) => insight.current_release_id === this.state.editRelease.id)}
            isAdmin={user.isAdmin}
          />
        )}
        {curAnalysisIssuesReleaseId && (
          <Modal
            title={`Release #${curAnalysisIssuesReleaseId}: Analysis Issues`}
            centered
            width="90%"
            visible
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => this.setState({ curAnalysisIssuesReleaseId: null })}
            onCancel={() => this.setState({ curAnalysisIssuesReleaseId: null })}
          >
            <AnalysisIssues
              releaseId={curAnalysisIssuesReleaseId}
              analysisIssues={curAnalysisIssuesRelease.analysis_issues}
            />
          </Modal>
        )}
        {this.state.appId &&
          (currApp ? (
            <h2>
              #{currApp.id} {currApp.name}({currApp.package})
            </h2>
          ) : (
            <h2>app #{this.state.appId}</h2>
          ))}
        <div className="releases__input">
          <ReleasesDropdown
            changePlatform={this.changePlatform}
            selectedAppPackage={currApp && currApp.name}
            onAppChange={(appId) => this.setState({ appId })}
            platform={this.defaultPlatform}
          />
          <div className="releases__submit">
            <p className="releases__submit__msg">{status}</p>
          </div>
          <Button
            disabled={!this.state.appId || !user.isAdmin}
            type="primary"
            loading={this.props.isApiRequestLoading}
            onClick={() => {
              this.props.refreshPresignedUrl();
              this.setState({ showReleaseAddModal: true });
            }}
          >
            Add New Release
          </Button>
        </div>

        <ReleasesTable
          currApp={currApp}
          releases={this.props.releases}
          onEditRelease={this.handleEditRelease}
          onDeleteRelease={this.handleDeleteRelease}
          isLoading={this.props.isApiRequestLoading}
          onDownloadReports={this.handleDownloadReports}
          reportDownloadUrls={this.props.reportDownloadUrls}
          updateReleaseHandle={this.handleReleaseEditSubmit}
          showAnalysisIssues={(releaseId) => this.setState({ curAnalysisIssuesReleaseId: releaseId })}
          isAdmin={user.isAdmin}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ usersPage, appsReleasesPage, insightPage }) => ({
  allApps: usersPage.allApps,
  appsStatus: usersPage.status,
  user: usersPage.user,
  releases: appsReleasesPage.releases,
  status: appsReleasesPage.status,
  isApiRequestLoading: appsReleasesPage.isApiRequestLoading,
  isRefreshingPresignedUrl: appsReleasesPage.isRefreshingPresignedUrl,
  presignedUrl: appsReleasesPage.presignedUrl,
  reportDownloadUrls: appsReleasesPage.reportDownloadUrls,
  insights: insightPage.insights,
});

export default connect(mapStateToProps, {
  getAllApps,
  getReleases,
  addRelease,
  updRelease,
  delRelease,
  refreshPresignedUrl,
  getReportLink,
})(Releases);
