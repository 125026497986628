export const DiffFilterTypes = {
  Strings: 'strings',
  Layouts: 'layouts',
};

const filterStringsDiff = (searchString, stringsDiff) => {
  if (!searchString) return;

  const result = {};

  if (Array.isArray(stringsDiff.added)) {
    result.added = stringsDiff.added.filter((addedString) =>
      JSON.stringify(addedString).toLowerCase().includes(searchString)
    );
  }

  if (Array.isArray(stringsDiff.removed)) {
    result.removed = stringsDiff.removed.filter((removedString) =>
      JSON.stringify(removedString).toLowerCase().includes(searchString)
    );
  }

  if (Array.isArray(stringsDiff.changed)) {
    result.changed = stringsDiff.changed.filter((changedString) =>
      JSON.stringify(changedString).toLowerCase().includes(searchString)
    );
  }

  if (result.added && !result.added.length) delete result.added;
  if (result.changed && !result.changed.length) delete result.changed;
  if (result.removed && !result.removed.length) delete result.removed;

  return result;
};

const filterLayoutsDiff = (searchString, layoutsDiff) => {
  if (!searchString) return;

  const result = {};

  if (Array.isArray(layoutsDiff.added)) {
    result.added = layoutsDiff.added.filter((item) => item.toLowerCase().includes(searchString));
  }

  if (Array.isArray(layoutsDiff.removed)) {
    result.removed = layoutsDiff.removed.filter((item) => item.toLowerCase().includes(searchString));
  }

  if (Array.isArray(layoutsDiff.changed)) {
    result.changed = layoutsDiff.changed.filter((item) => item.toLowerCase().includes(searchString));
  }

  if (result.added && !result.added.length) delete result.added;
  if (result.changed && !result.changed.length) delete result.changed;
  if (result.removed && !result.removed.length) delete result.removed;

  return result;
};

export const filterDiff = (searchString, diffObject) => {
  if (!searchString) return;

  const filteredResult = {
    diff: {},
  };

  Object.entries(diffObject.diff).forEach(([key, value]) => {
    switch (key) {
      case DiffFilterTypes.Strings:
        filteredResult.diff[key] = filterStringsDiff(searchString, value);
        break;
      case DiffFilterTypes.Layouts:
        filteredResult.diff[key] = filterLayoutsDiff(searchString, value);
        break;
      default:
        break;
    }
  });

  return filteredResult;
};
