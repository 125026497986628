import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import PersonasTab from './personasTab/Tab';
import SettingsTab from './settingsTab/Tab';

export const GENDER = ['male', 'female', 'unspecified'];

const { TabPane } = Tabs;

const Personas = ({ user }) => (
  <Tabs type="card">
    <TabPane tab="Personas" key="1">
      <PersonasTab isAdmin={user.isAdmin} />
    </TabPane>
    {user.isAdmin && (
      <TabPane tab="Settings" key="2">
        <SettingsTab />
      </TabPane>
    )}
  </Tabs>
);

const mapStateToProps = ({ usersPage }) => ({
  user: usersPage.user,
});

export default connect(mapStateToProps, () => ({}))(Personas);
