import { addInsightTimelineAPI, updInsightTimelineAPI, delInsightTimelineAPI } from '../actions/insightPage';
import * as AT from '../actionTypes';

const insightTimelineMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.ADD_INSIGHT_TIMELINE: {
        dispatch(addInsightTimelineAPI(action.payload.insightId, action.payload.data));
        return next(action);
      }
      case AT.UPD_INSIGHT_TIMELINE: {
        dispatch(updInsightTimelineAPI(action.payload.insightId, action.payload.timelineId, action.payload.data));
        return next(action);
      }
      case AT.DEL_INSIGHT_TIMELINE: {
        dispatch(delInsightTimelineAPI(action.payload.insightId, action.payload.timelineId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default insightTimelineMiddleware;
