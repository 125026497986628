import React, { useRef } from 'react';
import { Modal, Form, Input, Row, Col, Slider, Icon, Button, Popconfirm } from 'antd';
import moment from 'moment';

class CreateReportGraph extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { complaints, deleteComplaintHandle } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        {complaints.length > 0 && complaints[0].created_at && (
          <div className="graph-complaint-date">
            <strong>Created:</strong>
            {moment(complaints[0].created_at).format('ll')}
          </div>
        )}
        <Row gutter={[8, 8]}>
          <Col span={complaints.length === 0 ? 24 : 22}>
            <Form.Item label="Complaint">
              {getFieldDecorator('description', {
                initialValue: complaints.length > 0 ? complaints[0].description : '',
              })(<Input.TextArea placeholder="Graph report" />)}
            </Form.Item>
          </Col>
          {complaints.length > 0 && (
            <Col span={2} style={{ marginTop: '3rem' }}>
              <Popconfirm
                title="Are you sure?"
                icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                onConfirm={deleteComplaintHandle}
              >
                <Button type="danger small" icon="delete" />
              </Popconfirm>
            </Col>
          )}
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Graph rank">
              {getFieldDecorator('graph_rank', {
                initialValue: complaints.length > 0 ? complaints[0].graph_rank : 4,
              })(<Slider tooltipVisible placeholder="Graph report" min={1} max={5} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(CreateReportGraph);

export default ({ createReport, deleteComplaintHandle, onHide, complaints }) => {
  let formRef = useRef(null);

  return (
    <Modal
      title="Complaints"
      visible
      style={{ top: 20 }}
      className="graph-compaints-modal"
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            const newValues = { ...values };
            if (newValues.description) {
              createReport(newValues);
            }
            formRef.props.form.resetFields();
            onHide();
          });
        }
      }}
      onCancel={() => {
        onHide();
      }}
    >
      {complaints.length === 0 && <h3>Add Complaint</h3>}
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        complaints={complaints}
        deleteComplaintHandle={() => deleteComplaintHandle(complaints[0].id)}
      />
    </Modal>
  );
};
