import React, { useEffect, useState } from 'react';
import { Circle, Image, Layer, Stage } from 'react-konva';
import { UJ_ACTION_TYPES } from '../../../../utils/consts';
import { Rectangle } from '../../../canvasedDeviceViewer/Rectangle';

const CANVAS_WORKBENCH_WIDTH = 140;
const CANVAS_WORKBENCH_HEIGHT = 310;

const UJ_TAP_RADIUS = 15;

const normalizeX = (x, imageWidth, canvasWidth) => Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y, imageHeight, canvasHeight) => Math.round((canvasHeight * y) / imageHeight);

const normalizeRect = (rect, image, canvasWidth = CANVAS_WORKBENCH_WIDTH, canvasHeight = CANVAS_WORKBENCH_HEIGHT) => {
  const top = normalizeY(rect.top, image.height, canvasHeight);
  const bottom = normalizeY(rect.bottom, image.height, canvasHeight);
  const left = normalizeX(rect.left, image.width, canvasWidth);
  const right = normalizeX(rect.right, image.width, canvasWidth);

  const height = Math.abs(bottom - top);
  const width = Math.abs(right - left);

  return {
    y: top,
    x: left,
    height,
    width,
    type: rect.type,
  };
};

export const CanvasedImagePreview = ({
  imageSrc,
  rects,
  ujAction,
  canvasWidth = CANVAS_WORKBENCH_WIDTH,
  canvasHeight = CANVAS_WORKBENCH_HEIGHT,
}) => {
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    const image = new window.Image();
    image.src = imageSrc;
    image.onload = () => {
      setImagePreview(image);
    };
  }, [imageSrc]);

  return (
    <div>
      <div className="canvased-image__canvas">
        <Stage width={canvasWidth} height={canvasHeight}>
          <Layer>
            {imagePreview && (
              <React.Fragment>
                <Image image={imagePreview} name="image" width={canvasWidth} height={canvasHeight} />
                {rects.map((rect) => (
                  <Rectangle
                    {...normalizeRect(rect, imagePreview, canvasWidth, canvasHeight)}
                    name="rect"
                    key={rect.key}
                  />
                ))}
                {ujAction && ujAction.type === UJ_ACTION_TYPES.Click && (
                  <Circle
                    radius={UJ_TAP_RADIUS}
                    stroke="#f00000"
                    fill="rgba(233, 7, 7, 0.54)"
                    opacity={0.8}
                    strokeWidth={3}
                    x={normalizeX(ujAction.startX, imagePreview.width, canvasWidth)}
                    y={normalizeY(ujAction.startY, imagePreview.height, canvasHeight)}
                  />
                )}
              </React.Fragment>
            )}
          </Layer>
        </Stage>
      </div>
    </div>
  );
};
