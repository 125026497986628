import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  devices: [],
  deviceModels: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_DEVICES: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.payload.devices,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_DEVICES_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case actionTypes.UPD_DEVICE: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_DEVICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        devices: state.devices.map((x) => (x.id === action.payload.data.id ? action.payload.data : x)),
      };
    }
    case actionTypes.ADD_DEVICE: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_DEVICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        devices: [action.payload.data, ...state.devices],
      };
    }
    case actionTypes.DEL_DEVICE: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_DEVICE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        devices: state.devices.map((x) => (x.id === action.payload.deviceId ? { ...x, _deleted: true } : x)),
      };
    }
    case actionTypes.GET_DEVICE_MODELS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_DEVICE_MODELS_SUCCESS: {
      return {
        ...state,
        deviceModels: action.payload.deviceModels,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_DEVICE_MODELS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }
    case actionTypes.ADD_DEVICE_MODEL: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_DEVICE_MODEL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        deviceModels: [action.payload.data, ...state.deviceModels],
      };
    }
    case actionTypes.UPD_DEVICE_MODEL: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_DEVICE_MODEL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        deviceModels: state.deviceModels.map((x) => (x.id === action.payload.data.id ? action.payload.data : x)),
      };
    }
    case actionTypes.DEL_DEVICE_MODEL: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_DEVICE_MODEL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        deviceModels: state.deviceModels.map((x) =>
          x.id === action.payload.deviceModelId ? { ...x, _deleted: true } : x
        ),
      };
    }

    default: {
      return state;
    }
  }
}
