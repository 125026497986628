import { getAllPossibleInsightsByEmailsAPI } from '../actions/possibleInsightsByEmails';
import * as AT from '../actionTypes';

const possibleInsightsByEmailsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_POSSIBLE_INSIGHTS_BY_EMAILS: {
        dispatch(getAllPossibleInsightsByEmailsAPI());
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default possibleInsightsByEmailsMiddleware;
