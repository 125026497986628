import React from 'react';
import { Button, Checkbox, Icon, Popconfirm, Table } from 'antd';

export default ({
  variants,
  releaseId,
  isLoading,
  onVariantEdit,
  onAssetsEdit,
  onReleaseAdd,
  onReleaseRemove,
  isDisabled,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Release Link',
      dataIndex: 'releases',
      render: (value, record) => (
        <div
          style={isDisabled ? { pointerEvents: 'none' } : null}
          title={`related with releases: ${value.map((x) => `${x.id}-${x.name}-${x.version_code}`).join()}`}
        >
          <Popconfirm
            title="Variant will be deleted because no longer associated with AB test and release"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() =>
              (!value.map((x) => x.id).includes(releaseId) ? onReleaseAdd : onReleaseRemove)(record.id, releaseId)
            }
            disabled={!value.map((x) => x.id).includes(releaseId)}
          >
            <Checkbox
              checked={value.map((x) => x.id).includes(releaseId)}
              onChange={
                !value.map((x) => x.id).includes(releaseId) ? () => onReleaseAdd(record.id, releaseId) : undefined
              }
            />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Control',
      dataIndex: 'control',
      render: (value, record) => <div title={`variant #${record.id}`}>{value ? 'Yes' : 'No'}</div>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'Assets',
      dataIndex: 'assets',
      render: (value, record) => (
        <div
          style={{
            display: 'flex',
            width: 'max-content',
          }}
        >
          <ul>
            <li>images: {value.filter((x) => x.type === 'image').length}</li>
            <li>video: {value.filter((x) => x.type === 'video').length}</li>
          </ul>
          <Button style={{ margin: '4px' }} type="small" icon="menu" onClick={() => onAssetsEdit(record.id)} />
        </div>
      ),
    },
    {
      title: '',
      key: 'actions',
      render: (value, record) => (
        <div style={isDisabled ? { pointerEvents: 'none' } : null}>
          <Button style={{ margin: '4px' }} type="small" icon="edit" onClick={() => onVariantEdit(record.id)}>
            Edit Variant
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={variants}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
    </div>
  );
};
