import { backendApiRequest } from './request';

export const getUserFlows = async () =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/user-flows`,
  });

export const createUserFlow = async ({ name, parent_id }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/user-flows`,
    body: {
      name,
      parent_id,
    },
  });

export const updateUserFlow = async ({ user_flow_id, name }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/user-flows/${user_flow_id}`,
    body: {
      name,
    },
  });

export const deleteUserFlow = async ({ user_flow_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/user-flows/${user_flow_id}`,
  });

export const createUserFlowSet = async ({ user_flow_id, persona_id, release_id, is_visible }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/user-flows/${user_flow_id}/sets`,
    body: {
      user_flow_id,
      persona_id,
      release_id,
      is_visible,
    },
  });

export const updateUserFlowSet = async ({ set_id, persona_id, user_flow_id, release_id, is_visible }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/user-flows/sets/${set_id}`,
    body: {
      persona_id,
      user_flow_id,
      release_id,
      is_visible,
    },
  });

export const deleteUserFlowSet = async ({ set_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/user-flows/sets/${set_id}`,
  });

export const createUserFlowSetAssets = async ({ set_id, assets }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/user-flows/sets/${set_id}/assets`,
    body: { assets },
  });

export const updateUserFlowSetAssets = async ({ asset_id, rects, text, is_hidden, orientation }) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/user-flows/sets/assets/${asset_id}`,
    body: { rects, text, is_hidden, orientation },
  });

export const deleteUserFlowSetAsset = async (asset_id) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/user-flows/sets/assets/${asset_id}`,
  });

export const getUserFlowSets = async (app_id) =>
  backendApiRequest({
    method: 'GET',
    path: `/admin/user-flows/sets`,
    params: {
      app_id,
    },
  });

export const updateUserFlowSetAssetIndex = async (assets) =>
  backendApiRequest({
    method: 'PUT',
    path: `/admin/insights/assets/order-indexes`,
    body: { assets: assets.map(({ id, order_index }) => ({ id, order_index })) },
  });

export const addLinkedInsight = async ({ asset_id, insight_id }) =>
  backendApiRequest({
    method: 'POST',
    path: `/admin/user-flows/sets/assets/${asset_id}/insights`,
    body: {
      insight_id,
    },
  });

export const deleteLinkedInsight = async ({ asset_id, insight_id }) =>
  backendApiRequest({
    method: 'DELETE',
    path: `/admin/user-flows/sets/assets/${asset_id}/insights/${insight_id}`,
  });
