import { message } from 'antd';

export const showMessageByVersionDiff = (feVersion, beVersion) => {
  const [aMajor, aMinor, aBugfix] = feVersion.split('.');
  const [bMajor, bMinor, bBugfix] = beVersion.split('.');

  if (aMajor < bMajor || aMinor < bMinor || aBugfix < bBugfix)
    message.error('Front end version is not up to date with the services version, consider updating the front end', 0);

  if (bMajor < aMajor)
    message.error(
      `Breaking changes on the services,
    current version: ${feVersion} | up to date version: ${beVersion} |
    you have to update them (use the command 'workbench --update')`,
      0
    );

  if (bMinor < aMinor)
    message.info(`New feature added on the services, consider updating (use the command 'workbench --update') `, 0);

  if (bBugfix < aBugfix)
    message.info(`New bugfixes added on the services, consider updating (use the command 'workbench --update') `, 0);
};

export const replaceWbHistoryParams = (params, history) => {
  // if param exists replace it if not append it
  const newParams = new URLSearchParams(params || '');
  const curParams = new URLSearchParams(history.location.search || '');

  for (const [key, value] of newParams.entries()) {
    curParams.set(key, value);
  }

  history.replace(`/workbench?${curParams.toString() || ''}`);
};

export const isPointInsideRect = (x, y, left, top, right, bottom) => {
  if (x > left && x < right && y > top && y < bottom) {
    return true;
  } else {
    return false;
  }
};

export const compareVersions = (verA, verB) =>
  verA.localeCompare(verB, undefined, { numeric: true, sensitivity: 'base' });

export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
