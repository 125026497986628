import { createAction } from 'redux-actions';
import { REMOTE_OPTIONS } from '../../components/workbench/utils/consts';

const initState = {
  config: REMOTE_OPTIONS[0],
};

const WbActions = {
  CHANGE_CONFIG: 'CHANGE_CONFIG',
};

export const changeWbConfig = createAction(
  WbActions.CHANGE_CONFIG,
  ({ videoSocketUrlLeft, videoSocketUrlRight, deviceServicesHostUrl, wsUrl }) => ({
    videoSocketUrlLeft,
    videoSocketUrlRight,
    deviceServicesHostUrl,
    wsUrl,
  })
);

export const workbenchReducer = (state = initState, action) => {
  switch (action.type) {
    case WbActions.CHANGE_CONFIG:
      return {
        ...state,
        config: action.payload,
      };
    default:
      return state;
  }
};
