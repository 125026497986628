/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import { Modal, Form, Input, Checkbox, Select } from 'antd';

class VerificationFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appSearchQuery: '',
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { verificationFilters } = this.props;

    const curAppId = getFieldValue('app_id');
    const curOrchId = getFieldValue('orchestrator_id');

    const existingDeviceIds = verificationFilters
      .filter((x) => x.app.id === curAppId && x.orchestrator.id === curOrchId)
      .map((x) => x.device_model.id);

    const filteredApps = this.state.appSearchQuery.length
      ? this.props.apps.filter((x) => x.package_name.includes(this.state.appSearchQuery)).slice(0, 100)
      : [];

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label="App">
          {getFieldDecorator('app_id', {
            rules: [
              {
                required: true,
              },
            ],
          })(
            <Select
              showSearch
              filterOption={false}
              onSearch={(appSearchQuery) => this.setState({ appSearchQuery })}
              placeholder="App"
            >
              {filteredApps.map((app) => (
                <Select.Option value={app.id} key={app.id}>
                  {app.app_name}({app.package_name}) - {app.platform}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Orchestrator">
          {getFieldDecorator('orchestrator_id', {
            rules: [{ required: true }],
          })(
            <Select
              placeholder="Orchestrator"
              disabled={!curAppId || !!(getFieldValue('device_model_ids') || []).length}
              showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
            >
              {this.props.orchestrators.map((orchestrator) => (
                <Select.Option value={orchestrator.id} key={orchestrator.id}>
                  {orchestrator.hostname}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Device Model">
          {getFieldDecorator('device_model_ids', {
            rules: [
              {
                required: true,
              },
            ],
          })(
            <Select
              disabled={!curAppId || !curOrchId}
              filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
              placeholder="Device Model"
              mode="multiple"
              allowClear
            >
              {_.sortBy(this.props.deviceModels, (x) => x.model).map((deviceModel) => (
                <Select.Option
                  value={deviceModel.id}
                  key={deviceModel.id}
                  disabled={existingDeviceIds.includes(deviceModel.id)}
                >
                  {deviceModel.model}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('ignore_deviation', {
            rules: [{ required: true }],
            initialValue: true,
          })(<Checkbox defaultChecked>Ignore Deviation</Checkbox>)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('ignore_new_version', {
            rules: [{ required: true }],
            initialValue: true,
          })(<Checkbox defaultChecked>Ignore New Version</Checkbox>)}
        </Form.Item>
        <Form.Item label="Reason">
          {getFieldDecorator('reason', {
            rules: [],
            initialValue: '',
          })(<Input.TextArea></Input.TextArea>)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(VerificationFilterForm);

export default function VerificationFilterModal({
  handleHide,
  handleSumit,
  apps,
  deviceModels,
  orchestrators,
  verificationFilters,
}) {
  let formRef = null;

  return (
    <Modal
      title="Create new verification filter"
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            handleSumit(values);
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        apps={apps}
        deviceModels={deviceModels}
        orchestrators={orchestrators}
        verificationFilters={verificationFilters}
      />
    </Modal>
  );
}
