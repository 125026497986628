import { Select } from 'antd';
import React, { useState } from 'react';

export const SelectCustomRelease = ({
  customAppReleases,
  setAppVer,
  deviceAppVer,
  curAppPackage,
  setDiff,
  setProgress,
}) => {
  const [search, setSearch] = useState('');

  const filteredAppReleases = customAppReleases.filter((release) => release.release_name.includes(search));

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleSelect = (releaseName) => {
    const release = filteredAppReleases.find((release) => release.release_name === releaseName);

    if (!release) return;

    setDiff(null);
    setProgress(null);
    setAppVer({
      package: curAppPackage,
      version_code: release.version_code.toString(),
      version_name: release.release_name,
    });
  };

  return (
    <Select
      showSearch
      size="small"
      filterOption={false}
      placeholder="Version..."
      defaultActiveFirstOption={false}
      onSearch={handleSearch}
      onChange={handleSelect}
      value={deviceAppVer ? deviceAppVer.version_name : ''}
      style={{ width: 150, border: '1px solid gray', borderRadius: '5px' }}
    >
      {filteredAppReleases.map((release) => (
        <Select.Option value={release.release_name} key={release.release_name}>
          {release.release_name}
        </Select.Option>
      ))}
    </Select>
  );
};
