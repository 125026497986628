import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import Nav from './common/Nav';
import InsightPageTabs from './insight/InsightPageTabs';
import AbTests from './abTest/AbTests';
import Users from './users/Users';
import UserGroups from './user_groups/UserGroups';
import Apps from './apps/Apps';
import Releases from './releases/Releases';
import CurrentToken from './currentToken/CurrentToken';
import InsightImportance from './insightImportance/InsightImportance';
import AnnotationVerification from './annotationVerification/AnnotationVerification';
import Devices from './devices/Devices';
import Products from './products/Products';
import Personas from './personas/Personas';
import { Route, Switch, Redirect, withRouter } from 'react-router';
import Notifications from './notifications/Notifications';
import Reports from './reports/Reports';
import { setUser } from '../redux/actions/usersPage';

import UIViewer from './UIViewer/UIViewer';
import GraphBuilder from './GraphBuilder/GraphBuilder';
import GraphMapping from './graphMapping/GraphMapping';
import DiffTabs from './VersionsDiff/DiffTabs';

import { getCurUserDetails } from '../redux/actions/login';
import { WorkbenchPathWithParams } from './workbench_v2/utils/consts';

const Home = ({
  curAuthUser,
  setCurAuthUser,

  getCurUserDetails,
}) => {
  const renderRoute = ({ url, path, component }) => {
    if (!path) path = url;
    return <Route path={`/${path}`} component={component} key={url} />;
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser({
      // Optional, By default is false.
      // If set to true, this call will send a request to Cognito to get the latest user data
      bypassCache: false,
    })
      .then((user) => {
        if (
          !(user.signInUserSession.idToken.payload['cognito:groups'] || []).some((x) =>
            ['Admin', 'Annotator_Admin'].includes(x)
          )
        ) {
          Auth.signOut({ global: true })
            .then((data) => console.log(data))
            .catch((err) => console.log(err));
        }
        setCurAuthUser({
          reviewer: user.signInUserSession.idToken.payload['cognito:groups'].includes('Reviewer'),
          isAnnotatorAdmin: user.signInUserSession.idToken.payload['cognito:groups'].includes('Annotator_Admin'),
          isAdmin: user.signInUserSession.idToken.payload['cognito:groups'].includes('Admin'),
          email: user.attributes.email,
        });

        getCurUserDetails();
      })
      .catch((err) => console.log(err));
  }, []);

  let types = [
    {
      label: 'Workbench2',
      url: WorkbenchPathWithParams,
      component: InsightPageTabs,
      hideNav: true,
    },
    {
      label: 'Workbench',
      url: 'workbench',
      component: InsightPageTabs,
      hideNav: true,
    },
    {
      label: 'User Journeys',
      url: 'user-journeys',
      component: InsightPageTabs,
      hideNav: true,
    },
    { label: 'Insights', url: 'insights', component: InsightPageTabs },
    {
      label: 'Intelligence',
      url: 'intelligence',
      component: InsightPageTabs,
      hideNav: true,
    },
    {
      label: 'Marketing',
      url: 'marketing',
      component: InsightPageTabs,
      hideNav: true,
    },
    {
      label: 'A/B Tests',
      url: 'ab-tests',
      component: AbTests,
    },
    {
      label: 'Users',
      url: 'users',
      component: Users,
    },
    {
      label: 'Devices',
      url: 'devices',
      component: Devices,
    },
    {
      label: 'Organizations',
      url: 'user_groups',
      component: UserGroups,
    },
    {
      label: 'Personas',
      url: 'personas',
      component: Personas,
    },
    {
      label: 'Products',
      url: 'products',
      component: Products,
    },
    {
      label: 'Apps',
      url: 'apps',
      component: Apps,
    },
    {
      label: 'Releases',
      url: 'releases',
      component: Releases,
    },
    {
      label: 'Current User Token',
      url: 'current-token',
      component: CurrentToken,
    },
    // {
    //   label: 'Email Sender', url: 'email-json', component: EmailJSON,
    // },
    {
      label: 'Insight Importance',
      url: 'insight-importance',
      component: InsightImportance,
    },
    {
      label: 'Annotation Verification',
      url: 'annotation-verification',
      component: AnnotationVerification,
    },
    {
      label: 'Notifications',
      url: 'notifications',
      component: Notifications,
    },
    {
      label: 'Reports',
      url: 'reports',
      component: Reports,
    },
    {
      label: 'App Graph',
      url: 'ui-viewer',
      component: UIViewer,
    },
    {
      label: 'Graph Builder',
      url: 'graph-builder',
      component: GraphBuilder,
    },
    {
      label: 'Graph Mapping',
      url: 'graph-mapping',
      component: GraphMapping,
    },
    {
      label: 'Versions Diff',
      url: 'versions-diff',
      component: DiffTabs,
    },
  ];

  if (curAuthUser.reviewer) {
    types = [
      { label: 'Insights', url: 'insights', component: InsightPageTabs },
      {
        label: 'Intelligence',
        url: 'intelligence',
        component: InsightPageTabs,
        hideNav: true,
      },
      {
        label: 'Marketing',
        url: 'marketing',
        component: InsightPageTabs,
        hideNav: true,
      },
    ];
  }

  if (!curAuthUser.isAdmin && curAuthUser.isAnnotatorAdmin) {
    types = types.filter((x) => ['Products', 'Releases', 'Personas', 'Insights', 'Marketing'].includes(x.label));
  }

  return (
    <div style={{ height: '100%' }} className="manager-home">
      <Nav types={types.filter((t) => !t.hideNav)} />
      <Switch>
        {types.map(renderRoute)}
        <Redirect to={types[0].url} />
      </Switch>
    </div>
  );
};

const mapStateToProps = ({ usersPage }) => ({
  curAuthUser: usersPage.user,
});

export default withRouter(
  connect(mapStateToProps, {
    setCurAuthUser: setUser,
    getCurUserDetails,
  })(Home)
);
