import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

class Nav extends Component {
  renderItem = ({ label, url }) => (
    <li className="nav__item" key={url}>
      <NavLink to={`/${url}`} activeClassName="nav__item-active">
        <span className="nav__item-label">{label}</span>
      </NavLink>
    </li>
  );

  render() {
    const { types } = this.props;
    return (
      <nav className="nav">
        <ul className="nav__list">{types.map(this.renderItem)}</ul>
      </nav>
    );
  }
}

export default withRouter(Nav);
