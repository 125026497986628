import React, { useState, useEffect } from 'react';
import { Alert, Button, message } from 'antd';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import {
  getCTAsForInsight,
  createCTAForInsight,
  setCTAForInsight,
  remCTAForInsight,
} from '../../../../api/insightsAPI';

import CtasTable from './CtasTable';
import CtaModal from './CtaModal';

const InsightCtas = ({ insight }) => {
  const [ctas, ctasChange] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [curEditCtaId, setCurEditCtaId] = useState(null);

  const { data, isLoading, error, status } = useQuery(['InsightCtas', insight ? insight.id : null], () =>
    getCTAsForInsight(insight.id)
  );
  useEffect(() => {
    if (data && data.data) {
      ctasChange(data.data);
    }
  }, [status]);

  const addCTAMutation = useMutation(createCTAForInsight, {
    onSuccess: (data) => {
      ctasChange([...ctas, data.data]);
      message.destroy();
      message.success('CTA was added');
    },
  });
  const updCTAMutation = useMutation(setCTAForInsight, {
    onSuccess: (data, variables) => {
      ctasChange(ctas.map((x) => (x.id !== variables.cta_id ? x : data.data)));
      message.destroy();
      message.success('CTA was updated');
    },
  });
  const remCTAMutation = useMutation(remCTAForInsight, {
    onSuccess: (data, cta_id) => {
      ctasChange(ctas.filter((x) => x.id !== cta_id));
      message.destroy();
      message.success('CTA was deleted');
    },
  });

  const addCta = (data) => {
    addCTAMutation.mutateAsync({
      insight_id: insight.id,
      data,
    });
  };
  const updCta = (id, data) => {
    updCTAMutation.mutateAsync({
      cta_id: id,
      data,
    });
  };

  const delCta = (cta_id) => {
    remCTAMutation.mutateAsync(cta_id);
  };

  return (
    <div className="insight-manage-ctas">
      <h3 className="insight-data-item-title">CTA</h3>
      <Button
        onClick={() => {
          setIsModalVisible(true);
        }}
        className="add-something"
        icon="plus"
        style={{ marginBottom: 16 }}
        loading={isLoading}
      >
        Add CTA
      </Button>
      {error && (
        <Alert
          message={error.message}
          description={JSON.stringify(
            (error.response && error.response.body && error.response.body.error) || error.response.body.validation_error
          )}
          type="error"
          showIcon
        />
      )}
      {isModalVisible && (
        <CtaModal
          cta={ctas.find((x) => x.id === curEditCtaId)}
          onHide={() => {
            setIsModalVisible(false);
            setCurEditCtaId(null);
          }}
          onAddCta={addCta}
          onUpdCta={updCta}
        />
      )}
      <CtasTable
        isLoading={isLoading}
        ctas={ctas}
        onUpdCta={(ctaId) => {
          setIsModalVisible(true);
          setCurEditCtaId(ctaId);
        }}
        onDelCta={delCta}
      />
    </div>
  );
};

export default InsightCtas;
