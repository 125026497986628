import { Button, Icon, Tooltip } from 'antd';
import React, { useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { ActionTypes } from '../../../hooks/useVideoCanvasSocket';
import { UJ_ACTION_TYPES, VIEWS } from '../../../utils/consts';
import { isPointInsideRect } from '../../../utils/helpers';
import { Rectangle } from '../Rectangle';
import { TransformerComponent } from '../TranformerComponent';
import './VideoStreamCanvas.scss';
import { KeyEvent, KeyToCodeMap } from './keyEvents';

const RECT_CLICK_MARGIN = 10;

export const VideoStreamCanvas = ({
  canvasWidth,
  canvasHeight,
  deviceId,
  normalizedRects,
  sendMouseEvent,
  sendControlEvent,
  sendKeyEvent,
  videoCanvasContainerId,
  handleUjAction,
  ...rest
}) => {
  const [isInsideRect, setIsInsideRect] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [lastCoords, setLastCoords] = useState(null);

  const handleMouseDown = ({ evt }) => {
    const { layerX, layerY } = evt;
    setIsMouseDown(true);

    // double click
    if (evt.detail >= 2) {
      sendControlEvent(ActionTypes.ScreenOn);
    }

    if (
      normalizedRects.find(({ left, top, right, bottom }) =>
        isPointInsideRect(
          layerX,
          layerY,
          left - RECT_CLICK_MARGIN,
          top - RECT_CLICK_MARGIN,
          right + RECT_CLICK_MARGIN,
          bottom + RECT_CLICK_MARGIN
        )
      )
    ) {
      return setIsInsideRect(true);
    }

    setLastCoords({ x: layerX, y: layerY });
    sendMouseEvent(ActionTypes.MouseDown, layerX, layerY);
  };

  const handleMouseUp = ({ evt }) => {
    setIsMouseDown(false);

    if (isInsideRect) return setIsInsideRect(false);

    const { layerX, layerY } = evt;

    if (lastCoords) {
      const { x, y } = lastCoords;
      const deNormalizeX = (x) => Math.round((1080 * x) / canvasWidth);
      const deNormalizeY = (y) => Math.round((2400 * y) / canvasHeight);

      handleUjAction({
        type: x !== layerX || y !== layerY ? UJ_ACTION_TYPES.Swipe : UJ_ACTION_TYPES.Click,
        startX: deNormalizeX(x),
        startY: deNormalizeY(y),
        endX: deNormalizeX(layerX),
        endY: deNormalizeY(layerY),
      });

      setLastCoords(null);
    }

    sendMouseEvent(ActionTypes.MouseUp, layerX, layerY);
  };

  const handleMouseMove = ({ evt }) => {
    if (isInsideRect) return setIsInsideRect(false);
    if (!isMouseDown) return;

    const { layerX, layerY } = evt;

    sendMouseEvent(ActionTypes.MouseMove, layerX, layerY);
  };

  const handleMouseLeave = () => {
    if (!isMouseDown) return;
    setIsMouseDown(false);
    sendMouseEvent(ActionTypes.MouseUp, 0, 0);
  };

  const handleScreenOn = () => {
    sendControlEvent(ActionTypes.ScreenOn);
  };

  const handleKeyDown = (e) => {
    const keycode = KeyToCodeMap.get(e.nativeEvent.code);
    sendKeyEvent(ActionTypes.KeyDown, keycode);
  };

  const handleKeyUp = (e) => {
    const keycode = KeyToCodeMap.get(e.nativeEvent.code);

    if (keycode === undefined || keycode === null) return;

    sendKeyEvent(ActionTypes.KeyUp, keycode);
  };

  const handleControlClick = (keycode) => {
    return () => {
      sendKeyEvent(ActionTypes.KeyDown, keycode);
      sendKeyEvent(ActionTypes.KeyUp, keycode);
    };
  };

  return (
    <React.Fragment>
      <div id={videoCanvasContainerId} className="video-stream-container">
        <div
          className="canvased-image__canvas"
          id={rest.view === VIEWS.VIEW_LEFT ? 'left-canvas' : 'right-canvas'}
          onMouseLeave={handleMouseLeave}
          tabIndex={0}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        >
          <Stage
            width={canvasWidth}
            height={canvasHeight}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            <Layer>
              {Object.entries(rest.internalRects).map(([key, value]) => (
                <Rectangle
                  name="rect"
                  key={key}
                  onTransform={(newProps) => rest.handleRectChange(key, newProps)}
                  onMouseDown={(e) => rest.handleImageMouseDown(key, e)}
                  {...value}
                />
              ))}
              <TransformerComponent selectedRect={rest.selectedRect} />
            </Layer>
          </Stage>
        </div>
      </div>
      <div className="control-buttons-container">
        <div className="control-button" onClick={handleControlClick(KeyEvent.KEYCODE_APP_SWITCH)}>
          <Icon type="unordered-list" />
        </div>
        <div className="control-button" onClick={handleControlClick(KeyEvent.KEYCODE_HOME)}>
          <Icon type="minus-circle" />
        </div>
        <div className="control-button" onClick={handleControlClick(KeyEvent.KEYCODE_BACK)}>
          <Icon type="left" />
        </div>
      </div>
      <div className="toggle-button-container">
        <Tooltip placement="bottom" title="Turn Screen On">
          <Button shape="circle" icon="poweroff" onClick={handleScreenOn} />
        </Tooltip>
      </div>
    </React.Fragment>
  );
};
