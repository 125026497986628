import React, { useState } from 'react';
import { Button } from 'antd';
import ReactJson from 'react-json-view';

export default ({ json, title }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <React.Fragment>
      <Button onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? 'Hide' : 'Show'} {title}
      </Button>
      {isVisible && <ReactJson src={json} style={{ marginTop: '15px' }} />}
    </React.Fragment>
  );
};
