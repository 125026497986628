/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form, Input, DatePicker } from 'antd';

class UserCreationForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="add-user-form">
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Please enter the user name!' }],
          })(<Input placeholder="Name" />)}
        </Form.Item>

        <Form.Item label="Trial expiration date">
          {getFieldDecorator('trial_expiration_date', {
            rules: [],
          })(<DatePicker placeholder="Trail expiration" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedUserCreationForm = Form.create({ name: 'user_creation' })(UserCreationForm);

export default function AddOrgModal({ modalVisible, setIsVisible, addOrg }) {
  let formRef = null;
  return (
    <Modal
      title="Add Organization"
      centered
      visible={modalVisible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            const newValues = { ...values };
            if (newValues.trial_expiration_date) {
              newValues.trial_expiration_date = values.trial_expiration_date.format('YYYY-MM-DD');
            }

            addOrg(newValues);
            setIsVisible(false);
          });
        }
      }}
      onCancel={() => {
        setIsVisible(false);
        formRef.props.form.resetFields();
      }}
    >
      <WrappedUserCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
      />
    </Modal>
  );
}
