import { getAbTestsAPI, updAbTestAPI, addAbTestAPI, deleteAbTestAPI, getAbTestDataAPI } from '../actions/abTestsPage';
import {
  getVariantsAPI,
  addVariantAPI,
  updVariantAPI,
  delVariantAPI,
  addVariantAssetAPI,
  updVariantAssetAPI,
  delVariantAssetAPI,
  addVariantReleaseAPI,
  delVariantReleaseAPI,
  updInsightThumbnailAPI,
  getInsightAssetsAPI,
  delInsightAssetAPI,
  updInsightAssetAPI,
  addInsightAssetAPI,
  refreshPresignedUrlAPI,
} from '../actions/insightPage';

import * as AT from '../actionTypes';

const abTestsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_AB_TESTS: {
        const { page, pageSize, appId, searchQuery, abTestID, hasInsights } = action.payload;
        dispatch(getAbTestsAPI(page, pageSize, appId, searchQuery, abTestID, hasInsights));
        return next(action);
      }
      case AT.GET_AB_TEST_DATA: {
        const { abTestID } = action.payload;
        dispatch(getAbTestDataAPI(abTestID));
        return next(action);
      }
      case AT.UPD_AB_TEST: {
        const { abTestId, data } = action.payload;
        dispatch(updAbTestAPI(abTestId, data));
        return next(action);
      }
      case AT.ADD_AB_TEST: {
        dispatch(addAbTestAPI(action.payload));
        return next(action);
      }
      case AT.DELETE_AB_TEST: {
        dispatch(deleteAbTestAPI(action.payload));
        return next(action);
      }

      case AT.GET_VARIANTS: {
        const { abTestId } = action.payload;
        dispatch(getVariantsAPI(abTestId));
        return next(action);
      }
      case AT.ADD_VARIANT: {
        const { abTestId, data } = action.payload;
        dispatch(addVariantAPI(abTestId, data));
        return next(action);
      }
      case AT.UPD_VARIANT: {
        const { variantId, data } = action.payload;
        dispatch(updVariantAPI(variantId, data));
        return next(action);
      }
      case AT.DEL_VARIANT: {
        const { variantId } = action.payload;
        dispatch(delVariantAPI(variantId));
        return next(action);
      }

      case AT.ADD_VARIANT_ASSET: {
        const { variantId, data } = action.payload;
        dispatch(addVariantAssetAPI(variantId, data));
        return next(action);
      }
      case AT.UPD_VARIANT_ASSET: {
        const { assetId, data } = action.payload;
        dispatch(updVariantAssetAPI(assetId, data));
        return next(action);
      }
      case AT.DEL_VARIANT_ASSET: {
        const { variantId, assetId } = action.payload;
        dispatch(delVariantAssetAPI(variantId, assetId));
        return next(action);
      }

      case AT.ADD_VARIANT_RELEASE: {
        const { abTestId, variantId, releaseId } = action.payload;
        dispatch(addVariantReleaseAPI(abTestId, variantId, releaseId));
        return next(action);
      }
      case AT.DEL_VARIANT_RELEASE: {
        const { abTestId, variantId, releaseId } = action.payload;
        dispatch(delVariantReleaseAPI(abTestId, variantId, releaseId));
        return next(action);
      }

      case AT.UPD_INSIGHT_THUMBNAIL: {
        const { insightId, data } = action.payload;
        dispatch(updInsightThumbnailAPI(insightId, data));
        return next(action);
      }
      case AT.GET_INSIGHT_ASSETS: {
        const { insightId } = action.payload;
        dispatch(getInsightAssetsAPI(insightId));
        return next(action);
      }
      case AT.DEL_INSIGHT_ASSET: {
        const { insightId, assetId } = action.payload;
        dispatch(delInsightAssetAPI(insightId, assetId));
        return next(action);
      }
      case AT.UPD_INSIGHT_ASSET: {
        const { insightId, assetId, data } = action.payload;
        dispatch(updInsightAssetAPI(insightId, assetId, data));
        return next(action);
      }
      case AT.ADD_INSIGHT_ASSET: {
        const { insightId, data } = action.payload;
        dispatch(addInsightAssetAPI(insightId, data));
        return next(action);
      }
      case AT.REFRESH_INSIGHT_PRESIGNED_URL: {
        dispatch(refreshPresignedUrlAPI());
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default abTestsMiddleware;
