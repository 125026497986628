import React from 'react';
import { Select, Spin, Table } from 'antd';
import Img from 'react-image';
import moment from 'moment';

export default ({ isLoading, releases, currentPage, pageSize, totalSize, getReleases }) => {
  const columns = [
    {
      title: 'App',
      render: (record) => (
        <div style={{ display: 'flex' }}>
          <Img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={[record.app_icon]}
            alt=""
            loader={<Spin />}
            container={(children) => (
              <div
                style={{
                  width: '25px',
                  height: '25px',
                }}
              >
                {children}
              </div>
            )}
          />
          #{record.app_id} {record.app_name}
        </div>
      ),
    },
    {
      title: 'OS',
      dataIndex: 'platform',
    },
    {
      title: 'Release',
      render: (record) => `${record.version_code}_${record.release_name}`,
    },
    {
      title: 'Release Date',
      render: (record) => record.release_date && moment(record.release_date).format('ll'),
    },
    {
      title: 'Upload Date',
      render: (record) => record.upload_date && moment(record.upload_date).format('ll'),
    },
    {
      title: 'Status',
      dataIndex: 'state',
    },
    {
      title: 'Graphs',
      render: (record) => (
        <div>
          <ul>
            {record.graphs.map((x) => (
              <li>
                <a
                  href={`/#/graph-builder?productID=${record.product_id}&appID=${record.app_id}&versionID=${
                    record.id
                  }&personaID=${x.persona && x.persona.id}&deviceID=${x.device && x.device.id}`}
                >
                  Update graph {x.persona ? x.persona.title : 'unknown persona'} -{' '}
                  {x.device ? x.device.model : 'unknown device'}
                </a>
              </li>
            ))}
          </ul>
          <ul>
            {record.devices.length
              ? record.devices.map((d) =>
                  record.personas.map((p) => (
                    <li>
                      <a
                        href={`/#/graph-builder?productID=${record.product_id}&appID=${record.app_id}&versionID=${
                          d.installed_release_id === record.id ? record.id : ''
                        }&personaID=${p.id}&deviceID=${d.id}`}
                      >
                        Create graph -{' '}
                        {`Device #${d.id} ${d.manufacturer} ${d.model} (${record.platform} ${d.os_version}) - Persona #${p.id} ${p.location} ${p.title}`}
                      </a>
                    </li>
                  ))
                )
              : 'Not installed on any device'}
          </ul>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={releases}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={{
          pageSize,
          total: totalSize,
          current: currentPage,
          onChange: (page, _pageSize) => getReleases(page, _pageSize),
        }}
      />
    </div>
  );
};
