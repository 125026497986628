import React, { useState } from 'react';
import { Table, Spin, Button, Input, Icon, Popconfirm } from 'antd';
import Img from 'react-image';

import Highlighter from 'react-highlight-words';

const ProductsTable = (props) => {
  const [tableSearchQuery, setTableSearchQuery] = useState('');

  const onDelete = (product) => {
    console.log(`deleting productID ${product.id}`);
    props.deleteProduct(product);
  };
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setTableSearchQuery(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setTableSearchQuery('');
  };
  const getColumnSearchProps = (dataIndex) => {
    let searchInput = null;
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon="search"
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.select());
        }
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[tableSearchQuery]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ),
    };
  };

  const getProductTableColumns = () => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
      render: (text, product) => (
        <div>
          {text}
          <Button
            style={{ margin: '4px' }}
            type="small"
            icon="edit"
            onClick={(e) => {
              e.stopPropagation();
              props.setShowProductsEditModal(true);
              props.setProductID(product.id);
            }}
            disabled={!props.isAdmin}
          />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => onDelete(product)}
            disabled={!props.isAdmin}
          >
            <Button type="danger small" icon="delete" disabled={!props.isAdmin} />
          </Popconfirm>
        </div>
      ),
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'product',
      width: '300px',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Icon',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: '10%',
      render: (text) => (
        <Img
          style={{ width: '100%', height: '100%' }}
          src={[text]}
          alt=""
          loader={<Spin />}
          container={(children) => <div style={{ width: '25px', height: '25px' }}>{children}</div>}
        />
      ),
    },
    {
      title: 'Applications',
      dataIndex: 'apps',
      key: 'apps',
      ...getColumnSearchProps('apps_text'),
      render: (value) => (
        <React.Fragment>
          {value.map((x) => (
            <div style={{ display: 'flex' }}>
              <Img
                style={{
                  width: '100%',
                  height: '100%',
                }}
                src={[x.thumbnail]}
                alt={x.package_name}
                loader={<Spin />}
                container={(children) => (
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    {children}
                  </div>
                )}
              />
              <span style={{ marginLeft: '10px' }}>
                #<a href={`/#/releases?platform=${x.platform}&appId=${x.id}`}>{x.id}</a>
                <Highlighter
                  highlightStyle={{
                    backgroundColor: '#ffc069',
                    padding: 0,
                  }}
                  searchWords={[tableSearchQuery]}
                  autoEscape
                  textToHighlight={` ${x.platform} -${x.display_name} (${x.package_name}) - ${x.platform}`}
                />
              </span>
            </div>
          ))}
        </React.Fragment>
      ),
    },
  ];

  return (
    <div>
      <Table
        className="main-table"
        columns={getProductTableColumns()}
        dataSource={props.productsData.map((x) => ({
          ...x,
          apps_text: x.apps.map((app) => `#${app.id} ${app.display_name} ${app.package_name}`).join(' '),
        }))}
        scroll={{ y: 600 }}
        pagination={{ pageSize: 50 }}
      />
    </div>
  );
};
export default ProductsTable;
