import { Badge, Button, Icon, Tooltip } from 'antd';
import React from 'react';
import './ManagementPanel.scss';

export const ManagementPanel = (props) => {
  const {
    appId,
    onAddMarker,
    onAddBlur,
    onRemoveRect,
    onRemoveAllRects,
    hideLink,
    onLinkClicked,
    handleVideoToggle,
    videoStarted,
    isLinked,
    isCustomDevice,
    graphData,
    isVideoLoading,
    handlePrintScreen,
    handlePrintScreenBoth,
    handleVideoToggleBoth,
    handleTranslateScreen,
    isIosDevice,
  } = props;

  const handleAddMark = () => {
    onAddMarker();
  };

  const handleAddBlur = () => {
    onAddBlur();
  };

  return (
    <div className="panel-main">
      <div className="button-group">
        <div className="button-wrapper">
          <Button onClick={handleAddMark} disabled={isCustomDevice}>
            Mark
          </Button>
        </div>
        <div className="button-wrapper">
          <Button onClick={handleAddBlur} disabled={isCustomDevice}>
            Blur
          </Button>
        </div>
        <div className="button-wrapper">
          <Button onClick={onRemoveRect} disabled={isCustomDevice}>
            Delete
          </Button>
        </div>
        <div className="button-wrapper">
          <Button onClick={onRemoveAllRects} disabled={isCustomDevice}>
            Delete All
          </Button>
        </div>
        <div className="button-wrapper">
          <Button onClick={handleTranslateScreen} disabled={!appId || isCustomDevice}>
            Translate Screen
          </Button>
        </div>
      </div>
      <div className="button-group">
        <div className="button-wrapper">
          <Button onClick={handlePrintScreen} disabled={!appId || isCustomDevice}>
            Print Screen
          </Button>
        </div>
      </div>
      <div className="button-group">
        <div className="button-wrapper">
          <Button
            onClick={handleVideoToggle}
            icon="video-camera"
            disabled={!appId || isCustomDevice || isIosDevice}
            loading={isVideoLoading}
          >
            {videoStarted ? 'Stop' : 'Start'} Recording
          </Button>
        </div>
      </div>
      {!hideLink && (
        <div className="button-group">
          <div className="button-wrapper">
            <Button onClick={onLinkClicked} type={isLinked ? 'primary' : 'default'} disabled={isCustomDevice}>
              <Icon type="link" rotate={45} style={{ fontSize: '21px' }} />
            </Button>
          </div>
          <div className="button-wrapper">
            <Button onClick={handlePrintScreenBoth} disabled={!appId || isCustomDevice}>
              Print Both Screens
            </Button>
          </div>
          <div className="button-wrapper">
            <Button
              onClick={handleVideoToggleBoth}
              icon="video-camera"
              disabled={!appId || isCustomDevice || isIosDevice}
              loading={isVideoLoading}
            >
              {videoStarted ? 'Stop' : 'Start'} Recording Both
            </Button>
          </div>
        </div>
      )}
      {graphData && (
        <React.Fragment>
          <div className="graph-data-container">
            <Badge count={graphData.dataset ? graphData.dataset.length : 0} showZero>
              <Tooltip title="AppGraph Screen Count">
                <Icon type="mobile" style={{ fontSize: 25 }} />
              </Tooltip>
            </Badge>
          </div>
          {graphData.graphLink && (
            <div className="graph-data-container">
              <a href={graphData.graphLink} download>
                Download App Graph
              </a>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};
