import { getInsightCtasAPI, addInsightCtaAPI, updInsightCtaAPI, delInsightCtaAPI } from '../actions/insightPage';
import * as AT from '../actionTypes';

const insightCtasMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_INSIGHT_CTAS: {
        dispatch(getInsightCtasAPI(action.payload.insightId));
        return next(action);
      }
      case AT.ADD_INSIGHT_CTA: {
        dispatch(addInsightCtaAPI(action.payload.insightId, action.payload.data));
        return next(action);
      }
      case AT.UPD_INSIGHT_CTA: {
        dispatch(updInsightCtaAPI(action.payload.insightId, action.payload.ctaId, action.payload.data));
        return next(action);
      }
      case AT.DEL_INSIGHT_CTA: {
        dispatch(delInsightCtaAPI(action.payload.insightId, action.payload.ctaId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default insightCtasMiddleware;
