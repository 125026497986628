export const AB_TEST_TYPE = {
  ab_test: 'A/B Test',
  feature: 'Feature',
};

export const AB_TEST_STATUS = {
  active: 'Active',
  integrated: 'Integrated',
  abandoned: 'Abandoned',
  abandoned_no_data: 'Abandoned(no data)',
};
