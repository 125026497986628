import React from 'react';
import { Transformer } from 'react-konva';

export class TransformerComponent extends React.Component {
  componentDidMount() {
    this.checkNode();
  }

  componentDidUpdate() {
    this.checkNode();
  }

  checkNode() {
    this.transformer.detach();
    const { selectedRect } = this.props;
    if (selectedRect) {
      this.transformer.attachTo(selectedRect);
      this.transformer.getLayer().batchDraw();
    }
  }

  boundBoxFunc = (oldBoundBox, newBoundBox) => {
    // Disable Resize
    if (newBoundBox.rotation && newBoundBox.rotation !== 0) {
      return oldBoundBox;
    }
    return newBoundBox;
  };

  render() {
    return (
      <Transformer
        boundBoxFunc={this.boundBoxFunc}
        rotateEnabled={false}
        keepRatio={false}
        ref={(node) => {
          this.transformer = node;
        }}
      />
    );
  }
}
