import React from 'react';
import PropTypes from 'prop-types';
import { Rect } from 'react-konva';

class Rectangle extends React.Component {
  handleChange = (e) => {
    const shape = e.target;
    const width = shape.width() > 1 ? shape.width() : 100;
    const height = shape.height() > 1 ? shape.height() : 100;
    this.props.onTransform({
      x: shape.x(),
      y: shape.y(),
      width: width * shape.scaleX(),
      height: height * shape.scaleY(),
      rotation: shape.rotation(),
      type: this.props.type,
    });
  };

  render() {
    let fill = null;
    let stroke = '#2196f3';
    let opacity = null;
    if (this.props.type === 'blur') {
      stroke = null;
      fill = 'black';
      opacity = 0.5;
    }
    return (
      <Rect
        x={this.props.x}
        y={this.props.y}
        width={this.props.width}
        height={this.props.height}
        // force no scaling
        // otherwise Transformer will change it
        scaleX={1}
        scaleY={1}
        stroke={stroke}
        fill={fill}
        opacity={opacity}
        strokeWidth={3}
        name={this.props.name}
        onDragEnd={this.handleChange}
        onTransformEnd={this.handleChange}
        onMouseDown={this.props.onMouseDown}
        draggable
        cornerRadius={5}
      />
    );
  }
}

Rectangle.propTypes = {
  onTransform: PropTypes.func.isRequired,
  onMouseDown: PropTypes.func.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['marker', 'blur']).isRequired,
};
export default Rectangle;
