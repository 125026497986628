import * as AT from '../actionTypes';

export const getAppsWithScreenChanges = () => ({
  type: AT.GET_APPS_WITH_SCREEN_CHANGES,
});

export const getAppsWithScreenChangesAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/data?has_screen_changes=1`,
    method: 'GET',
    label: 'GET_APPS_WITH_SCREEN_CHANGES',
    onSuccess: (body, dispatch) => dispatch(getAppsWithScreenChangesSuccess(body)),
  },
});

export const getAppsWithScreenChangesSuccess = (data) => ({
  type: AT.GET_APPS_WITH_SCREEN_CHANGES_SUCCESS,
  payload: { data },
});

export const getScreenChangesForApp = (app_id) => ({
  type: AT.GET_SCREEN_CHANGES_FOR_APP,
  payload: {
    app_id,
  },
});

export const getScreenChangesForAppAPI = ({ app_id }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `app/${app_id}/screen-changes`,
    method: 'GET',
    label: 'GET_SCREEN_CHANGES_FOR_APP',
    onSuccess: (body, dispatch) => dispatch(getScreenChangesForAppSucces(body)),
  },
});

export const getScreenChangesForAppSucces = (data) => ({
  type: AT.GET_SCREEN_CHANGES_FOR_APP_SUCCESS,
  payload: { data },
});

// GET GTAPH_DATA

// all graphs
export const getAllAppsWithGraphs = () => ({
  type: AT.GET_ALL_APPS_WITH_GRAPHS,
});

export const getAllAppsWithGraphsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/graphs`,
    method: 'GET',
    label: 'GET_ALL_APPS_WITH_GRAPHS',
    onSuccess: (body, dispatch) => dispatch(getAllAppsWithGraphsSuccess(body)),
    onFailure: (error, dispatch) => dispatch(getAllAppsWithGraphsFailure(error)),
  },
});

export const getAllAppsWithGraphsSuccess = (data) => ({
  type: AT.GET_ALL_APPS_WITH_GRAPHS_SUCCESS,
  payload: { data },
});

export const getAllAppsWithGraphsFailure = (error) => ({
  type: AT.GET_ALL_APPS_WITH_GRAPHS_FAILURE,
  payload: { error },
});

// version
export const getVersionGraphDataForApp = ({ platform, package_name }) => ({
  type: AT.GET_VERSION_GRAPH_DATA_FOR_APP,
  payload: { platform, package_name },
});

export const getVersionGraphDataForAppAPI = ({ platform, package_name }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `backoffice/graph/versions?platform=${platform}&package_name=${package_name}`,
    method: 'GET',
    label: 'GET_VERSION_GRAPH_DATA_FOR_APP',
    onSuccess: (body, dispatch) =>
      dispatch(
        getGraphDataForApp({
          platform,
          package_name,
          version_code: body[0],
        })
      ),
  },
});

// app
export const getGraphDataForApp = (graph_id) => ({
  type: AT.GET_GRAPH_DATA_FOR_APP,
  payload: { graph_id },
});

export const getGraphDataForAppAPI = ({ graph_id }) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/graphs/${graph_id}`,
    method: 'GET',
    label: 'GET_GRAPH_DATA_FOR_APP',
    onSuccess: (body, dispatch) => dispatch(getGraphDataForAppSuccess(body)),
  },
});

export const getGraphDataForAppSuccess = (data) => ({
  type: AT.GET_GRAPH_DATA_FOR_APP_SUCCESS,
  payload: { data },
});

export const createScreenChangeInsight = (insight, prevImageAsset, currentImageAsset) => ({
  type: AT.CREATE_SCREEN_CHANGE_INSIGHT,
  payload: {
    insight,
    prevImageAsset,
    currentImageAsset,
  },
});

// create complaint
export const createComplaintForGraph = (data) => ({
  type: AT.CREATE_COMPLAINT_FOR_GRAPH,
  payload: data,
});

export const createComplaintForGraphAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/graphs/${data.id}/complaints`,
    method: 'POST',
    data: {
      description: data.description,
      graph_rank: data.graph_rank,
    },
    label: 'CREATE_COMPLAINT_FOR_GRAPH',
    onSuccess: (body, dispatch) => dispatch(createComplaintForGraphSuccess(body)),
  },
});

export const createComplaintForGraphSuccess = (data) => ({
  type: AT.CREATE_COMPLAINT_FOR_GRAPH_SUCCESS,
  payload: { data },
});

export const deleteComplaintForGraph = (graph_id) => ({
  type: AT.DELETE_COMPLAINT_FOR_GRAPH,
  payload: graph_id,
});

export const deleteComplaintForGraphAPI = (graph_id) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/graphs/complaints/${graph_id}`,
    method: 'DELETE',
    label: 'DELETE_COMPLAINT_FOR_GRAPH',
    onSuccess: (body, dispatch) => dispatch(deleteComplaintForGraphSuccess(body)),
  },
});

export const deleteComplaintForGraphSuccess = (data) => ({
  type: AT.DELETE_COMPLAINT_FOR_GRAPH_SUCCESS,
  payload: { data },
});
