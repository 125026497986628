import React from 'react';
import { Button, Icon, Popconfirm, Table, InputNumber } from 'antd';

export default ({ deviceModels, isLoading, onUpdDeviceModel, onDelDeviceModel, onChangePriority, updDeviceModel }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      render: (value, record) => (
        <div style={{ display: 'flex', width: '8rem' }}>
          <Button
            type="primary"
            shape="circle"
            icon="down"
            disabled={value <= 0}
            onClick={() => onChangePriority(record.id, Math.max(0, (value || 0) - 1))}
          />
          <InputNumber value={value} readOnly />
          <Button
            type="primary"
            shape="circle"
            icon="up"
            onClick={() => onChangePriority(record.id, (value || 0) + 1)}
          />
        </div>
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      filters: [...new Set(deviceModels.map((x) => x.platform).filter((x) => x))].map((x) => ({
        text: x,
        value: x,
      })),
      onFilter: (value, record) => record.platform === value,
      filterMultiple: false,
    },
    {
      title: 'OS Version',
      dataIndex: 'os_version',
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
    },
    {
      title: 'Model',
      dataIndex: 'model',
    },
    {
      title: 'Product Name',
      dataIndex: 'product_name',
    },
    {
      title: 'RAM',
      dataIndex: 'ram',
    },
    {
      title: 'CPU',
      dataIndex: 'cpu',
    },
    {
      title: 'GPU',
      dataIndex: 'gpu',
    },
    {
      title: 'Storage',
      dataIndex: 'storage',
    },
    {
      title: 'Device Type',
      dataIndex: 'device_type',
    },
    {
      title: 'Screen Size',
      dataIndex: 'screen_size',
    },
    {
      title: 'Screen Density',
      dataIndex: 'screen_density',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) =>
        record._deleted ? (
          <div>
            <div style={{ color: '#ff1a1a' }}>Device Model is deleted</div>
            <div>
              <Button onClick={() => updDeviceModel(record.id, { _deleted: !record._deleted })}>Enable</Button>
            </div>
          </div>
        ) : (
          <div>
            <Button style={{ margin: '4px' }} type="small" icon="edit" onClick={() => onUpdDeviceModel(record.id)} />
            <Popconfirm
              title="Are you sure?"
              icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
              onConfirm={() => {
                onDelDeviceModel(record.id);
              }}
            >
              <Button icon="delete" type="danger small" />
            </Popconfirm>
          </div>
        ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={deviceModels}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
    </div>
  );
};
