import React, { useState } from 'react';

import { connect } from 'react-redux';
import { Select } from 'antd';
import { selectApp } from '../../redux/actions/insightPage';
import CachedAppsList from '../../utils/CachedAppsList';

const { Option } = Select;

const AppSelector = ({ apps, selectedAppPackage, selectedAppPlatform, onAppSelected }) => {
  const [searchQuery, searchQueryChange] = useState('');

  const handleAppSelected = (value, option) => {
    if (selectedAppPackage !== option.props.package || selectedAppPlatform !== option.props.platform) {
      onAppSelected(option.props.name, option.props.package, option.props.platform);
      const appToCache = apps.find(
        (app) =>
          app.name === option.props.name &&
          app.package === option.props.package &&
          app.platform === option.props.platform
      );
      CachedAppsList.addCachedApp(appToCache);
      searchQueryChange('');
    }
  };

  if (apps.length === 0) {
    return null;
  }
  const filteredAppList = (apps || []).filter((app) => {
    if (searchQuery && searchQuery.length >= 3) {
      const match =
        app.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        app.package.toLowerCase().includes(searchQuery.toLowerCase());
      return match;
    }
    return false;
  });

  const appsToShow = filteredAppList.length === 0 ? CachedAppsList.getCachedApps() : filteredAppList;

  return (
    <div className="insight-selector">
      <div className="fields">
        <div className="insight-selector__app-dropdown two wide field">
          <Select
            showSearch
            filterOption={false}
            style={{ width: 350 }}
            placeholder="Select app..."
            onSearch={(value) => searchQueryChange(value)}
            onChange={handleAppSelected}
            defaultActiveFirstOption={false}
            value={[selectedAppPackage, selectedAppPlatform].toString()}
          >
            {appsToShow.map((app) => (
              <Option
                key={app.id}
                value={[app.package, app.platform].toString()}
                package={app.package}
                name={app.name}
                platform={app.platform}
              >
                {`${app.name}(${app.package}) - ${app.platform}`}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAppSelected: (appName, appPackage, appPlatform) => {
    dispatch(selectApp(appName, appPackage, appPlatform));
  },
});

const mapStateToProps = (state) => ({
  apps: state.apps.all,
  selectedAppName: state.insightPage.selectedAppName,
  selectedAppPackage: state.insightPage.selectedAppPackage,
  selectedAppPlatform: state.insightPage.selectedAppPlatform,
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSelector);
