import React from 'react';
import { Button, Icon, Popconfirm, Table, Divider, Select, Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import ProductAutocomplete from '../user_groups/products/ProductAutocomplete';
import './reports.scss';

const { Option } = Select;

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    // eslint-disable-next-line no-plusplus
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return `${bytes.toFixed(dp)} ${units[u]}`;
}
const dateFormat = 'YYYY-MM-DD';
export default ({
  isLoading,
  reports,
  tags,
  organizations,
  products,
  onDelReport,
  onUpdReport,
  onUpdTags,
  onUpdOrgReports,
  updReport,
  previewEmailContent,
  addProduct,
  delProduct,
}) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Uploaded',
      dataIndex: 'upload_date',
      render: (value, record) => (
        <DatePicker
          defaultValue={moment(value, dateFormat)}
          onChange={(e, dateString) => updReport(record.id, { upload_date: dateString })}
        />
      ),
    },
    {
      title: 'Size',
      dataIndex: 'size_bytes',
      render: (value) => <React.Fragment>{humanFileSize(value)}</React.Fragment>,
    },
    {
      title: 'Url',
      dataIndex: 'url',
      render: (value, record) => (
        <div>
          <a href={value}>Download report</a>
          {value !== record.preview_url && (
            <div>
              <a href={record.preview_url}>Download preview(PDF)</a>
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Screenshot',
      dataIndex: 'screenshot_url',
      render: (value, record) =>
        value ? (
          <a rel="noopener noreferrer" target="_blank" href={value}>
            link
          </a>
        ) : (
          ''
        ),
    },
    {
      title: 'Is Visible',
      dataIndex: 'is_visible',
      render: (value, record) => (
        <Checkbox checked={value} onChange={() => updReport(record.id, { is_visible: !value })} />
      ),
    },
    {
      title: 'Is Newsletter',
      dataIndex: 'is_newsletter',
      render: (value, record) => (
        <Checkbox checked={value} onChange={() => updReport(record.id, { is_newsletter: !value })} />
      ),
    },
    {
      title: 'Send Email',
      dataIndex: 'send_email',
      render: (value, record) =>
        !value ? (
          <Popconfirm
            title="Are you sure you want to send this mail? if this report was sent in the past it will not be sent again."
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => updReport(record.id, { send_email: !value })}
          >
            <Checkbox checked={value} />
          </Popconfirm>
        ) : (
          <Checkbox checked={value} onChange={() => updReport(record.id, { send_email: !value })} />
        ),
    },
    {
      title: 'Products',
      dataIndex: 'products',
      render: (value, record) => (
        <div style={{ width: '100%' }}>
          <Select
            value={record.products_rel_type}
            onChange={(value) => updReport(record.id, { products_rel_type: value })}
          >
            {[
              { value: 'none', label: 'None' },
              { value: 'all', label: 'All' },
              { value: 'specific', label: 'Specific' },
            ].map((x) => (
              <Option key={x.key} value={x.value}>
                {x.label}
              </Option>
            ))}
          </Select>
          {record.products_rel_type === 'specific' && (
            <div className="report-wrapper">
              {record.products.map((product) => (
                <div key={product.id} title={product.name} className="report-item">
                  <img src={product.thumbnail} /> {product.name}
                  <Button icon="delete" type="small" onClick={() => delProduct(record.id, product.id)} />
                </div>
              ))}
              <ProductAutocomplete
                products={products.filter((p) => !record.products.map((x) => x.id).includes(p.id))}
                onSelect={(selProduct) => addProduct(record.id, selProduct)}
              />
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (value, record) => (
        <Select
          mode="tags"
          style={{ width: '100%' }}
          placeholder="Tags"
          onChange={(value) => onUpdTags(record, value)}
          value={record.tags.map((tag) => tag.name)}
        >
          {tags.map((tag) => (
            <Option value={tag.name} key={tag.name}>
              {tag.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Organizations',
      dataIndex: 'organizations',
      render: (value, record) => (
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Organizations"
          onChange={(v) => onUpdOrgReports(record, v)}
          value={record.orgs.map((tag) => tag.id)}
        >
          <Option value="all">Select All</Option>
          {organizations.map((org) => (
            <Option value={org.id} key={org.id}>
              {org.name}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: '',
      render: (value, record) => (
        <div style={{ display: 'flex' }}>
          <Button type="small" icon="edit" title="Change report" onClick={() => onUpdReport(record.id)} />
          <Divider type="vertical" />
          <Button
            type="small"
            icon="eye"
            title="Preview email content"
            onClick={() => previewEmailContent(record.id)}
          />
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => onDelReport(record.id)}
          >
            <Button icon="delete" type="danger small" title="Delete report" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={reports}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: 600,
        }}
        pagination={false}
      />
    </div>
  );
};
