import * as AT from '../actionTypes';
// eslint-disable-next-line import/first
import { stringify } from 'query-string';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const getAbandonedInsightsApiRequestFailed = (error) => ({
  type: AT.API_REQUEST_ABANDONED_INSIGHTS_FAILED,
  payload: { error },
});

export const getAbandonedInsights = (untilDate) => ({
  type: AT.GET_ABANDONED_INSIGHTS,
  payload: { untilDate },
});

export const getAbandonedInsightsApi = (untilDate) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/abandoned?${stringify({ until_date: untilDate })}`,
    method: 'GET',
    label: NETWORK_LABELS.GET_ABANDONED_INSIGHTS,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_ABANDONED_INSIGHTS_SUCCESS,
        payload: { insights: body.data },
      }),
    onFailure: (error, dispatch) => dispatch(getAbandonedInsightsApiRequestFailed(error)),
  },
});

export const getAbandonedInsightsCount = () => ({
  type: AT.GET_ABANDONED_INSIGHTS_COUNT,
});

export const getAbandonedInsightsCountApi = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `insight/abandoned/count`,
    method: 'GET',
    label: NETWORK_LABELS.GET_ABANDONED_INSIGHTS_COUNT,
    onSuccess: (body, dispatch) =>
      dispatch({
        type: AT.GET_ABANDONED_INSIGHTS__COUNT_SUCCESS,
        payload: { count: body.data.count },
      }),
    onFailure: (error, dispatch) => dispatch(getAbandonedInsightsApiRequestFailed(error)),
  },
});
