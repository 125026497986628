import React, { useEffect, useState } from 'react';
import { parsePatch } from 'diff';

import { Tabs, Select, Spin, message } from 'antd';
import { connect } from 'react-redux';

import { useQuery } from 'react-query';

import { getAllProducts } from '../../redux/actions/products';
import { getDiffDataForVersion, getAppsWithDiffDocuments } from '../../api/versionDiffAPI';
const S3_BASE_LOCATION = 'https://analyzeimages.s3.amazonaws.com/images';

const { TabPane } = Tabs;

function VersionsDiff({}) {
  const [appPackage, appPackageChange] = useState(null);
  const [appPlatform, appPlatformChange] = useState(null);
  const [ver1guid, ver1guidChange] = useState(null);
  const [ver2guid, ver2guidChange] = useState(null);

  const [patchDiff, patchDiffChange] = useState('');

  const appsWithDocsData = useQuery(['getAppsWithDiffDocuments'], () => getAppsWithDiffDocuments());

  // const versionData2 = useQuery(['appVersionData', appPackage, appPlatform], () => getVersionsForApp(appPlatform, appPackage), {
  //   enabled: !!appPackage,
  // });

  const rawDiff = useQuery(['rawDiffData', ver1guid, ver2guid], () => getDiffDataForVersion(ver1guid, ver2guid), {
    enabled: !!ver1guid && !!ver2guid,
    retry: false,
  });

  useEffect(() => {
    if (rawDiff.data) {
      const diff = {};
      const patchSections = Object.keys(rawDiff.data.dissectors);
      patchSections.forEach((sec) => {
        const arrayLines = rawDiff.data.dissectors[sec].value;
        if (arrayLines.length === 0) {
          return;
        }
        const diffDis = parsePatch(arrayLines.join(`\n`)).find((part) => part.hunks.length > 0);
        diffDis.hunks.forEach((hunk) => {
          hunk.changed = hunk.lines.find((line) => line[0] === '+') && hunk.lines.find((line) => line[0] === '-');
          hunk.added = hunk.lines.find((line) => line[0] === '+') && !hunk.lines.find((line) => line[0] === '-');
          hunk.deleted = !hunk.lines.find((line) => line[0] === '+') && hunk.lines.find((line) => line[0] === '-');
        });
        diff[sec] = diffDis;
      });

      console.log(diff);
      patchDiffChange(diff);
    } else {
      patchDiffChange('');
    }
  }, [rawDiff.data]);

  useEffect(() => {
    if (rawDiff.isError) {
      message.error('Something went wrong');
    }
  }, [rawDiff.isError]);

  const handleChangeApp = (packageName) => {
    appPackageChange(packageName);
    ver1guidChange(null);
    ver2guidChange(null);
  };

  let versionData;
  if (appPlatform && appPackage) {
    versionData = appsWithDocsData.data.filter((a) => a.platform === appPlatform && a.app_name === appPackage);
  }

  const regex = new RegExp('"(.*?)"');
  return (
    <div className="version-diff">
      <div className="filters">
        <Select
          className="app-platform-selectbox app-filter"
          onChange={(platform) => appPlatformChange(platform)}
          placeholder="Choose Platform"
          value={appPlatform}
        >
          <Select.Option value="iOS" key="Ios">
            iOS
          </Select.Option>
          <Select.Option value="Android" key="Android">
            Android
          </Select.Option>
        </Select>
        {appPlatform && appsWithDocsData.data && (
          <Select
            className="app-selectbox app-filter"
            onChange={(packageName) => handleChangeApp(packageName)}
            placeholder="Choose App"
            value={appPackage}
            disabled={!appPlatform}
            showSearch
            filterOption={(input, option) => option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {appsWithDocsData.data
              .filter((a) => a.platform === appPlatform)
              .reduce((acc, el) => (acc.includes(el.app_name) ? acc : [...acc, el.app_name]), [])
              .map((packageName) => (
                <Select.Option
                  value={packageName}
                  key={packageName}
                  className={
                    appsWithDocsData.data.find((a) => a.app_name === packageName).supported
                      ? 'supported'
                      : 'non-supported'
                  }
                >
                  {packageName}
                </Select.Option>
              ))}
          </Select>
        )}
        {versionData && (
          <div className="versions">
            <Select value={ver1guid} onChange={ver1guidChange}>
              {[...new Map(versionData.map((item) => [item.version_name, item])).values()]
                .sort((a, b) => (a.version_code > b.version_code ? -1 : 1))
                .map((ver) => (
                  <Select.Option value={ver.document_guid} key={ver.document_guid}>
                    {ver.version_name}
                  </Select.Option>
                ))}
            </Select>
            <Select value={ver2guid} onChange={ver2guidChange}>
              {[...new Map(versionData.map((item) => [item.version_name, item])).values()]
                .sort((a, b) => (a.version_code > b.version_code ? -1 : 1))
                .map((ver) => (
                  <Select.Option value={ver.document_guid} key={ver.document_guid}>
                    {ver.version_name}
                  </Select.Option>
                ))}
            </Select>
          </div>
        )}
      </div>
      {rawDiff.isLoading && (
        <div className="loader-diff-big">
          <Spin size="large" />
        </div>
      )}
      {patchDiff && (
        <Tabs animated={{ inkBar: true, tabPane: false }}>
          {Object.keys(patchDiff).map((sec, i) => (
            <TabPane tab={sec.replace('_diff_full', '').replace('_', ' ')} key={`tab${i}`}>
              <div className="diff-section">
                {['added', 'changed', 'deleted'].map((type) =>
                  patchDiff[sec].hunks.filter((h) => h[type]).length ? (
                    <div>
                      <h2 className={type}>{type}</h2>
                      {patchDiff[sec].hunks
                        .filter((h) => h[type])
                        .map((hunk, hunkIndex) => (
                          <div className="hunk-part" key={hunkIndex}>
                            {hunk.lines.map((line, i) => {
                              if (sec === 'generic_images_diff_full') {
                                console.log(line);
                                if (line.includes('value')) return null;
                                if (
                                  line.includes('name') &&
                                  hunk.lines[i + 1] &&
                                  hunk.lines[i + 1].includes('value') &&
                                  hunk.lines[i + 1][0] === '-' &&
                                  hunk.lines[i + 2] &&
                                  hunk.lines[i + 2].includes('value') &&
                                  hunk.lines[i + 2][0] === '+'
                                ) {
                                  return (
                                    <div className="change-images">
                                      <img src={`${S3_BASE_LOCATION}/${ver1guid}${line.match(/(?<=").+?(?=")/g)[2]}`} />
                                      <img src={`${S3_BASE_LOCATION}/${ver2guid}${line.match(/(?<=").+?(?=")/g)[2]}`} />
                                    </div>
                                  );
                                }
                                if (
                                  line.includes('name') &&
                                  hunk.lines[i + 1] &&
                                  hunk.lines[i + 1].includes('value') &&
                                  hunk.lines[i + 1][0] === '+' &&
                                  hunk.lines[i + 2] &&
                                  hunk.lines[i + 2].includes('name')
                                ) {
                                  return (
                                    <img src={`${S3_BASE_LOCATION}/${ver1guid}${line.match(/(?<=").+?(?=")/g)[2]}`} />
                                  );
                                }
                                if (
                                  line.includes('name') &&
                                  hunk.lines[i + 1] &&
                                  hunk.lines[i + 1].includes('value') &&
                                  hunk.lines[i + 1][0] === '-' &&
                                  hunk.lines[i + 2] &&
                                  hunk.lines[i + 2].includes('name')
                                ) {
                                  return (
                                    <img src={`${S3_BASE_LOCATION}/${ver2guid}${line.match(/(?<=").+?(?=")/g)[2]}`} />
                                  );
                                }
                                return null;
                              }
                              return (
                                <pre
                                  className={`hunk-line ${line[0] === '+' ? 'green' : ''} ${
                                    line[0] === '-' ? 'red' : ''
                                  }`}
                                  key={i}
                                >
                                  {line}
                                </pre>
                              );
                            })}
                          </div>
                        ))}
                    </div>
                  ) : null
                )}
              </div>
            </TabPane>
          ))}
        </Tabs>
      )}
      <div className="section-wrap" />
    </div>
  );
}

const mapStateToProps = ({ productsData }) => ({
  productsData:
    productsData && productsData.productsData
      ? productsData.productsData.sort((a, b) => (a.name > b.name ? 1 : -1))
      : null,
});

const mapDispatchToProps = {
  getAllProducts,
};

export default connect(mapStateToProps, mapDispatchToProps)(VersionsDiff);
