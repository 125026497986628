import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PossibleInsightsByEmailsTable from './Table';
import { getAllPossibleInsightsByEmails } from '../../redux/actions/possibleInsightsByEmails';

const PossibleInsightsByEmails = ({
  // eslint-disable-next-line no-shadow
  getAllPossibleInsightsByEmails,
  emails,
}) => {
  useEffect(() => {
    getAllPossibleInsightsByEmails();
  }, []);

  if (!emails) {
    return null;
  }

  return (
    <div>
      <PossibleInsightsByEmailsTable emails={emails} />
    </div>
  );
};

const mapStateToProps = ({ possInsightsByEmails }) => ({
  emails: possInsightsByEmails.emails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllPossibleInsightsByEmails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PossibleInsightsByEmails);
