import { NETWORK_LABELS } from '../../constants/networkLabels';
import * as AT from '../actionTypes';

export const getAllProducts = () => ({
  type: AT.GET_ALL_PRODUCTS,
});

export const getAllProductsSuccess = (body) => ({
  type: AT.GET_ALL_PRODUCTS_SUCCESS,
  payload: { productsData: body.data },
});

export const getAllProductsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: `products/all`,
    label: NETWORK_LABELS.GET_ALL_PRODUCTS,
    method: 'GET',
    onSuccess: (body, dispatch) => dispatch(getAllProductsSuccess(body)),
    onFailure: (error, dispatch) => console.log(`getAllProducts fetched: ${error}`),
  },
});

export const addNewProduct = (product) => ({
  type: AT.ADD_NEW_PRODUCT,
  payload: { product },
});

export const addNewProductAPI = (product) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'products',
    label: NETWORK_LABELS.ADD_NEW_PRODUCT,
    method: 'POST',
    data: product,
    onSuccess: (body, dispatch) => dispatch(addNewProductSuccess(body.data)),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const addNewProductSuccess = (product) => ({
  type: AT.ADD_NEW_PRODUCT_SUCCESS,
  payload: { product },
});

export const deleteProduct = (product) => ({
  type: AT.DELETE_PRODUCT,
  payload: { productID: product.id },
});

export const deleteProductAPI = (productID) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `products/${productID}`,
    label: NETWORK_LABELS.DEL_PRODUCT,
    method: 'DELETE',
    onSuccess: (body, dispatch) => dispatch(delProductSuccess(productID)),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const delProductSuccess = (productID) => ({
  type: AT.DELETE_PRODUCT_SUCCESS,
  payload: { productID },
});

export const updateProduct = (product) => ({
  type: AT.UPDATE_PRODUCT,
  payload: { product },
});

export const updateProductAPI = (product) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `products/${product.id}`,
    label: NETWORK_LABELS.UPDATE_PRODUCT,
    method: 'PUT',
    data: product,
    onSuccess: (body, dispatch) => dispatch(updateProductSuccess(body.data)),
    onFailure: (error, dispatch) => console.log(error),
  },
});

export const updateProductSuccess = (product) => ({
  type: AT.UPDATE_PRODUCT_SUCCESS,
  payload: { product },
});
