/* eslint-disable react/prop-types */
import React from 'react';
import { Modal, Form, Input, Row, Col, Select, Checkbox } from 'antd';
import { CUSTOM, DOMAINS } from '../../../constants/notifications';
import { PLATFORMS } from '../../../constants/platforms';

class NotificationForm extends React.Component {
  state = {
    selectedDomains: [],
  };

  handleSelectDomain = (selectedDomains) => {
    this.setState({ selectedDomains });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
      }
    });
  };

  render() {
    const { selectedDomains } = this.state;
    const filteredDomains = DOMAINS.filter((domain) => !selectedDomains.includes(domain));
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="Notification Type">
              {getFieldDecorator('action_type', {
                rules: [
                  {
                    required: true,
                    message: 'notification action type',
                  },
                ],
                initialValue: (this.props.notification && this.props.notification.action_type) || CUSTOM,
              })(<Input placeholder="custom" disabled />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Platform">
              {getFieldDecorator('icon_platform_overlay', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter platform',
                  },
                ],
                initialValue: (this.props.notification && this.props.notification.icon_platform_overlay) || 'Android',
              })(
                <Select filterOption={false} placeholder="Android">
                  {Object.values(PLATFORMS).map((platform) => (
                    <Select.Option value={platform} key={platform}>
                      {platform}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Show in">
              {getFieldDecorator('display_place', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter platform',
                  },
                ],
                initialValue: this.props.notification && this.props.notification.display_place,
              })(
                <Select filterOption={false} placeholder="center" value="center">
                  {['center', 'product', 'both'].map((platform) => (
                    <Select.Option value={platform} key={platform}>
                      {platform}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                rules: [
                  {
                    required: true,
                    message: 'Title',
                  },
                ],
                initialValue: this.props.notification && this.props.notification.title,
              })(<Input placeholder="title" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Description">
              {getFieldDecorator('description', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter notification description',
                  },
                ],
                initialValue: this.props.notification && this.props.notification.description,
              })(<Input placeholder="Description" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Icon">
              {getFieldDecorator('icon', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter an icon',
                  },
                ],
                initialValue: this.props.notification && this.props.notification.icon,
              })(<Input placeholder="Icon" />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Link">
              {getFieldDecorator('link', {
                rules: [
                  {
                    required: !this.props.notification,
                    message: 'Please enter link',
                  },
                ],
                initialValue: this.props.notification && this.props.notification.link,
              })(<Input placeholder="link" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={16}>
            <Form.Item label="Domains">
              {getFieldDecorator('domains', {
                rules: [
                  {
                    required: !this.props.notification,
                    message: 'Please select domains of customers',
                  },
                ],
              })(
                <Select
                  filterOption={false}
                  placeholder=""
                  value={selectedDomains}
                  mode="multiple"
                  onChange={this.handleSelectDomain}
                >
                  {filteredDomains.map((domain) => (
                    <Select.Option value={domain} key={domain}>
                      {domain}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Send email">
              {getFieldDecorator('send_email', {
                rules: [
                  {
                    required: true,
                    message: 'Please enter platform',
                  },
                ],
                initialValue: false,
              })(<Checkbox>Send email?</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Form.Item>
              {getFieldDecorator('is_visible', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: this.props.notification ? this.props.notification.is_visible : true,
              })(<Checkbox>Is Visible</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedNotificationCreationForm = Form.create({ name: 'notification_form' })(NotificationForm);

export default function NotificationsModal({ visible, handleHide, handleSubmit, notification }) {
  let formRef = null;

  return (
    <Modal
      title={notification ? `Change notification #${notification.id}` : 'Add new notification'}
      centered
      visible={visible}
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            handleSubmit({ ...values, id: notification ? notification.id : undefined });
            if (notification) {
              formRef.props.form.resetFields();
            }
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
        if (notification) {
          formRef.props.form.resetFields();
        }
      }}
    >
      <WrappedNotificationCreationForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        notification={notification}
      />
    </Modal>
  );
}
