import * as AT from '../actionTypes';
import {
  getAppsDataAPI,
  getReleasesAPI,
  updateAppsAPI,
  addNewAppAPI,
  updAppAPI,
  setAppCategoriesAPI,
  getLocationsAPI,
  addReleaseAPI,
  updReleaseAPI,
  delReleaseAPI,
  refreshPresignedUrlAPI,
  getReportLinkAPI,
} from '../actions/appsReleasesPage';

const releasesMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_APPS_DATA: {
        dispatch(getAppsDataAPI());
        return next(action);
      }
      case AT.GET_LOCATIONS: {
        dispatch(getLocationsAPI());
        return next(action);
      }
      case AT.SET_APP_CATEGORIES: {
        dispatch(setAppCategoriesAPI(action.payload));
        return next(action);
      }
      case AT.ADD_NEW_APPS: {
        const { app } = action.payload;
        dispatch(addNewAppAPI(app));
        return next(action);
      }
      case AT.UPDATE_APP: {
        const { app } = action.payload;
        dispatch(updAppAPI(app));
        return next(action);
      }
      case AT.GET_RELEASES: {
        dispatch(getReleasesAPI(action.payload.appId));
        return next(action);
      }
      case AT.UPDATE_APPS: {
        const { appsData } = action.payload;
        dispatch(updateAppsAPI(appsData));
        return next(action);
      }
      case AT.ADD_RELEASE: {
        const { release } = action.payload;
        dispatch(addReleaseAPI(release));
        return next(action);
      }
      case AT.UPD_RELEASE: {
        const { releaseID, data } = action.payload;
        dispatch(updReleaseAPI(releaseID, data));
        return next(action);
      }
      case AT.DEL_RELEASE: {
        const { releaseID } = action.payload;
        dispatch(delReleaseAPI(releaseID));
        return next(action);
      }
      case AT.REFRESH_PRESIGNED_URL: {
        dispatch(refreshPresignedUrlAPI());
        return next(action);
      }
      case AT.GET_RELEASE_REPORT: {
        const { releaseID, reportDir } = action.payload;
        dispatch(getReportLinkAPI(releaseID, reportDir));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default releasesMiddleware;
