export const L_INTELLIGENCE = 'intelligence';
export const L_BENCHMARK = 'benchmark';
export const L_MARKETING = 'marketing';
const LICENCES = [L_INTELLIGENCE, L_BENCHMARK, L_MARKETING];
export const LICENCES_DATA = {
  [L_INTELLIGENCE]: 'Product Intelligence',
  [L_BENCHMARK]: 'Product Benchmark',
  [L_MARKETING]: 'Marketing Campaigns',
};
export const MATCH_TYPES = ['Match Any', 'Match All'];
export const pagesTypes = {
  INTELLIGENCE: 'pages.intelligence',
  BENCHMARK_OVERVIEW: 'pages.benchmark_overview',
  MARKETING: 'pages.marketing',
};
export const getUserLicence = (userData) => {
  if (Object.keys(userData).length === 0) return {};
  const availItems = userData.permissions;
  return {
    [L_INTELLIGENCE]: availItems.includes(pagesTypes.INTELLIGENCE),
    [L_BENCHMARK]: availItems.includes(pagesTypes.BENCHMARK_OVERVIEW),
    [L_MARKETING]: availItems.includes(pagesTypes.MARKETING),
  };
};
