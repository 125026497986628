import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  isLoading: false,
  error: null,
  tags: [],
  reports: [],
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_TAGS: {
      return {
        ...state,
        error: null,
      };
    }
    case actionTypes.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: action.payload.tags,
      };
    }
    case actionTypes.GET_REPORTS: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        reports: action.payload.reports,
        isLoading: false,
      };
    }
    case actionTypes.API_REQUEST_REPORTS_FAILED: {
      return {
        ...state,
        error: action.payload.error,
        isLoading: false,
      };
    }
    case actionTypes.DEL_REPORT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_REPORT_SUCCESS: {
      const { reportId } = action.payload;
      return {
        ...state,
        reports: state.reports.filter((x) => x.id !== reportId),
        isLoading: false,
      };
    }
    case actionTypes.ADD_REPORT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_REPORT_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        isLoading: false,
        reports: [report, ...state.reports],
      };
    }

    case actionTypes.UPD_REPORT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.UPD_REPORT_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        isLoading: false,
        reports: state.reports.map((x) => (x.id === report.id ? report : x)),
      };
    }
    case actionTypes.ADD_REPORT_TAG: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_REPORT_TAG_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        isLoading: false,
        tags: [...state.tags, ...report.tags.filter((x) => !state.tags.map((y) => y.id).includes(x.id))],
        reports: state.reports.map((x) => (x.id === report.id ? report : x)),
      };
    }
    case actionTypes.DEL_REPORT_TAG: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_REPORT_TAG_SUCCESS: {
      const { reportId, tagId } = action.payload;
      return {
        ...state,
        isLoading: false,
        reports: state.reports.map((r) =>
          r.id === reportId
            ? {
                ...r,
                tags: r.tags.filter((t) => t.id !== tagId),
              }
            : r
        ),
      };
    }
    case actionTypes.ADD_ORG_REPORT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.ADD_ORG_REPORT_SUCCESS: {
      const { report } = action.payload;
      return {
        ...state,
        isLoading: false,
        reports: state.reports.map((x) => (x.id === report.id ? report : x)),
      };
    }
    case actionTypes.DEL_ORG_REPORT: {
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    }
    case actionTypes.DEL_ORG_REPORT_SUCCESS: {
      const { reportId, organizationId } = action.payload;
      return {
        ...state,
        isLoading: false,
        reports: state.reports.map((r) =>
          r.id === reportId
            ? {
                ...r,
                orgs: r.orgs.filter((t) => t.id !== organizationId),
              }
            : r
        ),
      };
    }
    default: {
      return state;
    }
  }
}
