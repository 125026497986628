import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'antd';
import ReactWSPlayer from './ReactWSPlayer';

const VideoStream = React.memo(
  ({
    sendDataToDeviceOnFramerHandle,
    farmerDevice,
    streamURL,

    interactionWithVideo,
  }) => {
    const handleBack = (ev) => {
      sendDataToDeviceOnFramerHandle('back');
    };

    const handleHome = (ev) => {
      sendDataToDeviceOnFramerHandle('go_home');
    };

    const handleEnter = (ev) => {
      sendDataToDeviceOnFramerHandle('press_return_key');
    };

    if (!farmerDevice) {
      return null;
    }

    console.log('VIDEO COMP RERENDER');

    if (!farmerDevice.video_stream_present) {
      return 'Stream is down, please launch display worker ';
    }

    return (
      <div className="video-wrapper">
        <ReactWSPlayer
          streamURL={streamURL}
          farmerDevice={farmerDevice}
          sendDataToDeviceOnFramerHandle={sendDataToDeviceOnFramerHandle}
        />
        <div className="video-management">
          {farmerDevice.typ !== 'ios' && <Button onClick={handleBack}>Back</Button>}
          <Button onClick={handleHome}>Home</Button>
          {farmerDevice.typ !== 'ios' && <Button onClick={handleEnter}>Enter</Button>}
        </div>
      </div>
    );
  },
  (prevProps, nextProps) =>
    prevProps.streamURL === nextProps.streamURL &&
    prevProps.farmerDeviceState === nextProps.farmerDeviceState &&
    prevProps.farmerDevice.video_stream_present === nextProps.farmerDevice.video_stream_present // avoid rerender component and blink video
);

export default VideoStream;
