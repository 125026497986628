import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { message, Button } from 'antd';

// Components
import AppSelector from './AppSelector';
import InsightDataForm from './InsightDataForm';

// API and actions
import {
  getInsightsByApp,
  getPersonasByApp,
  getReleasesByApp,
  getInsightByID,
  updInsightByID,
  createInsight,
  removeInsight,
  copyInsightAPI,
  getVariantsByABtestID,
  getAssetsByInsightID,
  getCTAsForInsight,
} from '../../api/insightsAPI';

import { addVariantRelease, delVariantRelease, loadLabels } from '../../redux/actions/insightPage';

import { loadApps } from '../../redux/actions/apps';

import InsightList from './InsightList';

// Const and helpers
import { ALL_PLATFORMS, PLATFORMS } from '../../constants/platforms';

function InsightsPage2({
  // data
  history,
  apps,
  labelsSuggestions,
  isMarketing,
  curUserDetails,
  authUser,

  // methods
  loadApps,
  loadLabels,
  addVariantRelease,
  delVariantRelease,
}) {
  const queryClient = useQueryClient();

  // States
  const [curApp, curAppChange] = useState(null);
  const [curInsight, curInsightChange] = useState(null);

  const updateURL = () => {
    const path = history.location.pathname.split('/');
    const appID = (curApp && curApp.id) || path[2] || '';
    const insID = (curInsight && curInsight.id) || path[3] || '';
    history.replace(`/${isMarketing ? 'marketing' : 'intelligence'}${appID && `/${appID}`}${insID && `/${insID}`}`);
  };

  // API
  // APP DATA
  const AppInsights = useQuery(['AppInsights', curApp ? curApp.id : null], () => getInsightsByApp(curApp.id), {
    enabled: !!(curApp && curApp.id),
  });
  const AppPersonas = useQuery(['AppPersonas', curApp ? curApp.id : null], () => getPersonasByApp(curApp.id), {
    enabled: !!(curApp && curApp.id),
  });
  const AppReleases = useQuery(['AppReleases', curApp ? curApp.id : null], () => getReleasesByApp(curApp.id), {
    enabled: !!(curApp && curApp.id),
  });

  // INSIGHT DATA
  const InsightData = useQuery(
    ['InsightData', curInsight ? curInsight.id : null],
    () => getInsightByID(curInsight.id),
    {
      enabled: !!(curInsight && curInsight.id),
    }
  );
  const ABTestVariants = useQuery(
    [
      'ABTestVarints',
      curInsight && InsightData.data && InsightData.data.data.ab_test ? InsightData.data.data.ab_test.id : null,
    ],
    async () => {
      const data = await getVariantsByABtestID(InsightData.data.data.ab_test.id);
      // sort variants by control, A, B, C.... and images by order_index
      const modVariants = data.data
        .sort((v1, v2) => (v1.control ? 0 : v1.type < v2.type ? -1 : 1))
        .map((v) => ({
          ...v,
          assets: v.assets.sort((a, b) => (a.order_index - b.order_index < 0 ? -1 : 1)),
        }));
      return modVariants;
    },
    {
      enabled: !!(InsightData.data && InsightData.data.data.ab_test && InsightData.data.data.ab_test.id),
    }
  );
  const InsightAssets = useQuery(
    ['InsightAssets', curInsight ? curInsight.id : null],
    async () => {
      const data = await getAssetsByInsightID(curInsight.id);
      const prevAssetsList = {
          type: 'previous',
          id: 'previous',
          releases: [],
        },
        currentAssetsList = {
          type: 'current',
          id: 'current',
          releases: [],
        };
      const insData = queryClient.getQueryData(['InsightData', curInsight.id]);

      const type = insData ? insData.data.type : curInsight.type;

      if (['screen_change', 'removed_feature', 'new_feature', 'indication'].includes(type)) {
        prevAssetsList.assets = [...data.data.filter((a) => a.insight_asset_type === 'previous')];
        currentAssetsList.assets = [...data.data.filter((a) => a.insight_asset_type === 'current')].sort((a, b) =>
          a.order_index - b.order_index < 0 ? -1 : 1
        );
        return [prevAssetsList, currentAssetsList];
      }

      if (isMarketing) {
        currentAssetsList.assets = data.data.sort((a, b) => (a.order_index - b.order_index < 0 ? -1 : 1));
        return [currentAssetsList];
      }
    },
    {
      enabled: !(InsightData.data && InsightData.data.data.ab_test && InsightData.data.data.ab_test.id) && !!curInsight,
    }
  );

  const updateInsightMutation = useMutation(updInsightByID, {
    onSuccess: (res, variables) => {
      queryClient.setQueryData(['InsightData', variables.insight_id], res);
      const previousAppInsights = queryClient.getQueryData(['AppInsights', curApp.id]);
      queryClient.setQueryData(['AppInsights', curApp.id], {
        data: previousAppInsights.data.map((ai) =>
          ai.id === variables.insight_id
            ? {
                ...ai,
                title: res.data.title,
                type: res.data.type,
                should_show: res.data.should_show,
              }
            : ai
        ),
      });
      message.destroy();
      message.success('Insight was saved');

      if (variables.data.current_release_id) {
        setTimeout(() => {
          queryClient.invalidateQueries(['ABTestVarints']);
        }, 300);
      }
      if (variables.data.type) {
        setTimeout(() => {
          queryClient.invalidateQueries(['ABTestVarints']);
          queryClient.invalidateQueries(['InsightAssets']);
        }, 300);
      }
    },
    onError: (data) => {
      message.destroy();
      message.error(JSON.parse(data.message).msg);
    },
  });
  const createInsightMutation = useMutation(createInsight, {
    onSuccess: (res, vars) => {
      const previousAppInsights = queryClient.getQueryData(['AppInsights', curApp.id]);
      queryClient.setQueryData(['AppInsights', curApp.id], {
        data: [{ ...res.data, isNew: true }, ...previousAppInsights.data],
      });
      message.success('Insight was created');
      curInsightChange(res.data);
    },
  });
  const copyInsightMutation = useMutation(copyInsightAPI, {
    onSuccess: (res, vars) => {
      const previousAppInsights = queryClient.getQueryData(['AppInsights', curApp.id]);
      queryClient.setQueryData(['AppInsights', curApp.id], {
        data: [{ ...res.data, isNew: true }, ...previousAppInsights.data],
      });
      message.success('Insight was copied');
      curInsightChange(res.data);
    },
  });
  const removeInsightMutation = useMutation(removeInsight, {
    onSuccess: (res, vars) => {
      const previousAppInsights = queryClient.getQueryData(['AppInsights', curApp.id]);
      queryClient.setQueryData(['AppInsights', curApp.id], {
        data: previousAppInsights.data.filter((ins) => ins.id !== vars.insight_id),
      });
      message.success('Insight was deleted');
      curInsightChange(null);
    },
  });
  const reqUpdateInsight = (insight_id, data) => {
    updateInsightMutation.mutateAsync({
      insight_id,
      data,
    });
  };
  const reqCreateInsight = () => {
    createInsightMutation.mutateAsync({
      app_id: curApp.id,
    });
  };
  const reqRemoveInsight = () => {
    removeInsightMutation.mutateAsync({
      insight_id: curInsight.id,
    });
  };
  const reqCopyInsight = () => {
    copyInsightMutation.mutateAsync({
      insight_id: curInsight.id,
    });
  };

  // Effects
  useEffect(() => {
    loadApps(isMarketing ? PLATFORMS.marketing : ALL_PLATFORMS);
    loadLabels();
    return () => {
      curAppChange(null);
      curInsightChange(null);
      queryClient.invalidateQueries();
    };
  }, []);

  useEffect(() => {
    updateURL();
  }, [curApp, curInsight]);

  useEffect(() => {
    if (apps.length > 0 && !curApp) {
      const path = history.location.pathname.split('/');
      curAppChange(apps.find((a) => a.id === Number(path[2])));
    }
  }, [apps]);

  useEffect(() => {
    const path = history.location.pathname.split('/');
    if (AppInsights && AppInsights.isFetched && !curInsight && path[3]) {
      curInsightChange(AppInsights.data.data.find((ins) => ins.id === Number(path[3])));
    }
  }, [AppInsights.isFetched]);

  return (
    <div className="insight-page-2">
      <div className="app-selector-and-crt-btn">
        <AppSelector
          onAppSelected={(app) => {
            curInsightChange(null);
            curAppChange(app);
          }}
          isMarketing={isMarketing}
          curApp={curApp}
          allowedPlatforms={isMarketing ? ['Marketing'] : ['Android', 'iOS', 'Web', 'Chrome Extension', 'Windows']}
        />
        {curApp && (
          <Button className="create-ins-btn add-something" icon="plus" onClick={reqCreateInsight}>
            Create Insight
          </Button>
        )}
      </div>
      <div className="ins-2-content">
        <div className="content-list">
          {AppInsights.data && AppInsights.data.data.length > 0 && (
            <InsightList insights={AppInsights.data.data} curInsight={curInsight} curInsightChange={curInsightChange} />
          )}
        </div>
        <div className="content-insight-data">
          {InsightData.data && InsightData.data.data && (
            <InsightDataForm
              key={InsightData.data.data.id}
              origInsight={InsightData.data.data}
              isMarketing={isMarketing}
              updateInsight={reqUpdateInsight}
              removeInsight={reqRemoveInsight}
              copyInsight={reqCopyInsight}
              isUpdating={updateInsightMutation.isLoading}
              appID={curApp ? curApp.id : null}
              releasesList={AppReleases && AppReleases.data ? AppReleases.data : null}
              ABTestVariants={ABTestVariants}
              InsightAssets={InsightAssets}
              InsightPersonasList={AppPersonas && AppPersonas.data ? AppPersonas.data : null}
              labelsSuggestions={labelsSuggestions}
              onReleaseAdd={(variantId, releaseId) => addVariantRelease(abTestId, variantId, releaseId)}
              onReleaseRemove={(variantId, releaseId) => delVariantRelease(abTestId, variantId, releaseId)}
              curApp={curApp}
              curUserDetails={curUserDetails}
              authUser={authUser}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  authUser: state.usersPage.user,
  curUserDetails: state.loginData.curUserDetails,
  apps: state.apps.all,
  labelsSuggestions: state.insightPage.labels,
});

const mapDispatchToProps = {
  loadApps,
  loadLabels,
  addVariantRelease,
  delVariantRelease,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsPage2);
