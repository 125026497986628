import React from 'react';
import { Modal, Form, Input, InputNumber, Row, Col } from 'antd';

class TimelineForm extends React.Component {
  render() {
    const { form, timeline } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Form.Item label="Day">
              {getFieldDecorator('day', {
                rules: [{ required: true }],
                initialValue: timeline ? timeline.day : null,
              })(<InputNumber placeholder="Day" />)}
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label="Event">
              {getFieldDecorator('event', {
                rules: [{ max: 255, required: true }],
                initialValue: timeline ? timeline.event : '',
              })(<Input.TextArea placeholder="Event" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(TimelineForm);

export default ({ timeline, onHide, onAddTimeline, onUpdTimeline }) => {
  let formRef = null;

  return (
    <Modal
      title={timeline ? `Change Timeline #${timeline.id}` : 'Add new Timeline'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (timeline) {
              onUpdTimeline(timeline.id, values);
            } else {
              onAddTimeline(values);
            }
            formRef.props.form.resetFields();
            onHide();
          });
        }
      }}
      onCancel={() => {
        onHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        timeline={timeline}
      />
    </Modal>
  );
};
