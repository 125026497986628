import React, { useEffect, useState } from 'react';
import { Input, Button, Spin, message, Upload } from 'antd';
import { connect } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import { getNewApp, getPresignedUrl } from '../../api/versionDiffAPI';

function NewAppDiff({}) {
  const [appURL, appURLChange] = useState('');
  const [sendRequest, sendRequestChange] = useState(false);
  const [presignedUrl, presignedUrlChange] = useState(null);
  const getPresignedUrlMutation = useMutation(getPresignedUrl, {
    onSuccess: (data) => presignedUrlChange(data),
  });

  const gettingNewApp = useQuery(['getNewAppURL', appURL], () => getNewApp(appURL), {
    enabled: !!appURL && !!sendRequest,
    retry: false,
  });

  useEffect(() => {
    sendRequestChange(gettingNewApp.isLoading);
  }, [gettingNewApp.isLoading]);

  useEffect(() => {
    if (gettingNewApp.status === 'success') {
      message.success('Analyse is done. Please refresh.');
    }
  }, [gettingNewApp.status]);

  useEffect(() => {
    if (gettingNewApp.isError) {
      message.error('Something went wrong');
    }
  }, [gettingNewApp.isError]);

  const handleGetNewApp = () => {
    sendRequestChange(true);
  };
  return (
    <div className="new-app-diff">
      <h2>Get new app</h2>
      <div className="get-new-app">
        <div style={{ width: '30rem', padding: '1rem 0' }}>
          <Upload
            name="file"
            multiple={false}
            listType="text"
            accept=".apk"
            beforeUpload={async (file) => getPresignedUrlMutation.mutateAsync(file.name)}
            action={() => presignedUrl.s3_data.url}
            data={() => presignedUrl.s3_data.fields}
            onChange={(info) => info.file.status === 'done' && appURLChange(presignedUrl.url)}
          >
            <Button icon="upload">Upload APK</Button>
            <div>{getPresignedUrlMutation.isLoading && 'Requesting resigned url...'}</div>
            <div>{getPresignedUrlMutation.isError && `Error: ${JSON.stringify(getPresignedUrlMutation.error)}`}</div>
          </Upload>
        </div>
        <Input value={appURL} onChange={(e) => appURLChange(e.currentTarget.value)} />
        <Button onClick={handleGetNewApp}>Analyze</Button>
        {gettingNewApp.isLoading && <Spin size="large" />}
      </div>
      <div className="list-sites">
        <a href="https://f-droid.org/en/" target="_blank">
          {' '}
          https://f-droid.org/en/{' '}
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = ({ productsData }) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NewAppDiff);
