import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

// Get all possible emails

export const getAllPossibleInsightsByEmails = () => ({
  type: AT.GET_POSSIBLE_INSIGHTS_BY_EMAILS,
});

export const getAllPossibleInsightsByEmailsAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'insight/by-email',
    label: NETWORK_LABELS.GET_POSSIBLE_EMAILS,
    onSuccess: (body, dispatch) => dispatch(getAllPossibleInsightsByEmailsSuccess(body)),
  },
});

export const getAllPossibleInsightsByEmailsSuccess = (data) => ({
  type: AT.GET_POSSIBLE_INSIGHTS_BY_EMAILS_SUCCESS,
  payload: data.data,
});
