import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const apiRequestFailed = (error) => ({
  type: AT.API_REQUEST_ORCHESTRATOR_FAILED,
  payload: { error },
});

export const addNewOrchestrator = (orchestrator) => ({
  type: AT.ADD_NEW_ORCHESTRATOR,
  payload: { orchestrator },
});

export const addNewOrchestratorAPI = (orchestrator) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'admin/orchestrators',
    label: NETWORK_LABELS.ADD_NEW_ORCHESTRATOR,
    method: 'POST',
    data: orchestrator,
    onSuccess: (body, dispatch) => dispatch(addNewOrchestratorSuccess(body.data)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error)),
  },
});

export const addNewOrchestratorSuccess = (orchestrator) => ({
  type: AT.ADD_NEW_ORCHESTRATOR_SUCCESS,
  payload: { orchestrator },
});

export const updOrchestrator = (orchestrator) => ({
  type: AT.UPDATE_ORCHESTRATOR,
  payload: { orchestrator },
});

export const updOrchestratorAPI = (orchestrator) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/orchestrators/${orchestrator.id}`,
    label: NETWORK_LABELS.UPDATE_ORCHESTRATOR,
    method: 'PUT',
    data: orchestrator,
    onSuccess: (body, dispatch) => dispatch(updOrchestratorSuccess(body.data)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error)),
  },
});

export const updOrchestratorSuccess = (orchestrator) => ({
  type: AT.UPDATE_ORCHESTRATOR_SUCCESS,
  payload: { orchestrator },
});

export const delOrchestrator = (orchestratorId) => ({
  type: AT.DELETE_ORCHESTRATOR,
  payload: { orchestratorId },
});

export const delOrchestratorAPI = (orchestratorId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `admin/orchestrators/${orchestratorId}`,
    label: NETWORK_LABELS.DELETE_ORCHESTRATOR,
    method: 'DELETE',
    onSuccess: (body, dispatch) => dispatch(delOrchestratorSuccess(orchestratorId)),
    onFailure: (error, dispatch) => dispatch(apiRequestFailed(error)),
  },
});

export const delOrchestratorSuccess = (orchestratorId) => ({
  type: AT.DELETE_ORCHESTRATOR_SUCCESS,
  payload: { orchestratorId },
});
