/* eslint-disable react/no-multi-comp */
import { Button, Icon } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { Circle, Image, Layer, Stage } from 'react-konva';
import Spinner from '../../../../../Insight2/InsightComponents/ImageCanvas/Spinner';
import { Rectangle } from '../../../../../workbench/components/canvasedDeviceViewer/Rectangle';
import { TransformerComponent } from '../../../../../workbench/components/canvasedDeviceViewer/TranformerComponent';
import { UJ_ACTION_TYPES } from '../../../../../workbench/utils/consts';

const CANVAS_WIDTH_PORTRAIT = 350;
const CANVAS_HEIGHT_PORTRAIT = 500;
const CANVAS_WIDTH_LANDSCAPE = 500;
const CANVAS_HEIGHT_LANDSCAPE = 350;
const MODAL_ZOOM_FACTOR = 2.5;
const normalizeX = (x, imageWidth, canvasWidth) => Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y, imageHeight, canvasHeight) => Math.round((canvasHeight * y) / imageHeight);

const deNormalizeX = (x, imageWidth, canvasWidth) => Math.round((imageWidth * x) / canvasWidth);
const deNormalizeY = (y, imageHeight, canvasHeight) => Math.round((imageHeight * y) / canvasHeight);

const ControlButtons = ({ onAddMarker, onAddBlur, onRemoveRect, disableAddMarker, disableRemove, isZoomIn }) =>
  !isZoomIn ? (
    <div>
      <div className="canv-btn-row">
        <Button.Group>
          <Button icon disabled={disableRemove} onClick={onRemoveRect} className="canv-btn-short">
            <Icon type="minus" />
          </Button>
        </Button.Group>
      </div>
      <div className="canv-btn-row">
        <Button disabled={disableAddMarker} onClick={onAddMarker} className="canv-btn-full">
          Mark
        </Button>
      </div>
      <div className="canv-btn-row">
        <Button onClick={onAddBlur} className="canv-btn-full">
          Blur
        </Button>
      </div>
    </div>
  ) : (
    <div>
      <div className="canv-btn-row zoom-in">
        <Button icon disabled={disableRemove} onClick={onRemoveRect} className="canv-btn-short">
          <Icon type="minus" />
        </Button>
        <Button disabled={disableAddMarker} onClick={onAddMarker} className="canv-btn-full">
          Mark
        </Button>
        <Button onClick={onAddBlur} className="canv-btn-full">
          Blur
        </Button>
      </div>
    </div>
  );

ControlButtons.propTypes = {
  onAddMarker: PropTypes.func.isRequired,
  onAddBlur: PropTypes.func.isRequired,
  onRemoveRect: PropTypes.func.isRequired,
  disableAddMarker: PropTypes.bool.isRequired,
  disableRemove: PropTypes.bool.isRequired,
};

export class CanvasedImage extends React.PureComponent {
  constructor(props) {
    super(props);
    let canvasWidth = CANVAS_WIDTH_PORTRAIT;
    let canvasHeight = CANVAS_HEIGHT_PORTRAIT;

    if (props.isLandscape) {
      canvasWidth = CANVAS_WIDTH_LANDSCAPE; // window.screen.width * 0.8;
      canvasHeight = CANVAS_HEIGHT_LANDSCAPE; // window.screen.height * 0.8;
    }

    if (props.isZoomIn) {
      canvasWidth *= MODAL_ZOOM_FACTOR;
      canvasHeight *= MODAL_ZOOM_FACTOR;
    }

    this.state = {
      image: null,
      internalRects: {},
      showControlButtons: false,
      selectedRectKey: null,
      selectedRect: null,
      canvasWidth,
      canvasHeight,
    };
  }

  handleClickOutside = (event) => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(this);
    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ selectedRect: null, selectedRectKey: null });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    const { rects } = this.props;
    const image = new window.Image();
    image.src = this.props.imgSrc;
    image.onload = () => {
      const canvasWidth = this.props.isLandscape
        ? CANVAS_WIDTH_LANDSCAPE
        : normalizeX(image.width, image.height, CANVAS_WIDTH_LANDSCAPE);
      const canvasHeight = this.props.isLandscape
        ? normalizeY(image.height, image.width, CANVAS_HEIGHT_PORTRAIT)
        : CANVAS_HEIGHT_PORTRAIT;
      const internalRects = !rects
        ? {}
        : Object.entries(rects).reduce((result, entry) => {
            const rect = entry[1];
            result[entry[0]] = {
              x: normalizeX(
                rect.left,
                image.width,
                this.props.isZoomIn ? canvasWidth * MODAL_ZOOM_FACTOR : canvasWidth
              ),
              y: normalizeY(
                rect.top,
                image.height,
                this.props.isZoomIn ? canvasHeight * MODAL_ZOOM_FACTOR : canvasHeight
              ),
              width: normalizeX(
                rect.right - rect.left,
                image.width,
                this.props.isZoomIn ? canvasWidth * MODAL_ZOOM_FACTOR : canvasWidth
              ),
              height: normalizeY(
                rect.bottom - rect.top,
                image.height,
                this.props.isZoomIn ? canvasHeight * MODAL_ZOOM_FACTOR : canvasHeight
              ),
              type: rect.type,
            };
            return result;
          }, {});
      this.setState({
        image,
        internalRects,
        showControlButtons: true,
        canvasWidth,
        canvasHeight,
      });
    };
  }

  handleImageMouseDown = (key, e) => {
    const clickedOnTransformer = e.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    this.setState({
      selectedRect: e.target,
      selectedRectKey: key,
    });
  };

  handleRectChange = (key, newProps) => {
    const width = newProps.width > 1 ? newProps.width : 100;
    const height = newProps.height > 1 ? newProps.height : 100;

    newProps = { ...newProps, width, height };
    newProps.key = key;
    this.onRectChange(newProps);
  };

  onRectChange(rect) {
    const { image } = this.state;
    const { isZoomIn } = this.props;
    const deZoomIn = (value) => (isZoomIn ? value / MODAL_ZOOM_FACTOR : value);
    this.props.onRectChange({
      top: deNormalizeY(deZoomIn(rect.y), image.height, this.state.canvasHeight),
      left: deNormalizeX(deZoomIn(rect.x), image.width, this.state.canvasWidth),
      right: deNormalizeX(deZoomIn(rect.width + rect.x), image.width, this.state.canvasWidth),
      bottom: deNormalizeY(deZoomIn(rect.height + rect.y), image.height, this.state.canvasHeight),
      key: rect.key,
      type: rect.type,
    });
    this.setState({
      selectedRectKey: rect.key,
    });
  }

  handleDeleteRect = () => {
    const { selectedRectKey, internalRects } = this.state;
    if (selectedRectKey) {
      this.props.onRectChange({ removed: selectedRectKey });
      delete internalRects[selectedRectKey];
      this.setState({
        internalRects,
        selectedRectKey: null,
        selectedRect: null,
      });
    }
  };
  handleAddRect = (rectType) => {
    const { image } = this.state;
    const newRect = {
      x: 0,
      y: 0,
      width: normalizeX(500, image.width, this.state.canvasWidth),
      height: normalizeY(250, image.height, this.state.canvasHeight),
    };

    newRect.key = new Date().getTime();
    newRect.type = rectType;
    this.onRectChange(newRect);
    this.state.internalRects[newRect.key] = newRect;
    this.setState({ internalRects: this.state.internalRects });
  };

  handleAddMarker = () => {
    this.handleAddRect('marker');
  };
  handleAddBlur = () => {
    this.handleAddRect('blur');
  };

  render() {
    return (
      <div className="canvased-image">
        {this.props.asset.type === 'image' && (
          <div className="canvased-image__controls">
            {this.state.showControlButtons && (
              <ControlButtons
                onRemoveRect={this.handleDeleteRect}
                onAddMarker={this.handleAddMarker}
                onAddBlur={this.handleAddBlur}
                disableAddMarker={false}
                disableRemove={!this.state.selectedRectKey}
                onZoomIn={this.props.onZoomIn}
                isZoomIn={this.props.isZoomIn}
              />
            )}
          </div>
        )}

        <div className="canvased-image__canvas">
          {!this.props.isZoomIn && (
            <div className="zoom-in-button">
              <Button onClick={this.props.onZoomIn} className="canv-btn-short">
                <Icon type="zoom-in" />
              </Button>
            </div>
          )}
          <Stage
            width={this.props.isZoomIn ? this.state.canvasWidth * MODAL_ZOOM_FACTOR : this.state.canvasWidth}
            height={this.props.isZoomIn ? this.state.canvasHeight * MODAL_ZOOM_FACTOR : this.state.canvasHeight}
          >
            <Layer>
              {this.state.image && (
                <Image
                  image={this.state.image}
                  name="image"
                  width={this.props.isZoomIn ? this.state.canvasWidth * MODAL_ZOOM_FACTOR : this.state.canvasWidth}
                  height={this.props.isZoomIn ? this.state.canvasHeight * MODAL_ZOOM_FACTOR : this.state.canvasHeight}
                />
              )}
              {Object.entries(this.state.internalRects).map(([key, value]) => (
                <Rectangle
                  {...value}
                  name="rect"
                  key={key}
                  onTransform={(newProps) => this.handleRectChange(key, newProps)}
                  onMouseDown={(e) => this.handleImageMouseDown(key, e)}
                />
              ))}
              {this.props.action && this.props.action.action_type === UJ_ACTION_TYPES.Click && this.state.image && (
                <Circle
                  radius={25}
                  stroke="#f00000"
                  fill="rgba(233, 7, 7, 0.54)"
                  opacity={0.8}
                  strokeWidth={3}
                  x={normalizeX(this.props.action.action_coords.x, this.state.image.width, this.state.canvasWidth)}
                  y={normalizeY(this.props.action.action_coords.y, this.state.image.height, this.state.canvasHeight)}
                />
              )}
              <TransformerComponent selectedRect={this.state.selectedRect} />
            </Layer>
          </Stage>
        </div>
        {!this.props.isZoomIn && (
          <div className="preview-image">
            <div className="preview">
              <div className="preview-label">Preview</div>
              <div
                className={`update-preview-btn ${this.props.isLandscape ? 'landscape' : ''}`}
                onClick={this.props.updatePreview}
              />

              <Button
                className="add-something"
                disabled={this.props.updatePreviewIsloading}
                onClick={this.props.updatePreview}
              >
                Refresh preview
              </Button>
            </div>
            <div
              className="preview-wrapper"
              style={{
                width: `${this.state.canvasWidth}px`,
                height: `${this.state.canvasHeight + (this.props.isLandscape ? 27 : 24)}px`,
              }}
            >
              <img src={this.props.previewLink || this.props.url_annotated} />
              {this.props.updatePreviewIsloading && <Spinner />}
            </div>
          </div>
        )}
      </div>
    );
  }
}

CanvasedImage.defaultProps = {
  rects: null,
};

CanvasedImage.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  rects: PropTypes.shape({}),
  onRectChange: PropTypes.func.isRequired,
  isLandscape: PropTypes.bool.isRequired,
};
