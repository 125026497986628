import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Button, Modal, Select, message } from 'antd';
import { updOrgPerms } from '../../../api/appsAPI';
import { getOrgs } from '../../../api/organizationsAPI';
import { ORG_PERMS } from '../../../constants/apps';

export default ({ apps, onClose }) => {
  const [currOrgs, setCurrOrgs] = useState([]);
  const [currApps, setCurrApps] = useState([]);
  const [curOrgPerm, setCurOrgPerm] = useState('nothing');
  const [appSearchQuery, setAppSearchQuery] = useState('');
  const orgs = useQuery(['orgs'], getOrgs);
  const updOrgPermsMutation = useMutation(updOrgPerms, {
    onSuccess: async (data) => message.success(`Done! added ${data.meta.added}, updated ${data.meta.updated}`),
  });
  const isLoading = [orgs, updOrgPermsMutation].some((x) => x.isLoading);
  const orgsList = orgs.isFetched ? orgs.data.data : [];
  const filteredApps = appSearchQuery.length
    ? apps
        .filter((x) => (x.package_name + (x.display_name || '')).toLowerCase().includes(appSearchQuery.toLowerCase()))
        .slice(0, 100)
    : [];

  return (
    <Modal
      title="Org Permissions"
      visible
      onOk={onClose}
      onCancel={onClose}
      closable
      centered
      width="60%"
      height="40%"
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <div style={{ display: 'flex' }}>
        <Select
          placeholder="Organizations"
          mode="multiple"
          allowClear
          style={{ width: '16rem' }}
          loading={isLoading}
          onChange={setCurrOrgs}
          value={currOrgs}
          showSearch
          filterOption={(input, option) => option.props.children.toLowerCase().includes(input.toLowerCase())}
        >
          {orgsList.map((org) => (
            <Select.Option value={org.id} key={org.id}>
              {org.name}
            </Select.Option>
          ))}
        </Select>
        <Select
          style={{ width: '26rem', marginLeft: '1rem' }}
          onSearch={setAppSearchQuery}
          placeholder="Apps"
          mode="multiple"
          allowClear
          onChange={setCurrApps}
          value={currApps}
          showSearch
          filterOption={false}
        >
          {filteredApps.map((app) => (
            <Select.Option value={app.id} key={app.id}>
              {app.app_name}({app.package_name}) - {app.platform}
            </Select.Option>
          ))}
        </Select>
        <Select value={curOrgPerm} style={{ width: '16rem', marginLeft: '1rem' }} onChange={setCurOrgPerm}>
          {Object.entries(ORG_PERMS).map(([key, value]) => (
            <Select.Option value={key}>{value}</Select.Option>
          ))}
        </Select>
        <Button
          type="primary"
          style={{ marginLeft: '1rem', width: '7rem' }}
          loading={isLoading}
          disabled={isLoading || !currApps || !currOrgs}
          onClick={() =>
            updOrgPermsMutation.mutateAsync({
              body: {
                app_ids: currApps,
                org_ids: currOrgs,
                has_performance: ['both', 'performance'].includes(curOrgPerm) || undefined,
                has_experiments: ['both', 'experiments'].includes(curOrgPerm) || undefined,
              },
            })
          }
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
