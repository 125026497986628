import { getInsightPersonasAPI } from '../actions/insightPage';
import * as AT from '../actionTypes';

const insightPersonasMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_INSIGHT_PERSONAS: {
        dispatch(getInsightPersonasAPI(action.payload.appId));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default insightPersonasMiddleware;
