import React from 'react';
import { Modal, Form, Input, Checkbox, Select, Row, Col } from 'antd';

class DeviceForm extends React.Component {
  render() {
    const { form, device, deviceModels, orchestrators } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Form.Item label="Device Model">
          {getFieldDecorator('device_model_id', {
            rules: [{ required: true }],
            initialValue: device && device.device_model_id,
          })(
            <Select placeholder="Device Model" allowClear>
              {deviceModels.map((deviceModel) => (
                <Select.Option value={deviceModel.id} key={deviceModel.id}>
                  {deviceModel.manufacturer} {deviceModel.product_name} - {deviceModel.model} - {deviceModel.platform}{' '}
                  {deviceModel.os_version}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label="Device ID">
          {getFieldDecorator('device_id', {
            rules: [
              {
                required: true,
                max: 30,
              },
            ],
            initialValue: device && device.device_id,
          })(<Input placeholder="Device ID" />)}
        </Form.Item>
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <Form.Item label="OS Version">
              {getFieldDecorator('os_version', {
                rules: [{ max: 100 }],
                initialValue: device && device.os_version,
              })(<Input placeholder="OS Version" />)}
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="OS Build">
              {getFieldDecorator('os_build', {
                rules: [{ max: 100 }],
                initialValue: device && device.os_build,
              })(<Input placeholder="OS Build" />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              {getFieldDecorator('is_rooted', {
                rules: [{ required: true }],
                valuePropName: 'checked',
                initialValue: device ? device.is_rooted : false,
              })(<Checkbox>Is Rooted</Checkbox>)}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Orchestrator">
          {getFieldDecorator('orchestrator_id', {
            rules: [{ required: true }],
            initialValue: device && device.orchestrator_id,
          })(
            <Select placeholder="Orchestrator">
              {orchestrators.map((orchestrator) => (
                <Select.Option value={orchestrator.id} key={orchestrator.id}>
                  {orchestrator.hostname}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item label="API url">
          {getFieldDecorator('api_url', {
            rules: [{ max: 200 }],
            initialValue: device && device.api_url,
          })(<Input placeholder="API url" />)}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(DeviceForm);

export default ({ device, deviceModels, handleHide, addSubmit, updSubmit, orchestrators }) => {
  let formRef = null;

  return (
    <Modal
      title={device ? `Change device #${device.id}` : 'Add new device'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (device) {
              updSubmit(device.id, values);
            } else {
              addSubmit(values);
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        device={device}
        deviceModels={deviceModels}
        orchestrators={orchestrators}
      />
    </Modal>
  );
};
