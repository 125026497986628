import * as AT from '../actionTypes';
import { NETWORK_LABELS } from '../../constants/networkLabels';

export const apiRequestDevicesFailed = (error) => ({
  type: AT.API_REQUEST_DEVICES_FAILED,
  payload: { error },
});

// Get all devices

export const getAllDevices = () => ({
  type: AT.GET_DEVICES,
});

export const getAllDevicesAPI = () => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'devices',
    label: NETWORK_LABELS.GET_DEVICES,
    onSuccess: (body, dispatch) => dispatch(getAllDevicesSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDevicesFailed(error)),
  },
});

export const getAllDevicesSuccess = (data) => ({
  type: AT.GET_DEVICES_SUCCESS,
  payload: { devices: data.data },
});

// Update device

export const updDevice = (deviceId, data) => ({
  type: AT.UPD_DEVICE,
  payload: {
    deviceId,
    data,
  },
});

export const updDeviceAPI = (deviceId, data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `devices/${deviceId}`,
    method: 'PUT',
    data,
    label: NETWORK_LABELS.UPD_DEVICE,
    onSuccess: (body, dispatch) => dispatch(updDeviceSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDevicesFailed(error)),
  },
});

export const updDeviceSuccess = (body) => ({
  type: AT.UPD_DEVICE_SUCCESS,
  payload: { data: body.data },
});

// Create device

export const addDevice = (data) => ({
  type: AT.ADD_DEVICE,
  payload: { data },
});

export const addDeviceAPI = (data) => ({
  type: AT.API_REQUEST,
  payload: {
    url: 'devices',
    method: 'POST',
    data,
    label: NETWORK_LABELS.ADD_DEVICE,
    onSuccess: (body, dispatch) => dispatch(addDeviceSuccess(body)),
    onFailure: (error, dispatch) => dispatch(apiRequestDevicesFailed(error)),
  },
});

export const addDeviceSuccess = (body) => ({
  type: AT.ADD_DEVICE_SUCCESS,
  payload: { data: body.data },
});

// Delete device

export const delDevice = (deviceId) => ({
  type: AT.DEL_DEVICE,
  payload: { deviceId },
});

export const delDeviceAPI = (deviceId) => ({
  type: AT.API_REQUEST,
  payload: {
    url: `devices/${deviceId}`,
    method: 'DELETE',
    label: NETWORK_LABELS.DEL_DEVICE,
    onSuccess: (body, dispatch) => dispatch(delDeviceSuccess(deviceId)),
    onFailure: (error, dispatch) => dispatch(apiRequestDevicesFailed(error)),
  },
});

export const delDeviceSuccess = (deviceId) => ({
  type: AT.DEL_DEVICE_SUCCESS,
  payload: { deviceId },
});
