import moment from 'moment';

// eslint-disable-next-line camelcase
export const createInsight = (createScreenChangeInsight, app, node_id, formValues) => {
  const insight = {
    type: formValues.type,
    state: 'new',
    priority: null,
    title: formValues.title,
    description: formValues.description,
    start_time: moment().format('YYYY-MM-DD'),
    ab_test_id: null,
    should_show: false,
    is_deleted: false,
    version_code: app.version.split('__')[0],
    app_id: app.app.id,
    other_release_id: null,
    labels: formValues.labelsSuggestions,
    personas: [app.persona.id],
    relations: [],
    packageName: app.app.package_name,
    content: {}, // content????
    app: app.app,
    node_id,
  };
  console.log(insight, app);
  createScreenChangeInsight(insight);
};
