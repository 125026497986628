import { Spin, Tooltip } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom';
import { VIEWS } from '../../utils/consts';
import './CanvasedDeviceViewer.scss';
import { ManagementPanel } from './managementPanel';
import { SelectCustomRelease } from './selectCustomRelease';
import { VideoStreamCanvas } from './videoStreamCanvas';

const COPY_PASTE_MARGIN = 5;

const MODAL_ZOOM_FACTOR = 1.5;
const normalizeX = (x, imageWidth, canvasWidth) => Math.round((canvasWidth * x) / imageWidth);
const normalizeY = (y, imageHeight, canvasHeight) => Math.round((canvasHeight * y) / imageHeight);

const deNormalizeX = (x, imageWidth, canvasWidth) => Math.round((imageWidth * x) / canvasWidth);
const deNormalizeY = (y, imageHeight, canvasHeight) => Math.round((imageHeight * y) / canvasHeight);

const DEFAULT_MARK_WIDTH = 500;
const DEFAULT_MARK_HEIGHT = 250;

const SMALL_MARK_WIDTH = 250;
const SMALL_MARK_HEIGHT = 152;

export class CanvasedDeviceViewer extends React.PureComponent {
  static lastMouseDown = null;

  constructor(props) {
    super(props);
    this.state = {
      image: {
        width: 1080,
        height: 2400,
      },
      showControlButtons: true,
      selectedRectKey: null,
      selectedRect: null,
      internalRects: {},
    };
  }

  handleClickOutside = (event) => {
    // eslint-disable-next-line react/no-find-dom-node
    const domNode = ReactDOM.findDOMNode(this);
    CanvasedDeviceViewer.lastMouseDown = event.target;

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({ selectedRect: null, selectedRectKey: null });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    document.removeEventListener('copy', this.handleCopy, true);
    document.removeEventListener('paste', this.handlePaste, true);
  }

  componentDidUpdate() {
    if (
      this.state.image &&
      this.state.internalRects &&
      this.props.rects &&
      this.props.rects.length !== Object.keys(this.state.internalRects).length
    ) {
      // update current rects if prop rects have changed
      const newRects = {};
      this.props.rects.forEach((rect) => {
        const top = normalizeY(rect.top, this.state.image.height, this.props.canvasHeight);
        const bottom = normalizeY(rect.bottom, this.state.image.height, this.props.canvasHeight);
        const left = normalizeX(rect.left, this.state.image.width, this.props.canvasWidth);
        const right = normalizeX(rect.right, this.state.image.width, this.props.canvasWidth);
        const height = Math.abs(bottom - top);
        const width = Math.abs(right - left);
        newRects[rect.key] = {
          y: top,
          x: left,
          width,
          height,
          type: rect.type,
          key: rect.key,
          isHint: rect.isHint,
          hintType: rect.hintType,
          text: rect.text,
        };
      });
      this.setState({
        internalRects: newRects,
        selectedRect: null,
        selectedRectKey: null,
      });
    }

    if (
      this.props.deviceWidth &&
      this.props.deviceHeight &&
      (this.state.image.width !== this.props.deviceWidth || this.state.image.height !== this.props.deviceHeight)
    ) {
      this.setState({
        image: {
          width: this.props.deviceWidth,
          height: this.props.deviceHeight,
        },
      });
    }
  }

  handlePaste = async () => {
    const canvas = document.querySelector(
      `#${this.props.view === VIEWS.VIEW_LEFT ? 'left-canvas' : 'right-canvas'} canvas`
    );

    if (CanvasedDeviceViewer.lastMouseDown !== canvas) return;

    const { x, y, height, width, type } = JSON.parse(await navigator.clipboard.readText());

    if (
      typeof x !== 'number' ||
      typeof y !== 'number' ||
      typeof height !== 'number' ||
      typeof width !== 'number' ||
      !type
    )
      return;

    this.handleAddRect(type, x, y, width, height);
  };

  handleCopy = async () => {
    const canvas = document.querySelector(
      `#${this.props.view === VIEWS.VIEW_LEFT ? 'left-canvas' : 'right-canvas'} canvas`
    );
    const rect = this.props.rects.find((rect) => rect.key === this.state.selectedRectKey);

    if (CanvasedDeviceViewer.lastMouseDown !== canvas) return;

    if (!this.state.selectedRect || !this.state.selectedRectKey || !rect) return;

    const top = normalizeY(rect.top, this.state.image.height, this.props.canvasHeight);
    const bottom = normalizeY(rect.bottom, this.state.image.height, this.props.canvasHeight);
    const left = normalizeX(rect.left, this.state.image.width, this.props.canvasWidth);
    const right = normalizeX(rect.right, this.state.image.width, this.props.canvasWidth);

    const height = Math.abs(bottom - top);
    const width = Math.abs(right - left);

    await navigator.clipboard.writeText(
      JSON.stringify({
        x: left + COPY_PASTE_MARGIN,
        y: top - COPY_PASTE_MARGIN,
        height,
        width,
        type: rect.type,
      })
    );
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    document.addEventListener('copy', this.handleCopy, true);
    document.addEventListener('paste', this.handlePaste, true);
  }

  handleImageMouseDown = (key, e) => {
    const clickedOnTransformer = e.target.getParent().className === 'Transformer';
    if (clickedOnTransformer) {
      return;
    }

    this.setState({
      selectedRect: e.target,
      selectedRectKey: key,
    });
  };

  handleRectChange = (key, newProps) => {
    const width = newProps.width > 1 ? newProps.width : 100;
    const height = newProps.height > 1 ? newProps.height : 100;

    newProps = { ...newProps, width, height };
    newProps.key = key;
    this.onRectChange(newProps);
  };

  onRectChange(rect) {
    const { image } = this.state;
    const { isZoomIn } = this.props;
    const deZoomIn = (value) => (isZoomIn ? value / MODAL_ZOOM_FACTOR : value);

    this.props.onRectChange({
      top: deNormalizeY(deZoomIn(rect.y), image.height, this.props.canvasHeight),
      left: deNormalizeX(deZoomIn(rect.x), image.width, this.props.canvasWidth),
      right: deNormalizeX(deZoomIn(rect.width + rect.x), image.width, this.props.canvasWidth),
      bottom: deNormalizeY(deZoomIn(rect.height + rect.y), image.height, this.props.canvasHeight),
      key: rect.key,
      type: rect.type,
    });

    this.setState({
      selectedRectKey: rect.key,
    });
  }

  handleDeleteRect = () => {
    const { selectedRectKey, internalRects } = this.state;

    if (selectedRectKey) {
      this.props.onRectChange({ removed: selectedRectKey });
      delete internalRects[selectedRectKey];
      this.setState({
        internalRects,
        selectedRectKey: null,
        selectedRect: null,
      });
    }
  };

  handleDeleteAllRects = () => {
    this.props.onRectChange({ removedAll: true });

    this.setState({
      internalRects: [],
      selectedRectKey: null,
      selectedRect: null,
    });
  };

  handleAddRect = (rectType, x, y, width, height) => {
    const { image } = this.state;

    const markWidth = this.state.image.width >= 1080 ? DEFAULT_MARK_WIDTH : SMALL_MARK_WIDTH;
    const markHeight = this.state.image.height >= 2400 ? DEFAULT_MARK_HEIGHT : SMALL_MARK_HEIGHT;

    const newRect = {
      x: x || 0,
      y: y || 0,
      width: width || normalizeX(markWidth, image.width, this.props.canvasWidth),
      height: height || normalizeY(markHeight, image.height, this.props.canvasHeight),
    };

    newRect.key = new Date().getTime();
    newRect.type = rectType;
    this.onRectChange(newRect);
    const newRects = { ...this.state.internalRects, [newRect.key]: newRect };
    this.setState({ internalRects: newRects });
  };

  handleAddMarker = () => {
    this.handleAddRect('marker');
  };
  handleAddBlur = () => {
    this.handleAddRect('blur');
  };

  handleUjActionTrigger = async (action) => {
    if (this.props.imagesCount < 1) {
      await this.props.handlePrintScreen();
    }

    this.props.setDeviceImages((prev) => {
      prev[0] = {
        ...prev[0],
        ujAction: action,
      };

      return [...prev];
    });

    await this.props.handlePrintScreen();
  };

  handleUjAction = (action) => {
    this.props.handleAutoDelayTrigger(() => this.handleUjActionTrigger(action));
  };

  getNormalizedRects = () => {
    return this.props.rects.map(({ left, top, bottom, right }) => ({
      left: normalizeX(left, this.state.image.width, this.props.canvasWidth),
      top: normalizeY(top, this.state.image.height, this.props.canvasHeight),
      right: normalizeX(right, this.state.image.width, this.props.canvasWidth),
      bottom: normalizeY(bottom, this.state.image.height, this.props.canvasHeight),
    }));
  };

  render() {
    const hintsFound = this.props.rects.reduce((acc, curr) => (curr?.isHint ? acc + 1 : acc), 0);
    return (
      <div className={this.props.classes.container}>
        <div className="management-buttons">
          <ManagementPanel
            onRemoveRect={this.handleDeleteRect}
            onRemoveAllRects={this.handleDeleteAllRects}
            onAddMarker={this.handleAddMarker}
            onAddBlur={this.handleAddBlur}
            disableAddMarker={false}
            disableRemove={!this.state.selectedRectKey}
            {...this.props}
          />
        </div>
        <div className="right-part">
          <div className="device-viewer-main">
            <div className="version-wrapper">
              <div className="version-text">Version:</div>
              <div className="version">
                {this.props.isCustomDevice ? (
                  <SelectCustomRelease
                    customAppReleases={this.props.customAppReleases}
                    setAppVer={this.props.setAppVer}
                    deviceAppVer={this.props.deviceAppVer}
                    curAppPackage={this.props.curAppPackage}
                    setDiff={this.props.setDiff}
                    setProgress={this.props.setProgress}
                  />
                ) : this.props.deviceAppVer && this.props.deviceAppVer.version_name ? (
                  this.props.deviceAppVer.version_name
                ) : (
                  ''
                )}{' '}
                |{' '}
              </div>
              <div className={this.props.versionType}>{this.props.versionType}</div>
              <Tooltip title={this.props.deviceConnected ? 'Video Connected' : 'Video Disconnected'}>
                <div className={`device-status ${this.props.deviceConnected ? 'green' : 'red'}`} />
              </Tooltip>
            </div>
            <div className="hints-found">
              {hintsFound > 0 ? `Hints found: ${hintsFound}` : 'No hints'}{' '}
              <Spin spinning={this.props.hintsProgress} size="small" />
            </div>
          </div>
          <div className="canvased-image">
            {this.props.deviceId && (
              <VideoStreamCanvas
                {...this.props}
                handleUjAction={this.handleUjAction}
                internalRects={this.state.internalRects}
                selectedRect={this.state.selectedRect}
                handleRectChange={this.handleRectChange}
                handleImageMouseDown={this.handleImageMouseDown}
                normalizedRects={this.getNormalizedRects()}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
