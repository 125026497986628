import React from 'react';

export const PROBLEMS_AND_SOLTIONS = [
  {
    heading: 'Workbench is not working correctly',
    description: <p>Use iTerm2 and not the MacOS built in Terminal App.</p>,
  },
  {
    heading: 'How do I install the workbench?',
    description: (
      <p>
        You can install the workbench by following the instructions{' '}
        <a href="https://www.notion.so/watchful/How-to-install-workbench-services-in-an-analyst-s-working-station-and-update-it-too-3c200d5457b846a58ff10e18eafef95e">
          here
        </a>
      </p>
    ),
  },
  {
    heading: 'Device is flickering when using the WB what can I do?',
    description: <p>Uninstall the application atx and run again</p>,
  },
  {
    heading: 'The video or the device control is stuck',
    description: (
      <p>
        Click on reconnect devices button and wait a couple of seconds, or try to restart the workbench services and
        reload the page
      </p>
    ),
  },
];
