import * as AT from './../actionTypes';
import {
  createSubscriptionAPI,
  delSubscriptionAPI,
  getUserSubscriptionsAPI,
  updEmailPreferencesAPI,
  updSubscriptionAPI,
} from '../actions/subscriptions';

const subscriptionsMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case AT.GET_USER_SUBSCRIPTIONS: {
        dispatch(getUserSubscriptionsAPI(action.payload.userID, action.payload.data));
        return next(action);
      }
      case AT.CREATE_SUBSCRIPTION: {
        dispatch(createSubscriptionAPI(action.payload.userID, action.payload.data));
        return next(action);
      }
      case AT.UPDATE_SUBSCRIPTION: {
        dispatch(updSubscriptionAPI(action.payload.userID, action.payload.subscriptionID, action.payload.subscription));
        return next(action);
      }
      case AT.DELETE_SUBSCRIPTION: {
        dispatch(delSubscriptionAPI(action.payload.userID, action.payload.subscriptionID));
        return next(action);
      }
      case AT.UPDATE_EMAIL_PREFERENCES: {
        dispatch(updEmailPreferencesAPI(action.payload.userID, action.payload.emailPreferences));
        return next(action);
      }
      default:
        return next(action);
    }
  };

export default subscriptionsMiddleware;
