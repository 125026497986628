import React from 'react';
import { Button, Icon, Popconfirm, Table } from 'antd';

export default ({ timeline, isLoading, onUpdTimeline, onDelTimeline }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'day',
      dataIndex: 'day',
    },
    {
      title: 'event',
      dataIndex: 'event',
    },
    {
      title: '',
      key: 'actions',
      render: (value, record) => (
        <div>
          <Button style={{ margin: '4px' }} type="small" icon="edit" onClick={() => onUpdTimeline(record.id)} />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              onDelTimeline(record.id);
            }}
          >
            <Button icon="delete" type="danger small" />
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={timeline}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{ y: 600 }}
        pagination={false}
      />
    </div>
  );
};
