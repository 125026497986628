import { Input, Pagination, Tabs } from 'antd';
import React, { memo, useMemo, useState } from 'react';
import './DiffPanel.scss';
import { filterDiff } from './utils/filters';

const SUPPORTED_DIFF_TYPES = ['added', 'removed', 'changed'];
const DIFF_PER_PAGE = 150;

const DiffPanelBase = ({ diffObject }) => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const filteredDiff = useMemo(() => {
    const searchString = search.toLowerCase();

    return searchString ? filterDiff(searchString, diffObject) : diffObject;
  }, [search, diffObject]);

  const renderStringDiff = (value) => {
    return <li key={value}>{value}</li>;
  };

  const renderObjectDiff = (value) => {
    return Object.entries(value).map(([k, v]) => {
      if (v !== null && v.constructor === Object) {
        return (
          <li key={k}>
            <h3 className="diff-title">{k}</h3>
            <ul className="diff-list-values-inner">{renderObjectDiff(v)}</ul>
          </li>
        );
      }

      return (
        <li key={k}>
          <h3 className="diff-title">{k}</h3>
          <p className="diff-desc">{v}</p>
        </li>
      );
    });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <div className="diff-main">
      <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Diff" />
      <div className="diff-list-container">
        <Tabs type="card">
          {Object.entries(filteredDiff.diff).map(([category, diff]) => (
            <Tabs.TabPane tab={category} key={category} className="diff-list-item">
              <Tabs className="diff-list-inner-tabs">
                {Object.entries(diff).map(([type, values]) => (
                  <Tabs.TabPane tab={type} key={type} className={SUPPORTED_DIFF_TYPES.includes(type) ? type : 'added'}>
                    <ul className="diff-list-values">
                      {values
                        .slice((page - 1) * DIFF_PER_PAGE, page * DIFF_PER_PAGE)
                        .map((value) =>
                          typeof value === 'string' ? renderStringDiff(value) : renderObjectDiff(value)
                        )}
                    </ul>
                    <Pagination
                      simple
                      onChange={handlePageChange}
                      current={page}
                      total={values.length ? Math.ceil(values.length / DIFF_PER_PAGE) : 1}
                    />
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export const DiffPanel = memo(DiffPanelBase);
