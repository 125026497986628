import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  status: '',
  user: {},
  allApps: [],
  allAppsObject: {},
  allUsers: [],
  error: null,
  productsForUser: [],
  userDetails: {},
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS: {
      const status = 'Loading all users';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.GET_ALL_USERS_SUCCESS: {
      const { data } = action.payload;
      const allUsers = data.users.sort((a, b) => {
        const firstDomain = a.email.substring(a.email.indexOf('@') + 1);
        const secondDomaon = b.email.substring(b.email.indexOf('@') + 1);
        if (firstDomain === secondDomaon) {
          const fistName = a.email.substring(0, a.email.indexOf('@'));
          const secondname = b.email.substring(0, b.email.indexOf('@'));
          return fistName.localeCompare(secondname);
        }
        return firstDomain.localeCompare(secondDomaon);
      });
      return {
        ...state,
        allUsers,
        status: '',
      };
    }
    case actionTypes.GET_ALL_USERS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus}`,
      };
    }
    case actionTypes.GET_ALL_APPS_SUCCESS: {
      const { apps } = action.payload;
      const allAppsObject = {};
      // eslint-disable-next-line array-callback-return
      apps.map((app) => {
        allAppsObject[app.id] = app;
      });
      return {
        ...state,
        allApps: apps,
        allAppsObject,
      };
    }
    case actionTypes.GET_USER_APPS: {
      const status = 'Loading user apps...';
      return {
        ...state,
        status,
      };
    }

    case actionTypes.GET_USER_APPS_SUCCESS: {
      const { apps, userID } = action.payload;
      const allUsers = state.allUsers.map((user) => {
        if (user.id === userID) {
          const userAppsArray = [];
          apps.forEach((app) => {
            if (state.allAppsObject[app.id]) {
              userAppsArray.push({ ...state.allAppsObject[app.id], ...app });
            }
          });
          return { ...user, apps: userAppsArray };
        }
        return { ...user };
      });
      return {
        ...state,
        status: '',
        allUsers,
      };
    }

    case actionTypes.ADD_USER: {
      const status = 'Saving...';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.ADD_USER_SUCCESS: {
      const {
        data: { user },
      } = action.payload;
      user.apps = [];
      const allUsers = [user, ...state.allUsers];
      const status = 'Saved Successfully!';
      return {
        ...state,
        allUsers,
        status,
      };
    }
    case actionTypes.ADD_USER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        status: `Error :${error.message}`,
        error,
      };
    }
    case actionTypes.INIT_USER_MSG: {
      return {
        ...state,
        status: '',
      };
    }
    case actionTypes.GET_USER: {
      const status = 'Loading user data';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.GET_USER_SUCCESS: {
      const { data } = action.payload;
      const status = '';
      return {
        ...state,
        user: data.user,
        status,
      };
    }
    case actionTypes.GET_USER_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: errorStatus,
      };
    }
    case actionTypes.DELETE_USER: {
      return {
        ...state,
      };
    }
    case actionTypes.DELETE_USER_SUCCESS: {
      const {
        data: { user_id },
      } = action.payload;
      const allUsers = state.allUsers.filter((user) => user.id !== user_id);
      return {
        ...state,
        allUsers,
      };
    }
    case actionTypes.DELETE_USER_FAILED: {
      return {
        ...state,
      };
    }
    case actionTypes.REMOVE_USER_APP: {
      const { app, user } = action.payload;
      const allUsersNew = state.allUsers.map((userData) => {
        if (userData.id === user.id) {
          const apps = userData.apps.filter((userApp) => userApp.id !== app.id);
          return { ...userData, apps };
        }
        return userData;
      });
      return {
        ...state,
        allUsers: allUsersNew,
      };
    }
    case actionTypes.ADD_USER_APP: {
      const { app, user } = action.payload;
      const allUsersNew = state.allUsers.map((userData) => {
        if (userData.id === user.id) {
          let apps = userData.apps.filter((userApp) => userApp.id !== app.id);
          if (apps.length === userData.apps.length) {
            apps = [...apps, state.allAppsObject[app.id]];
            return { ...userData, apps };
          }
          return userData;
        }
        return userData;
      });
      return {
        ...state,
        allUsers: allUsersNew,
      };
    }
    case actionTypes.EDIT_USER_APPS: {
      const status = 'Saving user apps...';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.EDIT_USER_APPS_SUCCESS: {
      const status = 'Saved Successfully!';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.EDIT_USER_APPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: errorStatus.message,
      };
    }

    case actionTypes.EDIT_USER_DEATILS: {
      const status = 'Updating user data...';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.EDIT_USER_DEATILS_SUCCESS: {
      const {
        data: { user },
      } = action.payload;
      const allUsers = state.allUsers.map((userData) => {
        if (userData.id !== user.id) {
          return userData;
        }
        const cloneUser = { ...userData };
        cloneUser.admin = user.admin;
        cloneUser.annotator = user.annotator;
        cloneUser.rawData = user.rawData;
        cloneUser.is_disabled = user.is_disabled;
        cloneUser.first_name = user.first_name;
        cloneUser.last_name = user.last_name;
        cloneUser.role = user.role;
        cloneUser.department = user.department;
        cloneUser.phone = user.phone;
        cloneUser.is_org_admin = user.is_org_admin;
        return cloneUser;
      });
      const status = 'Updating Successfully!';
      return {
        ...state,
        allUsers,
        status,
      };
    }
    case actionTypes.EDIT_USER_DEATILS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: errorStatus.message,
      };
    }

    case actionTypes.SET_USER_PASSWORD: {
      const status = 'Updating user password...';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.SET_USER_PASSWORD_SUCCESS: {
      const status = 'Updating Successfully!';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.SET_USER_PASSWORD_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: errorStatus.message,
      };
    }
    case actionTypes.SET_USER: {
      const user = action.payload;
      return {
        ...state,
        user,
      };
    }

    case actionTypes.GET_PRODUCTS_FOR_USER_SUCCESS: {
      const productsForUser = action.payload.data;
      return {
        ...state,
        productsForUser,
      };
    }
    case actionTypes.GET_PRODUCTS_FOR_USER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        status: error.message,
      };
    }

    case actionTypes.GET_USER_DETAILS_SUCCESS: {
      const userDetails = action.payload;
      return {
        ...state,
        userDetails,
      };
    }

    case actionTypes.GET_USER_DETAILS_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        status: error.message,
      };
    }
    case actionTypes.UPDATE_EMAIL_PREFERENCES_SUCCESS: {
      const { email_preferences } = action.payload;
      return {
        ...state,
        userDetails: { ...state.userDetails, email_preferences },
      };
    }

    default: {
      return state;
    }
  }
}
