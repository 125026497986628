import React from 'react';
import { Modal, Form, Input, Select } from 'antd';
import moment from 'moment';
import { countries } from '../../../constants/countries';

class DeviceForm extends React.Component {
  render() {
    const { form, orchestrator } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form>
        <Form.Item label="Orchestrator name">
          {getFieldDecorator('hostname', {
            rules: [
              {
                required: true,
                max: 30,
              },
            ],
            initialValue: orchestrator && orchestrator.hostname,
          })(<Input placeholder="Orchestrator name" />)}
        </Form.Item>
        <Form.Item label="Location">
          {getFieldDecorator('location', {
            rules: [{ required: true }],
            initialValue: orchestrator && orchestrator.location,
          })(
            <Select placeholder="Location" allowClear>
              {countries.map((c) => (
                <Select.Option value={c.code} key={c.code}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(DeviceForm);

export default ({ orchestrator, handleHide, addSubmit, updSubmit }) => {
  let formRef = null;

  return (
    <Modal
      title={orchestrator ? `Change orchestrator #${orchestrator.id}` : 'Add new orchestrator'}
      centered
      visible
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }
            if (orchestrator) {
              updSubmit({ ...orchestrator, ...values });
            } else {
              addSubmit({ ...values, epoch: moment().toISOString() });
            }
            formRef.props.form.resetFields();
            handleHide();
          });
        }
      }}
      onCancel={() => {
        handleHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        orchestrator={orchestrator}
      />
    </Modal>
  );
};
