import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  appsData: [],
  releases: [],
  parentReleasesArray: [],
  isApiRequestLoading: false,
  status: '',
  isRefreshingPresignedUrl: false,
  presignedUrl: null,
  reportDownloadUrls: {},
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_LOCATIONS_SUCCESS: {
      return { ...state, locations: action.payload };
    }
    case actionTypes.SET_APP_CATEGORIES_SUCCESS: {
      const pl = action.payload[0];
      return { ...state, appsData: state.appsData.map((ad) => (ad.app_name === pl.app_name ? pl : ad)) };
    }
    case actionTypes.GET_APPS_DATA: {
      return {
        ...state,
        appsData: [],
        status: 'Loading apps data...',
      };
    }
    case actionTypes.GET_RELEASES: {
      return {
        ...state,
        releases: [],
        status: 'Loading releases...',
      };
    }
    case actionTypes.GET_APPS_DATA_SUCCESS: {
      return {
        ...state,
        status: '',
        appsData: action.payload.appsData || [],
      };
    }
    case actionTypes.GET_RELEASES_SUCCESS: {
      const {
        releases: { releases },
      } = action.payload;
      let parentReleasesArray = releases.map((release) => ({
        value: release.id,
        text: `${release.release_name} (${release.version_code})`,
        key: release.id,
        id: release.id,
      }));
      parentReleasesArray = [
        {
          value: null,
          text: 'null',
          key: 'null',
          id: 'null',
        },
        ...parentReleasesArray,
      ];
      return {
        ...state,
        releases,
        parentReleasesArray,
        status: '',
      };
    }
    case actionTypes.GET_RELEASES_FAILED: {
      return {
        ...state,
      };
    }
    case actionTypes.SET_RELEASE_STATE: {
      const { releaseId } = action.payload;
      const releases = state.releases.map((release) => {
        if (release.id !== releaseId) return release;
        return {
          ...release,
          state: action.payload.state,
          lastChange: new Date(),
        };
      });
      return {
        ...state,
        releases,
      };
    }

    case actionTypes.SET_RELEASE_IS_VISIBLE: {
      const { releaseId } = action.payload;
      const releases = state.releases.map((release) => {
        if (release.id !== releaseId) return release;
        return {
          ...release,
          is_visible: action.payload.isVisible,
          lastChange: new Date(),
        };
      });
      return {
        ...state,
        releases,
      };
    }
    case actionTypes.SET_RELEASE_IS_COMPARISON_COMPLETED: {
      const { releaseId } = action.payload;
      const releases = state.releases.map((release) => {
        if (release.id !== releaseId) return release;
        return {
          ...release,
          is_comparison_completed: action.payload.isComparisonCompleted,
          lastChange: new Date(),
        };
      });
      return {
        ...state,
        releases,
      };
    }

    case actionTypes.SET_RELEASE_PARENT_ID: {
      const { releaseId, paraentId } = action.payload;
      const releases = state.releases.map((release) => {
        if (release.id !== releaseId) return release;
        return {
          ...release,
          parent_release_id: paraentId,
          lastChange: new Date(),
        };
      });
      return {
        ...state,
        releases,
      };
    }
    case actionTypes.ADD_NEW_APPS: {
      return {
        ...state,
        isApiRequestLoading: true,
        status: 'Adding apps...',
      };
    }
    case actionTypes.ADD_NEW_APPS_SUCCESS: {
      return {
        ...state,
        appsData: [action.payload.app, ...state.appsData],
        status: 'Added Successfully!',
        isApiRequestLoading: false,
      };
    }
    case actionTypes.ADD_NEW_APPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }
    case actionTypes.UPDATE_APPS: {
      return {
        ...state,
        status: 'Saving apps...',
      };
    }
    case actionTypes.UPDATE_APP: {
      return {
        ...state,
        isApiRequestLoading: true,
        status: 'Updating app...',
      };
    }
    case actionTypes.UPDATE_APP_SUCCESS: {
      return {
        ...state,
        appsData: state.appsData.map((x) => (x.id === action.payload.app.id ? action.payload.app : x)),
        status: 'Updated Successfully!',
        isApiRequestLoading: false,
      };
    }
    case actionTypes.UPDATE_APP_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }

    case actionTypes.UPDATE_APPS_SUCCESS: {
      return {
        ...state,
        status: 'Saved Successfully!',
      };
    }
    case actionTypes.UPDATE_APPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }
    case actionTypes.ADD_RELEASE: {
      return {
        ...state,
        isApiRequestLoading: true,
        status: 'Adding release...',
      };
    }
    case actionTypes.ADD_RELEASE_SUCCESS: {
      return {
        ...state,
        releases: [action.payload.release, ...state.releases],
        status: 'Added Successfully!',
        isApiRequestLoading: false,
      };
    }
    case actionTypes.ADD_RELEASE_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }
    case actionTypes.UPD_RELEASE: {
      return {
        ...state,
        isApiRequestLoading: true,
        status: 'Updating release...',
      };
    }
    case actionTypes.UPD_RELEASE_SUCCESS: {
      const { release } = action.payload;
      return {
        ...state,
        releases: state.releases.map((x) => (x.id === release.id ? release : x)),
        status: 'Saved Successfully!',
        isApiRequestLoading: false,
      };
    }
    case actionTypes.UPD_RELEASE_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }
    case actionTypes.DEL_RELEASE: {
      return {
        ...state,
        isApiRequestLoading: true,
        status: 'Deleting release...',
      };
    }
    case actionTypes.DEL_RELEASE_SUCCESS: {
      const { releaseID } = action.payload;
      return {
        ...state,
        releases: state.releases.filter((x) => x.id !== releaseID),
        status: 'Saved Successfully!',
        isApiRequestLoading: false,
      };
    }
    case actionTypes.DEL_RELEASE_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus.response.text}`,
      };
    }

    case actionTypes.REFRESH_PRESIGNED_URL: {
      return {
        ...state,
        presignedUrl: null,
        isRefreshingPresignedUrl: true,
        status: 'Refreshing Presigned Url...',
      };
    }
    case actionTypes.REFRESH_PRESIGNED_URL_SUCCESS: {
      const { s3_data, url, filename } = action.payload;
      return {
        ...state,
        status: '',
        presignedUrl: { s3_data, url, filename },
        isRefreshingPresignedUrl: false,
      };
    }
    case actionTypes.REFRESH_PRESIGNED_URL_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus}`,
      };
    }

    case actionTypes.GET_RELEASE_REPORT: {
      const { releaseID, reportDir } = action.payload;
      return {
        ...state,
        reportDownloadUrls: {
          ...state.reportDownloadUrls,
          [releaseID]: state.reportDownloadUrls[releaseID]
            ? {
                ...state.reportDownloadUrls[releaseID],
                [reportDir]: null,
              }
            : { [reportDir]: null },
        },
        status: 'Preparing report URL...',
      };
    }
    case actionTypes.GET_RELEASE_REPORT_SUCCESS: {
      const { meta, download_url } = action.payload;
      return {
        ...state,
        reportDownloadUrls: {
          ...state.reportDownloadUrls,
          [meta.release_id]: {
            ...(state.reportDownloadUrls[meta.release_id] || {}),
            [meta.dir]: {
              url: download_url,
              error: null,
            },
          },
        },
        status: '',
      };
    }
    case actionTypes.GET_RELEASE_REPORT_EMPTY: {
      const { meta, msg } = action.payload;
      return {
        ...state,
        reportDownloadUrls: {
          ...state.reportDownloadUrls,
          [meta.release_id]: {
            ...(state.reportDownloadUrls[meta.release_id] || {}),
            [meta.dir]: {
              url: null,
              error: msg,
            },
          },
        },
        status: '',
      };
    }
    case actionTypes.GET_RELEASE_REPORT_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus}`,
      };
    }
    case actionTypes.ADD_RELEASE_ANALYSIS_ISSUE_SUCCESS: {
      const { issue } = action.payload;
      return {
        ...state,
        releases: state.releases.map((x) =>
          x.id === issue.release_id
            ? {
                ...x,
                analysis_issues: [issue, ...x.analysis_issues],
              }
            : x
        ),
      };
    }
    case actionTypes.UPD_RELEASE_ANALYSIS_ISSUE_SUCCESS: {
      const { issue } = action.payload;
      return {
        ...state,
        releases: state.releases.map((x) =>
          x.id === issue.release_id
            ? {
                ...x,
                analysis_issues: x.analysis_issues.map((y) => (y.id === issue.id ? issue : y)),
              }
            : x
        ),
      };
    }
    case actionTypes.DEL_RELEASE_ANALYSIS_ISSUE_SUCCESS: {
      const { issueId } = action.payload;
      return {
        ...state,
        releases: state.releases.map((x) => ({
          ...x,
          analysis_issues: x.analysis_issues.filter((y) => y.id !== issueId),
        })),
      };
    }
    default: {
      return state;
    }
  }
}
