/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, Button, Popconfirm, Checkbox, message } from 'antd';
import { isEmpty } from 'lodash';
import { setInsightsImportance } from '../../redux/actions/insightPage';
import { getAllUsers } from '../../redux/actions/usersPage';
import { getInsightByIDS, addNotification, getMeaningfulInsightEmailPreview } from '../../redux/actions/emailJSONPage';

const { Option } = Select;

function InsightImportance(props) {
  const [insightIDs, setInsightIDs] = useState([]);
  const [sendMail, setSendMail] = useState(false);
  useEffect(() => {
    if (props.criticalInsights.length > 0) {
      const ins = props.criticalInsights[0];
      props.getMeaningfulInsightEmailPreview({
        insight: ins,
      });
    }
  }, [props.criticalInsights]);

  const handleInsightIDsChange = (value) => {
    setInsightIDs(value);
    props.getInsightByIDS(value);
  };
  const handleAssignInsightsToEmails = ({ removeImportance = false }) => {
    insightIDs.forEach((insightID) => {
      props.setInsightsImportance({ insightID, removeImportance });
    });
    props.addNotification(insightIDs[0], sendMail);
    // }
  };

  const checkboxHandle = (event) => {
    setSendMail(event.target.checked);
  };
  const instructions = `Insert insight ids, separate values with commas`;
  return (
    <div className="insight-importance">
      <div className="left">
        <div>{instructions}</div>
        <div className="insight-importance__inputs">
          <div className="insight-importance__inputs__insight-ids">
            <div>Insight IDs:</div>
            <Select
              style={{ width: '100%' }}
              size="large"
              mode="tags"
              onChange={handleInsightIDsChange}
              tokenSeparators={[',']}
              value={insightIDs}
            >
              {insightIDs.map((option) => (
                <Option key={option}>{option}</Option>
              ))}
            </Select>
          </div>
        </div>

        <div className="row">
          <div className="item">
            <Popconfirm
              title="This will send an email to clients "
              onConfirm={handleAssignInsightsToEmails}
              okText="Ok"
              cancelText="Cancel"
              disabled={isEmpty(insightIDs)}
            >
              <Button type="primary" size="large" shape="round" block disabled={isEmpty(insightIDs)}>
                Add Importance for sending email
              </Button>
            </Popconfirm>
          </div>

          <div className="item">
            <Popconfirm
              title="This will remove importance from the chosen insights"
              onConfirm={() => handleAssignInsightsToEmails({ removeImportance: true })}
              okText="Ok"
              cancelText="Cancel"
              disabled={isEmpty(insightIDs)}
            >
              <Button type="danger" size="large" shape="round" block disabled={isEmpty(insightIDs)}>
                Remove Importance
              </Button>
            </Popconfirm>
          </div>

          <Checkbox className="checkbox" checked={sendMail} disabled={isEmpty(insightIDs)} onChange={checkboxHandle}>
            <div style={{ width: '13rem' }}>Send critical insight mail?</div>
          </Checkbox>
        </div>
      </div>
      <div className="right">
        <div dangerouslySetInnerHTML={{ __html: props.htmlContent }} className="preview-window" />
      </div>
      {props.errorMessage && message.error(props.errorMessage, 3)}
    </div>
  );
}

const mapDispatchToProps = {
  setInsightsImportance,
  getAllUsers,
  getMeaningfulInsightEmailPreview,
  getInsightByIDS,
  addNotification,
};
const mapStateToProps = (state) => ({
  allUsers: state.usersPage.allUsers,
  htmlContent: state.emailJSONPage.htmlContent,
  criticalInsights: state.emailJSONPage.criticalInsights,
  errorMessage: state.emailJSONPage.errorMessage,
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightImportance);
