import { Select, message } from 'antd';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getInsightsByApp } from '../../../../../../api/insightsAPI';
import { addLinkedInsight, deleteLinkedInsight } from '../../../../../../api/userJourneysAPI';
import { ABBR_MAP_TYPE } from '../../../../../../constants/insightType';

const { Option } = Select;

export const LinkedInsight = ({ assetId, linkedInsights, appId }) => {
  const queryClient = useQueryClient();

  const { data: insightSuggestions, isFetching: insightSuggestionsLoading } = useQuery(
    ['insightRelationsSuggestions', assetId],
    () => getInsightsByApp(appId),
    { enabled: !!appId }
  );

  const addInsightRelationMutation = useMutation(addLinkedInsight, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.destroy();
      message.success('Related insight was added');
    },
  });

  const delInsightRelationMutation = useMutation(deleteLinkedInsight, {
    onSuccess() {
      queryClient.invalidateQueries(['GetUserFlows']);
      queryClient.invalidateQueries(['GetUserFlowSets']);
      message.destroy();
      message.success('Related insight was deleted');
    },
  });

  const isLoading = [
    insightSuggestionsLoading,
    addInsightRelationMutation.isLoading,
    delInsightRelationMutation.isLoading,
  ].some((x) => x);

  const handleAddInsight = (insights) => {
    linkedInsights
      .filter((ins) => !insights.includes(ins.id))
      .forEach((x) =>
        delInsightRelationMutation.mutateAsync({
          asset_id: assetId,
          insight_id: x.id,
        })
      );

    insights
      .filter((y) => !linkedInsights.map((x) => x.id).includes(y))
      .forEach((relatedInsightId) =>
        addInsightRelationMutation.mutateAsync({
          asset_id: assetId,
          insight_id: relatedInsightId,
        })
      );
  };

  return (
    <React.Fragment>
      <Select
        mode="multiple"
        placeholder="Related Insights"
        style={{ width: '250px' }}
        maxTagCount={1}
        onChange={handleAddInsight}
        filterOption={(input, option) => option.props.children.join().toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={linkedInsights.map(({ id }) => id)}
        getPopupContainer={(trNode) => trNode}
        disabled={isLoading}
        loading={isLoading}
      >
        {(!insightSuggestionsLoading ? insightSuggestions.data : []).map((insight) => (
          <Option value={insight.id} key={insight.id}>
            #{insight.id} [{ABBR_MAP_TYPE[insight.type]}] {insight.title} - {insight.platform}
          </Option>
        ))}
      </Select>
    </React.Fragment>
  );
};
