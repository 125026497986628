import React, { useState } from 'react';
import { Button } from 'antd';
import OrchestratorsTable from './OrchestratorsTable';
import Modal from './Modal';

const OrchestratorTab = ({ orchestrators, isLoading, addOrchestrator, updOrchestrator, onDelOrchestrator }) => {
  const [isModalVisible, IsModalVisibleChange] = useState(false);
  const [currEditOrchestratorId, currEditOrchestratorIdChange] = useState(null);

  const handleUpdOrchestratorModal = (orchestratorId) => {
    currEditOrchestratorIdChange(orchestratorId);
    IsModalVisibleChange(true);
  };
  const handleDelOrchestrator = (orchestratorId) => {
    onDelOrchestrator(orchestratorId);
    currEditOrchestratorIdChange(null);
  };

  return (
    <div>
      {isModalVisible && (
        <Modal
          orchestrator={orchestrators.find((orchestrator) => orchestrator.id === currEditOrchestratorId)}
          handleHide={() => {
            IsModalVisibleChange(false);
            // setCurEditDeviceId(null);
          }}
          addSubmit={addOrchestrator}
          updSubmit={updOrchestrator}
        />
      )}
      <Button onClick={() => IsModalVisibleChange(true)} type="primary" style={{ margin: 16 }} loading={isLoading}>
        New Orchestrator
      </Button>
      <OrchestratorsTable
        isLoading={isLoading}
        orchestrators={orchestrators}
        onUpdOrchestrator={(orchestratorId) => handleUpdOrchestratorModal(orchestratorId)}
        onDelOrchestrator={(orchestratorId) => handleDelOrchestrator(orchestratorId)}
      />
    </div>
  );
};

export default OrchestratorTab;
