import * as actionTypes from '../actionTypes/index';

const INIT_STATE = {
  status: '',
  allUserGroups: [],
  allPermissions: [],
  missingApps: {},
  requestedOrgID: '',
  orchestrators: [],
  isLoading: false,
  error: null,
};

export default function (state = INIT_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_ALL_USER_GROUPS: {
      const status = 'Loading all users';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.GET_ALL_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        allUserGroups: data,
        status: '',
      };
    }
    case actionTypes.SET_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;
      // debugger
      const allUserGroupsCopy = state.allUserGroups.map((ug) => (ug.id !== data.id ? ug : data));
      return {
        ...state,
        allUserGroups: allUserGroupsCopy,
      };
    }
    case actionTypes.GET_ALL_USER_GROUPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus}`,
      };
    }

    // PERMISSONS
    case actionTypes.GET_ALL_USER_GROUP_PERMS: {
      const status = 'Loading all org perms';
      return {
        ...state,
        status,
      };
    }
    case actionTypes.GET_ALL_USER_GROUP_PERMS_SUCCESS: {
      const { perms } = action.payload;
      return {
        ...state,
        allPermissions: perms,
        status: '',
      };
    }
    case actionTypes.GET_ALL_USER_GROUP_PERMS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        status: `Error :${errorStatus}`,
      };
    }

    case actionTypes.SET_PERM_USER_GROUPS: {
      return {
        ...state,
        requestedOrgID: action.payload.org_id,
      };
    }

    case actionTypes.SET_PERM_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;
      const allUserGroupsCopy = state.allUserGroups.map((ug) => {
        if (ug.id !== state.requestedOrgID) {
          return ug;
        }

        const newPerms = ug.permissions.filter((perm) => !data.request.remove.includes(perm.name));
        data.request.add.forEach((perm) => {
          newPerms.push({ name: perm, id: perm });
        });

        const cloneUg = { ...ug };
        cloneUg.permissions = newPerms;
        return cloneUg;
      });
      const status = 'Updating Successfully!';

      return {
        ...state,
        requestedOrgID: '',
        allUserGroups: allUserGroupsCopy,
        status,
      };
    }

    case actionTypes.GET_ORCHESTRATORS_LIST_SUCCESS: {
      return {
        ...state,
        orchestrators: action.payload.data,
      };
    }

    case actionTypes.SET_PERM_USER_GROUPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        requestedOrgID: '',
        status: `Error :${errorStatus}`,
      };
    }

    // DOMAINS
    case actionTypes.SET_DOMAIN_USER_GROUPS: {
      return {
        ...state,
        requestedOrgID: action.payload.org_id,
      };
    }

    case actionTypes.SET_DOMAIN_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;
      const allUserGroupsCopy = state.allUserGroups.map((ug) => {
        if (ug.id !== state.requestedOrgID) {
          return ug;
        }

        const newDomains = ug.domains.filter((domain) => !data.request.remove.includes(domain.name));
        data.request.add.forEach((domain) => {
          newDomains.push({ name: domain, id: domain });
        });

        const cloneUg = { ...ug };
        cloneUg.domains = newDomains;
        return cloneUg;
      });
      const status = 'Updating Successfully!';

      return {
        ...state,
        requestedOrgID: '',
        allUserGroups: allUserGroupsCopy,
        status,
      };
    }

    case actionTypes.SET_DOMAIN_USER_GROUPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        requestedOrgID: '',
        status: `Error :${errorStatus}`,
      };
    }

    // ORCHESTRATORS
    case actionTypes.SET_ORCHESTRATOR_USER_GROUPS: {
      return {
        ...state,
        requestedOrgID: action.payload.org_id,
      };
    }

    case actionTypes.SET_ORCHESTRATOR_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;
      const allUserGroupsCopy = state.allUserGroups.map((ug) => {
        if (ug.id !== state.requestedOrgID) {
          return ug;
        }

        const newOrchestratorPermissions = ug.orchestrator_permissions.filter(
          (loc) => !data.request.remove.find((loc_id) => loc_id === loc.id)
        );
        data.request.add.forEach((loc_id) => {
          newOrchestratorPermissions.push(state.orchestrators.find((loc) => loc.id === loc_id));
        });

        const cloneUg = { ...ug };
        cloneUg.orchestrator_permissions = newOrchestratorPermissions;
        return cloneUg;
      });
      const status = 'Updating Successfully!';

      return {
        ...state,
        requestedOrgID: '',
        allUserGroups: allUserGroupsCopy,
        status,
      };
    }

    case actionTypes.SET_ORCHESTRATOR_USER_GROUPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        requestedOrgID: '',
        status: `Error :${errorStatus}`,
      };
    }

    // Apps
    case actionTypes.SET_APP_USER_GROUPS: {
      return {
        ...state,
        requestedOrgID: action.payload.org_id,
      };
    }

    case actionTypes.SET_APP_USER_GROUPS_SUCCESS: {
      const { data } = action.payload;

      const allUserGroupsCopy = state.allUserGroups.map((ug) => {
        if (ug.id !== state.requestedOrgID) {
          return ug;
        }

        let newApps;
        if (data.request.remove.length > 0) {
          newApps = ug.apps.filter((app) => !data.request.remove.includes(app.app_id));
        } else {
          newApps = data.request.set;
        }

        const cloneUg = { ...ug };
        cloneUg.apps = newApps;
        return cloneUg;
      });
      const status = 'Updating Successfully!';

      return {
        ...state,
        requestedOrgID: '',
        allUserGroups: allUserGroupsCopy,
        status,
      };
    }

    case actionTypes.SET_APP_USER_GROUPS_FAILED: {
      const { errorStatus } = action.payload;
      return {
        ...state,
        requestedOrgID: '',
        status: `Error :${errorStatus}`,
      };
    }

    case actionTypes.SET_APP_USER_MISSING_GROUPS_SUCCESS: {
      const copyMissingApps = { ...state.missingApps };
      copyMissingApps[action.payload.data.org_id] = action.payload.data.body;
      return {
        ...state,
        missingApps: copyMissingApps,
      };
    }

    // ADD UG
    case actionTypes.ADD_USER_GROUPS_SUCCESS: {
      const allUserGroupsCopy = [...state.allUserGroups];
      allUserGroupsCopy.push(action.payload.data);
      return {
        ...state,
        allUserGroups: allUserGroupsCopy,
      };
    }

    // DELETE UG
    case actionTypes.DELETE_USER_GROUPS_SUCCESS: {
      const allUserGroupsCopy = [...state.allUserGroups.filter((ug) => ug.id !== action.payload.data.organization_id)];
      return {
        ...state,
        allUserGroups: allUserGroupsCopy,
      };
    }

    // Products
    case actionTypes.ADD_PRODUCT_TO_USER_GROUP: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.ADD_PRODUCT_TO_USER_GROUP_SUCCESS: {
      const { organization_id, products } = action.payload;
      return {
        ...state,
        isLoading: false,
        allUserGroups: state.allUserGroups.map((x) =>
          x.id === organization_id
            ? {
                ...x,
                products,
              }
            : x
        ),
      };
    }
    case actionTypes.DEL_PRODUCT_FROM_USER_GROUP: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actionTypes.DEL_PRODUCT_FROM_USER_GROUP_SUCCESS: {
      const { organization_id, products } = action.payload;
      return {
        ...state,
        isLoading: false,
        allUserGroups: state.allUserGroups.map((x) =>
          x.id === organization_id
            ? {
                ...x,
                products,
              }
            : x
        ),
      };
    }
    case actionTypes.USER_GROUPS_PRODUCTS_API_REQUEST_FAILED: {
      return {
        ...state,
        error: action.payload.error,
      };
    }

    default: {
      return state;
    }
  }
}
