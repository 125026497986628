/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import moment from 'moment';
import { Button, Checkbox, Icon, Popconfirm, Select, Table, Tooltip } from 'antd';
import ReportDownloadLink from './ReportDownloadLink';
import { RELEASE_STATES } from '../../../constants/releases';
import { mixPanelTracking } from 'monitoring/mixpanel';

export default ({
  currApp,
  releases,
  isLoading,
  onEditRelease,
  onDeleteRelease,
  reportDownloadUrls,
  onDownloadReports,
  updateReleaseHandle,
  showAnalysisIssues,
  isAdmin,
}) => {
  const [changeStateDialogAttrs, setChangeStateDialogAttrs] = useState({});

  const columns = [
    {
      title: 'Actions',
      key: 'actions',
      render: (value, record) => (
        <div style={{ width: '130%' }}>
          <Button type="default" icon="edit" loading={isLoading} onClick={() => onEditRelease(record)} />
          <Popconfirm
            title="Are you sure?"
            icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
            onConfirm={() => {
              onDeleteRelease(record.id);
            }}
            disabled={!isAdmin}
          >
            <Button type="danger" icon="delete" loading={isLoading} style={{ margin: '0 5px' }} disabled={!isAdmin} />
          </Popconfirm>
          {record.analysis_issues && (
            <Button onClick={() => showAnalysisIssues(record.id)} style={{ width: '8rem' }} disabled={!isAdmin}>
              Issues {record.analysis_issues.filter((x) => x.state !== 'closed').length} /{' '}
              {record.analysis_issues.length}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'Release Name',
      dataIndex: 'release_name',
    },
    {
      title: 'Version Code',
      dataIndex: 'version_code',
    },
    {
      title: 'Upload Date',
      dataIndex: 'upload_date',
    },
    {
      title: 'Binary Link',
      dataIndex: 'binary_link',
      render: (value, record) => <a href={record.binary_link}>Download APK</a>,
    },
    {
      title: 'Reports',
      dataIndex: 'reports',
      render: (value, record) => (
        <div style={{ display: 'flex' }}>
          <div style={{ padding: '0 5px 0 0' }}>
            <ReportDownloadLink
              reportDir="out"
              releaseID={record.id}
              reportDownloadUrls={reportDownloadUrls}
              onDownloadReports={onDownloadReports}
              disabled={!isAdmin}
            />
          </div>
          <div>
            <ReportDownloadLink
              reportDir="open"
              releaseID={record.id}
              reportDownloadUrls={reportDownloadUrls}
              onDownloadReports={onDownloadReports}
              disabled={!isAdmin}
            />
          </div>
        </div>
      ),
    },
    {
      title: 'State',
      dataIndex: 'state',
      render: (value, record) => (
        <div>
          <Popconfirm
            title="Start Analysis process?"
            visible={changeStateDialogAttrs.id === record.id}
            onConfirm={() =>
              updateReleaseHandle(record.id, {
                state: changeStateDialogAttrs.state,
                analysis_start_date: moment().format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS),
              })
            }
            onCancel={() => updateReleaseHandle(record.id, { state: changeStateDialogAttrs.state })}
            onVisibleChange={() => setChangeStateDialogAttrs({})}
          >
            <Select
              filterOption={false}
              style={{ width: 180 }}
              onChange={(selVal) => {
                mixPanelTracking.track('release_status_changed', {
                  release_id: record.id,
                  old_status: record.state,
                  new_status: selVal,
                  app_name: currApp.name,
                });
                if (
                  !record.analysis_start_date &&
                  ['in_review', 'analysis_pending', 'analysis_in_progress', 'published'].includes(selVal)
                ) {
                  setChangeStateDialogAttrs({
                    id: record.id,
                    state: selVal,
                  });
                } else {
                  updateReleaseHandle(record.id, { state: selVal });
                }
              }}
              defaultActiveFirstOption={false}
              value={value}
              disabled={!isAdmin}
            >
              {RELEASE_STATES.map((state) => (
                <Select.Option key={state} value={state}>
                  {state}
                </Select.Option>
              ))}
            </Select>
          </Popconfirm>
          <div style={{ whiteSpace: 'nowrap' }}>
            {record.state_updated_by && (
              <div>
                upd{' '}
                <b title={`updated at ${record.state_updated_at}`}>
                  {record.state_updated_by.name}({moment(record.state_updated_at).format(moment.HTML5_FMT.DATE)})
                </b>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      title: 'Release Date',
      dataIndex: 'release_date',
      render: (value) => (
        <div title={value} style={{ whiteSpace: 'nowrap' }}>
          {value && moment(value).format(moment.HTML5_FMT.DATE)}
        </div>
      ),
    },
    {
      title: 'Start analysis date',
      dataIndex: 'analysis_start_date',
      render: (value) => value && moment(value).format(moment.HTML5_FMT.DATETIME_LOCAL),
    },
    {
      title: 'Parent Release',
      dataIndex: 'parent_release_id',
      render: (value) => {
        const parentRelease = releases.find((x) => x.id === value);
        if (parentRelease) {
          return (
            <React.Fragment>
              {parentRelease.release_name} ({parentRelease.version_code})
            </React.Fragment>
          );
        }
        return null;
      },
    },
    {
      title: 'Is Visible',
      dataIndex: 'is_visible',
      render: (value, record) => (
        <Checkbox
          checked={value}
          onChange={(evt) => {
            updateReleaseHandle(record.id, { ...record, is_visible: evt.target.checked });
          }}
          disabled={!isAdmin}
        />
      ),
    },
    {
      title: 'Summary',
      dataIndex: 'summary',
      render: (value) =>
        value ? (
          <Tooltip placement="top" title={value}>
            {value.slice(0, 20)}
          </Tooltip>
        ) : (
          value
        ),
    },
    {
      title: 'Is Comparison Completed',
      dataIndex: 'is_comparison_completed',
      render: (value) => (value === null ? '' : value ? 'Yes' : 'No'),
    },
    {
      title: 'Scrape Rrom',
      dataIndex: 'scrape_from',
    },
  ];
  return (
    <div>
      <Table
        loading={isLoading}
        dataSource={releases}
        columns={columns}
        rowKey={(record) => record.id}
        className="releases__table"
      />
    </div>
  );
};
