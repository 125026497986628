"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FocusAreas = void 0;
var antd_1 = require("antd");
var focus_areas_1 = require("hooks/focus-areas");
var react_1 = __importStar(require("react"));
var edit_create_focus_area_modal_1 = require("./edit-create-focus-area-modal");
var verticals_1 = require("hooks/verticals");
var FocusAreas = function () {
    var focusAreas = (0, focus_areas_1.useGetFocusAreas)({
        vertical_id: undefined,
    }).data;
    var verticals = (0, verticals_1.useGetVerticals)().data;
    var deleteFocusArea = (0, focus_areas_1.useDeleteFocusArea)();
    var updateFocusArea = (0, focus_areas_1.useUpdateFocusArea)();
    var createFocusArea = (0, focus_areas_1.useCreateFocusArea)();
    var _a = (0, react_1.useState)({
        open: false,
        mode: 'create',
    }), modalProps = _a[0], setModalProps = _a[1];
    var handleDelete = function (id) { return function () {
        deleteFocusArea.mutateAsync({
            id: id,
        });
    }; };
    var handleCreateFocusArea = function (_a) {
        var vertical_id = _a.vertical_id, name = _a.name;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!vertical_id)
                            return [2 /*return*/];
                        return [4 /*yield*/, createFocusArea.mutateAsync({
                                name: name,
                                vertical_id: vertical_id,
                            })];
                    case 1:
                        _b.sent();
                        setModalProps(function (prev) { return (__assign(__assign({}, prev), { open: false })); });
                        return [2 /*return*/];
                }
            });
        });
    };
    var handleUpdateFocusArea = function (_a) {
        var name = _a.name;
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!modalProps.focusAreaId)
                            return [2 /*return*/];
                        return [4 /*yield*/, updateFocusArea.mutateAsync({
                                id: modalProps.focusAreaId,
                                name: name,
                            })];
                    case 1:
                        _b.sent();
                        setModalProps(function (prev) { return (__assign(__assign({}, prev), { open: false, focusAreaId: undefined, initialValues: undefined })); });
                        return [2 /*return*/];
                }
            });
        });
    };
    var focusAreasData = (0, react_1.useMemo)(function () {
        if (!(focusAreas === null || focusAreas === void 0 ? void 0 : focusAreas.data) || !(verticals === null || verticals === void 0 ? void 0 : verticals.data))
            return [];
        return focusAreas.data.map(function (focusArea) {
            var _a;
            return (__assign(__assign({}, focusArea), { vertical_name: (_a = verticals.data.find(function (vertical) { return vertical.id === focusArea.vertical_id; })) === null || _a === void 0 ? void 0 : _a.name }));
        });
    }, [focusAreas === null || focusAreas === void 0 ? void 0 : focusAreas.data, verticals === null || verticals === void 0 ? void 0 : verticals.data]);
    var FocusAreasCols = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Vertical',
            dataIndex: 'vertical_name',
            key: 'vertical_name',
        },
        {
            title: 'Action',
            key: 'action',
            render: function (_a) {
                var id = _a.id, name = _a.name;
                return (react_1.default.createElement("span", null,
                    react_1.default.createElement("a", { onClick: function () {
                            setModalProps(function (prev) { return (__assign(__assign({}, prev), { focusAreaId: id, initialValues: { name: name, vertical_id: null }, open: true, mode: 'edit' })); });
                        } },
                        "Edit",
                        ' '),
                    react_1.default.createElement(antd_1.Divider, { type: "vertical" }),
                    react_1.default.createElement(antd_1.Popconfirm, { trigger: "click", title: "Are you sure delete?", onConfirm: handleDelete(id), okText: "Yes", cancelText: "No" },
                        react_1.default.createElement("a", null, "Delete"))));
            },
        },
    ];
    return (react_1.default.createElement("div", { style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '2rem',
        } },
        react_1.default.createElement(antd_1.Button, { type: "primary", onClick: function () { return setModalProps(function (prev) { return (__assign(__assign({}, prev), { open: true, mode: 'create' })); }); } }, "Create Focus Area"),
        !focusAreasData ? (react_1.default.createElement(antd_1.Spin, { size: "large" })) : (react_1.default.createElement(antd_1.Table, { style: { maxHeight: '80vh', overflowY: 'scroll' }, pagination: { pageSize: 50 }, columns: FocusAreasCols, dataSource: focusAreasData })),
        react_1.default.createElement(edit_create_focus_area_modal_1.EditCreateFocusAreaModal, { onSubmit: modalProps.mode === 'create' ? handleCreateFocusArea : handleUpdateFocusArea, mode: modalProps.mode, visible: modalProps.open, initialValues: modalProps.initialValues, onClose: function () { return setModalProps(function (prev) { return (__assign(__assign({}, prev), { open: false })); }); } })));
};
exports.FocusAreas = FocusAreas;
