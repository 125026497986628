import { message } from 'antd';
import { useMutation } from 'react-query';
import { parseString } from 'xml2js';
import { getDeviceScreenData, translateText } from '../../../api/workbenchAPI';

const textRegex = new RegExp(/\p{L}/u);

const findNodesWithText = (root) => {
  const nodesWithText = [];

  if (!root) {
    return [];
  }

  if (root['$'].text && textRegex.test(root['$'].text)) {
    nodesWithText.push(root);
  }

  if (root.node) {
    root.node.forEach((child) => {
      nodesWithText.push(...findNodesWithText(child));
    });
  }

  return nodesWithText;
};

export const useScreenTranslation = ({ platform, serial, setDeviceRects }) => {
  const translateMutation = useMutation('Translate', (text) => translateText(text));
  const getDeviceScreenDataMutation = useMutation('GetDeviceScreenData', (serial) => getDeviceScreenData(serial));

  const handleTranslateScreenAndroid = async () => {
    const { xml } = await getDeviceScreenDataMutation.mutateAsync(serial);

    parseString(xml, async (err, result) => {
      if (err) {
        message.error('Error parsing XML:', err);
        return;
      }

      const root = result.hierarchy;
      const nodeWithTexts = findNodesWithText(root);

      const rects = await Promise.all(
        nodeWithTexts.map(async (node) => {
          const { bounds, text } = node['$'];
          // convert string of type "[92,21][190,79]" into a rect
          const [left, top, right, bottom] = bounds
            .match(/\[(\d+),(\d+)\]\[(\d+),(\d+)\]/)
            .slice(1)
            .map(Number);

          const { translation, error } = await translateMutation.mutateAsync({
            text,
            targetLang: 'en',
          });

          if (error) {
            return message.error(error);
          }

          return {
            top,
            left,
            bottom,
            right,
            type: 'translation',
            key: _.uniqueId(),
            text: translation,
          };
        })
      );
      setDeviceRects(rects);
    });
  };
  const handleTranslateScreenIos = async () => {
    const { xml } = await getDeviceScreenDataMutation.mutateAsync(serial);
    const xmlString = `<root>${xml}</root>`;
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
    const rootElement = xmlDoc.documentElement;
    const nodeElements = rootElement.querySelectorAll('node');
    const arrayFromNodeList = Array.from(nodeElements);
    const rects = await Promise.all(
      arrayFromNodeList.map(async (node) => {
        const text = node.getAttribute('label');
        const bounds = node.getAttribute('bounds');
        if (text === null || bounds === null) {
          return;
        }
        // convert string of type "[92,21][190,79]" into a rect
        const [left, top, right, bottom] = bounds
          .match(/\[(\d+),(\d+)\]\[(\d+),(\d+)\]/)
          .slice(1)
          .map(Number);
        const { translation, error } = await translateMutation.mutateAsync({
          text,
          targetLang: 'en',
        });
        if (error) {
          return message.error(error);
        }
        return {
          top,
          left,
          bottom,
          right,
          type: 'translation',
          key: _.uniqueId(),
          text: translation,
          isHint: false,
        };
      })
    );
    setDeviceRects(rects.filter((rect) => rect !== undefined));
  };
  if (platform === 'iOS') {
    return {
      handleTranslateScreen: handleTranslateScreenIos,
    };
  } else {
    return {
      handleTranslateScreen: handleTranslateScreenAndroid,
    };
  }
};
