"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REMOTE_OPTIONS = exports.INSIGHT_ASSET_TYPES = exports.UJ_ACTION_TYPES = exports.CUSTOM_DEVICE = exports.LEFT_VIDEO_CONTAINER_ID = exports.RIGHT_VIDEO_CONTAINER_ID = exports.RIGHT_DEVICE_STREAM_PORT = exports.LEFT_DEVICE_STREAM_PORT = exports.SOCKET_SEND_EVENTS = exports.ASSET_TYPES = exports.URL_PARAMS = exports.FRAMES_SERVICE_VERSION = exports.SIMPLE_DEVICE_MANAGER_VERSION = exports.VIEWS = exports.EDITING_MODES = exports.MarkerTypes = exports.WorkbenchPathWithParams = exports.WorkbenchPath = void 0;
exports.WorkbenchPath = 'workbench_v2';
exports.WorkbenchPathWithParams = 'workbench_v2/:insightId?';
exports.MarkerTypes = {
    marker: 'marker',
    blur: 'blur',
    translation: 'translation',
};
exports.EDITING_MODES = {
    None: 'None',
    PrintScreen: 'PrintScreen',
    InsightCreation: 'InsightCreation',
    AddToInsight: 'AddToInsight',
};
exports.VIEWS = {
    VIEW_LEFT: 'VIEW_LEFT',
    VIEW_RIGHT: 'VIEW_RIGHT',
};
exports.SIMPLE_DEVICE_MANAGER_VERSION = '1.0.0';
exports.FRAMES_SERVICE_VERSION = '2.1.0';
exports.URL_PARAMS = {
    AppId: 'appId',
    LeftDevice: 'leftDevice',
    RightDevice: 'rightDevice',
    MockEnabled: 'mockEnabled',
    Remote: 'remote',
    UJId: 'ujId',
};
exports.ASSET_TYPES = {
    Previous: 'previous',
    Current: 'current',
};
exports.SOCKET_SEND_EVENTS = {
    GetDiffData: 'get-diff-data',
    HintsAnalysisStart: 'hints-analysis-start',
    HintsAnalysisStop: 'hints-analysis-stop',
    RpStart: 'rp-start',
    RpStop: 'rp-stop',
    VideoStart: 'video-start',
    VideoStop: 'video-stop',
};
exports.LEFT_DEVICE_STREAM_PORT = 8055;
exports.RIGHT_DEVICE_STREAM_PORT = 8056;
exports.RIGHT_VIDEO_CONTAINER_ID = 'video-viewer-right';
exports.LEFT_VIDEO_CONTAINER_ID = 'video-viewer-left';
exports.CUSTOM_DEVICE = {
    serial: 'CUSTOM',
    device_name: '',
    model: '',
    os_version: 'None',
    platform: '',
};
exports.UJ_ACTION_TYPES = {
    Click: 'tap',
    Swipe: 'swipe',
};
exports.INSIGHT_ASSET_TYPES = {
    Image: 'image',
    Video: 'video',
};
exports.REMOTE_OPTIONS = [
    {
        name: 'Local',
        videoSocketUrlLeft: 'ws://localhost:8055',
        videoSocketUrlRight: 'ws://localhost:8056',
        deviceServicesHostUrl: 'http://localhost:4567',
        wsUrl: 'ws://localhost:8008',
    },
    {
        name: 'Office',
        videoSocketUrlLeft: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18055',
        videoSocketUrlRight: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18056',
        deviceServicesHostUrl: 'https://4rfedb3gnz.tunn3lz.watchful.ai:14567',
        wsUrl: 'wss://4rfedb3gnz.tunn3lz.watchful.ai:18008',
    },
];
