import { Button, Progress, Tree } from 'antd';
import React, { useEffect, useState } from 'react';
import { CUSTOM_DEVICE, SOCKET_SEND_EVENTS } from '../../utils/consts';
import './HintsPanel.scss';

const HintsConfigFields = {
  OnlyResourceStrings: 'only_resource_strings',
  ElementLocationChangeHints: 'element_location_change_hint',
  DiffDataHints: 'diff_hints',
  AddedRemovedTextHints: 'added_removed_text',
};

const intialHintsConfig = [HintsConfigFields.AddedRemovedTextHints, HintsConfigFields.ElementLocationChangeHints];

const customDeviceHintsConfig = [];

export const HintsPanel = ({
  progress,
  socket,
  currApp,
  prevVersion,
  currVersion,
  setHintProgress,
  isCustomDevice,
  rightDevice,
  leftDevice,
}) => {
  const [hintsStarted, setHintsStarted] = useState(false);
  const [hintsConfig, setHintsConfig] = useState(intialHintsConfig);
  const isDiffCollected = progress && progress.percent === 100;
  const isAddedRemovedTextSelected = hintsConfig.includes(HintsConfigFields.AddedRemovedTextHints);

  useEffect(() => {
    setHintsConfig(isCustomDevice ? customDeviceHintsConfig : intialHintsConfig);
  }, [isCustomDevice]);

  const handleGenerateDiff = () => {
    const prevDeviceAppVer = prevVersion || currVersion;
    const currDeviceAppVer = currVersion || prevVersion;

    socket.send({
      event: SOCKET_SEND_EVENTS.GetDiffData,
      platform: currApp.platform,
      package: currApp.package,
      prev_version: `${prevDeviceAppVer.version_code}__${prevDeviceAppVer.version_name}`,
      curr_version: `${currDeviceAppVer.version_code}__${currDeviceAppVer.version_name}`,
    });
  };

  const handleToggleHints = () => {
    const prevDeviceAppVer = prevVersion || currVersion;
    const currDeviceAppVer = currVersion || prevVersion;

    socket.send({
      event: hintsStarted ? SOCKET_SEND_EVENTS.HintsAnalysisStop : SOCKET_SEND_EVENTS.HintsAnalysisStart,
      ...(hintsStarted
        ? null
        : {
            platform: currApp.platform,
            hint_types: hintsConfig,
            prev_custom_version:
              leftDevice.serial === CUSTOM_DEVICE.serial
                ? `${prevDeviceAppVer.version_code}__${prevDeviceAppVer.version_name}`
                : '',
            curr_custom_version:
              rightDevice.serial === CUSTOM_DEVICE.serial
                ? `${currDeviceAppVer.version_code}__${currDeviceAppVer.version_name}`
                : '',
          }),
    });
    setHintProgress(null);
    setHintsStarted(!hintsStarted);
  };

  const handleHintsConfigChange = ({ checked }) => {
    let hintsOptions = checked;

    if (!hintsOptions.includes(HintsConfigFields.AddedRemovedTextHints)) {
      hintsOptions = hintsOptions.filter((option) => !option.includes(HintsConfigFields.OnlyResourceStrings));
    }

    if (hintsStarted) {
      socket.send({
        event: SOCKET_SEND_EVENTS.HintsAnalysisStop,
      });
      socket.send({
        event: SOCKET_SEND_EVENTS.HintsAnalysisStart,
        hint_types: hintsOptions,
      });
    }

    setHintsConfig(hintsOptions);
  };

  const handleConfigOptionSelect = (_, { node }) => {
    const { eventKey } = node.props;

    if (hintsConfig.includes(eventKey)) {
      const checkedOptions = hintsConfig.filter((option) => !option.includes(eventKey));
      return handleHintsConfigChange({ checked: checkedOptions });
    }

    if (!hintsConfig.includes(eventKey)) {
      return handleHintsConfigChange({ checked: [...hintsConfig, eventKey] });
    }
  };

  return (
    <div className="hints-panel-container">
      <Progress
        type="circle"
        percent={progress ? progress.percent : 0}
        width={65}
        className="hints-progress"
        format={(percent) => (percent === 100 ? `${progress.message}` : `${percent}%`)}
      />
      <Button
        type="primary"
        loading={progress && progress.percent !== 100}
        onClick={handleGenerateDiff}
        disabled={hintsStarted}
      >
        Generate Diff
      </Button>
      <Tree
        checkStrictly
        checkable
        defaultCheckedKeys={hintsConfig}
        checkedKeys={hintsConfig}
        onCheck={handleHintsConfigChange}
        onSelect={handleConfigOptionSelect}
        disabled={isCustomDevice}
      >
        <Tree.TreeNode
          title="Added/Removed Text"
          className="screen-data-hints"
          key={HintsConfigFields.AddedRemovedTextHints}
          disabled={isCustomDevice}
        >
          <Tree.TreeNode
            title="Only Resource Strings"
            className="screen-data-hints"
            key={HintsConfigFields.OnlyResourceStrings}
            disabled={!isDiffCollected || !isAddedRemovedTextSelected || isCustomDevice}
          />
        </Tree.TreeNode>
        <Tree.TreeNode
          title="Element Location Change Hints"
          className="element-location-hints"
          key={HintsConfigFields.ElementLocationChangeHints}
          disabled={isCustomDevice}
        />
        <Tree.TreeNode
          title="Diff Data Hints"
          key={HintsConfigFields.DiffDataHints}
          className="diff-data-hints"
          disabled={!isDiffCollected}
        />
      </Tree>
      <Button type="primary" disabled={!hintsConfig.length} onClick={handleToggleHints}>
        {hintsStarted ? 'Stop Hints Generation' : 'Start Hints Generation'}
      </Button>
    </div>
  );
};
