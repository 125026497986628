import { Icon, Popconfirm, Popover, Table, Select } from 'antd';
import React, { useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import ins_ass_del_ico from '../../../../assets/icons/ins_ass_del.svg';
import ins_ass_edit_ico from '../../../../assets/icons/ins_ass_edit.svg';
import InsightAssetUploader2 from '../../../Insight2/InsightComponents/InsightAssetUploader2';
import { CanvasedImagePreview } from './CanvasedImagePreview';
import { EditAssetModal } from './EditAssetModal';
import './UJAssetList.scss';

const DragHandle = SortableHandle((props) => (
  <Icon
    type="menu"
    style={{
      cursor: 'grab',
    }}
  />
));
const SortableItem = SortableElement((props) => <tr {...props} style={{ zIndex: 1001 }} />);
const ASortableContainer = SortableContainer((props) => <tbody {...props} />);

const DraggableContainer = ({ onSortEnd, ...props }) => (
  <ASortableContainer useDragHandle helperClass="row-dragging" onSortEnd={(data) => onSortEnd(data)} {...props} />
);

const DraggableBodyRow = ({ className, style, assets, ...restProps }) => (
  <SortableItem index={restProps['data-row-key']} {...restProps} />
);

const ASSET_PREVIEW_POPUP_WIDTH = 150;
const ASSET_PREVIEW_POPUP_HEIGHT = 350;

const ASSET_PREVIEW_WIDTH = 50;
const ASSET_PREVIEW_HEIGHT = 120;

export const UJAssetList = ({ curUserFlowSet, updateAssetIndex, deleteAsset, createAssets, updateAsset, curApp }) => {
  const [editAssetModal, setEditAssetModal] = useState({
    isOpen: false,
    actAssetIdx: 0,
  });

  const { assets } = curUserFlowSet;
  const sortedAssets = assets.sort((cur, other) => cur.order_index - other.order_index);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const assetOnOldLoc = assets[oldIndex];
    const assetOnNewLoc = assets[newIndex];

    updateAssetIndex([
      { id: assetOnOldLoc.id, order_index: newIndex },
      { id: assetOnNewLoc.id, order_index: oldIndex },
    ]);
  };

  const handleDeleteAsset =
    ({ user_flow_set_asset_id }) =>
    () => {
      deleteAsset(user_flow_set_asset_id);
    };

  const handleEditAssetModalOpen = (assetIdx) => () => {
    setEditAssetModal({ isOpen: true, actAssetIdx: assetIdx });
  };

  const handleEditAssetModalClose = () => {
    setEditAssetModal((prev) => ({ ...prev, isOpen: false }));
  };

  const addAssetAdapter = ({ uploaded_file_name, orientation }) => {
    createAssets({
      set_id: curUserFlowSet.id,
      assets: [
        {
          image_url: uploaded_file_name,
          ui_xml_url: '',
          text: '',
          orientation,
        },
      ],
    });
  };

  const assetsTableColumns = [
    {
      dataIndex: 'order_index',
      width: '30px',
      render: (val, rec) => <DragHandle rec={rec} />,
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (text, a, index) =>
        a.type === 'image' ? (
          <Popover
            overlayClassName="asset-preview-full"
            content={
              <CanvasedImagePreview
                canvasWidth={a.orientation === 'portrait' ? ASSET_PREVIEW_POPUP_WIDTH : ASSET_PREVIEW_POPUP_HEIGHT}
                canvasHeight={a.orientation === 'portrait' ? ASSET_PREVIEW_POPUP_HEIGHT : ASSET_PREVIEW_POPUP_WIDTH}
                imageSrc={a.url_annotated}
                action_type={a.action_type}
                action_coords={a.action_coords}
                circleRadius={15}
              />
            }
          >
            <div>
              <CanvasedImagePreview
                canvasWidth={a.orientation === 'portrait' ? ASSET_PREVIEW_WIDTH : ASSET_PREVIEW_HEIGHT}
                canvasHeight={a.orientation === 'portrait' ? ASSET_PREVIEW_HEIGHT : ASSET_PREVIEW_WIDTH}
                imageSrc={a.url_annotated}
                action_type={a.action_type}
                action_coords={a.action_coords}
                circleRadius={5}
              />
            </div>
          </Popover>
        ) : (
          <Popover
            overlayClassName="asset-preview-full"
            content={<video src={a.url_annotated} autoPlay loop muted className="asset-preview-full-img" />}
          >
            <video src={a.url_annotated} autoPlay loop muted className="asset-preview" />
          </Popover>
        ),
    },
    {
      title: 'Orientation',
      dataIndex: 'orientation',
      width: '120px',
      render: (value, record) => (
        <React.Fragment>
          <Select
            placeholder="Platform"
            value={value}
            onChange={(orientation) => updateAsset({ asset_id: record.user_flow_set_asset_id, orientation })}
            width="7rem"
          >
            {['portrait', 'landscape'].map((x) => (
              <Select.Option key={x} value={x}>
                {x}
              </Select.Option>
            ))}
          </Select>
        </React.Fragment>
      ),
    },
  ];

  const editColumn = {
    title: '',
    key: '',
    width: '100px',
    render: (val, rec, index) => (
      <div className="modes-manage">
        <img src={ins_ass_edit_ico} onClick={handleEditAssetModalOpen(index)} />
        <Popconfirm title="Are you sure?" onConfirm={handleDeleteAsset(rec)}>
          <img src={ins_ass_del_ico} />
        </Popconfirm>
      </div>
    ),
  };

  const handleRowClick = (record, idx) => setEditAssetModal((prev) => ({ ...prev, actAssetIdx: idx }));

  const renderTableLayout = ({ columns, ...rest }) => (
    <div>
      <Table
        className="insight-data-assets"
        columns={columns}
        dataSource={sortedAssets}
        pagination={false}
        bordered
        components={{
          body: {
            wrapper: (props) => DraggableContainer({ ...props, onSortEnd }),
            row: (props) => DraggableBodyRow({ ...props }),
          },
        }}
        {...rest}
      />
      <InsightAssetUploader2 addAsset={addAssetAdapter} />
    </div>
  );

  return (
    <div className="ujasset-list">
      <h3 className="ujasset-list-title">Screens ({assets.length})</h3>
      {renderTableLayout({ columns: [...assetsTableColumns, editColumn] })}
      {assets.length > 0 && (
        <EditAssetModal
          appId={curApp ? curApp.id : null}
          isOpen={editAssetModal.isOpen}
          actAssetIndex={editAssetModal.actAssetIdx}
          closeModal={handleEditAssetModalClose}
          assets={assets}
          tableLayout={() =>
            renderTableLayout({
              columns: assetsTableColumns,
              onRowClick: handleRowClick,
              rowClassName: (record, index) => `asset-row ${index === editAssetModal.actAssetIdx ? 'active' : ''}`,
            })
          }
          updAsset={updateAsset}
        />
      )}
    </div>
  );
};
