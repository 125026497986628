import React, { useRef } from 'react';
import { Modal, Form, Input, Row, Col, Select } from 'antd';
import { WithContext as ReactTags } from 'react-tag-input';

const types = ['screen_change', 'new_feature', 'indication'];

class CreateInsight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      local_labels: [],
    };
  }
  render() {
    const KeyCodes = {
      comma: 188,
      enter: 13,
    };

    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const { getFieldDecorator } = this.props.form;
    const { labelsSuggestions } = this.props;
    const handleAddLabel = (label) => {
      // label.text = label.text.replace(/\s/g, '').toLowerCase();
      // label.id = label.id.replace(/\s/g, '').toLowerCase();
      label.text = label.text.trim().toLowerCase();
      label.id = label.id.trim().toLowerCase();
      const isExistInCurrentLocalLabels = this.state.local_labels.some((localLabel) => localLabel.text === label.text);
      if (!isExistInCurrentLocalLabels) {
        const labelsSuggestionsArray = labelsSuggestions.map((suggestion) => suggestion.name.toLowerCase());
        if (!labelsSuggestionsArray.includes(label.text.toLowerCase())) {
          label.className = 'new';
        }
        this.setState((state) => ({
          local_labels: [...state.local_labels, label],
        }));
      }
    };
    const handleDeleteLabel = (i) => {
      this.setState((state) => ({
        local_labels: state.local_labels.filter((label, index) => index !== i),
      }));
    };
    return (
      <Form>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Type">
              {getFieldDecorator('type', {
                initialValue: '',
              })(
                <Select>
                  {types.map((type) => (
                    <Select.Option key={type} value={type}>
                      {type}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Title">
              {getFieldDecorator('title', {
                rules: [{ max: 255 }],
                initialValue: '',
              })(<Input placeholder="Insight title" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="description">
              {getFieldDecorator('description', {
                initialValue: '',
              })(<Input.TextArea placeholder="Insight description" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form.Item label="Labels">
              {getFieldDecorator('labelsSuggestions', {
                initialValue: '',
              })(
                <ReactTags
                  value=""
                  delimiters={delimiters}
                  allowDragDrop={false}
                  // autocomplete
                  inline
                  tags={this.state.local_labels}
                  handleAddition={handleAddLabel}
                  handleDelete={handleDeleteLabel}
                  placeholder="Add labels"
                  suggestions={labelsSuggestions.map((suggestion) => ({
                    id: suggestion.name,
                    text: suggestion.name,
                  }))}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedForm = Form.create()(CreateInsight);

export default ({ labelsSuggestions, onHide, onCreateInsight }) => {
  let formRef = null;
  console.log(labelsSuggestions);
  return (
    <Modal
      title="Create new insight"
      centered
      visible
      className="create-insight-from-app-graph"
      onOk={() => {
        if (formRef) {
          formRef.props.form.validateFields((errors, values) => {
            if (errors) {
              return;
            }

            const newValues = { ...values, labelsSuggestions: formRef.state.local_labels.map((l) => l.text) };
            onCreateInsight(newValues);
            formRef.props.form.resetFields();
            onHide();
          });
        }
      }}
      onCancel={() => {
        onHide();
      }}
    >
      <WrappedForm
        wrappedComponentRef={(form) => {
          formRef = form;
        }}
        labelsSuggestions={labelsSuggestions}
      />
    </Modal>
  );
};
