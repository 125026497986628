class CachedAppsList {
  static key = 'CACHED_APPS';
  static MAX_CACHE_SIZE = 10;

  static addCachedApp(val, sub_key = '') {
    let apps = this.getCachedApps(sub_key);
    if (apps.length >= CachedAppsList.MAX_CACHE_SIZE) {
      apps.pop();
    }
    if (!apps.some((app) => app.name === val.name && app.package === val.package && app.platform === val.platform)) {
      // skip this app if it's already in the list
      apps = [val, ...apps];
    }

    localStorage.setItem(CachedAppsList.key + sub_key, JSON.stringify(apps));
  }

  static getCachedApps(sub_key = '') {
    return JSON.parse(localStorage.getItem(CachedAppsList.key + sub_key)) || [];
  }
}

export default CachedAppsList;
