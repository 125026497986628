export const AB_TEST_TYPE = {
  ab_test: 'A/B Test',
  feature: 'Feature',
};

export const AB_TEST_STATUS = {
  active: 'Active',
  integrated: 'Integrated',
  abandoned: 'Abandoned',
};
export const ORG_PERMS = {
  nothing: '-',
  experiments: 'Experiments (Intelligence)',
  performance: 'Performance',
  both: 'Both',
};
