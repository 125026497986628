import React, { useState, useEffect } from 'react';
import { Button, Upload, message, Icon } from 'antd';
import { Auth } from 'aws-amplify';

const { Dragger } = Upload;

const InsightAssetUploader2 = ({ addAsset, asset_type, isMarketing }) => {
  const accept = isMarketing ? 'image/*, text/html' : 'image/*, video/*, text/html';

  const [fileList, fileListChange] = useState([]);
  const handleAddAsset = (type, uploadedFileName, originalFileName, orientation) => {
    addAsset(
      {
        type,
        uploaded_file_name: uploadedFileName,
        original_file_name: originalFileName,
        orientation: orientation || 'portrait',
      },
      asset_type
    );
  };

  const addAmzData = async (file) => {
    const type = { image: 'image', video: 'video', text: 'html' }[file.type.split('/')[0]];
    const currentAWSSession = await Auth.currentSession();
    const token = currentAWSSession.idToken.jwtToken;
    // eslint-disable-next-line no-undef
    const res = await fetch(`${API_HOST}/insight/presigned-post-url`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const q = await res.json();
    file.amzData = q.data;
    file.key = q.data.uploaded_file_name;
    file.percent = 0;
    file.status = 'uploading';
    fileListChange((fileList) => [...fileList, file]);

    const formData = new FormData();
    Object.keys(file.amzData.s3_post_data.fields).forEach((key) => {
      formData.append(key, file.amzData.s3_post_data.fields[key]);
    });
    formData.append('file', file);
    fetch(file.amzData.s3_post_data.url, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        const addAsset = (orientation) => handleAddAsset(type, file.amzData.uploaded_file_name, file.name, orientation);
        if (type === 'image') {
          const fileReader = new FileReader();
          fileReader.onload = (event) => {
            const image = new Image();
            image.src = event.target.result;
            image.onload = () => addAsset(image.width > image.height ? 'landscape' : 'portrait');
          };
          fileReader.readAsDataURL(file);
        } else {
          addAsset();
        }
        fileListChange((fileList) =>
          fileList.map((f) =>
            f.uid === file.uid
              ? {
                  ...file,
                  name: file.name,
                  percent: 100,
                  status: 'done',
                }
              : f
          )
        );
        setTimeout(() => {
          fileListChange((fileList) => fileList.filter((f) => f.uid !== file.uid));
          message.destroy();
          message.success('Asset upload finished successfully');
        }, 1500);
      })
      .catch((e) => {
        message.error(e);
      });
  };

  const props = {
    name: 'file',
    multiple: true,
    accept,
    fileList,
    beforeUpload: (file) => {
      message.destroy();
      message.success('Starting asset upload');
      addAmzData(file);
      return false;
    },
  };

  return (
    <div className="ins-drap-drop-wrap">
      <Dragger {...props}>
        <div className="ins-drap-drop">
          <div>
            <Button className="add-something" icon="plus">
              Add Screens
            </Button>
          </div>
          <div>or</div>
          <div>drag here</div>
        </div>
      </Dragger>
    </div>
  );
};

export default InsightAssetUploader2;
