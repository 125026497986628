import React, { useState, useEffect } from 'react';
import { Select, message, Checkbox } from 'antd';
import { countries } from '../../../constants/countries';
import wwf from '../../../assets/icons/world-wide-flag.svg';

const allCountries = [
  {
    name: 'World Wide',
    code: 'ww',
  },
  ...countries,
];

export default function InsightOverrideCountries({ ins, handleInsChange }) {
  const handlePersonaLocationsChange = (selectedValue) => {
    if ((selectedValue.includes('ww') && selectedValue.length > 1) || ins.override_locations.includes('ww')) {
      message.error("You cannot select 'World wide' and other countries");
      return;
    }
    handleInsChange({ override_locations: [...selectedValue] });
  };

  return (
    <div className="over-countries">
      <div className=" over-countries-select">
        <Select
          mode="multiple"
          placeholder="Override Countries"
          onChange={handlePersonaLocationsChange}
          value={ins.override_locations}
          disabled={ins.override_locations === null}
          getPopupContainer={(trNode) => trNode}
        >
          {allCountries.map((country) =>
            country.code === 'ww' ? (
              <Select.Option value={country.code} key={country.code}>
                <img src={wwf} alt="world wide" />
                {country.name}
              </Select.Option>
            ) : (
              <Select.Option value={country.code} key={country.code}>
                <span style={{ marginRight: '1rem' }} className={`flag-icon flag-icon-${country.code.toLowerCase()}`} />
                {country.name}
              </Select.Option>
            )
          )}
        </Select>
        <span className="over-countries-tip-not-rec">*Not recommended for the faint of heart</span>
      </div>
      <div className="over-countries-tip">
        <Checkbox
          checked={ins.override_locations !== null}
          onClick={(e) => {
            handleInsChange({ override_locations: ins.override_locations ? null : [] });
          }}
        >
          Override countries
        </Checkbox>
      </div>
    </div>
  );
}
