import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { EDITING_MODES } from '../../utils/consts';
import './Footer.scss';
import { ProblemsAndSolutions } from './problemsAndSolutions';
import { RemoteSelect } from './remoteSelect';
import { UpdatesDrawer } from './updatesDrawer';
import { FE_UPDATES } from './updatesDrawer/updates';
import { UserJourneysModal } from './userJourneysModal';

export const Footer = ({
  editingModeStep,
  setEditingModeStep,
  handleReconnectClick,
  onCancel,
  imagesWithRects,
  app,
  getReleases,
  releases,
  resetScreens,
}) => {
  const [updatesOpen, setUpdatesOpen] = useState(false);
  const [userJourneysOpen, setUserJourneysOpen] = useState(false);

  const onCreateInsightHandle = () => setEditingModeStep(EDITING_MODES.InsightCreation);
  const onAddToInsightHandle = () => setEditingModeStep(EDITING_MODES.AddToInsight);
  const handleCancel = () => {
    onCancel();
    setEditingModeStep(EDITING_MODES.None);
  };

  const handleUpdatesOpen = () => {
    setUpdatesOpen(true);
  };

  const handleUpdatesClose = () => {
    setUpdatesOpen(false);
  };

  const handleCommonProblemsClick = () => {
    Modal.info({
      title: 'Common Problems And Solutions',
      content: <ProblemsAndSolutions />,
    });
  };

  const handleUserJourneyClick = () => {
    setUserJourneysOpen(true);
  };

  const handleCancelUserJourneys = () => {
    setUserJourneysOpen(false);
  };

  const handleUserJoureyCreated = () => {
    handleCancelUserJourneys();
    setEditingModeStep(EDITING_MODES.None);
  };

  return (
    <div className="footer-main">
      <div className="footer">
        <div className="buttons">
          <a onClick={handleUpdatesOpen} className="support-link">
            Whats New?
          </a>
          <a onClick={handleCommonProblemsClick} className="support-link">
            Problems And Solutions
          </a>
          <RemoteSelect />
          {editingModeStep === EDITING_MODES.PrintScreen ? (
            <React.Fragment>
              <div className="button-wrapper">
                <Button type="primary" onClick={handleUserJourneyClick}>
                  Create User Journey
                </Button>
              </div>
              <div className="button-wrapper">
                <Button type="primary" onClick={onCreateInsightHandle}>
                  Create Insight
                </Button>
              </div>
              <div className="button-wrapper">
                <Button onClick={onAddToInsightHandle}>Add to Insight</Button>
              </div>
              <div className="button-wrapper">
                <Button onClick={handleCancel}>Cancel</Button>
              </div>
            </React.Fragment>
          ) : (
            <div className="button-wrapper">
              <Button onClick={handleReconnectClick}>Restart Video</Button>
            </div>
          )}
        </div>
      </div>
      <UpdatesDrawer visible={updatesOpen} onClose={handleUpdatesClose} updates={FE_UPDATES} />
      {userJourneysOpen && (
        <UserJourneysModal
          visible={userJourneysOpen}
          onCancel={handleCancelUserJourneys}
          onOk={handleUserJoureyCreated}
          imagesWithRects={imagesWithRects}
          app={app}
          getReleases={getReleases}
          releases={releases}
          resetScreens={resetScreens}
        />
      )}
    </div>
  );
};
